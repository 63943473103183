import React, { Component } from 'react';
import ReactDOM from 'react-dom';

let MODAL_OPEN_COUNTER = 0;

class Overlay extends Component {
  constructor(props) {
    super(props);

    this.originalOnKeyDownHandler = document.body.onkeydown;
    this.newOnKeyHandler = (e) => {
      if (['Enter', 'Tab'].includes(e.key)) {
        //Allow following keypresses inside modal.
        return;
      }
      // Allow all keypresses when element is an Input or TextArea
      // other types of elements don't stop propagation
      const inputElements = ['TEXTAREA', 'INPUT'];
      if (
        document.activeElement &&
        inputElements.includes(document.activeElement.nodeName)
      ) {
        return;
      }
      // Prevent all other keyevents from firing to protect tagging.
      e.preventDefault();
      e.stopImmediatePropagation();
    };
  }

  componentWillUnmount() {
    MODAL_OPEN_COUNTER--;
    if (MODAL_OPEN_COUNTER === 0) {
      const html = document.getElementsByTagName('html')[0];
      html.classList.remove('modal-open');
      document.body.onkeydown = this.originalOnKeyDownHandler;
    }
  }

  componentDidMount() {
    if (MODAL_OPEN_COUNTER === 0) {
      const html = document.getElementsByTagName('html')[0];
      html.classList.add('modal-open');
      document.body.onkeydown = this.newOnKeyHandler;
    }
    MODAL_OPEN_COUNTER++;
  }

  render() {
    return <div className="view-overlay">{this.props.children}</div>;
  }
}

class Modal extends Overlay {
  static defaultProps = {
    size: 'md',
    contrastingCloseBtn: false,
  };

  constructor(props) {
    super(props);

    if (props.size && props.size !== '' && !this.SIZES.includes(props.size)) {
      console.warn(`Modal component doesnt support size: '${props.size}'.`);
    }
  }

  SIZES = ['sm', 'md', 'lg', 'full-screen'];

  render() {
    const { children, onCloseClick, size, contrastingCloseBtn, className } =
      this.props;

    // In cases like jwplayer fullscreen mode an element (DOMNode) is
    // in opened in fullscreen mode (using the Fullscreen API
    // https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API). When
    // we add the Modal to the body it won't be visible.
    // Make sure we first check if there is any element in fullscreen mode, and
    // if so, attach our Modal to that element.
    // TODO: in case of jwplayer the content of the Modal gets affected by the
    //       jwplayer css.
    let parent =
      document.fullscreenElement || document.webkitCurrentFullScreenElement;
    if (!parent) {
      parent = document.getElementsByTagName('body')[0];
    }

    return ReactDOM.createPortal(
      <div
        className={`modal ${size} ${className ?? ''} ${
          this.props.multiStepHandler ? 'has-tabs' : ''
        }`}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            {this.props.multiStepHandler}
            {this.props.title && (
              <div className={'modal-info-header'}>
                <h2>{this.props.title}</h2>
                {this.props.description && <p>{this.props.description}</p>}
              </div>
            )}
            <div
              className={`modal-close clickable ${
                contrastingCloseBtn ? 'modal-close-contrasting' : ''
              }`}
              onClick={onCloseClick}
            />
            {children}
          </div>
        </div>
      </div>,
      parent
    );
  }
}

export { Modal, Overlay };
