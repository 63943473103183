import {
  LocalCommandHandler,
  RemoteCommandHandler,
} from '../../infra/Messaging/CommandHandler';
import { CommandBus, Command } from '../../infra/Messaging';

import { Session } from '../Session';

import SportingEventCollection from './SportingEvent';
import TeamCollection from '../Team';
import { lineUpCollection } from '../LineUp/LineUp';

class SportingEventServiceCommandHandler extends LocalCommandHandler {
  constructor() {
    super();

    this.sportingEventRepository = SportingEventCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'SportingEventService.CreateMatch':
        const commands = [];
        let homeTeamId, homeTeamName, awayTeamId, awayTeamName;
        if (TeamCollection.get(command.homeTeam)) {
          homeTeamId = command.homeTeam;
          homeTeamName = TeamCollection.get(command.homeTeam).get('name');
        } else {
          homeTeamId = TeamCollection.nextIdentity();
          homeTeamName = command.homeTeam;
          commands.push(
            Command.create('Team.Create', {
              teamId: homeTeamId,
              name: command.homeTeam,
              sportType: command.sportType,
            })
          );
        }

        if (TeamCollection.get(command.awayTeam)) {
          awayTeamId = command.awayTeam;
          awayTeamName = TeamCollection.get(command.awayTeam).get('name');
        } else {
          awayTeamId = TeamCollection.nextIdentity();
          awayTeamName = command.awayTeam;
          commands.push(
            Command.create('Team.Create', {
              teamId: awayTeamId,
              name: command.awayTeam,
              sportType: command.sportType,
            })
          );
        }

        const lineUpId = lineUpCollection.nextIdentity();
        commands.push(Command.create('LineUp.Create', { lineUpId: lineUpId }));

        // hope this will be correct. We don't have a separate collection
        // for observationLogs
        const observationLogId = SportingEventCollection.nextIdentity();
        const sportingEventId = SportingEventCollection.nextIdentity();

        commands.push(
          Command.create('SportingEvent.CreateMatch', {
            sportingEventId,
            observationLogId,
            lineUpId,
            homeTeamId,
            awayTeamId,
            name: `${homeTeamName} - ${awayTeamName}`,
            scheduledAt: command.scheduledAt,
            tags: command.tags,
          })
        );
        CommandBus.dispatch(commands);
        break;
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['SportingEventService.*'];
  }
}

class LocalSportingEventCommandHandler extends LocalCommandHandler {
  constructor() {
    super();

    this.sportingEventRepository = SportingEventCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'SportingEvent.CreateMatch':
        const sportingEvent = this.sportingEventRepository.addNew({
          sportingEventId: command.sportingEventId,
          observationLogId: command.observationLogId,
          lineUpId: command.lineUpId,
          type: 'match',
          homeTeamId: command.homeTeamId,
          awayTeamId: command.awayTeamId,
          name: command.name,
          scheduledAt: command.scheduledAt,
          clocks: {
            live: {
              synchronizationPoints: [],
              clockId: 'U1',
            },
          },
          videoIds: [],
          _metadata: {
            privileges: [],
            source: { type: 'ResourceGroup' },
          },
          tags: command.tags,
        });
        sportingEvent.reportCollection.addNew({
          reportId: 'score',
          data: {},
        });
        break;
      case 'SportingEvent.AddSynchronizationPoint': {
        const sportingEvent = this.sportingEventRepository.get(
          command.sportingEventId
        );
        sportingEvent.addSynchronizationPoint(
          command.clockId,
          command.type,
          command.key,
          command.time
        );
        break;
      }
      case 'SportingEvent.RemoveSynchronizationPoint': {
        const sportingEvent = this.sportingEventRepository.get(
          command.sportingEventId
        );
        sportingEvent.removeSynchronizationPoint(
          command.clockId,
          command.type,
          command.key
        );
        break;
      }
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['SportingEvent.*'];
  }
}

class RemoteSportingEventCommandHandler extends RemoteCommandHandler {
  constructor() {
    super();

    this.sportingEventRepository = SportingEventCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'SportingEvent.AddSynchronizationPoint': {
        const sportingEvent = this.sportingEventRepository.get(
          command.sportingEventId
        );
        this.addUnitOfWork(command, [
          this.createRpcRequest(sportingEvent, 'addSynchronizationPoint', {
            clockId: command.clockId,
            type: command.type,
            key: command.key,
            time: command.time,
          }),
        ]);
        break;
      }
      case 'SportingEvent.RemoveSynchronizationPoint': {
        const sportingEvent = this.sportingEventRepository.get(
          command.sportingEventId
        );
        this.addUnitOfWork(command, [
          this.createRpcRequest(sportingEvent, 'removeSynchronizationPoint', {
            clockId: command.clockId,
            type: command.type,
            key: command.key,
          }),
        ]);
        break;
      }
      case 'SportingEvent.CreateMatch':
        const model = SportingEventCollection.build({
          sportingEventId: command.sportingEventId,
        });

        this.addUnitOfWork(command, [
          this.createAddWithIdRequest(model, {
            observationLogId: command.observationLogId,
            lineUpId: command.lineUpId,
            homeTeamId: command.homeTeamId,
            awayTeamId: command.awayTeamId,
            name: command.name,
            scheduledAt: command.scheduledAt,
            type: 'match',
            tags: command.tags,
          }),
        ]);
        break;

      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['SportingEvent.*'];
  }
}

export default () => {
  new SportingEventServiceCommandHandler();
  new LocalSportingEventCommandHandler();
  new RemoteSportingEventCommandHandler();
};
