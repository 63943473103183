import React from 'react';
import { Formik } from 'formik';
import omit from 'lodash/omit';
import moment from 'moment';

import { Page } from 'lib/Page';
import { CountrySelect } from 'lib/CountrySelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { withTranslation } from 'react-i18next';
import { gotoRoute } from 'modules/route';

import { tenantCollection } from 'domain/Tenant';
import ResourceGroupCollection from 'domain/ResourceGroup';
import { AuthAwareAdapterProxy } from 'utils/AuthAwareAdapterProxy';
import logger from 'utils/Logger';

const addClubAdmin = async (email, resourceGroup) => {
  return AuthAwareAdapterProxy.withUseResourceGroup(
    resourceGroup.id,
    async () => {
      try {
        await resourceGroup.members.create({
          firstName: '',
          lastName: '',
          email,
          roleNames: ['admin'],
        });
      } catch (e) {
        logger.error(`Could not add ${email} to ${resourceGroup.id}`);
      }
    }
  );
};

const addTeam = async (email, teamName, resourceGroup) => {
  return AuthAwareAdapterProxy.withUseResourceGroup(
    resourceGroup.id,
    async () => {
      const teamResourceGroup_ = await ResourceGroupCollection.create(
        {
          name: teamName,
        },
        { optimistic: false }
      );

      const teamResourceGroup = ResourceGroupCollection.get(
        teamResourceGroup_.resourceGroupId
      );

      try {
        await teamResourceGroup.members.create({
          firstName: '',
          lastName: '',
          email: email,
          roleNames: ['trainer'],
        });
      } catch (e) {
        logger.error(`Could not add ${email} to ${teamResourceGroup.id}`);
      }

      // this resourceGroup does not belong here
      ResourceGroupCollection.remove([teamResourceGroup.id]);
    }
  );
};

const initialValues = {
  productIds: ['teamtv-video-analyse-default'],
  countryCode: 'NL',
  licenseType: 'demo',
  licenseStart: moment(),
  licenseEnd: moment().add('1', 'M'),
};

// TODO: move up the dependency chain
const LicenseTypeSelect = (props) => {
  const { t } = props;

  const options = [
    { value: '', label: '' },
    { value: 'demo', label: 'licenseTypes.demo' },
    { value: 'free', label: 'licenseTypes.free' },
    { value: 'paid', label: 'licenseTypes.paid' },
  ];

  const propsSanitized = omit(props, 't');

  return (
    <select {...propsSanitized}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const SportTypeSelect = (props) => {
  const { t } = props;

  const options = [
    { value: '', label: '' },
    { value: 'korfball', label: 'sportType.korfball' },
    { value: 'soccer', label: 'sportType.soccer' },
    { value: 'hockey', label: 'sportType.hockey' },
    { value: 'handball', label: 'sportType.handball' },
    { value: 'tennis', label: 'sportType.tennis' },
  ];

  const propsSanitized = omit(props, 't');

  return (
    <select {...propsSanitized}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

// TODO move up the dependency tree
const ProductsSelect = (props) => {
  const { t, value } = props;

  // TODO: fetch from backend
  const options = [
    {
      value: 'teamtv-video-analyse-default',
      label: 'productIds.teamtv-video-analyse-default',
    },
    {
      value: 'teamtv-free-exchange',
      label: 'productsIds.teamtv-free-exchange',
    },
    {
      value: 'teamtv-player-development-korfball-default',
      label: 'productIds.teamtv-player-development-korfball-default',
    },
    {
      value: 'teamtv-player-development-korfball-mental',
      label: 'productIds.teamtv-player-development-korfball-mental',
    },
    {
      value: 'teamtv-player-development-korfball-highperf',
      label: 'productIds.teamtv-player-development-korfball-highperf',
    },
    {
      value: 'teamtv-player-development-korfball-demo',
      label: 'productIds.teamtv-player-development-korfball-demo',
    },
    {
      value: 'footballvision-player-development-soccer-prehighperf',
      label: t(
        'productIds.footballvision-player-development-soccer-prehighperf'
      ),
    },
    {
      value: 'footballvision-player-development-soccer-highperf',
      label: 'productIds.footballvision-player-development-soccer-highperf',
    },
    {
      value: 'footballvision-player-development-soccer-demo',
      label: 'productIds.footballvision-player-development-soccer-demo',
    },
  ];

  const propsSanitized = omit(props, ['t', 'setFieldValue']);

  const handleChange = (changeEvt) => {
    const value = Array.from(changeEvt.target.options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    props.setFieldValue('productIds', value);
  };

  return (
    <select multiple={true} {...propsSanitized} onChange={handleChange}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const provisionClub = async (
  name,
  sportType,
  countryCode,
  licenseType,
  licenseStart,
  licenseEnd,
  productIds,
  firstTeamName,
  adminEmail
) => {
  const isoStartDate = licenseStart.utc().startOf('day').toISOString();
  const isoEndDate = licenseEnd.utc().endOf('day').toISOString();
  const tenant_ = await tenantCollection.create({
    name,
    sportType,
    countryCode,
  });
  const tenantId = tenant_.tenantId;
  const tenant = tenantCollection.get(tenantId);

  for (const productId of productIds) {
    await tenant.grantLicense({
      licenseType,
      productId,
      startDate: isoStartDate,
      endDate: isoEndDate,
    });
  }

  await ResourceGroupCollection.fetch();

  for (const resourceGroup of ResourceGroupCollection.toArray()) {
    if (resourceGroup.get('tenantId') === tenantId) {
      await addClubAdmin(adminEmail, resourceGroup);
      await addTeam(adminEmail, name + ' ' + firstTeamName, resourceGroup);

      return;
    }
  }
  throw 'Could not add admin and team';
};

const NewClubPage = withTranslation('module.reseller')((props) => {
  const { onAdd, t } = props;

  const onCancel = () => {
    gotoRoute('reseller.overview');
  };

  const handleSubmit = async (values, formikBag) => {
    await provisionClub(
      values.name,
      values.sportType,
      values.countryCode,
      values.licenseType,
      values.licenseStart,
      values.licenseEnd,
      values.productIds,
      values.firstTeamName,
      values.adminEmail
    );
    gotoRoute('reseller.overview');
    // onAdd({
    //   name: values.name,
    //   sport: values.sport,
    //   countryCode: values.countryCode,
    //   license: {
    //     type: values.licenseType,
    //     start: values.licenseStart,
    //     end: values.licenseEnd,
    //     dataAmount: values.licenseDataAmount,
    //     productIds: values.productIds,
    //   },
    //   firstTeamName: values.firstTeamName,
    //   adminEmail: values.adminEmail,
    // });
  };

  const requiredFields = [
    'name',
    'sportType',
    'countryCode',
    'licenseType',
    'licenseStart',
    'licenseEnd',
    // 'licenseDataAmount',
    'adminEmail',
  ];

  const validate = (values) => {
    let errors = {};

    for (const key of requiredFields) {
      if (!values[key] || values[key] === '') {
        errors[key] = true;
      }
    }

    if (!values.productIds.length) {
      errors['productIds'] = true;
    }

    return errors;
  };

  const pageProps = {
    title: 'title',
    breadcrumbs: [
      { path: '/', title: 'Home' },
      { path: '/reseller', title: 'title' },
      { title: 'addClub' },
    ],
  };

  return (
    <Page {...pageProps}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          submitCount,
          errors,
          setFieldValue,
          onReset,
        }) => {
          // TODO: move to up in the dependency tree
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };

          return (
            <form onSubmit={handleSubmit}>
              {/* <div className="modal-header">
              <h5 className="modal-title">{t('title')}</h5>
            </div> */}
              <div className="xxmodal-body">
                <div className="row">
                  <div className="col-12">
                    <div className={`form-group ${validClassName('name')}`}>
                      <label htmlFor="name">{t('name')}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={values.name}
                        autoComplete="new-password"
                        data-lpignore="true"
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('sportType')}`}
                    >
                      <label htmlFor="sportType">{t('sportType')}</label>
                      <SportTypeSelect
                        type="text"
                        className="form-control"
                        name="sportType"
                        t={t}
                        value={values.sportType}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('countryCode')}`}
                    >
                      <label htmlFor="countryCode">{t('country')}</label>
                      <CountrySelect
                        type="text"
                        className="form-control"
                        name="countryCode"
                        value={values.countryCode}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('licenseType')}`}
                    >
                      <label htmlFor="licenseType">{t('licenseType')}</label>
                      <LicenseTypeSelect
                        t={t}
                        className="form-control"
                        name="licenseType"
                        value={values.licenseType}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('productIds')}`}
                    >
                      <label htmlFor="licenseType">{t('products')}</label>
                      <ProductsSelect
                        t={t}
                        className="form-control"
                        name="productIds"
                        value={values.productIds}
                        setFieldValue={setFieldValue}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('licenseStart')}`}
                    >
                      <label htmlFor="licenseStart">{t('licenseStart')}</label>
                      <DatePicker
                        id="new-clubs-license-start"
                        name="licenseStart"
                        className="form-control-datepicker"
                        selected={values.licenseStart}
                        onChange={(value) =>
                          setFieldValue('licenseStart', value)
                        }
                        autoComplete="off"
                        isClearable={false}
                        placeholderText={t('licenseStart')}
                        popperPlacement="top"
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('licenseEnd')}`}
                    >
                      <label htmlFor="licenseEnd">{t('licenseEnd')}</label>
                      <DatePicker
                        id="new-clubs-license-end"
                        name="licenseEnd"
                        className="form-control-datepicker"
                        selected={values.licenseEnd}
                        onChange={(value) => setFieldValue('licenseEnd', value)}
                        autoComplete="off"
                        isClearable={false}
                        placeholderText={t('licenseEnd')}
                        popperPlacement="top"
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  {/*<div className="col-12">*/}
                  {/*<div*/}
                  {/*className={`form-group ${validClassName(*/}
                  {/*'licenseDataAmount'*/}
                  {/*)}`}*/}
                  {/*>*/}
                  {/*<label htmlFor="licenseDataAmount">*/}
                  {/*{t('licenseDataAmount')}*/}
                  {/*</label>*/}
                  {/*<input*/}
                  {/*type="number"*/}
                  {/*min="0"*/}
                  {/*className="form-control"*/}
                  {/*name="licenseDataAmount"*/}
                  {/*value={values.licenseDataAmount}*/}
                  {/*onChange={handleChange}*/}
                  {/*/>*/}
                  {/*<div className="invalid-feedback">*/}
                  {/*{t('common.form:required-field')}*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  {/*</div>*/}
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName(
                        'firstTeamName'
                      )}`}
                    >
                      <label htmlFor="firstTeamName">
                        {t('firstTeamName')}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstTeamName"
                        autoComplete="new-password"
                        data-lpignore="true"
                        value={values.firstTeamName}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('adminEmail')}`}
                    >
                      <label htmlFor="adminEmail">{t('adminEmail')}</label>
                      <input
                        type="email"
                        className="form-control"
                        name="adminEmail"
                        data-lpignore="true"
                        value={values.adminEmail}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-actions">
                <button type="submit" className="btn btn-primary">
                  {t('addClub')}
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onCancel}
                >
                  {t('common:cancel')}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Page>
  );
});

export { NewClubPage };
