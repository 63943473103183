import better_day from './backgroundmusic/better-day-186374-loop.aac';
import drive_breakbeat from './backgroundmusic/drive-breakbeat-173062-loop.aac';
import upbeat from './backgroundmusic/upbeat-music.aac';

const AvailablegetBackgroundMusicTracks = [
  { value: 'upbeat', label: 'Upbeat', url: upbeat },
  { value: 'better_day', label: 'Better Day', url: better_day },
  {
    value: 'drive_breakbeat',
    label: 'Drive Breakbeat',
    url: drive_breakbeat,
  },
];

export const getBackgroundMusicTracks = (filterArray: string[]) => {
  const backgroundMusicTracks = filterArray.map((track) => {
    return AvailablegetBackgroundMusicTracks.find(
      (_track) => _track.value === track
    );
  });
  return backgroundMusicTracks;
};

export const getBackgroundMusicTrack = (trackId: string) => {
  return AvailablegetBackgroundMusicTracks.find(
    (_track) => _track.value === trackId
  );
};
