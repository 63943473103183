import React, { Fragment, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
// import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import { NavDropDown } from 'lib/NavDropdown';
import { useObservationContext } from '../../../../domain/ObservationLogger/ObservationContextProvider';
import './index.scss';

import logoUrl from 'img/icons/club_placeholder.svg';
import { observer, Observer } from 'mobx-react';

// export { LiveObservationGoalView } from './goal-event';

export const LiveObservationView: React.FunctionComponent<{
  actionsPrimary: ActionButtonProps[];
  actionsSecondary: ActionButtonProps[];
  // observationContext: ObservationContext;
}> = ({ actionsPrimary = [], actionsSecondary = [], children }) => {
  const { clock } = useObservationContext('clock');
  const disabled = clock.state === 'STOPPED';

  return (
    <div className="live-observation-view">
      <LiveObservationHeaderView />
      <div
        className={`live-observation-view-inner ${disabled ? 'disabled' : ''}`}
      >
        {children}
      </div>
      {/* TODO extract */}
      <div className="live-observation-view-actions">
        <div className="live-observation-view-actions-primary">
          {actionsPrimary.map((action, idx) => (
            <ActionButton key={idx} {...action} />
          ))}
        </div>
        <div className="live-observation-view-actions-secondary">
          {actionsSecondary.map((action, idx) => (
            <ActionButtonSecondary key={idx} {...action} />
          ))}
        </div>
      </div>
    </div>
  );
};

export interface ActionButtonProps {
  active: boolean;
  disabled: boolean;
  className: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => {};
}

const ActionButton: React.FunctionComponent<ActionButtonProps> = ({
  active = false,
  disabled = false,
  className = 'btn btn-default live-observation-view-action',
  onClick = console.debug,
  children,
}) => {
  const classNames = addActiveClass({ className, active });

  return (
    <button
      type="button"
      className={classNames}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const LiveObservationHeaderView: React.FunctionComponent = () => {
  const {
    clock,
    teams,
    score,
    gameState,
    observationLogger,
  } = useObservationContext(
    'clock',
    'teams',
    'score',
    'gameState',
    'observationLogger'
  );

  return (
    <div className="live-observation-view-header">
      <div className="score-board">
        <div
          className="i-team match-team-logo home"
          style={{
            backgroundImage: `url(${teams.homeTeam.logoUrl})`,
          }}
        />
        <div className="name-possession home">
          <div className="name">{teams.homeTeam.label}</div>
          <div
            className={`possession ${
              gameState.possession === 'home' ? 'active' : ''
            }`}
          />
        </div>
        <div className="score home">{score.homeScore}</div>
        <div className="play-pause">
          {clock.state === 'STARTED' ? (
            <button
              type="button"
              onClick={() =>
                observationLogger.setPointObservation('TIME:PAUSE', {}, 'Pause')
              }
              className="score-board-btn"
            >
              <i className="i-pause i-light" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() =>
                observationLogger.setPointObservation(
                  'TIME:RESUME',
                  {},
                  'Resume'
                )
              }
              disabled={clock.state === 'STOPPED'}
              className="score-board-btn"
            >
              <i className="i-play i-light" />
            </button>
          )}
        </div>
        <div className="score away">{score.awayScore}</div>
        <div className="name-possession away">
          <div className="name">{teams.awayTeam.label}</div>
          <div
            className={`possession ${
              gameState.possession === 'away' ? 'active' : ''
            }`}
          />
        </div>
        <div
          className="i-team match-team-logo home"
          style={{
            backgroundImage: `url(${teams.awayTeam.logoUrl})`,
          }}
        />
      </div>
      <div className="timer">
        <div className="seconds">1s</div>
        <button
          type="button"
          onClick={() =>
            observationLogger.setPointObservation(
              'TIME:CORRECTION',
              {
                seconds: -1,
              },
              '-1 seconde'
            )
          }
          className="timer-btn decrease"
        >
          &minus;
        </button>
        <div className="time">{clock.time}</div>
        <button
          type="button"
          onClick={() =>
            observationLogger.setPointObservation(
              'TIME:CORRECTION',
              {
                seconds: 1,
              },
              '+1 seconde'
            )
          }
          className="timer-btn increase"
        >
          +
        </button>
        <div className="seconds">1s</div>
      </div>
    </div>
  );
};

// {
/* <div className="match-team-name text-left hidden-md">
  {sportingEvent.homeTeam.label}
</div>
<div
  className="match-team-logo hidden-md"
  style={{
    background: `url(${sportingEvent.homeTeam.logoUrl})`,
  }}
/>
<div className="match-team-name text-left show-md">
  {sportingEvent.homeTeam.shortLabel}
</div> */
// }

const addActiveClass = ({
  className = '',
  active = false,
}: {
  className: string;
  active: boolean;
}): string => {
  return `${className} ${active ? 'active' : ''}`;
};

// NICETOHAVE: refactor - maybeAddClass('active')({ className, active });

const ActionButtonSecondary: React.FunctionComponent<ActionButtonProps> = props => (
  <ActionButton
    {...props}
    className="btn btn-outline-dark live-observation-view-action"
  />
);
