import { Page } from '../../lib/Page';
import React, { useEffect, useState } from 'react';
import { OverviewItem } from '../../lib/OverviewItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Loading from '../../lib/Loading';
import { Session } from '../../domain/Session';
import { AuthAwareAdapterProxy } from '../../utils/AuthAwareAdapterProxy';
import ResourceGroupCollection from '../../domain/ResourceGroup';
import './training-schedule.scss';
import { useQuery, useQueryClient } from 'react-query';
import { NoResults } from '../../lib/PlaceHolder';
import noresultImg from '../../img/no_matches.svg';
import sweetAlert from 'lib/sweetAlert';
import clubPlaceholderIcon from 'img/icons/club_placeholder.svg';
import { AddOrEditSchedule } from './form/addOrEditSchedule';
import {
  addScheduleEntry,
  editScheduleEntry,
  fetchSchedule,
  removeScheduleEntry,
} from './schedule';
import { Tooltip } from '../../lib/Tooltip';

export const TrainingScheduleOverview = () => {
  const [teams, setTeams] = useState();
  const { loading, data: schedule } = useQuery(['schedule'], fetchSchedule);
  const queryClient = useQueryClient();

  useEffect(() => {
    (async () => {
      const currentSession = Session.current();

      const adminResourceGroupId = currentSession.getClubAdminResourceGroupId();

      const _teams = await AuthAwareAdapterProxy.withUseResourceGroup(
        adminResourceGroupId,
        async () => {
          await ResourceGroupCollection.fetchIfEmpty();
          setTeams(ResourceGroupCollection.sorted());
        }
      );
    })();
  }, []);

  const teamValues = teams?.map((team) => {
    return { label: team.get('name'), value: team.get('resourceGroupId') };
  });
  const invalidateAndClose = () => {
    queryClient.invalidateQueries(['schedule']);
    setModalComponent();
  };
  const { t } = useTranslation('module.schedule');

  const primaryAction = {
    title: t('addSchedule'),
    onClick: () => {
      setModalComponent(
        <AddOrEditSchedule
          onClose={() => setModalComponent()}
          teamValues={teamValues}
          onSubmit={(values) =>
            addScheduleEntry(schedule, values, invalidateAndClose)
          }
        />
      );
    },
  };
  const pageProps = {
    title: t('title'),
    breadcrumbs: [{ path: '/', title: 'Home' }, { title: t('title') }],
    className: 'sportingEvent-overview',
    primaryAction: primaryAction,
  };
  const [modalComponent, setModalComponent] = useState();

  if (!teams || loading) {
    return <Loading type={'fullscreen'} />;
  }
  return (
    <Page {...pageProps}>
      {modalComponent}
      <View
        schedule={schedule}
        teamValues={teamValues}
        setModal={(v) => setModalComponent(v)}
      />
    </Page>
  );
};

const formatTeamName = (name) => {
  if (!name) {
    return 'not-set';
  }
  const _name = name.split(' ');
  return _name.slice(-1);
};

const View = ({ setModal, teamValues, schedule }) => {
  const queryClient = useQueryClient();

  const invalidateAndClose = () => {
    queryClient.invalidateQueries(['schedule']);
    setModal();
  };
  const { t } = useTranslation('module.schedule');

  useEffect(() => {
    // open modal when view is empty
    if (schedule?.entries.length === 0) {
      setModal(
        <AddOrEditSchedule
          onClose={() => setModal()}
          teamValues={teamValues}
          onSubmit={(v) => addScheduleEntry(schedule, v, () => setModal())}
        />
      );
    }
  }, []);

  return (
    <div className={'sportingEvent-overview__category overview-schedule'}>
      {schedule?.entries.map((scheduleEntry) => {
        const onEdit = (values) => {
          editScheduleEntry(
            schedule,
            values,
            scheduleEntry.scheduleEntryId,
            invalidateAndClose
          );
        };
        const team =
          teamValues.find((t) => t.value === scheduleEntry.resourceGroupId)
            ?.label ?? null;
        return (
          <div className="match-overview-item">
            <OverviewItem
              key={scheduleEntry.scheduleEntryId}
              itemDate={
                <ItemDate
                  dateStart={scheduleEntry.start}
                  dateEnd={scheduleEntry.end}
                  duration={
                    scheduleEntry?.tags?.automaticCameraConfig
                      ?.recordingDuration
                  }
                  repeat={scheduleEntry?.repeat}
                />
              }
              details={() => (
                <div className={'training-schedule-details'}>
                  {/*<IsAutomaticRecordingTooltip /> For future when non camera packages can record.*/}
                  <div className={'training-schedule-details__name'}>
                    {scheduleEntry.name}
                  </div>
                  <div className={'training-schedule-details__team'}>
                    <Tooltip tooltip={team}>
                      <img
                        style={{ width: '24px', marginRight: '0.25em' }}
                        src={clubPlaceholderIcon}
                      />
                      <span className={'d-md-none'}>
                        {formatTeamName(team)}
                      </span>
                    </Tooltip>
                    <span className={'d-none d-md-inline'}>{team}</span>
                  </div>
                </div>
              )}
              secondaryActions={[
                {
                  label: t('common:edit'),
                  onClick: () => {
                    setModal(
                      <AddOrEditSchedule
                        onClose={() => setModal()}
                        scheduleEntry={scheduleEntry}
                        teamValues={teamValues}
                        onSubmit={onEdit}
                      />
                    );
                  },
                  icon: 'edit',
                },
                {
                  label: t('common:delete'),
                  onClick: () => {
                    sweetAlert({
                      title: t('deleteConfirm'),
                      text: t('deleteConfirmMessage'),
                      dangerMode: true,
                      buttons: [t('common:cancel'), t('common:confirm')],
                    }).then((willDelete) => {
                      if (willDelete) {
                        removeScheduleEntry(
                          schedule,
                          scheduleEntry.scheduleEntryId,
                          () => queryClient.invalidateQueries(['schedule'])
                        );
                      }
                    });
                  },
                },
              ]}
            />
          </div>
        );
      })}
      {schedule?.entries.length === 0 && (
        <NoResults imgSrc={noresultImg} description={t('noResults')} />
      )}
    </div>
  );
};

const ItemDate = ({ dateStart, dateEnd, duration, repeat }) => {
  const startDate = moment(dateStart);
  const endDate = moment(dateEnd);

  const { t } = useTranslation('module.schedule');

  return (
    <div className="overview-item-date" style={{ position: 'relative' }}>
      <div className="overview-item-date-day-of-month">
        <Tooltip
          tooltip={t('weeklyOn', {
            day: startDate.format('dddd'),
            time: startDate.format('HH:mm'),
          })}
        >
          <i className={'i-refresh i-xs i-light'}></i>
        </Tooltip>
        {startDate.format('dd')}
      </div>
      <div className="overview-item-date-time">
        {startDate.format('HH:mm').replace(/\./g, '')}
      </div>
      {duration && (
        <div className="overview-item-date-time">
          {startDate
            .add(duration, 'minutes')
            .format('HH:mm')
            .replace(/\./g, '')}
        </div>
      )}
    </div>
  );
};
