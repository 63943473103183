import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ResponsiveSVG, Row, Column } from 'lib/responsive-svg';

import { Cell } from 'lib/Reporting/Cell';

import { HandballTeamField } from './team-field';
import { Table } from './table';

export const TeamDashboard = ({ teamReport, onClick, reportState }) => {
  const rowHeight = 510;

  const columns = [
    {
      key: 'shots_6_7m',
      label: '6-7m',
    },
    {
      key: 'shots_7_9m',
      label: '7-9m',
    },
    {
      key: 'shots_gt9m',
      label: '>9m',
    },
    {
      key: 'shots_all',
      label: 'tot',
    },
    {
      key: 'penalties',
      label: '7m',
    },
  ];

  const [rows, setRows] = useState(
    teamReport.playerReports.map(report => ({
      color: 'antiquewhite',
      icon: null,
      id: report.name, // TODO add report id
      label: report.name,
      data: report,
      selected: false,
    }))
  );

  const toggleSelectedRow = toggleRow => {
    const idx = rows.findIndex(row => row === toggleRow);
    const updated = [
      ...rows.slice(0, idx),
      { ...rows[idx], selected: !toggleRow.selected },
      ...rows.slice(idx + 1),
    ];

    setRows(updated);
  };

  const activeShots = rows => {
    const selectedRows = rows.filter(row => row.selected);
    // WARNING: reports don't seem to have an unique id and are therefore
    // filtered by name
    const activeFilter =
      selectedRows.length === 0
        ? () => true // all active when no rows selected
        : report => selectedRows.map(row => row.id).includes(report.name);

    return teamReport.playerReports
      .filter(activeFilter)
      .flatMap(report => report.shots);
  };

  return (
    <ResponsiveSVG
      width={1024}
      height={rowHeight}
      breakPoint={1024}
      rowHeight={rowHeight}
    >
      <Row top={0}>
        <Column height={512}>
          <g>
            <text x={20} y={20}>
              <NavLink to={'match'}>
                <tspan
                  fontSize="14"
                  fontWeight="400"
                  fill="#36A6DE"
                  textDecoration="underline"
                  // textAnchor="end"
                >
                  Terug naar overzicht
                </tspan>
              </NavLink>
            </text>
            <HandballTeamField
              shots={activeShots(rows)}
              onClick={onClick}
              x="0"
              y="80"
              width="512"
              height="430"
            />
          </g>
        </Column>
        <Column>
          <g>
            <Table
              transform={`translate(20, 80)`}
              title="Rendement per speler"
              columns={columns}
              rows={rows}
              onCellClick={onClick}
              onRowClick={toggleSelectedRow}
            />
          </g>
        </Column>
      </Row>
    </ResponsiveSVG>
  );
};
