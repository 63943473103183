import { ObservationInputTemplate } from '../ObservationInputTemplate';
import { createComponentFactoryFromTemplate } from '../components/DynamicObservationInput';
import { ObservationInputOptions, ObservationInput } from '../ObservationInput';

import previewImgSrc from './soccer.live.default.png';

const soccerObservationInputTemplate = new ObservationInputTemplate({
  buttons: {},
  hooks: {},
  namespace: 'module.observe.live.soccer',
  layout: `
        <table style={{marginTop: "50px", width: "100%", textAlign: "center"}}>
            <tbody>
            <tr>
                <td><Label color="rgb(94, 156, 211)" text={t('attack.label')} /></td>
                <td><Label color="rgb(252, 13, 27)" text={t('defence.label')} /></td>
                <td><Label color="rgb(252, 61, 202)" text={t('setPieces.label')} /></td>
                <td><Label color="rgb(166, 166, 166)" text={t('goals.label')} /></td>
                <td><Label color="rgb(255, 253, 56)" text={t('highlights.label')} /></td>
            </tr>
            <tr>
                <td><SimpleButton label={t('attack.shotGoal')} /></td>
                <td><SimpleButton label={t('defence.shotGoalOpponent')}/></td>
                <td><SimpleButton label={t('setPieces.for')} description={t('setPieces.descriptionFor')} /></td>
                <td><SimpleButton label={t('goals.for')} description={t('goals.descriptionFor')} observationCode="GOAL" attributes={{teamId: "{$.homeTeam.id}"}}  /></td>
                <td><SimpleButton label={t('highlights.summary')}  /></td>
            </tr>
            <tr>
                <td><SimpleButton label={t('attack.buildUpOwn')}/></td>
                <td><SimpleButton label={t('defence.defenceOwn')} /></td>
                <td><SimpleButton label={t('setPieces.against')} description={t('setPieces.descriptionAgainst')}/></td>
                <td><SimpleButton label={t('goals.against')} description={t('goals.descriptionAgainst')} observationCode="GOAL" attributes={{teamId: "{$.awayTeam.id}"}} /></td>
                <td />
            </tr>
            <tr>
                <td><SimpleButton label={t('attack.buildUpOpponent')} /></td>
                <td><SimpleButton label={t('defence.defenceOpponent')} /></td>
                <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
                <td><SimpleButton label={t('attack.opportunities')} /></td>
                <td><SimpleButton label={t('defence.opportunities')}  /></td>
                <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
                <td><SimpleButton label={t('attack.ballLoss')} /></td>
                <td><SimpleButton label={t('defence.ballInterception')}  /></td>
                <td colspan="3">&nbsp;</td>
            </tr>
            </tbody>
        </table>

    `,
});

const SoccerObservationInput = createComponentFactoryFromTemplate(
  soccerObservationInputTemplate
);
const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'soccer.live.default',
  version: 1,
  component: SoccerObservationInput,
  options,
  title: 'Soccer Live default',
  previewImgSrc,
});
