import { Model, Collection } from 'mobx-rest';
import { api } from 'utils/ApiClient';

class DevelopmentGoal extends Model {
  get primaryKey() {
    return 'developmentGoalId';
  }
}

class DevelopmentGoalCollection extends Collection {
  url() {
    return '/pdp/developmentGoals';
  }

  model() {
    return DevelopmentGoal;
  }

  ensureLoaded() {
    if (this.isEmpty()) this.fetch();
  }

  loading() {
    return this.isRequest('fetching');
  }

  fetch = async () => {
    const developmentGoals = await super.fetch();
    return developmentGoals.map(goal => ({
      ...goal,
      deadline: goal.deadline ? new Date(goal.deadline) : null,
    }));
  };

  delete = async developmentGoalId => {
    await api.delete(`${this.url()}/${developmentGoalId}`);
  };

  update = async (developmentGoalId, properties) => {
    await api.patch(`${this.url()}/${developmentGoalId}`, properties);
  };
}

const developmentGoalCollection = new DevelopmentGoalCollection();
window.developmentGoalCollection = developmentGoalCollection;

export default developmentGoalCollection;
