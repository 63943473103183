import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { observer, Observer } from 'mobx-react';

import { withTranslation, Trans } from 'react-i18next';

import logger from '../../../utils/Logger';
import Loading from 'lib/Loading';

import { EventPublisher } from 'utils/EventPublisher';
import { Page } from 'lib/Page';

import { Error } from 'lib/PlaceHolder';
import { getTutorials } from '../../../lib/tutorials';
import { ReactPlayer } from '../../../lib/ReactPlayer';
import ReactJWPlayer from 'react-jw-player';
import { TutorialCard, TutorialsView } from '../../home/components/tutorials';
import uuidv4 from 'uuid/v4';

const Tutorial = withTranslation('module.play')(
  observer(
    class Match extends Component {
      constructor() {
        super();

        this.tutorial = null;
        this.tutorials = null;
        this.playerId = uuidv4();
        this.dataState = observable('loading');
      }

      componentWillMount() {
        this.loadData();
      }

      get tutorialId() {
        return this.props.match.params.tutorialId;
      }
      get otherTutorials() {
        return getTutorials().filter(
          (t) => t.videoId !== this.props.match.params.tutorialId
        );
      }

      handleChange() {
        this.tutorial = getTutorials(this.tutorialId);
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          this.tutorial = getTutorials(this.tutorialId);
          this.tutorials = this.otherTutorials;
          this.dataState.set('loaded');

          EventPublisher.dispatch('VIDEO_PLAYED');
        } catch (e) {
          console.log(e);
          logger.error(e, {
            transactionName: 'Unable to load Tutorials',
          });
          this.dataState.set('error');
        }
      });

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        if (this.props.match.params.tutorialId !== this.tutorial.videoId) {
          this.handleChange();
        }

        const actions = [];

        const pageProps = {
          noTopPadding: false,
          title: this.tutorial.label,
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { path: '/', title: 'Tutorials' },
            {
              title: this.tutorial.label,
            },
          ],
        };
        return (
          <Page {...pageProps}>
            <div className="layout-personal tutorials">
              <div className={'row'}>
                <div className="col-lg-9">
                  <ReactJWPlayer
                    isAutoPlay={true}
                    playerId={this.playerId}
                    playerScript="https://cdn.jwplayer.com/libraries/iJqhMiBf.js"
                    file={this.tutorial.videoUrl}
                    customProps={{
                      controls: true,
                      displaytitle: false,
                      playbackRateControls: true,
                      nextUpDisplay: false,
                      playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2, 4],
                    }}
                  />
                </div>
                <div className="col-lg-3 d-none d-lg-block">
                  <div className="block">
                    <div className="pill">Other tutorials</div>
                    {this.otherTutorials.map((tutorial) => {
                      return <TutorialCard tutorial={tutorial} />;
                    })}
                  </div>
                </div>

                <div className="col-12 d-lg-none">
                  <div className="spacer"></div>
                  <div className="block">
                    <div className="pill">Other tutorials</div>
                    <TutorialsView tutorials={this.otherTutorials} />
                  </div>
                </div>
              </div>
            </div>
          </Page>
        );
      }
    }
  )
);

export { Tutorial };
