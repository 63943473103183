import { Trans, useTranslation } from 'react-i18next';
import { Page } from '../../lib/Page';
import { ViewFilters } from 'lib/Page/viewFilters';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getComments } from '../../domain/Observation';
import api from '../../utils/ApiClient';
import { get } from '../../lib/notifications';
import Loading from '../../lib/Loading';
import playlist from './index';
import { PlaylistCard } from './card/Card';
import { playlistCollection } from '../../domain/Playlist';
import logger from '../../utils/Logger';
import { confirmModal } from '../../lib/Confirmation';
import { Session } from '../../domain/Session';

import { NoResults, Error, NoPermissions } from 'lib/PlaceHolder';

import noresultImg from 'img/no_playlists.svg';
import { getMembers } from '../../domain/ResourceGroup';
import debounce from 'lodash/debounce';
import { Modal } from '../../lib';
import { PlaylistSetACL } from './PlaylistSetACL';
import { SetResourceACL } from '../../lib/SetResourceACL';

export const PlaylistView = () => {
  const { t } = useTranslation('module.playlist');

  const [activeFilters, setActiveFilters] = useState({
    search: '',
    button: '',
    buttonLabel: 'all',
    generatedOther: false,
    generatedOwn: false,
    generated: false,
  });

  const manageFilters = (changeSet) => {
    setActiveFilters((old) => ({ ...old, ...changeSet }));
  };

  const pageProps = {
    title: t('title'),
    breadcrumbs: [
      { path: '/', title: 'Home' },
      {
        title: t('title'),
      },
    ],
  };

  const viewfilters = [
    {
      type: 'search',
      placeholder: t('searchPlaceholder'),
      // onSubmit: value => {
      //   manageFilters({ search: value });
      // },
      onChange: debounce((value) => {
        manageFilters({ search: value });
      }, 200),
    },
    {
      type: 'buttonGroup',
      buttons: [
        {
          label: 'All',
          onClick: () => {
            manageFilters({ button: '', buttonLabel: 'All' });
          },
          isActive: activeFilters.button === '',
        },
        {
          label: t('groupNameOwn'),
          onClick: () => {
            manageFilters({ button: 'own', buttonLabel: t('groupNameOwn') });
          },
          isActive: activeFilters.button === 'own',
        },
        {
          label: t('groupNameOther'),
          onClick: () => {
            manageFilters({
              button: 'other',
              buttonLabel: t('groupNameOther'),
            });
          },
          isActive: activeFilters.button === 'other',
        },
        {
          label: t('showGenerated'),
          type: 'checkBox',
          onClick: () => {
            manageFilters({
              generated: !activeFilters.generated,
            });
          },
          isActive: activeFilters.generated,
        },
      ],
    },
  ];

  return (
    <Page
      {...pageProps}
      className="page--playlist-view"
      viewFilters={viewfilters}
    >
      <div className={'playlist-view'}>
        <ViewContent filters={activeFilters} />
      </div>
    </Page>
  );
};

const ViewContent = ({ filters }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('module.playlist');

  const fetchDefault = async () => {
    let { data } = await api.get('playlists');
    return data ? data : [];
  };
  const fetchGenerated = async (type) => {
    let response = await api.get('generatedPlaylists', {
      params: { type: type },
    });
    return response?.data ? response.data : [];
  };
  const defaultPlaylists = useQuery(['playlist', 'default'], fetchDefault, {
    placeholderData: [],
  });

  const [modalComponent, setModalComponent] = useState();

  const generatedPlaylistsCustom = useQuery(
    ['playlist', 'generatedCustom'],
    () => fetchGenerated('custom'),
    {
      placeholderData: [],
      enabled: filters.generated,
      refetchOnReconnect: false,
      keepPreviousData: true,
      refetchOnMount: false,
    }
  );
  const generatedPlaylistsOwn = useQuery(
    ['playlist', 'generatedOwn'],
    () => fetchGenerated('own'),
    {
      placeholderData: [],
      enabled: filters.generated,
      refetchOnReconnect: false,
      keepPreviousData: true,
      refetchOnMount: false,
    }
  );
  const fetchMembers = async () => {
    const members = await getMembers();
    return members.map((member) => {
      return {
        id: member.userId,
        display: `${member.firstName} ${member.lastName}`,
        profilePictureUrl: member.profilePictureUrl
          ? member.profilePictureUrl
          : 'default',
      };
    });
  };

  const { data: members } = useQuery(['members'], fetchMembers, {
    placeholderData: [],
    refetchOnWindowFocus: 'always',
  });

  const actions = [
    // User actions per video.
    {
      // Delete Playlist Function
      key: 'delete',
      onClick: function (playlist) {
        const deleteAction = async () => {
          // Create action for confirmation window
          try {
            await api.delete('playlists/' + playlist.playlistId);
            await queryClient.invalidateQueries([['playlist', 'default']]);
          } catch (e) {
            logger.error(e, {
              transactionName: 'Unable to Remove Playlist',
            });
          }
        };
        // Open confirmation window
        confirmModal({
          title: t('module.playlist:delete'),
          body: (
            <Trans
              i18nKey="module.playlist:deleteConfirm"
              values={{ label: playlist.name }}
            >
              Are you sure that you want to remove{' '}
              <strong>{playlist.name}</strong>?
            </Trans>
          ),
          actionLabel: t('common:confirm'),
          actionHandler: deleteAction,
        });
        return;
      },
      label: t('common:delete'),
      permission: function (playlist) {
        if (
          ['trainer', 'admin'].includes(Session.current().currentRoleName()) &&
          Session.current()
            .currentPrivileges()
            .hasPrivilege('playlist:destroy') &&
          playlist.groupId !== 'own' &&
          playlist.playlistId.indexOf('generated') === -1
        ) {
          return 'group'; // user is trainer or admin and playlist is not generated.
        }
        if (playlist.groupId == 'own') {
          return 'own'; // user is creator of playlist.
        }
      },
    },
    {
      key: 'changePermissions',
      label: t('common:changePermissions'),
      onClick: (playlist) => {
        setModalComponent(
          <SetResourceACL
            resourceType={'playlist'}
            resourceId={playlist.playlistId}
            onClose={() => {
              setModalComponent(<></>);
              queryClient.invalidateQueries(['playlist', 'default']);
            }}
            individualUsers={true}
            initialACL={playlist._metadata?.acl}
          />
        );
      },
      permission: (playlist) => {
        return (
          Session.current()
            .currentPrivileges()
            .hasPrivilege('resourceGroup:set-resource-acl') &&
          playlist.playlistId.indexOf('generated') === -1
        );
      },
    },
  ];
  if (members && !defaultPlaylists.isFetched) {
    return <Loading />;
  } else {
    let playlists = filters.generated
      ? [
          ...defaultPlaylists.data,
          ...(generatedPlaylistsOwn.isFetched &&
          generatedPlaylistsOwn.isSuccess &&
          generatedPlaylistsOwn?.data
            ? generatedPlaylistsOwn.data
            : []),
          ...(generatedPlaylistsCustom.isFetched &&
          generatedPlaylistsCustom.isSuccess &&
          generatedPlaylistsCustom?.data
            ? generatedPlaylistsCustom.data
            : []),
        ]
      : defaultPlaylists.data;

    playlists = playlists
      .filter((playlist) => {
        let found = [];
        if (filters.button.length) {
          found.push(playlist.groupId === filters.button);
        }
        if (filters.search.length) {
          found.push(
            playlist.name
              .toLowerCase()
              .indexOf(filters.search.toLowerCase()) !== -1
          );
        }
        return found.length === 0 || found.every((e) => e); // Check for all filters to be true (additive filtering)
      })
      .sort((a, b) => a.created.localeCompare(b.created))
      .reverse();

    const Errors = () => {
      const error = defaultPlaylists.isError
        ? t('title')
        : generatedPlaylistsCustom.isError || generatedPlaylistsOwn.isError
        ? t('generated')
        : null;
      if (error) {
        return (
          <div
            className={
              'playlist__error userlogin__warning userlogin__warning--error'
            }
          >
            <span>{t('error', { type: error })}</span>
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <>
        <Errors />
        {modalComponent}
        {playlists.length > 0 &&
          playlists.map((playlist) => {
            return (
              <div key={playlist.id} className={'playlist-view__card'}>
                <PlaylistCard
                  actions={actions}
                  playlist={playlist}
                  members={members}
                />
              </div>
            );
          })}
        {playlists.length === 0 && (
          <div className="playlistview__empty view--empty">
            <NoResults imgSrc={noresultImg} description={''} />
            <div className={'empty-feedback'}>
              {t('notFound')}
              {filters.search && (
                <span className={'search-string'}>
                  {' '}
                  {t('notFoundSearch')} <strong>"{filters.search}"</strong>
                </span>
              )}
              {filters.button && (
                <span>
                  {' '}
                  {t('notFoundButton')} <strong>{filters.buttonLabel}</strong>
                </span>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
};
