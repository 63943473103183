import React, { useEffect, useState } from 'react';
import videoCollection from '../../../domain/Video';
import Loading from '../../../lib/Loading';
import ReactJWPlayer from 'react-jw-player';
import { useTranslation } from 'react-i18next';

export const VersionVideoPlayer = ({ version, sportingEvent }) => {
  // Set retries and interval for retrying when still encoding.
  const MAX_RETRIES = 60;
  const RETRY_EVERY = 10 * 1000;
  // Every 10 sec for 10 minutes.

  const [video, setVideo] = useState();
  const [retries, setretries] = useState(0);

  const { t } = useTranslation('module.development.tasks');

  useEffect(() => {
    (async () => {
      await videoCollection.remove([sportingEvent.mainVideoId()]); // make sure we get this one fresh
      const _video = await videoCollection.getOrFetch(
        sportingEvent.mainVideoId()
      );
      setVideo(_video);

      if ((!_video || _video?.state !== 'ready') && retries <= MAX_RETRIES) {
        // Empty video retry (Video is still processing)
        setTimeout(
          () => {
            setretries((o) => o + 1);
          },
          retries === 0 ? 0 : RETRY_EVERY // Instant on first load with encoding video
        );
      }
    })();
  }, [retries]);
  if (!video || !video.has('mediaUrl')) {
    if (video && video.state !== 'ready') {
      return (
        <div className={'processing-video'}>
          <Loading type={'spinner'} color={'white'} size={30} />
          <p>{t('videoProcessing')}</p>
        </div>
      );
    }
    if (!!video) {
      //loading video
      return (
        <div className={'processing-video'}>
          <Loading type={'spinner'} color={'white'} size={30} />
        </div>
      );
    } else {
      // Video has been removed or faulty file has been uploaded.
      return <div className={'processing-video'}></div>;
    }
  } else {
    return (
      <ReactJWPlayer
        playerId={`version-${version.versionId}`}
        isAutoPlay={false}
        playerScript="https://cdn.jwplayer.com/libraries/iJqhMiBf.js"
        playlist={[{ file: video?.mediaUrl }]}
      />
    );
  }
};
