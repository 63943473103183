/* TeamTV step 2 */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import endpoint from 'utils/ApiEndpoint';
import Select from 'react-select';
import Loading from 'lib/Loading';
import { Error } from '../index';

export const ClubForm = ({ stepHandler, formData, setFormData }) => {
  const [value, setValue] = useState({
    tenantName: formData.tenantName,
    place: formData.place,
    sportType: formData.sportType,
    sportTypeLabel: formData.sportTypeLabel,
  });
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();
  const [byPassError, setByPassError] = useState(false);

  const checkValue = async () => {
    setIsChecking(true);

    if (!byPassError) {
      await fetch(
        `${endpoint}/anonymous/tenantCreationRequest/checkTenantAvailability?name=${value.tenantName}&sportType=${value.sportType}&language=${formData.countryCode}`
      ).then(async (response) => {
        const data = await response.json();
        if (!data) {
          setError(true);
          setIsChecking(false);
          return;
        }
        setFormData((o) => {
          return { ...o, ...value };
        });
        stepRef.current.classList.add('animateOut');
        setIsChecking(false);
        setTimeout(() => stepHandler.next(), 500);
      });
      setIsChecking(false);
      return;
    }

    setIsChecking(false);
    setFormData((o) => {
      return { ...o, ...value };
    });

    stepRef.current.classList.add('animateOut');
    setTimeout(() => stepHandler.next(), 500);
  };
  const stepRef = useRef();

  const sportTypeOptions = [
    { value: 'korfball', label: t('sportType:korfball') },
    { value: 'soccer', label: t('sportType:soccer') },
    { value: 'hockey', label: t('sportType:hockey') },
    { value: 'handball', label: t('sportType:handball') },
    { value: 'tennis', label: t('sportType:tennis') },
    { value: 'basketball', label: t('sportType:basketball') },
    { value: 'rugby', label: t('sportType:rugby') },
    { value: 'other', label: t('sportType:other') },
  ];

  return (
    <form
      data-test-id={'step-club'}
      ref={stepRef}
      className={'userlogin-form-step'}
      onSubmit={(e) => {
        e.preventDefault();
        if (!isChecking) {
          checkValue();
        }
      }}
    >
      <h1>{t('createClubTitle')}</h1>
      <p>{t('createClubBody')}</p>
      <div className={'userlogin-form-input'}>
        <label htmlFor={'place'}></label>
        <Select
          options={sportTypeOptions}
          isClearable={false}
          placeholder={t('form.sportType')}
          className="userloginform__select"
          classNamePrefix="userloginform__select"
          defaultValue={sportTypeOptions.find(
            (r) => r.value === formData.sportType
          )}
          onChange={(option) =>
            setValue((o) => {
              return {
                ...o,
                ...{
                  sportType: option.value,
                  sportTypeLabel: option.value !== 'other' ? option.label : '',
                },
              };
            })
          }
        />
      </div>
      {value.sportType === 'other' && (
        <div className={'userlogin-form-input fadeInDown'}>
          <input
            type={'text'}
            id={'club'}
            placeholder={t('form.sportTypeOther')}
            value={value.sportTypeLabel}
            required="required"
            name="sportTypeOther"
            onChange={(e) => {
              e.persist();
              setError(false);
              setValue((o) => {
                return { ...o, ...{ sportTypeLabel: e.target.value } };
              });
            }}
          />
          <label htmlFor={'sportTypeOther'}>{t('form.sportTypeOther')}</label>
        </div>
      )}
      <div className={'userlogin-form-input'}>
        <input
          type={'text'}
          id={'club'}
          placeholder={t('form.clubNamePlaceholder')}
          value={value.tenantName}
          required="required"
          onChange={(e) => {
            e.persist();
            setError(false);
            setValue((o) => {
              return { ...o, ...{ tenantName: e.target.value } };
            });
          }}
        />
        <label htmlFor={'userlogin-email'}>
          {t('form.clubNamePlaceholder')}
        </label>
      </div>
      {error && (
        <Error
          title={t('form.clubnameInUseError')}
          body={t('form.clubnameInUseErrorBody')}
          action={
            <>
              <a
                target="_blank"
                rel="noopener"
                href={t('form.existingClubAccountLink')}
              >
                {t('form.existingClubAccount2')}
              </a>
              <a
                style={{ marginTop: '0.5em' }}
                rel="noopener"
                href={`mailto:${
                  window.isSkillReflect
                    ? 'support@skillreflect.com'
                    : 'support@teamtv.nl'
                }`}
              >
                {t('errorPage:contact')}
              </a>
              <a
                style={{ marginTop: '0.5em' }}
                href="#"
                onClick={() => {
                  setByPassError(true);
                  setError(false);
                }}
              >
                {t('form.clubnameInUseContinue')}
              </a>
            </>
          }
        />
      )}
      <div className={'userlogin-form-input'}>
        <input
          type={'text'}
          id={'place'}
          required="required"
          placeholder={t('form.clubLocationPlaceholder')}
          autoComplete={'address-level2'}
          value={value.place}
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, ...{ place: e.target.value } };
            });
          }}
        />
        <label htmlFor={'place'}>{t('form.clubLocationPlaceholder')}</label>
      </div>

      <div className={'userlogin-form-actions'}>
        <button
          type="submit"
          className={'btn btn-primary'}
          disabled={
            !value.tenantName || !value.place || !value.sportType || error
          }
        >
          {t('form.nextStep')}
          {isChecking && (
            <Loading type={'spinner'} color={'white'} size={16}></Loading>
          )}
        </button>
      </div>
    </form>
  );
};
