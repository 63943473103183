import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { exerciseCollection } from 'domain/Exercise';
import { trainingTemplateCollection } from 'domain/TrainingTemplate';

import { TrainingTemplateOverviewPage } from './overview';
import { EditTrainingTemplatePage } from './edit';
import { injectLoader } from 'lib/InjectLoader';

const MODULE_NAME = 'training-templates';

const trainingTemplateLoader = async props => {
  const trainingTemplate = await trainingTemplateCollection.getOrFetch(
    props.match.params.trainingTemplateId
  );
  await exerciseCollection.fetchIfEmpty();
  const exercises = exerciseCollection.toArray();
  exercises.sort((a, b) => {
    const c = (a.tags.category || '').localeCompare(b.tags.category || '');
    if (c === 0) {
      return a.title.localeCompare(b.title);
    } else {
      return c;
    }
  });

  return {
    trainingTemplate,
    exercises,
  };
};

const trainingTemplateModule = new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('overview', TrainingTemplateOverviewPage, 'overview'),
    // new RouteExact(
    //   'edit/:trainingTemplateId',
    //   injectLoader(exerciseLoader, EditTrainingTemplatePage),
    //   'edit'
    // ),
    new RouteExact(
      'edit/:trainingTemplateId',
      injectLoader(trainingTemplateLoader, EditTrainingTemplatePage),
      'edit'
    ),
    new RouteRedirect('', 'overview'),
  ]
);

export default trainingTemplateModule;
