import { Model, PersistentCollection } from './PersistentCollection';

class Conversation extends Model {
  get primaryKey() {
    return 'conversationId';
  }

  get conversationId() {
    return this.get('conversationId');
  }

  get comments() {
    return this.toJS().comments;
  }

  get created() {
    return this.get('created');
  }

  get conversationStarterUser() {
    return this.toJS().conversationStarterUser;
  }

  get tags() {
    return this.toJS().tags;
  }

  addComment(comment) {
    return this.rpc('addComment', { comment });
  }
}

class ConversationCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Conversation;
  }
}

export { ConversationCollection };
