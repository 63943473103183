import React, { Component, Fragment, useState, useEffect } from 'react';
import HotKey from 'react-shortcut';
import { action, toJS } from 'mobx';
import uuidv4 from 'uuid/v4';

import downloadIcon from 'img/icons/download_white.svg';

import { formatTime } from 'lib/FormattedTime';
import { VideoClock } from 'domain/ObservationLogger/Clock';
import { ReactPlayer } from 'lib/ReactPlayer';
import { logEvent } from 'utils/EventLogger';
import {
  additionalDuration,
  baseDurationAfter,
  baseDurationBefore,
} from 'lib/Config';

import videoCollection from 'domain/Video';

import { DownloadWaitTime } from 'lib/LicenseLimitEnforcing';
import { Session } from '../../domain/Session';
import { VideoAngleSelector } from '../VideoAngles';

const getElementWidth = (element) => {
  const cs = getComputedStyle(element);

  const paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
  const paddingY = parseFloat(cs.paddingTop) + parseFloat(cs.paddingBottom);

  const borderX =
    parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
  const borderY =
    parseFloat(cs.borderTopWidth) + parseFloat(cs.borderBottomWidth);

  // Element width and height minus padding and border
  const elementWidth = element.offsetWidth - paddingX - borderX;
  const elementHeight = element.offsetHeight - paddingY - borderY;
  return elementWidth;
};

class VideoFragmentsCreator {
  constructor(video) {
    this.video = video;
    this._videoFragments = [];
  }

  _setVideoFragments(videoFragments) {
    this._videoFragments = videoFragments;
  }

  getVideoFragments() {
    return this._videoFragments;
  }
}

class ObservationsToVideoFragmentsCreator extends VideoFragmentsCreator {
  setVideoFragmentsFromObservations(observations) {
    const videoFragments = [];
    for (const observation of observations) {
      let startTime = observation.startTime,
        endTime = observation.endTime;

      if (startTime === endTime) {
        // negative and videoDuration passing values will be corrected in baseclass
        startTime = startTime - baseDurationBefore;
        endTime = endTime + baseDurationAfter;
      } else {
        startTime -= additionalDuration;
        endTime += additionalDuration;
      }

      const fragment = this.video.getVideoFragment(
        startTime,
        endTime,
        observation.description // TODO: show proper name based on observation{code, attributes}
      );
      videoFragments.push(fragment);
    }
    this._setVideoFragments(videoFragments);
  }
}

class VideoPlayer extends Component {
  static defaultProps = {
    buttons: [],
  };

  constructor(props) {
    super(props);
    this.videoPlayerWrapperRef = React.createRef();

    this.playerId = this.props?.playerId ?? `player-${uuidv4()}`;
    this.state = { downloadWait: false };

    this.resizePlayerHandler = this.resizePlayerHandler.bind(this);

    this.onPlayerReady = this.onPlayerReady.bind(this);
  }

  resizePlayerHandler() {
    const videoPlayerWrapper = this.videoPlayerWrapperRef.current;

    let aspectRatio;
    if (this.props.media) {
      aspectRatio = this.props.media.width / this.props.media.height;
    } else {
      aspectRatio = 16 / 9;
    }

    if (!!this.props.resizeHandler) {
      const props = this.props.resizeHandler(aspectRatio);
      videoPlayerWrapper.style['min-height'] = 'unset';
      for (const key of Object.keys(props)) {
        videoPlayerWrapper.style[key] = `${props[key]}px`;
      }
      return;
    }

    const playerWidth = getElementWidth(videoPlayerWrapper);
    if (this.props.heightAttribute === 'height') {
      videoPlayerWrapper.style['height'] = `${playerWidth / aspectRatio}px`;
      videoPlayerWrapper.style['min-height'] = 'unset';
    } else {
      videoPlayerWrapper.style['max-height'] = `${playerWidth / aspectRatio}px`;
    }
  }

  componentDidMount() {
    this.resizePlayerHandler();
    window.addEventListener('resize', this.resizePlayerHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizePlayerHandler);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activePlayListItem !== this.props.activePlayListItem) {
      const player = window.jwplayer(this.playerId);
      if (player.getPlaylistIndex() !== this.props.activePlayListItem) {
        player.playlistItem(this.props.activePlayListItem);
      }
    }
  }

  onPlayerReady() {
    const { clock, videoFragments, buttons } = this.props;

    const playerRef = window.jwplayer(this.playerId);
    if (clock) {
      playerRef.on('playlistItem', ({ index }) => {
        clock.setOffset(videoFragments[index].startTime);
      });
      clock.attachToVideo(playerRef);
    }

    if (!!this.props.onPlaylistItem) {
      playerRef.on('playlistItem', ({ index }) => {
        this.props.onPlaylistItem(index);
      });
    }

    if (buttons.indexOf('download') !== -1) {
      playerRef.addButton(
        downloadIcon,
        'Download Video',
        () => {
          if (Session.current().isFeatureAvailable('instant-download')) {
            this.downloadCurrentVideoFragments();
          } else {
            playerRef.pause();
            this.setState({ downloadWait: true });
          }
        },
        'download'
      );
    }
    this.addLogging(playerRef, this.props.video, this.props.videoFragments);
    this.resizePlayerHandler();
  }

  addLogging(playerRef, video, videoFragments) {
    let currentVideoFragment;
    let _index;
    let eventsSent = [];
    playerRef.on('playlistItem', ({ index }) => {
      currentVideoFragment = videoFragments[index];
      eventsSent = [];
      _index = index;
    });

    const sendIfNew = (event, { viewable }) => {
      if (eventsSent.indexOf(event) === -1) {
        const [eventName, eventValue] = event.split(':');
        video = video || currentVideoFragment.getVideo();
        const videoFragmentTRN = `trn:videoFragment:${video.tenantId}:${video.videoId}:${currentVideoFragment.startTime}-${currentVideoFragment.endTime}`;
        logEvent({
          eventName,
          eventValue,
          eventAttributes: {
            viewable,
            playlist: {
              currentItem: _index,
              totalItems: videoFragments.length,
            },
          },
          resourceTRN: videoFragmentTRN,
          resourceName: `${video.description} / ${currentVideoFragment.description}`,
        });
        eventsSent.push(event);
      }
    };

    playerRef.on('time', ({ duration, position, viewable }) => {
      const percentage = (position / duration) * 100;
      if (percentage > 95) {
        sendIfNew('play:95', { viewable });
      } else if (percentage > 50) {
        sendIfNew('play:50', { viewable });
      } else if (percentage > 5) {
        sendIfNew('play:5', { viewable });
      } else if (percentage > 0) {
        sendIfNew('play:start', { viewable });
      }
    });
  }

  downloadCurrentVideoFragments() {
    let video = videoCollection.get(
      window.jwplayer().getPlaylistItem().videoFragment.videoId
    );
    this.startDownload(video, this.props.videoFragments, this.props.title);
  }

  async startDownload(video, videoFragments, title) {
    let downloadUrl;
    if (videoFragments.length === 0) {
      return;
    } else if (
      videoFragments.length === 1 &&
      video.isVideoFragmentFullVideo(videoFragments[0])
    ) {
      Session.current().user.logAction('DownloadStarted', {
        videoId: video.videoId,
        fullVideo: true,
        title,
      });

      downloadUrl = await video.getDownloadUrl();
    } else {
      Session.current().user.logAction('DownloadStarted', {
        videoId: video.videoId,
        fullVideo: false,
        fragmentCount: videoFragments.length,
        title,
      });

      downloadUrl = await video.getObservationsDownloadUrl(
        title,
        videoFragments
      );
    }
    window.location.href = downloadUrl;
  }

  render() {
    const {
      videoFragments,
      buttons,
      repeat,
      clock,
      video,
      extra,
      showPlaylist,
      disableHotKeys,
      multiCam,
      id = 'video-player-wrapper',
      hideFeatures = false,
    } = this.props;

    const playlistItems = videoFragments.map((videoFragment) => {
      if (
        (!video || videoFragment.videoId !== video.id) &&
        !videoFragment.getVideo
      ) {
        throw 'Make sure videos are loaded!';
      }
      let returnObject = {
        file: (
          video || videoFragment.getVideo()
        ).getVideoFragmentUrlByVideoFagment(videoFragment),
        title: `${formatTime(videoFragment.startTime)}: ${
          videoFragment.description
        }`,
        videoFragment: videoFragment,
      };

      if (returnObject.file.indexOf('blob') !== -1) {
        returnObject.type = 'mp4';
        returnObject.sourcetype = 'mp4';
      }
      return returnObject;
    });

    return (
      <div id={id} ref={this.videoPlayerWrapperRef}>
        {clock && !disableHotKeys && (
          <Fragment>
            <HotKey
              keys={['space']}
              onKeysPressed={() => clock.togglePlayState()}
            />
            <HotKey
              keys={['left']}
              onKeysPressed={() => clock.relativeSeek(-5)}
            />
            <HotKey
              keys={['right']}
              onKeysPressed={() => clock.relativeSeek(5)}
            />
            <HotKey
              keys={['down']}
              onKeysPressed={() => clock.relativeSeek(-2)}
            />
            <HotKey keys={['up']} onKeysPressed={() => clock.relativeSeek(2)} />
          </Fragment>
        )}

        <ReactPlayer
          onReady={this.onPlayerReady}
          playerId={this.playerId}
          playlistItems={playlistItems}
          showPlaylist={showPlaylist}
          repeat={repeat}
          multiCam={multiCam}
          hideFeatures={hideFeatures}
        />
        {extra}
        {this.state.downloadWait && (
          <DownloadWaitTime
            time={60}
            completeCallback={() => this.downloadCurrentVideoFragments()}
            closeCallback={() => this.setState({ downloadWait: false })}
          />
        )}
      </div>
    );
  }
}

const ObservationLogVideoPlayer = (props) => {
  let { video, observations, multiCam, playerId, ...videoPlayerProps } = props;

  if (!observations || !observations.length) {
    observations = [
      {
        startTime: 0,
        endTime: video.duration,
      },
    ];
  }

  const converter = new ObservationsToVideoFragmentsCreator(video);
  converter.setVideoFragmentsFromObservations(observations);
  const videoFragments = converter.getVideoFragments();
  return (
    <>
      <VideoPlayer
        videoFragments={videoFragments}
        video={video}
        playerId={playerId || `player-${uuidv4()}`}
        multiCam={multiCam ?? video.getOtherAngles()}
        {...videoPlayerProps}
      />
    </>
  );
};

export { ObservationLogVideoPlayer, VideoPlayer };
