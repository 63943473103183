import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';
import { observable, extendObservable, action } from 'mobx';
import ponyfill from 'fetch-ponyfill';

import logger from '../utils/Logger';
import apiEndpoint from '../utils/ApiEndpoint';
import auth from '../utils/Authentication';

class RemoteCommandDispatcherState {
  constructor() {
    this._state = observable('ready');
    this._queueLength = observable(0);

    extendObservable(this, {
      get state() {
        return this._state.get();
      },
      get queueLength() {
        return this._queueLength.get();
      },
    });
  }

  startProcessing = action(() => {
    if (this.state !== 'ready') {
      this._state.set('retrying');
    } else {
      this._state.set('processing');
    }
  });

  setFailed = action(() => {
    this._state.set('failed');
  });

  setReady = action(() => {
    this._state.set('ready');
  });

  setOffline = action(() => {
    this._state.set('offline');
  });

  setQueueLength = action(queueLength => {
    this._queueLength.set(queueLength);
  });
}

class APIClient {
  constructor(apiEndpoint) {
    this.apiEndpoint = apiEndpoint;
  }

  buildRequestOptions(options) {
    let HeadersConstructor = Headers;
    const { headers, data, ...otherOptions } = options;

    if (typeof HeadersConstructor === 'undefined') {
      HeadersConstructor = ponyfill().Headers;
    }

    const baseHeaders = {};
    if (data) {
      baseHeaders['Content-Type'] = 'application/json';
    }

    const headersObject = new HeadersConstructor(
      Object.assign(baseHeaders, headers)
    );

    return {
      ...otherOptions,
      headers: headersObject,
      body: data ? JSON.stringify(data) : null,
    };
  }
  send(method, path, data, resourceGroupId) {
    const headers = {
      Authorization: `Bearer ${auth.getAccessToken()}`,
    };

    if (resourceGroupId) {
      headers['X-Resource-Group-ID'] = resourceGroupId;
    }

    let rejectPromise;

    const options = {
      method: method,
      credentials: 'omit',
      headers: headers,
      data: data,
    };

    let fetchMethod = fetch;
    if (typeof fetchMethod === 'undefined') {
      fetchMethod = ponyfill().fetch;
    }

    const url = this.apiEndpoint + path;
    const xhr = fetchMethod(url, this.buildRequestOptions(options));

    const promise = new Promise((resolve, reject) => {
      rejectPromise = reject;

      xhr.then(resolve, error => {
        return reject(error);
      });
    });

    const abort = () => rejectPromise('abort');

    return { abort, promise };
  }

  authenticate() {
    if (auth.isAuthenticated) {
      return Promise.resolve(true);
    } else {
      return auth.auth.renewToken();
    }
  }
}

class RemoteCommandDispatcher {
  static _instance = null;

  constructor() {
    this._requests = [];
    this._state = new RemoteCommandDispatcherState();
    this._processingRequests = false;

    this.apiClient = new APIClient(apiEndpoint);

    window.addEventListener('online', () => {
      // user went back online, try to send stuff
      this.processRequests();
    });
  }

  state() {
    return this._state;
  }

  logFailedRequest = asyncAction(function*(request, response) {
    let responseBody;
    try {
      responseBody = yield response.text();
    } catch (e) {
      responseBody = undefined;
    }

    const extra = {
      request,
      response: {
        status: response.status,
        url: response.url,
        body: responseBody,
      },
    };

    logger.warning('RemoteCommand.Dispatch failed', {
      extra,
    });
  });

  processRequests = asyncAction(function*() {
    if (this._processingRequests) {
      return;
    }

    this._processingRequests = true;
    this._state.startProcessing();
    let failed = false,
      offline = false;
    while (this._requests.length > 0) {
      const request = this._requests.shift();

      const { promise, abort } = this.apiClient.send(
        request.method,
        request.path,
        request.body,
        request.resourceGroupId
      );

      // TODO: make sure we can detect network error and other errors
      try {
        const response = yield promise;
        if (!response.ok) {
          failed = true;
          this._requests.push(request);

          // this should never happen, log it!
          this.logFailedRequest(request, response);
          break;
        }
      } catch (e) {
        logger.warning('Request failed, you are probably offline', {
          error: e,
        });

        // TODO: make sure other stays the same...
        this._requests.unshift(request);
        offline = true;
        break;
      }
    }

    this._state.setQueueLength(this._requests.length);
    if (offline) {
      this._state.setOffline();
    } else if (failed) {
      this._state.setFailed();
    } else {
      this._state.setReady();
    }

    this._processingRequests = false;
  });

  dispatch(command, requests) {
    this._requests.push(...requests);
    this.processRequests();
    //console.warn('!!!Background processing is disabled!!!');
  }

  static instance() {
    if (this._instance === null) {
      this._instance = new RemoteCommandDispatcher();
    }
    return this._instance;
  }
}

const RemoteCommandDispatcherStateIndicator = observer(
  class RemoteCommandDispatcherStateIndicator extends Component {
    render() {
      const remoteCommandDispatcherState = RemoteCommandDispatcher.instance().state();
      if (
        remoteCommandDispatcherState.state === 'ready' ||
        remoteCommandDispatcherState.state === 'processing'
      ) {
        return null;
      }

      const retry = () => {
        RemoteCommandDispatcher.instance().processRequests();
      };
      const state = remoteCommandDispatcherState.state;

      let description;
      if (state === 'failed') {
        description =
          'Er is een fout opgetreden bij het verwerken van je invoer. De systeembeheerder is op de hoogte gesteld.';
      } else {
        description = (
          <span>
            Je bent momenteel niet met het internet verbonden. Hierdoor zijn de
            laatste {remoteCommandDispatcherState.queueLength} ingevoerde acties
            niet geupload.{' '}
            <span className="clickable underline" onClick={retry}>
              Probeer opnieuw te verbinden
            </span>
            .
          </span>
        );
      }

      return (
        <div className={`network-toast ${state}`}>
          <div className="relative width-100 height-100">
            <div className="flex-content">
              <div className="network-toast-text relative">{description}</div>
            </div>
          </div>
        </div>
      );
    }
  }
);

export { RemoteCommandDispatcher, RemoteCommandDispatcherStateIndicator };
