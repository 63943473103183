import React, { useState, useEffect } from 'react';

import { ObservationLogVideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { TimeSlider } from 'lib/TimeSlider/index';

export interface ObservationVideoTimeAttributes {
  startTime: number;
  endTime: number;
}

export const ObservationDetailsVideoTime = ({
  observation,
  video,
  onChange,
}: {
  observation: Observation;
  video: {};
  onChange: (value: ObservationVideoTimeAttributes) => void;
}) => {
  const [startTime, setStartTime] = useState<number>(
    observation.displayStartTime
  );
  const [endTime, setEndTime] = useState<number>(observation.displayEndTime);

  const handleChange = ([start, end]: [number, number]) => {
    setStartTime(start);
    setEndTime(end);

    onChange({
      startTime: start,
      endTime: end,
    });
  };

  useEffect(() => {
    setStartTime(observation.displayStartTime);
    setEndTime(observation.displayEndTime);
  }, [observation]);

  return (
    <div>
      <div className="form-group">
        <ObservationLogVideoPlayer
          repeat
          video={video}
          observations={[
            {
              startTime,
              endTime,
            },
          ]}
          heightAttribute="height"
        />
      </div>
      <div className="form-group">
        <TimeSlider
          min={Math.max(0, observation.displayStartTime - 20)}
          max={observation.displayEndTime + 20}
          startTime={observation.displayStartTime}
          endTime={observation.displayEndTime}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
