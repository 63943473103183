import React from 'react';

const NoPdpData = ({ t, createNewGoal }) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-5 col-md-5 text-center">
        <div className="card">
          <div className="card-body">
            <h4>{t('noDevelopmentGoalsYetHeader')}</h4>
            <p>{t('noDevelopmentGoalsYetBody')}</p>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginBottom: '1rem' }}
              onClick={createNewGoal}
            >
              + {t('addFirstDevelopmentGoal')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoPdpData;
