import {
  AbsoluteFill,
  interpolate,
  OffthreadVideo,
  useCurrentFrame,
} from 'remotion';

import video from './intro.mp4';
import zIndexes from '../../helpers/zIndexes.json';

export const EhtvIntro: React.FC = ({ durationInFrames, clip }) => {
  const frame = useCurrentFrame();
  const totalDuration = durationInFrames;

  const opacity = interpolate(
    frame,
    [totalDuration * 0.8, totalDuration],
    [1, 0],
    {
      extrapolateRight: 'clamp',
    }
  );
  return (
    <AbsoluteFill
      style={{
        opacity: opacity,
        zIndex: zIndexes.intro,
      }}
    >
      <OffthreadVideo src={video} />
    </AbsoluteFill>
  );
};

export const EhtvOutro: React.FC = ({ durationInFrames, clip }) => {
  const frame = useCurrentFrame();

  const opacity = interpolate(frame, [0, 0.1 * durationInFrames], [0, 1], {
    extrapolateRight: 'clamp',
  });
  return (
    <AbsoluteFill
      style={{
        opacity: opacity,
        zIndex: zIndexes.outro,
      }}
    >
      <OffthreadVideo src={video} />
    </AbsoluteFill>
  );
};

export const introConfig = {
  name: {
    en: 'EHTV Intro',
    nl: 'EHTV Intro',
    de: 'EHTV Intro',
  },
  duration: 5,
  offset: {
    end: 2,
  },
  options: {},
  defaults: {},
  thumbnail:
    "data:image/svg+xml,%3Csvg viewBox='0 0 2796 1920' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_153_6' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='2796' height='1920'%3E%3Crect width='2796' height='1920' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_153_6)'%3E%3Crect width='2796' height='1920' fill='%23393939'/%3E%3Cpath d='M3449.42 1214.56C3527.44 1510.09 3566.45 1657.85 3485.15 1739.15C3403.85 1820.45 3256.09 1781.44 2960.56 1703.42L1934.93 1432.64C1636.11 1353.74 1486.69 1314.29 1456.54 1202.58C1426.39 1090.86 1535.66 981.589 1754.2 763.046L2509.05 8.2008C2727.59 -210.342 2836.86 -319.613 2948.58 -289.459C3060.29 -259.305 3099.74 -109.891 3178.64 188.935L3449.42 1214.56Z' fill='%23525ECA'/%3E%3Cpath d='M3841.42 1102.56C3919.44 1398.09 3958.45 1545.85 3877.15 1627.15C3795.85 1708.45 3648.09 1669.44 3352.56 1591.42L2326.93 1320.64C2028.11 1241.74 1878.69 1202.29 1848.54 1090.58C1818.39 978.86 1927.66 869.589 2146.2 651.046L2901.05 -103.799C3119.59 -322.342 3228.86 -431.613 3340.58 -401.459C3452.29 -371.305 3491.74 -221.891 3570.64 76.9354L3841.42 1102.56Z' fill='%23343D8E'/%3E%3Crect x='830' y='864' width='1136' height='96' fill='%23D9D9D9'/%3E%3Crect x='937' y='1080' width='922' height='64' fill='%23D9D9D9'/%3E%3C/g%3E%3C/svg%3E%0A",
  removable: false,
};

export const outroConfig = {
  name: {
    en: 'EHTV Outro',
    nl: 'EHTV Outro',
    de: 'EHTV Outro',
  },
  duration: 6,
  offset: {
    start: 1,
  },
  options: {},
  defaults: {},
  thumbnail:
    "data:image/svg+xml,%3Csvg viewBox='0 0 2796 1920' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_153_6' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='2796' height='1920'%3E%3Crect width='2796' height='1920' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_153_6)'%3E%3Crect width='2796' height='1920' fill='%23393939'/%3E%3Cpath d='M3449.42 1214.56C3527.44 1510.09 3566.45 1657.85 3485.15 1739.15C3403.85 1820.45 3256.09 1781.44 2960.56 1703.42L1934.93 1432.64C1636.11 1353.74 1486.69 1314.29 1456.54 1202.58C1426.39 1090.86 1535.66 981.589 1754.2 763.046L2509.05 8.2008C2727.59 -210.342 2836.86 -319.613 2948.58 -289.459C3060.29 -259.305 3099.74 -109.891 3178.64 188.935L3449.42 1214.56Z' fill='%23525ECA'/%3E%3Cpath d='M3841.42 1102.56C3919.44 1398.09 3958.45 1545.85 3877.15 1627.15C3795.85 1708.45 3648.09 1669.44 3352.56 1591.42L2326.93 1320.64C2028.11 1241.74 1878.69 1202.29 1848.54 1090.58C1818.39 978.86 1927.66 869.589 2146.2 651.046L2901.05 -103.799C3119.59 -322.342 3228.86 -431.613 3340.58 -401.459C3452.29 -371.305 3491.74 -221.891 3570.64 76.9354L3841.42 1102.56Z' fill='%23343D8E'/%3E%3Crect x='830' y='864' width='1136' height='96' fill='%23D9D9D9'/%3E%3Crect x='937' y='1080' width='922' height='64' fill='%23D9D9D9'/%3E%3C/g%3E%3C/svg%3E%0A",
  removable: false,
};
