export const historyManager = {
  set: (key, highlightVideoId, content) => {
    localStorage.setItem(`${key}-${highlightVideoId}`, JSON.stringify(content));
  },
  undo: (highlightVideoId, content) => {
    if (!historyManager.has('undo', highlightVideoId)) {
      return content;
    }
    const prevContent = JSON.parse(
      localStorage.getItem(`undo-${highlightVideoId}`)
    );
    localStorage.setItem(`undo-${highlightVideoId}`, JSON.stringify(content));
    return prevContent;
  },
  has: (key, highlightVideoId) => {
    return localStorage.getItem(`${key}-${highlightVideoId}`);
  },
};
