import api from '../../utils/ApiClient';
import { AuthAwareAdapterProxy } from '../../utils/AuthAwareAdapterProxy';
import { Session } from '../../domain/Session';

export const addScheduleEntry = async (
  schedule,
  values,
  callback = () => {}
) => {
  const newEntry = remapValues(values);
  schedule.entries.push(newEntry);
  await putSchedule(schedule);

  callback();
};

const remapValues = (values) => {
  return {
    tags: { automaticCameraConfig: values.automaticCameraConfig },
    start: values.start.toISOString(),
    end: values.end.toISOString(),
    resourceGroupId: values.resourceGroupId,
    sportingEventType: values.sportingEventType,
    // repeat: values.repeat, Field is unexpected
    name: values.name,
  };
};

export const editScheduleEntry = async (
  schedule,
  values,
  scheduleEntryId,
  callback
) => {
  schedule.entries.forEach((scheduleEntry, index) => {
    if (scheduleEntry.scheduleEntryId === scheduleEntryId) {
      schedule.entries[index] = {
        scheduleEntryId: scheduleEntryId,
        ...remapValues(values),
      };
    }
  });
  await putSchedule(schedule);
  callback();
};
export const removeScheduleEntry = async (
  schedule,
  scheduleEntryId,
  callback = () => {}
) => {
  schedule.entries = schedule.entries.filter(
    (scheduleEntry) => scheduleEntry.scheduleEntryId !== scheduleEntryId
  );
  await putSchedule(schedule);
  callback();
};

const putSchedule = async (schedule) => {
  const currentSession = Session.current();
  const adminResourceGroupId = currentSession.getClubAdminResourceGroupId();
  const response = await AuthAwareAdapterProxy.withUseResourceGroup(
    adminResourceGroupId,
    async () => {
      return await api.put('/automaticCamera/schedule', schedule);
    }
  );
  return response;
};

export const fetchSchedule = async () => {
  const currentSession = Session.current();

  const adminResourceGroupId = currentSession.getClubAdminResourceGroupId();
  const response = await AuthAwareAdapterProxy.withUseResourceGroup(
    adminResourceGroupId,
    async () => {
      return await api.get('/automaticCamera/schedule');
    }
  );

  return response.data;
};
