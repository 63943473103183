import { Model } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';
import videoCollection from './Video';

class Exercise extends Model {
  get primaryKey() {
    return 'exerciseId';
  }

  get exerciseId() {
    return this.get('exerciseId');
  }

  get title() {
    return this.get('title');
  }

  get exerciseTemplate() {
    return this.get('exerciseTemplate');
  }

  get content() {
    return this.get('content');
  }

  get videoId() {
    return this.get('videoId');
  }

  get tags() {
    return this.has('tags') ? this.get('tags') : {};
  }

  hasVideo() {
    return this.has('videoId');
  }

  getVideo() {
    if (!this.hasVideo() || !this.videoId) {
      return null;
    }
    return videoCollection.get(this.videoId);
  }

  async deleteVideo() {
    if (this.hasVideo()) {
      await this.rpc('deleteVideo', { videoId: this.videoId });
      this.set({ videoId: null });
    }
  }

  setTitle(title, refresh = true) {
    return this.rpc('setTitle', { title }).then(() => refresh && this.fetch());
  }

  setVariables(variables, refresh = true) {
    return this.rpc('setVariables', { variables }).then(
      () => refresh && this.fetch()
    );
  }

  setVideoId(videoId, refresh = true) {
    return this.rpc('setVideoId', { videoId }).then(
      () => refresh && this.fetch()
    );
  }

  setImage(image, refresh = true) {
    return this.rpc('setVideoId', { image }).then(
      () => refresh && this.fetch()
    );
  }

  initVideoUpload(files) {
    return this.rpc('initVideoUpload', { files });
  }
}

class ExerciseCollection extends PersistentCollection {
  url() {
    return '/exercises';
  }

  model() {
    return Exercise;
  }

  addExercise(title, exerciseTemplateId = 'exercise-template-sv') {
    return this.create(
      {
        title,
        exerciseTemplateId,
      },
      { optimistic: false }
    );
  }
}

console.log('init exercise collection');
const exerciseCollection = new ExerciseCollection();
window.exerciseCollection = exerciseCollection;

export { exerciseCollection };
