import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ModalView } from './modal-view';

const IntroView = withTranslation('module.dataSync')(
  ({ itemCount, onSave, onCancel, t }) => (
    <ModalView onSave={onSave} onCancel={onCancel} onSaveText={t('introSave')}>
      <div className="row">
        <div className="col-12">
          <p>{t('introContent', { itemCount })}</p>
        </div>
      </div>
    </ModalView>
  )
);

export { IntroView };
