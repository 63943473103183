class TourHistory {
  hasSeen(tour) {
    const items = (localStorage.getItem('tourHistory') || '').split(',');
    return items.indexOf(tour.key) !== -1;
  }

  seen(tour) {
    if (this.hasSeen(tour)) return;

    const items = (localStorage.getItem('tourHistory') || '').split(',');
    items.push(tour.key);
    localStorage.setItem('tourHistory', items.join(','));
  }
}

export default new TourHistory();
