import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import { trainingTemplateCollection } from 'domain/TrainingTemplate';

import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Notification } from 'lib/Notification';

import { gotoPath, gotoRoute } from 'modules/route';
import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';

import { NoResults } from 'lib/PlaceHolder';

import { themes } from './themes';

import './index.scss';

export const TrainingTemplateOverviewPage = withTranslation(
  'module.trainingTemplate'
)(
  observer(
    class TrainingTemplateOverviewPage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          yield trainingTemplateCollection.fetchIfEmpty();
          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Training templates',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      async createNewTrainingTemplate() {
        const trainingTemplateTitle = prompt('Titel van training?');
        if (!!trainingTemplateTitle) {
          const trainingTemplate =
            await trainingTemplateCollection.addTrainingTemplate(
              trainingTemplateTitle
            );
          gotoPath(`edit/${trainingTemplate.trainingTemplateId}`);
        }
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <div>Error...</div>;
        }

        const pageProps = {
          title: 'Training Templates',
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              title: 'Training Templates',
            },
          ],
          primaryAction: {
            title: 'New Training Template',
            onClick: this.createNewTrainingTemplate,
          },
        };

        const themeLabel = (themeId) => {
          const theme = themes.find((theme) => theme.id === themeId);
          if (!!theme) {
            return theme.label;
          }
        };

        const trainingTemplates = trainingTemplateCollection.toArray();
        trainingTemplates.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });

        return (
          <Page {...pageProps}>
            {trainingTemplates.length === 0 ? (
              <NoResults />
            ) : (
              <table className="table training-templates-overview">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Theme</th>
                    <th># Exercises</th>
                  </tr>
                </thead>
                <tbody>
                  {trainingTemplates.map((template) => {
                    return (
                      <tr
                        key={template.trainingTemplateId}
                        className="training-template"
                        onClick={() =>
                          gotoPath(`edit/${template.trainingTemplateId}`)
                        }
                      >
                        <td>{template.title}</td>
                        <td>{template.categoryId}</td>
                        <td>{themeLabel(template.themeId)}</td>
                        <td>{template.exercises.length}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Page>
        );
      }
    }
  )
);
