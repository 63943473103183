import { ITableProps, kaReducer } from 'ka-table';

const addNewRowReducer = (prevState: ITableProps, action: any) => {
  if (['ShowNewRow', 'SaveNewRow', 'HideNewRow'].includes(action.type)) {
    prevState.columns = prevState.columns.map(column => {
      if (column.key === ':add') {
        return {
          ...column,
          style: {
            ...column.style,
            width: action.type === 'ShowNewRow' ? '200px' : '0px',
          },
        };
      }
      return column;
    });
  }
  return kaReducer(prevState, action);
};

export default addNewRowReducer;
