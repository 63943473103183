import React from 'react';
import { withTranslation } from 'react-i18next';

const EventInput = withTranslation('module.observe.live')(
  ({ actionHandler, homeTeamName, awayTeamName, t }) => {
    const eventInputRow = (id, label, actionName) => (
      <li>
        <div className="select-thumb" id={id} />
        <div className="select-name" onClick={() => actionHandler(actionName)}>
          {label}
        </div>
      </li>
    );
    return (
      <div className="event-input-view">
        <div className="row">
          <div className="col-4">
            <b>{t('correctionDescription')}</b>
          </div>
          <div className="col-4">
            <b>{t('refDecisionDescription')}</b>
          </div>
          <div className="col-4">
            <b>{t('otherDescription')}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <ul className="invoer-select">
              {eventInputRow(
                'goal-home',
                t('goalMissed', { teamName: `${homeTeamName}` }),
                'goal-home'
              )}
              {eventInputRow(
                'goal-away',
                t('goalMissed', { teamName: `${awayTeamName}` }),
                'goal-away'
              )}
              {eventInputRow('fault-see', t('inputError'), 'observation-error')}
            </ul>
          </div>
          <div className="col-4">
            <ul className="invoer-select">
              {eventInputRow('yellow-card', t('yellowCard'), 'yellow-card')}
              {eventInputRow('red-card', t('redCard'), 'red-card')}
              {eventInputRow('throw-free', t('freeShotCause'), 'free-ball')}
              {eventInputRow('throw-punish', t('penaltyCause'), 'penalty-shot')}
            </ul>
          </div>
          <div className="col-4">
            <ul className="invoer-select">
              {eventInputRow(
                'toggle-play-direction',
                t('turnFieldDescription'),
                'togglePlayDirection'
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
);

export { EventInput };
