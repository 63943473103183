import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// import TeamCollection from '../../../domain/Team';
import { withTranslation } from 'react-i18next';

const GrowthAmbitionCreateSelectPeriod = withTranslation(
  'module.development.growthAmbition.create.selectPeriod'
)(
  observer(props => {
    const { onSubmit, onCancel, t, draftGrowthAmbition } = props;
    const { startDate, endDate } = draftGrowthAmbition;
    const initialValues = {
      startDate,
      endDate,
    };

    // NOTE: values aren't used because they aren't properly set when using the
    // Datepicker component.
    const validate = values => {
      console.warn('SKIPPING VALIDATION: TODO REMOVE!!:', errors);
      return {};

      let errors = {};

      if (!draftGrowthAmbition.startDate) {
        errors.startDate = true;
      }

      if (!draftGrowthAmbition.endDate) {
        errors.endDate = true;
      }

      return errors;
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          submitCount,
          errors,
          setFieldValue,
          onReset,
          validateForm,
        }) => {
          const validClassName = key => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };

          return (
            <form
              className="growth-ambition-create-select-period"
              onSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-12">
                  <p>{t('description')}</p>
                </div>
                <div className="col-12 col-md-3">
                  <div className={`form-group ${validClassName('startDate')}`}>
                    <DatePicker
                      id="new-match-scheduled-at"
                      name="startDate"
                      className="form-control-datepicker"
                      selected={startDate}
                      onChange={value => {
                        draftGrowthAmbition.setStartDate(value);
                        validateForm();
                      }}
                      autoComplete="off"
                      isClearable={false}
                      placeholderText={t('startDatePlaceholder')}
                      popperPlacement="top"
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className={`form-group ${validClassName('endDate')}`}>
                    <DatePicker
                      id="new-match-scheduled-at"
                      name="endDate"
                      className="form-control-datepicker"
                      selected={endDate}
                      onChange={value => {
                        draftGrowthAmbition.setEndDate(value);
                        validateForm();
                      }}
                      autoComplete="off"
                      isClearable={false}
                      placeholderText={t('endDatePlaceholder')}
                      popperPlacement="top"
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 offset-md-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={!startDate || !endDate}
                  >
                    {t('submit')}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={onCancel}
                  >
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  })
);

export { GrowthAmbitionCreateSelectPeriod };
