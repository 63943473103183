import React, { useRef, useState } from 'react';
import { Page } from '../../../lib/Page';

import UserInfo from '../components/user-info';
import { MenuNotificationContainer } from '../../../lib/notifications';
import MatchInfo from '../components/match-info';
import PlaylistInfo from '../components/playlist-info';
import { UserActionlist } from '../components/user-actionlist';
import { withTranslation } from 'react-i18next';
import ClubInfo from '../components/club-info';
import ReactConfetti from 'react-confetti';
import { Welcome } from '../components/welcome';
import { Onboarding } from '../components/onboarding';
import { TutorialsView } from '../components/tutorials';
import { getTutorials } from '../../../lib/tutorials';

const PersonalPage = withTranslation('module.home')(({ t }) => {
  const [feed, setFeed] = useState(false);

  const pageProps = {
    title: t('title'),
  };
  return (
    <Page className="page--homepage" {...pageProps}>
      <div className="layout-personal">
        <Welcome />
        <Onboarding />
        <div className="row">
          <div className="col-lg-4">
            <div className="block block-personal">
              <div className="pill">{t('myProfile')}</div>
              <UserInfo t={t} />
              <UserActionlist type={'player'} />
            </div>
            <div className="spacer"></div>
            <div className="block block-personal block--club">
              <div className="pill">{t('myTeam')}</div>
              <ClubInfo t={t} />
              <UserActionlist type={'club'} />
            </div>

            <div className="spacer d-lg-none"></div>
          </div>
          <div className="col-lg-8">
            <div className="block block-matches">
              <div className="pill">{t('matches')}</div>
              <MatchInfo />
            </div>
            <div className="spacer"></div>
            <div className="block block-matches">
              <PlaylistInfo />
            </div>
            <div className="spacer"></div>
            {getTutorials().length > 0 && (
              <div className="block block-matches">
                <div className="pill">Tutorials</div>
                <TutorialsView tutorials={getTutorials()} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
});

export default PersonalPage;
