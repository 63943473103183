import { Tour } from '../Tour';

import { matchRuleShort } from 'utils/matchRuleShort';

export default new Tour({
  key: 'homePage',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return matchRuleShort(path, '/home/me');
  },
  steps: (currentSession) => {
    const _steps = [];

    _steps.push({
      target: '.page-header-knowledge-base',
      i18nKey: 'module.tours.homePage:step1Help',
    });
    return _steps;
  },
});
