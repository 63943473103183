/* SkillReflect step 2 */
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import endpoint from 'utils/ApiEndpoint';
import Loading from 'lib/Loading';
import { Error } from '../index';

export const TenantForm = ({ stepHandler, formData, setFormData }) => {
  const [value, setValue] = useState({
    tenantName: formData.tenantName,
    place: formData.place,
    sportType: 'edu',
    phoneNumber: formData.phone,
  });
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();
  const [byPassError, setByPassError] = useState(false);

  const checkValue = async () => {
    setIsChecking(true);

    if (!byPassError) {
      await fetch(
        `${endpoint}/anonymous/tenantCreationRequest/checkTenantAvailability?name=${value.tenantName}`
      ).then(async (response) => {
        const data = await response.json();
        if (!data) {
          setError(true);
          setIsChecking(false);
          return;
        }
        setFormData((o) => {
          return { ...o, ...value };
        });
        stepRef.current.classList.add('animateOut');
        setIsChecking(false);
        setTimeout(() => stepHandler.next(), 500);
      });

      setIsChecking(false);
      return;
    }
    setIsChecking(false);
    setFormData((o) => {
      return { ...o, ...value };
    });

    stepRef.current.classList.add('animateOut');
    setTimeout(() => stepHandler.next(), 500);
  };
  const stepRef = useRef();

  return (
    <form
      data-test-id={'step-company'}
      ref={stepRef}
      className={'userlogin-form-step'}
      onSubmit={(e) => {
        e.preventDefault();
        if (!isChecking) {
          checkValue();
        }
      }}
    >
      <h1>{t('createClubTitle')}</h1>
      <p>{t('createClubBody')}</p>
      <div className={'userlogin-form-input'}>
        <input
          type={'text'}
          id={'club'}
          placeholder={t('form.clubNamePlaceholder')}
          value={value.tenantName}
          required="required"
          onChange={(e) => {
            e.persist();
            setError(false);
            setValue((o) => {
              return { ...o, ...{ tenantName: e.target.value } };
            });
          }}
        />
        <label htmlFor={'userlogin-email'}>
          {t('form.clubNamePlaceholder')}
        </label>
      </div>
      {error && (
        <Error
          title={t('form.clubnameInUseError')}
          body={t('form.clubnameInUseErrorBody')}
          action={
            <a
              href="#"
              onClick={() => {
                setByPassError(true);
                setError(false);
              }}
            >
              {t('form.clubnameInUseContinue')}
            </a>
          }
        ></Error>
      )}
      <div className={'userlogin-form-input'}>
        <input
          type={'text'}
          id={'place'}
          required="required"
          placeholder={t('form.clubLocationPlaceholder')}
          autoComplete={'address-level2'}
          value={value.place}
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, ...{ place: e.target.value } };
            });
          }}
        />
        <label htmlFor={'place'}>{t('form.clubLocationPlaceholder')}</label>
      </div>

      <div className={'userlogin-form-input'}>
        <input
          type={'text'}
          id={'phone'}
          required="required"
          placeholder={t('form.phoneNumberPlaceholder')}
          autoComplete={'address-level2'}
          value={value.phoneNumber}
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, ...{ phoneNumber: e.target.value } };
            });
          }}
        />
        <label htmlFor={'phone'}>{t('form.phoneNumber')}</label>
      </div>

      {/*{phoneRegExp.test(value.phone) ? 'VALID' : 'INVALID'}*/}

      <div className={'userlogin-form-actions'}>
        <button
          type="submit"
          className={'btn btn-primary'}
          disabled={!value.tenantName || !value.place || error}
        >
          {t('form.nextStep')}
          {isChecking && (
            <Loading type={'spinner'} color={'white'} size={16}></Loading>
          )}
        </button>
      </div>
    </form>
  );
};
