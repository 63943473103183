class MessageRegistry {
  constructor() {
    this.messages = [];
  }

  register(message) {
    this.messages.push(message);
  }

  getMessage(key) {
    return this.messages.find(({ key: key_ }) => key === key_);
  }
}

const registry = new MessageRegistry();

export default registry;
