import { DataType, SortDirection } from 'ka-table/enums';
import { Column } from 'ka-table/models';

const getLocalizedColumns = (t: Function): Column[] => [
  {
    key: ':delete',
    title: '',
    style: { width: '40px', textAlign: 'center' },
  },
  {
    key: 'developmentGoalId',
    title: t('columnId'),
    dataType: DataType.String,
    isEditable: false,
    style: { width: '50px' },
    sortDirection: SortDirection.Descend,
  },
  {
    key: 'markerLevel0',
    title: t('columnMarkerSet'),
    dataType: DataType.String,
    style: { width: '250px' },
  },
  {
    key: 'markerLevel1',
    title: t('columnSkill'),
    dataType: DataType.String,
    style: { width: '250px' },
  },
  {
    key: 'markerLevel2',
    title: t('columnMarker'),
    dataType: DataType.String,
    style: { width: '250px' },
  },
  {
    key: 'description',
    title: t('columnDescription'),
    dataType: DataType.String,
    style: { width: '250px' },
    editFormat: 'TEXTAREA',
  },
  {
    key: 'resultVisualisation',
    title: t('columnResultVisualisation'),
    dataType: DataType.String,
    style: { width: '250px' },
    editFormat: 'TEXTAREA',
  },
  {
    key: 'successCriteria',
    title: t('columnSuccessCriteria'),
    dataType: DataType.String,
    style: { width: '250px' },
    editFormat: 'TEXTAREA',
  },
  {
    key: 'weight',
    title: t('columnWeight'),
    dataType: DataType.Number,
    style: { width: '250px' },
    isResizable: false,
  },
  {
    key: 'deadline',
    title: t('columnDeadline'),
    isResizable: false,
    dataType: DataType.Date,
    style: { width: '250px' },
  },
  {
    key: 'status',
    title: t('columnStatus'),
    isResizable: false,
    dataType: DataType.String,
    style: { width: '250px' },
  },
  {
    key: 'experience',
    title: t('columnExperience'),
    dataType: DataType.String,
    style: { width: '250px' },
    editFormat: 'TEXTAREA',
  },
  {
    key: 'options',
    title: t('columnOptions'),
    dataType: DataType.String,
    style: { width: '250px' },
    editFormat: 'TEXTAREA',
  },
  {
    key: 'actions',
    title: t('columnActions'),
    dataType: DataType.String,
    style: { width: '250px' },
    editFormat: 'TEXTAREA',
  },
  {
    key: ':add',
    title: '',
    isResizable: false,
    style: { width: '0px' },
  },
];

export default getLocalizedColumns;
