import { EventHandler } from './EventHandler';

class EventBus {
  static dispatch(event) {
    console.log('dispatch e', JSON.stringify(event));
    EventHandler.handle(event);
  }
}

export { EventBus };
