import tourRegistry from '../TourRegistry';

import matchTour from './match';
import developmentOverviewTour from './team-development';
import videoObserveSoccerTour from './video-observe-soccer';
import videoObserveKorfballTour from './video-observe-korfball';
import videoObserveHockeyTour from './video-observe-hockey';
import dashboardKorfballTour from './dashboard-korfball';
import dashboardHockeyTour from './dashboard-hockey';
import liveObserveKorfballTour from './live-observe-korfball';
import homePageTour from './home';

tourRegistry.register(matchTour);
tourRegistry.register(videoObserveSoccerTour);
tourRegistry.register(videoObserveKorfballTour);
tourRegistry.register(videoObserveHockeyTour);
tourRegistry.register(dashboardKorfballTour);
tourRegistry.register(dashboardHockeyTour);
tourRegistry.register(liveObserveKorfballTour);
tourRegistry.register(developmentOverviewTour);
tourRegistry.register(videoObserveSoccerTour);
tourRegistry.register(homePageTour);
