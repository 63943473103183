import React, {
  Component,
  Fragment,
  Children,
  useState,
  useRef,
  useEffect,
} from 'react';

import Select from 'react-select';
import korfballPlaceholder from './korfball-placeholder.png';

import backgroundImageRightGreen from 'img/korfbalveld-rechts-groen-no-labels.svg';

import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';

import { Column, Input, ObservationDetailsContainer } from './base';
import { ObservationChatBox } from 'lib/Conversation';
import { useTranslation } from 'react-i18next';

const PositionMarker = ({ position, color }) => {
  if (!position) {
    return <div />;
  }
  const size = 20;
  const left = position.x - size / 2;
  const top = position.y - size / 2;
  return (
    <div
      style={{
        height: size + 'px',
        width: size + 'px',
        borderRadius: '50%',
        zIndex: 2,
        backgroundColor: color,
        left: left + 'px',
        top: top + 'px',
        position: 'absolute',
      }}
    />
  );
};

const Label = ({ text }) => {
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </div>
  );
};

const REAL_FIELD_WIDTH = 20;
const REAL_FIELD_HEIGHT = 20;

const pol2cart = ({
  distance,
  angle,
  rotation,
  centerX,
  centerY,
  imageWidth,
  imageHeight,
}) => {
  if (angle > 90) {
    angle -= 360;
  }
  angle += rotation + 90;

  const phi = (angle / 360) * 2 * Math.PI;
  const rho = distance;

  const x = ((rho * Math.cos(phi)) / REAL_FIELD_WIDTH) * imageWidth + centerX;
  const y = ((rho * Math.sin(phi)) / REAL_FIELD_HEIGHT) * imageHeight + centerY;
  return { x, y };
};

const cart2pol = ({
  x,
  y,
  rotation,
  centerX,
  centerY,
  imageWidth,
  imageHeight,
}) => {
  const xCorrected = ((x - centerX) / imageWidth) * REAL_FIELD_WIDTH;
  const yCorrected = ((y - centerY) / imageHeight) * REAL_FIELD_HEIGHT;

  const distance =
    Math.round(Math.sqrt(xCorrected ** 2 + yCorrected ** 2) * 10) / 10;
  let angle =
    Math.round(
      (Math.atan2(yCorrected, xCorrected) / (2 * Math.PI)) * 360 * 10
    ) / 10;

  angle -= rotation + 90;
  if (angle < -180) {
    angle = 360 + angle;
  }

  return { distance, angle };
};

const LocationInput = ({ onChange, rotation = 0, shotObservations = [] }) => {
  const [markers, setMarkers] = useState([]);
  const [options, setOptions] = useState({});
  const domRef = useRef(null);

  useEffect(() => {
    const { width: imageWidth } = domRef.current.getBoundingClientRect();
    const imageHeight = imageWidth;

    let centerX, centerY;
    switch (rotation) {
      case -90:
        centerX = imageWidth * 0.31;
        break;
      case 0:
        centerX = imageWidth * 0.5;
        break;
      case 90:
        centerX = imageWidth * 0.69;
        break;
    }

    switch (rotation) {
      case -90:
        centerY = imageHeight * 0.5;
        break;
      case 0:
        centerY = imageHeight * 0.31;
        break;
      case 90:
        centerY = imageHeight * 0.5;
        break;
    }

    const options = {
      rotation,
      centerX,
      centerY,
      imageWidth,
      imageHeight,
    };
    setOptions(options);

    setMarkers([]);
    try {
      const markers_ = shotObservations.map((shotObservation) => {
        const { distance, angle, result } = shotObservation;
        if (!distance || !angle) {
          throw 'invalid';
        }
        return {
          position: pol2cart({ distance, angle, ...options }),
          color: {
            MISS: '#cf0f0f',
            HIT: '#cf990f',
            GOAL: '#0fcf85',
          }[result],
        };
      });
      setMarkers(markers_);
    } catch (e) {}
  }, [shotObservations, rotation]);

  const _onClick = (e) => {
    const bb = e.nativeEvent.target.getBoundingClientRect();
    const x = e.nativeEvent.clientX - bb.left;
    const y = e.nativeEvent.clientY - bb.top;
    const { distance, angle } = cart2pol({ x, y, ...options });

    onChange({ distance, angle });
  };

  // if (this.props.showCurrentMarker)
  // {
  //     let position = null;
  //     const attributes = this.props.observationLogger.getCurrentPartialObservationAttributes(this.props.groupId);
  //     if (attributes && attributes.has("distance") && attributes.has("angle") && this.props.active)
  //     {
  //         position = this.pol2cart({distance: attributes.get('distance'), angle: attributes.get('angle')});
  //     }
  // }

  const backgroundImage = backgroundImageRightGreen;

  return (
    <div ref={domRef} style={{ position: 'relative' }}>
      <img
        src={backgroundImage}
        className="width-100 field-image"
        style={{ transform: `rotate(${rotation - 90}deg)` }}
      />

      <div
        style={{
          height: '100%',
          width: '100%',
          top: 0,
          position: 'absolute',
          zIndex: 10,
        }}
        onClick={_onClick}
        className="clickable"
      />
      <div
        style={{
          height: '100%',
          width: '100%',
          top: 0,
          position: 'absolute',
          zIndex: 6,
        }}
      >
        {markers.map((marker, i) => {
          return (
            <PositionMarker
              key={i}
              color={marker.color}
              position={marker.position}
            />
          );
        })}
      </div>
    </div>
  );
};

const OptionSelect = ({ key_, values, t, currentValue, setCurrentValue }) => {
  return (
    <Input label={t(`${key_}.label`)}>
      <ButtonGroupSelect
        className="form-control"
        options={values.map((value) => {
          return {
            label: t(`${key_}.options.${value}`),
            value,
          };
        })}
        // Convention is to always use uppercase values for constants
        // When previous data doesn't follow the convention and uses lowercase,
        // make sure this select doesn't break
        value={currentValue ? currentValue.toUpperCase() : null}
        radio
        onChange={setCurrentValue}
      />
    </Input>
  );
};

const KorfballShotObservationDetails = ({
  observation,
  attributesOptions,
  ...props
}) => {
  const [personId, setPersonId] = useState(null);
  const [opponentPersonId, setOpponentPersonId] = useState(null);
  const [assistPersonId, setAssistPersonId] = useState(null);
  const [assistPosition, setAssistPosition] = useState('inside');
  const [reboundPersonId, setReboundPersonId] = useState(null);
  const [leg, setLeg] = useState(null);
  const [pressure, setPressure] = useState(null);
  const [result, setResult] = useState(null);
  const [angle, setAngle] = useState(null);
  const [distance, setDistance] = useState(null);
  const [type, setType] = useState(null);
  const [description, setDescription] = useState(null);

  const { t } = useTranslation('module.observe.video.korfball');

  useEffect(() => {
    const shotTypeLabel = type ? t(`shotType.options.${type}`) : '';
    const personLabel = (
      attributesOptions.personId.find(({ value }) => value === personId) || {
        player: { firstName: '' },
      }
    ).player.firstName;
    const resultLabel = result ? t(`result.options.${result}`) : '';

    setDescription(`${shotTypeLabel} ${personLabel}: ${resultLabel}`);
  }, [personId, result, type]);

  useEffect(() => {
    setPersonId(observation.attributes_.personId);
    setOpponentPersonId(observation.attributes_.opponentPersonId);
    setAssistPersonId(observation.attributes_.assistPersonId);
    setResult(observation.attributes_.result);
    setAngle(observation.attributes_.angle);
    setDistance(observation.attributes_.distance);
    setLeg(observation.attributes_.leg);
    setPressure(observation.attributes_.pressure);
    setType(observation.attributes_.type);
    setDescription(observation.description);
    setAssistPosition(observation.attributes_.assistPosition);
    setReboundPersonId(observation.attributes_.reboundPersonId);
  }, [observation]);

  const observationProps = {
    description,
    triggerTime: observation.triggerTime,
    attributes: {
      personId,
      result,
      angle,
      distance,
      type,
      leg,
      pressure,
      opponentPersonId,
      assistPersonId,
      assistPosition,
      reboundPersonId,
    },
  };

  return (
    <ObservationDetailsContainer
      observationId={observation.id}
      startTime={observation.displayStartTime}
      endTime={observation.displayEndTime}
      observationProps={observationProps}
      {...props}
    >
      <Column>
        <Input label={t('shotPosition')}>
          <div>
            <LocationInput
              rotation={0}
              shotObservations={[{ angle, distance, result }]}
              onChange={({ angle, distance }) => {
                setAngle(angle);
                setDistance(distance);
                if (!type) {
                  setType(distance <= 6 ? 'SHORT' : 'LONG');
                }
              }}
            />
          </div>
        </Input>
      </Column>
      <Column title={description}>
        <Input label={t('attacker')}>
          <Select
            options={attributesOptions.personId}
            value={
              !!personId &&
              attributesOptions.personId.find(
                (option) => option.value === personId
              )
            }
            onChange={(option) => setPersonId(option.value)}
          />
        </Input>
        <Input label={t('defender')}>
          <Select
            options={attributesOptions.personId}
            value={
              !!opponentPersonId &&
              attributesOptions.personId.find(
                (option) => option.value === opponentPersonId
              )
            }
            onChange={(option) => setOpponentPersonId(option.value)}
          />
        </Input>
        <Input label={t('assist')}>
          <Select
            options={attributesOptions.personId}
            value={
              !!assistPersonId &&
              attributesOptions.personId.find(
                (option) => option.value === assistPersonId
              )
            }
            onChange={(option) => setAssistPersonId(option.value)}
          />
        </Input>
        <OptionSelect
          key_="assistPosition"
          values={['INSIDE', 'OUTSIDE']}
          currentValue={assistPosition}
          setCurrentValue={setAssistPosition}
          t={t}
        />

        <Input label={t('rebound')}>
          <Select
            options={attributesOptions.personId}
            value={
              !!reboundPersonId &&
              attributesOptions.personId.find(
                (option) => option.value === reboundPersonId
              )
            }
            onChange={(option) => setReboundPersonId(option.value)}
          />
        </Input>

        <OptionSelect
          key_="shotType"
          values={['LONG', 'SHORT', 'RUNNING-IN', 'FREE-BALL', 'PENALTY']}
          currentValue={type}
          setCurrentValue={setType}
          t={t}
        />

        <OptionSelect
          key_="shotLeg"
          values={['LEFT', 'BOTH', 'RIGHT']}
          currentValue={leg}
          setCurrentValue={setLeg}
          t={t}
        />

        <OptionSelect
          key_="shotPressure"
          values={['NONE', 'MEDIUM', 'HIGH']}
          currentValue={pressure}
          setCurrentValue={setPressure}
          t={t}
        />

        <OptionSelect
          key_="result"
          values={['GOAL', 'HIT', 'MISS']}
          currentValue={result}
          setCurrentValue={setResult}
          t={t}
        />
      </Column>
      <Column>
        <ObservationChatBox observationId={observation.observationId} />
      </Column>
    </ObservationDetailsContainer>
  );
};

export { KorfballShotObservationDetails };
