import { Tour } from '../Tour';

import { matchRuleShort } from 'utils/matchRuleShort';

export default new Tour({
  key: 'developmentOverview',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return (
      matchRuleShort(path, '/development/tdp*') && currentSession.isTrainer()
    );
  },
  steps: () => [
    {
      target: '.page-header-cta button',
      i18nKey: 'module.tours.development:step1AddGrowthAmbition',
    },
    {
      target: ".page-actions button[data-path='monitor-growth-ambition']",
      i18nKey: 'module.tours.development:step2MonitorGrowthAmbition',
    },
    {
      target: ".page-actions button[data-path='learning-line-overview']",
      i18nKey: 'module.tours.development:step3LearningLineOverview',
    },
    {
      target: '.learning-lines-detail div:first-child .card-body .btn-primary',
      i18nKey: 'module.tours.development:step4UpdateLearningLine',
    },
  ],
  callbacks: {
    start: () => {
      // console.log("tour started");
    },
    nextStep: index => {
      // console.log(`tour nextStep ${index}`);
    },
    end: () => {
      // console.log("tour ended");
    },
  },
});
