import { getRoutePath, gotoRoute, RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';
import './styles/index.scss';

import {
  MatchDetail,
  TrainingOverview,
  RecordingOverview,
  MatchOverview,
  MatchLivestreamOverview,
  MatchLivestreamDetail,
} from './containers';

const MODULE_NAME = 'match';

export default new Module(
  MODULE_NAME,
  [
    {
      title: 'Wedstrijden',
      subItems: [
        {
          path: 'overview',
          title: 'Wedstrijden',
        },
        {
          path: 'videos',
          title: "Video's",
        },
        {
          path: 'rapports',
          title: 'Rapporten',
        },
        {
          path: 'imports',
          title: 'Data imports',
        },
      ],
    },
  ],
  [
    window.isSkillReflect
      ? new RouteRedirect('', () =>
          gotoRoute('recordings', { ARRAYfilters: {} })
        )
      : new RouteRedirect('', 'overview/all'),
    new RouteRedirect('overview', 'overview/all'),
    window.isSkillReflect
      ? new RouteRedirect('overview/*', () =>
          gotoRoute('recordings', { ARRAYfilters: {} })
        )
      : new RouteExact(
          'overview/:group/:ARRAYfilters?',
          MatchOverview,
          'overview'
        ),
    new RouteExact(
      'overview/:timeFilterName/:uploadVideoSportingEventId',
      MatchOverview,
      'overview-upload'
    ),
    new RouteExact('detail/:sportingEventId', MatchDetail, 'detail'),
    new RouteExact(
      'livestream/:sportingEventId/',
      MatchLivestreamOverview,
      'livestream'
    ),
    new RouteExact(
      'livestream/:sportingEventId/:overlay',
      MatchLivestreamDetail,
      'livestream-details'
    ),
  ],
  ['sporting-event:view']
);

export const Trainings = new Module(
  'trainings',
  [
    {
      title: 'Trainings',
      subItems: [
        {
          path: 'overview',
          title: 'Trainings',
        },
      ],
    },
  ],
  [
    new RouteRedirect('', 'overview/all'),
    new RouteExact('overview/:ARRAYfilters?', TrainingOverview, 'overview'),
    new RouteExact('detail/:sportingEventId', MatchDetail, 'detail'),
  ],
  ['sporting-event:view']
);

export const Recordings = new Module(
  'recordings',
  [
    {
      title: 'recordings',
      subItems: [
        {
          path: 'overview',
          title: 'Recordings',
        },
      ],
    },
  ],
  [
    new RouteRedirect('', 'overview/all'),
    new RouteExact('overview/:ARRAYfilters?', RecordingOverview, 'overview'),
    new RouteExact('detail/:sportingEventId', MatchDetail, 'detail'),
  ],
  ['sporting-event:view']
);
