import React, { useState, useEffect } from 'react';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandHistory } from '../../../infra/Messaging';
import { PointTriggerButton } from 'utils/ObservationInput/components/TriggerButton';
import {
  VideoObservationPanel,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import learningLineCollection from 'domain/Development/LearningLine';

const LearningLineObservationInput = ({ observationContext, args }) => {
  const { observationLogger } = observationContext;
  return (
    <VideoObservationPanel observationContext={observationContext}>
      <EventButtonGroup>
        <InputTrigger
          type="point"
          observationCode="LEARNING-LINE"
          attributes={{
            learningLineId: args,
            rating: 'positive',
          }}
          observationLogger={observationLogger}
          description={'Good example'}
          render={props => <PointTriggerButton {...props} keys={'1'} />}
        />
        <InputTrigger
          type="point"
          observationCode="LEARNING-LINE"
          attributes={{
            learningLineId: args,
            rating: 'positive',
          }}
          observationLogger={observationLogger}
          description={'Bad example'}
          render={props => <PointTriggerButton {...props} keys={'2'} />}
        />
      </EventButtonGroup>
    </VideoObservationPanel>
  );
};

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'learning-line.default',
  component: LearningLineObservationInput,
  options,
  title: 'LearningLine',
  usedKeys: [],
});
