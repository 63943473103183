import ReactConfetti from 'react-confetti';
import React, { useEffect, useRef, useState } from 'react';
import { Session } from '../../../domain/Session';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const Welcome = () => {
  const [closed, setClosed] = useState(localStorage.getItem('welcomeClosed'));
  const welcomeRef = useRef();
  let width, height;
  const { t } = useTranslation('module.home');

  const closeBanner = () => {
    localStorage.setItem('welcomeClosed', true);
    setClosed(!closed);
  };

  useEffect(() => {
    if (welcomeRef.current) {
      width = welcomeRef.current.clientWidth;
      height = welcomeRef.current.clientHeight;
    }
  });
  let createdDate = Session.current().user.created;
  let today = moment().startOf('day');
  if (today.diff(createdDate, 'days') > 14 || closed) {
    return null;
  }
  return (
    <div ref={welcomeRef} className="notification--welcome-message">
      <button className="close" onClick={() => closeBanner()}>
        <i className="i-cross i-light i-xs"></i>
      </button>
      <ReactConfetti
        colors={
          window.isSkillReflect
            ? [
                '#ffa12e',
                '#ff9425',
                '#ff861e',
                '#ff771a',
                '#ff6718',
                '#ff5519',
                '#ff401c',
              ]
            : [
                '#28E89E',
                '#00D6BF',
                '#00C0DE',
                '#00A7F4',
                '#008AF7',
                '#0068E3',
                '#683BB8',
              ]
        }
        width={width}
        height={height}
      />
      <div>
        <h3>{t('welcome')}</h3>
        <p>{t('welcomeSub')}</p>
      </div>
    </div>
  );
};
