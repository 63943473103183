import React, { Component } from 'react';

class ObservationInputBase extends Component {
  componentWillMount() {
    this.observationContext = this.props.observationContext;
    this.observationLogger = this.props.observationContext.observationLogger;
  }
}

export { ObservationInputBase };
