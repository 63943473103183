import React from 'react';
import { observer } from 'mobx-react';
import { InputTrigger } from './InputTrigger';

export const PlayerSelect = observer(
  ({ players, observationCode, observationLogger, triggerType }) => {
    const columns = players.length > 4 ? 2 : 1;
    let playerSelect;
    if (players.length > 0) {
      players.sort((a, b) => a.fullName.localeCompare(b.fullName));
      playerSelect = (
        <div className={`col-${columns * 4}`}>
          <b>Player</b>
          <ul className="invoer-select" id="player-select" style={{ columns }}>
            {players.map(player => {
              return (
                <InputTrigger
                  key={player.id}
                  type={triggerType}
                  observationCode={observationCode}
                  groupId={observationCode}
                  attributes={{ personId: player.id }}
                  observationLogger={observationLogger}
                  render={({ active, trigger }) => (
                    <li className={active ? 'selected' : ''}>
                      {!!player.profilePictureUrl && (
                        <div
                          className="select-thumb"
                          style={{
                            backgroundImage: `url(${player.profilePictureUrl})`,
                          }}
                        />
                      )}
                      {!player.profilePictureUrl && (
                        <div className="select-thumb">{player.code}</div>
                      )}
                      <div className="select-name clickable" onClick={trigger}>
                        {player.fullName}
                        {!!player.number ? ` (${player.number})` : ''}
                      </div>
                    </li>
                  )}
                />
              );
            })}
            <InputTrigger
              type={triggerType}
              observationCode={observationCode}
              groupId={observationCode}
              attributes={{}}
              observationLogger={observationLogger}
              render={({ trigger }) => (
                <li>
                  <div className="select-thumb">?</div>
                  <div className="select-name clickable" onClick={trigger}>
                    <b>
                      <i>Unknown</i>
                    </b>
                  </div>
                </li>
              )}
            />
          </ul>
        </div>
      );
    } else {
      playerSelect = null;
    }
    return playerSelect;
  }
);
