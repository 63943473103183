import React, { useEffect, useState } from 'react';
import RjsfForm from '@rjsf/core';
import { JSONSchema7Array, JSONSchema7Object } from 'json-schema';

import './index.scss';
import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';
import Select from 'react-select';

import { timeout } from 'lib/util';

// TODO: @koenvo
// START EXAMPLE STORE
//
// usage see: src/modules/dev/react-jsonschema-form-test.tsx
//

const players = [
  {
    id: '1dsgls18571',
    name: 'John Doe',
  },
  {
    id: '19285agawoo',
    name: 'Jane Doe',
  },
];

const _repo = {
  players,
};

const store = {
  get: async (key: keyof typeof _repo) => {
    return timeout(_repo[key], 1);
  },
};

// END EXAMPLE STORE

const ButtonGroupSelectWidget = function({ options, ...props }) {
  return <ButtonGroupSelect options={options.enumOptions} {...props} />;
};

interface ReactSelectOption {
  value: any;
  label: string;
}

const ReactSelectWidget = function({
  options,
  onChange,
  value,
  schema,
  ...props
}) {
  const handleChange = (options: ReactSelectOption | ReactSelectOption[]) => {
    if (Array.isArray(options)) {
      onChange(options.map(o => o.value));
    } else {
      onChange(options.value);
    }
  };

  const valueOption = Array.isArray(value)
    ? options?.enumOptions?.filter((option: ReactSelectOption) =>
        value.includes(option.value)
      )
    : options?.enumOptions?.find(
        (option: ReactSelectOption) => option.value === value
      );

  return (
    <Select
      options={options.enumOptions}
      onChange={handleChange}
      value={valueOption}
      {...props}
    />
  );
};

export const uiSchemaDefaults = {
  classNames: 'jsonschema-form',
};

const widgetsDefaults = {
  ButtonGroupSelectWidget,
  ReactSelectWidget,
};

export const Form: React.FC<any> = ({
  uiSchema = {},
  widgets = {},
  children,
  ...props
}) => {
  const uiSchemaMerged = { ...uiSchemaDefaults, ...uiSchema };
  const widgetsMerged = { ...widgetsDefaults, ...widgets };

  return (
    <RjsfForm uiSchema={uiSchemaMerged} widgets={widgetsMerged} {...props}>
      {' '}
      {children ? (
        children
      ) : (
        <div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      )}
    </RjsfForm>
  );
};

// async resolves queryKeys and translation

const schemaDefaults = {};

// @return schema
export const useSchema = (orig, translate, defaults = schemaDefaults) => {
  const [queriesResolved, setQueriesResolved] = useState();

  useEffect(() => {
    (async () => {
      setQueriesResolved(await schemaTraverseQueryKeys(orig));
    })();
  }, [orig]);

  if (!queriesResolved) {
    return null;
  }
  const translated = schemaTraverseI18n({
    schema: queriesResolved,
    translate,
  });

  return schemaTraverseDefaults({
    schema: translated,
    acc: {},
    defaults,
  });
};

const schemaTraverseI18n = ({ schema, acc = {}, translate }) => {
  for (const [key, value] of Object.entries(schema)) {
    if (key === 'i18n' && typeof value === 'object') {
      for (const [i18nKey, i18nValue] of Object.entries(value)) {
        if (i18nKey === 'enumNames' && schema.enum) {
          acc[i18nKey] = schema.enum.map(enumValue =>
            translate(`${i18nValue}.${enumValue}`)
          );
        } else {
          acc[i18nKey] = translate(i18nValue);
        }
      }
    } else {
      if (Array.isArray(value)) {
        const list = [];
        for (const item of value) {
          if (typeof item === 'object') {
            list.push(schemaTraverseI18n({ schema: item, translate }));
          } else {
            list.push(item);
          }
          // TODO handle arrays
        }
        acc[key] = list;
      } else if (typeof value === 'object') {
        acc[key] = schemaTraverseI18n({ schema: value, translate });
      } else {
        acc[key] = value;
      }
    }
  }

  return acc;
};

const schemaTraverseQueryKeys = async (schema: object, acc = {}) => {
  for (const [key, value] of Object.entries(schema)) {
    if (key === 'queryKey') {
      acc['enum'] = await store.get(value);
    } else {
      if (Array.isArray(value)) {
        const list = [];
        for (const item of value) {
          if (typeof item === 'object') {
            list.push(await schemaTraverseQueryKeys(item));
          } else {
            list.push(item);
          }
          // TODO handle arrays
        }
        acc[key] = list;
      } else if (typeof value === 'object') {
        acc[key] = await schemaTraverseQueryKeys(value);
      } else {
        acc[key] = value;
      }
    }
  }

  return acc;
};

const isSchemaObject = (subject: any): subject is JSONSchema7Object =>
  typeof subject === 'object';
const isJSONSchema7Array = (subject: any): subject is JSONSchema7Array[] =>
  Array.isArray(subject);

export const schemaTraverseDefaults = ({
  schema,
  acc = {},
  defaults = {},
}: {
  schema: JSONSchema7Object;
  acc?: JSONSchema7Object;
  defaults?: JSONSchema7Object;
}): JSONSchema7Object => {
  for (const [key, value] of Object.entries(schema)) {
    if (isJSONSchema7Array(value)) {
      acc[key] = value.map((item, idx) =>
        isSchemaObject(item)
          ? schemaTraverseDefaults({
              schema: item,
              defaults: (defaults[key] as JSONSchema7Array)?.[idx] as
                | JSONSchema7Object
                | undefined,
            })
          : item
      );
    } else if (isSchemaObject(value)) {
      acc[key] = schemaTraverseDefaults({
        schema: value,
        defaults: defaults[key] as JSONSchema7Object,
      });

      if (typeof (defaults[key] as any)?.default !== 'undefined') {
        // @ts-ignore
        acc[key].default = defaults[key]?.default;
      }
    } else {
      acc[key] = value;
    }
  }

  return acc;
};
