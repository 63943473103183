import React, { useContext, useMemo } from 'react';
import { Internals, TimelineContextValue } from 'remotion';

type PremountProps = {
  for: number;
  children: React.ReactNode;
};

export const Premount: React.FC<PremountProps> = ({
  for: premountFor,
  children,
}) => {
  const sequenceContext = useContext(Internals.SequenceContext);
  if (typeof premountFor === 'undefined') {
    throw new Error(
      `The <Premount /> component requires a 'for' prop, but none was passed.`
    );
  }

  if (typeof premountFor !== 'number') {
    throw new Error(
      `The 'for' prop of <Premount /> must be a number, but is of type ${typeof premountFor}`
    );
  }

  if (Number.isNaN(premountFor)) {
    throw new Error(
      `The 'for' prop of <Premount /> must be a real number, but it is NaN.`
    );
  }

  if (!Number.isFinite(premountFor)) {
    throw new Error(
      `The 'for' prop of <Premount /> must be a finite number, but it is ${premountFor}.`
    );
  }

  const context = useContext(Internals.Timeline.TimelineContext);
  const value: TimelineContextValue = useMemo(() => {
    const contextOffset = sequenceContext
      ? sequenceContext.cumulatedFrom + sequenceContext.relativeFrom
      : 0;

    const currentFrame = context.frame - contextOffset;
    return {
      ...context,
      playing: currentFrame < premountFor ? false : context.playing,
      imperativePlaying: {
        current:
          currentFrame < premountFor
            ? false
            : context.imperativePlaying.current,
      },
      frame: Math.max(0, currentFrame - premountFor) + contextOffset,
    };
  }, [context, premountFor, sequenceContext]);

  return (
    <Internals.Timeline.TimelineContext.Provider value={value}>
      {children}
    </Internals.Timeline.TimelineContext.Provider>
  );
};
