import React, { useContext, useState } from 'react';
import { getClipConfig } from '../../remotion/clipRegistry';
import { ContentEditView } from './contentEditView';
import uuidv4 from 'uuid/v4';
import { VideoTimeContext } from './utils/VideoTimeProvider';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export const AddContentView = ({ template, onAddContent }) => {
  const [selected, setSelected] = useState();
  const [newContent, setNewContent] = useState();

  const { t } = useTranslation('module.highlights');

  const components = template.availableContent
    .map((block) => {
      const config = getClipConfig(block);
      if (config) {
        return { ...config, type: block };
      }
    })
    .filter(Boolean);
  const newContentId = uuidv4();

  const videoTime = useContext(VideoTimeContext);
  return (
    <div>
      <div className="modal-header">
        <h5 className="modal-title">{t('addContentBlock')}</h5>
      </div>

      <div className="form-group">
        <label>{t('selectContent')}</label>
        <div className="row">
          {components.map((component) => {
            const content = {
              contentId: newContentId,
              type: component.type,
              options: component.defaults,
              ...component.offset,
              ...component.duration,
            };
            return (
              <SelectContentBlock
                key={component.type}
                config={component}
                onSelect={(type) => {
                  setSelected(type);
                  setNewContent(content);
                }}
                isSelected={selected == component.type}
              />
            );
          })}
        </div>
      </div>
      {newContent && (
        <div className={'form-group'}>
          <ContentEditView
            content={newContent}
            onEditContent={(content) => {
              // setNewContent(content);
              onAddContent(content, videoTime);
            }}
            isEdit={false}
          />
        </div>
      )}
      {/* DEBUG{newContent && Object.entries(newContent).map(([k, v]) => `${k}:${v} \n`)}*/}
    </div>
  );
};

const SelectContentBlock = ({ config, onSelect, isSelected }) => {
  const currentLanguage = i18n?.language ?? 'en';
  return (
    <div className={'col-md-3 col-sm-4 col-6'}>
      <div
        onClick={() => onSelect(config.type)}
        className={`select-content-block ${isSelected ? 'is-selected' : ''}`}
      >
        <div
          className="thumb"
          style={{
            backgroundImage: `url("${config?.thumbnail}")`,
            backgroundSize: 'cover',
          }}
        ></div>
        <h6>{config.name[currentLanguage] ?? config.name['en']}</h6>
      </div>
    </div>
  );
};
