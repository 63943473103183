import { VideoFragment } from './VideoFragment';

export class Cell {
  constructor(
    readonly metrics: { [key: string]: number },
    readonly videoFragments?: VideoFragment[]
  ) {}

  static empty(): Cell {
    return new Cell({ total: 0, won: 0 }, []);
  }
}

export class TypedCell<MetricsType, MetadataType = any> {
  constructor(
    readonly metrics: Readonly<MetricsType>,
    readonly videoFragments: VideoFragment[] = [],
    readonly metadata?: MetadataType
  ) {}
}
