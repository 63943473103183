class ObservationInputTemplate {
  constructor({ buttons, hooks, layout, namespace }) {
    this.buttons = buttons;
    this.hooks = hooks;
    this.namespace = namespace;
    this.layout = layout;
  }
}

export { ObservationInputTemplate };
