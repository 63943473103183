import * as Sentry from '@sentry/react';

const logger = {
  warning: (msg, options = {}) => {
    Sentry.withScope((scope) => {
      scope.setExtra('options', options);
      scope.setLevel('warning');
      Sentry.captureMessage(msg);
    });
  },
  error: (e, options = {}) => {
    Sentry.withScope((scope) => {
      scope.setExtra('options', options);
      if (options?.transactionName) {
        scope.setTransactionName(options.transactionName);
      }
      Sentry.captureException(e);
    });
  },
};
export default logger;
