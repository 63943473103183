import type { TransitionPresentation } from '@remotion/transitions';
import type { TransitionPresentationComponentProps } from '@remotion/transitions';
import { AbsoluteFill, Video } from 'remotion';
import replayVideo from './Instant Replay stinger transition.webm';
import { useMemo } from 'react';
import zIndexes from '../../helpers/zIndexes.json';

type CustomPresentationProps = {
  width: number;
  height: number;
  color: string;
};

export const ReplayPresentation = (
  props: CustomPresentationProps
): TransitionPresentation<CustomPresentationProps> => {
  return { component: Replay, props };
};

const Replay: React.FC<
  TransitionPresentationComponentProps<CustomPresentationProps>
> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps,
}) => {
  const isEntering = presentationDirection === 'entering';
  const style: React.CSSProperties = useMemo(() => {
    return {
      opacity: !isEntering ? 1 : presentationProgress > 0.5 ? 1 : 0,
    };
  }, [isEntering, presentationDirection, presentationProgress]);
  return (
    <AbsoluteFill>
      <AbsoluteFill style={style}>{children}</AbsoluteFill>
      {presentationDirection == 'entering' && (
        <AbsoluteFill
          style={{
            zIndex: zIndexes.transition,
            backgroundColor: 'transparent',
          }}
        >
          <Video src={replayVideo} playbackRate={0.5} />
        </AbsoluteFill>
      )}
    </AbsoluteFill>
  );
};
