import { Link } from 'react-router-dom';
import StoreIcon from 'img/icons/store.svg';
import ReactTooltip from 'react-tooltip';
import './index.scss';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/ApiClient';
import { useTranslation } from 'react-i18next';
import { UpgradeNotificationContainer } from '../../UpgradePopup/upgradePopup';
import { Session } from '../../../domain/Session';
import moment from 'moment';
import _ from 'lodash';

export const StoreLink = () => {
  const queryKey = 'storeUpgrade';
  const { t } = useTranslation('storeIcon');
  const [popupOpen, setPopupOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [isUserInitiated, setIsUserInitiated] = useState(false);
  const session = Session.current();
  const response = useQuery(
    queryKey,
    async () => {
      const response = await api.get('store/subscriptions');
      return response.data;
    },
    { placeholderData: [] }
  );
  const {
    data: subscriptions,
    isFetched: subscriptionsFetched,
    error: subscriptionsError,
  } = response;

  const REMINDER_FREE = 7; // weekly
  const REMINDER_TRIAL = 1; // daily

  // todo: Get this array from Backend?
  let availableProducts = !window.isSkillReflect
    ? {
        prod_MQ2R32b5Peopgh: 'Camera',
        prod_MQ2LOAkq6SR6ZZ: 'Camera',
        prod_MQ2KB81vzunzaY: 'Camera',
        prod_M6VawgYVcjpbtb: 'Pro',
        prod_M6VVmPpGiMU0vR: 'Basic',
        prod_K0vwMJOJlMVKvk: 'Free',
        // prod_M83YX9WJliL99B: 'MatchPack',
      }
    : {
        prod_LSPRzq4dL8igOL: 'Professional',
        prod_LSOyHXFrKMeGYT: 'Basic',
        prod_LSOtoyfr60KQqL: 'Free',
      };
  const canBuy =
    session.hasClubAdminPrivileges() &&
    session.isFeatureAvailable('buySubscription');

  /*
   *  Show upgrade popup / link to store
   *
   *  Popup when:
   *     - Eligible to buy (admins)
   *     - Using Free or [Pro trial]
   *      Push every:
   *        7 days [Free]
   *        1 days [Pro trial]
   *          - Except when customer also has basic.
   *   Link to store when:
   *     - Not eligable to buy (players, trainers)
   *     - Not in "Other groups" (development)
   *
   *  Upgrade message (and (^) indicator) when:
   *     - Free or Basic or Trialing
   *
   *
   *  SkillReflect, spelenderwijs/other
   *    - Show store icon
   *
   * */
  useEffect(() => {
    let reminder_days = 0; // never
    let _currentProducts;
    if (subscriptionsFetched && !subscriptionsError) {
      _currentProducts = subscriptions
        .map((subscription) => {
          return availableProducts[subscription?.productId];
        })
        .filter(Boolean);

      if (subscriptions.length === 0) {
        _currentProducts = ['Free'];
      }
      if (_currentProducts.length === 0 && subscriptions.length > 0) {
        _currentProducts = ['other'];
      }
      if (isTrialing) {
        reminder_days = REMINDER_TRIAL;
        setMessage(t('upgrade'));
      }
      if (_currentProducts.join() === 'Free') {
        reminder_days = REMINDER_FREE;
        setMessage(t('upgrade'));
      }
      if (_currentProducts.includes('Basic')) {
        setMessage(t('upgrade'));
      }
      if (
        !isClosedTimeOut(reminder_days) &&
        canBuy &&
        !session.currentTenantIsNewerThan(2) &&
        !_currentProducts.includes('Basic')
      ) {
        setPopupOpen(true);
      }
      setCurrentProducts(_currentProducts);
    }
  }, [subscriptionsFetched]);

  if (session?.currentMembership()?.roleNames[0] === 'trainee') {
    return null;
  }

  if (!response.isFetched) {
    return <GoToStore />;
  }
  if (window.isSkillReflect) {
    return <GoToStore message={message} />;
  }
  if (currentProducts.includes('other')) {
    // Spelenderwijs, andere licenties?
    return <GoToStore />;
  }
  // const isTrialing = subscriptions.find((subscription) => {
  //   return subscription.state === 'trialing';
  // });
  const isTrialing = subscriptions?.find((subscription) => {
    return subscription.state === 'trialing';
  });
  if (
    _.intersection(['Camera', 'Pro', 'Professional', 'Basic'], currentProducts)
      .length > 0 &&
    !isTrialing
  ) {
    return <GoToStore message={message} />;
  }

  if (!canBuy) {
    return <GoToStore message={message} />;
  }

  const onClosePopup = () => {
    setPopupOpen(false);
    localStorage.setItem('upgradePopupClosed', new Date().toISOString());
    session.user.logAction('upgradePopupClosed', {
      component: 'Lib:upgradePopup',
      url: window.location.href,
      isUserInitiated: isUserInitiated,
    });
  };

  const onOpenPopup = (isUserInitiated_ = isUserInitiated) => {
    session.user.logAction('upgradePopupShown', {
      component: 'Lib:upgradePopup',
      url: window.location.href,
      isUserInitiated: isUserInitiated_,
    });
  };

  return (
    <div className={`notification-store ${message ? 'hasMessage' : ''}`}>
      <div
        data-tip=""
        data-for="storeIcon"
        className="clickable"
        onClick={() => {
          setIsUserInitiated(true);
          setPopupOpen(true);
        }}
      >
        <span className="warning"></span>
        <img src={StoreIcon} />
      </div>
      <UpgradeNotificationContainer
        session={session}
        isOpen={popupOpen}
        currentProducts={currentProducts}
        availableProducts={availableProducts}
        isTrialing={isTrialing}
        onClose={onClosePopup}
        onOpenPopup={onOpenPopup}
      />
    </div>
  );
};

const GoToStore = ({ message }) => {
  return (
    <a
      className={`notification-store ${message ? 'hasMessage' : ''}`}
      href={'/apps/launch/store'}
      target={'_blank'}
    >
      {message && (
        <ReactTooltip
          id="storeIcon"
          type="dark"
          effect="solid"
          place={'bottom'}
          clickable={true}
          className={'tooltip--styled lock-tooltip'}
          backgroundColor="#495057"
        >
          {message}
        </ReactTooltip>
      )}
      <div data-tip="" data-for="storeIcon">
        <span className={'warning'} />
        <img src={StoreIcon} />
      </div>
    </a>
  );
};

const isClosedTimeOut = (days) => {
  const date = window.localStorage.getItem('upgradePopupClosed');

  if (!date) {
    return false;
  }
  if (
    days &&
    moment().startOf('day').diff(moment(date).startOf('day'), 'days') >= days
  ) {
    // Reminder every $(days)
    return false;
  } else {
    //`hidden because closed within ${days}`
    return true;
  }
};
