import React, { useEffect, useState, createContext, useContext } from 'react';

const ResponsiveSVGContext = createContext();

export const ResponsiveSVG = ({
  width = 1024,
  height = 768,
  breakPoint = 1024,
  rowHeight,
  children,
  ...props
}) => {
  if (typeof rowHeight === 'undefined') {
    throw new Error('no rowHeight provided');
  }

  const [state, setState] = useState({
    vbWidth: width,
    vbHeight: height,
    isBelowBreakpoint: false,
    rowHeight,
  });

  const mergeState = newState =>
    setState(prevState => ({ ...prevState, ...newState }));

  const calcViewBoxHeight = () => {
    if (!state.isBelowBreakpoint) {
      return height;
    }

    return height + rowHeight * 3;
  };

  useEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth < breakPoint) {
        mergeState({ isBelowBreakpoint: true });
      } else {
        mergeState({ isBelowBreakpoint: false });
      }
    };

    window.addEventListener('resize', resizeListener);
    resizeListener();

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [breakPoint]);

  return (
    <ResponsiveSVGContext.Provider value={state}>
      <svg
        viewBox={`0 0 ${width} ${calcViewBoxHeight()}`}
        width="100%"
        height="100%"
        {...props}
      >
        {children}
      </svg>
    </ResponsiveSVGContext.Provider>
  );
};

export const Row = ({ top, children, ...props }) => {
  if (typeof top === 'undefined') {
    throw new Error('no top provided');
  }

  const { vbWidth, isBelowBreakpoint, rowHeight } = useContext(
    ResponsiveSVGContext
  );

  return (
    <g width="100%" x="0" {...props}>
      {React.Children.map(children, (child, idx) => {
        // assumes only 2 columns of 50% vb width
        const xOffset = idx > 0 && !isBelowBreakpoint ? vbWidth / 2 : 0;
        const yOffset = isBelowBreakpoint
          ? parseInt(top) + rowHeight * 2 * idx
          : top;
        const scale = isBelowBreakpoint ? 2 : 1;

        return React.cloneElement(child, {
          transform: `translate(${xOffset}, ${yOffset}) scale(${scale})`,
        });
      })}
    </g>
  );
};

// Added a separate component so no transform props are overriden of any other
// children.
export const Column = ({ children, ...props }) => <g {...props}>{children}</g>;
