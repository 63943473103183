import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AnonymousPageWrapper } from './AnonymousPageWrapper';
import { Helmet } from 'react-helmet';

import { useTranslation } from 'react-i18next';
import ttvIcon from '../img/ttv_icon.svg';
import srIcon from '../img/sr_icon.svg';
import completeLottieSR from '../lib/animations/lottie/completed_sr.json';
import completeLottie from '../lib/animations/lottie/completed.json';
import Lottie from 'react-lottie';

const VerifyEmailPage = ({ props, Authentication }) => {
  const { t } = useTranslation('registerPage');

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: window.isSkillReflect ? completeLottieSR : completeLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    eventListeners: [
      {
        eventName: 'loopComplete',
      },
    ],
  };

  return (
    <AnonymousPageWrapper className={'page--login'} title={'login'}>
      <Helmet>
        <title>Verify Email</title>
      </Helmet>
      <div className="userlogin-layout">
        <div className="userlogin__formwrapper">
          <img
            className={'userlogin__icon'}
            src={window.isSkillReflect ? srIcon : ttvIcon}
          />
          <div className={'user-login-form-step'}>
            <Lottie
              style={{ height: 200, width: 200 }}
              options={defaultOptions}
            />
            <h1>{t('emailVerified')}</h1>
            <p> {t('emailVerifiedSub')}</p>
            <Link to={'/login'} className={'btn btn-primary'}>
              {t('emailVerifiedLogin')}
            </Link>
          </div>
          <div className={'userlogin__bottom'}></div>
        </div>
      </div>
    </AnonymousPageWrapper>
  );
};

export default withRouter(VerifyEmailPage);
