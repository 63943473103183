import { interpolate, useVideoConfig } from 'remotion';
import { useMemo } from 'react';

export const SponsorRuleRoundRobin: React.FC<{
  sponsors: [];
  sponsorDuration: number;
  frame: number;
  sponsorTimeBlocks: any[];
  absoluteFrame;
}> = ({ sponsors, sponsorTimeBlocks, absoluteFrame, sponsorDuration = 8 }) => {
  const videoConfig = useVideoConfig();

  const divisibleBlocks = useMemo(() => {
    return calculateBlocks(sponsorTimeBlocks, sponsors, sponsorDuration);
  }, [sponsors, sponsorTimeBlocks]);

  const frame = absoluteFrame;
  const fps = videoConfig.fps;
  const transitionInFrames = Math.round((sponsorDuration * 25) / 8);

  return (
    <div>
      {divisibleBlocks.map((block, index) => {
        const opacity = interpolate(
          frame,
          [
            block.start * fps,
            block.start * fps + transitionInFrames,
            block.end * fps - transitionInFrames,
            block.end * fps,
          ],
          [0, 1, 1, 0]
        );
        return (
          <img
            key={`sponsor-image-${index}`}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              left: 0,
              bottom: 0,
              opacity: opacity,
              objectFit: 'contain',
              objectPosition: 'left bottom',
            }}
            src={block.sponsor.imageUrl}
          />
        );
      })}
    </div>
  );
};

const calculateBlocks = (sponsorTimeBlocks, sponsors, sponsorDuration = 8) => {
  // TODO: fix when sponsors.length == 1 -> No transitions needed
  const nextSponsorToShow = (addedScreenTime, lastPlayedTime) => {
    // Sort sponsors primarily on screenTime.
    // If sponsors have equal screenTime, pick the one that hasn't been played for the longest.
    let thisSponsor = sponsors.sort((a, b) => {
      return a.screenTime - b.screenTime || a.lastPlayed - b.lastPlayed;
    })[0];

    thisSponsor.screenTime += addedScreenTime;
    thisSponsor.lastPlayed = lastPlayedTime;

    return thisSponsor;
  };
  const _divisibleBlocks = [];
  sponsorTimeBlocks.forEach((block) => {
    const secondsIntervalPerSponsor = sponsorDuration;
    const availableSlots = Math.max(
      1,
      Math.floor((block.end - block.start) / secondsIntervalPerSponsor)
    );

    for (let i = 0; i < availableSlots; i++) {
      // Count available sponsor slots
      const newBlockStart = block.start + i * secondsIntervalPerSponsor;
      const newBlockEnd = newBlockStart + secondsIntervalPerSponsor;
      if (block.end >= newBlockEnd + secondsIntervalPerSponsor) {
        // Sponsor will fit, next one will aswell
        _divisibleBlocks.push({
          sponsor: nextSponsorToShow(newBlockEnd - newBlockStart, newBlockEnd),
          start: newBlockStart,
          end: newBlockEnd,
        });
      } else {
        // Keep sponsor going till end
        _divisibleBlocks.push({
          sponsor: nextSponsorToShow(block.end - newBlockStart, block.end),
          start: newBlockStart,
          end: block.end,
        });
      }
    }
  });
  return _divisibleBlocks;
};
