import React, { useEffect, useState } from 'react';
import SportingEventCollection from 'domain/SportingEvent';
import VideoCollection from 'domain/Video';

import Authentication from 'utils/Authentication';
import UploadVideo from 'modules/match/components/UploadVideo';
import { ModalVideoPlayer } from './player';
import hexToHsl from 'hex-to-hsl';

function setColor(name, hex) {
  const [h, s, l] = hexToHsl(hex);

  const root = document.documentElement;
  root.style.setProperty(`--${name}`, hex);
  root.style.setProperty(`--${name}-h`, h);
  root.style.setProperty(`--${name}-s`, `${s}%`);
  root.style.setProperty(`--${name}-l`, `${l}%`);
}

export const Launch = () => {
  const [action, setAction] = useState(null);
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('embedding');

    window.parent.postMessage({ command: 'ready' }, '*');

    const close = () => {
      setAction(null);
      window.parent.postMessage({ command: 'close' }, '*');
    };
    const invokeCallback = (callbackId, eventName, args) => {
      window.parent.postMessage(
        {
          command: 'callback',
          callbackId,
          eventName,
          ...args,
        },
        '*'
      );
    };

    const receiveMessage = (e) => {
      const { command, ...args } = e.data;
      switch (command) {
        case 'setToken':
          Authentication.setToken(args.token);
          break;
        case 'uploadVideo':
          (async () => {
            const sportingEvent = await SportingEventCollection.getOrFetch(
              args.sportingEventId
            );
            await sportingEvent.loadTeams();
            const onStart = (videoId) => {
              if (typeof args.callbackId !== 'undefined') {
                invokeCallback(args.callbackId, 'Started', { videoId });
              }
            };
            const onUploaded = (videoId) => {
              if (typeof args.callbackId !== 'undefined') {
                invokeCallback(args.callbackId, 'Uploaded', { videoId });
              }
            };
            setAction(
              <UploadVideo
                sportingEvent={sportingEvent}
                onReady={close}
                onStart={onStart}
                onUploaded={onUploaded}
              />
            );
          })();
          break;
        case 'showClips':
          (async () => {
            const sportingEvent = await SportingEventCollection.getOrFetch(
              args.sportingEventId
            );
            const video = await VideoCollection.getOrFetch(
              args.videoId || sportingEvent.mainVideoId()
            );

            const videoFragments = args.clips.map((clip) => {
              return {
                ...clip,
                getVideo() {
                  return video;
                },
              };
            });
            setAction(
              <ModalVideoPlayer
                videoFragments={videoFragments}
                onClose={close}
                fullscreen={true}
              />
            );
          })();
          break;
        case 'setOptions':
          const { colors = {} } = args.options;
          if (!!colors.primary) {
            setColor('primary-color', colors.primary);
          }
          if (!!colors.secondary) {
            setColor('secondary-color', colors.secondary);
          }
          break;

        default:
          setTimeout(close, 10);
      }
    };
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return action;
};
