import React, { Component, memo } from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';

import { InputTrigger } from './InputTrigger';
import { asyncAction } from 'mobx-utils';

const ButtonWrapper = ({
  onClick,
  disabled,
  color,
  children,
  active,
  size,
}) => {
  const width = {
    large: '300px',
    medium: '150px',
    small: '100px',
  }[size ? size : 'medium'];
  const style = { border: `3px solid ${color}` };
  if (active) {
    style.backgroundColor = color;
    style.color = '#fff';
  }
  return (
    <button
      style={{
        width: width,
        borderRadius: '5px',
        padding: '5px',
        paddingTop: '12px',
        paddingBottom: '12px',
        margin: '5px',
        fontWeight: 'bold',
        fontSize: '16px',
        ...style,
      }}
      key="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const DynamicTriggerButton = observer(
  class TriggerButton extends Component {
    constructor() {
      super();
      this.oneShotButtonActive = observable(false);
    }

    isActive(groupId, observationCode, attributes) {
      return this.props.observationLogger.matchesCurrentObservationCodeAndAttributes(
        groupId,
        observationCode,
        attributes
      );
    }

    getProps() {
      switch (typeof this.props.buttonProps) {
        case 'undefined':
          return this.props;
        case 'function':
          return this.props.buttonProps();
        default:
          throw 'Unknown props';
      }
    }

    render() {
      const {
        type,
        groupId,
        observationCode,
        autoRestart,
        attributes,
        keyboardShortCutKeys,
        color,
        label,
        disabled,
        size,
        description,
      } = this.getProps();
      const observationLogger = this.props.observationLogger;

      let keyboardShortCut = null;
      if (typeof keyboardShortCutKeys !== 'undefined') {
        keyboardShortCut = keyboardShortCutKeys.join('+');
      }

      return (
        <InputTrigger
          {...{
            type,
            groupId,
            observationCode,
            autoRestart,
            attributes,
            observationLogger,
            description,
          }}
          render={({ active, trigger }) => {
            const components = [
              <ButtonWrapper
                key="button"
                size={size}
                onClick={trigger}
                active={active}
                disabled={disabled}
                color={color}
              >
                {label}
                {keyboardShortCut !== null ? (
                  <small>
                    <br />
                    {keyboardShortCut}
                  </small>
                ) : (
                  ''
                )}
              </ButtonWrapper>,
            ];
            if (keyboardShortCut !== null) {
              components.push(
                <HotKey
                  key="hotkey"
                  keys={keyboardShortCutKeys}
                  onKeysPressed={trigger}
                />
              );
            }
            return components;
          }}
        />
      );
    }
  }
);

const wait = ms => {
  return new Promise(success => {
    setTimeout(success, ms);
  });
};

const TriggerButton2 = ({ active, trigger, keys, description, classNames }) => {
  return (
    <div
      onClick={trigger}
      className={`clickable label ${active ? 'active' : ''} ${
        classNames ? classNames : ''
      }`}
    >
      {description}
      <span className="button-hotkey">{keys}</span>
      <HotKey keys={keys.split('+')} onKeysPressed={trigger} />
    </div>
  );
};

const TriggerButton = observer(
  class TriggerButton extends Component {
    constructor() {
      super();
      this.isActive = observable(false);
    }

    get controlledActiveState() {
      return typeof this.props.active !== 'undefined';
    }

    trigger = asyncAction(function*() {
      this.props.trigger();
      if (!this.controlledActiveState) {
        this.isActive.set(true);
        yield wait(400);
        this.isActive.set(false);
      }
    });

    render() {
      const { keys, description, classNames } = this.props;
      const trigger = this.trigger.bind(this);
      const active = this.controlledActiveState
        ? this.props.active
        : this.isActive.get();

      return (
        <div
          onClick={trigger}
          className={`clickable label ${active ? 'active' : ''} ${
            classNames ? classNames : ''
          }`}
        >
          {description}
          <span className="button-hotkey">{keys}</span>
          {!!keys && <HotKey keys={keys.split('+')} onKeysPressed={trigger} />}
        </div>
      );
    }
  }
);

const TriggerButton5 = memo(
  ({ active, keys, description, classNames, trigger, disableAfterTrigger }) => {
    console.log('rerendering');
    return (
      <div
        onClick={trigger}
        className={`clickable label ${active ? 'active' : ''} ${
          classNames ? classNames : ''
        } `}
      >
        {description}
        <span className="button-hotkey">{keys}</span>
        {!!keys && <HotKey keys={keys.split('+')} onKeysPressed={trigger} />}
      </div>
    );
  }
);

const RangeTriggerButton = ({ ...props }) => <TriggerButton {...props} />;

const PointTriggerButton = ({ classNames, ...props }) => (
  <TriggerButton
    classNames={`label-light ${classNames ? classNames : ''}`}
    {...props}
  />
);

const CustomTagButton = ({ classNames, ...props }) => (
  <TriggerButton classNames="label-secondary" {...props} />
);

export {
  DynamicTriggerButton,
  RangeTriggerButton,
  PointTriggerButton,
  CustomTagButton,
};
