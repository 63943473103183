import React from 'react';
import uuidv4 from 'uuid/v4';
import './styles/inputcheckbox.scss';
export const InputCheckboxRadio = ({
  checked,
  onChange,
  label,
  disabled,
  type = 'checkbox',
}) => {
  let id = uuidv4();
  return (
    <div className={'input-checkbox'}>
      <input
        checked={checked}
        id={id}
        disabled={disabled}
        type={type}
        onChange={() => !disabled && onChange(checked)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
