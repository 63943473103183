import { Modal } from '../../../../lib';
import React, { useEffect, useState } from 'react';
import { BroadcastIntegration } from './BroadcastIntegration';
import { ButtonGroupSelect } from '../../../../lib/ButtonGroupSelect';
import { Session } from '../../../../domain/Session';

export const Integrations = ({ sportingEvent, onClose }) => {
  const canUseBroadcast = Session.current().isFeatureAvailable('broadcast'); // permission.
  const canUseYT = false; // permission.
  const [currentIntegration, setCurrentIntegration] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  useEffect(() => {
    const _integrations = [];
    if (canUseBroadcast) {
      _integrations.push({ label: 'Broadcast', value: 'broadcast' });
    }

    if (canUseYT) {
      _integrations.push({ label: 'Youtube', value: 'yt', disabled: true });
    }

    if (_integrations.length > 0) {
      setIntegrations(_integrations);
      setCurrentIntegration(_integrations[0].value);
    }
  }, [sportingEvent]);

  const Component = () => {
    switch (currentIntegration) {
      case 'broadcast':
        return <BroadcastIntegration sportingEvent={sportingEvent} />;
      case 'other':
        return <div>Other</div>;
    }
  };

  if (integrations && currentIntegration) {
    return (
      <Modal onCloseClick={onClose}>
        <div>
          <div className="modal-header">
            <h5>Broadcast</h5>
          </div>
          {integrations.length > 1 && (
            <div className="form-group">
              <label>Select integration</label>

              <ButtonGroupSelect
                radio={true}
                options={integrations}
                value={currentIntegration}
                onChange={(v) => setCurrentIntegration(v)}
              />
            </div>
          )}

          {currentIntegration && <Component />}
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal onCloseClick={onClose}>
        <div>
          <div className="modal-header">
            <h5>Integrations</h5>
          </div>
          <div>No integrations available</div>
          <div className="form-actions">
            <button className={'btn btn-primary'} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    );
  }
};
