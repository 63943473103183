export class Time {
  public constructor(
    readonly eventTime: number,
    readonly startTime?: number,
    public endTime?: number
  ) {}

  setEndTime(endTime: number) {
    this.endTime = endTime;
  }
}
