import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { asyncAction } from 'mobx-utils';

import { CustomTagManager } from 'domain/CustomTag';
import { withTranslation } from 'react-i18next';

import './index.scss';

const CreateCustomTag = withTranslation('module.teamTags')(
  observer(
    class CreateCustomTag extends Component {
      constructor() {
        super();

        this.values = observable({
          description: '',
          key: '',
          durationBefore: 7,
          durationAfter: 5,
        });
      }

      handleChange = action((name, value) => {
        this.values[name] = value;
      });

      onSave = asyncAction(function*() {
        const customTag = yield CustomTagManager.create(this.values);
        this.props.onCustomTagAdded(customTag.customTagId);
      });

      render() {
        const standardTagsInUse =
          typeof this.props.observationInputConfiguration === 'undefined' ||
          this.props.observationInputConfiguration.getStandardTagsVisibility();

        return (
          <div className="create-custom-tag-view">
            <div className="modal-header">
              <h5 className="modal-title">{this.props.t('addTeamTag')}</h5>
            </div>
            <div className="row">
              <div className="col-12">
                <form>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>{this.props.t('tagName')}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={this.props.t('descriptionExample')}
                        value={this.values['description']}
                        name="description"
                        onChange={e =>
                          this.handleChange('description', e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-md-6 col-xl-3">
                      <label>{this.props.t('hotkey')}</label>
                      <select
                        className="form-control"
                        value={this.values['key']}
                        onChange={e => this.handleChange('key', e.target.value)}
                      >
                        <option value="">{this.props.t('noHotkey')}</option>
                        {CustomTagManager.getAvailableKeys(
                          standardTagsInUse
                        ).map(availableKey => (
                          <option key={availableKey} value={availableKey}>
                            {availableKey}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 col-xl-3">
                      <label>{this.props.t('durationBefore')}</label>
                      <input
                        type="number"
                        className="form-control"
                        min="3"
                        max="20"
                        placeholder={this.props.t('durationBefore')}
                        value={this.values['durationBefore']}
                        name="durationBefore"
                        onChange={e =>
                          this.handleChange('durationBefore', e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group col-md-6 col-xl-3">
                      <label>{this.props.t('durationAfter')}</label>
                      <input
                        type="number"
                        className="form-control"
                        min="3"
                        max="20"
                        placeholder={this.props.t('durationAfter')}
                        value={this.values['durationAfter']}
                        name="durationAfter"
                        onChange={e =>
                          this.handleChange('durationAfter', e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="row modal-footer">
                    <div className="col-12 form-actions">
                      <button
                        className="btn btn-primary"
                        onClick={e => {
                          this.onSave();
                          e.preventDefault();
                        }}
                      >
                        {this.props.t('addTeamTag')}
                      </button>
                      <button
                        className="btn btn-link"
                        onClick={e => {
                          e.preventDefault();

                          this.props.onCancel();
                        }}
                      >
                        {this.props.t('common:cancel')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export { CreateCustomTag };
