import React, { useState } from 'react';
import moment from 'moment';

import { withVisibleToggle } from 'lib/hocs/withVisibleToggle';
import { Notification, get, patch } from 'lib/notifications';
import VisibilitySensor from 'react-visibility-sensor';

import { useMutation, useQuery, useQueryClient } from 'react-query';

import './index.scss';
import { StoreLink } from '../store';

export interface MenuNotification extends Notification {
  type: 'menu';
  seen?: string; // '2021-07-20T08:03:13.196Z'
}

interface MenuNotificationsViewProps {
  notifications: MenuNotification[];
  targetRef?: React.LegacyRef<HTMLDivElement>;
  toggleRef?: React.LegacyRef<HTMLDivElement>;
  setViewed: (notification: MenuNotification) => any;
}

/**
 * @example
 * return (
 *   <MenuNotificationView
 *     notification={notification}
 *     onClose={handleClose}
 *   />)
 */
export const MenuNotificationView = ({
  notification,
  setViewed,
}: {
  notification: MenuNotification;
  setViewed: (notification: MenuNotification) => any;
}) => (
  <VisibilitySensor
    onChange={(visible) => visible && setViewed(notification)}
    active={!notification.viewed}
  >
    <div className="notifications-menu-notification">
      <div
        className="notifications-menu-notification-message"
        dangerouslySetInnerHTML={{ __html: notification.messageHtml }}
      />
      <div className="notifications-menu-notification-date">
        {moment(notification.created).fromNow()}
      </div>
    </div>
  </VisibilitySensor>
);

/**
 * @example
 * return (
 *   <MenuNotificationView
 *     notification={notification}
 *     onClose={handleClose}
 *   />)
 */
export const MenuNotificationsView = withVisibleToggle(
  ({
    notifications = [],
    targetRef,
    toggleRef,
    setViewed,
  }: MenuNotificationsViewProps) => {
    const unseenNotificationCount = notifications.filter(
      (notification) => !notification.viewed
    ).length;

    return (
      <div className="notifications-menu">
        <div
          className={`notifications-menu-icon ${
            notifications.length ? '' : 'd-none'
          }`}
          ref={toggleRef}
        >
          <i className="i-notification i-light" />
          {unseenNotificationCount ? (
            <div className="unread-badge">
              <span>{unseenNotificationCount}</span>
            </div>
          ) : null}
        </div>
        <div
          className={`menu-panel notifications-menu-panel ${''}`}
          ref={targetRef}
        >
          <div className="notifications-menu-panel-container">
            <div className="notifications-menu-title">Notifications</div>
            {notifications.map((notification) => (
              <MenuNotificationView
                key={notification.notificationId}
                notification={notification}
                setViewed={setViewed}
              />
            ))}
          </div>
        </div>
        <StoreLink />
      </div>
    );
  }
);

export const MenuNotificationContainer = () => {
  const queryKey = 'menuNotifications';
  const queryClient = useQueryClient();

  const { status, data } = useQuery(queryKey, () =>
    get({ seen: false, type: 'menu' })
  );

  const viewMutation = useMutation<any, any, Notification>(
    ({ notificationId }) => patch(notificationId, { viewed: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  if (status !== 'success') {
    return <MenuNotificationsView notifiations={[]} />;
  }

  const notifications = data as MenuNotification[];

  const notificationsSorted = notifications.sort(
    (left, right) =>
      new Date(right.created).valueOf() - new Date(left.created).valueOf()
  );

  return (
    <MenuNotificationsView
      notifications={notificationsSorted}
      setViewed={(notification) =>
        !notification.viewed && viewMutation.mutate(notification)
      }
    />
  );
  // return <MenuNotificationsView notifications={[]} />;
};
