import React from 'react';

export const PercentageLabel: React.FC<{
  percentage: any;
  tx?: number;
  ty?: number;
}> = ({ percentage, tx = 0, ty = 0 }) => {
  if (isNaN(percentage)) {
    percentage = '- ';
  } else {
    percentage = (percentage * 100).toFixed(0);
  }
  return (
    <g transform={`translate(${tx}, ${ty})`}>
      <rect
        className="background-rect-filler"
        fill="#ffffff"
        x="0"
        y="0"
        width="38"
        height="20"
        rx="7"
      />
      {/* <rect
        className="background-rect"
        x="0"
        y="0"
        width="38"
        height="20"
        rx="7"
      /> */}
      <text
        textAnchor="end"
        x="27"
        y="15"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="14"
        fontWeight="600"
        fill="#000000"
      >
        <tspan>{percentage}</tspan>
      </text>
      <text
        x="27.267"
        y="15"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="10"
        fontWeight="normal"
        fill="#000000"
      >
        <tspan>%</tspan>
      </text>
    </g>
  );
};
