import uuidv4 from 'uuid/v4';
import { Model } from 'mobx-rest';

import { PersistentCollection } from './PersistentCollection';
import { baseDurationBefore, baseDurationAfter } from 'lib/Config';

import { api } from 'utils/ApiClient';

const path = '/observations';

class Observation extends Model {
  get primaryKey() {
    return 'observationId';
  }

  get observationId() {
    return this.get('observationId');
  }

  get observationLogId() {
    return this.get('observationLogId');
  }

  get creatorRoleName() {
    if (this.has('creatorRoleName')) {
      return this.get('creatorRoleName');
    }
    return null;
  }

  get triggerTime() {
    return this.get('triggerTime');
  }

  get startTime() {
    return this.get('startTime');
  }

  get endTime() {
    return this.get('endTime');
  }

  get creatorUser() {
    if (this.has('creatorUser')) {
      return this.get('creatorUser');
    }
    return null;
  }

  get displayStartTime() {
    if (this.startTime === this.endTime) {
      return this.startTime - baseDurationBefore;
    } else {
      return this.startTime;
    }
  }

  get displayEndTime() {
    if (this.startTime === this.endTime) {
      return this.endTime + baseDurationAfter;
    } else {
      return this.endTime;
    }
  }

  get code() {
    return this.get('code');
  }

  get clockId() {
    return this.get('clockId');
  }

  get attributes_() {
    return this.get('attributes');
  }

  get description() {
    return this.get('description');
  }

  setTimes(clockId, triggerTime, startTime, endTime) {
    return this.collection.build({
      ...this.toJS(),
      clockId,
      triggerTime,
      startTime,
      endTime,
    });
  }
}

class ObservationCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Observation;
  }

  loading() {
    return this.isRequest('fetching');
  }

  setObservationLogId(observationLogId) {
    this._observationLogId = observationLogId;
  }

  observationLogId() {
    return this._observationLogId;
  }

  nextIdentity() {
    let lastObservationIndex = 0;
    for (const observation of this.toArray()) {
      const observationIndex = parseInt(
        observation.get('observationId').split('.')[1]
      );
      if (observationIndex > lastObservationIndex) {
        lastObservationIndex = observationIndex;
      }
    }
    const uniquePart = uuidv4().split('-')[1];
    return `${this.observationLogId()}.${lastObservationIndex +
      1}.${uniquePart}`;
  }

  addBulkObservation(observations) {
    return this.rpc('addBulkObservation', { observations });
  }

  // GET developmentplan/:id/observations
}

// START NEW API

// extracted from update function
export const changeset = ({ observation, params }) => {
  let changedAttributes = {};
  if (params.attributes !== null) {
    for (const key of Object.keys(params.attributes)) {
      if (observation.attributes[key] !== params.attributes[key]) {
        changedAttributes[key] = params.attributes[key];
      }
    }
  }

  const timeChanged =
    params.startTime !== observation.startTime ||
    params.endTime !== observation.endTime;

  if (Object.keys(changedAttributes).length > 0 || timeChanged) {
    return {
      observationId: observation.id,
      attributes: changedAttributes,
      description:
        params.description !== observation.description
          ? params.description
          : null,
      clockId: observation.clockId,
      triggerTime:
        params.triggerTime !== observation.triggerTime
          ? params.triggerTime
          : null,
      startTime: timeChanged ? params.startTime : null,
      endTime: timeChanged ? params.endTime : null,
    };
  } else {
    return {};
  }
};

export const get = async (query = '') => {
  const response = await api.get(`observations${query}`);
  return response.data;
};

const patch = async ({ id, data = {} }) => {
  const response = await api.patch(`observations/${id}`, data);
  return response.data;
};

export const delete_ = async ({ observationId }) => {
  await api.delete(`observations/${observationId}`);
};

export const update = ({ observation, params }) => {
  const data = changeset({ observation, params });

  if (Object.entries(data).length) {
    return patch({ id: observation.observationId, data });
  } else {
    return Promise.resolve();
  }
};

export const getComments = async observationId => {
  const { data } = await api.get(`/observations/${observationId}/comments`);
  return data;
};

export const addComment = async ({ observationId, comment, mentions = [] }) => {
  const { data } = await api.post(`/observations/${observationId}/comments`, {
    comment,
    mentions,
  });

  return data;
};

export const setSeenComment = async (observationId, commentId) => {
  await api.post(`/observations/${observationId}/comments/${commentId}/seen`);
};

function buildMap(sportingEventCollection) {
  const map = {};
  for (const sportingEvent of sportingEventCollection.toArray()) {
    for (const [clockSource, clock] of Object.entries(sportingEvent.clocks())) {
      if (clockSource !== 'U1') {
        map[
          `${sportingEvent.toJS().observationLogId}-${clock.clockId}`
        ] = clockSource;
      }
    }
  }
  return map;
}

export const videoId = (sportingEventCollection: object) => (
  observation: object
) => {
  const videoIds = buildMap(sportingEventCollection);
  return videoIds[`${observation.observationLogId}-${observation.clockId}`];
};

export const sportingEvent = (
  sportingEventCollection: object,
  observation: object
) => {
  const se = sportingEventCollection
    .toArray()
    .find(se => se.get('observationLogId') === observation.observationLogId)
    ?.toJS();
  return se;
};

export interface ObservationRating {
  key: string;
  color: string;
  i18nKey: string;
}

export const ratings: ObservationRating[] = [
  {
    key: 'positive',
    color: 'green',
    i18nKey: 'module.development:ratingPositive',
  },
  {
    key: 'neutral',
    color: 'orange',
    i18nKey: 'module.development:ratingNeutral',
  },
  {
    key: 'negative',
    color: 'red',
    i18nKey: 'module.development:ratingNegative',
  },
];

export const ratingColorByKey = ratingKey => {
  const rating = ratings.find(rating => rating.key === ratingKey);

  return rating?.color ?? 'grey';
};

export const displayTime = ({ startTime, endTime }, timeOffset = undefined) => {
  if (startTime instanceof String || typeof startTime === 'string') {
    // @ts-ignore
    startTime = new Date(startTime) / 1000 - new Date(timeOffset) / 1000;
    // @ts-ignore
    endTime = new Date(endTime) / 1000 - new Date(timeOffset) / 1000;
  }
  if (startTime === endTime) {
    return {
      displayStartTime: Math.max(0, startTime - baseDurationBefore),
      displayEndTime: endTime + baseDurationAfter,
    };
  } else {
    return {
      displayStartTime: Math.max(0, startTime),
      displayEndTime: Math.max(0, endTime),
    };
  }
};

export default ObservationCollection;
