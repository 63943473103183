import { VideoFragment } from '../../VideoFragment';
import { TypedCell } from '../../Cell';

export type ShotMetricType = {
  total: number;
  scored: number;
};

type SingleShotMetricType = {
  scored: boolean;
};

export type FoulMetricType = {
  total: number;
  fouled: number;
};

type ShotPosition = {
  position: {
    distance: number;
    x: number;
  };
  goalPosition: {
    x: number;
    y: number;
  };
};

const emptyShotCell: () => TypedCell<ShotMetricType> = () => {
  return new TypedCell<ShotMetricType>({ total: 0, scored: 0 }, []);
};

export class PlayerReport {
  constructor(
    readonly name: string | null,
    public shots_6_7m: TypedCell<ShotMetricType> = emptyShotCell(),
    public shots_7_9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public shots_gt9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public shots_all: TypedCell<ShotMetricType> = emptyShotCell(),
    public penalties: TypedCell<ShotMetricType> = emptyShotCell(),
    public shots: TypedCell<SingleShotMetricType, ShotPosition>[] = []
  ) {}
}

export class PositionReport {
  constructor(
    public left: TypedCell<ShotMetricType> = emptyShotCell(),
    public left_center_6_7m: TypedCell<ShotMetricType> = emptyShotCell(),
    public left_center_7_9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public left_center_gt9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public center_6_7m: TypedCell<ShotMetricType> = emptyShotCell(),
    public center_7_9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public center_gt9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public right_center_6_7m: TypedCell<ShotMetricType> = emptyShotCell(),
    public right_center_7_9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public right_center_gt9m: TypedCell<ShotMetricType> = emptyShotCell(),
    public right: TypedCell<ShotMetricType> = emptyShotCell()
  ) {}
}

export class TeamReport {
  constructor(
    public teamName: string,
    public attackFouls: TypedCell<FoulMetricType> = new TypedCell<
      FoulMetricType
    >({ total: 0, fouled: 0 }, []),
    public attackScores: TypedCell<ShotMetricType> = emptyShotCell(),
    public shots: TypedCell<ShotMetricType> = emptyShotCell(),

    public playerReports: PlayerReport[] = [],
    public positionReport: PositionReport = new PositionReport()
  ) {}
}

export class Report {
  public home: TeamReport = new TeamReport('home');
  public away: TeamReport = new TeamReport('away');
  public metadata: any;
}
