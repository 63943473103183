import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';
import logger from '../utils/Logger';

export const Aside = ({
  children,
  onClose,
  width = 450,
  transitionDuration = 500,
  isCloseAble = true,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const _width = Math.min(width, window.innerWidth * 0.9);
    if (children && isOpen) {
      document.documentElement.style.setProperty(
        '--aside-width',
        _width + 'px'
      );
      document.documentElement.style.setProperty(
        '--aside-transition-duration',
        transitionDuration + 'ms'
      );
      document.documentElement.style.setProperty(
        '--aside-indent',
        _width * -1 + 'px'
      );
      document.getElementById('aside').classList.add('opened');
      document.body.classList.add('prevent-scroll');
    }
    return async () => {
      _onClose();
    };
  }, []);

  const hideAside = async () => {
    // Start Closing
    document.getElementById('aside').classList.remove('opened');
    document.body.classList.remove('prevent-scroll');
    // Clear content after animation
    return await new Promise((resolve) =>
      setTimeout(() => {
        // Remove all set properties after closing animation has finished
        document.documentElement.style.removeProperty('--aside-width');
        document.documentElement.style.removeProperty(
          '--aside-transition-duration'
        );
        document.documentElement.style.removeProperty('--aside-indent');
        resolve();
      }, transitionDuration)
    );
  };

  const _onClose = async () => {
    await hideAside();
    if (typeof onClose == 'function') onClose();
    setIsOpen(false);
  };

  return (
    <>
      {isOpen &&
        createPortal(
          <>
            <div
              className="aside__backdrop"
              onClick={isCloseAble ? _onClose : () => {}}
            ></div>
            {isCloseAble && (
              <div className={`modal-close clickable `} onClick={_onClose} />
            )}
            <div className={'aside__inner'}>{children}</div>
          </>,
          document.getElementById('aside')
        )}
    </>
  );
};
