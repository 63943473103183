import { Time } from './Time';
import { IObservationTreeBuilder } from './IObservationTreeBuilder';
import { ObservationTree } from './ObservationTree';
import { IObservation } from './IObservation';
import { ObservationTreeNode } from './ObservationTreeNode';
import { ITeam } from './ITeam';
import { treeSetCurrentObservation } from './treeSetCurrentObservation';
import { displayTime } from '../../domain/Observation';

export class DefaultObservationTreeBuilder implements IObservationTreeBuilder {
  constructor(
    readonly matchConfig: any,
    readonly homeTeam: ITeam,
    readonly awayTeam: ITeam,
    readonly context: any
  ) {}

  build(observations: IObservation[]) {
    const observationTree = new ObservationTree();
    const timeOffset = this.context.getLiveClockStartTime();

    for (const observation of observations) {
      const { displayStartTime } = displayTime(observation, timeOffset);
      const node = new ObservationTreeNode(
        observation.observationId,
        observation.description,
        new Time(displayStartTime),
        undefined,
        observation.code === 'CUSTOM' ? 'C' : undefined,
        ['EDIT'],
        observation.attributes_.rating,
        observation.creatorUser
      );
      observationTree.addNode(node);
    }
    treeSetCurrentObservation({
      tree: observationTree,
      clock: this.context.clock,
    });

    return observationTree;
  }
}
