import { getClipConfig } from '../../remotion/clipRegistry';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useQuery, useQueryClient } from 'react-query';
import { colorContrast } from '../../remotion/helpers/colorContrast';

export const ContentEditView = ({
  content,
  onEditContent,
  onChange,
  isEdit = true,
}) => {
  const { t } = useTranslation('module.highlights');
  const config = getClipConfig(content.type);
  const Component = {
    text: Text,
    color: Color,
    images: Images,
  };

  const _onChange = (label, value) => {
    content.options[label] = value;
    if (typeof onChange !== 'undefined') {
      onChange(content);
    }
  };
  const currentLanguage = i18n?.language ?? 'en';

  return (
    <div>
      <h5>
        {t('common:edit')} {config?.name['en']}
      </h5>
      {Object.entries(config.options).map(([key, values], index) => {
        const Input = Component[values.type];
        return (
          <Input
            index={index}
            key={`${key}_${index}`}
            label={key}
            name={values.label[currentLanguage]}
            value={content?.options[key]}
            defaults={config?.defaults}
            onChange={_onChange}
          />
        );
      })}
      {typeof onEditContent !== 'undefined' && (
        <div className="form-actions">
          <button
            onClick={() => {
              onEditContent(content);
            }}
            className={'btn btn-primary'}
          >
            {isEdit ? t('common:save') : t('common:add')}
          </button>
        </div>
      )}
    </div>
  );
};

const Text = ({ label, value, onChange, defaults, name }) => {
  const [_value, setValue] = useState(value);

  const _onChange = (value) => {
    setValue(value);
    onChange(label, value);
  };
  return (
    <div className="form-group">
      <label>{name}</label>
      <input
        className="form-control"
        type={'text'}
        name={label}
        value={_value ?? defaults[label]}
        onChange={(e) => _onChange(e.target.value)}
      />
    </div>
  );
};

export const Color = ({ label, value, onChange, defaults, name }) => {
  const [_value, setValue] = useState(value);
  const _onChange = (value) => {
    setValue(value);
    onChange(label, value);
  };

  const { t } = useTranslation('module.highlights');

  const queryClient = useQueryClient();

  const { isFetched, data: recentlyUsedColors } = useQuery(
    ['highlights.recentlyUsedColors'],
    () => {
      return (
        window.localStorage
          .getItem('highlights.recentlyUsedColors')
          ?.split(',') ?? []
      );
    },
    { placeholderData: [], initialData: [] }
  );

  const addRecentlyUsedColor = (value) => {
    // const addedColor = recentlyUsedColors.splice(4, 1, value);
    const addedColor = [value, ...recentlyUsedColors].slice(0, 5);
    window.localStorage.setItem(
      'highlights.recentlyUsedColors',
      addedColor.join(',')
    );
    queryClient.invalidateQueries(['highlights.recentlyUsedColors']);
  };

  const colorPickerRef = useRef();
  const colorAddRef = useRef();

  useEffect(() => {
    colorPickerRef?.current.addEventListener(
      'change',
      (e) => _onChange(e.target.value),
      false
    );
    colorAddRef?.current.addEventListener(
      'change',
      (e) => {
        _onChange(e.target.value);
        addRecentlyUsedColor(e.target.value);
      },
      false
    );
    colorPickerRef?.current.addEventListener(
      'input',
      (e) => _onChange(e.target.value),
      false
    );
    colorAddRef?.current.addEventListener(
      'input',
      (e) => _onChange(e.target.value),
      false
    );
    // return () => {
    //   colorPickerRef?.current?.removeEventListener('input');
    //   colorPickerRef?.current?.removeEventListener('change');
    // };
  }, [colorPickerRef?.current, recentlyUsedColors]);

  return (
    <div className="form-group">
      <label>{name}</label>
      <div></div>
      <div className="colorpicker">
        <div
          style={{
            padding: '6px',
          }}
        >
          <div
            onClick={() => {
              colorPickerRef.current.click();
            }}
            className={'colorpicker__selected'}
            style={{
              backgroundColor: _value,
              color: colorContrast(_value, '#fff', '#101010'),
            }}
          >
            <input
              className="form-input-color"
              type={'color'}
              name={label}
              ref={colorPickerRef}
              value={_value ?? defaults[label]}
              style={{ appearance: 'none', position: 'absolute', opacity: 0 }}
            />
            <span>
              <strong>{t('colorPicker.chooseColor')}</strong>
              <br /> {t('colorPicker.alternative')}
            </span>
          </div>
        </div>

        <div className="d-flex">
          {isFetched &&
            recentlyUsedColors.map((color, index) => {
              let active = color === _value;
              let activeStyle = active
                ? {
                    borderColor: '#35da00',
                    width: '42px',
                    height: '42px',
                    boxShadow: '0 0 6px rgba(0,0,0,0.2)',
                  }
                : {};
              return (
                <div
                  key={`${index}-${color}`}
                  style={{
                    padding: active ? '3px' : '6px',
                  }}
                >
                  <div
                    className={'colorpicker__option'}
                    style={{
                      backgroundColor: color,
                      borderRadius: '7px',
                      ...activeStyle,
                    }}
                    onClick={() => {
                      _onChange(color);
                    }}
                  ></div>
                </div>
              );
            })}
          <div
            style={{
              padding: '6px',
              position: 'relative',
              borderLeft: 'solid 1px #ddd',
            }}
          >
            <input
              className="form-input-color"
              type={'color'}
              name={label}
              ref={colorAddRef}
              value={_value ?? defaults[label]}
              style={{ appearance: 'none', position: 'absolute', opacity: 0 }}
            />
            <div
              onClick={() => {
                colorAddRef.current.click();
              }}
              style={{
                width: '36px',
                height: '36px',
                backgroundColor: '#222',
                borderRadius: '7px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <i className={`i-add i-light`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Images = ({ label, value, onChange, defaults, name, maxLength = 5 }) => {
  const [output, setOutput] = useState([...value]);
  const regex = /(https?:\/\/.*\.(?:png|jpg|jpeg|svg|webp))/i;
  const inputs = Array.from(Array(Math.min(output.length + 1, maxLength)));
  const { t } = useTranslation('module.highlights');

  const _onChange = (value, index) => {
    if (value.match(regex)) {
      setOutput((o) => {
        const newValue = [...o];
        newValue[index] = value;
        onChange(label, newValue);
        return newValue;
      });
    }
  };
  const removeIndex = (index) => {
    setOutput((o) => {
      const newValue = [...o];
      newValue.splice(index, 1);
      onChange(label, newValue);
      return newValue;
    });
  };

  return (
    <div className="form-group">
      <label>{name}</label>
      {inputs.map((x, index) => {
        return (
          <div className={'d-flex mt-2'}>
            {output[index] && !output[index].match(regex) && (
              <>
                ❌
                <input
                  className="form-control error"
                  type={'text'}
                  name={label}
                  value={output[index] ?? defaults[index]}
                  onChange={(e) => _onChange(e.target.value, index)}
                />
              </>
            )}
            {!output[index] && (
              <input
                className="form-control"
                type={'text'}
                name={label}
                placeholder={`📋 ${t('pasteALink')}`}
                value={output[index] ?? defaults[index]}
                onChange={(e) => _onChange(e.target.value, index)}
              />
            )}
            {output[index]?.match(regex) && (
              <img src={output[index]} width={150} />
            )}
            {output[index] ? (
              <button onClick={() => removeIndex(index)}>&times;</button>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};
