import React, { useState } from 'react';

import { Notification, get, patch } from 'lib/notifications';

import './index.scss';
import { useMutation, useQuery, useQueryClient } from 'react-query';

type NotificationOnClose = (notification: Notification) => void;
// type NotificationOnClose = any;

interface BannerNotificationViewProps {
  notification: Notification;
  onClose: NotificationOnClose;
}

/**
 * @example
 * const notification = {
 *   id: '22b5ba01-a8a4-4cc0-8c88-dbac05030218',
 *   messageHTML: `
 *     <span>
 *       You're using a free version of TeamTV. Upgrade your plan to enable all
 *       features.
 *       <button type="button" onClick="(function(){
 *             console.debug('upgrade now clicked');
 *             return false;
 *           })();return false;"
 *         class="btn btn-link"
 *       >
 *         Upgrade&nbsp;now
 *       </button>
 *     </span>`,
 *   priority: 3,
 *   icon: 'warning',
 *   showCloseButton: true,
 * }
 * const handleClose = (notification) => { ...remove notification };
 *
 * return (
 *   <NotificationView
 *     notification={notification}
 *     onClose={handleClose}
 *   />)
 */
export const BannerNotificationView = ({
  notification,
  onClose,
}: BannerNotificationViewProps) => (
  <div className="notifications-banner">
    {notification.config.icon && (
      <i
        className={`notifications-banner-icon i-${notification.config.icon} i-light`}
      />
    )}
    <div
      className="notifications-banner-message"
      dangerouslySetInnerHTML={{ __html: notification.messageHtml }}
    />
    {notification.config.closable && (
      <i
        className="notifications-banner-icon i-cross i-light clickable"
        onClick={() => onClose(notification)}
      />
    )}
  </div>
);

export const BannerNotificationContainer = ({ session }: { session: any }) => {
  const queryKey = 'bannerNotifications';
  const queryClient = useQueryClient();

  const { status, data } = useQuery(queryKey, () =>
    get({ seen: false, type: 'banner' })
  );

  const closeMutation = useMutation<any, any, Notification>(
    ({ notificationId }) => patch(notificationId, { seen: true, closed: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  if (status !== 'success') {
    return null;
  }

  let notifications = data as Notification[];

  const currentTenantId = session.currentTenantId();

  notifications = notifications.filter((notification: Notification) => {
    if (!!notification.config.showAtTenantId) {
      return notification.config.showAtTenantId === currentTenantId;
    }
    return true;
  });

  // no notification returned
  if (!notifications.length) {
    return null;
  }

  const notification = notifications.sort(
    (left, right) => right.config.priority - left.config.priority
  )[0];

  return (
    <BannerNotificationView
      notification={notification}
      onClose={(notification) => closeMutation.mutate(notification)}
    />
  );
};
