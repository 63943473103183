import React from 'react';

const UnsavedChangesButton = ({
  t,
  syncOpenChanges,
  numberOfUnsavedChanges,
}) => {
  const buttonTranslationKey =
    numberOfUnsavedChanges === 1
      ? 'unsavedChangeButton'
      : 'unsavedChangesButton';
  return (
    <button type="button" className="btn btn-primary" onClick={syncOpenChanges}>
      <i className="i-upload i-xs i-light"></i>{' '}
      {t(buttonTranslationKey, { numberOfUnsavedChanges })}
    </button>
  );
};

export default UnsavedChangesButton;
