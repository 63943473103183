import { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';

import {
  Video,
  AbsoluteFill,
  interpolate,
  OffthreadVideo,
  useCurrentFrame,
  useVideoConfig,
  getRemotionEnvironment,
} from 'remotion';

import { Scoreboard } from '../overlays/scoreboard';
import { PausableVideo } from '../helpers/PausableVideo';
import { calculateFocalPointOffset } from '../helpers/calculateFocalPointOffset';

export const HighlightClip = ({ clip, durationInFrames }) => {
  const frame = useCurrentFrame();
  const videoConfig = useVideoConfig();

  return (
    <AbsoluteFill>
      {/*<AbsoluteFill*/}
      {/*  style={{*/}
      {/*    width: '100%',*/}
      {/*    height: '100%',*/}
      {/*    backgroundImage: `url(${clip.thumbnailUrl})`,*/}
      {/*    filter: 'blur(4px)',*/}
      {/*    backgroundSize: 'cover',*/}
      {/*  }}*/}
      {/*/>*/}
      <ClipVideo clip={clip} {...durationInFrames} />
      {clip.overlays?.includes('scoreboard') && clip?.$sportingEvent && (
        <Scoreboard clip={clip} />
      )}
    </AbsoluteFill>
  );
};

const ClipVideo = ({ clip, durationInFrames }) => {
  const videoRef = useRef();
  const containerRef = useRef();
  const { isRendering } = getRemotionEnvironment();
  const videoConfig = useVideoConfig();

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [hls, setHls] = useState(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (clip.videoUrl.indexOf('.m3u8') === -1) {
      return;
    }

    const shouldUseHlsJs = !videoRef.current.canPlayType(
      'application/vnd.apple.mpegurl'
    );

    const _hls =
      shouldUseHlsJs && Hls.isSupported()
        ? new Hls({
            // autoStartLoad: true,
            debug: process.env?.HLS_DEBUG === 'true',
            // startFragPrefetch: true,
            //
            // maxBufferLength: videoConfig.fps,
            // maxMaxBufferLength: videoConfig.fps,
            // maxBufferSize: durationInFrames * videoConfig.fps,
            // maxBufferHole: 0.5,
          })
        : null;
    setHls(_hls);

    if (Hls.isSupported() && _hls) {
      _hls.loadSource(clip.videoUrl);
      _hls.attachMedia(videoRef.current);
    } else {
      const video = videoRef.current;
      if (video) {
        video.addEventListener('canplaythrough', function () {
          var promise = video.play();
          if (promise !== undefined) {
            promise
              .catch(function (error) {
                console.log('Cannot start playback');
                console.log(error);
              })
              .then(function () {
                // setLogger((o) => [...o, 'Auto-play started']);
                console.log('video playback started started');
              });
          }
        }); //  Fires when the browser can play through the audio/video without stopping for buffering

        video.playsinline = 'true';
      }
    }
  }, [clip.videoUrl, videoRef.current]);

  const VideoComponent = isRendering ? OffthreadVideo : PausableVideo;

  useEffect(() => {
    if (clip?.focalPoint) {
      setOffset({
        x: calculateFocalPointOffset(
          clip.focalPoint.x,
          videoRef.current?.getBoundingClientRect().width,
          containerRef.current?.getBoundingClientRect().width
        ),
        y: calculateFocalPointOffset(
          clip.focalPoint.y,
          videoRef.current?.getBoundingClientRect().height,
          containerRef.current?.getBoundingClientRect().height
        ),
      });
    }
  }, [clip.focalPoint, videoConfig, videoRef.current]);

  return (
    <>
      <AbsoluteFill ref={containerRef} />
      <VideoComponent
        playbackRate={clip?.playbackRate ?? 1}
        volume={0.8} // FadeOut audio aswell
        style={{
          objectFit: 'cover',
          position: 'absolute',
          height: '100%',
          left: `calc(50% - ${offset.x}%)`,
          top: `calc(50% - ${offset.y}%)`,
          transform: 'translate(-50%,-50%)',
          aspectRatio: '16/9', // Fix for offset when clip has not yet loaded.
        }}
        hls={hls}
        ref={videoRef}
        src={clip.videoUrl}
      />
    </>
  );
};
