import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  HighlightContentBlockClip,
  HighlightContentBlockGeneric,
} from './highlighsContentBlock';
import { CachedAsyncImage } from './utils/clipThumbnail';

export const DragAndDropClips = ({
  content,
  highlightVideo,
  onDeleteContent,
  onTimeClick,
  onMoveContent,
  getClipVideo,
  getClipSportingEvent,
  openEditClip,
  template,
  openEditContent,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const grid = 6;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '1px',
    margin: `0 0 12px 0`,
    borderRadius: '7px',

    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: `${grid * 2} ${grid}`,
    width: '100%',
    marginTop: '1rem',
  });

  const [items, setitems] = useState(content);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const _items = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setitems(_items);
    onMoveContent(_items);
  };
  // Update component when clips change
  useEffect(() => {
    setitems(content);
  }, [content]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((content, index) => {
              const duration = content.end - content.start;
              let angle = getClipVideo(content.videoUri)?.tags?.output_key;
              const sportingEvent = getClipSportingEvent(
                content.sportingEventId
              );

              return (
                <Draggable
                  key={`drag${index}-${content.contentId}`}
                  draggableId={`drag${index}-${content.contentId}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      className={`${snapshot.isDragging ? 'is-dragging' : ''}`}
                    >
                      {content.type == 'clip.Video' && (
                        <HighlightContentBlockClip
                          key={`drag${index}-${content.contentId}`}
                          name={content.name}
                          sportingEventLabel={sportingEvent?.name}
                          index={index}
                          angle={angle}
                          startTime={content.startTime}
                          endTime={content.endTime}
                          duration={content.end - content.start}
                          seStart={content.start}
                          seEnd={content.end}
                          highlightVideoId={highlightVideo.highlightVideoId}
                          contentId={content.contentId}
                          onEditClip={openEditClip}
                          onDelete={() => onDeleteContent(content.contentId)}
                          onClick={onTimeClick(content.startTime)}
                          clip={content}
                          img={
                            <CachedAsyncImage
                              promise={async () => {
                                return await content.generateThumbnail(250);
                              }}
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              cacheKey={`${content.videoId}${content.start}-250`}
                            />
                          }
                        />
                      )}
                      {content.type !== 'clip.Video' && (
                        <HighlightContentBlockGeneric
                          openEditContent={openEditContent}
                          template={template}
                          content={content}
                          onClick={onTimeClick(
                            content.startTime - (content?.offset?.start ?? 0)
                          )}
                          onDeleteContent={onDeleteContent}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
