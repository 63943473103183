import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Loading from '../../Loading';

export const SelectUserMedia = ({ type, mediaSelectedCallback }) => {
  // Create a select dropdown to select user media, add callback that returns deviceID.
  const [options, setOptions] = useState();
  const [error, setError] = useState();
  const { t } = useTranslation('liveRecording');
  useEffect(() => {
    setup();
  }, []);
  let activeId;

  const setup = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(s => {
        var tracks = s.getTracks();
        navigator.mediaDevices.enumerateDevices().then(devices => {
          setOptions(
            devices
              .filter(device => device.kind === type)
              .map(o => {
                return {
                  value: o.deviceId,
                  label: o.label || `${type} ${devices.length + 1}`,
                };
              })
          );
          s.getTracks().forEach(track => track.stop());
        });
        activeId = tracks.find(t => t.kind === type.replace('input', ''));
      })
      .catch(error => {
        setError('Error accessing media devices.' + error);
        console.error('Error accessing media devices.', error);
      });
  };
  // Recreate mediadevices list when devices change.
  navigator.mediaDevices.ondevicechange = event => setup();

  if (options && options.length) {
    return (
      <div className={'form-group'}>
        <label>{t(type)}</label>
        <Select
          defaultValue={options.find(o => o.id === activeId)}
          options={options}
          onChange={option => mediaSelectedCallback(option.value)}
        />
      </div>
    );
  } else {
    if (error) {
      return (
        <>
          <div className="live-recording-error">
            <b>{t('noTypeInput', { type: type.replace('input', '') })}</b>
            <br />
            {t('noTypeInputExplanation', { type: type.replace('input', '') })}
            <br />

            <button
              className={'btn btn-link'}
              onClick={() => {
                setError('');
                setup();
              }}
            >
              <i className="i-refresh"></i>
              {t('retry')}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <Loading type={'dots'} color={'#ccc'} size={30} />
        </div>
      );
    }
  }
};
