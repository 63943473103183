import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
// import Range from 'rc-slider/lib/Range';

import { Range } from 'rc-slider';

import SportingEventCollection from '../../../domain/SportingEvent';
import TeamCollection from 'domain/Team';
import PersonCollection from 'domain/Person';
import { Session } from './../../../domain/Session';

import { Dashboard } from '../components/Dashboard';
import { FormattedTimeObserver } from 'lib/FormattedTime';
import { MatchClockHelper } from '../../../domain/ObservationLogger';
import Loading from 'lib/Loading';
import moment from 'moment';
import logger from '../../../utils/Logger';
import { EventPublisher } from 'utils/EventPublisher';
import { withTranslation } from 'react-i18next';
import { Page } from 'lib/Page';
import { Error } from 'lib/PlaceHolder/index';
import { ReportFetcherFactory } from 'lib/Reporting';

import './dashboard.scss';
import { NavLink } from 'react-router-dom';
import { DashboardV2 } from 'modules/reporting/components/DashboardV2';

const range = (N) => Array.apply(null, { length: N }).map(Number.call, Number);

const ReportDetail = withTranslation('module.reporting')(
  observer(
    class ReportDetail extends Component {
      constructor() {
        super();

        this.sportingEvent = null;
        this.report = null;
        this.reportId = observable('');
        this.fetchInterval = null;

        this.dataState = observable('loading');
      }

      componentWillMount() {
        this.loadData();
      }

      componentWillUnmount() {
        if (this.fetchInterval) {
          clearInterval(this.fetchInterval);
        }
      }

      refreshData = asyncAction(function* () {
        yield Promise.all([
          this.scoreReport.fetch(),
          this.report.fetch(),
          this.sportingEvent.fetch(),
        ]);
        if (this.sportingEvent.ended()) {
          clearInterval(this.fetchInterval);
        }
      });

      async loadReportByTimeFilter(startIndex, endIndex) {
        const reportId = Session.current().getDefaultReportId();
        const sportRules = Session.current().getSportRules();

        let timeFilter;
        // 4 = MAX

        if (startIndex === 0 && endIndex === 4) {
          timeFilter = '';
        } else {
          if (this.sportingEvent.periodCount === 4) {
            const startTimeMap = [
              'START_PERIOD:1',
              'START_PERIOD:2',
              'START_PERIOD:3',
              'START_PERIOD:4',
              'END_PERIOD:4',
            ];

            const endTimeMap = [
              'START_PERIOD:1',
              'END_PERIOD:1',
              'END_PERIOD:2',
              'END_PERIOD:3',
              'END_PERIOD:4',
            ];

            timeFilter = `,startTime=${startTimeMap[startIndex]},endTime=${endTimeMap[endIndex]}`;
          } else {
            const startTimeMap = [
              'START_PERIOD:1',
              `START_PERIOD:1+${parseInt(sportRules.periodDuration / 2)}`,
              'START_PERIOD:2',
              `START_PERIOD:2+${parseInt(sportRules.periodDuration / 2)}`,
              'END_PERIOD:2',
            ];

            const endTimeMap = [
              'START_PERIOD:1',
              `START_PERIOD:1+${parseInt(sportRules.periodDuration / 2)}`,
              'END_PERIOD:1',
              `START_PERIOD:2+${parseInt(sportRules.periodDuration / 2)}`,
              'END_PERIOD:2',
            ];

            timeFilter = `,startTime=${startTimeMap[startIndex]},endTime=${endTimeMap[endIndex]}`;
          }
        }

        const today = moment();
        const ended =
          this.sportingEvent.ended() ||
          moment(this.sportingEvent.get('scheduledAt')).isBefore(today, 'day');
        if (!ended) {
          this.sportingEvent.markReportsAsStale();
        }

        if (
          Session.current().sportType() === 'tennis' ||
          Session.current().sportType() === 'handball'
        ) {
          this.report = ReportFetcherFactory.build(
            Session.current().sportType(),
            this.sportingEvent
          );
          await this.report.fetch();
        } else {
          this.report = await this.sportingEvent.getReport(
            reportId + timeFilter
          );
        }

        runInAction(() => {
          this.reportId.set(reportId + timeFilter);
        });
      }

      get reportState() {
        return this.props.match.params.reportState;
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          yield PersonCollection.fetchIfEmpty();

          const sportingEvent = yield SportingEventCollection.getOrFetch(
            this.props.match.params.sportingEventId
          );
          this.sportingEvent = sportingEvent;

          yield sportingEvent.loadTeams();

          this.scoreReport = yield sportingEvent.getReport('score');

          const today = moment();
          const ended =
            sportingEvent.ended() ||
            moment(sportingEvent.get('scheduledAt')).isBefore(today, 'day');
          if (!ended) {
            console.log('Set autorefresh');
            this.fetchInterval = setInterval(() => {
              this.refreshData();
            }, 10000);
          }

          yield this.loadReportByTimeFilter(0, 4);

          this.dataState.set('loaded');

          EventPublisher.dispatch('DASHBOARD_VIEWED');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Reporting Dashboard',
          });
          this.dataState.set('error');
        }
      });

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const useV2Dashboard =
          ['tennis', 'handball'].indexOf(Session.current().sportType()) !== -1;
        const showHeader = Session.current().sportType() !== 'tennis';

        const layoutComponent = Session.current().getDefaultDashboardLayout();
        const sportRules = Session.current().getSportRules();

        const sportingEvent = this.sportingEvent;

        const timeFn = MatchClockHelper.getCurrentTimeFn(sportingEvent);

        const homeScore = this.scoreReport.c(
          `team_id:${sportingEvent.homeTeam.id}.score`
        ).count;
        const awayScore = this.scoreReport.c(
          `team_id:${sportingEvent.awayTeam.id}.score`
        ).count;

        const homeSubstitutionCount = this.scoreReport.c(
          `team_id:${sportingEvent.homeTeam.id}.substitutions`
        ).count;
        const awaySubstitutionCount = this.scoreReport.c(
          `team_id:${sportingEvent.awayTeam.id}.substitutions`
        ).count;

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { path: '/match', title: this.props.t('module.match:matches') },
            {
              title: `${this.sportingEvent.homeTeam.label} - ${this.sportingEvent.awayTeam.label}`,
            },
          ],
        };

        const synchronizationPoints =
          this.sportingEvent.mainSynchronizationPoints();

        const reportId = this.reportId.get();

        return [
          <Page key="dashboard" {...pageProps}>
            <div className="landscape-show">
              {showHeader && (
                <div className="title-wrapper relative width-100">
                  <div className="invoer-title relative break-after width-100">
                    <div className="container relative break-after height-100">
                      <div className="flex-content">
                        <div
                          id="team-1"
                          className="team-title float-left text-right"
                        >
                          <div
                            id="team-score-1"
                            className="float-right team-score"
                          >
                            <h1>{homeScore}</h1>
                          </div>
                          <img
                            className="team-thumb float-right"
                            src={sportingEvent.homeTeam.logoUrl}
                          />
                          <h1 className="inline-block float-right team-name hidden-md">
                            {sportingEvent.homeTeam.label}
                          </h1>
                        </div>
                        <div
                          id="team-2"
                          className="team-title float-left text-left"
                        >
                          <div
                            id="team-score-1"
                            className="float-left team-score"
                          >
                            <h1>{awayScore}</h1>
                          </div>
                          <img
                            className="team-thumb float-left"
                            src={sportingEvent.awayTeam.logoUrl}
                          />
                          <h1 className="inline-block float-left team-name hidden-md">
                            {sportingEvent.awayTeam.label}
                          </h1>
                        </div>
                        <div className="invoer-time text-center hidden-md">
                          <FormattedTimeObserver timeFn={timeFn} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {synchronizationPoints.length > 0 && !useV2Dashboard && (
                <div className="dashboard-time-frame">
                  <Range
                    max={4}
                    defaultValue={[0, 4]}
                    onAfterChange={([startIndex, endIndex]) =>
                      this.loadReportByTimeFilter(startIndex, endIndex)
                    }
                    marks={{
                      0: { label: this.props.t('startMatch') },
                      1: {
                        label:
                          sportingEvent.periodCount === 4
                            ? this.props.t('break1')
                            : '',
                      },
                      2: { label: this.props.t('halftime') },
                      3: {
                        label:
                          sportingEvent.periodCount === 4
                            ? this.props.t('break2')
                            : '',
                      },
                      4: { label: this.props.t('endMatch') },
                    }}
                  />
                </div>
              )}

              <div className="dashboard-wrapper relative width-100 break-after">
                <div className="info-icon" />

                {!useV2Dashboard && (
                  <Dashboard
                    includeVideo={true}
                    layoutComponent={layoutComponent}
                    report={this.report}
                    sportingEvent={sportingEvent}
                    reportState={this.reportState}
                  />
                )}
                {useV2Dashboard && (
                  <DashboardV2
                    layoutComponent={layoutComponent}
                    reportFetcher={this.report}
                    sportingEvent={sportingEvent}
                    reportState={this.reportState}
                  />
                )}
              </div>
            </div>
          </Page>,

          <div key="turn-device" className="portrait-show">
            <div className="container">
              <div className="flex-content">
                <div className="turn-center width-100">
                  <b>Draai</b> uw apparaat om gebruik te maken van deze functie.
                </div>
              </div>
            </div>
          </div>,
        ];

        //
        // return <JsxParser
        //     renderInWrapper={false}
        //     showWarnings={true}
        //     bindings={reportData}
        //     jsx={renderedSVG.replace(/>\s+</g,'><')} />;
        //
        // return <div>
        //     <img alt="dashboard" style={{width: "100%"}} src={`data:image/svg+xml,${renderedSVG}`} />
        // </div>;
        // //return <div>{this.props.match.params.sportingEventId}</div>;
      }
    }
  )
);

export default ReportDetail;
