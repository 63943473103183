import React, { Component, useState } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { SliderRail, Handle, Track, Tick } from './components';
import { formatTime } from 'lib/FormattedTime'; // example render components - source below

const sliderStyle = {
  position: 'relative',
  width: '100%',
};

function formatTick(time) {
  return formatTime(time);
}

export const TimeSlider = ({
  min,
  max,
  startTime,
  endTime,
  onChange,
  height = '60px',
}) => {
  const [newStartTime, setNewStartTime] = useState(startTime);
  const [newEndTime, setNewEndTime] = useState(endTime);
  // renderDateTime(date, header) {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         textAlign: 'center',
  //         fontFamily: 'Arial',
  //         margin: 5,
  //       }}
  //     >
  //       <b>{header}:</b>
  //       <div style={{ fontSize: 12 }}>{format(date, 'MMM dd h:mm a')}</div>
  //     </div>
  //   );
  // }

  const timeTicks = [];
  for (let i = min; i < max; i += 5) {
    timeTicks.push(i);
  }

  return (
    <div>
      <div style={{ margin: '5%', height, width: '90%' }}>
        <Slider
          mode={2}
          step={1}
          domain={[+min, +max]}
          rootStyle={sliderStyle}
          onChange={onChange}
          values={[startTime, endTime]}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div>
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[+min, +max]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks values={timeTicks}>
            {({ ticks }) => (
              <div>
                {ticks.map((tick) => (
                  <Tick
                    key={tick.id}
                    tick={tick}
                    count={ticks.length}
                    format={formatTick}
                  />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    </div>
  );
};

export const SimpleSlider = ({
  domain = [0, 100],
  values,
  onChange,
  step = 1,
  valuePrefix = '',
  valueSuffix = '',
}) => {
  const sliderStyle = {
    position: 'relative',
    width: 'calc(100% - 2rem)',
    touchAction: 'none',
  };

  const [_values, setValues] = useState(values);

  const _onChange = (values) => {
    setValues(values);
    onChange(values);
  };
  const _onUpdate = (values) => {
    setValues(values);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <Slider
        mode={1}
        step={step}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={_onChange}
        values={_values}
        onUpdate={_onUpdate}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
      <div style={{ marginLeft: '0.5em', whiteSpace: 'noWrap', flexGrow: 1 }}>
        {valuePrefix}
        {_values}
        {valueSuffix}
      </div>
    </div>
  );
};
