export const timeout = <T extends object | undefined>(value: T, ms = 1000) =>
  new Promise<T>((resolve) => window.setTimeout(resolve, ms, value));

export const dotPropGet = (obj, path_, default_ = null) => {
  const parts = path_.split('.');
  let value = obj;
  for (const part of parts) {
    if (typeof value[part] === 'undefined') {
      return default_;
    } else {
      value = value[part];
    }
  }
  return value;
};
