import appLogo from '../img/teamtv_logo.svg';
import appLogo_SR from '../img/logo_sr.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AnonymousPageWrapper = ({ title, className, children }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="app-header">
        <div className="app-header-content">
          <div className="app-header-branding">
            <img
              width={150}
              src={window.isSkillReflect ? appLogo_SR : appLogo}
            />
          </div>
          <div className={'app-header-userlogin-secondary'}>
            <a href={'/login'} className={'btn btn-outline'}>
              {t('registerPage:loginC')}
            </a>
            <a href={'/register'} className={'btn btn-outline'}>
              {t('registerPage:title')}
            </a>
          </div>
        </div>
      </div>
      <div className={`page ${className}`}>
        <header className="page-header">
          <div className="page-header-inset-wrapper">
            <div className="page-header-inset">
              <div className="page-title">{title}</div>
              <ol className="breadcrumb"></ol>
              {!window.isSkillReflect && (
                <div className="page-header-knowledge-base">
                  <a
                    href="https://help.teamtvsport.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    <span>{t('module.knowledgeBase:openKnowledgeBase')}</span>
                    <i className="i-knowledge-base i-light"></i>
                  </a>
                </div>
              )}
            </div>
          </div>
        </header>
        {children}
      </div>
    </>
  );
};
