import { Model } from 'mobx-rest';

import PersonCollection from '../Person';
import TeamCollection from '../Team';
import { PersistentCollection } from '../PersistentCollection';
import { observable, extendObservable } from 'mobx';

class LineUp extends Model {
  get primaryKey() {
    return 'lineUpId';
  }

  _ensureTeamRolesExists(teamId) {
    const rolesPerTeam = this.get('rolesPerTeam');
    if (!rolesPerTeam[teamId]) {
      // make sure it's an observable, otherwise when we add players to the team
      // the changes won't be picked up
      extendObservable(rolesPerTeam, { [teamId]: [] });
    }
  }

  getTeamRoles(teamId) {
    this._ensureTeamRolesExists(teamId);

    return (this.toJS().rolesPerTeam[teamId] || []).map((role) => {
      return this.createRole(
        teamId,
        role.personId,
        role.position,
        role.roleName
      );
    });
  }

  createRole(teamId, personId, position, roleName) {
    return {
      personId,
      position,
      roleName,

      person: PersonCollection.getOrEmpty(personId),
      get player() {
        const playerCollection = TeamCollection.get(teamId).players;
        const player = playerCollection.get(personId);
        if (player) {
          return player.toJS();
        }
        return {};
      },
    };
  }

  getPerson(personId) {
    // TODO: check if person is in LineUp
    return PersonCollection.get(personId);
  }

  addPlayer(teamId, personId, position) {
    this._ensureTeamRolesExists(teamId);

    const rolesPerTeam = this.get('rolesPerTeam');

    rolesPerTeam[teamId].push({
      roleName: 'player',
      personId,
      position,
    });

    //
    // return (withFetch) => this.rpc("addPlayer", {teamId, personId, position}).then(
    //     () => { return withFetch && this.fetch(); }
    // );
  }
  async swapAttackAndDefence(lineUpId, teamId) {
    await this.rpc('swapAttackDefence', { lineUpId, teamId });
    return this.fetch();
  }

  removePlayer(teamId, personId) {
    this._ensureTeamRolesExists(teamId);

    const rolesPerTeam = this.get('rolesPerTeam');
    rolesPerTeam[teamId] = rolesPerTeam[teamId].filter((role) => {
      return role.personId !== personId;
    });

    //
    // return (withFetch) => this.rpc("removePlayer", {personId}).then(
    //     () => { return withFetch && this.fetch(); }
    // );
  }
  //
  // addSubstitute(teamId, personId)
  // {
  //     const rolesPerTeam = this.get('rolesPerTeam');
  //     rolesPerTeam[teamId].push({
  //         roleName: 'substitute',
  //         personId
  //     });
  //     //
  //     // return (withFetch) => this.rpc("addSubstitute", {teamId, personId}).then(
  //     //     () => { return withFetch && this.fetch(); }
  //     // );
  // }
  //
  // removeSubstitute(teamId, personId)
  // {
  //     const rolesPerTeam = this.get('rolesPerTeam');
  //     rolesPerTeam[teamId] = rolesPerTeam[teamId].filter((role) => {
  //         return role.personId !== personId
  //     });
  //     //
  //     // return (withFetch) => this.rpc("removeSubstitute", {personId}).then(
  //     //     () => { return withFetch && this.fetch(); }
  //     // );
  // }

  // substitutePlayer(teamId, outPersonId, inPersonId, position)
  // {
  //     this.removePlayer(teamId, outPersonId);
  //     this.removeSubstitute(teamId, inPersonId);
  //     this.addSubstitute(teamId, outPersonId);
  //     this.addPlayer(teamId, inPersonId, position);
  // }
  //
  // substitutePlayer(teamId, outPersonId, inPersonId, position, time)
  // {
  //     return this.rpc("substitutePlayer", {teamId, outPersonId, inPersonId, position, time}).then(
  //         () => { return this.fetch(); }
  //     );
  // }
  //
  // resetTeamLineUp(teamId)
  // {
  //     return this.rpc("resetTeamLineUp", {teamId}).then(
  //         () => { return this.fetch(); }
  //     );
  // }
}

class LineUpCollection extends PersistentCollection {
  url() {
    return '/lineUps';
  }

  model() {
    return LineUp;
  }

  ensureLoaded() {
    if (this.isEmpty() && !this.loading()) this.fetch();
  }

  loading() {
    return this.isRequest('fetching');
  }

  isLoading() {
    return this.loading();
  }
}

console.log('init lineup collection');
const lineUpCollection = new LineUpCollection();
window.lineUpCollection = lineUpCollection;

export { lineUpCollection };
