import React, { useEffect, useRef } from 'react';

export const ScrollIntoView = () => {
  const ref = useRef();
  useEffect(() => {
    setTimeout(() => {
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }, [ref.current]);
  return <div ref={ref} />;
};
