import React, { Fragment } from 'react';

const Label = ({ x, y, cell, color, opacity, onClick }) => {
  const completedCount = cell.v('completed');
  const totalCount = cell.count;
  const percentage = totalCount > 0 ? completedCount / totalCount : 0;

  return (
    <g
      onClick={() => onClick(cell, 'completed')}
      className="clickable"
      transform={`translate(${x - 40}, ${y - 9})`}
      opacity={opacity}
    >
      <g fill={color}>
        <rect x="0" y="0" width="80" height="18" rx="7" />
      </g>
      <text
        x="40"
        y="14"
        textAnchor="middle"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="14"
        fontWeight="600"
        fill="#FFFFFF"
      >
        <tspan>
          {totalCount} / {(percentage * 100).toFixed(0)}%
        </tspan>
      </text>
    </g>
  );
};

const HOME_COLOR = '#0FCF85';
const AWAY_COLOR = '#CF0F0F';

const ConditionalLabel = ({ x, y, cell, color, onClick, opacity = 1 }) => {
  if (cell.count === 0 && false) {
    return null;
  }
  return (
    <Label
      x={x}
      y={y}
      cell={cell}
      color={color}
      onClick={onClick}
      opacity={opacity}
    />
  );
};

const Dashboard = ({ homeTeamReport, awayTeamReport, onClick }) => {
  const homeBox = homeTeamReport.c('end_section:4C');
  const awayBox = awayTeamReport.c('end_section:4C');

  return (
    <svg viewBox="0 0 940 540" version="1.1">
      <g
        id="Voetbal-Dashboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-8" fillRule="nonzero">
          <g id="Group-5" transform="translate(68, 10) scale(1.2)">
            <rect
              id="Rectangle-Copy-12"
              stroke="#FFFFFF"
              strokeWidth="2"
              fill="#003600"
              x="1"
              y="1"
              width="673"
              height="448"
            ></rect>
            <path
              d="M337.5,2.84217094e-14 L337.5,450"
              id="Line-Copy"
              stroke="#FFFFFF"
              strokeWidth="3"
            ></path>
            <rect
              id="Rectangle-Copy-16"
              stroke="#FFFFFF"
              strokeWidth="3"
              x="629.5"
              y="161.5"
              width="44"
              height="127"
            ></rect>
            <rect
              id="Rectangle-Copy-17"
              stroke="#FFFFFF"
              strokeWidth="3"
              x="561.5"
              y="89.5"
              width="112"
              height="271"
            ></rect>
            <rect
              id="Rectangle-Copy-17"
              stroke="#FFFFFF"
              strokeWidth="3"
              x="1.5"
              y="89.5"
              width="112"
              height="271"
            ></rect>
            <circle
              id="Oval-Copy"
              stroke="#FFFFFF"
              strokeWidth="3"
              cx="337.75"
              cy="224.75"
              r="57.75"
            ></circle>
            <path
              d="M561.5,187.570274 C548.805704,188.762371 538.5,204.974683 538.5,225 C538.5,245.025317 548.805704,261.237629 561.5,262.429726 L561.5,187.570274 Z"
              id="Combined-Shape-Copy-6"
              stroke="#FFFFFF"
              strokeWidth="3"
            ></path>
            <path
              d="M673.5,1.5 L661.539394,1.5 C662.031699,7.72509214 667.081722,12.7379396 673.5,13.4207192 L673.5,1.5 Z"
              id="Combined-Shape-Copy-8"
              stroke="#FFFFFF"
              strokeWidth="3"
            ></path>
            <path
              d="M673.5,436.5 L661.539394,436.5 C662.031699,442.725092 667.081722,447.73794 673.5,448.420719 L673.5,436.5 Z"
              id="Combined-Shape-Copy-8"
              stroke="#FFFFFF"
              strokeWidth="3"
              transform="translate(667.500000, 442.500000) scale(1, -1) translate(-667.500000, -442.500000) "
            ></path>
            <path
              d="M115,125 L560,125"
              id="Line-2-Copy-4"
              stroke="#FFFFFF"
              strokeLinecap="square"
              strokeDasharray="5,10"
            ></path>
            <path
              d="M336,225 L786,225"
              id="Line-2-Copy-6"
              stroke="#FFFFFF"
              strokeLinecap="square"
              strokeDasharray="5,10"
              transform="translate(561.000000, 225.000000) rotate(90.000000) translate(-561.000000, -225.000000) "
            ></path>
            <path
              d="M115,325 L560,325"
              id="Line-2-Copy-8"
              stroke="#FFFFFF"
              strokeLinecap="square"
              strokeDasharray="5,10"
            ></path>
            <rect
              id="Rectangle-Copy-19"
              fill="#0FCF85"
              x="665"
              y="194"
              width="10"
              height="62"
            ></rect>
            <rect
              id="Rectangle-Copy-16"
              stroke="#FFFFFF"
              strokeWidth="3"
              x="1.5"
              y="161.5"
              width="44"
              height="127"
            ></rect>
            <path
              d="M136.5,187.570274 C123.805704,188.762371 113.5,204.974683 113.5,225 C113.5,245.025317 123.805704,261.237629 136.5,262.429726 L136.5,187.570274 Z"
              id="Combined-Shape-Copy-6"
              stroke="#FFFFFF"
              strokeWidth="3"
              transform="translate(125.000000, 225.000000) scale(-1, 1) translate(-125.000000, -225.000000) "
            ></path>
            <path
              d="M13.5,1.5 L1.53939381,1.5 C2.03169903,7.72509214 7.08172205,12.7379396 13.5,13.4207192 L13.5,1.5 Z"
              id="Combined-Shape-Copy-8"
              stroke="#FFFFFF"
              strokeWidth="3"
              transform="translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) "
            ></path>
            <path
              d="M13.5,436.5 L1.53939381,436.5 C2.03169903,442.725092 7.08172205,447.73794 13.5,448.420719 L13.5,436.5 Z"
              id="Combined-Shape-Copy-8"
              stroke="#FFFFFF"
              strokeWidth="3"
              transform="translate(7.500000, 442.500000) scale(-1, -1) translate(-7.500000, -442.500000) "
            ></path>
            <path
              d="M-111,225 L339,225"
              id="Line-2-Copy-6"
              stroke="#FFFFFF"
              strokeLinecap="square"
              strokeDasharray="5,10"
              transform="translate(114.000000, 225.000000) rotate(90.000000) translate(-114.000000, -225.000000) "
            ></path>
            <rect
              id="Rectangle-Copy-19"
              fill="#CF0F0F"
              x="0"
              y="194"
              width="10"
              height="62"
            ></rect>

            <ConditionalLabel
              x="56"
              y="44"
              onClick={onClick}
              cell={awayBox.c('start_section:4R')}
              color={AWAY_COLOR}
            />
            <ConditionalLabel
              x="56"
              y="406"
              onClick={onClick}
              cell={awayBox.c('start_section:4L')}
              color={AWAY_COLOR}
            />

            <ConditionalLabel
              x="224"
              y="62"
              onClick={onClick}
              cell={awayBox.c('start_section:3R')}
              color={AWAY_COLOR}
            />
            <ConditionalLabel
              x="224"
              y="225"
              onClick={onClick}
              cell={awayBox.c('start_section:3C')}
              color={AWAY_COLOR}
            />
            <ConditionalLabel
              x="224"
              y="388"
              onClick={onClick}
              cell={awayBox.c('start_section:3L')}
              color={AWAY_COLOR}
            />

            <ConditionalLabel
              x="449"
              y="62"
              onClick={onClick}
              cell={homeBox.c('start_section:3L')}
              color={HOME_COLOR}
            />
            <ConditionalLabel
              x="449"
              y="225"
              onClick={onClick}
              cell={homeBox.c('start_section:3C')}
              color={HOME_COLOR}
            />
            <ConditionalLabel
              x="449"
              y="388"
              onClick={onClick}
              cell={homeBox.c('start_section:3R')}
              color={HOME_COLOR}
            />

            <ConditionalLabel
              x="618"
              y="44"
              onClick={onClick}
              cell={homeBox.c('start_section:4L')}
              color={HOME_COLOR}
            />
            {/*<ConditionalLabel x="618" y="225" cell={destination.c('start_section:4C')} />*/}
            <ConditionalLabel
              x="618"
              y="406"
              onClick={onClick}
              cell={homeBox.c('start_section:4R')}
              color={HOME_COLOR}
            />

            {/*<!--<rect id="Rectangle-Copy-17" fill="#FFFFFF" opacity="0.4" strokeWidth="3" x="1.5" y="89.5" width="112" height="271"></rect>--> */}
          </g>
        </g>
      </g>
    </svg>
  );
};

/*
 <ConditionalLabel x="224" y="46" cell={awayBox.c('start_section:3R')} color={AWAY_COLOR} />
 <ConditionalLabel x="224" y="78" cell={homeBox.c('start_section:2L')} color={HOME_COLOR} opacity={0} />

 <ConditionalLabel x="224" y="209" cell={awayBox.c('start_section:3C')} color={AWAY_COLOR} />
 <ConditionalLabel x="224" y="241" cell={homeBox.c('start_section:2C')} color={HOME_COLOR} opacity={0} />

 <ConditionalLabel x="224" y="372" cell={awayBox.c('start_section:3L')} color={AWAY_COLOR} />
 <ConditionalLabel x="224" y="404" cell={homeBox.c('start_section:2R')} color={HOME_COLOR} opacity={0} />


 <ConditionalLabel x="449" y="46" cell={homeBox.c('start_section:3L')} color={HOME_COLOR} />
 <ConditionalLabel x="449" y="78" cell={awayBox.c('start_section:2R')} color={AWAY_COLOR} opacity={0} />

 <ConditionalLabel x="449" y="209" cell={homeBox.c('start_section:3C')} color={HOME_COLOR} />
 <ConditionalLabel x="449" y="241" cell={awayBox.c('start_section:2C')} color={AWAY_COLOR} opacity={0} />

 <ConditionalLabel x="449" y="372" cell={homeBox.c('start_section:3R')} color={HOME_COLOR} />
 <ConditionalLabel x="449" y="404" cell={awayBox.c('start_section:2L')} color={AWAY_COLOR} opacity={0} />

 */

export default Dashboard;
