import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { NavPrimary } from 'lib/NavPrimary';
import { menuItemsForType } from '../../resource-group-types';

const MainMenu = ({ session, t, toggle, app }) => {
  const [isPortalElementReady, setIsPortalElementReady] = useState(
    document.getElementById('app-header-center') !== null
  );

  const activeGroupType = session.currentResourceGroupType();
  const menuItems = menuItemsForType(
    { session, t, toggle, app },
    activeGroupType
  );

  useEffect(() => {
    setIsPortalElementReady(true);
  }, []);

  if (!isPortalElementReady) {
    return null;
  }

  if (!app) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <NavPrimary
          session={session}
          t={t}
          toggle={() => {}}
          menuItems={menuItems}
          key="1"
        />,
        document.getElementById('app-header-center')
      )}
      <NavPrimary
        session={session}
        t={t}
        toggle={toggle}
        menuItems={menuItems}
        key="2"
      />
    </>
  );
};

export { MainMenu };
