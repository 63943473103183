import './userLogin.scss';
import React, { useState } from 'react';
import { RegisterForm } from '../lib/UserLogin/RegisterForm';
import { AnonymousPageWrapper } from './AnonymousPageWrapper';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ttvIcon from '../img/ttv_icon.svg';
import srIcon from '../img/sr_icon.svg';

function getCookie(name) {
  function escape(s) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
  }
  var match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
  );
  return match ? match[1] : null;
}

const RegisterPage = ({ props }) => {
  const { t } = useTranslation('registerPage');
  const [step, setStep] = useState(0);

  let tags = null;
  let email;
  if (props.location.search) {
    // Decode tags from URL
    let params = new URLSearchParams(props.location.search);
    if (params.get('tags')) {
      // Tags sent from webflow account register
      tags = window.atob(params.get('tags'));
    }
    email = params.get('email');
  }
  const tagsFromCookie = getCookie('tags');
  if (tagsFromCookie && tags === null) {
    tags = window.atob(tagsFromCookie);
  }
  if (tags === null) {
    // Set as default ref for tags when logging referrals. Should be overwritten above unless register page was accessed directly.
    tags = 'ref:__DIRECT__';
  }

  const stepHandler = {
    next: () => {
      setStep((currentStep) => currentStep + 1);
    },
    goto: (page) => {
      setStep(page);
    },
  };

  return (
    <AnonymousPageWrapper className={'page--userlogin'} title={'register'}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="userlogin-layout">
        <div className="userlogin__formwrapper">
          <img
            className="userlogin__icon"
            src={window.isSkillReflect ? srIcon : ttvIcon}
          />
          <RegisterForm
            step={step}
            stepHandler={stepHandler}
            email={email}
            tags={tags}
          />
          <div className={'userlogin__bottom'}>
            {t('existingAccount')} <a href={'/login'}>{t('login')}</a>
            <p className="text-muted">
              {t('form.existingClubAccount1')}{' '}
              <a href={t('form.existingClubAccountLink')}>
                {t('form.existingClubAccount2')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </AnonymousPageWrapper>
  );
};
export default RegisterPage;
