import React, { Children, Fragment, useState, useRef, useEffect } from 'react';

import { observationInputConfigurationCollection } from 'domain/ObservationInputConfiguration';

import { formatTime } from 'lib/FormattedTime';

import {
  CustomTagButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';
import { InputTrigger } from 'utils/ObservationInput/components/InputTrigger';
import { EventButtonGroup } from 'lib/ObservationPanel/Video/EventButtonGroup';
import { PossessionButtonGroup } from 'lib/ObservationPanel/Video/PossessionButtonGroup';
import { observer, Observer } from 'mobx-react';
import { ObservationInputConfiguration } from 'domain/ObservationInputConfiguration';
import { Modal } from 'lib/Modal';
import { ManageCustomTags } from 'lib/CustomTag/ManageCustomTags';

import { HistoryObserver } from 'infra/Messaging';
import { CommandHistory } from 'infra/Messaging';

import { withTranslation } from 'react-i18next';

const splitChildren = children => {
  const asArray = Children.toArray(children);

  return {
    possessionButtons: asArray.filter(
      child => child.type === PossessionButtonGroup
    ),
    eventButtons: asArray.filter(child => child.type === EventButtonGroup),
  };
};

//onOpenManageCustomTags={this.props.onOpenManageCustomTags}

const CustomTagManager = withTranslation('module.observe.video')(
  ({ observationContext, onDone, t }) => {
    const [showManageCustomTags, setShowManageCustomTags] = useState(false);

    return (
      <Fragment>
        <div
          className="label label-link clickable edit-teamtags-label"
          onClick={() => {
            observationContext.clock.pause();
            setShowManageCustomTags(true);
          }}
        >
          {t('selectTags')} <span className="i-settings" />
        </div>
        {showManageCustomTags && (
          <Modal onCloseClick={() => setShowManageCustomTags(false)}>
            <ManageCustomTags onDone={() => setShowManageCustomTags(false)} />
          </Modal>
        )}
      </Fragment>
    );
  }
);

const RecordButton = ({ observationContext }) => {
  const lastDescription = useRef('');

  const { observationLogger } = observationContext;
  useEffect(() => {
    const cb = (code, attributes) => {
      if (code === 'CUSTOM') {
        if (attributes.has('recorded')) {
          observationContext.clock.pause();
          const description = prompt(
            'Omschrijving voor opname',
            lastDescription.current
          );
          if (description === null) {
            return false;
          }
          lastDescription.current = description;
          return description;
        }
      }
    };

    observationLogger.on('descriptionRequested', cb);
    return () => {
      observationLogger.removeHandler('descriptionRequested', cb);
    };
  }, []);

  return (
    <InputTrigger
      type="toggle-range"
      observationCode="CUSTOM"
      observationLogger={observationLogger}
      attributes={{ recorded: true }}
      groupId="RECORD-BUTTON"
      render={props => {
        let description = (
          <>
            <i className="i-record" />
            Record
          </>
        );
        if (props.active) {
          description = (
            <Observer>
              {() => {
                return (
                  <>
                    <i className="i-stop i-light i-flash" />
                    Record{' '}
                    {formatTime(
                      observationLogger.getCurrentPartialObservationDuration(
                        'RECORD-BUTTON'
                      )
                    )}
                  </>
                );
              }}
            </Observer>
          );
        }
        return (
          <RangeTriggerButton
            {...props}
            classNames="label-secondary label-i-lft"
            description={description}
            keys=""
          />
        );
      }}
    />
  );
};

const VideoObservationPanel = observer(
  ({ observationContext, showRecordButton = true, children }) => {
    const observationLogger = observationContext.observationLogger;

    let { possessionButtons, eventButtons } = splitChildren(children);

    let observationInputConfiguration = observationInputConfigurationCollection.observationInputConfigurationById(
      'main'
    );
    if (!observationInputConfiguration) {
      observationInputConfiguration = new ObservationInputConfiguration(null);
    }

    if (!observationInputConfiguration.getStandardTagsVisibility()) {
      possessionButtons = null;
      eventButtons = null;
    }

    // TODO HERE: KOEN HIJ WERKT NIET
    const handleUndo = HistoryObserver.instance().canUndo()
      ? CommandHistory.instance().undoToPreviousTaggedHistoryItem
      : null;

    return (
      <Fragment>
        {possessionButtons && possessionButtons.length > 0 && (
          <div className="labels-connected">
            <div className="label-connection" />
            {possessionButtons}
          </div>
        )}
        <div className="labels">
          {eventButtons}
          {observationInputConfiguration
            .getCustomTags()
            .map((customTag, index) => {
              // Is this the first teamtag?
              return (
                <InputTrigger
                  key={customTag.id}
                  type="custom-point"
                  observationCode="CUSTOM"
                  observationLogger={observationLogger}
                  description={customTag.description}
                  durationBefore={customTag.durationBefore}
                  durationAfter={customTag.durationAfter}
                  render={props => (
                    <CustomTagButton {...props} keys={customTag.key} />
                  )}
                />
              );
            })}

          {showRecordButton && (
            <RecordButton observationContext={observationContext} />
          )}

          <CustomTagManager observationContext={observationContext} />

          {handleUndo && (
            <div className="label label-link clickable" onClick={handleUndo}>
              <i className="i-undo" />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
);

export { VideoObservationPanel };
