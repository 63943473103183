import React, { Component } from 'react';
import { observer } from 'mobx-react';

import SportingEventCollection from 'domain/SportingEvent';
import { Notification } from 'lib/Notification';
import { message } from 'lib/Message';

import { ObservationInputNav } from 'modules/observe/components/InputNav';
import VideoCollection from 'domain/Video';
import { MatchOverviewItem } from '../MatchOverviewItem';

import { Session } from '../../../../domain/Session';
import { withTranslation } from 'react-i18next';
import { LockedContent } from 'lib/LicenseLimitEnforcing';
import { Interval } from 'lib/Interval';
import { getLabel } from './index';
import _ from 'lodash';

const PastMatchRow = withTranslation('module.match')(
  observer(
    class MatchRow extends Component {
      render() {
        let sportingEvent = this.props.sportingEvent;

        let localCopy = null;
        for (const se of SportingEventCollection.toArray()) {
          if (se.tags.copyOf === sportingEvent.id) {
            localCopy = se;
            break;
          }
        }

        if (localCopy !== null) {
          // FIXME: this breaks when copy is made before video was assigned
          sportingEvent = localCopy;
        }

        let videoState = 'none';
        let video = null;
        if (sportingEvent.hasVideo()) {
          video = VideoCollection.get(sportingEvent.mainVideoId());
          if (video && !video.error) {
            const state = video.has('state') ? video.state : 'no-state';
            if (video.isReady) {
              videoState = 'ready';
            } else if (state === 'failed') {
              videoState = 'failed';
            } else if (state === 'new') {
              const stateChanged = video.get('stateChangedAt');
              if ((new Date() - new Date(stateChanged)) / 1000 > 24 * 3600) {
                videoState = 'upload-stalled';
              } else {
                videoState = 'uploading';
              }
            } else if (state == 'expired') {
              videoState = 'expired';
            } else {
              videoState = 'processing';
            }
          } else {
            if (sportingEvent.mainVideoId() !== 'filtered') {
              // filtered means license kicked in.
              // console.log(sportingEvent.id, sportingEvent.mainVideoId());
            }
            videoState = 'denied';
          }
        }

        const currentSession = Session.current();

        const primaryActions = [];
        if (
          currentSession.isFeatureAvailable('viewReport') &&
          this.props.onOpenReport
        ) {
          primaryActions.push({
            onClick: () => this.props.onOpenReport(sportingEvent.id),
            icon: 'stats',
            label: this.props.t('viewReport'),
          });
        }

        if (
          currentSession.isFeatureAvailable('play') &&
          videoState === 'ready'
        ) {
          primaryActions.push({
            onClick: () => this.props.onOpenPlay(sportingEvent.id),
            icon: 'play',
            label: 'Play',
          });
        }

        if (
          currentSession.isFeatureAvailable('play') &&
          videoState === 'denied'
        ) {
          primaryActions.push({
            onClick: () =>
              message({
                title: this.props.t('module.exchange:noPermissionTitle'),
                body: this.props.t('module.exchange:noPermissionBody'),
              }),
            className: 'disabled',
            icon: 'play',
            label: 'Play',
          });
        }
        if (
          currentSession.isFeatureAvailable('play') &&
          videoState === 'expired'
        ) {
          primaryActions.push({
            onClick: () =>
              message({
                title: this.props.t('videoExpired'),
                body: this.props.t('videoExpiredText'),
              }),
            className: 'disabled',
            icon: 'play',
            label: 'Play',
          });
        }

        if (
          sportingEvent.privilegedTo('init-upload', true) &&
          videoState === 'none'
        ) {
          primaryActions.push({
            onClick: () =>
              this.props.onUploadVideo(
                sportingEvent.isCopy() ? sportingEvent.original : sportingEvent
              ),
            icon: 'upload',
            label: this.props.t('uploadVideo'),
            id: 'uploadVideo',
          });
        }

        if (videoState === 'uploading') {
          primaryActions.push({
            icon: 'uploading',
            label: this.props.t('videoStateUploadingTitle'),
            onClick: () =>
              message({
                title: this.props.t('videoStateUploadingTitle'),
                body: this.props.t('videoStateUploadingBody'),
              }),
          });
        } else if (videoState === 'processing') {
          primaryActions.push({
            icon: 'processing i-rotating',
            label: this.props.t('videoStateProcessingTitle'),
            onClick: () =>
              message({
                title: this.props.t('videoStateProcessingTitle'),
                body: this.props.t('videoStateProcessingBody'),
              }),
          });
        } else if (videoState === 'upload-stalled') {
          primaryActions.push({
            icon: 'error i-no-filter',
            label: this.props.t('videoStateUploadStalledTitle'),
            onClick: () =>
              message({
                title: this.props.t('videoStateUploadStalledTitle'),
                body: this.props.t('videoStateUploadStalledBody'),
              }),
          });
        } else if (videoState === 'failed') {
          primaryActions.push({
            icon: 'error i-no-filter',
            label: this.props.t('videoStateProcessingFailedTitle'),
            onClick: () =>
              message({
                title: this.props.t('videoStateProcessingFailedTitle'),
                body: this.props.t('videoStateProcessingFailedBody'),
              }),
          });
        }

        if (videoState === 'ready') {
          let lockedReasons = [
            {
              check: !currentSession
                .currentPrivileges()
                .hasPrivilege('CustomTag:view'),
              reason: 'taggingNoLicense',
            },
            {
              check: !sportingEvent.privilegedTo('observe'),
              reason: 'taggingDisabled',
              lockType: 'hidden',
            },
          ];

          primaryActions.push(
            <LockedContent
              reasons={lockedReasons}
              component={'tagging'}
              place={'left'}
              size={'small'}
              locked={true}
              display={'block'}
            >
              {({ isLocked, onClickHandler }) => (
                <ObservationInputNav
                  live={false}
                  urlParams={{ sportingEventId: sportingEvent.id }}
                  key="observation-input"
                  disabled={isLocked}
                >
                  <span
                    className={`overview-item-action ${
                      isLocked ? 'disabled' : 'clickable'
                    }`}
                  >
                    <i className="i-observe i-dark" />
                    <span className="overview-item-action-label">
                      {this.props.t('videoObserve')}
                    </span>
                  </span>
                </ObservationInputNav>
              )}
            </LockedContent>
          );
        }

        if (
          videoState !== 'ready' &&
          !['processing', 'uploading', 'upload-stalled', 'failed'].includes(
            videoState
          )
        ) {
          // Live recording
          let lockedReasons = [
            {
              check: !currentSession
                .currentPrivileges()
                .hasPrivilege('CustomTag:view'),
              reason: 'taggingNoLicense',
            },
            {
              check: !currentSession
                .licensePrivileges()
                .hasPrivilege('video:live-record'),
              reason: 'disabled',
            },
            {
              check: !sportingEvent.privilegedTo('observe'),
              reason: 'taggingDisabled',
              lockType: 'hidden',
            },
          ];

          if (false) {
            // TODO: should this be enabled?
            primaryActions.push(
              <LockedContent
                reasons={lockedReasons}
                component={'tagging'}
                place={'left'}
                size={'small'}
                locked={true}
                display={'block'}
              >
                {({ isLocked, onClickHandler }) => (
                  <ObservationInputNav
                    live={false}
                    urlParams={{ sportingEventId: sportingEvent.id }}
                    key="observation-input"
                    disabled={isLocked}
                  >
                    <span
                      className={`overview-item-action ${
                        isLocked ? 'disabled' : 'clickable'
                      }`}
                    >
                      <i className="i-liverecord" />
                      <span className="overview-item-action-label">
                        {this.props.t('videoObserveLive')}
                      </span>
                    </span>
                  </ObservationInputNav>
                )}
              </LockedContent>
            );
          }
        }

        // if (currentSession.isFeatureAvailable('exportObservations')) {
        //   primaryActions.push({
        //     onClick: this.props.onExportObservations,
        //     icon: 'export',
        //     label: this.props.t('exportObservations'),
        //   });
        // }

        const secondaryActions = [];
        if (currentSession.isFeatureAvailable('viewSportingEventDetails')) {
          secondaryActions.push({
            onClick: this.props.onOpenDetail,
            label: 'Details',
          });
        }
        if (currentSession.isFeatureAvailable('editSportingEvent')) {
          secondaryActions.push({
            onClick: this.props.onEditSportingEvent,
            label: _.upperFirst(
              this.props.t('editSportingEvent', {
                type: `${this.props.t(`type.${sportingEvent.type}`)}`,
              })
            ),
            id: 'edit',
          });
        }
        if (
          this.props.onEditShareSportingEvent &&
          currentSession.isFeatureAvailable('editShareSportingEvent')
        ) {
          secondaryActions.push({
            onClick: this.props.onEditShareSportingEvent,
            label: this.props.t('editTags'),
            id: 'editShare',
          });
        }
        if (
          currentSession
            .currentPrivileges()
            .hasPrivilege('resourceGroup:set-resource-acl')
        ) {
          secondaryActions.push({
            onClick: this.props.onOpenSetACL,
            label: this.props.t('changePermissions'),
            id: 'changePermissions',
          });
        }

        if (
          currentSession.isFeatureAvailable('deleteVideo') &&
          videoState !== 'none'
        ) {
          if (sportingEvent.isCopy()) {
            if (sportingEvent.original.privilegedTo('delete-video', true)) {
              secondaryActions.push({
                onClick: () =>
                  this.props.onDeleteVideo(sportingEvent.original.id),
                label: this.props.t('deleteVideo'),
                id: 'deleteVideo',
              });
            }
          } else {
            if (sportingEvent.privilegedTo('delete-video', true)) {
              secondaryActions.push({
                onClick: () => this.props.onDeleteVideo(sportingEvent.id),
                label: this.props.t('deleteVideo'),
                id: 'deleteVideo',
              });
            }
          }
        }
        if (
          currentSession.isFeatureAvailable('importObservations') &&
          videoState === 'ready'
        ) {
          secondaryActions.push({
            onClick: async () => {
              const sportingEventId =
                await sportingEvent.getPrivilegedToSportingEventId('observe');
              this.props.onImportObservations(sportingEventId);
            },
            label: this.props.t('importXML'),
            id: 'importXML',
          });
        }

        if (
          sportingEvent.privilegedTo('edit', true) &&
          sportingEvent.getVideoSynchronizationPoints().length > 0
        ) {
          secondaryActions.push({
            onClick: () =>
              this.props.onRemoveVideoSynchronizationPoints(sportingEvent.id),
            label: this.props.t('removeVideoSynchronizationPoints'),
            id: 'removeSynchronizationPoint',
          });
        }

        if (sportingEvent.privilegedTo('destroy', true)) {
          if (!sportingEvent.isCopy()) {
            secondaryActions.push({
              onClick: this.props.onDeleteSportingEvent,
              label: _.upperFirst(
                this.props.t('deleteSportingEvent', {
                  type: `${this.props.t(`type.${sportingEvent.type}`)}`,
                })
              ),
              id: 'deleteMatch',
            });
          } else {
            secondaryActions.push({
              onClick: () => this.props.onDeleteAnalysis(sportingEvent),
              label: this.props.t('deleteAnalysis'),
              id: 'deleteAnalysis',
            });
          }
        }

        const label = getLabel(sportingEvent, this.props.t);

        const itemProps = {
          sportingEvent,
          primaryActions,
          secondaryActions,
          label,
        };

        return (
          <>
            {(videoState === 'uploading' || videoState === 'processing') && (
              <Interval
                interval={15}
                fn={() => video.fetch()}
                onExit={() => {
                  if (video.state === 'ready') {
                    Notification.show(
                      this.props.t('processingDone', { sportingEvent }),
                      2500
                    );
                  }
                }}
              />
            )}
            <MatchOverviewItem {...itemProps} locked={this.props.locked} />
          </>
        );
      }
    }
  )
);

export { PastMatchRow };
