import uuidv4 from 'uuid/v4';

// const Command = (name, attributeNames) => {
//     // Maybe add https://github.com/Bootstragram/zealit/blob/master/zealit.js
//     attributeNames = attributeNames.split(" ");
//     return function (...attributeValues) {
//         if (attributeNames.length !== attributeValues.length) {
//             throw new "Argument mismatch";
//         }
//         for (let i = 0; i < attributeNames.length; i++) {
//             this[attributeNames[i]] = attributeValues[i];
//         }
//         this.commandName = name;
//         this.id = uuidv4();
//         return Object.freeze(this);
//     }
// };

class Command {
  static commands = {};

  static register(commandType, attributeNames) {
    Command.commands[commandType] = attributeNames.split(' ');
  }

  static create(commandType, attributes, additionalAttributes = undefined) {
    if (!Command.commands.hasOwnProperty(commandType)) {
      throw 'Unknown command ' + commandType;
    }

    const commandAttributes = Command.commands[commandType];

    for (const attributeName of commandAttributes) {
      if (attributeName.substring(0, 1) === '?') {
        // Optional attribute
      } else {
        if (!attributes.hasOwnProperty(attributeName)) {
          throw 'Command Attribute missing: ' +
            commandType +
            ' ' +
            attributeName;
        }
      }
    }

    return new Command(
      uuidv4(),
      commandType,
      attributes,
      additionalAttributes || {}
    );
  }

  constructor(id, commandType, attributes, additionalAttributes) {
    for (let [key, value] of Object.entries(attributes)) {
      this[key] = value;
    }
    for (let [key, value] of Object.entries(additionalAttributes)) {
      this[key] = value;
    }
    this.commandId = id;
    this.commandType = commandType;
    return Object.freeze(this);
  }
}

export default Command;
