import { extendObservable, observable, action } from 'mobx';

class ImportState {
  constructor() {
    this._observations = observable([]);
    this._state = observable('new');
    this._file = observable(null);

    extendObservable(this, {
      get observations() {
        return this._observations;
      },
      get state() {
        return this._state.get();
      },
      get file() {
        return this._file.get();
      },
    });
  }

  setObservations = action(observations => {
    this._observations.replace(observations);
  });

  setState = action(state => {
    this._state.set(state);
  });

  setFile = action(file => {
    this._file.set(file);
  });
}

class XMLReader {
  constructor(importState) {
    this.importState = importState;
  }

  parseXML(xmlDom) {
    const instances = xmlDom.getElementsByTagName('instance');

    const items = [];

    for (let instance of instances) {
      const id = instance.getElementsByTagName('ID')[0].innerHTML;
      const code = instance.getElementsByTagName('code')[0].innerHTML;
      const start = parseFloat(
        instance.getElementsByTagName('start')[0].innerHTML
      );
      const end = parseFloat(instance.getElementsByTagName('end')[0].innerHTML);
      const labels = [];
      for (let label of instance.getElementsByTagName('label')) {
        labels.push(label.getElementsByTagName('text')[0].innerHTML);
      }
      items.push({ id, code, start, end, labels });
    }
    return items;
  }

  async readFile(file) {
    const reader = new FileReader();
    return new Promise(success => {
      reader.onload = e => {
        success(reader.result.replace(/\0/g, ''));
      };
      reader.readAsText(file);
    });
  }

  async setFile(file) {
    const content = await this.readFile(file);
    const oParser = new DOMParser();
    const oDOM = oParser.parseFromString(content, 'application/xml');
    const instances = this.parseXML(oDOM);

    const observations = instances.map(instance => {
      return {
        code: 'CUSTOM',
        description: instance.code,
        startTime: instance.start,
        endTime: instance.end,
        attributes: {},
      };
    });

    this.importState.setFile(file);
    this.importState.setObservations(observations);
    this.importState.setState('loaded');
  }
}

function chunk(arr, len) {
  const chunks = [],
    n = arr.length;
  let i = 0;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
}

class BulkObservationLogger {
  constructor(importState) {
    this.importState = importState;
  }

  async logToSportingEvent(sportingEvent) {
    if (!sportingEvent.hasVideo()) {
      throw 'SportingEvent has no video';
    }

    const videoId = sportingEvent.mainVideoId();
    const clockId = sportingEvent.clocks()[videoId].clockId;
    const observationCollection = sportingEvent.getObservationCollection(
      clockId
    );

    await observationCollection.addBulkObservation(
      this.importState.observations
    );
  }
}

export { ImportState, XMLReader, BulkObservationLogger };
