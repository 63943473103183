import './scoreboard.css';
import {
  AbsoluteFill,
  Easing,
  interpolate,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import { loadFont } from '@remotion/google-fonts/Lato';
import zIndexes from '../helpers/zIndexes.json';

export const Scoreboard = ({ clip }) => {
  const frame = useCurrentFrame();
  const videoConfig = useVideoConfig();
  const { fontFamily } = loadFont(); // "Titan One"

  const actionsPerSecond = clip.$sportingEvent.events.map((event) => {
    return {
      clipTime: event.video_time - clip.start,
      homeTeamScore: event.home_score,
      awayTeamScore: event.away_score,
      clockTime: event.clock_time,
      period: event.period,
    };
  });

  let playbackSpeed = clip?.playbackRate ?? 1;
  let truefps = videoConfig.fps / playbackSpeed;
  const currentClipTime = Math.floor(frame / truefps);

  const firstItem = actionsPerSecond[0];
  const missingItemsStart = firstItem.clipTime - currentClipTime;
  if (missingItemsStart > 0) {
    for (let i = 0; i < missingItemsStart; i++) {
      actionsPerSecond.unshift({
        clipTime: firstItem.clipTime - i - 1,
        homeTeamScore: firstItem.homeTeamScore,
        awayTeamScore: firstItem.awayTeamScore,
        clockTime: Math.max(0, firstItem.clockTime - i - 1),
        period: firstItem.period,
      });
    }
  }

  const lastItem = actionsPerSecond[actionsPerSecond.length - 1];
  const missingItemsEnd = currentClipTime - lastItem.clipTime;
  if (missingItemsEnd > 0) {
    for (let i = 0; i < missingItemsEnd; i++) {
      actionsPerSecond.push({
        clipTime: lastItem.clipTime + i + 1,
        homeTeamScore: lastItem.homeTeamScore,
        awayTeamScore: lastItem.awayTeamScore,
        clockTime: lastItem.clockTime + i + 1,
        period: lastItem.period,
      });
    }
  }

  let currentActionIndex = actionsPerSecond.findIndex((a) => {
    return a.clipTime == currentClipTime;
  });
  const currentAction =
    actionsPerSecond[Math.min(currentActionIndex, actionsPerSecond.length)];

  if (!currentAction) {
    throw `Cannot find data for ${JSON.stringify(clip)} - ${frame}`;
  }

  const getAnimationFromToValue = (keyToCheck, animationInSeconds) => {
    // Insert key to check for changes on, returns animationframes from 0 -> amountOfKeysToCheck(seconds)
    let startSecondsAgo = 0;
    let skipped = 0;
    const currentObject = currentAction[keyToCheck];
    let fromValue = currentObject;
    let toValue;

    if (currentActionIndex > 0) {
      for (var i = animationInSeconds; i > 0; i--) {
        if (currentActionIndex - i >= 0) {
          let prevObject = actionsPerSecond[currentActionIndex - i][keyToCheck];
          if (currentObject !== prevObject) {
            startSecondsAgo++;
            fromValue = prevObject;
            toValue = currentObject;
          }
        } else {
          // Skipped an index because it doesn't exit.
          skipped++;
        }
      }
    }

    if (startSecondsAgo) {
      // calculate which frame of animation we are in.
      const currentFrameOfCheckable =
        (animationInSeconds - startSecondsAgo - skipped) * truefps +
        (frame - currentActionIndex * truefps);

      return {
        animationFrame: currentFrameOfCheckable,
        fromValue: fromValue,
        toValue: toValue,
      };
    }
    // -1 means not animating, animation starts on frame 0;
    return { fromValue: fromValue, animationFrame: -1 };
  };

  const clockTime = Math.round(Math.floor(currentAction.clockTime));
  const seconds = clockTime % 60;
  const minutes = (clockTime - seconds) / 60;

  let periodName = '';
  // We cannot use react-i18n here.
  switch (currentAction.period) {
    case 1:
      periodName = '1st';
      break;
    case 2:
      periodName = '2nd';
      break;
    case 3:
      periodName = '3rd';
      break;
    default:
      periodName = `${currentAction.period}th`;
  }

  return (
    <AbsoluteFill
      style={{
        position: 'absolute',
        zIndex: zIndexes.overlay,
        fontFamily: fontFamily,
        fontSize: Math.floor(videoConfig.height / 50),
      }}
    >
      <div className={'scoreboard scoreboard--hockey'}>
        <div className="scoreboard-row">
          <div className="scoreboard-period scoreboard-block">{periodName}</div>
          <div className="scoreboard-time scoreboard-block">
            {minutes.toString().padStart(2, '0')}:
            {seconds.toString().padStart(2, '0')}
          </div>
          <div
            className="scoreboard-club scoreboard-block"
            // style={{
            //   backgroundColor: '#0c7d9d',
            // }}
          >
            {clip.$sportingEvent.$homeTeam.shortCode}
          </div>
          <div className="scoreboard-score scoreboard-block">
            <div className="score score-left">
              <AnimatedScoreboardInteger
                animation={getAnimationFromToValue('homeTeamScore', 1)}
                animationDuration={1}
                fps={truefps}
              />
            </div>
            <div className="score-divider"></div>
            <div className="score score-right">
              <AnimatedScoreboardInteger
                animation={getAnimationFromToValue('awayTeamScore', 1)}
                animationDuration={1}
                fps={truefps}
              />
            </div>
          </div>
          <div
            className="scoreboard-club scoreboard-block"
            // style={{
            //   backgroundColor: '#9d0c7b',
            // }}
          >
            {clip.$sportingEvent.$awayTeam.shortCode}
          </div>
        </div>
      </div>
    </AbsoluteFill>
  );
};

const AnimatedScoreboardInteger = ({
  animation,
  fps,
  animationDuration = 2,
}) => {
  if (animation.animationFrame >= 0) {
    const amount = interpolate(
      animation.animationFrame,
      [0, animationDuration * fps * 0.5],
      [0, 100],
      {}
    );
    const opacity = interpolate(
      animation.animationFrame,
      [0.25 * animationDuration * fps, animationDuration * fps * 0.5],
      [0, 1],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
      }
    );
    const opacityOut = interpolate(
      animation.animationFrame,
      [0, animationDuration * fps * 0.5],
      [1, 0],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp',
      }
    );
    return (
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            width: 0,
            height: '100%',
            width: '100%',
            transform: `translateY(${-amount}%)`,
            opacity: opacityOut,
          }}
        >
          {animation?.fromValue}
        </div>
        <div style={{ opacity: opacity, transform: `scale(${opacity})` }}>
          {animation?.toValue}
        </div>
      </div>
    );
  }

  return animation.fromValue;
};
