import React from 'react';
import i18n from 'i18next';
import { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { NavLink } from 'react-router-dom';

import './style.scss';
import { useTranslation } from 'react-i18next';
import { Session } from '../../domain/Session';
import moment from 'moment';

const allowedLocales = window.isSkillReflect
  ? {
      // SR
      GB: { lng: 'en', iso: 'en-GB', label: 'English' },
    }
  : {
      // TTV
      NL: { lng: 'nl', iso: 'nl-NL', label: 'Nederlands (NL)' },
      BE: { lng: 'nl', iso: 'nl-BE', label: 'Nederlands (BE)' },
      GB: { lng: 'en', iso: 'en-GB', label: 'English' },
      DE: { lng: 'de', iso: 'de-DE', label: 'Deutsch' },
      ES: { lng: 'es', iso: 'es-ES', label: 'Español' },
    };

export const ChangeLanguage = ({ onChange, value, showLabel = true }) => {
  let customCodes = {};
  Object.keys(allowedLocales).map((key) => {
    customCodes[key] = allowedLocales[key].label;
  });

  const setSelected = (code) => {
    setLang(code);

    let loc = new Intl.Locale(allowedLocales[code].iso);
    if (typeof onChange === 'function') {
      onChange(loc);
    }
    if (i18n.language !== code) {
      i18n.changeLanguage(loc.language);
    }

    moment.locale(loc.language);

    return code;
  };
  const [lang, setLang] = useState(value ?? null);
  const { t } = useTranslation('module.settings.profile');

  if (Object.keys(allowedLocales).length <= 1) {
    // Do not render when no languages to select from
    return null;
  }
  return (
    <div className="form-group">
      {showLabel && <label htmlFor="language">{t('language')} </label>}
      <ReactFlagsSelect
        countries={Object.keys(allowedLocales)}
        customLabels={customCodes}
        placeholder={t('language')}
        onSelect={(code) => setSelected(code)}
        selected={lang}
        className={'flag-select'}
      />
    </div>
  );
};

export const CurrentLanguage = () => {
  const { t } = useTranslation('module.settings.profile');
  const currentSession = Session.current();

  if (Object.keys(allowedLocales).length <= 1) {
    // Do not render when no languages to select from
    return null;
  }
  let allowedISO = [];
  Object.values(allowedLocales).map((val) => {
    allowedISO.push(val.iso);
  });
  return (
    <NavLink
      to="/user-management/user-profile"
      title={t('language')}
      className={`currentLanguage ${
        allowedISO.includes(
          currentSession.user.locale?.baseName // Show warning when user ISO is not in allowedISO
        )
          ? ''
          : 'warning'
      }`}
    >
      <span className="langcode">{i18n?.language?.toUpperCase() ?? 'EN'}</span>
      <i className={'i-language i-light'} />
    </NavLink>
  );
};
