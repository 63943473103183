import React, { createRef } from 'react';
import { Formik } from 'formik';
import { withTranslation } from 'react-i18next';
import CreatableSelectInput from 'lib/CreateableSelect';
import Loading from 'lib/Loading';

const validate = (values) => {
  const errors = {};

  // if (!values.name) {
  //   errors.name = true;
  // }

  if (!values.playlist) {
    errors.playlist = true;
  }

  if (!values.description) {
    errors.description = true;
  }

  return errors;
};

const playlistOptions = ({ groups, t }) =>
  groups.map((group) => {
    return {
      label: t(`selectGroups.${group.groupName}`),
      options: group.playlists.map((playlist) => ({
        value: playlist.playlistId,
        label: playlist.name,
      })),
    };
  });

export const PlaylistCreateView = withTranslation('module.playlist.create')(
  ({
    onSubmit = console.debug,
    onCancel = console.debug,
    t,
    groups,
    length,
    description,
    ...props
  }) => {
    const initialValues = {
      description,
      length,
      comment: '',
    };
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          submitCount,
          errors,
          setFieldValue,
        }) => {
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };

          // Use a min height to make space for an open select menu.
          const minHeight = '450px';

          return (
            <form
              onSubmit={
                !isSubmitting ? handleSubmit : (e) => e.preventDefault()
              } // Do nothing when already submitting. (this form closes after handleSubmit
              style={{ minHeight }}
            >
              <div className="modal-header">
                <h5 className="modal-title">{t('addToPlaylist')}</h5>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className={`form-group ${validClassName('playlist')}`}>
                      <label htmlFor="playlist">{t('playlist')}:</label>
                      <CreatableSelectInput
                        id="add-to-playlist"
                        name="playlist"
                        className="custom-form-control"
                        autoFocus
                        placeholder={t('playlistPlaceholder')}
                        options={playlistOptions({ groups, t })}
                        formatCreateLabel={(inputValue) =>
                          `+ ${t('module.playlist.create:createPrefix', {
                            label: inputValue,
                          })}`
                        }
                        onChange={(option) => setFieldValue('playlist', option)}
                      />
                      <div className="invalid-feedback">
                        {t('module.playlist:playlist-required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className={`form-group ${validClassName('length')}`}>
                      <label>{t('length')}:</label>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={values.length === '3-3'}
                          onChange={(e) =>
                            e.target.checked && setFieldValue('length', '3-3')
                          }
                          name="length-event"
                          id="create-playlist-length-event3-3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="create-playlist-length-event3-3"
                        >
                          {t('lengthOptionEvent', { before: 3, after: 3 })}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={values.length === '7-5'}
                          onChange={(e) =>
                            e.target.checked && setFieldValue('length', '7-5')
                          }
                          name="length-event"
                          id="create-playlist-length-event7-5"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="create-playlist-length-event7-5"
                        >
                          {t('lengthOptionEvent', { before: 7, after: 5 })}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          checked={values.length === 'video-fragment'}
                          onChange={(e) =>
                            e.target.checked &&
                            setFieldValue('length', 'video-fragment')
                          }
                          name="length-video-fragment"
                          id="create-playlist-length-video-fragment"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="create-playlist-length-video-fragment"
                        >
                          {t('lengthOptionVideoFragment')}
                        </label>
                      </div>
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div
                      className={`form-group ${validClassName('description')}`}
                    >
                      <label htmlFor="description">
                        {t('common:description')}:
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={values.description}
                        onChange={(e) =>
                          setFieldValue('description', e.target.value)
                        }
                        name="description"
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="comment">{t('comment')}:</label>
                      <textarea
                        style={{ resize: 'none' }}
                        rows="3"
                        className="form-control"
                        value={values.comment}
                        onChange={(e) =>
                          setFieldValue('comment', e.target.value)
                        }
                        placeholder={t('commentPlaceholder')}
                        name="comment"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="btn btn-primary">
                    {t('addToPlaylist')}
                    {isSubmitting && (
                      <Loading color={'#fff'} size={'16'} type={'spinner'} />
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={onCancel}
                  >
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }
);
