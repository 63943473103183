import { IObservationTreeBuilder } from './IObservationTreeBuilder';
import { TennisObservationTreeBuilder } from './TennisObservationTreeBuilder';
import { DefaultObservationTreeBuilder } from './DefaultObservationTreeBuilder';
import { ITeam } from './ITeam';
import { KorfballObservationTreeBuilder } from './KorfballObservationTreeBuilder';

export class ObservationTreeBuilderFactory {
  static factory(
    sportType: string,
    matchConfig: any,
    homeTeam: ITeam,
    awayTeam: ITeam,
    context?: any
  ): IObservationTreeBuilder {
    switch (sportType) {
      case 'tennis':
        return this.tennisFactory(matchConfig, homeTeam, awayTeam, context);
      case 'korfball':
        return new KorfballObservationTreeBuilder(
          matchConfig,
          homeTeam,
          awayTeam,
          context
        );
      default:
        return this.defaultFactory(matchConfig, homeTeam, awayTeam, context);
    }
  }

  static tennisFactory(
    matchConfig: any,
    homeTeam: ITeam,
    awayTeam: ITeam,
    context: any
  ): IObservationTreeBuilder {
    return new TennisObservationTreeBuilder(
      matchConfig,
      homeTeam,
      awayTeam,
      context
    );
  }

  static defaultFactory(
    matchConfig: any,
    homeTeam: ITeam,
    awayTeam: ITeam,
    context: any
  ): IObservationTreeBuilder {
    return new DefaultObservationTreeBuilder(
      matchConfig,
      homeTeam,
      awayTeam,
      context
    );
  }
}
