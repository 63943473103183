import {
  LocalCommandHandler,
  RemoteCommandHandler,
} from '../../infra/Messaging/CommandHandler';

import { ObservationContext } from './ObservationContext';
import { Session } from './../Session';
import { TimeMapper } from 'domain/ObservationLogger/Clock';

function fixCommandClock(command, observationContext) {
  // Fixes inconsistencies between local and livetagging clocks.
  const observationLog = observationContext.observationLog;
  const observation = observationLog.get(command.observationId);
  let out = {
    attributes: command.attributes,
    clockId: command.clockId,
    triggerTime: command.triggerTime,
    startTime: command.startTime,
    endTime: command.endTime,
    description: command.description,
  };

  if (command.clockId !== null) {
    const sourceClock = observationContext.getClockById(command.clockId);
    const destinationClock = observationContext.getClockById(
      observation.clockId
    );
    if (command.clockId !== observation.clockId) {
      out.clockId = observation.clockId;
    }

    if (command.triggerTime !== null && !isNaN(command.triggerTime)) {
      const triggerTime = TimeMapper.map(
        sourceClock,
        destinationClock,
        command.triggerTime
      );
      out.triggerTime = triggerTime;
    } else {
      out.triggerTime = null;
    }

    if (command.startTime !== null && !isNaN(command.startTime)) {
      const startTime = TimeMapper.map(
        sourceClock,
        destinationClock,
        command.startTime
      );
      out.startTime = startTime;
    } else {
      out.startTime = null;
    }
    if (command.endTime !== null && !isNaN(command.endTime)) {
      const endTime = TimeMapper.map(
        sourceClock,
        destinationClock,
        command.endTime
      );
      out.endTime = endTime;
    } else {
      out.endTime = null;
    }
  }
  return out;
}

class LocalObservationLoggerCommandHandler extends LocalCommandHandler {
  doHandle(command) {
    const observationLogger = ObservationContext.instance().observationLogger;

    switch (command.commandType) {
      case 'ObservationLogger.StartRangeObservation':
        observationLogger.startRangeObservation(
          command.groupId,
          command.code,
          command.attributes
        );
        break;
      case 'ObservationLogger.EndRangeObservation':
        observationLogger.endRangeObservation(
          command.groupId,
          command.attributes,
          command.time,
          undefined /* code */,
          command.description
        );
        break;
      case 'ObservationLogger.RemovePartialObservation':
        observationLogger.removePartialObservation(command.groupId);
        break;
      case 'ObservationLogger.SetObservationAttributes':
        observationLogger.setObservationAttributes(
          command.groupId,
          command.code,
          command.attributes
        );
        break;
      case 'ObservationLogger.SetPointObservation':
        observationLogger.setPointObservation(
          command.code,
          command.attributes,
          command.description
        );
        break;
      case 'ObservationLogger.UpdateObservation':
        observationLogger.updateObservation(
          command.observationId,
          command.attributes,
          command.description,
          command.clockId,
          command.triggerTime,
          command.startTime,
          command.endTime
        );
        break;

      case 'ObservationLogger.SetCustomPointObservation':
        observationLogger.setCustomPointObservation(
          command.code,
          command.attributes,
          command.description,
          command.durationBefore,
          command.durationAfter
        );
        break;

      case 'ObservationLogger.ToggleRangeObservationExclusive':
        observationLogger.toggleRangeObservationExclusive(
          command.groupId,
          command.code,
          command.attributes,
          false /* autoRestart */
        );
        break;
      case 'ObservationLogger.TurnoffGroup':
        observationLogger.turnoffGroup(command.groupId);
        break;

      // Used for undo purposes
      case 'ObservationLogger.RestoreSnapshot':
        observationLogger.restoreSnapshot(command.snapshot);
        break;
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['ObservationLogger.*'];
  }
}

class LocalObservationLogCommandHandler extends LocalCommandHandler {
  understoodTypes() {
    return ['ObservationLog.*'];
  }

  doHandle(command) {
    const observationContext = ObservationContext.instance();

    const observationLog = observationContext.observationLog;

    switch (command.commandType) {
      case 'ObservationLog.AddObservation':
        const session = Session.current();
        const currentUser = session.user.toJS();

        observationLog.add([
          {
            clockId: command.clockId,
            observationId: command.observationId,
            code: command.code,
            attributes: command.attributes,
            triggerTime: command.triggerTime,
            startTime: command.startTime,
            endTime: command.endTime,
            description: command.description,
            creatorRoleName: session.currentRoleName(),
            creatorUser: currentUser,
          },
        ]);
        break;

      case 'ObservationLog.UpdateObservation':
        const observation = observationLog.get(command.observationId);
        observation.set({
          attributes: {
            ...observation.get('attributes'),
            ...command.attributes,
          },
        });
        if (command.description !== null) {
          observation.set({
            description: command.description,
          });
        }

        let aCommand = fixCommandClock(command, observationContext);

        aCommand.triggerTime !== null &&
          observation.set({
            triggerTime: aCommand.triggerTime,
          });

        aCommand.startTime !== null &&
          observation.set({
            startTime: aCommand.startTime,
          });

        aCommand.endTime !== null &&
          observation.set({
            endTime: aCommand.endTime,
          });

        observationLog.reload();
        break;

      case 'ObservationLog.RemoveObservation':
        observationLog.remove([command.observationId]);
        break;

      default:
        return null;
    }
    return true;
  }
}

class RemoteObservationLogCommandHandler extends RemoteCommandHandler {
  understoodTypes() {
    return ['ObservationLog.*'];
  }

  doHandle(command) {
    const observationLog = ObservationContext.instance().observationLog;

    switch (command.commandType) {
      case 'ObservationLog.AddObservation': {
        const model = observationLog.build({
          observationId: command.observationId,
        });

        this.addUnitOfWork(command, [
          this.createAddWithIdRequest(model, {
            source: command.source,
            code: command.code,
            attributes: command.attributes,
            triggerTime: command.triggerTime,
            startTime: command.startTime,
            endTime: command.endTime,
            description: command.description || '',
          }),
        ]);
        break;
      }
      case 'ObservationLog.UpdateObservation': {
        const model = observationLog.build({
          observationId: command.observationId,
        });
        const aCommand = fixCommandClock(
          command,
          ObservationContext.instance()
        );
        this.addUnitOfWork(command, [
          this.createUpdateRequest(model, {
            attributes: aCommand.attributes,
            description: aCommand.description,
            clockId: aCommand.clockId,
            triggerTime: aCommand.triggerTime,
            startTime: aCommand.startTime,
            endTime: aCommand.endTime,
          }),
        ]);
        break;
      }
      case 'ObservationLog.RemoveObservation': {
        const model = observationLog.build({
          observationId: command.observationId,
        });
        this.addUnitOfWork(command, [this.createDeleteRequest(model)]);
        break;
      }

      default:
        return null;
    }
    return true;
  }
}

export default () => {
  new LocalObservationLoggerCommandHandler();

  new LocalObservationLogCommandHandler();
  // setInterval(() => {
  //   console.error("RemoteObservationLogCommandHandler disabled");
  // }, 10000);

  new RemoteObservationLogCommandHandler();
};
