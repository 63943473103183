import './userLogin.scss';
import React, { useEffect, useState } from 'react';
import {
  AcceptLegalDocuments,
  Error,
  RegisterForm,
} from '../lib/UserLogin/RegisterForm';
import { AnonymousPageWrapper } from './AnonymousPageWrapper';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import ttvIcon from '../img/ttv_icon.svg';
import srIcon from '../img/sr_icon.svg';
import i18n from 'i18next';
import endpoint from '../utils/ApiEndpoint';
import Loading from '../lib/Loading';
import Logger from '../utils/Logger';
import logger from '../utils/Logger';
import { SVCreateAccount } from '../lib/UserLogin/RegisterForm/steps/sv_createAccount';
import loginAnimationLottie from '../lib/animations/lottie/login-sr-animation.json';
import countdownLottie from '../lib/animations/lottie/countDown3.json';
import Lottie from 'react-lottie';

function getCookie(name) {
  function escape(s) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
  }
  var match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
  );
  return match ? match[1] : null;
}

const AccountSetup = ({ props }) => {
  const { t } = useTranslation('registerPage');

  var _params = new URLSearchParams(document.location.search);
  let tags = null;

  useEffect(() => {
    // Spelenderwijs so nl :)
    i18n.changeLanguage('nl');
  }, []);

  return (
    <AnonymousPageWrapper className={'page--userlogin'} title={'register'}>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="userlogin-layout">
        <div className="userlogin__formwrapper">
          <img
            className="userlogin__icon"
            src={window.isSkillReflect ? srIcon : ttvIcon}
          />
          {_params.get('token') && <AccountSetupForm params={_params} />}
          {!_params.get('token') && (
            <Error
              title={'Geen geldige token gevonden'}
              body={
                'Klik op de link in de email die je hebt ontvangen om het nogmaals te proberen, Lukt dit niet? neem dan contact op met support.'
              }
            />
          )}
          <div className={'userlogin__bottom'}>
            {t('existingAccount')} <a href={'/login'}>{t('login')}</a>
            <p className="text-muted">
              {t('form.existingClubAccount1')}{' '}
              <a href={t('form.existingClubAccountLink')}>
                {t('form.existingClubAccount2')}
              </a>
            </p>
          </div>
        </div>
      </div>
    </AnonymousPageWrapper>
  );
};

const AccountSetupForm = ({ params }) => {
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();
  const [byPassError, setByPassError] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState();
  const [value, setValue] = useState({
    email: params.get('email') ?? '',
    countryCode: 'nl',
    token: params.get('token'),
    password: '',
    passwordRepeat: '',
    sportType: 'korfball',
    acceptedDocuments: false,
  });
  const [step, setStep] = useState(0);
  const stepHandler = {
    next: () => {
      setStep((currentStep) => currentStep + 1);
    },
    goto: (page) => {
      setStep(page);
    },
  };
  useEffect(() => {
    (async () => {
      let type = new FormData();
      type.append(
        'brandCode',
        window.isSkillReflect ? 'skillreflect' : 'teamtv'
      );
      let response = await fetch(`${endpoint}/anonymous/getLegalDocuments`, {
        method: 'post',
        body: type,
      });
      setCheckBoxes(await response.json());
    })();
  }, []);

  const checkEmailValue = async () => {
    setError(false);
    setIsChecking(true);

    await fetch(
      `${endpoint}/anonymous/tenantCreationRequest/checkMailAvailability?email=${value.email}`
    )
      .then(async (response) => {
        const data = await response.json();
        if (!data) {
          setError('mail');
          setIsChecking(false);
          return;
        }

        setValue((o) => {
          return { ...o, ...value };
        });
        setIsChecking(false);
        // Continue
      })
      .catch((e) => {
        console.log(e);
        setError('mail');
        setIsChecking(false);
      });
  };

  const checkClubValue = async () => {
    setIsChecking(true);

    if (!byPassError) {
      await fetch(
        `${endpoint}/anonymous/tenantCreationRequest/checkTenantAvailability?name=${value.tenantName}&sportType=${value.sportType}&language=${value.countryCode}`
      ).then(async (response) => {
        const data = await response.json();
        if (!data) {
          setError('club');
          setIsChecking(false);
          return;
        }
        setValue((o) => {
          return { ...o, ...value };
        });
        setIsChecking(false);
      });
      setIsChecking(false);
      return;
    }

    setIsChecking(false);
    setValue((o) => {
      return { ...o, ...value };
    });
  };
  if (step === 3 && value.password.length) {
    setTimeout(() => {
      window.location.href = '/login';
    }, 5000);
  }
  if (step === 0) {
    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            await checkEmailValue();
            await checkClubValue();
          } catch (e) {
            logger.error(e, {
              transactionName: `Unable to create Spelenderwijs tenant`,
            });
            setIsChecking(false);
          }
          // etc..
          stepHandler.next();
          // Do actual submit.
        }}
        className={'userlogin-form-step'}
      >
        <h1>Maak je Spelenderwijs verbeteren account af</h1>
        <p>
          Je bent bijna klaar, we hebben nog een beetje informatie van je nodig.
        </p>
        <div className={'userlogin-form-input'}>
          <input
            type={'email'}
            id={'userlogin-email'}
            placeholder={t('form.emailPlaceholder')}
            autoComplete={'email'}
            value={value.email}
            required="required"
            onChange={(e) => {
              e.persist();
              setValue((o) => {
                return { ...o, email: e.target.value };
              });
            }}
          />
          <label htmlFor={'userlogin-email'}>
            {t('form.emailPlaceholder')}
          </label>
          {error && error === 'mail' && (
            <Error
              title={t('form.inUseError')}
              body={t('form.inUseErrorBody')}
              action={<a href={'/login'}>{t('loginC')}</a>}
            />
          )}
        </div>
        {/*
          Add Password field
      */}
        <div className={'userlogin-form-input'}>
          <input
            type={'password'}
            id={'password'}
            autoComplete={'new-password'}
            placeholder={t('form.passwordPlaceholderNew')}
            value={value.password}
            onChange={(e) => {
              e.persist();
              setValue((o) => {
                return { ...o, ...{ password: e.target.value } };
              });
            }}
          />
          <label htmlFor={'password'}>{t('form.password')}</label>
        </div>
        <div className={'userlogin-form-input'}>
          <input
            type={'password'}
            id={'passwordRepeat'}
            placeholder={t('form.passwordRepeatPlaceholder')}
            autoComplete={'new-password'}
            value={value.passwordRepeat}
            onChange={(e) => {
              e.persist();
              setValue((o) => {
                return { ...o, ...{ passwordRepeat: e.target.value } };
              });
            }}
          />
          <label htmlFor={'passwordRepeat'}>{t('form.passwordRepeat')}</label>
        </div>
        {/*
          Add clubname field.
      */}
        <div className={'userlogin-form-input'}>
          <input
            type={'text'}
            id={'club'}
            placeholder={t('form.clubNamePlaceholder')}
            value={value.tenantName}
            required="required"
            onChange={(e) => {
              e.persist();
              setError(false);
              setValue((o) => {
                return { ...o, ...{ tenantName: e.target.value } };
              });
            }}
          />
          <label htmlFor={'userlogin-email'}>
            {t('form.clubNamePlaceholder')}
          </label>
        </div>
        {error && error === 'club' && (
          <Error
            title={t('form.clubnameInUseError')}
            body={t('form.clubnameInUseErrorBody')}
            action={
              <>
                <a
                  target="_blank"
                  rel="noopener"
                  href={t('form.existingClubAccountLink')}
                >
                  {t('form.existingClubAccount2')}
                </a>
                <a
                  style={{ marginTop: '0.5em' }}
                  rel="noopener"
                  href={`mailto:${
                    window.isSkillReflect
                      ? 'support@skillreflect.com'
                      : 'support@teamtv.nl'
                  }`}
                >
                  {t('errorPage:contact')}
                </a>
                <a
                  style={{ marginTop: '0.5em' }}
                  href="#"
                  onClick={() => {
                    setByPassError(true);
                    setError(false);
                  }}
                >
                  {t('form.clubnameInUseContinue')}
                </a>
              </>
            }
          />
        )}
        {/* legal */}
        {checkBoxes?.length > 0 && (
          <AcceptLegalDocuments
            formData={value}
            setFormData={setValue}
            checkBoxes={checkBoxes}
          />
        )}
        <div className={'userlogin-form-actions'}>
          <button
            type="submit"
            className={'btn btn-primary'}
            disabled={
              !value.email ||
              !value.countryCode ||
              !value.token ||
              !value.password ||
              value.password !== value.passwordRepeat ||
              !value.tenantName ||
              !value.sportType ||
              !value.acceptedDocuments ||
              error == 'club'
            }
          >
            {t('form.complete')}
            {isChecking && (
              <Loading type={'spinner'} color={'white'} size={16} />
            )}
          </button>
        </div>
      </form>
    );
  }
  if (step === 1) {
    return <SVCreateAccount stepHandler={stepHandler} formData={value} />;
  }
  if (step === 2) {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: window.isSkillReflect
        ? loginAnimationLottie
        : countdownLottie,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    return (
      <Lottie style={{ height: 200, width: 200 }} options={defaultOptions} />
    );
  }
};

export default AccountSetup;
