/* Step 1 */

import React, { useRef, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import endpoint from 'utils/ApiEndpoint';
import { ChangeLanguage } from 'lib/ChangeLanguage';
import Loading from 'lib/Loading';
import { Error } from '../index';

export const EmailForm = ({ stepHandler, formData, setFormData }) => {
  const [value, setValue] = useState({
    email: formData.email,
    countryCode:
      formData.countryCode ??
      (i18n.countryCode === 'en' ? 'GB' : i18n.language.toUpperCase()),
  });
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();

  const checkValue = async () => {
    setError(false);
    setIsChecking(true);

    if (!stepRef.current.checkValidity()) {
      return false;
    }

    await fetch(
      `${endpoint}/anonymous/tenantCreationRequest/checkMailAvailability?email=${value.email}`
    )
      .then(async (response) => {
        const data = await response.json();
        if (!data) {
          setError(true);
          setIsChecking(false);
          return;
        }

        setFormData((o) => {
          return { ...o, ...value };
        });
        stepRef.current.classList.add('animateOut');
        setIsChecking(false);
        setTimeout(() => stepHandler.next(), 500);
      })
      .catch((e) => {
        console.log(e);
        setError(e);
        setIsChecking(false);
      });
  };
  const stepRef = useRef();

  return (
    <form
      data-test-id={'step-email'}
      ref={stepRef}
      className={'userlogin-form-step'}
      onSubmit={(e) => {
        e.preventDefault();
        if (!isChecking) {
          checkValue();
        }
      }}
    >
      <h1>{t('form.createAccountTitle')}</h1>
      <p>{t('form.createAccountBody')}</p>

      <div className={'userlogin-form-input'}>
        <ChangeLanguage
          onChange={(locale) =>
            setValue((o) => {
              return { ...o, countryCode: locale.region };
            })
          }
          showLabel={false}
          value={value.countryCode}
        />
      </div>
      <div className={'userlogin-form-input'}>
        <input
          type={'email'}
          id={'userlogin-email'}
          placeholder={t('form.emailPlaceholder')}
          autoComplete={'email'}
          value={value.email}
          required="required"
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, email: e.target.value };
            });
          }}
        />
        <label htmlFor={'userlogin-email'}>{t('form.emailPlaceholder')}</label>
      </div>
      {error && (
        <Error
          title={t('form.inUseError')}
          body={t('form.inUseErrorBody')}
          action={<a href={'/login'}>{t('loginC')}</a>}
        />
      )}

      <div className={'userlogin-form-actions'}>
        <button
          type="submit"
          className={'btn btn-primary'}
          disabled={!value.email || !value.countryCode}
        >
          {t('form.nextStep')}
          {isChecking && <Loading type={'spinner'} color={'white'} size={16} />}
        </button>
      </div>
    </form>
  );
};
