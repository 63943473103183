import Command from '../../infra/Messaging/Command';

Command.register(
  'SportingEventService.CreateMatch',
  'sportType homeTeam awayTeam scheduledAt tags'
);

Command.register(
  'SportingEvent.CreateMatch',
  'sportingEventId observationLogId lineUpId homeTeamId awayTeamId name scheduledAt tags'
);
Command.register(
  'SportingEvent.AddSynchronizationPoint',
  'sportingEventId clockId type key time'
);
Command.register(
  'SportingEvent.RemoveSynchronizationPoint',
  'sportingEventId clockId type key'
);
