//
// {
//   id: 11124511 /* UNIQUE */,
//     preLabel: '0 - 0' /* OR null */,
//   postLabel: '',
//   text: '3e set',
//   type: 'SET' /* PREDEFINED, E.G. HALF_TIME, SET, GAME, SHOT, PASS */,
//   actions: ['ACTION_TYPE_LET', 'ACTION_TYPE_DELETE'] /* PREDEFINED */,
//   startTime: 60 /* integer */,
//   endTime: 120 /* integer */,
//   children: [],
// },

import { Time } from './Time';
import { IObservationTreeBuilder } from './IObservationTreeBuilder';
import { ObservationTree } from './ObservationTree';
import { IObservation } from './IObservation';
import { ObservationTreeNode } from './ObservationTreeNode';
import { ITeam } from './ITeam';
import { treeSetCurrentObservation } from './treeSetCurrentObservation';

import init, { TennisReducer } from '@teamtv/teamtv-match-state';

init();

const gameScore = (points: number, tiebreakType: string): number => {
  if (tiebreakType === 'NoTiebreak') {
    if (points === 1) return 15;
    if (points === 2) return 30;
    if (points >= 3) return 40;
    return 0;
  } else {
    return points;
  }
};

const isAdvantage = (
  myGamePoints: number,
  opponentGamePoints: number,
  tiebreakType: string
) => {
  if (tiebreakType !== 'NoTiebreak') return '';
  if (myGamePoints < 3 || opponentGamePoints < 3) return '';
  if (myGamePoints > opponentGamePoints) return 'A';
  return '';
};

export class TennisObservationTreeBuilder implements IObservationTreeBuilder {
  constructor(
    readonly matchConfig: any,
    readonly homeTeam: ITeam,
    readonly awayTeam: ITeam,
    readonly context: any
  ) {}

  build(observations: IObservation[]) {
    // assume observations are sorted!

    const observationTree = new ObservationTree();

    const reducer = new TennisReducer(this.matchConfig);

    //let [previousState, reducer] = init(this.matchConfig);

    let previousSetId: string = '';
    let previousGameId: string = '';
    let previousPointId: string = '';

    let currentSetNode: ObservationTreeNode | null = null;
    let currentGameNode: ObservationTreeNode | null = null;
    let activePointNode: ObservationTreeNode | null = null;
    let currentPointNode: ObservationTreeNode | null = null;
    let currentEventNode: ObservationTreeNode | null = null;

    let previousState = reducer.get_state();

    let isError = false;
    for (const observation of observations) {
      try {
        reducer.reduce(observation.toJS());
      } catch (e) {
        isError = true;
      }

      if (isError) {
        currentSetNode = new ObservationTreeNode(
          observation.id,
          observation.description,
          // TODO: fix. This end of previous set
          new Time(observation.startTime, observation.startTime),
          undefined,
          'ERROR',
          ['EDIT'],
          observation.attributes_.rating,
          observation.creatorUser
        );
        observationTree.addNode(currentSetNode);
        continue;
      }

      const currentState = reducer.get_state();

      const { homeScore, awayScore } = currentState.score;

      const setCounter = homeScore.sets + awayScore.sets;
      const currentSetId = `set-${setCounter}`;
      let setChanged = false;
      if (currentSetId !== previousSetId) {
        if (currentSetNode !== null) {
          currentSetNode.time.setEndTime(observation.startTime);
          currentSetNode.preLabel = `${previousState.score.homeScore.sets} - ${previousState.score.awayScore.sets}`;
        }
        currentSetNode = new ObservationTreeNode(
          currentSetId,
          `${setCounter + 1}e set`,
          // TODO: fix. This end of previous set
          new Time(observation.startTime, observation.startTime),
          'IN PROGRESS',
          [],
          observation.attributes_.rating,
          observation.creatorUser
        );
        observationTree.addNode(currentSetNode);
        previousSetId = currentSetId;
        setChanged = true;
      }

      const gameCounter = homeScore.games + awayScore.games;
      const currentGameId = `game-${setCounter}-${gameCounter}`;

      if (currentGameId !== previousGameId) {
        if (currentGameNode !== null) {
          currentGameNode.time.setEndTime(observation.startTime);
        }
        currentGameNode = new ObservationTreeNode(
          currentGameId,
          `${gameCounter + 1}e game`,
          // TODO: fix. This end of previous game
          new Time(observation.startTime, observation.startTime),
          `${homeScore.games} - ${awayScore.games}`,
          ({ current }) => {
            if (!current) {
              return null;
            }
            return currentState.server === 'HomeParty'
              ? this.homeTeam.shortLabel
              : this.awayTeam.shortLabel;
          },
          [],
          observation.attributes_.rating,
          observation.creatorUser
        );

        (currentSetNode as ObservationTreeNode).addNode(currentGameNode);
        previousGameId = currentGameId;
      }

      const pointCounter = homeScore.points + awayScore.points;
      const currentPointId = `point-${setCounter}-${gameCounter}-${pointCounter}`;

      if (currentPointId !== previousPointId) {
        const tiebreakType: string = currentState.tiebreakType;
        const homeAdvantage = isAdvantage(
          homeScore.points,
          awayScore.points,
          tiebreakType
        );
        const awayAdvantage = isAdvantage(
          awayScore.points,
          homeScore.points,
          tiebreakType
        );

        currentPointNode = new ObservationTreeNode(
          currentPointId,
          `${pointCounter + 1}e punt`,
          new Time(observation.startTime),
          currentState.server === 'HomeParty'
            ? `${gameScore(
                homeScore.points,
                tiebreakType
              )}${homeAdvantage} - ${gameScore(
                awayScore.points,
                tiebreakType
              )}${awayAdvantage}`
            : `${gameScore(
                awayScore.points,
                tiebreakType
              )}${awayAdvantage} - ${gameScore(
                homeScore.points,
                tiebreakType
              )}${homeAdvantage}`,
          undefined,
          [],
          observation.attributes_.rating,
          observation.creatorUser
        );
        (currentGameNode as ObservationTreeNode).addNode(currentPointNode);

        if (activePointNode === null) {
          activePointNode = currentPointNode;
        }
      }

      if (currentPointId !== previousPointId && previousPointId !== '') {
        let result = undefined;
        switch (observation.attributes_.result) {
          case 'WINNER':
            result = 'W';
            break;
          case 'FORCED-ERROR':
            result = 'F';
            break;
          case 'UNFORCED-ERROR':
            result = 'U';
            break;
        }
        const currentNode = currentEventNode as ObservationTreeNode;
        currentNode.postLabel = result;
        currentNode.setMergedObservationId(observation.observationId);
      } else {
        const isHomeParty = !currentState.rally.isNextShotOfServer
          ? currentState.server === 'HomeParty'
          : currentState.server !== 'HomeParty';

        currentEventNode = new ObservationTreeNode(
          observation.observationId,
          `${observation.description} ${
            isHomeParty ? this.homeTeam.name : this.awayTeam.name
          }`,
          new Time(observation.startTime),
          undefined,
          undefined,
          ['EDIT'],
          observation.attributes_.rating,
          observation.creatorUser
        );
        (activePointNode as ObservationTreeNode).addNode(currentEventNode);
      }

      previousPointId = currentPointId;
      previousState = currentState;
      activePointNode = currentPointNode;
    }

    //console.log('set reducre free');
    reducer.free();

    treeSetCurrentObservation({
      tree: observationTree,
      clock: this.context.clock,
    });
    return observationTree;
  }
}
