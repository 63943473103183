import React from 'react';
import { ObservationDetailsTennisBase } from './base';

const schema = {
  type: 'object',
  properties: {
    attributes: {
      type: 'object',
      properties: {
        area: {
          type: 'string',
        },
        result: {
          type: 'string',
          enum: ['WINNER', 'FORCED-ERROR', 'UNFORCED-ERROR'],
          i18n: {
            enumNames: 'sports.tennis.endpoints:results',
          },
        },
        comment: {
          type: 'string',
          i18n: {
            title: 'comment',
          },
        },
      },
    },
  },
};

export const ObservationDetailTennisEndpoint = props => (
  <ObservationDetailsTennisBase {...props} schema={schema} />
);
