export const getOrdinalPeriod = (t, periodNo, periodCount) => {
  // returns 1st half, 2nd half, 1st quarter, 3rd quarter etc..
  const value = {
    period: `${t('common:int', {
      count: periodNo,
      ordinal: true,
    })}`,
    periodCount:
      periodCount === 2
        ? `${t('common:halve')}`
        : periodCount === 4
        ? `${t('common:quarter')}`
        : `${t('common:period')}`,
  };
  return value;
};
