import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { MultiCamThumbnails } from '../modules/play/components/MultiCamThumbnails';

class ReactPlayerMultiCamHandler extends Component {
  constructor(props) {
    super(props);
    this.onAngleChange = this.onAngleChange.bind(this);
  }

  onAngleChange(angle, description) {
    this.props.videoAngleContext.changeAngle(
      this.props.player.id,
      angle,
      description
    );
  }

  setCurrentAngle() {
    this.setState({
      currentAngle: this.props.player.getPlaylistItem().videoFragment.videoId,
    });
  }

  render() {
    return (
      <>
        {ReactDOM.createPortal(
          <div className="videoAngles">
            {this.props.multiCam.map((videoId, index) => (
              <MultiCamThumbnails
                onSelect={this.onAngleChange}
                selected={
                  videoId ===
                  this.props.videoAngleContext.getPlayerDataForPlayerId(
                    this.props.player.id
                  )?.selectedAngle
                }
                key={this.props.multiCam[index]}
                videoId={videoId}
                index={index}
              />
            ))}
          </div>,
          this.props.player.getContainer()
        )}
      </>
    );
  }
}

export { ReactPlayerMultiCamHandler };
