import { Player, RenderPoster } from '@remotion/player';
import { AbsoluteFill } from 'remotion';
import React, { useState, useRef, useCallback } from 'react';
import { BufferManager } from './BufferManager';
import Loading from '../../lib/Loading';

export const HighlightsPlayer = ({
  playerRef,
  composition,
  durationInFrames,
  compositionWidth,
  compositionHeight,
  fps,
  style,
  inputProps,
  isRecording,
}) => {
  const [buffering, setBuffering] = useState(false);
  const pausedBecauseOfBuffering = useRef(false);

  const onBuffer = useCallback(() => {
    if (!isRecording) {
      setBuffering(true);
      playerRef.current?.pause();
      pausedBecauseOfBuffering.current = true;
    }
  }, [isRecording]);

  const onContinue = useCallback(() => {
    if (!isRecording) {
      setBuffering(false);
      // Play only if we paused because of buffering
      if (pausedBecauseOfBuffering.current) {
        pausedBecauseOfBuffering.current = false;
        playerRef.current?.play();
      }
    }
  }, [isRecording]);

  // Add this to your component rendering the <Player>
  const renderPoster: RenderPoster = useCallback(() => {
    if (buffering) {
      return (
        <AbsoluteFill
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 100,
          }}
        >
          <Loading
            type={'spinner'}
            color={'#efefef'}
            size={compositionWidth / 25}
          />
        </AbsoluteFill>
      );
    }

    return null;
  }, [buffering]);

  return (
    <BufferManager onBuffer={onBuffer} onContinue={onContinue}>
      <Player
        ref={playerRef}
        component={composition}
        compositionHeight={compositionHeight}
        compositionWidth={compositionWidth}
        durationInFrames={durationInFrames}
        fps={fps}
        style={style}
        inputProps={inputProps}
        showPosterWhenPaused
        controls={!isRecording}
      />
    </BufferManager>
  );
};
