import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';

import { withTranslation } from 'react-i18next';

import {
  VideoObservationPanel,
  PossessionButtonGroup,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';

const KorfballObservationInput = withTranslation('module.observe.video')(
  observer(
    class KorfballObservationInput extends Component {
      componentWillMount() {
        const {
          observationLogger,
          homeTeam,
          awayTeam,
        } = this.props.observationContext;

        observationLogger.on(
          'afterObservationAdded',
          (
            observationCode,
            attributes,
            triggerTime,
            startTime,
            endTime,
            description
          ) => {
            switch (observationCode) {
              case 'SHOT':
                if (attributes.get('result') === 'GOAL') {
                  this.switchPossession();
                }
                break;
            }

            // TODO: make sure when switchPossesion is called after a goal
            // only 1 history item is added instead of two (SHOT + POSSESSION)
            CommandHistory.instance().tagHistoryItem(description);
          }
        );

        observationLogger.on('descriptionRequested', (code, attributes) => {
          let positionDescription = '1ste verdediging ';
          if (this.props.observationContext.goalCounter % 4 < 2) {
            positionDescription = '1ste aanval ';
          }

          switch (code) {
            case 'POSSESSION':
              return `${
                attributes.get('teamId') === homeTeam.id
                  ? positionDescription + homeTeam.label
                  : positionDescription + awayTeam.label
              }`;
              break;

            default:
              throw "Don't know how to describe " + code;
          }
        });
      }

      switchPossession() {
        const {
          observationLogger,
          homeTeam,
          awayTeam,
        } = this.props.observationContext;
        const teamSwitchMap = {
          [homeTeam.id]: awayTeam.id,
          [awayTeam.id]: homeTeam.id,
        };
        const partialObservationAttributeBag = observationLogger.getCurrentPartialObservationAttributes(
          'POSSESSION'
        );
        if (!partialObservationAttributeBag) {
          return;
        }
        const teamId =
          teamSwitchMap[partialObservationAttributeBag.get('teamId')];
        this._switchPossession(teamId);
      }

      _switchPossession(teamId) {
        CommandBus.dispatch(
          Command.create('ObservationLogger.TurnoffGroup', {
            groupId: 'POSSESSION',
          })
        );

        const activePosition =
          this.props.observationContext.goalCounter % 4 < 2
            ? 'ATTACK'
            : 'DEFENCE';

        CommandBus.dispatch(
          Command.create('ObservationLogger.StartRangeObservation', {
            groupId: 'POSSESSION',
            code: 'POSSESSION',
            attributes: { teamId, position: activePosition },
          })
        );
      }

      render() {
        const homeTeam = this.props.observationContext.homeTeam;
        const awayTeam = this.props.observationContext.awayTeam;
        const observationLogger = this.props.observationContext
          .observationLogger;
        let homePossessionBtn = null;
        let awayPossessionBtn = null;
        if (this.props.observationContext.goalCounter % 4 < 2) {
          homePossessionBtn = (
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: homeTeam.id, position: 'ATTACK' }}
              observationLogger={observationLogger}
              description={`${this.props.t('possession')} ${this.props.t(
                'firstAttack'
              )}  ${homeTeam.label}`}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable set-homepossession-button label ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['1']} onKeysPressed={trigger} />
                  {`${this.props.t('firstAttack')} ${homeTeam.label}`}
                  <span className="button-hotkey">1</span>
                  <img className="i" src={homeTeam.logoUrl} />
                </div>
              )}
            />
          );

          awayPossessionBtn = (
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: awayTeam.id, position: 'ATTACK' }}
              description={`${this.props.t('possession')} ${this.props.t(
                'firstAttack'
              )}  ${awayTeam.label}`}
              observationLogger={observationLogger}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable label label-i-lft ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['2']} onKeysPressed={trigger} />
                  <img className="i" src={awayTeam.logoUrl} />
                  {`${this.props.t('firstAttack')} ${awayTeam.label}`}
                  <span className="button-hotkey">2</span>
                </div>
              )}
            />
          );
        } else {
          homePossessionBtn = (
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: homeTeam.id, position: 'DEFENCE' }}
              observationLogger={observationLogger}
              description={`${this.props.t('possession')} ${this.props.t(
                'firstDefence'
              )}  ${homeTeam.label}`}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable set-homepossession-button label ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['1']} onKeysPressed={trigger} />
                  {`${this.props.t('firstDefence')} ${homeTeam.label}`}
                  <span className="button-hotkey">1</span>
                  <img className="i" src={homeTeam.logoUrl} />
                </div>
              )}
            />
          );

          awayPossessionBtn = (
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: awayTeam.id, position: 'DEFENCE' }}
              description={`${this.props.t('possession')} ${this.props.t(
                'firstDefence'
              )}  ${awayTeam.label}`}
              observationLogger={observationLogger}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable label label-i-lft ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['2']} onKeysPressed={trigger} />
                  <img className="i" src={awayTeam.logoUrl} />
                  {`${this.props.t('firstDefence')} ${awayTeam.label}`}
                  <span className="button-hotkey">2</span>
                </div>
              )}
            />
          );
        }

        return (
          <VideoObservationPanel
            observationContext={this.props.observationContext}
          >
            <PossessionButtonGroup>
              {homePossessionBtn}
              <PointTriggerButton
                keys="b"
                trigger={() => this.switchPossession()}
                description={this.props.t('ballOver')}
              />
              {awayPossessionBtn}
            </PossessionButtonGroup>
            <EventButtonGroup>
              <InputTrigger
                type="point"
                observationCode="BALL-LOSS"
                observationLogger={observationLogger}
                description={this.props.t('ballLoss')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="v"
                  />
                )}
              />
              <InputTrigger
                type="point"
                observationCode="SHOT"
                attributes={{ result: 'MISS' }}
                observationLogger={observationLogger}
                description={this.props.t('shotResultMiss')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="k"
                  />
                )}
              />
              <InputTrigger
                type="point"
                observationCode="SHOT"
                attributes={{ result: 'GOAL' }}
                observationLogger={observationLogger}
                description={this.props.t('shotResultGoal')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="l"
                  />
                )}
              />
              <InputTrigger
                type="point"
                observationCode="PENALTY-GIVEN"
                attributes={{ type: 'FREE-BALL' }}
                observationLogger={observationLogger}
                description={this.props.t('freeShotGiven')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="8"
                  />
                )}
              />
              <InputTrigger
                type="point"
                observationCode="PENALTY-GIVEN"
                attributes={{ type: 'PENALTY-SHOT' }}
                observationLogger={observationLogger}
                description={this.props.t('penaltyShotGiven')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="9"
                  />
                )}
              />
            </EventButtonGroup>
          </VideoObservationPanel>
        );
      }
    }
  )
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'korfball.video.default',
  component: KorfballObservationInput,
  options,
  usedKeys: ['1', '2', '8', '9', 'v', 'k', 'l'],
  title: 'Korfball Video',
});
