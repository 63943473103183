import React, { useState, useEffect } from 'react';
import Loading from 'lib/Loading';
import { Error } from 'lib/PlaceHolder';

const injectLoader = (loader, Component) => {
  return (props) => {
    const [extraProps, setExtraProps] = useState(null);
    const [state, setState] = useState('loading');

    useEffect(() => {
      const subLoader = async () => {
        try {
          console.log(props);
          const _extraProps = await loader(props);
          setExtraProps(_extraProps);
          setState('loaded');
        } catch (e) {
          console.log(e);
          setState('error');
        }
      };
      subLoader();
    }, []);

    if (state === 'loading') {
      return <Loading type={'fullscreen'} />;
    } else if (state === 'error') {
      return <Error />;
    }

    return <Component {...props} {...extraProps} />;
  };
};

export { injectLoader };
