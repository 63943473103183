import ReactTooltip from 'react-tooltip';
import React from 'react';
import uuidv4 from 'uuid/v4';

export const Tooltip = ({
  tooltip,
  children,
  style = 'dark',
  className,
  place = 'right',
  delay = 500,
}) => {
  const id = uuidv4();
  return (
    <span data-tip className={'tooltip__wrapper'} data-for={id}>
      <ReactTooltip
        id={id}
        type={style}
        effect="solid"
        delayShow={delay}
        className={`tooltip--styled lock-tooltip ${className}`}
        backgroundColor={style == 'dark' ? '#495057' : '#f7f7f7'}
        place={place}
      >
        {typeof tooltip === 'function' ? tooltip() : tooltip}
      </ReactTooltip>

      {children}
    </span>
  );
};
