import React, { Fragment, useEffect, useState } from 'react';

import { Observer } from 'mobx-react';

import SportingEventCollection from 'domain/SportingEvent';
import TeamCollection from 'domain/Team';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';

const SportingEventDetail = withTranslation()(
  ({
    t,
    match: {
      params: { sportingEventId },
    },
  }) => {
    const [sportingEvent, setSportingEvent] = useState(null);

    useEffect(() => {
      const loadData = async () => {
        setSportingEvent(null);
        await SportingEventCollection.fetchIfEmpty();

        let sportingEvent = SportingEventCollection.get(sportingEventId);
        if (
          sportingEvent.isCopy() &&
          sportingEvent.original.privilegedTo('observe')
        ) {
          sportingEvent = sportingEvent.original;
        }
        await sportingEvent.eventStreams.fetchIfEmpty();
        await TeamCollection.fetchIfEmpty();
        setSportingEvent(sportingEvent);
      };
      loadData();
    }, [sportingEventId]);

    if (sportingEvent === null) {
      return <Loading type={'fullscreen'} />;
    }

    const pageProps = {
      title: 'Details',
      breadcrumbs: [
        { path: '/', title: 'Home' },
        { path: '/match', title: t('module.match:matches') },
        {
          title: `${sportingEvent.homeTeam.label} - ${sportingEvent.awayTeam.label}`,
        },
      ],
    };

    console.log(sportingEvent.eventStreams);

    return (
      <Page {...pageProps}>
        <Observer>
          {() => {
            const eventStream =
              sportingEvent.eventStreams.length > 0
                ? sportingEvent.eventStreams.at(0)
                : null;

            if (eventStream !== null) {
              return (
                <Fragment>
                  <div>
                    PollingUrl: {eventStream.get('endpointUrls').polling}
                  </div>
                </Fragment>
              );
            } else {
              return (
                <div>
                  No eventStream created yet. <br />
                  <button
                    onClick={() =>
                      sportingEvent.eventStreams.create(
                        {},
                        { optimistic: false }
                      )
                    }
                  >
                    Create EventStream
                  </button>
                </div>
              );
            }
          }}
        </Observer>
      </Page>
    );
  }
);

export default SportingEventDetail;
