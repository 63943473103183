import { gotoRoute, RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import './styles/index.scss';

import { Match } from './containers';
import { Tutorial } from './containers/tutorial';
import { DemoRedirect } from '../match/components/DemoRedirect';
const MODULE_NAME = 'play';

let types = ['match', 'recording', 'training'];

let routes = [];
let redirects = [];
for (const type of types) {
  routes.push(new RouteExact(`${type}/:sportingEventId`, Match, type));
}
if (window.isSkillReflect) {
  // Redirect URL with play/match -> play/recording.
  redirects.push(
    new RouteRedirect('match/:sportingEventId', 'recording/:sportingEventId')
  );
}

routes.push(new RouteExact('demo', DemoRedirect, 'demo'));

export default new Module(
  MODULE_NAME,
  [],
  [
    ...redirects,
    ...routes,
    new RouteExact('tutorials/:tutorialId', Tutorial, 'tutorials'),
  ]
);
