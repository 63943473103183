import React, { Component } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';
import Select from 'react-select';

import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';

import { withTranslation } from 'react-i18next';
import { goBack, gotoPath } from 'modules/route';
import { GrowthAmbitionCreateSelectPeriod } from './select-period';
import { GrowthAmbitionCreateSelectLearningIndicators } from './select-learning-indicators';
import { GrowthAmbitionCreateConfirm } from './confirm';

import { Error } from 'lib/PlaceHolder';

// import './index.scss';

const GrowthAmbitionCreate = withTranslation(
  'module.development.growthAmbition.create'
)(
  observer(
    class GrowthAmbitionCreate extends Component {
      constructor(props) {
        super(props);
        this.stepIdx = observable(0);
        this.learningLineId = observable(null);
        this.learningLineOptions = [];
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          // Q: is it possible that there are no learning lines yet?
          if (this.props.learningLines.length) {
            const initialLearningLineId = this.props.learningLines[0].id;
            this.learningLineId.set(initialLearningLineId);

            this.learningLineOptions = this.props.learningLines.map(
              (learningLine) => ({
                value: learningLine.id,
                label: learningLine.name,
              })
            );
          }

          this.draftGrowthAmbition =
            this.props.developmentPlan.getDraftGrowthAmbition();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Growth ambition',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      onSubmitPeriod = action(() => {
        this.stepIdx.set(this.stepIdx + 1);
      });

      onSubmitConfirm = async (args) => {
        await this.props.developmentPlan.addGrowthAmbition(
          this.draftGrowthAmbition.startDate,
          this.draftGrowthAmbition.endDate,
          this.draftGrowthAmbition.indicatorIds
        );
        gotoPath('monitor-growth-ambition');
      };

      onCancel() {
        goBack();
      }

      onSelectLearningLineChange = action(({ value }) => {
        this.learningLineId.set(value);
      });

      render() {
        const { t } = this.props;
        const { url: path } = this.props.match;

        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const breadcrumbs = [{ path: '/', title: 'Home' }];
        if (this.props.person) {
          breadcrumbs.push(
            ...[
              {
                path: '/development/pdp',
                title: this.props.t('module.development:playerDevelopment'),
              },
              { title: this.props.person.fullName },
            ]
          );
        }
        if (this.props.team) {
          breadcrumbs.push({
            title: this.props.t('module.development:teamDevelopment'),
          });
        }
        const pageProps = {
          title: t('module.development:title'),
          breadcrumbs,
          // subtitle: this.person.fullName,
          // filters: [
          //   {
          //     title: 'Overzicht' /*TODO:TRANSLATE*/,
          //     path: '../learning-line-overview',
          //   },
          //   {
          //     title: 'Groeiambitie' /*TODO:TRANSLATE*/,
          //     path: 'monitor-growth-ambition',
          //     isActive: true,
          //   },
          // ],
          showHeaderBackground: true,
          headerChildren: (
            <div className="growth-ambition-create">
              <div className="wizard-steps">
                <div
                  className={`wizard-step clickable ${
                    this.stepIdx.get() === 0 ? 'active' : ''
                  }`}
                  onClick={action(() => this.stepIdx.set(0))}
                >
                  Stap 1 &mdash; Kies periode
                </div>
                <div
                  className={`wizard-step clickable ${
                    this.stepIdx.get() === 1 ? 'active' : ''
                  }`}
                  onClick={action(() => this.stepIdx.set(1))}
                >
                  Stap 2 &mdash; Kies ontwikkelpunten
                </div>
              </div>
              <div>
                {this.stepIdx.get() === 0 && (
                  <GrowthAmbitionCreateSelectPeriod
                    draftGrowthAmbition={this.draftGrowthAmbition}
                    onSubmit={this.onSubmitPeriod}
                    onCancel={this.onCancel}
                  />
                )}
                {this.stepIdx.get() === 1 && (
                  <GrowthAmbitionCreateConfirm
                    onSubmit={this.onSubmitConfirm}
                    draftGrowthAmbition={this.draftGrowthAmbition}
                    onCancel={this.onCancel}
                  />
                )}
                <div
                  className={`form-inline row ${
                    this.stepIdx.get() === 0 ? 'disabled' : ''
                  }`}
                >
                  <div className="col-12 col-md-3 form-group">
                    <label htmlFor="select-learning-line">
                      Selecteer leerlijn
                    </label>
                  </div>
                  <div className="col-12 col-md-6">
                    <Select
                      id="select-learning-line"
                      options={this.learningLineOptions}
                      onChange={this.onSelectLearningLineChange}
                      value={this.learningLineOptions.find(
                        (option) => option.value === this.learningLineId.get()
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          ),
        };

        return (
          <Page {...pageProps}>
            <div
              className={`growth-ambition-create ${
                this.stepIdx.get() === 0 ? 'disabled' : ''
              }`}
            >
              <GrowthAmbitionCreateSelectLearningIndicators
                learningLine={this.props.learningLines.find(
                  (learningLine) =>
                    learningLine.id === this.learningLineId.get()
                )}
                developmentPlan={this.props.developmentPlan}
                draftGrowthAmbition={this.draftGrowthAmbition}
              />
            </div>
          </Page>
        );
      }
    }
  )
);

export { GrowthAmbitionCreate };
