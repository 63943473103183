import { VideoFragment } from '../../VideoFragment';
import { Cell } from '../../Cell';

export class Report {
  private data: { [key: string]: Cell } = {};
  private metaData: { [key: string]: string } = {};

  constructor() {}

  setCell(
    path: string,
    metrics: { [key: string]: number },
    videoFragments: VideoFragment[]
  ) {
    this.data[path] = new Cell(metrics, videoFragments);
  }

  setMetaData(path: string, data: string) {
    this.metaData[path] = data;
  }

  addCell(
    path: string,
    metrics: { [key: string]: number },
    videoFragments: VideoFragment[]
  ) {}

  getCell(path: string): Cell {
    if (this.data.hasOwnProperty(path)) {
      return this.data[path];
    } else {
      return Cell.empty();
    }
  }

  getMetaData(path: string): string {
    return this.metaData[path] || '';
  }

  // private _setCell(path: string, cell: Cell) {
  //   this.data[path] = cell;
  // }
  //
  // filter(prefix: string): Report {
  //   const prefixLength: number = prefix.length;
  //
  //   const filteredReport = new Report();
  //   for (const path of Object.keys(this.data)) {
  //     if (path.startsWith(prefix)) {
  //       filteredReport._setCell(path.substring(), this.data[path]);
  //     }
  //   }
  //   return filteredReport;
  // }
}
