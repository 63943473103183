import React, { useState } from 'react';

import { EfficiencyLabel } from './efficiency-label';

const effiencyClassName = percentage => {
  if (percentage > 0.55) {
    return 'success-high';
  } else if (percentage <= 0.55 && percentage > 0.4) {
    return 'warning';
  } else {
    return 'failure-mid';
  }
};

export const Table = ({
  columns = [],
  rows = [],
  title = '',
  onRowClick = console.debug,
  onCellClick = console.debug,
  ...props
}) => {
  const rowTitleWidth = 160;
  const columnTitleHeight = 25;

  const columnWidth = 46;
  const rowHeight = 39;

  const handleRowClick = row => () => {
    onRowClick(row);
  };
  const selectedRows = rows.filter(row => row.selected);

  const isRowDisabled = row => {
    return selectedRows.length && !selectedRows.includes(row);
  };

  return (
    <g {...props}>
      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="12"
        fontWeight="400"
        fill="#000000"
      >
        <tspan>{title}</tspan>
      </text>
      {columns.map((column, idx) => (
        <text
          key={idx}
          textAnchor="middle"
          x={rowTitleWidth + (0.5 + idx) * columnWidth}
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="12"
          fontWeight="normal"
          fill="#000000"
        >
          <tspan>{column.label}</tspan>
        </text>
      ))}

      {rows.map((row, idx) => {
        const top = columnTitleHeight + idx * rowHeight;
        const rowTitle = row.label;
        const titleWidth = getTextWidth(
          rowTitle,
          '600 10pt Avenir-Heavy, Avenir'
        );
        return (
          <g
            key={idx}
            transform={`translate(0, ${top})`}
            className={`dashboard-table-row ${
              isRowDisabled(row) ? 'disabled' : ''
            }`}
          >
            <g>
              <rect
                className="player-label"
                fill={row.color}
                fillRule="nonzero"
                x="40"
                y="-12"
                width={titleWidth + 20}
                height="34"
                rx="7"
              />
              {row.selected && (
                <rect
                  className="player-label-filler"
                  fill={row.color}
                  fillRule="nonzero"
                  x="13"
                  y="-12"
                  width={rowTitleWidth + columns.length * columnWidth - 13}
                  height="34"
                  rx="7"
                />
              )}
              <defs>
                <clipPath id={`circle-clip-${idx}`}>
                  <circle rx="0" ry="0" r="17" transform="translate(17, 5)" />
                </clipPath>
              </defs>
              s
              <image
                x="0"
                y="-12"
                xlinkHref={row.icon}
                height="34"
                width="34"
                clipPath={`url(#circle-clip-${idx})`}
              />
              <text
                fontFamily="Avenir-Heavy, Avenir, Lato"
                fontSize="12"
                fontWeight="600"
                fill="#FFFFFF"
              >
                <tspan x="50" y="10">
                  {rowTitle}
                </tspan>
              </text>
              {/* transparent click layer */}
              <rect
                fill="#ffffff"
                opacity="0"
                x="0"
                y="-12"
                height="34"
                width={rowTitleWidth}
                onClick={handleRowClick(row)}
                className="clickable"
              />
            </g>

            {columns.map((column, idx) => {
              const successMetric = column.successMetric || 'scored';
              const cell = row.data[column.key];
              // TODO
              const success = cell.metrics[successMetric];
              const total = cell.metrics.total;
              return (
                <EfficiencyLabel
                  key={idx}
                  className={
                    (column.classFn && column.classFn(success, total)) ||
                    effiencyClassName
                  }
                  tx={rowTitleWidth + idx * columnWidth}
                  success={success}
                  total={total}
                  onClick={
                    total > 0 ? () => onCellClick(cell, successMetric) : null
                  }
                />
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}
