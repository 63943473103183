import { VideoTask } from './VideoTask';
import { ReadingTask } from './ReadingTask';
import { DocumentTask } from './DocumentTask';
import { TextTask } from './TextTask';
import { QuestionAnswerTask } from './QuestionAnswerTask';
import { useAssignmentContext } from '../assignments/assignmentContext';
import Loading from '../../../lib/Loading';

export const LearninglineTask = ({ name, task, assignment }) => {
  let Element;

  const assignmentContext = useAssignmentContext();

  switch (task.taskType) {
    case 'read':
      Element = (
        <ReadingTask
          assignmentName={name}
          assignment={assignment}
          task={task}
        />
      );
      break;
    case 'document':
      Element = (
        <DocumentTask
          assignmentName={name}
          task={task}
          assignment={assignment}
        />
      );
      break;
    case 'video':
      Element = (
        <VideoTask assignmentName={name} task={task} assignment={assignment} />
      );
      break;
    case 'text':
      Element = (
        <TextTask assignmentName={name} task={task} assignment={assignment} />
      );
      break;
    case 'question_answer':
      Element = (
        <QuestionAnswerTask
          assignmentName={name}
          task={task}
          assignment={assignment}
        />
      );
      break;
  }

  if (assignmentContext.isFetching) {
    Element = <Loading type={'spinner'} />;
  }

  return (
    <div className={'learningLineTask'}>
      <div
        className={'task-body'}
        dangerouslySetInnerHTML={{ __html: task.body }}
      ></div>
      {Element}
    </div>
  );
};
