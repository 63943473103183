import uuidv4 from 'uuid/v4';

// const Event = (name, attributeNames) => {
//     // Maybe add https://github.com/Bootstragram/zealit/blob/master/zealit.js
//     attributeNames = attributeNames.split(" ");
//     return function (...attributeValues) {
//         if (attributeNames.length !== attributeValues.length) {
//             throw new "Argument mismatch";
//         }
//         for (let i = 0; i < attributeNames.length; i++) {
//             this[attributeNames[i]] = attributeValues[i];
//         }
//         this.eventName = name;
//         this.id = uuidv4();
//         return Object.freeze(this);
//     }
// };

class Event {
  static events = {};

  static register(eventType, attributeNames) {
    Event.events[eventType] = attributeNames.split(' ');
  }

  static create(eventType, attributes) {
    if (!Event.events.hasOwnProperty(eventType)) {
      throw 'Unknown command ' + eventType;
    }

    const eventAttributes = Event.events[eventType];

    for (const attributeName of eventAttributes) {
      if (!attributes.hasOwnProperty(attributeName)) {
        throw 'Event Attribute missing: ' + eventType + ' - ' + attributeName;
      }
    }

    return new Event(uuidv4(), eventType, attributes);
  }

  constructor(id, eventType, attributes) {
    for (let [key, value] of Object.entries(attributes)) {
      this[key] = value;
    }
    this.id = id;
    this.eventType = eventType;
    return Object.freeze(this);
  }
}

export default Event;
