const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
const ARGUMENT_NAMES = /([^\s,]+)/g;

export function getDependencies(func: any): string[] {
  const fnStr = func.toString().replace(STRIP_COMMENTS, '');
  let result = fnStr.slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')'));
  const ret = /([^\s,]+)\s(.+)/.exec(result);
  if (ret === null) {
    return [];
  }
  const [_, deps] = ret.slice(1);

  let matches = deps.match(ARGUMENT_NAMES);
  if (matches === null) matches = [];
  return matches;
}

export function filteredObject(obj: { [key: string]: any }, keys: string[]) {
  const ret: { [key: string]: any } = {};
  for (const key of keys) {
    ret[key] = obj[key];
  }
  return ret;
}
