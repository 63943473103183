class AttributeBag {
  constructor(attributes) {
    this._attributes = {};
    this._setAttributes(attributes, false);
  }

  _setAttributes(attributes, allowOverwrite) {
    if (!attributes) {
      return;
    }
    for (let [key, attribute] of Object.entries(attributes)) {
      if (!(attribute instanceof Attribute)) {
        attribute = new Attribute(key, 'string', attribute);
      }
      if (key !== attribute.key) {
        throw "Key '{0}' of attribute does not match '{1}'".format(
          key,
          attribute.key
        );
      }
      if (
        this._attributes.hasOwnProperty(attribute.key) &&
        !this._attributes[attribute.key].hasUndefinedValue() &&
        this.get(attribute.key) !== attribute.value &&
        !allowOverwrite
      ) {
        //throw `Cannot overwrite '${attribute.key}'`;
        console.warning(`Should not overwrite '${attribute.key}'`);
      }
      this._attributes[attribute.key] = attribute;
    }
  }

  extend(attributeBag, allowOverwrite = false) {
    this._setAttributes(attributeBag.attributes, allowOverwrite);
  }

  get attributes() {
    return this._attributes;
  }

  get(key) {
    if (typeof this._attributes[key] === 'undefined') {
      debugger;
      throw 'Attribute ' + key + ' not found';
    }
    return this._attributes[key].value;
  }

  has(key) {
    const attribute = this._attributes[key];
    return !(typeof attribute === 'undefined' || attribute.hasUndefinedValue());
  }

  hasUndefinedAttributes() {
    for (const [key, attribute] of Object.entries(this._attributes)) {
      if (attribute.hasUndefinedValue()) {
        return true;
      }
    }
    return false;
  }

  toObject() {
    let ret = {};
    for (const [key, attribute] of Object.entries(this._attributes)) {
      ret[key] = attribute.value;
    }
    return ret;
  }
}

class Attribute {
  constructor(key, type, value) {
    this._key = key;
    this._type = type;
    this._setValue(value);
  }

  _setValue(value) {
    //check type
    this._value = value;
  }

  get key() {
    return this._key;
  }

  get type() {
    return this._type;
  }

  get value() {
    return this._value;
  }

  hasUndefinedValue() {
    return typeof this.value === 'undefined';
  }
}

export { AttributeBag as default, Attribute };
