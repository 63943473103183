import React from 'react';
import { withTranslation } from 'react-i18next';

const ModalView = ({
  onSave,
  onSaveText,
  saveButtonDisabled,
  onCancel,
  onCancelText,
  t,
  children,
}) => (
  <div className="data-sync-modal-view">
    <div className="modal-header">
      <h5 className="modal-title">{t('title')}</h5>
    </div>
    <div className="modal-body">{children}</div>
    <div className="modal-footer">
      <div className="form-actions">
        <button
          type="button"
          className="btn btn-primary"
          onClick={onSave}
          disabled={!!saveButtonDisabled}
        >
          {onSaveText || t('common:save')}
        </button>
        <button type="button" className="btn btn-link" onClick={onCancel}>
          {onCancelText || t('common:cancel')}
        </button>
      </div>
    </div>
  </div>
);
ModalView.defaultProps = {
  onSave: console.warn,
  onCancel: console.warn,
};
const ModalViewNS = withTranslation('module.dataSync')(ModalView);

export { ModalViewNS as ModalView };
