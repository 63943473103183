class MatchConfigurationInput {
  constructor({ Component, getInitialValues, getValues, validator }) {
    this.Component = Component;
    this.getValues = getValues;
    this.getInitialValues = getInitialValues;
    this.validator = validator;
  }
}

export { MatchConfigurationInput };
