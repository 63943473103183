import { useState } from 'react';
import { Modal } from '../Modal';
import { useTranslation } from 'react-i18next';

export const MultiStepModal = ({
  steps,
  onComplete,
  onClose,
  isCompleteable = true,
  modalProps,
  initialStep = 0,
}) => {
  const [currentStep, setCurrentStep] = useState(initialStep);

  return (
    <div>
      <Modal
        {...modalProps}
        multiStepHandler={
          steps.length > 1 && (
            <StepNavigation
              currentStep={currentStep}
              steps={steps}
              callback={(i) => setCurrentStep(i)}
            />
          )
        }
        onCloseClick={onClose}
      >
        {steps[currentStep].title && (
          <div className="modal-header">
            <h3>{steps[currentStep].title}</h3>}
          </div>
        )}
        {steps[currentStep].component}
        <StepAction
          step={currentStep}
          steps={steps}
          callback={(int) => setCurrentStep(int)}
          isCompleteAble={isCompleteable}
          onComplete={onComplete}
        />
      </Modal>
    </div>
  );
};

const StepNavigation = ({ currentStep, steps, callback }) => {
  if (steps.length > 1) {
    return (
      <div className="stepnavigation">
        {steps.map((step, index) => {
          return (
            <a
              className={`tabselect ${currentStep === index ? 'active' : ''}`}
              onClick={() => callback(index)}
              key={`step-${index}`}
            >
              {index + 1}. {step.label}
            </a>
          );
        })}
      </div>
    );
  } else {
    return <div className="stepnavigation"></div>;
  }
};

const StepAction = ({ step, steps, callback, onComplete, isCompleteAble }) => {
  const { t } = useTranslation('common');
  return (
    <div className="stepactions d-flex justify-content-between">
      {steps.length > 1 && (
        <button
          onClick={() => {
            callback(step - 1);
          }}
          className={`btn btn-link ${step === 0 ? 'disabled' : ''}`}
        >
          {t('prev')}
        </button>
      )}
      {step < steps.length - 1 && (
        <button
          onClick={() => {
            callback(step + 1);
          }}
          className="btn btn-primary"
        >
          {t('next')}
        </button>
      )}
      {step === steps.length - 1 && (
        <button
          className={`btn btn-primary ${!isCompleteAble ? 'disabled' : ''}`}
          onClick={() => {
            return isCompleteAble ? onComplete() : null;
          }}
        >
          {t('close')}
        </button>
      )}
    </div>
  );
};
