import React, { Component, Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import omit from 'lodash/omit';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { LicenseTypeSelect } from './type-select';
import { ProductSelect } from './product-select';

const NewLicenseForm = withTranslation('module.reseller')(
  observer(({ t, values, onSubmit, onCancel }) => (
    <form className="card" onSubmit={onSubmit}>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="licenseType">{t('licenseType')}</label>
              <LicenseTypeSelect
                t={t}
                className="form-control"
                name="licenseType"
                value={values.licenseType}
                onChange={action(
                  evt => (values.licenseType = evt.target.value)
                )}
              />
            </div>
          </div>
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="productId">{t('products')}</label>
              <ProductSelect
                t={t}
                className="form-control"
                name="productId"
                value={values.productId}
                onChange={action(evt => (values.productId = evt.target.value))}
              />
            </div>
          </div>
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="licenseStart">{t('licenseStart')}</label>
              <DatePicker
                id="new-clubs-license-start"
                name="licenseStart"
                className="form-control-datepicker"
                selected={values.licenseStart}
                onChange={action(value => {
                  values.licenseStart = value;
                })}
                autoComplete="off"
                isClearable={false}
                placeholderText={t('licenseStart')}
                popperPlacement="top"
              />
            </div>
          </div>
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="licenseDuration">{t('licenseDuration')}</label>
              <DurationSelect
                t={t}
                className="form-control"
                name="licenseDuration"
                value={values.licenseDuration}
                onChange={action(
                  evt => (values.licenseDuration = evt.target.value)
                )}
              />
            </div>
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            {t('common:save')}
          </button>
          <button type="button" className="btn btn-link" onClick={onCancel}>
            {t('common:cancel')}
          </button>
        </div>
      </div>
    </form>
  ))
);

const DurationSelect = props => {
  const { t } = props;

  const options = [
    { value: '1', label: t('duration.one') },
    { value: '2', label: t('duration.two') },
    { value: '3', label: t('duration.three') },
  ];

  const propsSanitized = omit(props, 't');

  return (
    <select {...propsSanitized}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export { NewLicenseForm };
