// export const themeGroups: { [key: string]: TrainingTheme[] } = {
//   Schieten: [
//     { id: '1', label: 'Op 1 been' },
//     { id: '2', label: 'Stap naar achteren' },
//     { id: '3', label: 'Korte kansen' },
//     { id: '4', label: 'Afstandschoten' },
//   ],
//   Assist: [
//     { id: '5', label: 'Met 1 hand' },
//     { id: '6', label: 'Uitstappen' },
//     { id: '7', label: 'Vanuit aansteun' },
//     { id: '8', label: 'Vanuit buitenveld' },
//     { id: '9', label: 'Hoog naar hoog' },
//     { id: '10', label: 'Buiten naar buiten' },
//   ],
//   Vrijlopen: [
//     { id: '11', label: '1 tegen 1 actie' },
//     { id: '12', label: 'Vrijlopen uit 3-1' },
//     { id: '13', label: 'Korf gebruiken om vrij te komen' },
//     { id: '14', label: 'Elkaar gebruiken' },
//     { id: '15', label: 'In steun komen op goede momenten' },
//   ],
//
//   Rebound: [
//     { id: '16', label: 'Lopende rebound' },
//     { id: '17', label: 'Statische rebound' },
//   ],
//
//   'Voorkomen van schieten': [
//     { id: '18', label: 'Bal verdedigen' },
//     { id: '19', label: 'Tegenstander een kant op dwingen' },
//     { id: '20', label: 'Tegenstander insluiten' },
//     { id: '21', label: 'Tegenstander naar binnen dwingen' },
//   ],
//
//   'Voorkomen van assist': [{ id: '22', label: 'Bal verdedigen' }],
//
//   Onderscheppen: [
//     { id: '23', label: 'Overzicht op de bal' },
//     { id: '24', label: 'Tegenstander naar bal toe dwingen' },
//     { id: '25', label: 'Tegenstander dwingen tot lange lijn' },
//     { id: '26', label: 'Voorverdedigen' },
//   ],
//   Rebounden: [
//     { id: '27', label: 'Verdedigende statische rebound' },
//     { id: '28', label: 'Zakkende verdedigers' },
//     { id: '29', label: 'Rebounder vastzetten' },
//   ],
// };

export const themes: TrainingTheme[] = [
  { id: '1', label: 'Schieten' },
  { id: '2', label: 'Assist' },
  { id: '3', label: 'Vrijlopen' },
  { id: '4', label: 'In balbezit blijven' },
  { id: '5', label: 'Voorkomen van schieten' },
  { id: '6', label: 'Voorkomen van passing' },
  { id: '7', label: 'Onderscheppen' },
  { id: '8', label: 'In balbezit komen' },
];
