import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';

import {
  VideoObservationPanel,
  PossessionButtonGroup,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';
import { Modal } from 'lib/Modal';

const TennisObservationInput = observer(
  class TennisObservationInput extends Component {
    componentWillMount() {
      const { observationLogger, clock } = this.props.observationContext;

      observationLogger.on(
        'afterObservationAdded',
        (code, attributes, triggerTime, startTime, endTime, description) => {
          CommandHistory.instance().tagHistoryItem(description);
        }
      );

      observationLogger.on(
        'afterObservationAdded',
        (observationCode, attributes) => {
          switch (observationCode) {
            case 'SERVICE':
            case 'RETURN':
              clock.setRate(0.25);
              break;
            case 'APPROACH':
            case 'NETPLAY':
            case 'BASELINE':
              clock.setRate(0.7);
              break;
            case 'ENDPOINT':
              clock.setRate(2.0);
              break;
            case 'SERVICE-ERROR':
              clock.setRate(2.0);
              clock.play();
              break;
          }
        }
      );

      //   observationLogger.on(
      //     'afterObservationAdded',
      //     (observationCode, attributes) => {
      //       switch (observationCode) {
      //         case 'SERVICE-ERROR':
      //         case 'ENDPOINT':
      //           if (this.props.afterAdd) {
      //             const observations = this.props.observationContext.observationLogger.observationLog.toArray();
      //             const lastObservation = observations[observations.length - 1];
      //             this.props.afterAdd({ id: lastObservation.id });
      //           } else {
      //             this.props.observationContext.clock.pause();
      //           }
      //           break;
      //       }
      //     }
      //   );
    }

    cancelServiceError() {
      const command = Command.create(
        'ObservationLogger.RemovePartialObservation',
        { groupId: 'SERVICE-ERROR' }
      );
      CommandBus.dispatch(command);
      this.props.observationContext.clock.play();
    }

    cancelEndpoint() {
      const command = Command.create(
        'ObservationLogger.RemovePartialObservation',
        { groupId: 'ENDPOINT' }
      );
      CommandBus.dispatch(command);
      this.props.observationContext.clock.play();
    }

    render() {
      const observationLogger = this.props.observationContext.observationLogger;
      const serviceErrorAttributes = observationLogger.getCurrentPartialObservationAttributes(
        'SERVICE-ERROR'
      );

      const endpointAttributes = observationLogger.getCurrentPartialObservationAttributes(
        'ENDPOINT'
      );

      return (
        <VideoObservationPanel
          observationContext={this.props.observationContext}
        >
          <EventButtonGroup>
            <InputTrigger
              type="point"
              observationCode="SERVICE"
              observationLogger={observationLogger}
              attributes={{ type: 'FIRST' }}
              description="1ste Service"
              render={props => <PointTriggerButton {...props} keys="a" />}
            />
            <InputTrigger
              type="start-point"
              groupId="SERVICE-ERROR"
              observationCode="SERVICE-ERROR"
              observationLogger={observationLogger}
              description="Service fout"
              render={props => <PointTriggerButton {...props} keys="e" />}
            />
            {serviceErrorAttributes && (
              <Modal onCloseClick={() => this.cancelServiceError()}>
                {/*<div> */}
                {/*<img src={tennisZonesSvg} width={190} height={340} /> */}
                {/*</div> */}
                <InputTrigger
                  type="end-point"
                  groupId="SERVICE-ERROR"
                  observationCode="SERVICE-ERROR"
                  observationLogger={observationLogger}
                  attributes={{ result: 'OUT', side: 'LONG' }}
                  description="Service fout - Long"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Service fout - long
                    </button>
                  )}
                />
                <InputTrigger
                  type="end-point"
                  groupId="SERVICE-ERROR"
                  observationCode="SERVICE-ERROR"
                  observationLogger={observationLogger}
                  attributes={{ result: 'NET' }}
                  description="Service fout - Net"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Service fout - net
                    </button>
                  )}
                />
                <InputTrigger
                  type="end-point"
                  groupId="SERVICE-ERROR"
                  observationCode="SERVICE-ERROR"
                  observationLogger={observationLogger}
                  attributes={{ result: 'OUT', side: 'LEFT' }}
                  description="Service fout - links naast"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Service fout - links naast
                    </button>
                  )}
                />
                <InputTrigger
                  type="end-point"
                  groupId="SERVICE-ERROR"
                  observationCode="SERVICE-ERROR"
                  observationLogger={observationLogger}
                  attributes={{ result: 'OUT', side: 'RIGHT' }}
                  description="Service fout - rechts naast"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Service fout - rechts naast
                    </button>
                  )}
                />
              </Modal>
            )}

            <InputTrigger
              type="point"
              observationCode="SERVICE"
              observationLogger={observationLogger}
              attributes={{ type: 'SECOND' }}
              description="2de Service"
              render={props => <PointTriggerButton {...props} keys="s" />}
            />

            <InputTrigger
              type="point"
              observationCode="RETURN"
              observationLogger={observationLogger}
              attributes={{ stroke: 'BACKHAND' }}
              description="Return BH"
              render={props => <PointTriggerButton {...props} keys="d" />}
            />
            <InputTrigger
              type="point"
              observationCode="RETURN"
              observationLogger={observationLogger}
              attributes={{ stroke: 'FOREHAND' }}
              description="Return FH"
              render={props => <PointTriggerButton {...props} keys="f" />}
            />
            <InputTrigger
              type="point"
              observationCode="BASELINE"
              observationLogger={observationLogger}
              attributes={{ stroke: 'BACKHAND' }}
              description="Baseline BH"
              render={props => <PointTriggerButton {...props} keys="b" />}
            />
            <InputTrigger
              type="point"
              observationCode="BASELINE"
              observationLogger={observationLogger}
              attributes={{ stroke: 'FOREHAND' }}
              description="Baseline FH"
              render={props => <PointTriggerButton {...props} keys="n" />}
            />
            <InputTrigger
              type="point"
              observationCode="APPROACH"
              observationLogger={observationLogger}
              attributes={{ stroke: 'BACKHAND' }}
              description="Midcourt BH"
              render={props => <PointTriggerButton {...props} keys="g" />}
            />
            <InputTrigger
              type="point"
              observationCode="APPROACH"
              observationLogger={observationLogger}
              attributes={{ stroke: 'FOREHAND' }}
              description="Midcourt FH"
              render={props => <PointTriggerButton {...props} keys="h" />}
            />
            <InputTrigger
              type="point"
              observationCode="NETPLAY"
              observationLogger={observationLogger}
              attributes={{ stroke: 'BACKHAND' }}
              description="Netplay BH"
              render={props => <PointTriggerButton {...props} keys="t" />}
            />
            <InputTrigger
              type="point"
              observationCode="NETPLAY"
              observationLogger={observationLogger}
              attributes={{ stroke: 'FOREHAND' }}
              description="Netplay FH"
              render={props => <PointTriggerButton {...props} keys="y" />}
            />
            <InputTrigger
              type="point"
              observationCode="AGAINSTNETPLAY"
              observationLogger={observationLogger}
              attributes={{ stroke: 'FOREHAND' }}
              description="Against netplay FH"
              render={props => <PointTriggerButton {...props} keys="p" />}
            />

            <InputTrigger
              type="point"
              observationCode="AGAINSTNETPLAY"
              observationLogger={observationLogger}
              attributes={{ stroke: 'BACKHAND' }}
              description="Against netplay BH"
              render={props => <PointTriggerButton {...props} keys="q" />}
            />
            <InputTrigger
              type="point"
              observationCode="AGAINSTNETPLAY"
              observationLogger={observationLogger}
              description="Against netplay SMASH"
              attributes={{ stroke: 'SMASH' }}
              render={props => <PointTriggerButton {...props} keys="r" />}
            />
            <InputTrigger
              type="start-point"
              observationCode="ENDPOINT"
              groupId="ENDPOINT"
              observationLogger={observationLogger}
              description="Endpoint"
              render={props => <PointTriggerButton {...props} keys="j" />}
            />

            {endpointAttributes && (
              <Modal onCloseClick={() => this.cancelEndpoint()}>
                {/*<div> */}
                {/*<img src={tennisZonesSvg} width={190} height={340} /> */}
                {/*</div> */}
                <InputTrigger
                  type="end-point"
                  groupId="ENDPOINT"
                  observationCode="ENDPOINT"
                  observationLogger={observationLogger}
                  attributes={{ result: 'WINNER' }}
                  description="Winner"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Winner
                    </button>
                  )}
                />
                <InputTrigger
                  type="end-point"
                  groupId="ENDPOINT"
                  observationCode="ENDPOINT"
                  observationLogger={observationLogger}
                  attributes={{ result: 'FORCED-ERROR' }}
                  description="Forced Error"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Forced Error
                    </button>
                  )}
                />
                <InputTrigger
                  type="end-point"
                  groupId="ENDPOINT"
                  observationCode="ENDPOINT"
                  observationLogger={observationLogger}
                  attributes={{ result: 'UNFORCED-ERROR' }}
                  description="Unforced Error"
                  render={({ trigger }) => (
                    <button className="btn btn-primary" onClick={trigger}>
                      Unforced Error
                    </button>
                  )}
                />
              </Modal>
            )}
          </EventButtonGroup>
        </VideoObservationPanel>
      );
    }
  }
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'tennis.video.default',
  component: TennisObservationInput,
  options,
  usedKeys: ['a', 'b', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'n', 'p'],
  title: 'Tennis Video',
});
