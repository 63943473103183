import React from 'react';
import { DefaultScoreBoard } from './Default';
import { TennisScoreBoard } from './Tennis';

const ScoreBoard = ({ observationContext, sportType }) => {
  switch (sportType) {
    case 'tennis':
      return <TennisScoreBoard observationContext={observationContext} />;
    default:
      return <DefaultScoreBoard observationContext={observationContext} />;
  }
};

export { ScoreBoard };
