import React, { useState } from 'react';
import { ITableProps, Table } from 'ka-table';
import { showNewRow, updateEditorValue } from 'ka-table/actionCreators';
import {
  DataType,
  EditingMode,
  FilteringMode,
  SortingMode,
} from 'ka-table/enums';
import { DispatchFunc } from 'ka-table/types';

import { TextAreaEditor } from './pdp-table-textarea';
import { markerReducer, MarkerCell, MarkerEditor } from './pdp-table-marker';

import 'ka-table/style.scss';
import './pdp-table.scss';
import {
  DropdownColumnCell,
  DropdownColumnEditor,
} from './columns/dropdown-column';
import getLocalizedColumns from './pdp-table-columns';
import UnsavedChangesButton from './buttons/unsaved-changes-button';
import NoPdpData from './pdp-table-no-data';
import DeleteButton from './buttons/delete-button';
import SaveButton from './buttons/save-button';
import addNewRowReducer from './reducers/add-new-row-reducer';
import {
  domainToInternalPropertyMapping,
  mapDomainPropertiesToInternal,
  mapInternalPropertiesToDomain,
} from './utils/internalProperties';
import { statusOptions, weightOptions } from './pdp-table-options';
import DataState from '../enums/data-state';
import Loading from '../../../lib/Loading';

const createTableProps = (dataArray, t) => {
  // initial value of the *props
  const tablePropsInit: ITableProps = {
    columns: getLocalizedColumns(t),
    data: dataArray,
    editingMode: EditingMode.Cell,
    rowKeyField: 'developmentGoalId',
    sortingMode: SortingMode.Single,
    filteringMode: FilteringMode.None,
    columnResizing: true,

    format: ({ column, value }) => {
      if (column.dataType === DataType.Date) {
        return value && value.toLocaleDateString('nl-NL');
      }
    },
  };

  return tablePropsInit;
};

export const PDPTable = ({
  dataArray,
  saveNewRow,
  deleteRow,
  updateExistingRow,
  learningLines,
  t,
  dataState,
  openChanges,
  syncOpenChanges,
}) => {
  const dataMappedToInternalProp = dataArray.map(mapDomainPropertiesToInternal);

  const [tableProps, changeTableProps] = useState(
    createTableProps(dataMappedToInternalProp, t)
  );

  const dispatch: DispatchFunc = async (action) => {
    switch (action.type) {
      case 'DeleteRow':
        await deleteRow(action.rowKeyValue);
        break;

      case 'UpdateCellValue':
        await updateExistingRow(
          action.rowKeyValue,
          domainToInternalPropertyMapping[action.columnKey] ?? action.columnKey,
          action.value
        );
        break;
      case 'SaveNewRow':
        const developmentGoalProperties = tableProps.editableCells
          .filter((cell) => Object.keys(cell.rowKeyValue).length === 0)
          .map(({ columnKey, editorValue }) => ({
            key: columnKey,
            value: editorValue,
          }));

        const { developmentGoalId } = await saveNewRow(
          mapInternalPropertiesToDomain(developmentGoalProperties)
        );
        action = { ...action, rowKeyValue: developmentGoalId };
        break;
      default:
        break;
    }

    changeTableProps(markerReducer(addNewRowReducer, action));
  };

  const isEditingNewRow =
    tableProps.editableCells &&
    tableProps.editableCells.find((e) => typeof e.rowKeyValue === 'object');

  if (tableProps.data.length === 0 && tableProps.editableCells == null) {
    return <NoPdpData t={t} createNewGoal={() => dispatch(showNewRow())} />;
  }

  return (
    <div>
      <div className="actions">
        {!isEditingNewRow && (
          <button
            type="button"
            className="btn btn-secondary"
            style={{ marginBottom: '1rem' }}
            onClick={() => dispatch(showNewRow())}
          >
            + {t('addNewDevelopmentGoal')}
          </button>
        )}
        <div className="float-right">
          {dataState === DataState.DIRTY && (
            <UnsavedChangesButton
              t={t}
              syncOpenChanges={syncOpenChanges}
              numberOfUnsavedChanges={openChanges?.length}
            />
          )}
          {dataState === DataState.UPDATING && <Loading />}
          {dataState === DataState.UPDATED && (
            <span className="dataStatusText">
              {t('noUnsavedChanges')} <i className="i-check i-sm"></i>
            </span>
          )}
        </div>
      </div>

      <Table
        {...tableProps}
        dispatch={dispatch}
        childComponents={{
          cellEditor: {
            content: (props) => {
              if (props.column.editFormat === 'TEXTAREA') {
                return <TextAreaEditor {...props} />;
              } else {
                switch (props.column.key) {
                  case 'markerLevel0':
                    return (
                      <MarkerEditor
                        learningLines={learningLines}
                        editableCells={tableProps.editableCells}
                        {...props}
                      />
                    );
                  case 'markerLevel1':
                    return (
                      <MarkerEditor
                        learningLines={learningLines}
                        markerLevel={1}
                        editableCells={tableProps.editableCells}
                        {...props}
                      />
                    );
                  case 'markerLevel2':
                    return (
                      <MarkerEditor
                        markerLevel={2}
                        learningLines={learningLines}
                        editableCells={tableProps.editableCells}
                        {...props}
                      />
                    );
                  case 'status':
                    return (
                      <DropdownColumnEditor
                        {...props}
                        defaultValue={'OPEN'}
                        options={statusOptions}
                      />
                    );
                  case 'weight':
                    return (
                      <DropdownColumnEditor
                        {...props}
                        options={weightOptions}
                      />
                    );
                  case ':delete':
                    return <DeleteButton {...props} t={t} />;
                  case ':add':
                    if (typeof props.rowData.id != undefined) {
                      return <SaveButton {...props} {...tableProps} t={t} />;
                    }
                }
              }
            },
          },
          dataRow: {
            elementAttributes: ({ rowKeyValue }) => {
              return {
                className:
                  typeof rowKeyValue === 'object' ? 'new-row' : 'existing-row',
              };
            },
            content: (props) => {},
          },

          headCell: {
            elementAttributes: (props) => {
              if (props.column.key === ':add') {
                return {
                  className: 'add-actions',
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    right: 0,
                    backgroundColor: 'transparent',
                    textAlign: 'right',
                    zIndex: 10,
                  },
                };
              }
            },
            content: (props) => {},
          },
          cell: {
            elementAttributes: (props) => {
              if (
                props.column.isEditable !== false &&
                props.column.key !== ':add' &&
                props.column.key !== ':delete'
              ) {
                return {
                  className: 'edit-string',
                };
              }
              if (props.column.key === ':delete') {
                return {
                  className: 'delete-action',
                };
              } else if (props.column.key === ':add') {
                return {
                  className: 'add-actions',
                  style: {
                    ...props.column.style,
                    position: 'sticky',
                    right: 0,
                    textAlign: 'right',
                  },
                };
              }
            },
          },
          cellText: {
            content: (props) => {
              switch (props.column.key) {
                case 'markerLevel0':
                  return (
                    <MarkerCell
                      {...props}
                      learningLines={learningLines}
                      editableCells={tableProps.editableCells}
                    />
                  );
                case 'markerLevel1':
                  return (
                    <MarkerCell
                      markerLevel={1}
                      learningLines={learningLines}
                      editableCells={tableProps.editableCells}
                      {...props}
                    />
                  );
                case 'markerLevel2':
                  return (
                    <MarkerCell
                      markerLevel={2}
                      learningLines={learningLines}
                      editableCells={tableProps.editableCells}
                      {...props}
                    />
                  );
                case 'status':
                  return (
                    <DropdownColumnCell
                      {...props}
                      options={statusOptions}
                      defaultValue={'OPEN'}
                    />
                  );

                case ':delete':
                  return <DeleteButton {...props} t={t} />;
              }
            },
          },
        }}
      />
    </div>
  );
};
