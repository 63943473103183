import { getTemplate } from '../../remotion/templateRegistry';
import api from '../../utils/ApiClient';
import { useQuery, useQueryClient } from 'react-query';
import Loading from '../../lib/Loading';
import { useTranslation } from 'react-i18next';
import { Page } from '../../lib/Page';
import React, { useEffect, useRef, useState } from 'react';
import { HighlightsComposition } from '../../remotion/Highlights/HighlightsComposition';
import { VideoTimeProvider } from './utils/VideoTimeProvider';

import { HighlightContentBlockGeneric } from './highlighsContentBlock';
import { DragAndDropClips } from './DragAndDropClips';
import { Modal } from '../../lib';
import { ClipSelectionView } from './clip-selection-view';
import { ClipEditView } from './clip-edit-view';
import { ContentEditView } from './contentEditView';
import { AddContentView } from './add-content-view';
import { preprocessContent } from '../../remotion/helpers/preprocess-content';
import { calculateAspectRatioToBounds } from '../../remotion/helpers/aspect-ratio';
import Select, { components } from 'react-select';
import { preloadVideo } from '@remotion/preload';
import { historyManager } from './utils/historyManager';
import { HighlightsPlayer } from '../../remotion/helpers/HighlightsPlayer';
import { AudioRecordButton } from './buttons/audioRecord';
import { Renders } from './utils/Renders';
import { VideoSettings } from './VideoSettings';
import { generateClipThumbnail } from './utils/clipThumbnail';
import { Aside } from '../../lib/Aside';

export const HighlightsDetail = ({ match }) => {
  const highlightVideoId = match.params.id;
  const queryClient = useQueryClient();

  const { data: highlightVideo } = useQuery(
    ['highlightVideos', highlightVideoId, true],
    () => getHighlightVideoById(highlightVideoId, true)
  );

  const { data: sportingEvents } = useQuery(['sportingEvents'], async () =>
    getSportingEvents()
  );
  const { data: videos } = useQuery(['videos'], async () => getVideos());
  const { data: sponsors } = useQuery(['sponsors'], async () => getSponsors());
  if (!highlightVideo || !sportingEvents || !videos || !sponsors) {
    return <Loading type={'fullscreen'} />;
  }
  const template = getTemplate(highlightVideo.templateId);
  const onRefresh = () => {
    queryClient.invalidateQueries(['highlightVideos', highlightVideoId]);
  };

  const props = {
    highlightVideo,
    template,
    sportingEvents,
    videos,
    onRefresh,
    sponsors,
  };

  return <HighlightsDetailPage {...props} />;
};

const HighlightsDetailPage = ({
  highlightVideo,
  template,
  sportingEvents,
  videos,
  onRefresh,
  sponsors,
}) => {
  const { t } = useTranslation('module.highlights');

  const [aspectRatio, setAspectRatio] = useState({ x: 16, y: 9 });
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const recordButton = useRef(null);
  const playButton = useRef(null);

  const pageProps = {
    title: highlightVideo.name,
    breadcrumbs: [
      { path: '/highlights', title: t('highlights') },
      {
        title: highlightVideo.name,
      },
    ],
  };
  const [modalComponent, setModalComponent] = useState();

  const playerRef = useRef<PlayerRef>(null);

  // preprocessContent and calculateAspectRatioToBounds are required to render the video
  const { totalDuration, content, sponsorTimeBlocks } = preprocessContent(
    template,
    highlightVideo.content,
    videos,
    false,
    generateClipThumbnail
  );
  const ratioValue = calculateAspectRatioToBounds(
    aspectRatio.x,
    aspectRatio.y,
    template.width
  );

  const getClipVideo = (clipVideoId) => {
    for (const video of videos) {
      if (video.videoUri === clipVideoId) {
        return video;
        break;
      }
    }
  };
  const onTimeClick = (time) => {
    return () => {
      const player = playerRef.current;
      player.play();
      setTimeout(() => {
        player.seekTo((time + 0.1) * template.fps);
      }, 100);
    };
  };

  const pausePlayer = () => {
    const player = playerRef.current;
    if (player) {
      player.pause();
    }
  };

  const addContent = async (newContent, videoTime = null) => {
    let output = highlightVideo.content;
    if (videoTime !== null) {
      let nextToPlayIndex =
        content.findIndex(
          (contentItem) => contentItem.startTime >= videoTime
        ) ?? 0;
      output.splice(nextToPlayIndex, 0, newContent);
    } else {
      output = output.concat(newContent);
    }

    await updateContent(output);
  };
  const closeModal = () => {
    setModalComponent(null);
  };

  const openEditClip = (contentId, action, time) => {
    setModalComponent(
      <Modal size={'lg'} onCloseClick={() => closeModal()}>
        <ClipEditView
          highlightVideo={highlightVideo}
          onSuccess={() => closeModal()}
          onClose={() => closeModal()}
          onSave={updateContent}
          contentId={contentId}
          action={action}
          template={template}
          videos={videos}
        />
      </Modal>
    );
  };

  const openEditContent = (content, action, time) => {
    let original = highlightVideo.content.find(
      (_content) => _content.contentId === content.contentId
    );
    pausePlayer();
    setModalComponent(
      <Modal onCloseClick={() => closeModal()}>
        <ContentEditView onEditContent={onEditContent} content={original} />
      </Modal>
    );
  };

  const undo = () => {
    let _content = historyManager.undo(
      highlightVideo.highlightVideoId,
      highlightVideo.content
    );
    updateContent(_content);
  };

  const onEditContent = (newContent) => {
    const content = highlightVideo.content;
    const newContentindex = content.findIndex(
      (content) => content?.contentId === newContent.contentId
    );
    content[newContentindex] = newContent;

    updateContent(content);
    closeModal();
  };

  useEffect(() => {
    const unpreloadables = [];
    content.forEach((contentItem) => {
      if (contentItem.type === 'clip.Video') {
        const unpreloadVideo = preloadVideo(contentItem.videoUrl);
        unpreloadables.push(unpreloadVideo);
      }
    });
    return () => {
      unpreloadables.forEach((unpreload) => {
        unpreload();
      });
    };
  }, [content]);

  const openAddClips = () => {
    pausePlayer();
    setModalComponent(
      <Aside onClose={() => closeModal()} width={560}>
        <ClipSelectionView
          onSuccess={closeModal}
          onClose={closeModal}
          onAddClips={addContent}
          sportingEvents={sportingEvents}
          videos={videos}
          highlightVideoId={highlightVideo.highlightVideoId}
          template={template}
        />
      </Aside>
    );
  };

  const openAddContent = () => {
    pausePlayer();
    setModalComponent(
      <Aside onClose={() => closeModal()} width={560}>
        <AddContentView
          template={template}
          onAddContent={(newContent, videoTime) => {
            addContent(newContent, videoTime);
            closeModal();
          }}
        />
      </Aside>
    );
  };

  const updateContent = async (content) => {
    historyManager.set(
      'undo',
      highlightVideo.highlightVideoId,
      highlightVideo.content
    );
    await api.patch(
      `highlights/v2/highlightVideos/${highlightVideo.highlightVideoId}`,
      {
        templateId: highlightVideo.templateId,
        name: highlightVideo.name,
        content: content.map((content) => {
          // Don't send 'events' key back to the server
          const { $sportingEvent: _, ...toKeep } = content;
          return toKeep;
        }),
      }
    );
    onRefresh();
  };
  const onMoveContent = async (content) => {
    await updateContent(content);
  };

  const deleteContent = async (contentId) => {
    sweetAlert({
      title: t('deleteClip'),
      text: t('deleteClipSubtitle'),
      dangerMode: true,
      buttons: [t('common:cancel'), t('common:confirm')],
    }).then(async (willDelete) => {
      if (willDelete) {
        const newContent = content.filter(
          (content) => content.contentId !== contentId
        );
        await updateContent(newContent);
      }
    });
  };

  const getClipSportingEvent = (sportingEventId) => {
    return sportingEvents.find((s) => s.sportingEventId === sportingEventId);
  };

  const onStartRecord = () => {
    setIsRecording(true);
    playerRef.current.seekTo(0);
    playerRef.current.play();
    playerRef.current.addEventListener('ended', () => {
      (recordButton as any).current.stop();
      onStopRecord();
    });
  };
  const onStopRecord = () => {
    setIsRecording(false);
    playerRef.current.seekTo(0);
    playerRef.current.pause();
  };
  const saveAudio = async (data: Blob, duration: number) => {
    const reader = new FileReader();
    await new Promise((resolve) => {
      reader.onload = resolve;
      reader.readAsDataURL(data);
    });

    await api.put(
      `highlights/v2/highlightVideos/${highlightVideo.highlightVideoId}/audioTrack`,
      {
        b64data: (reader.result as string).split(',')[1],
        contentType: data.type,
        duration,
      }
    );
  };

  const onRecordDone = async (data, duration) => {
    // Save data to backend and re-fetch entire highlight video
    await saveAudio(data, duration);
    onRefresh();
  };

  let styles =
    aspectRatio.x <= aspectRatio.y
      ? { width: 'auto', height: 'calc(100vh - 260px)', maxWidth: '100%' }
      : { width: '100%', height: 'auto' };

  return (
    <Page {...pageProps}>
      <div className="highlight-view layout-with-sidebar">
        <div
          className="main video-player-container"
          style={{
            position: 'unset',
            overflow: 'hidden',
            display: 'flex',
          }}
        >
          <div className="d-flex justify-content-between">
            <SelectAspectRatio
              callback={(v) => setAspectRatio(v.value)}
              value={aspectRatio}
            />
            <VideoSettings
              callback={onRefresh}
              highlightVideo={highlightVideo}
            />
          </div>
          {totalDuration > 0 && (
            <div
              className="player-wrapper"
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '2em',
                aspectRatio: `${ratioValue.x}/${ratioValue.y}`,
                position: 'relative',
                flexShrink: 0,
                flexGrow: 0,
                ...styles,
              }}
            >
              <HighlightsPlayer
                playerRef={playerRef}
                composition={HighlightsComposition}
                durationInFrames={totalDuration * template.fps}
                compositionWidth={ratioValue.x}
                compositionHeight={ratioValue.y}
                isRecording={isRecording}
                fps={template.fps}
                style={{
                  width: '100%',
                }}
                inputProps={{
                  fps: template.fps,
                  content,
                  totalDurationInFrames: template.fps * totalDuration,
                  template,
                  audioTrackUrl: highlightVideo.audioTrackUrl,
                  sponsorTimeBlocks,
                  config: highlightVideo?.config,
                  sponsors,
                }}
                renderPlayPauseButton={renderPlayPauseButton}
                controls
              />
            </div>
          )}
          <div className="actions highlight-view-actions">
            <Renders
              highlightVideo={highlightVideo}
              aspectRatio={aspectRatio}
            />
            <AudioRecordButton
              hasRecording={highlightVideo.audioTracks.length > 0}
              ref={recordButton}
              onRecord={onStartRecord}
              onDone={onStopRecord}
              onRecordDone={onRecordDone}
            />
          </div>
        </div>
        <div className="sidebar highlight-content">
          <VideoTimeProvider fps={template.fps} playerRef={playerRef}>
            {modalComponent}
            <div className="sidebar-header">{t('highlightContent')} </div>
            <div className="sidebar-list">
              <div className="highlight-content-block">
                <div className="highlight-content-block-name"></div>
                <div className="highlight-content-block-actions">
                  <button
                    onClick={() => openAddClips()}
                    className="btn btn-sm btn-primary"
                  >
                    + {t('addClip')}
                  </button>
                  {template.availableContent.length > 0 && (
                    <button
                      onClick={() => openAddContent()}
                      className="btn btn-sm btn-primary"
                    >
                      + {t('addContent')}
                    </button>
                  )}
                </div>

                <HighlightContentList
                  getClipVideo={getClipVideo}
                  getClipSportingEvent={getClipSportingEvent}
                  highlightVideo={highlightVideo}
                  onTimeClick={onTimeClick}
                  highlightContent={content}
                  onMoveContent={onMoveContent}
                  template={template}
                  openEditClip={openEditClip}
                  openEditContent={openEditContent}
                  onDeleteContent={deleteContent}
                />
              </div>
            </div>
          </VideoTimeProvider>
        </div>
      </div>
    </Page>
  );
};

const HighlightContentList = ({
  highlightVideo,
  highlightContent,
  getClipVideo,
  getClipSportingEvent,
  onTimeClick,
  onMoveContent,
  onDeleteContent,
  openEditClip,
  openEditContent,
  template,
}) => {
  const intro = highlightContent.find((content) =>
    content.type.includes('intro')
  );
  const outro = highlightContent.find((content) =>
    content.type.includes('outro')
  );
  const content = highlightContent.filter(
    (content) =>
      !content.type.includes('intro') && !content.type.includes('outro')
  );

  const _onMoveContent = (content) => {
    // Fix intro and outro in clip list after moving
    onMoveContent([intro, ...content, outro].filter(Boolean));
  };

  return (
    <div className="highlight-content-block-clips">
      {intro && (
        <HighlightContentBlockGeneric
          onClick={onTimeClick(intro.startTime)}
          openEditContent={openEditContent}
          template={template}
          content={intro}
          onDeleteContent={onDeleteContent}
        />
      )}
      <DragAndDropClips
        content={content}
        highlightVideo={highlightVideo}
        getClipVideo={getClipVideo}
        getClipSportingEvent={getClipSportingEvent}
        onTimeClick={onTimeClick}
        onMoveContent={_onMoveContent}
        template={template}
        openEditClip={openEditClip}
        openEditContent={openEditContent}
        onDeleteContent={onDeleteContent}
      />
      {outro && (
        <HighlightContentBlockGeneric
          openEditContent={openEditContent}
          onClick={onTimeClick(outro.startTime)}
          template={template}
          content={outro}
          onDeleteContent={onDeleteContent}
        />
      )}
    </div>
  );
};

/* API fetches*/

const getHighlightVideoById = async (id, enriched = false) => {
  const { data } = await api.get(
    `/highlights/v2/highlightVideos/${id}?enriched=${
      enriched ? 'true' : 'false'
    }`
  );
  return data;
};

export const getSportingEvents = async () => {
  const response = await api.get(`sportingEvents`);
  const sportingEvents = response.data;
  return sportingEvents;
};
export const getSportingEvent = async (sportingEventId) => {
  const response = await api.get(`sportingEvents/${sportingEventId}`);
  const sportingEvents = response.data;
  return sportingEvents;
};

export const getVideos = async () => {
  const response = await api.get(`videos`);
  const videos = response.data;
  return videos;
};

export const getSponsors = async () => {
  const response = await api.get(`sponsors`);
  const sponsors = response.data;
  return sponsors;
};

const renderPlayPauseButton = ({ playing }) => {
  if (!playing) {
    return (
      <svg
        width="18"
        viewBox="0 0 11 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.2671 4.84847L2.09454 0.179287C1.88176 0.0599386 1.64153 -0.00184365 1.39757 4.19011e-05C1.15361 0.00192746 0.914356 0.0674151 0.703444 0.190039C0.492531 0.312662 0.317252 0.488179 0.194914 0.699258C0.0725773 0.910336 0.00741394 1.14968 0.00585938 1.39364V6.07496H10.9775C10.9768 5.82623 10.9109 5.58204 10.7862 5.36681C10.6615 5.15157 10.4825 4.97283 10.2671 4.84847Z"
          fill="white"
        />
        <path
          d="M2.09476 12.0073L10.2431 7.34419C10.4683 7.21774 10.6557 7.03346 10.7859 6.81038C10.9161 6.5873 10.9845 6.3335 10.9838 6.0752H0V10.7322C0.00587788 10.9763 0.072976 11.215 0.195133 11.4263C0.317289 11.6377 0.49059 11.815 0.699122 11.9419C0.907653 12.0688 1.14473 12.1413 1.38859 12.1528C1.63245 12.1642 1.87527 12.1142 2.09476 12.0073Z"
          fill="white"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="18"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="4" height="11" rx="2" fill="white" />
        <rect x="7" width="4" height="11" rx="2" fill="white" />
      </svg>
    );
  }
};

export const SelectAspectRatio = ({ callback, value }) => {
  const aspectRatioOptions = getAspectRationOptions();

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <i className="i-aspectratio" style={{ margin: '0 0 0 12px' }} />
        {children}
      </components.Control>
    );
  };
  return (
    <div className={'d-flex align-content-center align-items-center'}>
      <Select
        options={aspectRatioOptions}
        onChange={(v) => callback(v)}
        value={aspectRatioOptions.find(
          (ratio) => ratio.value.x === value.x && ratio.value.y === value.y
        )}
        components={{ Control }}
      />
    </div>
  );
};

export const getAspectRationOptions = () => {
  const aspectRatioOptions = [
    {
      label: '1:1 Square (Facebook / Instagram)',
      value: { x: 1, y: 1 },
      id: '1:1',
    },
    {
      label: '4:5 Square (Instagram)',
      value: { x: 4, y: 5 },
      id: '4:5',
    },
    {
      label: '2:3 Vertical (FaceBook)',
      value: { x: 2, y: 3 },
      id: '2:3',
    },
    {
      label: '9:16 Vertical (Instagram)',
      value: { x: 9, y: 16 },
      id: '9:16',
    },
    {
      label: '16:9 Standard (Youtube)',
      value: { x: 16, y: 9 },
      id: '16:9',
    },
  ];
  return aspectRatioOptions;
};
