import React, { Component } from 'react';
import omit from 'lodash/omit';

interface CheckboxProps {
  /**
   * Whether the checkbox is checked, i.e truthy
   */
  checked: boolean;

  /**
   * Content to display as a label next to the checkbox
   */
  children: React.ReactNode;

  /**
   * Whether a loading incdicator should be displayed
   */
  loading?: boolean;

  /**
   * Function called with the changed checked state after user interaction
   */
  onChange: (value: boolean) => void;
}

/**
 * Checkbox component, used to enable or disable boolean fields
 */
class Checkbox extends Component<CheckboxProps> {
  constructor(props) {
    super(props);
  }

  // Uses label for styling, therefore use "active" instead of "checked"
  checkedStyleClass = () => (this.props.checked ? 'active' : '');

  icon = () =>
    this.props.checked ? (
      <span className="i-check" />
    ) : (
      <span className="i-add" />
    );

  render() {
    return (
      <div
        className={`clickable label checkbox ${this.checkedStyleClass()} ${
          this.props.loading ? 'is-loading' : ''
        }`}
        {...omit(this.props, ['loading'])}
        onClick={() => this.props.onChange(!this.props.checked)}
      >
        {this.props.children}
        {this.icon()}
      </div>
    );
  }
}

export { Checkbox };
