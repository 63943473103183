import { api } from 'utils/ApiClient';
import { useQueryClient } from 'react-query';
import { useEffect } from 'react';

export * from './banner';
export * from './menu';
export * from './popup';

export type NotificationType = 'menu' | 'banner' | 'popup';

export type NotificationCategory = 'default' | 'success' | 'warning' | 'error';
export interface NotificationConfig {
  category?: NotificationCategory;
  priority?: number; // 1
  icon?: string; // 'warning'
  closable?: boolean; // true
  showAtTenantId?: string;
}
export interface Notification {
  notificationId: string; // 'uuid-4',
  messageHtml: string; //'<unsafehtmlbody />',
  type: NotificationType;
  createdAt: string; // '2021-07-20T08:03:13.196Z'
  config: NotificationConfig;
  interactions: string[];
  viewed: boolean;
}

const defaultConfig: NotificationConfig = {
  category: 'default',
  priority: 0,
  closable: true,
};

// get & cleanup notifications (banners only for now)
export const get = async (params: any) => {
  const { data } = await api.get<Partial<Notification>[]>(
    '/users/me/notifications',
    { params }
  );

  const cleaned: Notification[] = data.map(cleanupConfig);

  return cleaned;
};

export const patch = async (id: string, changeset: object) => {
  const { data } = await api.patch(`/users/me/notifications/${id}`, changeset);

  return data;
};

const cleanupConfig = (notification: Partial<Notification>) => {
  if (
    Array.isArray(notification.config) ||
    typeof notification.config !== 'object'
  ) {
    notification.config = { ...defaultConfig };
  } else {
    notification.config = { ...defaultConfig, ...notification.config };
  }
  notification.viewed = notification.interactions.indexOf('viewed') !== -1;

  return notification as Notification;
};

export const NotificationCacheInvalidator = ({ session }: { session: any }) => {
  const queryClient = useQueryClient();
  useEffect(() => {
    session.on('notifications.message', ({ channel }) => {
      if (channel.endsWith('/notifications/banner')) {
        queryClient.invalidateQueries('bannerNotifications');
      } else if (channel.endsWith('/notifications/menu')) {
        queryClient.invalidateQueries('menuNotifications');
      } else if (channel.endsWith('/notifications/popup')) {
        queryClient.invalidateQueries('popupNotifications');
      }
    });
  }, []);
  return null;
};
