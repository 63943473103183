import React, { useContext } from 'react';
import { observer, Observer } from 'mobx-react';

import {
  matchStateFromObservations as handballMatchStateFromObservations,
  MatchConfig as HandballMatchConfig,
} from '../../modules/sport/handball/matchStateFromObservations';

// BE CAREFULL!! WE MIX PLAIN JS OBJECTS WITH REACT

const formatTime = (time: string) => {
  const seconds: number = Math.floor(parseFloat(time));
  let s = seconds % 60;
  let m = (seconds - s) / 60;
  //let h = parseInt(seconds / 3600);
  //h += ""; if (h.length == 1) h = "0" + h;

  // m += '';
  // if (m.length == 1) m = '0' + m;
  // s += '';
  // if (s.length == 1) s = '0' + s;
  return `${m < 10 ? '0' : ''}${m}:${s < 10 ? '0' : ''}${s}`;
};

type ClockContextProps = {
  time: string;
  state: 'STARTED' | 'STOPPED' | 'PAUSED';
};

const ClockContext = React.createContext<Partial<ClockContextProps>>({
  time: 'START',
});

type ScoreContextProps = {
  homeScore: number;
  awayScore: number;
};

const ScoreContext = React.createContext<Partial<ScoreContextProps>>({
  homeScore: 0,
  awayScore: 0,
});

type TeamsContextProps = {
  homeTeam: {
    label: string;
    logoUrl?: string;
  };
  awayTeam: {
    label: string;
    logoUrl?: string;
  };
};

const TeamsContext = React.createContext<Partial<TeamsContextProps>>({});

type GameStateContextProps = {
  possession?: string;
};

const GameStateContext = React.createContext<Partial<GameStateContextProps>>(
  {}
);

const ObservationLoggerContext = React.createContext(null);

const getClockState = (
  observationContext: any
): 'STARTED' | 'STOPPED' | 'PAUSED' => {
  const currentPeriod = observationContext.currentPeriod;
  const timeObservations = observationContext.timeObservations;
  const [currentState, periodNr, startTime, endTime] = currentPeriod;
  let paused = false;
  const currentPeriodTimeObservations = timeObservations.filter(
    (observation: any) => {
      return observation.startTime >= startTime;
    }
  );
  for (const timeObservation of currentPeriodTimeObservations) {
    switch (timeObservation.code) {
      case 'TIME:PAUSE':
        paused = true;
        break;
      case 'TIME:RESUME':
        paused = false;
        break;
    }
  }

  const started = currentState === 'STARTED';
  if (currentState !== 'STARTED') {
    return 'STOPPED';
  } else {
    if (paused) {
      return 'PAUSED';
    } else {
      return 'STARTED';
    }
  }
};

const ObservationContextProvider = observer(
  ({ observationContext, children, sportType }) => {
    // let matchState;
    // switch (sportType) {
    //   case 'handball':
    //     matchState = handballMatchStateFromObservations(
    //       observationContext.observations(),
    //       new HandballMatchConfig(2)
    //     );
    //     break;
    //   default:
    //     return <div>nope</div>;
    // }

    const possessionAttributes = observationContext.observationLogger.getCurrentPartialObservationAttributes(
      'POSSESSION'
    );
    const gameState = !!possessionAttributes
      ? {
          possession:
            possessionAttributes.get('teamId') ===
            observationContext.homeTeam.teamId
              ? 'home'
              : 'away',
        }
      : {};

    // clock: matchState.clock
    const timeFn = observationContext.currentTimeFn;

    return (
      <ObservationLoggerContext.Provider
        value={observationContext.observationLogger}
      >
        <ScoreContext.Provider
          value={{
            homeScore: observationContext.homeGoalCounter,
            awayScore: observationContext.awayGoalCounter,
          }}
        >
          <TeamsContext.Provider
            value={{
              homeTeam: {
                label: observationContext.homeTeam.label,
                logoUrl: observationContext.homeTeam.logoUrl,
              },
              awayTeam: {
                label: observationContext.awayTeam.label,
                logoUrl: observationContext.awayTeam.logoUrl,
              },
            }}
          >
            <GameStateContext.Provider value={gameState}>
              <Observer>
                {() => {
                  let time = timeFn();
                  if (typeof time !== 'string') {
                    time = formatTime(time);
                  }
                  const state = getClockState(observationContext);
                  return (
                    <ClockContext.Provider value={{ time, state }}>
                      {children}
                    </ClockContext.Provider>
                  );
                }}
              </Observer>
            </GameStateContext.Provider>
          </TeamsContext.Provider>
        </ScoreContext.Provider>
      </ObservationLoggerContext.Provider>
    );
  }
);

const useObservationContext = (...providers: string[]) => {
  const result: { [key: string]: any } = {};

  if (providers.indexOf('clock') !== -1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result['clock'] = useContext(ClockContext);
  }
  if (providers.indexOf('teams') !== -1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result['teams'] = useContext(TeamsContext);
  }
  if (providers.indexOf('score') !== -1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result['score'] = useContext(ScoreContext);
  }
  if (providers.indexOf('gameState') !== -1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result['gameState'] = useContext(GameStateContext);
  }
  if (providers.indexOf('observationLogger') !== -1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    result['observationLogger'] = useContext(ObservationLoggerContext);
  }
  return result;
};

export { ObservationContextProvider, useObservationContext };
