import React, { Fragment, Component } from 'react';

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { withTranslation } from 'react-i18next';

const TeamManagementNew = withTranslation('module.settings.club')(
  observer(
    class TeamManagementNew extends Component {
      constructor() {
        super();
        this.name = observable('');
      }

      setName = action(name => {
        this.name.set(name);
      });

      render() {
        return (
          <form
            onSubmit={e => {
              this.props.onAdd({
                name: this.props.clubName + ' ' + this.name.get(),
              });
              e.preventDefault();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.props.t('addTeamTitle')}</h5>
            </div>
            <div className="modal-body">
              <fieldset>
                <p>{this.props.t('addTeamDescription')}</p>
                <div className="row">
                  <div className="col-12">
                    <label>{this.props.t('teamName')}</label>
                  </div>
                </div>
                <div className="row form-inline">
                  <div className="col-12">
                    <div className="form-group">
                      <span>{this.props.clubName}</span>
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Bijv. A1"
                        onChange={e => this.setName(e.target.value)}
                      />
                      <span>
                        {this.props.t('teamNamePreview', {
                          teamName: `${this.props.clubName} ${this.name.get()}`,
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary">
                {this.props.t('createTeam')}
              </button>
              <div className="btn btn-link" onClick={this.props.onCancel}>
                {this.props.t('common:cancel')}
              </div>
            </div>
          </form>
        );
      }
    }
  )
);

const PersonManagementNew = withTranslation('module.settings.club')(
  observer(
    class TeamManagementNew extends Component {
      constructor() {
        super();
        this.firstName = observable('');
        this.lastName = observable('');
      }

      setFirstName = action(name => {
        this.firstName.set(name);
      });

      setLastName = action(name => {
        this.lastName.set(name);
      });

      render() {
        return (
          <form
            onSubmit={e => {
              this.props.onAdd({
                firstName: this.firstName.get(),
                lastName: this.lastName.get(),
                type: this.props.personType,
              });
              e.preventDefault();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.props.t('addTeamTitle')}</h5>
            </div>
            <div className="modal-body">
              <fieldset>
                <p>{this.props.t('addTeamDescription')}</p>
                <div className="row">
                  <div className="col-12">
                    <label>{this.props.t('teamName')}</label>
                  </div>
                </div>
                <div className="row form-inline">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={this.props.t(
                          'module.settings.group:firstName'
                        )}
                        onChange={e => this.setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-inline">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control mb-2"
                        placeholder={this.props.t(
                          'module.settings.group:lastName'
                        )}
                        onChange={e => this.setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="modal-footer">
              <button className="btn btn-primary">
                {this.props.t('createTeam')}
              </button>
              <div className="btn btn-link" onClick={this.props.onCancel}>
                {this.props.t('common:cancel')}
              </div>
            </div>
          </form>
        );
      }
    }
  )
);

export { TeamManagementNew, PersonManagementNew };
