import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export const InfiniteScroll = ({ array, component, itemsPerPage = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);

  function onChange(isVisible) {
    if (isVisible && currentPage < Math.ceil(array.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  }

  return (
    <div className="infinite-scroll__wrapper">
      {[...array]
        .slice(0, itemsPerPage * currentPage)
        .map((ro) => component(ro))}
      {currentPage < Math.ceil(array.length / itemsPerPage) && (
        <VisibilitySensor onChange={onChange}>
          <div style={{ opacity: 0, position: 'relative', top: '-100px' }}>
            Load more...
          </div>
        </VisibilitySensor>
      )}
    </div>
  );
};
