import React, { Component } from 'react';
import omit from 'lodash/omit';
import Loading from './Loading';

interface CheckboxProps {
  /**
   * Whether the checkbox is checked, i.e truthy
   */
  status: 'empty' | 'done' | 'accepted' | 'warning' | 'rejected' | 'submitted';

  /**
   * Content to display as a label next to the checkbox
   */
  children: React.ReactNode;

  /**
   * Whether a loading incdicator should be displayed
   */
  loading?: boolean;

  /**
   * Function called with the changed checked state after user interaction
   */
  onIconClick: () => void;

  icon: React.FC;

  Buttons: React.FC;

  dataId?: string;
}

/**
 * Checkbox component, used to enable or disable boolean fields
 */
class LearningLineStatusIndicator extends Component<CheckboxProps> {
  constructor(props) {
    super(props);
  }

  // Uses label for styling, therefore use "active" instead of "checked"

  render() {
    return (
      <div
        className={`learningline-status-indicator status--${
          this.props.status
        } ${this.props.loading ? 'is-loading' : ''}
        ${this.props?.Buttons() ? 'hasButtons' : ''}`}
        data-test-id={this.props.dataId}
      >
        <div className="learning-indicator__top">
          <div className="learning-indicator__children">
            {this.props.children}
          </div>
          {this.props.icon && (
            <div
              className={`icon ${
                !this.props.loading && this.props.onIconClick ? 'clickable' : ''
              }`}
              onClick={() => this.props.onIconClick()}
            >
              {this.props.loading ? (
                <Loading type={'spinner'} size={16} color={'gray'} />
              ) : (
                this.props.icon
              )}
            </div>
          )}
        </div>
        {this.props.Buttons()}
      </div>
    );
  }
}

export { LearningLineStatusIndicator };
