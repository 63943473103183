import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import Authentication from '../utils/Authentication';

const PrivateRoute = observer(
  ({ component: Component, render, defaultProps, ...rest }) => {
    const getComponent = (props) => {
      if (Component) {
        return <Component {...defaultProps} {...props} />;
      } else {
        return render(props);
      }
    };

    return (
      <Route
        {...rest}
        render={(props) =>
          Authentication.isAuthenticated === true ? (
            getComponent(props)
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  }
);

export default PrivateRoute;
