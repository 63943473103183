import React from 'react';
// import { PositionReport } from 'lib/Reporting/Sports/Handball/Report';
import {
  PositionReport,
  ShotMetricType,
} from '../../../../lib/Reporting/Sports/Handball/Report';
import { EfficiencyLabel } from './efficiency-label';

const effiencyClassName = (percentage: number): string => {
  if (percentage > 0.55) {
    return 'success-high';
  } else if (percentage <= 0.55 && percentage > 0.4) {
    return 'warning';
  } else {
    return 'failure-mid';
  }
};

const MetricLabel: React.FC<{
  metrics: ShotMetricType;
  [x: string]: any;
}> = ({ metrics, ...props }) => (
  <EfficiencyLabel
    success={metrics.scored}
    total={metrics.total}
    className={effiencyClassName}
    {...props}
  />
);

export const HandballMatchField: React.FC<{
  positionReport: PositionReport;
  onClick: (cell: any) => void;
}> = ({ positionReport, onClick, ...props }) => {
  const pr = positionReport;

  return (
    <svg width="432px" height="344px" viewBox="0 0 432 344" {...props}>
      <g
        id="Live-Dashboard-(handbal)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Handbal-dashboard---overzicht"
          transform="translate(-552.000000, -424.000000)"
        >
          <g id="Group-12" transform="translate(552.000000, 424.000000)">
            <path
              d="M23.8813414,136 C70.548008,188.91177 134.548008,215.806379 215.881341,216.683828 C297.214675,217.561276 360.548008,190.666667 405.881341,136"
              id="Line-6"
              stroke="#DDDDDD"
              strokeWidth={5}
              strokeLinecap="square"
              fillRule="nonzero"
            />
            <MetricLabel
              tx={12}
              ty={80}
              metrics={pr.left.metrics}
              onClick={() => onClick(pr.left)}
            />
            <MetricLabel
              tx={70}
              ty={150}
              metrics={pr.left_center_6_7m.metrics}
              onClick={() => onClick(pr.left_center_6_7m)}
            />
            <MetricLabel
              tx={40}
              ty={195}
              metrics={pr.left_center_7_9m.metrics}
              onClick={() => onClick(pr.left_center_7_9m)}
            />
            <MetricLabel
              tx={25}
              ty={275}
              metrics={pr.left_center_gt9m.metrics}
              onClick={() => onClick(pr.left_center_gt9m)}
            />
            <MetricLabel
              tx={195}
              ty={182}
              metrics={pr.center_6_7m.metrics}
              onClick={() => onClick(pr.center_6_7m)}
            />
            <MetricLabel
              tx={195}
              ty={235}
              metrics={pr.center_7_9m.metrics}
              onClick={() => onClick(pr.center_7_9m)}
            />
            <MetricLabel
              tx={195}
              ty={300}
              metrics={pr.center_gt9m.metrics}
              onClick={() => onClick(pr.center_gt9m)}
            />
            <MetricLabel
              tx={320}
              ty={150}
              metrics={pr.right_center_6_7m.metrics}
              onClick={() => onClick(pr.right_center_6_7m)}
            />
            <MetricLabel
              tx={350}
              ty={195}
              metrics={pr.right_center_7_9m.metrics}
              onClick={() => onClick(pr.right_center_7_9m)}
            />
            <MetricLabel
              tx={365}
              ty={275}
              metrics={pr.right_center_gt9m.metrics}
              onClick={() => onClick(pr.right_center_gt9m)}
            />
            <MetricLabel
              tx={378}
              ty={80}
              metrics={pr.right.metrics}
              onClick={() => onClick(pr.right)}
            />
            <polygon
              id="Rectangle"
              fill="#C3C3C3"
              transform="translate(215.381341, 124.000000) rotate(-90.000000) translate(-215.381341, -124.000000) "
              points="213.381341 117.5 217.381341 117.5 217.381341 130.5 213.381341 130.5"
            />
            <path
              d="M68.8813414,97 L4.38134136,151.5"
              id="Line-3"
              stroke="#DDDDDD"
              strokeWidth={5}
              opacity="0.747000558"
              strokeLinecap="square"
              fillRule="nonzero"
            />
            <path
              d="M428.881341,97 L358.881341,153"
              id="Line-3"
              stroke="#DDDDDD"
              strokeWidth={5}
              opacity="0.747000558"
              strokeLinecap="square"
              fillRule="nonzero"
              transform="translate(393.381341, 125.500000) scale(-1, 1) translate(-393.381341, -125.500000) "
            />
            <path
              d="M153.822012,158 L74.3813414,360.5"
              id="Line-3"
              stroke="#DDDDDD"
              strokeWidth={5}
              opacity="0.747000558"
              strokeLinecap="square"
              fillRule="nonzero"
            />
            <path
              d="M357.881341,156.5 L277.822012,360.5"
              id="Line-3-Copy"
              stroke="#DDDDDD"
              strokeWidth={5}
              opacity="0.747000558"
              strokeLinecap="square"
              fillRule="nonzero"
              transform="translate(317.881341, 258.500000) scale(-1, 1) translate(-317.881341, -258.500000) "
            />
            <path
              d="M3.88134136,201 C56.2146747,245.530239 126.714675,267.795359 215.381341,267.795359 C304.048008,267.795359 374.548008,245.530239 426.881341,201"
              id="Line-6"
              stroke="#C7C7C7"
              strokeWidth={5}
              strokeLinecap="square"
              strokeDasharray={22}
              fillRule="nonzero"
            />
            <path
              d="M426.335887,344 L426.335887,58.7574257 L426.335887,49.3069307 L426.335887,28.3058306 L380.999523,28.3058306 C379.717705,104.092409 312.935887,165.269527 231.117705,165.269527 L195.66316,165.269527 C113.799523,165.269527 47.0631595,104.092409 45.7813414,28.3058306 L5.4267959,28.3058306 L5.4267959,49.3069307 C5.4267959,51.7418409 5.4267959,54.873744 5.4267959,58.7026403 L5.4267959,344 L0.881341359,344 L0.881341359,23.740374 L128.382809,23.740374 L176.335887,23.740374 L176.335887,-1.36424205e-12 L254.517705,-1.36424205e-12 L254.517705,23.740374 L430.881341,23.740374 L430.881341,344 L426.335887,344 Z M376.626796,28.3058306 L50.1540686,28.3058306 C51.4358868,101.353135 116.208614,160.70407 195.66316,160.70407 L231.117705,160.70407 C310.56316,160.70407 375.344978,101.353135 376.626796,28.3058306 Z M250.881341,4.56545655 L179.97225,4.56545655 L179.97225,23.740374 L250.881341,23.740374 L250.881341,4.56545655 Z"
              id="Combined-Shape"
              fill="#CACACA"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
