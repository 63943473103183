import React, { Component, Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { observer, Observer } from 'mobx-react';
import { action, observable } from 'mobx';
import omit from 'lodash/omit';
import moment from 'moment';

import { Page } from 'lib/Page';
import { CountrySelect } from 'lib/CountrySelect';

import { Notification } from 'lib/Notification';

import { withTranslation } from 'react-i18next';
import { gotoRoute } from 'modules/route';
import { FileUploader, UploadState } from 'infra/Uploader';

const UploaderComponent = ({ uploadable }) => {
  const [fileUploader, setFileUploader] = useState(null);
  useEffect(() => {
    const fileUploader = new FileUploader(
      () => {},
      () => {
        uploadable.fetch();
      },
      new UploadState()
    );
    setFileUploader(fileUploader);
  }, [uploadable]);
  if (fileUploader === null) {
    return null;
  }
  return (
    <Observer>
      {() => {
        if (fileUploader.uploadState.state === 'queueing') {
          return (
            <>
              <input
                type="file"
                onChange={e =>
                  e.target.files.length > 0 &&
                  fileUploader.setFile(e.target.files[0])
                }
              />
              <button
                onClick={() => fileUploader.start(uploadable)}
                disabled={fileUploader.uploadState.files.length === 0}
              >
                Upload!
              </button>
            </>
          );
        } else {
          return (
            <>{`Uploading: ${(
              (fileUploader.uploadState.bytesUploaded /
                fileUploader.uploadState.bytesTotal) *
              100
            ).toFixed(0)}% @ ${(fileUploader.uploadState.speed / 1024).toFixed(
              1
            )}KB/s`}</>
          );
        }
      }}
    </Observer>
  );
};

const EditExercisePage = withTranslation('module.exercise')(props => {
  const { t, exercise, exerciseTemplateCollection } = props;

  const onCancel = () => {
    gotoRoute('exercise.overview');
  };

  const onDelete = async () => {
    const shouldDelete = window.confirm(
      `Weet je zeker dat je '${exercise.title}' wilt verwijderen?`
    );
    if (shouldDelete) {
      let video = null;
      if (exercise.hasVideo()) {
        video = exercise.getVideo();
      }
      await exercise.destroy({ optimistic: false });
      if (video !== null) {
        await video.destroy({ optimistic: false });
      }

      Notification.show('Oefening verwijderd', 2500);
      gotoRoute('exercise.overview');
    }
  };

  const onDeleteVideo = async e => {
    e.preventDefault();
    const shouldDelete = window.confirm(
      `Weet je zeker dat je de video van '${exercise.title}' wilt verwijderen?`
    );
    if (shouldDelete) {
      if (exercise.hasVideo()) {
        await exercise.deleteVideo();
      }

      Notification.show('Video verwijderd', 2500);
    }
  };

  const saveExercise = async (values, formikBag) => {
    let exerciseTemplateId = 'exercise-template-sv';
    switch ((values.category || '').split(' / ')[0]) {
      case 'Vaardigheden':
      case 'Warming-up':
        exerciseTemplateId = 'exercise-template-sv-skill';
        break;
      default:
        exerciseTemplateId = 'exercise-template-sv';
        break;
    }

    await exercise.save(
      {
        title: values.title,
        content: values.content,
        tags: {
          category: values.category,
        },
        exerciseTemplateId,
      },
      { optimistic: false }
    );
    // if (values.title !== exercise.title) {
    //   console.log(values.title, exercise.title);
    //   await exercise.setTitle(values.title, false);
    // }
    // await exercise.setVariables(values.content);
    formikBag.setSubmitting(false);
    Notification.show('Oefening opgeslagen', 2500);
    gotoRoute('exercise.overview');
  };

  const validate = values => {
    let errors = {};
    if (!values.title || values.title === '') {
      errors.title = true;
    }
    if (!values.category || values.category === '') {
      errors.category = true;
    }
    // for (const path of Object.keys(exercise.content)) {
    //   if (!values.content[path] || values.content[path] === '') {
    //     errors[`content['${path}']`] = true;
    //   }
    // }
    return errors;
  };

  const initialValues = {
    title: exercise.title,
    category: exercise.tags.category,
    content: exercise.content,
  };

  const pageProps = {
    title: 'Oefeningen',
    breadcrumbs: [
      { path: '/', title: 'Home' },
      { path: '/exercise', title: 'Overzicht' },
      { title: exercise.title },
    ],
  };

  return (
    <Page {...pageProps}>
      <Formik
        initialValues={initialValues}
        onSubmit={saveExercise}
        validate={validate}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          submitCount,
          errors,
          setFieldValue,
          onReset,
        }) => {
          // TODO: move to up in the dependency tree
          const validClassName = key => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };

          let exerciseTemplateId = 'exercise-template-sv';
          switch ((values.category || '').split(' / ')[0]) {
            case 'Vaardigheden':
            case 'Warming-up':
              exerciseTemplateId = 'exercise-template-sv-skill';
              break;
            default:
              exerciseTemplateId = 'exercise-template-sv';
              break;
          }

          const exerciseTemplate = exerciseTemplateCollection.get(
            exerciseTemplateId
          );

          return (
            <form onSubmit={handleSubmit}>
              {/* <div className="modal-header">
              <h5 className="modal-title">{t('title')}</h5>
            </div> */}
              <div className="row">
                <div className="col-12">
                  <div className={`form-group ${validClassName('title')}`}>
                    <label htmlFor="title">Titel</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={values.title}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={`form-group ${validClassName('category')}`}>
                    <label htmlFor="title">Categorie</label>
                    <select
                      type="text"
                      className="form-control"
                      name="category"
                      value={values.category}
                      autoComplete="off"
                      onChange={handleChange}
                    >
                      <option>-- kies categorie --</option>
                      <optgroup label="Aanvallen">
                        <option value="Aanvallen / Schieten">Schieten</option>
                        <option value="Aanvallen / Passing">Passing</option>
                        <option value="Aanvallen / Vrijlopen">Vrijlopen</option>
                        <option value="Aanvallen / In balbezit blijven">
                          In balbezit blijven
                        </option>
                      </optgroup>
                      <optgroup label="Verdedigen">
                        <option value="Verdedigen / Voorkomen van schieten">
                          Voorkomen van schieten
                        </option>
                        <option value="Verdedigen / Voorkomen van passing">
                          Voorkomen van passing
                        </option>
                        <option value="Verdedigen / Voorkomen van vrijlopen">
                          Voorkomen van vrijlopen
                        </option>
                        <option value="Verdedigen / In balbezit komen">
                          In balbezit komen
                        </option>
                      </optgroup>
                      <optgroup label="Vaardigheden">
                        <option value="Vaardigheden / Doorloopballen">
                          Doorloopballen
                        </option>
                        <option value="Vaardigheden / Schot vaardigheden">
                          Schotvaardigheden
                        </option>
                        <option value="Vaardigheden / Schotspelletjes">
                          Schotspelletjes
                        </option>
                      </optgroup>
                      <optgroup label="Warming-up">
                        <option value="Warming-up / E-F-jeugd / Gooien en vangen">
                          E-F-jeugd / Gooien en vangen
                        </option>
                        <option value="Warming-up / E-F-jeugd / Balvaardigheid">
                          E-F-jeugd / Balvaardigheid
                        </option>
                        <option value="Warming-up / E-F-jeugd / Veelzijdig bewegen">
                          E-F-jeugd / Veelzijdig bewegen
                        </option>
                        <option value="Warming-up / C-D-jeugd / Gooien en vangen">
                          C-D-jeugd / Gooien en vangen
                        </option>
                        <option value="Warming-up / C-D-jeugd / Sprintvormpjes">
                          C-D-jeugd / Sprintvormpjes
                        </option>
                        <option value="Warming-up / C-D-jeugd / Veelzijdig bewegen">
                          C-D-jeugd / Veelzijdig bewegen
                        </option>
                        <option value="Warming-up / A-B-jeugd + senioren / Sprintvormpjes">
                          A-B-jeugd + senioren / Sprintvormpjes
                        </option>
                        <option value="Warming-up / A-B-jeugd + senioren / Krachtvormpjes">
                          A-B-jeugd + senioren / Krachtvormpjes
                        </option>
                        <option value="Warming-up / A-B-jeugd + senioren / Veelzijdig bewegen">
                          A-B-jeugd + senioren / Veelzijdig bewegen
                        </option>
                      </optgroup>
                    </select>
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>
              </div>
              {exerciseTemplate.contentBlocks.map((contentBlock, idx) => {
                switch (contentBlock.type) {
                  case 'title-description':
                    return (
                      <TitleDescriptionFormFields
                        key={idx}
                        validClassName={validClassName}
                        title={contentBlock.title}
                        description={contentBlock.description}
                        content={values.content}
                        idx={idx}
                        onChange={handleChange}
                      />
                    );
                  case 'tabs':
                    return (
                      <TabsFormFields
                        validClassName={validClassName}
                        onChange={handleChange}
                        content={values.content}
                        key={idx}
                        tabs={contentBlock.tabs}
                        contentBlockIdx={idx}
                      />
                    );
                  default:
                    throw new Error(
                      `Unknown content block type: "${contentBlock.type}"`
                    );
                }
              })}

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    Video:{' '}
                    <Observer>
                      {() => {
                        if (exercise.hasVideo() && !!exercise.getVideo()) {
                          return (
                            <>
                              Ja: {exercise.getVideo().parts[0].filename}-{' '}
                              <a href="#" onClick={onDeleteVideo}>
                                verwijderen
                              </a>
                            </>
                          );
                        } else {
                          return <UploaderComponent uploadable={exercise} />;
                        }
                      }}
                    </Observer>
                  </div>
                </div>
              </div>
              <div className="form-actions">
                <button type="submit" className="btn btn-primary">
                  {t('common:save')}
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onCancel}
                >
                  {t('common:cancel')}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={onDelete}
                >
                  {t('common:delete')}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Page>
  );
});

const Variable = ({
  content,
  variable,
  validClassName,
  onChange,
  t,
  isText = true,
}) => {
  if (variable.type === 'CONSTANT') {
    return <div className="form-group">{variable.value}</div>;
  } else {
    const contentKey = `content['${variable.path}']`;
    if (isText) {
      return (
        <div className={`form-group ${validClassName(contentKey)}`}>
          <textarea
            className="form-control"
            name={contentKey}
            value={content[variable.path]}
            onChange={onChange}
            rows="4"
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      );
    }
  }
};

const TitleDescriptionFormFields = withTranslation('module.exercise')(
  ({ content, validClassName, title, description, idx, onChange, t }) => {
    return (
      <div className="row" key={`content-block-${idx}`}>
        <div className="col-12">
          <Variable
            content={content}
            variable={title}
            t={t}
            validClassName={validClassName}
            onChange={onChange}
          />
        </div>
        <div className="col-12">
          <Variable
            content={content}
            variable={description}
            t={t}
            validClassName={validClassName}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
);

const TabsFormFields = withTranslation('module.exercise')(
  ({ content, validClassName, tabs, onChange, t, contentBlockIdx }) => {
    return tabs.map(({ title, content: tabContent }, tabIdx) => {
      return (
        <div
          key={`content-block-${contentBlockIdx}-tab-${tabIdx}`}
          className="row"
        >
          <div className="col-12">
            <Variable
              content={content}
              variable={title}
              t={t}
              validClassName={validClassName}
              onChange={onChange}
            />
          </div>
          <div className="col-12">
            <Variable
              content={content}
              variable={tabContent}
              t={t}
              validClassName={validClassName}
              onChange={onChange}
            />
          </div>
        </div>
      );
    });
  }
);

export { EditExercisePage };
