import React, { useEffect, useState } from 'react';
import { Session } from 'domain/Session';

import { Page } from 'lib/Page';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const ChooseApp = withTranslation('module.apps.choose-app')(({ t }) => {
  const currentSession = Session.current();
  const appManager = currentSession.getAppManager();

  const pageProps = {
    // breadcrumbs: [
    //   { path: '#', onClick: () => alert('hoi'), title: 'Menu item 1' },
    //   { onClick: () => alert('hoi2'), title: 'Menu item 2' },
    // ],
    hideInset: true,
    hideFooter: true,
  };

  const availableApps = appManager.getListedApps();

  return (
    <Page {...pageProps}>
      <div className="choose-app">
        <div className="row">
          <div className="col-12">
            <div className="page-header">
              <h1>{t('title')}</h1>
            </div>
          </div>
        </div>
        <div className={`row app-count-${availableApps.length}`}>
          {availableApps.map(app => {
            return (
              <div className="col-12 col-md-4" key={app.appId}>
                <NavLink to={appManager.getAppPath(app)}>
                  <div className="thumbnail">
                    <img src={app.imgSrc} />
                    {/*<div className="caption">*/}
                    {/*<h3>{app.name}</h3>*/}
                    {/*</div>*/}
                  </div>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
});

const ChooseAppSmall = withTranslation('module.apps.choose-app')(({ t }) => {
  const currentSession = Session.current();
  const appManager = currentSession.getAppManager();
  const availableApps = appManager.getListedApps();
  return (
    <div className={'user-apps row'}>
      {availableApps.map(app => {
        return (
          <div className="app-button col-6 col-md-4" key={app.appId}>
            <NavLink to={appManager.getAppPath(app)}>
              <img src={app.imgSrc} />
            </NavLink>
          </div>
        );
      })}
    </div>
  );
});

export { ChooseApp, ChooseAppSmall };
