import { Model } from 'mobx-rest';

import { PersistentCollection } from '../PersistentCollection';

// import soccerAttack from './learning-lines/soccer-footballvision-prehighperf-attack.json';
// import soccerDefence from './learning-lines/soccer-footballvision-prehighperf-defence.json';

class VideoFragment extends Model {
  get primaryKey() {
    return 'videoFragmentId';
  }

  get indicatorId() {
    return this.has('indicatorId') && this.get('indicatorId');
  }

  get description() {
    return this.has('description') ? this.get('description') : '';
  }

  get created() {
    return this.get('created');
  }

  get videoId() {
    return this.get('videoId');
  }

  get rating() {
    return (this.has('rating') && this.get('rating')) || 'unknown';
  }

  get comment() {
    return this.has('comment') && this.get('comment');
  }

  get metadata() {
    return this.get('metadata');
  }

  get videoFragmentUrl() {
    return this.get('videoFragmentUrl');
  }
}

class VideoFragmentCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return VideoFragment;
  }
}

export class LearningLine extends Model {
  constructor(attributes) {
    super(attributes);

    this.videoFragmentCollection = null;
  }

  get videoFragments() {
    if (this.videoFragmentCollection === null) {
      this.videoFragmentCollection = new VideoFragmentCollection(
        `${this.url()}/videoFragments`
      );
    }
    return this.videoFragmentCollection;
  }

  get primaryKey() {
    return 'learningLineId';
  }

  get name() {
    return this.get('name');
  }

  get phases() {
    return this.get('phases');
  }

  get learningLineId() {
    return this.get('learningLineId');
  }

  get indicatorIds() {
    const ids = [];
    for (const phase of this.phases) {
      ids.push(...phase.skills.map(skill => skill.skillId));
      ids.push(...phase.practices.map(practice => practice.practiceId));
    }
    return ids;
  }

  indicatorsOfPhase(phaseId) {
    const indicators = [];
    for (const phase of this.phases) {
      if (phase.phaseId === phaseId) {
        for (const skill of phase.skills) {
          indicators.push({
            learningLine: this,
            phase,
            type: 'skill',
            indicator: Object.assign(skill, { indicatorId: skill.skillId }),
          });
        }
        for (const practice of phase.practices) {
          indicators.push({
            learningLine: this,
            phase,
            type: 'practice',
            indicator: Object.assign(practice, {
              indicatorId: practice.practiceId,
            }),
          });
        }
        break;
      }
    }
    return indicators;
  }

  indicators() {
    const _indicators = [];
    for (const phase of this.phases) {
      for (const skill of phase.skills) {
        _indicators.push({
          learningLine: this,
          phase,
          type: 'skill',
          indicator: Object.assign(skill, { indicatorId: skill.skillId }),
        });
      }
      for (const practice of phase.practices) {
        _indicators.push({
          learningLine: this,
          phase,
          type: 'practice',
          indicator: Object.assign(practice, {
            indicatorId: practice.practiceId,
          }),
        });
      }
    }
    return _indicators;
  }

  indicatorOfIndicatorId(indicatorId) {
    for (const phase of this.phases) {
      for (const skill of phase.skills) {
        if (skill.skillId === indicatorId) {
          return {
            learningLine: this,
            phase,
            type: 'skill',
            indicator: Object.assign(skill, { indicatorId }),
          };
        }
      }
      for (const practice of phase.practices) {
        if (practice.practiceId === indicatorId) {
          return {
            learningLine: this,
            phase,
            type: 'practice',
            indicator: Object.assign(practice, { indicatorId }),
          };
        }
      }
    }
    return null;
  }

  hasPrivilege(privilege) {
    const privileges = this.get('_metadata').privileges;
    return privileges.indexOf(`LearningLine:${privilege}`) !== -1;
  }

  getVideoFragments(indicatorId) {
    return this.videoFragments
      .toArray()
      .filter(videoFragment => videoFragment.indicatorId === indicatorId);
  }
}

class LearningLineCollection extends PersistentCollection {
  url() {
    return '/learningLines';
  }

  model() {
    return LearningLine;
  }
}

const learningLineCollection = new LearningLineCollection();
window.learningLineCollection = learningLineCollection;

export default learningLineCollection;
