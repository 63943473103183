import React, { createContext, useContext, useState, useRef } from 'react';

interface GenericSelector<Type> {
  (value?: Type): boolean;
}

type ObservationSelector = GenericSelector<Observation>;

const selectorAll: ObservationSelector = () => true;

export interface ObservationsEditCollection {
  observations: Observation[];
  selectedObservations: () => Observation[];
  current: Observation | null;
  initial: Observation | null;
  setCurrent: (observation: Observation | null) => void;
  currentIdx: () => number;
  getPrev: () => Observation | undefined;
  getNext: () => Observation | undefined;
  setSelector: (selector: ObservationSelector) => void;
  startEdit: ({ id }: { id: string }) => void;
}

export const useObservationsEditCollection = ({
  observations: obs,
  clock,
}: {
  observations: Observation[];
  clock?: any;
}): ObservationsEditCollection => {
  // WARN: assumes observations are provided in order
  // NOTE: observations are (re)created by mobx every time the observations in
  // the observationLogger are updated

  const observations = useRef<Observation[]>([]);
  observations.current = obs;

  const [initial, setInitial] = useState<Observation | null>(null);
  const [current, setCurrent] = useState<Observation | null>(null);
  const selector = useRef<ObservationSelector>(selectorAll);

  const forId = (id: string) =>
    observations.current.find(o => o.observationId === id);
  const selected = () =>
    observations.current.filter(selector.current) as Observation[];

  // current index within selected observations
  const currentIdx = () =>
    selected().findIndex(o => o.observationId === current?.observationId);

  const getPrev = () => {
    const idx = currentIdx();
    return idx ? selected()[idx - 1] : undefined;
  };

  const getNext = () => {
    const idx = currentIdx();
    return idx !== undefined && idx !== selected().length - 1
      ? selected()[idx + 1]
      : undefined;
  };

  const handleSetCurrent: ObservationsEditCollection['setCurrent'] = maybeObservation => {
    if (clock && clock._clockId !== 'L1') {
      maybeObservation ? clock.pause() : clock.play();
    }

    setCurrent(maybeObservation);
  };

  const setSelector = (sel: ObservationSelector = selectorAll) => {
    selector.current = sel;
  };

  const startEdit = ({ id }: { id: string }) => {
    // timeout needed so the observations are up-to-date
    window.setTimeout(() => {
      const observation = forId(id) || null;

      setInitial(observation);
      handleSetCurrent(observation);
    }, 1);
  };

  return {
    observations: observations.current,
    current,
    initial,
    setCurrent: handleSetCurrent,
    currentIdx,
    getPrev,
    getNext,
    selectedObservations: selected,
    setSelector,
    startEdit,
  };
};

export const ObservationsEditCollectionContext = createContext<
  ObservationsEditCollection
>({
  observations: [],
  selectedObservations: () => [],
  current: null,
  initial: null,
  setCurrent: () => {},
  setSelector: () => {},
  currentIdx: () => 0,
  getPrev: () => undefined,
  getNext: () => undefined,
  startEdit: () => {},
});
ObservationsEditCollectionContext.displayName =
  'ObservationsEditCollectionContext';

// provider component to manage state in class components
export const ObservationsEditCollectionContextProvider = ({
  observations,
  clock,
  children,
}: {
  observations: Observation[];
  clock?: VideoClock;
  children: React.ReactNode;
}) => {
  const value = useObservationsEditCollection({ observations, clock });

  return (
    <ObservationsEditCollectionContext.Provider value={value}>
      {children}
    </ObservationsEditCollectionContext.Provider>
  );
};

export const useObservationsEditCollectionContext = () =>
  useContext(ObservationsEditCollectionContext);
