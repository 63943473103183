import {
  LocalCommandHandler,
  RemoteCommandHandler,
} from 'infra/Messaging/CommandHandler';

import PersonCollection from './Person';
import { CommandBus } from '../../infra/Messaging/CommandBus';
import Command from '../../infra/Messaging/Command';

class PersonServiceCommandHandler extends LocalCommandHandler {
  constructor() {
    super();

    this.personRepository = PersonCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'PersonService.CreateAndAddToTeam':
        /*
                 TODO: if ordering matters: use saga
                 * change this to use saga? Saga PK = personId and react to "Person.Created" event
                 * OR: use service!
                 */

        const personId = this.personRepository.nextIdentity();
        const nextCommands = [
          Command.create('Person.Create', {
            personId,
            firstName: command.firstName,
            lastName: command.lastName,
            gender: command.gender,
          }),
          Command.create('Team.AddPlayer', {
            teamId: command.teamId,
            personId,
            number: command.number,
          }),
        ];
        CommandBus.dispatch(nextCommands);
        break;
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['PersonService.*'];
  }
}

class LocalPersonCommandHandler extends LocalCommandHandler {
  constructor() {
    super();

    this.personRepository = PersonCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'Person.Create':
        this.personRepository.addPerson(
          command.personId,
          command.firstName,
          command.lastName,
          command.gender
        );
        break;
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['Person.*'];
  }
}

class RemotePersonCommandHandler extends RemoteCommandHandler {
  constructor() {
    super();

    this.personRepository = PersonCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'Person.Create':
        const model = this.personRepository.build({
          personId: command.personId,
        });

        this.addUnitOfWork(command, [
          this.createAddWithIdRequest(model, {
            firstName: command.firstName,
            lastName: command.lastName,
            gender: command.gender,
          }),
        ]);
        break;
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['Person.Create'];
  }
}

export default () => {
  new PersonServiceCommandHandler();
  new LocalPersonCommandHandler();
  new RemotePersonCommandHandler();
};
