import React, { useEffect, useRef, useState } from 'react';
import './viewfilters.scss';
import { dragToScroll } from '../../DragToScroll';
import { LockedContent } from '../../LicenseLimitEnforcing';

export const ViewFilters = ({ filters }) => {
  return (
    <div className="view-filters">
      {filters.map((filter, index) => {
        switch (filter.type) {
          case 'search': {
            return (
              <Search
                key={`viewFilter-${index}`}
                placeholder={filter.placeholder}
                onSubmit={(v) => {
                  if (typeof filter.onSubmit !== 'undefined') {
                    filter.onSubmit(v);
                  }
                }}
                onChange={(v) => {
                  if (typeof filter.onChange !== 'undefined') {
                    filter.onChange(v);
                  }
                }}
              />
            );
          }
          case 'buttonGroup': {
            return (
              <ButtonGroup
                key={`viewFilter-${index}`}
                buttons={filter.buttons}
              />
            );
          }
        }
      })}
    </div>
  );
};

const PrimaryAction = ({ primaryAction }) => {
  return (
    <div className="page-header-cta">
      <LockedContent size={'large'} reasons={primaryAction.locked}>
        {({ isLocked, onClickHandler }) => (
          <button
            type="button"
            className="btn btn-primary"
            disabled={primaryAction.disabled}
            onClick={isLocked ? onClickHandler : primaryAction.onClick}
          >
            {primaryAction.title}
          </button>
        )}
      </LockedContent>
    </div>
  );
};

export const ViewActionsAndFilters = ({ primaryAction, filters }) => {
  return (
    <div className="viewfilter-wrapper">
      {primaryAction &&
        Array.isArray(primaryAction) &&
        primaryAction.map((primaryAction, i) => {
          return <PrimaryAction key={i} primaryAction={primaryAction} />;
        })}
      {primaryAction && !Array.isArray(primaryAction) && (
        <PrimaryAction primaryAction={primaryAction} />
      )}
      {filters && <ViewFilters filters={filters} />}
    </div>
  );
};

const Search = ({ placeholder, onSubmit, onChange }) => {
  const [value, setValue] = useState('');
  return (
    <div className="searchbox">
      <div className="searchbox-wrapper">
        <input
          value={value}
          placeholder={placeholder}
          onChange={(v) => {
            setValue(v.target.value);
            onChange(v.target.value);
          }}
          type="text"
        />
        <button onClick={() => onSubmit(value)}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="#3E3E3E"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.76087 2.21739C4.69929 2.21739 2.21739 4.69929 2.21739 7.76087C2.21739 10.8224 4.69929 13.3043 7.76087 13.3043C10.8224 13.3043 13.3043 10.8224 13.3043 7.76087C13.3043 4.69929 10.8224 2.21739 7.76087 2.21739ZM0 7.76087C0 3.47466 3.47466 0 7.76087 0C12.0471 0 15.5217 3.47466 15.5217 7.76087C15.5217 12.0471 12.0471 15.5217 7.76087 15.5217C3.47466 15.5217 0 12.0471 0 7.76087Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.414 11.413C11.8489 10.9782 12.5539 10.9782 12.9887 11.413L16.6748 15.0991C17.1096 15.5339 17.1096 16.239 16.6748 16.6738C16.24 17.1087 15.5349 17.1087 15.1001 16.6738L11.414 12.9878C10.9792 12.5529 10.9792 11.8479 11.414 11.413Z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const ButtonGroup = ({ buttons }) => {
  const dragToScrollRef = useRef();
  useEffect(() => {
    if (dragToScrollRef.current) {
      dragToScroll(dragToScrollRef.current);
    }
  }, [dragToScrollRef.current]);

  return (
    <div ref={dragToScrollRef} className="buttongroup">
      <div className="buttongroup__scrollwrapper">
        {buttons.map((button, idx) => {
          switch (button.type) {
            case 'checkBox':
              return <_Check button={button} key={`btn-${idx}`} />;
              break;
            default:
              return <_Button button={button} key={`btn-${idx}`} />;
          }
        })}
      </div>
    </div>
  );
};

const _Button = ({ button }) => {
  return (
    <button
      data-id={button?.dataId}
      onClick={() => button.onClick()}
      className={`btn ${button.isActive ? 'active' : ''}`}
    >
      {button.label}
    </button>
  );
};

const _Check = ({ button }) => {
  return (
    <div
      data-id={button?.dataId}
      className={`checkBox ${button.isActive ? 'active' : ''}`}
      onClick={() => button.onClick()}
    >
      {button.label}
    </div>
  );
};
