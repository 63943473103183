import React, { Component } from 'react';

import radioActive from './radio-active.svg';
import radioInactive from './radio-inactive.svg';

import styles from './index.module.scss';

class ButtonGroupSelect extends Component {
  static defaultProps = {
    options: [], //  [ { value: 1, label: 'one' }, ... ]
    onChange: () => {},
    value: null,
    radio: false, // TODO: refactor to separate RadioSelect component
  };

  constructor(props) {
    super(props);

    this.state = { selectedValue: props.value };
  }

  select = (value) => {
    if (!this.props.radio && this.state.selectedValue === value) {
      value = null; // deselect
    }
    this.setState({ selectedValue: value });
    this.props.onChange(value);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ selectedValue: this.props.value });
    }
  }

  render() {
    const { options } = this.props;
    const { selectedValue } = this.state;

    const buttons = options.map((option, idx) => {
      const isActive = option.value === selectedValue;
      const activeStyleClass = isActive ? 'active' : '';

      return (
        <button
          key={option.value}
          type="button"
          className={`btn ${activeStyleClass} ${option.className || ''}`}
          onClick={() => this.select(option.value)}
          disabled={option.disabled}
        >
          {this.props.radio && (
            <img
              src={isActive ? radioActive : radioInactive}
              alt="radio active"
              className={styles.radio}
            />
          )}
          <span>{option.label}</span>
        </button>
      );
    });

    return (
      <div
        className={`btn-group btn-group-select ${this.props.className || ''}`}
      >
        {buttons}
      </div>
    );
  }
}

export { ButtonGroupSelect };
