import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';
import { groupBy, toPairs } from 'lodash';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// import TeamCollection from '../../../domain/Team';
import { withTranslation } from 'react-i18next';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';

const GrowthAmbitionCreateConfirm = withTranslation(
  'module.development.growthAmbition.create.confirm'
)(
  observer(({ t, onSubmit, onCancel, draftGrowthAmbition }) => {
    const indicators = draftGrowthAmbition.indicatorIds.map(
      LearningLineQueryService.indicatorOfIndicatorId
    );
    const indicatorsByLearningLine = toPairs(
      groupBy(indicators, indicator => indicator.learningLine.id)
    );
    const colsPerIndicator = 4;
    const learningLineClasses = totalIndicators =>
      `col-12 col-md-${totalIndicators * colsPerIndicator}`;

    return (
      <div className="row growth-ambition-create-confirm">
        <div className="col-12">
          <p>{t('description')}</p>
        </div>
        <div className="col-12 col-md-9 selected-indicators">
          {indicators.length === 0 && (
            <div className="row">
              <div className="col-12">Kies hieronder je ontwikkelpunten</div>
            </div>
          )}
          {indicators.length > 0 && (
            <Fragment>
              <div className="row">
                {indicatorsByLearningLine.map(
                  ([learningLineId, indicators]) => (
                    <div
                      key={learningLineId}
                      className={learningLineClasses(indicators.length)}
                    >
                      <h3>{indicators[0].learningLine.name}</h3>
                    </div>
                  )
                )}
              </div>
              <div className="row">
                {indicatorsByLearningLine.map(([learningLineId, indicators]) =>
                  indicators.map(indicator => (
                    <div
                      key={indicator.indicator.indicatorId}
                      className={`col-12 col-md-${colsPerIndicator}`}
                    >
                      <div className="label active">
                        {indicator.indicator.name}
                        <i
                          className="i-cross i-light"
                          onClick={() =>
                            draftGrowthAmbition.removeGoal(
                              indicator.indicator.indicatorId
                            )
                          }
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </Fragment>
          )}
        </div>
        <div className="col-12 col-md-3 form-actions">
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={onSubmit}
            disabled={draftGrowthAmbition.indicatorIds.length === 0}
          >
            {t('submit')}
          </button>
          <button type="button" className="btn btn-link" onClick={onCancel}>
            {t('common:cancel')}
          </button>
        </div>
      </div>
    );
  })
);

export { GrowthAmbitionCreateConfirm };
