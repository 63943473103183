import React, { useState, useEffect, useRef } from 'react';

import { useMinsWithoutSecs, useRemainderSecs } from './utils/use-mins-secs';
import { getSportingEvent, SelectAspectRatio } from './highlightsDetail';
import { useTranslation } from 'react-i18next';
import videoCollection from '../../domain/Video';
import SportingEventCollection from '../../domain/SportingEvent';
import { InputCheckboxRadio } from '../../lib/InputCheckBoxRadio';
import { Player } from '@remotion/player';
import { HighlightsComposition } from '../../remotion/Highlights/HighlightsComposition';
import { preprocessContent } from '../../remotion/helpers/preprocess-content';
import { FocusPointSelector } from '../../remotion/helpers/focusPoint';
import { calculateAspectRatioToBounds } from '../../remotion/helpers/aspect-ratio';
import uuidv4 from 'uuid/v4';

import ImagePicker from 'lib/ImagePicker/ImagePicker';
import { CachedAsyncImage, generateClipThumbnail } from './utils/clipThumbnail';
import { TimeSlider } from 'lib/TimeSlider';

enum ViewStates {
  Init = 'INIT',
  Create = 'CREATE',
  Success = 'SUCCESS',
  Fail = 'FAIL',
}

const stopMainVideoPlayer = () => {
  // Pause main video player when editing clips.
  // const player = (window as any)?.jwplayer('playerId1');
  // if (player && player.getState() === 'playing') {
  //   player.pause();
  // }
  // return;
};

export const ClipEditView: React.FC<{
  highlightVideo: HighlightVideo;
  onSuccess(any): void;
  onClose(any): void;
  onSave: (clips: Clip[]) => Promise<void>;
  contentId: string;
  action: string;
  template: any[];
  videos: any[];
}> = ({
  onSuccess,
  onClose,
  onSave,
  highlightVideo,
  contentId,
  action,
  template,
  videos,
}) => {
  // Pause main video player when editing clips.
  stopMainVideoPlayer();

  const clipSelection = highlightVideo.content;
  const clipIdx = clipSelection.findIndex(
    (content) => content.contentId === contentId
  );
  const previousClip = clipIdx > 0 ? clipSelection[clipIdx - 1] : null;
  const clip = clipSelection[clipIdx];
  if (clip === undefined) {
    throw new Error('Could not find clip.');
  }

  const [view, setView] = useState('');
  const [clipStart, setClipStart] = useState(Math.floor(clip.start));
  const [clipEnd, setClipEnd] = useState(Math.floor(clip.end));
  const [videoUri, setVideoUri] = useState(clip.videoUri);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sportingEvent, setSportingEvent] = useState();
  const [angles, setAngles] = useState<any[]>([]);
  const [currentAngle, setCurrentAngle] = useState<string>('');
  const [effects, setEffects] = useState(clip.effects ?? []);
  const [transition, setTransition] = useState(clip?.transition ?? null);
  const [playbackRate, setPlaybackRate] = useState(clip.playbackRate ?? 1);
  const [aspectRatio, setAspectRatio] = useState({ x: 16, y: 9 });
  const [focalPoint, setFocalpoint] = useState({
    x: clip.focalPoint?.x ?? 0,
    y: clip.focalPoint?.y ?? 0,
  });
  const [overlays, setOverlays] = useState(clip.overlays ?? []);

  const [_clip, setClip] = useState();
  const [previousClipThumb, setPreviousClipThumb] = useState();

  const { t } = useTranslation('module.highlights');

  useEffect(() => {
    (async () => {
      // TODO use react-query
      const _sportingEvent = await SportingEventCollection.getOrFetch(
        clip.sportingEventId
      );
      setSportingEvent(_sportingEvent.data);

      if (_sportingEvent.videoIds.length > 1) {
        // TODO use react-query
        await videoCollection.fetchIfEmpty();
        const videos = videoCollection.toArray((v) => v.toJS());
        let _angles: any[] = [];
        for (const angle of _sportingEvent.videoIds) {
          for (const video of videos) {
            if (video.videoId === angle && video.state === 'ready') {
              _angles.push({
                label:
                  video?.tags?.output_key ?? `stream ${_angles.length + 1}`,
                value: angle,
                thumbnail: video.thumbnailUrl,
                videoId: video.videoId,
              });
              break;
            }
          }
        }
        setAngles(_angles);
        for (const angle of _angles) {
          if (clip.videoUri.includes(angle.value)) {
            setCurrentAngle(angle.value);
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    setClip({
      ...clip,
      start: clipStart,
      end: clipEnd,
      videoUri: videoUri,
      effects: effects,
      transition: transition,
      playbackRate: playbackRate,
      focalPoint: focalPoint,
      overlays: overlays,
    });
  }, [
    clipStart,
    clipEnd,
    playbackRate,
    effects,
    currentAngle,
    focalPoint,
    transition,
    overlays,
  ]);

  const images = angles.map((angle) => {
    return {
      imageSrc: () => generateClipThumbnail(angle.value, clipStart, 200),
      selected: angle.value === currentAngle,
      label: angle?.label,
      value: angle.value,
      cacheKey: `${angle.value}-${200}-${clipStart}`,
    };
  });

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitted(true);
    //
    if (action === 'copy') {
      const newContentId = uuidv4();
      clipSelection.splice(clipIdx + 1, 0, {
        ...clip,
        contentId: newContentId,
        copyOfContentId: clip.contentId,
        start: clipStart,
        transition: transition,
        end: clipEnd,
        videoUri: videoUri,
        effects,
        playbackRate,
        focalPoint,
        overlays,
      });
    } else {
      clipSelection[clipIdx] = {
        ...clip,
        start: clipStart,
        end: clipEnd,
        videoUri: videoUri,
        effects,
        transition: transition,
        playbackRate,
        focalPoint,
        overlays,
      };
    }

    try {
      setView(ViewStates.Create);

      await onSave(clipSelection);

      setView(ViewStates.Success);
      onSuccess(true);
    } catch (e) {
      setView(ViewStates.Fail);
      throw new Error(e);
    }
  };

  const playerRef = useRef();
  if (!_clip) {
    return;
  }
  // Add placeholder clip to test transitions.
  const _clips = transition
    ? [
        {
          type: 'clip.placeholder',
          imageSrc: previousClip?.thumbnailUrl,
        },
        _clip,
      ]
    : [_clip];
  let { totalDuration, content } = preprocessContent(
    template,
    _clips,
    videos,
    true,
    generateClipThumbnail
  );
  const ratioValue = calculateAspectRatioToBounds(
    aspectRatio.x,
    aspectRatio.y,
    450
  );

  return (
    <form className="form" onSubmit={handleSubmit}>
      <div className="modal-header">
        <h5 className="modal-title">
          {action === 'copy' ? t('copyClip') : t('editClip')}
        </h5>
      </div>

      <div className="row">
        <div className="col-12 col-md-12 col-lg-6 mb-3">
          <SelectAspectRatio
            callback={(v) => setAspectRatio(v.value)}
            value={aspectRatio}
          />
          {totalDuration > 0 && clipEnd <= clip.videoDuration && (
            <Player
              ref={playerRef}
              component={HighlightsComposition}
              durationInFrames={totalDuration * template.fps}
              compositionWidth={ratioValue.x}
              compositionHeight={ratioValue.y}
              fps={25}
              style={{
                width: '100%',
              }}
              inputProps={{
                fps: template.fps,
                content: content,
                totalDurationInFrames: totalDuration * template.fps,
                template,
                sponsorTimeBlocks: [],
                sponsors: [],
              }}
              controls
            />
          )}
          {(totalDuration <= 0 || clipEnd >= clip.videoDuration) && (
            <div
              style={{
                aspectRatio: '16/9',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                backgroundColor: 'black',
                color: 'white',
                alignItems: 'center',
              }}
            >
              {t('clipError')}
            </div>
          )}
          <TimeSlider
            min={Math.max(0, clipStart - 20)}
            max={Math.min(clipEnd + 20, clip.videoDuration)}
            startTime={clipStart}
            endTime={clipEnd}
            onChange={([start, end]) => {
              setClipEnd(end);
              setClipStart(start);
            }}
          />
        </div>
        <div className="col-lg-6">
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                {template?.transitions && template?.transitions.length > 0 && (
                  <div>
                    <label>{t('transition')}</label>
                    {template.transitions.map((_transition) => (
                      <InputCheckboxRadio
                        type={'radio'}
                        key={_transition}
                        label={t(_transition)}
                        onChange={(c) => {
                          c ? setTransition(null) : setTransition(_transition);
                        }}
                        checked={transition === _transition}
                      />
                    ))}
                  </div>
                )}
                {template?.effects && template?.effects.length > 0 && (
                  <div>
                    <label>{t('effects')}</label>
                    <InputCheckboxRadio
                      type={'checkbox'}
                      label={t('replay')}
                      onChange={(c) => {
                        if (c) {
                          setEffects([]);
                        } else {
                          setEffects(['replay']);
                        }
                      }}
                      checked={effects.includes('replay')}
                    />
                  </div>
                )}
                <label>{t('overlays')}:</label>
                <InputCheckboxRadio
                  type={'checkbox'}
                  label={t('scoreboard')}
                  onChange={(c) => {
                    c
                      ? setOverlays(overlays.filter((o) => o !== 'scoreboard'))
                      : setOverlays([...overlays, 'scoreboard']);
                  }}
                  checked={overlays.includes('scoreboard')}
                />
                <InputCheckboxRadio
                  type={'checkbox'}
                  label={'Sponsors'}
                  disabled={
                    !template?.availableClipSettings?.includes('sponsors')
                  }
                  onChange={(c) => {
                    c
                      ? setOverlays(overlays.filter((o) => o !== 'sponsors'))
                      : setOverlays([...overlays, 'sponsors']);
                  }}
                  checked={overlays.includes('sponsors')}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="form-group">
                <label>{t('playbackRate')}:</label>
                {[1, 0.5, 0.25].map((speed) => (
                  <InputCheckboxRadio
                    type={'checkbox'}
                    label={`${speed}x`}
                    onChange={() => {
                      setPlaybackRate(speed);
                    }}
                    key={`input-${speed}`}
                    checked={playbackRate === speed}
                    value={speed}
                  />
                ))}
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <FocusPointSelector
                clip={_clip}
                callback={(value) => setFocalpoint(value)}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <ImagePicker
            images={images}
            aspectRatio={16 / 9}
            callback={(images) => {
              setVideoUri((o) =>
                o.replace(currentAngle, images.find((i) => i.selected)?.value)
              );
              setCurrentAngle(images.find((i) => i.selected)?.value);
            }}
          />
          {false && angles && angles.length > 1 && (
            <div className="form-group">
              <label htmlFor="match">{t('selectStream')}:</label>
              <div className="select-stream">
                {angles.map((angle) => {
                  return (
                    <div
                      key={angle.value}
                      className={angle.value === currentAngle ? 'selected' : ''}
                      onClick={() => {
                        setVideoUri((o) =>
                          o.replace(currentAngle, angle.value)
                        );
                        setCurrentAngle(angle.value);
                      }}
                    >
                      <CachedAsyncImage
                        promise={async () => {
                          return await generateClipThumbnail(
                            angle.videoId,
                            clipStart,
                            200
                          );
                        }}
                        style={{
                          aspectRatio: '16/9',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          alignItems: 'center',
                        }}
                        cacheKey={`${angle.videoId}${clipStart}-200`}
                      />
                      <span>{angle.label}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="form-group actions">
        <button
          type="submit"
          className="btn btn-primary"
          disabled={view === ViewStates.Init}
        >
          {action === 'copy' ? t('addClip') : t('updateClip')}
        </button>
        <button type="button" className="btn btn-link" onClick={onClose}>
          {t('common:cancel')}
        </button>
      </div>
    </form>
  );
};

export const MinSecInput = ({
  totalSeconds = 0,
  onChange,
}: {
  totalSeconds: number;
  onChange: (totalSeconds: number) => void;
}) => {
  const { mins, setMins } = useMinsWithoutSecs(totalSeconds, onChange);
  const { secs, setSecs } = useRemainderSecs(totalSeconds, onChange);

  return (
    <div className="form-inline">
      <div className="input-group col-6">
        <input
          type="number"
          className="form-control"
          value={mins}
          onChange={setMins}
          pattern="\d*"
          inputMode="numeric"
        />
        <div className="input-group-append">
          <span className="input-group-text">mins</span>
        </div>
      </div>
      <div className="input-group col-6">
        <input
          type="number"
          className="form-control"
          value={secs}
          onChange={setSecs}
          pattern="\d*"
          inputMode="numeric"
        />
        <div className="input-group-append">
          <span className="input-group-text">secs</span>
        </div>
      </div>
    </div>
  );
};
