import React, { useEffect, useRef, useState } from 'react';
import Loading from './Loading';

export const SubmittingButton = ({ onClick, children, disabled, ...props }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMounted = useRef(false);

  const clickHandler = async (e) => {
    setIsSubmitting(true);
    try {
      await onClick(e);
    } finally {
      // only set state when component is mounted
      if (isMounted.current) {
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <button
      disabled={disabled || isSubmitting}
      onClick={clickHandler}
      {...props}
    >
      {children}
      {isSubmitting && <Loading color={'#fff'} size={'16'} type={'spinner'} />}
    </button>
  );
};
