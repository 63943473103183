import React, {
  Component,
  Fragment,
  Children,
  useState,
  useRef,
  useEffect,
} from 'react';

import Select from 'react-select';

import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';

import { Column, Input, ObservationDetailsContainer } from './base';

import learningLineCollection from 'domain/Development/LearningLine';

const LearningLineObservationDetails = ({
  observation,
  attributesOptions,
  ...props
}) => {
  const [indicatorId, setIndicatorId] = useState(null);
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState(null);

  const [indicatorOptions, setIndicatorOptions] = useState(null);

  useEffect(() => {
    (async () => {
      await learningLineCollection.fetchIfEmpty();

      const learningLine = learningLineCollection.get(
        observation.attributes_.learningLineId
      );
      setIndicatorOptions(
        learningLine.indicators().map(({ indicator }) => ({
          value: indicator.indicatorId,
          label: indicator.name,
        }))
      );
    })();
  }, [observation]);

  useEffect(() => {
    setIndicatorId(observation.attributes_.indicatorId);
    setRating(observation.attributes_.rating);
    setComment(observation.attributes_.comment);
  }, [observation]);

  if (indicatorOptions === null) {
    return null;
  }
  const ratingOptions = [
    { value: 'positive', label: 'Positive' },
    { value: 'neutral', label: 'Neutral' },
    { value: 'negative', label: 'Negative' },
    { value: 'unknown', label: 'Unrated' },
  ];

  const observationProps = {
    attributes: {
      indicatorId,
      rating,
      comment,
    },
  };

  return (
    <ObservationDetailsContainer
      observationId={observation.id}
      startTime={observation.displayStartTime}
      endTime={observation.displayEndTime}
      observationProps={observationProps}
      {...props}
    >
      <Column />
      <Column>
        <Input label="Marker">
          <Select
            options={indicatorOptions}
            value={indicatorOptions.find(
              option => option.value === indicatorId
            )}
            onChange={option => setIndicatorId(option.value)}
          />
        </Input>
        <Input label="Rating">
          <Select
            options={ratingOptions}
            value={ratingOptions.find(option => option.value === rating)}
            onChange={option => setRating(option.value)}
          />
        </Input>
        <Input label="Comment">
          <textarea
            className="form-control"
            rows="5"
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
        </Input>
      </Column>
    </ObservationDetailsContainer>
  );
};

export { LearningLineObservationDetails };
