import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';
import { Error } from '../RegisterForm';
import { withRouter } from 'react-router-dom';

import Authentication from 'utils/Authentication';

export const _PasswordResetForm = ({ history }) => {
  const [value, setValue] = useState({
    email: '',
  });
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();
  const [succes, setSucces] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    var event = e;
    setIsChecking(true);
    history.replace('/passwordreset'); // needed to trigger password submit.
    setTimeout(() => {
      Authentication.handleInAppPasswordReset(
        {
          realm: 'Username-Password-Authentication',
          connection: 'Username-Password-Authentication',
          email: value.email,
        },
        (error, response) => {
          setIsChecking(false);
          if (error) {
            setError(response);
          }
          if (response) {
            setSucces(response);
          }
        }
      );
    }, 150);
  };
  if (isChecking) {
    return (
      <div className={'userlogin-form-step'}>
        <Loading size={60} />
      </div>
    );
  }
  if (succes) {
    return (
      <div className={'userlogin-form-step'}>
        <h1>{t('loginPage:PasswordResetSucces1')}</h1>
        <p>{t('loginPage:PasswordResetSucces2')}</p>
      </div>
    );
  }

  return (
    <form
      className={'userlogin-form-step'}
      onSubmit={(e) => {
        return submitHandler(e);
      }}
      id={'passwordReset'}
    >
      <h1>{t('loginPage:PasswordResetTitle')}</h1>
      <p>{t('loginPage:PasswordResetSubtitle')}</p>
      <div className={'userlogin-form-input'}>
        <input
          type={'email'}
          id={'email'}
          placeholder={t('form.emailPlaceholder')}
          autocomplete={'email'}
          value={value.email}
          name={'email'}
          required="required"
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, email: e.target.value };
            });
          }}
        />
        <label htmlFor={'email'}>{t('form.emailPlaceholder')}</label>
      </div>

      {error && <Error title={error.code} body={error.description} />}
      <div className={'userlogin-form-actions'}>
        <button
          type="submit"
          value={'login'}
          className={'btn btn-primary'}
          disabled={!value.email}
        >
          {t('loginPage:PasswordResetTitle')}
        </button>
      </div>
    </form>
  );
};

export const PasswordResetForm = withRouter(_PasswordResetForm);
