import {
  AbsoluteFill,
  interpolate,
  useCurrentFrame,
  useVideoConfig,
} from 'remotion';
import './style.css';

import { loadFont } from '@remotion/google-fonts/Lato';

export const SponsorStill = ({ clip, durationInFrames }) => {
  const { fontFamily } = loadFont(); // "Titan One"

  const frame = useCurrentFrame();
  const fadeIn = interpolate(frame, [0, durationInFrames * 0.2], [0, 1], {
    extrapolateRight: 'clamp',
  });
  const videoConfig = useVideoConfig();
  const fadeOut = interpolate(
    frame,
    [durationInFrames - videoConfig.fps, durationInFrames],
    [1, 0],
    {
      extrapolateRight: 'clamp',
    }
  );

  return (
    <AbsoluteFill style={{ zIndex: 201, fontFamily }}>
      <div
        className="sponsorstill"
        style={{
          opacity: Math.min(fadeOut, fadeIn),
        }}
      >
        <div
          style={{ backgroundColor: clip?.options?.backgroundColor }}
          className="sponsor-bg"
        ></div>
        <h1 style={{ color: clip?.options?.color }}>{clip?.options?.title}</h1>

        <div className="sponsor-row">
          {clip?.options?.sponsors.map((sponsor) => {
            return <img src={sponsor} />;
          })}
        </div>
      </div>
    </AbsoluteFill>
  );
};
