import React, { Component, Fragment } from 'react';

import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { AuthAwareAdapterProxy } from 'utils/AuthAwareAdapterProxy';

import { Session } from './../../../domain/Session';

import PersonCollection from '../../../domain/Person';
import TeamCollection from '../../../domain/Team';
import ResourceGroupCollection from 'domain/ResourceGroup';

import Loading from 'lib/Loading';
import { Modal } from 'lib/Modal';
import { gotoRoute } from '../../route';
import logger from '../../../utils/Logger';

import { TTVUserOverview } from './overview';
import { confirmModal } from 'lib/Confirmation';
import { withTranslation } from 'react-i18next';
import { DaysAgo } from 'lib/DaysAgo';
import { EventPublisher } from 'utils/EventPublisher';
import { Page } from 'lib/Page';
import { OverviewItem } from 'lib/OverviewItem';

import userPlaceHolder from 'img/icons/profile-picture-placeholder.svg';
import { Error } from 'lib/PlaceHolder';

import { NewUsersInput } from './new-user-form';
import sweetAlert from 'lib/sweetAlert';

const isPlayerRole = (roleName) => {
  const playerRoleNames = ['Player', 'Speler', 'player'];
  return playerRoleNames.indexOf(roleName) !== -1;
};

const MemberRow = withTranslation('module.settings.group')(
  ({ t, member, onDeleteMember, onChangeNumber, player }) => {
    const itemProps = {
      title: (
        <>
          <span className="d-md-none">{member.firstName || member.email}</span>
          <span className="d-none d-md-inline-block">{member.fullName}</span>
        </>
      ),
      icon: member.profilePictureUrl || userPlaceHolder,
      features: (
        <>
          <span className="d-md-none">{t('active')}</span>
          {!!player && (
            <Jersey player={player} onClick={() => onChangeNumber(player)} />
          )}
          {member.roleNames.map((name) => (
            <span key={name} className="d-none d-md-inline-block">
              {t(`common.role:${name.toLowerCase()}`)}
            </span>
          ))}
        </>
      ),
      details: () => member.email,
      secondaryActions: [
        {
          onClick: () => onDeleteMember(member),
          label: t('common:delete'),
        },
      ],
    };

    return <OverviewItem {...itemProps} />;
  }
);

const InvitationRow = withTranslation('module.settings.group')(
  ({ member, onDelete, onInvite, onChangeNumber, t, player }) => {
    const itemProps = {
      title: (
        <>
          <span className="invitation d-md-none">
            {member.firstName || member.email}
          </span>
          <span className="invitation d-none d-md-inline-block">
            {member.fullName}
          </span>
        </>
      ),
      icon: member.profilePictureUrl || userPlaceHolder,
      features: (
        <>
          <span className="d-md-none">{t('invitationSent')}</span>
          {!!player && (
            <Jersey player={player} onClick={() => onChangeNumber(player)} />
          )}
          {member.roleNames.map((name) => (
            <span key={name} className="d-none d-md-inline-block">
              {t(`common.role:${name.toLowerCase()}`)}
            </span>
          ))}
        </>
      ),
      details: () => (
        <div className="invitation-row-details">
          <span className="overview-item-detail">{member.email} </span>
          <span className="overview-item-detail day-amount">
            <DaysAgo date={member.invitationCreated} />
          </span>
        </div>
      ),
      secondaryActions: [
        {
          onClick: onInvite,
          label: t('resend'),
        },
        {
          onClick: () => onDelete(member),
          label: t('common:delete'),
        },
      ],
    };

    return <OverviewItem {...itemProps} />;
  }
);

const Jersey = observer(({ player, onClick }) => {
  return (
    <svg className="jersey clickable" viewBox="0 0 27 26" onClick={onClick}>
      <g id="noun_Shirt_1385943" fill="#424242">
        <path
          d="M10.0366445,0 C9.01035006,0.274486436 4.82073446,1.90872923 4.15407772,2.50684049 C3.96673087,2.67506907 1.09645925,9.60045262 0.00311113608,11.1259337 C-0.125408489,11.3337547 3.76546871,13.2553162 4.70623857,13.6298008 L4.70623857,26 L22.2937614,26 L22.2937614,13.6298008 C23.2345313,13.2553162 27.1254085,11.3337547 26.9968889,11.1259337 C25.9035408,9.60045262 23.0332691,2.67506907 22.8459223,2.50684049 C22.1792655,1.90872923 17.9896499,0.274486436 16.9633555,6.9496274e-16 C14.4977738,1.28199866 12.6321426,1.28199866 10.0366445,0 Z"
          id="Path"
        ></path>
      </g>
      <text
        id="99"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="11"
        fontWeight="600"
        textAnchor="middle"
        fill="#FFFFFF"
      >
        <tspan x="13" y="15">
          {player.number}
        </tspan>
      </text>
    </svg>
  );
});

const PlayerRow = withTranslation('module.settings.group')(
  ({ player, onInvite, onDelete, onChangeNumber, t }) => {
    const member = player;
    const itemProps = {
      title: (
        <>
          <span className="d-md-none">{member.firstName}</span>
          <span className="d-none d-md-inline-block">{member.fullName}</span>
        </>
      ),
      icon: member.profilePictureUrl || userPlaceHolder,
      features: (
        <>
          <span className="d-md-none">{t('notInvited')}</span>
          {!!player && (
            <Jersey player={player} onClick={() => onChangeNumber(player)} />
          )}
          <span key="player" className="d-none d-md-inline-block">
            {t('common.role:player')}
          </span>
        </>
      ),
      secondaryActions: [
        {
          onClick: onInvite,
          label: t('invite'),
        },
        {
          onClick: () => onDelete(member),
          label: t('common:delete'),
        },
      ],
    };

    return <OverviewItem {...itemProps} />;
  }
);

const InvitePlayerInput = withTranslation('module.settings.group')(
  observer(
    class InvitePlayerInput extends Component {
      constructor(props) {
        super(props);
        this.email = observable(props.player.email || '');
      }

      setEmail = action((email) => {
        this.email.set(email);
      });

      render() {
        return (
          <form
            onSubmit={(e) => {
              e.preventDefault();

              this.props.onInvitePlayer(this.props.player, this.email.get());
            }}
          >
            <div className="row">
              <div className="col-12">
                <b>{this.props.t('invitePlayerTitle')}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {this.props.t('invitePlayerDescription', {
                  player: this.props.player,
                  teamName: this.props.teamName,
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  className="width-100"
                  value={this.email.get()}
                  onChange={(e) => this.setEmail(e.target.value)}
                  autoFocus={true}
                  placeholder={this.props.t('emailAddress')}
                />
              </div>
            </div>
            <div className="row last-row">
              <div className="col-12">
                <button type="submit" className="btn btn-primary">
                  {this.props.t('invite')}
                </button>
              </div>
            </div>
          </form>
        );
      }
    }
  )
);

const Overview = observer(
  ({
    players,
    members,
    roleFilter,
    onInvitePlayer,
    onDeleteMember,
    onDeletePlayer,
    onChangeNumber,
  }) => {
    // make sure mobx will observe changes
    players = players ? players.toArray() : [];
    members = members.toArray();

    let matchingMembers;
    if (roleFilter === 'all') {
      matchingMembers = members;
    } else {
      matchingMembers = members.filter((member) => {
        return member.roleNames.indexOf(roleFilter) !== -1;
      });
    }

    const rows = matchingMembers.map((member, i) => {
      let player;
      if (!!member.memberAttributes.personId) {
        player = players.find(
          (player) => player.personId === member.memberAttributes.personId
        );
      }
      // TODO
      if (member.isInvitationPending()) {
        return (
          <InvitationRow
            key={member.invitationId}
            player={player}
            member={member}
            onInvite={() => onInvitePlayer(member)}
            onDelete={onDeleteMember}
            onChangeNumber={onChangeNumber}
          />
        );
      } else {
        return (
          <MemberRow
            player={player}
            key={member.userId}
            member={member}
            onDeleteMember={onDeleteMember}
            onChangeNumber={onChangeNumber}
          />
        );
      }
    });

    if (isPlayerRole(roleFilter) || roleFilter === 'all') {
      const memberPersonIds = matchingMembers.map((member) => {
        return member.memberAttributes.personId;
      });
      for (const player of players) {
        if (memberPersonIds.indexOf(player.personId) === -1) {
          rows.push(
            <PlayerRow
              key={player.personId}
              player={player}
              onInvite={() => onInvitePlayer(player)}
              onDelete={onDeletePlayer}
              onChangeNumber={onChangeNumber}
            />
          );
        }
      }
    }

    return rows;
  }
);

const TeamManagement = withTranslation('module.settings.group')(
  observer(
    class TeamManagement extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');

        this.addUserDialogIsOpen = observable(false);
        this.invitePlayerDialogIsOpen = observable(false);
      }

      openAddUserDialog = action(() => {
        this.addUserDialogIsOpen.set(true);
      });

      closeAddUserDialog = action(() => {
        this.addUserDialogIsOpen.set(false);
      });

      openInvitePlayerModal = action((player) => {
        this.invitePlayerDialogIsOpen.set(player);
      });

      closeInvitePlayerDialog = action(() => {
        this.invitePlayerDialogIsOpen.set(false);
      });

      componentDidMount() {
        this.loadTeamMembers();
      }

      componentWillUnmount() {
        AuthAwareAdapterProxy.resetActiveResourceGroup();
      }

      loadTeamMembers = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          const manageAdmins = !!this.props.manageAdmins;
          const currentSession = Session.current();

          let resourceGroup;
          if (manageAdmins) {
            const adminResourceGroupId =
              Session.current().getClubAdminResourceGroupId();
            resourceGroup = ResourceGroupCollection.build({
              resourceGroupId: adminResourceGroupId,
            });
            AuthAwareAdapterProxy.setActiveResourceGroup(adminResourceGroupId);
          } else {
            resourceGroup = currentSession.currentResourceGroup();
          }

          // const privileges = Session.current().currentPrivileges();
          yield resourceGroup.members.fetchIfEmpty();
          yield resourceGroup.roles.fetchIfEmpty();

          if (
            currentSession.currentResourceGroupType() === 'team' &&
            !manageAdmins
          ) {
            const teamId = currentSession.currentObjectId();
            this.team = yield TeamCollection.getOrFetch(teamId);

            yield Promise.all([
              this.team.players.fetchIfEmpty(), // only for teams api, when has privilige team:view
              PersonCollection.fetchIfEmpty(), // only for teams api, when has privilige team:view
            ]);

            this.players = this.team.players;
          }

          this.roles = resourceGroup.roles;
          this.members = resourceGroup.members;
          this.targetResourceName = currentSession.targetResourceName();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load team overview',
          });
          this.dataState.set('error');
        }
      });

      addUsers = asyncAction(function* (users) {
        for (const user of users) {
          const memberAttributes = {};
          if (
            user.isPlayer &&
            Session.current().currentResourceGroupType() === 'team'
          ) {
            const person = yield PersonCollection.create({
              firstName: user.firstName,
              lastName: user.lastName,
              gender: user.gender,
            });
            yield this.team.players.create({
              personId: person.personId,
              number: user.number,
            });
            // ?
            memberAttributes.personId = person.personId;
          }

          if (user.email) {
            yield this.members.create({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              roleNames: user.roleNames,
              invitationId: 'created-by-server',
              created: new Date().toISOString(),
              memberAttributes,
            });
          }
        }

        if (users.length > 0) {
          EventPublisher.dispatch('INVITED_MEMBERS', users.length);
        }
      });

      invitePlayer = asyncAction(function* (player, email) {
        const playerRole = this.roles
          .toArray()
          .find((role) => isPlayerRole(role.roleName));

        if (player.has('invitationId')) {
          // this is actually a member
          const member = player;
          const newMemberModel = {
            firstName: member.firstName,
            lastName: member.lastName,
            email: email,
            roleNames: member.roleNames.slice(),
            invitationId: 'created-by-server',
            created: new Date().toISOString(),
            memberAttributes: {
              personId: member.memberAttributes.personId,
            },
          };
          yield member.destroy();
          this.members.create(newMemberModel, { optimistic: false });
          return;
        }

        this.members.create(
          {
            firstName: player.person.firstName,
            lastName: player.person.lastName,
            email: email,
            roleNames: [playerRole.roleName],
            invitationId: 'created-by-server',
            created: new Date().toISOString(),
            memberAttributes: {
              personId: player.personId,
            },
          },
          { optimistic: false }
        );
      });

      setRoleFilter(roleFilter) {
        gotoRoute('user-management.team', { roleFilter });
      }

      get roleFilter() {
        return this.props.match.params.roleFilter || 'all';
      }

      onDeleteMember(member) {
        sweetAlert({
          title: this.props.t(
            !this.props.manageAdmins ? 'removeMember' : 'removeMemberAdmin'
          ),
          text: this.props.t(
            !this.props.manageAdmins
              ? 'removeMemberConfirmMessage'
              : 'removeMemberConfirmMessageAdmin',
            { member }
          ),
          dangerMode: true,
          buttons: [
            this.props.t('common:cancel'),
            this.props.t('common:confirm'),
          ],
        }).then((willDelete) => {
          if (willDelete) {
            this._onDeleteMember(member);
          }
        });
      }

      _onDeleteMember = asyncAction(function* (member) {
        if (member.memberAttributes.personId) {
          const player = this.players.get(member.memberAttributes.personId);
          if (!!player) {
            yield player.destroy();
          }
        }
        member.destroy();
      });

      onChangeNumber(player) {
        let number = player.number;
        confirmModal({
          title: this.props.t('changeNumber'),
          body: (
            <div>
              <input
                type="text"
                defaultValue={number}
                onChange={(e) => (number = e.target.value)}
              />
            </div>
          ),
          actionLabel: this.props.t('common:confirm'),
          actionHandler: () => player.changeNumber(number),
        });
      }

      onDeletePlayer(player) {
        sweetAlert({
          title: this.props.t(
            !this.props.manageAdmins ? 'removeMember' : 'removeMemberAdmin'
          ),
          text: this.props.t(
            !this.props.manageAdmins
              ? 'removeMemberConfirmMessage'
              : 'removeMemberConfirmMessageAdmin',
            { member: player }
          ),
          dangerMode: true,
          buttons: [
            this.props.t('common:cancel'),
            this.props.t('common:confirm'),
          ],
        }).then((willDelete) => {
          if (willDelete) {
            this._onDeletePlayer(player);
          }
        });
      }

      _onDeletePlayer = asyncAction(function* (player) {
        player.destroy();
      });

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const roles = this.roles.toArray();

        const filters =
          roles.length > 1
            ? [
                {
                  title: this.props.t('allTeamMembers'),
                  path: 'all',
                  isActive: this.roleFilter === 'all',
                },
              ].concat(
                roles.map((role) => {
                  return {
                    title: this.props.t(
                      `common.role:${role.roleName.toLowerCase()}`
                    ),
                    path: role.roleName,
                    isActive: this.roleFilter === role.roleName,
                  };
                })
              )
            : undefined;

        const pageProps = {
          title: this.props.t(
            !this.props.manageAdmins ? 'title' : 'titleAdmin'
          ),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              title: this.props.t(
                !this.props.manageAdmins ? 'title' : 'titleAdmin'
              ),
            },
          ],
          primaryAction: {
            title: this.props.t(
              !this.props.manageAdmins ? 'addMember' : 'addMemberAdmin'
            ),
            onClick: () => this.openAddUserDialog(),
          },
          filters: filters,
          className: 'user-management-team-overview',
        };

        return (
          <Page {...pageProps}>
            <Overview
              roleFilter={this.roleFilter}
              players={this.players}
              members={this.members}
              onDeleteMember={(member) => this.onDeleteMember(member)}
              onDeletePlayer={(player) => this.onDeletePlayer(player)}
              onChangeNumber={(player) => this.onChangeNumber(player)}
              onInvitePlayer={(player) => this.openInvitePlayerModal(player)}
            />
            {/* /.page-body */}
            {this.addUserDialogIsOpen.get() && (
              <Modal onCloseClick={() => this.closeAddUserDialog()} size="lg">
                <NewUsersInput
                  manageAdmins={this.props.manageAdmins}
                  roles={this.roles.toArray()}
                  onAddUsers={(users) => {
                    this.addUsers(users);
                    this.closeAddUserDialog();
                  }}
                  resourceGroupName={Session.current().targetResourceName()}
                />
              </Modal>
            )}

            {this.invitePlayerDialogIsOpen.get() && (
              <Modal onCloseClick={() => this.closeInvitePlayerDialog()}>
                <InvitePlayerInput
                  teamName={this.targetResourceName}
                  player={this.invitePlayerDialogIsOpen.get()}
                  onInvitePlayer={(player, email) => {
                    this.invitePlayer(player, email);
                    this.closeInvitePlayerDialog();
                  }}
                />
              </Modal>
            )}
          </Page>
        );
      }
    }
  )
);

export { TeamManagement };
