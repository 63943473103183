import { useEffect } from 'react';

export const Interval = ({ fn, interval, onExit }) => {
  useEffect(() => {
    const pointer = setInterval(fn, interval * 1000);
    return () => {
      clearInterval(pointer);
      onExit && onExit();
    };
  }, [fn, interval, onExit]);

  return null;
};
