import React, { useState, useEffect, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { withTranslation } from 'react-i18next';

const CreatableSelectInput = withTranslation('common')(
  ({ options, autoFocus, formatCreateLabel, placeholder, onChange, t }) => {
    const [value, setValue] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const isInputPreviouslyBlurred = useRef(false);

    const createOptionFromInputValue = () => {
      if (!inputValue) return;
      setValue([{ label: inputValue, value: inputValue }]);
      if (typeof onChange !== 'undefined' && typeof onChange === 'function') {
        // Force callback function. Mobile will not trigger onChange(of Creatable) after creating option.
        // This will be called once here and once on selectItem on desktop.
        onChange({ label: inputValue, value: inputValue, __isNew__: true });
      }
    };

    const onInputBlur = () => {
      if (!isInputPreviouslyBlurred.current) {
        isInputPreviouslyBlurred.current = true;
        createOptionFromInputValue();
      }
    };

    const selectItem = option => {
      isInputPreviouslyBlurred.current = true; // Make sure onInputBlur does not fire after this.
      setValue(option);
      setInputValue(option.label);
      if (typeof onChange != 'undefined') {
        // Callback function
        onChange(option);
      }
    };
    const newLabel = formatCreateLabel // Add default "add new" text when none given.
      ? formatCreateLabel
      : value => `${t('add_new')} ` + value;

    return (
      <CreatableSelect
        value={value}
        autoFocus={autoFocus}
        placeholder={placeholder}
        inputValue={inputValue}
        onInputChange={v => {
          setInputValue(v);
        }}
        onChange={option => selectItem(option)}
        options={options}
        onBlur={onInputBlur}
        createOptionPosition="first"
        closeMenuOnSelect="true"
        blurInputOnSelect={true}
        formatCreateLabel={newLabel}
        styles={{
          option: (providedStyles, props) => ({
            ...providedStyles,
            fontWeight: props.data.__isNew__ && 'bold',
          }),
        }}
      />
    );
  }
);
export default CreatableSelectInput;
