import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';

import {
  VideoObservationPanel,
  PossessionButtonGroup,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';

//const HockeyObservationInput = createComponentFactoryFromTemplate(hockeyObservationInputTemplate);

const HockeyObservationInput = observer(
  class HockeyObservationInput extends Component {
    componentWillMount() {
      const { observationLogger, homeTeam, awayTeam } =
        this.props.observationContext;

      observationLogger.on(
        'beforeObservationAdded',
        (observationCode, attributes) => {
          if (observationCode === 'POSSESSION') {
            const command = Command.create('ObservationLogger.TurnoffGroup', {
              groupId: 'IN-POSSESSION-OF-BALL',
            });
            CommandBus.dispatch(command);
          }
        }
      );
      observationLogger.on(
        'beforeObservationStarted',
        (observationCode, attributes) => {
          const teamSwitchMap = {
            [homeTeam.id]: awayTeam.id,
            [awayTeam.id]: homeTeam.id,
          };
          if (observationCode === 'POSSESSION-TURNOVER') {
            const partialObservationAttributeBag =
              observationLogger.getCurrentPartialObservationAttributes(
                'POSSESSION'
              );
            if (!partialObservationAttributeBag) {
              return;
            }

            const newTeamId = {
              teamId:
                teamSwitchMap[partialObservationAttributeBag.get('teamId')],
            };

            const command = Command.create(
              'ObservationLogger.ToggleRangeObservationExclusive',
              {
                groupId: 'POSSESSION',
                code: 'POSSESSION',
                attributes: newTeamId,
              }
            );
            CommandBus.dispatch(command);
          }
        }
      );

      observationLogger.on('descriptionRequested', (code, attributes) => {
        switch (code) {
          case 'POSSESSION':
            return `Possession ${
              attributes.get('teamId') === homeTeam.id
                ? homeTeam.label
                : awayTeam.label
            }`;
            break;
          case 'POSSESSION-BUILD-UP':
            return 'Build-Up';
          case 'POSSESSION-TURNOVER':
            return 'Turnover';
          case 'POSSESSION-FREE-HIT':
            return 'Free-hit';
          default:
            throw "Don't know how to describe " + code;
        }
      });

      observationLogger.on(
        'afterObservationAdded',
        (code, attributes, triggerTime, startTime, endTime, description) => {
          CommandHistory.instance().tagHistoryItem(description);
        }
      );
    }

    getPossessionName(teamId) {
      const { homeTeam, awayTeam } = this.props.observationContext;
      const teamName = teamId === homeTeam.id ? homeTeam.label : awayTeam.label;
      return `Possession ${teamName}`;
    }

    endAllObservations() {
      {
        const command = Command.create('ObservationLogger.TurnoffGroup', {
          groupId: 'IN-POSSESSION-OF-BALL',
        });
        CommandBus.dispatch(command);
      }

      {
        const command = Command.create('ObservationLogger.TurnoffGroup', {
          groupId: 'POSSESSION',
        });
        CommandBus.dispatch(command);
      }
    }

    render() {
      const homeTeam = this.props.observationContext.homeTeam;
      const awayTeam = this.props.observationContext.awayTeam;
      const observationLogger = this.props.observationContext.observationLogger;

      return (
        <VideoObservationPanel
          observationContext={this.props.observationContext}
        >
          <PossessionButtonGroup>
            <HotKey keys={['tab']} onKeysPressed={this.endAllObservations} />
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: homeTeam.id }}
              observationLogger={observationLogger}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable set-homepossession-button label ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['1']} onKeysPressed={trigger} />
                  Possession {homeTeam.shortLabel}
                  <span className="button-hotkey">1</span>
                  <img className="i" src={homeTeam.logoUrl} />
                </div>
              )}
            />

            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: awayTeam.id }}
              observationLogger={observationLogger}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable label label-i-lft ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['2']} onKeysPressed={trigger} />
                  <img className="i" src={awayTeam.logoUrl} />
                  Possession {awayTeam.shortLabel}
                  <span className="button-hotkey">2</span>
                </div>
              )}
            />
          </PossessionButtonGroup>
          <EventButtonGroup>
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION-BUILD-UP"
              groupId="IN-POSSESSION-OF-BALL"
              observationLogger={observationLogger}
              render={(props) => (
                <RangeTriggerButton
                  classNames="tag-rangetag-button"
                  {...props}
                  keys="w"
                  description="Build-up"
                />
              )}
            />

            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION-TURNOVER"
              groupId="IN-POSSESSION-OF-BALL"
              observationLogger={observationLogger}
              render={(props) => (
                <RangeTriggerButton
                  {...props}
                  keys="q"
                  description="Turnover"
                />
              )}
            />

            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION-FREE-HIT"
              groupId="IN-POSSESSION-OF-BALL"
              observationLogger={observationLogger}
              render={(props) => (
                <RangeTriggerButton
                  {...props}
                  keys="e"
                  description="Free-hit"
                />
              )}
            />

            <InputTrigger
              type="point"
              observationCode="23-ENTRY"
              observationLogger={observationLogger}
              description="23 Entry"
              render={(props) => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="a"
                />
              )}
            />

            <InputTrigger
              type="point"
              observationCode="CIRCLE-ENTRY"
              observationLogger={observationLogger}
              description="Circle Entry"
              render={(props) => <PointTriggerButton {...props} keys="s" />}
            />

            <InputTrigger
              type="point"
              observationCode="SCORING-OPPORTUNITY"
              observationLogger={observationLogger}
              description="Scorings Opportunity"
              render={(props) => <PointTriggerButton {...props} keys="d" />}
            />

            <InputTrigger
              type="point"
              observationCode="REASON-PENALTY-CORNER"
              observationLogger={observationLogger}
              description="Penalty Corner given"
              render={(props) => <PointTriggerButton {...props} keys="f" />}
            />

            <InputTrigger
              type="point"
              observationCode="PENALTY-CORNER"
              observationLogger={observationLogger}
              description="Penalty Corner"
              render={(props) => <PointTriggerButton {...props} keys="z" />}
            />

            <InputTrigger
              type="point"
              observationCode="PENALTY-STROKE"
              observationLogger={observationLogger}
              description="Penalty Stroke"
              render={(props) => <PointTriggerButton {...props} keys="x" />}
            />

            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ type: 'FIELD', result: 'GOAL' }}
              observationLogger={observationLogger}
              description="Goal - FG"
              render={(props) => <PointTriggerButton {...props} keys="c+v" />}
            />

            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ type: 'PENALTY-CORNER', result: 'GOAL' }}
              observationLogger={observationLogger}
              description="Goal - PC"
              render={(props) => <PointTriggerButton {...props} keys="c+b" />}
            />

            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ type: 'PENALTY-STROKE', result: 'GOAL' }}
              observationLogger={observationLogger}
              description="Goal - PS"
              render={(props) => <PointTriggerButton {...props} keys="c+n" />}
            />
            <InputTrigger
              type="point"
              observationCode="HIGHLIGHT"
              observationLogger={observationLogger}
              description="Highlight"
              render={(props) => <PointTriggerButton {...props} keys="" />}
            />
          </EventButtonGroup>
        </VideoObservationPanel>
      );
    }
  }
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'hockey.video.default',
  component: HockeyObservationInput,
  title: 'Hockey Video',
  options,
  usedKeys: [
    '1',
    '2',
    'q',
    'w',
    'e',
    'a',
    's',
    'd',
    'f',
    'z',
    'x',
    'c',
    'v',
    'b',
    'n',
  ],
});
