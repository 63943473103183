import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';

import { formatTime, timeSince } from 'lib/FormattedTime';

import './index.scss';
import Loading from '../../../lib/Loading';

type User = {
  userId: string;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
};
type Comment = {
  commentId: number;
  comment: string;
  commenterUser: User;
  created: string;
};

type Conversation = {
  tenantId: string;
  conversationId: string;
  subjectTRN: string;
  subjectDescription: string;
  conversationStarterUser: User;
  created: string;
  updated: string;
  tags: { [key: string]: any };
  comments: Comment[];
};

type ChatItem = {
  id: string;
  content: string | JSX.Element;
  created: string;
  children: ChatItem[];
  parentId?: string;
  conversationId: string;
  username?: string;
  userPictureUrl?: string;
};

type OnReplyHandler = (
  {
    message,
    chatItem,
  }: {
    message: string;
    chatItem?: ChatItem;
  },
  callback: () => void
) => void;

const CommentToChatItem = ({
  conversation,
  comment,
}: {
  conversation: Conversation;
  comment: Comment;
}): ChatItem => ({
  id: comment.commentId.toString(),
  content: comment.comment,
  created: comment.created,
  children: [],
  parentId: conversation.conversationId,
  conversationId: conversation.conversationId,
  username: comment.commenterUser.firstName,
  userPictureUrl: comment.commenterUser.profilePictureUrl,
});

const conversationToChatItem = (conversation: Conversation): ChatItem => ({
  id: conversation.conversationId,
  content: (
    <>
      <span className="observation-node-badge" style={{ marginRight: '5px' }}>
        {formatTime(conversation.tags.videoTime)}
      </span>{' '}
      {conversation.comments[0].comment}
    </>
  ),
  created: conversation.created,
  children: conversation.comments
    .slice(1)
    .map((comment) => CommentToChatItem({ conversation, comment })),
  username: conversation.conversationStarterUser.firstName,
  userPictureUrl: conversation.conversationStarterUser.profilePictureUrl,
  conversationId: conversation.conversationId,
});

export const ChatList: React.FC<{
  onSelect: (chatItem: ChatItem) => void;
  onStartTyping: () => void;
  onStopTyping: () => void;
  onReply: OnReplyHandler;
  conversations?: Conversation[];
  activeConversationIds: string[];
  translator: (item: string) => string;
}> = ({
  onSelect,
  onStartTyping,
  onStopTyping,
  onReply,
  conversations,
  activeConversationIds,
  translator,
}) => {
  const chatItems = conversations?.map(conversationToChatItem);

  if (!chatItems) {
    return <Loading type={'spinner'} size={40} />;
  }

  return (
    <>
      <div className="sidebar-list chat-list">
        {chatItems.map((chatItem) => (
          <ChatItemView
            key={chatItem.id}
            active={
              activeConversationIds.indexOf(chatItem.conversationId) !== -1
            }
            chatItem={chatItem}
            onSelect={onSelect}
            onStartTyping={onStartTyping}
            onStopTyping={onStopTyping}
            onReply={onReply}
            translator={translator}
          />
        ))}
      </div>
      <div className="sidebar-footer">
        <ChatItemForm
          onReply={onReply}
          onStartTyping={onStartTyping}
          onStopTyping={onStopTyping}
          translator={translator}
        />
      </div>
    </>
  );
};

const ChatItemView: React.FC<{
  onSelect?: (chatItem: ChatItem) => void;
  onStartTyping: () => void;
  onStopTyping: () => void;
  onReply: OnReplyHandler;
  chatItem: ChatItem;
  active: boolean;
  translator: (item: string) => string;
}> = ({
  onSelect,
  onStartTyping,
  onStopTyping,
  onReply,
  chatItem,
  active,
  translator,
}) => {
  const handleSelect = () => {
    onSelect?.(chatItem);
  };
  const handleReply = ({ message }: { message: string }, callback) => {
    onReply({ chatItem, message }, callback);
  };

  const [childrenVisible, setChildrenVisible] = useState(false);
  const [replyVisible, setReplyVisible] = useState(false);

  const toggleChildrenVisible = () => setChildrenVisible(!childrenVisible);
  const toggleReplyVisible = () => {
    // also show children when replying
    if (!replyVisible) {
      setChildrenVisible(true);
    }
    setReplyVisible(!replyVisible);
  };

  return (
    <div className={`chat-item ${active ? 'active' : ''}`}>
      <div className="chat-item-header">
        {chatItem.userPictureUrl && (
          <img
            src={chatItem.userPictureUrl}
            alt={chatItem.id}
            className="profile-picture"
          />
        )}
        {chatItem.username && (
          <div className="username">{chatItem.username}</div>
        )}
        <div className="time-ago-relative">{timeSince(chatItem.created)}</div>
      </div>
      <div
        className={`chat-item-content ${onSelect ? 'clickable' : ''}`}
        onClick={handleSelect}
      >
        {chatItem.content}
      </div>
      <div className="chat-item-actions">
        <i
          className="i-undo2"
          onClick={toggleReplyVisible}
          style={{ cursor: 'pointer' }}
        />
        {!!chatItem.children.length && (
          <div
            className="toggle-comments"
            onClick={toggleChildrenVisible}
            style={{ cursor: 'pointer' }}
          >
            <i
              className={`i-chevron-${
                childrenVisible ? 'down' : 'right'
              } i-dark`}
            />{' '}
            {chatItem.children.length} opmerkingen
          </div>
        )}
      </div>
      <div className="chat-item-children">
        {!!chatItem.children.length && childrenVisible && (
          <>
            {chatItem.children.map((child) => (
              <ChatItemView
                active={active}
                key={child.id}
                chatItem={child}
                onSelect={onSelect}
                onStartTyping={onStartTyping}
                onStopTyping={onStopTyping}
                onReply={onReply}
                translator={translator}
              />
            ))}
          </>
        )}
        {replyVisible && (
          <ChatItemForm
            onStartTyping={onStartTyping}
            onStopTyping={onStopTyping}
            onReply={handleReply}
            translator={translator}
          />
        )}
      </div>
    </div>
  );
};

const ChatItemForm: React.FC<{
  onReply: OnReplyHandler;
  onStartTyping: () => void;
  onStopTyping: () => void;
  translator: (item: string) => string;
}> = ({ onReply, onStartTyping, onStopTyping, translator }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Formik
      initialValues={{ message: '' }}
      validate={(values) => {
        const errors: any = {};
        if (!values.message) {
          errors.message = true;
        }
        return errors;
      }}
      onSubmit={(values) => {
        setIsSubmitting(true);
        onReply({ message: values.message }, () => setIsSubmitting(false));
        values.message = '';
      }}
    >
      {({ values, errors, handleSubmit, handleChange }) => (
        <form
          className="chat-item-form"
          onSubmit={!isSubmitting ? handleSubmit : (e) => e.preventDefault()}
        >
          <div
            className={`form-group mb-0 ${
              errors.message ? 'is-invalid' : 'is-valid'
            }`}
          >
            <div className="input-group input-group-sm">
              <textarea
                rows={3}
                style={{ resize: 'none' }}
                name="message"
                className="form-control"
                placeholder={translator('commentPlaceholder')}
                onChange={handleChange}
                onFocus={onStartTyping}
                onBlur={onStopTyping}
                value={values.message}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="submit">
                  {translator('comment')}
                  {isSubmitting && (
                    <Loading size={20} color={'#fff'} type={'spinner'} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
