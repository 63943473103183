import React, { ReactNode, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';

import { Session } from 'domain/Session';
import { registry } from 'utils/ObservationInput';
import { Modal } from 'lib/Modal';
import { gotoRoute } from 'modules/route';
import sportingEventCollection from '../../../domain/SportingEvent';
import Loading from '../../../lib/Loading';

type ObservationInput = {
  name: string;
  title: string;
  urlParams: {};
  previewImgSrc?: string;
  key: () => string;
};

export const ObservationInputNav = ({
  live,
  children,
  urlParams = {},
  disabled,
}: {
  live: boolean;
  urlParams: {};
  children: ReactNode;
}) => {
  const [ObservationSelection, setObservationSelection] = useState(
    <div
      style={{
        height: '100%',
        opacity: 0.75,
      }}
    >
      <Loading size={12} color={'white'} type={'spinner'} />
    </div>
  );
  useEffect(() => {
    (async () => {
      let component = await observationSelection(
        live,
        children,
        urlParams,
        disabled
      );

      setObservationSelection(component);
    })();
  }, []);
  return ObservationSelection;
};

export async function observationSelection(
  live,
  children, // Open modal / observation directly when no children are given.
  urlParams = {},
  disabled
) {
  console.log('start observation selection');
  const sportingEvent = await sportingEventCollection.getOrFetch(
    urlParams?.sportingEventId
  );

  const type = sportingEvent?.get('type');
  let observationInputs = await registry.forSession({
    session: Session.current(),
    live,
    type,
  });

  const routeName = `observe.${live ? 'live' : 'video'}-start`;

  const openObservation = (observationInput: ObservationInput) => {
    if (!disabled) {
      gotoRoute(routeName, {
        observationInputKey: observationInput.key(),
        ...urlParams,
      });
    }
  };

  const openObservationSelectionModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ObservationInputNavModal
            onClose={onClose}
            observationInputs={observationInputs}
            onClickHandler={(observationInput: ObservationInput) => {
              openObservation(observationInput);
              onClose();
            }}
          />
        );
      },
    });
  };

  if (observationInputs.length === 0) {
    return null;
  } else if (observationInputs.length === 1) {
    if (!children) {
      return openObservation(observationInputs[0]);
    } else {
      return (
        <div
          onClick={() => openObservation(observationInputs[0])}
          style={{ height: '100%' }}
        >
          {children}
        </div>
      );
    }
  } else {
    if (!children) {
      openObservationSelectionModal();
    }

    return (
      <div
        data-id={'openObservationSelectionModal'}
        onClick={openObservationSelectionModal}
        style={{ height: '100%' }}
      >
        {children}
      </div>
    );
  }
}

const ObservationInputNavModal = ({
  observationInputs,
  onClickHandler,
  onClose,
}: {
  observationInputs: any[];
  onClickHandler: (observationInput: ObservationInput) => void;
  onClose: any;
}) => (
  <Modal onCloseClick={onClose}>
    <div className="modal-header">
      <h5 className="modal-title">Select tag panel</h5>
    </div>
    <p>Select the tag panel you'd like to use to tag this recording.</p>
    <div
      className={`observation-input-selection${
        window.isSkillReflect ? '--sr' : ''
      }`}
    >
      {observationInputs.map((observationInput, i) => (
        <button
          key={i}
          type="button"
          className="observation-input-selection-item"
          data-name={observationInput.name}
          style={
            observationInput.previewImgSrc
              ? {
                  background: `linear-gradient(hsla(0, 0%, 0%, 0.3), hsla(0, 0%, 0%, 0.3)), url(${observationInput.previewImgSrc}) center/contain no-repeat`,
                }
              : {}
          }
          onClick={() => onClickHandler(observationInput)}
        >
          <div className="observation-input-selection-item-inner">
            {observationInput.title}
          </div>
        </button>
      ))}
    </div>
  </Modal>
);
