import React, { useState, useEffect } from 'react';

import './style.scss';
import { useTranslation } from 'react-i18next';

const CountDown = ({ time, wait, complete, onCompleteCallback = () => {} }) => {
  const [seconds, setSeconds] = useState(time);
  const [focus, setFocus] = useState(true);
  const { t } = useTranslation('downloadWait');
  let countDownTimer;

  useEffect(() => {
    countDownTimer = setInterval(() => {
      if (seconds > 0 && document.visibilityState !== 'hidden') {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        onCompleteCallback();
        clearInterval(countDownTimer);
      }
    }, 1000);
    return () => {
      clearInterval(countDownTimer);
    };
  });

  return (
    <div>
      {seconds === 0 ? (
        complete
      ) : (
        <>
          {wait}
          <div className={'popup-timer'}>
            <svg
              width="40"
              viewBox="0 0 36 36"
              className="circular-chart orange"
            >
              <path
                className="circle-bg"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className="circle"
                strokeDasharray={`${(seconds * 100) / time}, 100`}
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" className="percentage">
                {seconds}
              </text>
            </svg>
            <span>
              {seconds === 1 ? t('second') : t('seconds')} {t('downloadText')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export { CountDown };
