import { Tour } from '../Tour';
import { matchRuleShort } from 'utils/matchRuleShort';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';

export default new Tour({
  key: 'videoObserveKorfball',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return (
      matchRuleShort(path, '/observe/*/video') &&
      currentSession.sportType() === 'korfball'
    );
  },
  steps: () => [
    {
      target: '.edit-teamtags-label',
      placement: 'top',
      offset: -10,
      i18nKey: 'module.tours.videoObserveKorfball:step1TeamTags',
    },
    {
      target: '.set-homepossession-button',
      placement: 'top',
      offset: -5,
      i18nKey: 'module.tours.videoObserveKorfball:step2Possession',
    },
    {
      target: '.tag-standardtag-button',
      placement: 'top',
      offset: -5,
      i18nKey: 'module.tours.videoObserveKorfball:step3StandardTags',
    },
    {
      target: '.input-observations',
      placement: 'top',
      offset: -5,
      i18nKey: 'module.tours.videoObserveKorfball:step4TagHistory',
    },
    {
      target: '.input-undo-icon',
      placement: 'top',
      offset: -10,
      i18nKey: 'module.tours.videoObserveKorfball:step5Undo',
    },
  ],
  callbacks: {
    start: () => {
      ObservationContext.instance().clock.stop();
    },
    nextStep: index => {
      // console.log(`tour nextStep ${index}`);
    },
    end: () => {
      // console.log("tour ended");
    },
  },
});
