import React from 'react';
import './filter.scss';
export const IconFilter = ({
  light = false,
  numberOfActiveFilters = 0,
}: {
  light?: boolean;
  numberOfActiveFilters?: number;
}) => (
  <div className="icon-filter">
    <span className={`${light ? 'i-light' : ''}`}>
      <svg width="16px" height="17px" viewBox="0 0 16 17">
        <g
          id="Highlights"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Wedstrijdvideo---Desktop---1170px-Copy-25"
            transform="translate(-1024.000000, -186.000000)"
            fill="#000000"
            fillRule="nonzero"
          >
            <g
              id="noun_filter_1985427_1A1A1A"
              transform="translate(1024.000000, 186.000000)"
            >
              <path
                d="M15.6427117,0 L0.355743466,0 C0.220018047,0 0.0914360717,0.0799616031 0.0342885269,0.207173244 C-0.0228590179,0.334384886 -0.00857213172,0.483404237 0.0771491855,0.592442786 L5.33472331,7.26196741 C5.79190366,7.83987172 6.04192417,8.56679538 6.04192417,9.30825752 L6.04192417,13.5571263 C6.04192417,13.6588957 6.08478483,13.760665 6.15979098,13.8297227 L9.36005349,16.7374174 C9.4279162,16.7992059 9.51363752,16.8282828 9.59935883,16.8282828 C9.64936294,16.8282828 9.69936704,16.817379 9.74579942,16.7955713 C9.87438139,16.7374174 9.95653099,16.6065711 9.95653099,16.464821 L9.95653099,9.30825752 C9.95653099,8.56679538 10.2065515,7.83987172 10.6637319,7.26196741 L15.921306,0.592442786 C16.0070273,0.483404237 16.0248859,0.334384886 15.9641666,0.207173244 C15.9034474,0.0799616031 15.7784371,0 15.6427117,0 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
    {numberOfActiveFilters > 0 ? (
      <div className="unread-badge">
        <span>{numberOfActiveFilters}</span>
      </div>
    ) : (
      ''
    )}
  </div>
);
