import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from './Modal';
import { withTranslation } from 'react-i18next';

const ConfirmUI = withTranslation('common')(
  ({ title, body, actionHandler, actionLabel, onClose, t }) => {
    return (
      <Modal onCloseClick={onClose} size="sm">
        <div className="row">
          <div className="col-12">
            <b>{title}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{body}</div>
        </div>
        <div className="modal-input">
          <div
            className="btn btn-primary"
            onClick={() => {
              onClose();
              actionHandler();
            }}
          >
            {actionLabel}
          </div>
          <span
            style={{ marginLeft: '10px' }}
            className="btn btn-link"
            onClick={onClose}
          >
            {t('cancel')}
          </span>
        </div>
      </Modal>
    );
  }
);

export function confirmModal({ title, body, actionHandler, actionLabel }) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ConfirmUI
          title={title}
          body={body}
          actionHandler={actionHandler}
          actionLabel={actionLabel}
          onClose={onClose}
        />
      );
    },
  });
}
