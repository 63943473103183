import { handballBuilder } from './Sports/Handball';
import { tennisBuilder } from './Sports/Tennis';

export class ReportFetcherFactory {
  static build(sportType: string, sportingEvent: any) {
    switch (sportType) {
      case 'tennis':
        return tennisBuilder(sportingEvent);
        break;
      case 'handball':
        return handballBuilder(sportingEvent);
        break;
      default:
        throw `Report definition for ${sportType} not found`;
    }
  }
}
