import React from 'react';
import { NavLink } from 'react-router-dom';

import { ResponsiveSVG, Row, Column } from 'lib/responsive-svg';

import { HandballMatchField } from './match-field';
import { Donut } from './donut';
import { withTranslation } from 'react-i18next';

export const MatchDashboard = ({ report, onClick, reportState }) => {
  const overviewContainerHeight = 260;
  const fieldContainerHeight = 384;
  const rowHeight = overviewContainerHeight + fieldContainerHeight; // = 644

  return (
    <ResponsiveSVG
      width={1024}
      height={rowHeight}
      breakPoint={1024}
      rowHeight={rowHeight}
    >
      <Row top={0}>
        <Column>
          <g>
            <text x={20} y={20 + 16}>
              <tspan fontSize="16">{report.metadata.home}</tspan>
            </text>
            <g transform={`translate(0, 45)`}>
              <Donut
                x="40"
                y="20"
                title="Schot"
                amount={report.home.shots.metrics.scored}
                total={report.home.shots.metrics.total}
                onClick={() => onClick(report.home.shots)}
              />
              <Donut
                x="180"
                y="20"
                title="Fout"
                amount={report.home.attackFouls.metrics.fouled}
                total={report.home.attackFouls.metrics.total}
                onClick={() => onClick(report.home.attackFouls)}
              />
              <Donut
                x="320"
                title="Aanval"
                amount={report.home.attackScores.metrics.scored}
                total={report.home.attackScores.metrics.total}
                onClick={() => onClick(report.home.attackScores)}
                radius={70}
                percentageSize={32}
              />
            </g>
            <text x={20} y={220 + 14}>
              <NavLink to={'team-home'}>
                <tspan
                  fontSize="14"
                  fontWeight="400"
                  fill="#36A6DE"
                  textDecoration="underline"
                >
                  Bekijk spelerstatistieken
                </tspan>
              </NavLink>
            </text>
          </g>
          <g transform={`translate(0, ${overviewContainerHeight})`}>
            <rect width="512" height={fieldContainerHeight} fill="#F9F9F9" />
            <HandballMatchField
              x="40"
              y={40}
              onClick={onClick}
              positionReport={report.home.positionReport}
            />
          </g>
        </Column>
        <Column>
          <g>
            <text x={512 - 20} y={20 + 16} textAnchor="end">
              <tspan fontSize={16}>{report.metadata.away}</tspan>
            </text>
            <g transform={`translate(0, 45)`}>
              <Donut
                x="40"
                title="Aanval"
                amount={report.away.attackScores.metrics.scored}
                total={report.away.attackScores.metrics.total}
                onClick={() => onClick(report.away.attackScores)}
                radius={70}
                percentageSize={32}
                color="#CF0E0F"
              />
              <Donut
                x="200"
                y="20"
                title="Fout"
                amount={report.away.attackFouls.metrics.fouled}
                total={report.away.attackFouls.metrics.total}
                onClick={() => onClick(report.away.attackFouls)}
                color="#CF0E0F"
              />
              <Donut
                x="340"
                y="20"
                title="Schot"
                amount={report.away.shots.metrics.scored}
                total={report.away.shots.metrics.total}
                onClick={() => onClick(report.away.shots)}
                color="#CF0E0F"
              />
            </g>
            <text x={512 - 20} y={220 + 14}>
              <NavLink to={'team-away'}>
                <tspan
                  fontSize="14"
                  fontWeight="400"
                  fill="#36A6DE"
                  textDecoration="underline"
                  textAnchor="end"
                >
                  Bekijk spelerstatistieken
                </tspan>
              </NavLink>
            </text>
          </g>
          <g transform={`translate(0, ${overviewContainerHeight})`}>
            <rect width="512" height={fieldContainerHeight} fill="#F9F9F9" />
            <HandballMatchField
              x="40"
              y={40}
              onClick={onClick}
              positionReport={report.away.positionReport}
            />
          </g>
        </Column>
      </Row>
    </ResponsiveSVG>
  );
};
