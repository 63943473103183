import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { toJS } from 'mobx';

import { Modal } from 'lib/Modal';
import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';

import { ObservationDetailTennisEndpoint } from './tennis/endpoint';
import { ObservationDetailTennisDefault } from './tennis/default';
import { KorfballShotObservationDetails } from './korfball';
import { DevelopmentPlanObservationDetails } from './development-plan';
import { LearningLineObservationDetails } from './learning-line';
import { ObservationDetailTennisServiceError } from './tennis/service-error';
import { CustomObservationDetails } from './custom';

const ObservationDetails = withTranslation('module.observe.video')(
  ({ sportType, onUpdateObservation, onDelete, t, onClose, ...props }) => {
    const {
      current: observation,
      initial: initialObservation,
      setCurrent,
      setSelector,
    } = useObservationsEditCollectionContext();

    if (!observation) return null;

    const handleClose = () => {
      setCurrent(null);
      onClose && onClose();
    };

    // supports existing onUpdate handlers in the containers
    const handleUpdate = (...args) => {
      onUpdateObservation(observation, ...args);
    };

    const handleDelete = (...args) => {
      onDelete(observation, ...args);
      setCurrent(null);
    };

    if (sportType === 'korfball' && initialObservation.code === 'SHOT') {
      setSelector(observation => observation.code === 'SHOT');
    } else {
      setSelector();
    }

    switch (true) {
      case sportType === 'korfball' && observation.code === 'SHOT':
        return (
          <KorfballShotObservationDetails
            {...props}
            observation={observation}
            onCancel={handleClose}
            onDelete={handleDelete}
            onUpdateObservation={handleUpdate}
            onClose={handleClose}
          />
        );
      case observation.code === 'DEVELOPMENT-PLAN':
        return (
          <DevelopmentPlanObservationDetails
            {...props}
            onCancel={handleClose}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            onUpdateObservation={handleUpdate}
            onClose={handleClose}
          />
        );

      case observation.code === 'LEARNING-LINE':
        return (
          <LearningLineObservationDetails
            {...props}
            observation={observation}
            onCancel={handleClose}
            onDelete={handleDelete}
            onUpdateObservation={handleUpdate}
            onClose={handleClose}
          />
        );
      case sportType === 'tennis' && observation.code === 'SERVICE-ERROR':
        return (
          // Doesn't USE ObservationDetailsBase

          <Modal size="full-screen" onCloseClick={handleClose}>
            <ObservationDetailTennisServiceError
              {...props}
              observation={observation}
              onCancel={handleClose}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
              onClose={handleClose}
            />
          </Modal>
        );
      case sportType === 'tennis' && observation.code === 'ENDPOINT':
        return (
          // Doesn't USE ObservationDetailsBase

          <Modal size="full-screen" onCloseClick={handleClose}>
            <ObservationDetailTennisEndpoint
              {...props}
              observation={observation}
              onCancel={handleClose}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
            />
          </Modal>
        );
      case sportType === 'tennis':
        return (
          // Doesn't USE ObservationDetailsBase
          <Modal size="full-screen" onCloseClick={handleClose}>
            <ObservationDetailTennisDefault
              {...props}
              observation={observation}
              onCancel={handleClose}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
            />
          </Modal>
        );
      default:
        // CUSTOM
        return (
          <CustomObservationDetails
            {...props}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            onUpdateObservation={handleUpdate}
            onClose={handleClose}
          />
        );
    }
  }
);

export { ObservationDetails };
