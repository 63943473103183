import { Session } from 'domain/Session';
import { UserIcon } from 'lib/userIcon/userIcon';
import React from 'react';

export const Comment = ({ userId, comment }) => {
  const currentSession = Session.current();

  const currentUserId = currentSession.user.realUserId;

  return (
    <div
      className={`version__comment ${
        currentUserId === userId ? 'comment--own' : ''
      }`}
    >
      {userId && <UserIcon userId={userId} size={25} />}
      <blockquote>{comment}</blockquote>
    </div>
  );
};
