import React, { Component } from 'react';
import { asyncAction } from 'mobx-utils';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import download from 'downloadjs';

import PersonCollection from '../domain/Person';
import { lineUpCollection } from '../domain/LineUp';
import TeamCollection from '../domain/Team';
import { MatchClockHelper } from '../domain/ObservationLogger';

class Exporter {
  static name() {
    throw 'Implement me';
  }

  static async _getExport(exportData, sportingEvent, observations, clock) {
    throw 'Implement me';
  }

  static async download(sportingEvent) {
    let data;
    try {
      data = await this.getExport(sportingEvent);
    } catch (e) {
      console.log(e);
      debugger;
      alert('Fout met downloaden');
      return;
    }

    const name = sportingEvent.get('name') + '.json';
    download(JSON.stringify(data, null, 2), name, 'application/json');
  }

  static addSyncInfo(gameInfo, sportingEvent, clock) {
    const startMatch = clock.findSynchronizationPointTime('START_PERIOD', '1');

    gameInfo.startgame_time = 0;
    gameInfo.half_time = clock.secondsElapsedBetween(
      startMatch,
      clock.findSynchronizationPointTime('END_PERIOD', '1')
    );
    gameInfo.start2ndhalf_time = clock.secondsElapsedBetween(
      startMatch,
      clock.findSynchronizationPointTime('START_PERIOD', '2')
    );
    gameInfo.endgame_time = clock.secondsElapsedBetween(
      startMatch,
      clock.findSynchronizationPointTime('END_PERIOD', '2')
    );
  }

  static async getExport(sportingEvent) {
    const exportData = {
      id: sportingEvent.id,
      name: this.name(),
      game_info: {},
    };
    // Use liveclock for everything
    const clock = MatchClockHelper.getClockByClockId(sportingEvent, 'U1');

    const observationCollection = sportingEvent.getObservationCollection(
      clock.clockId()
    );
    await observationCollection.fetchIfEmpty();

    const observations = observationCollection.toArray();

    let startMatch = clock.findSynchronizationPointTime('START_PERIOD', '1');
    if (startMatch === null) {
      for (const observation of observations) {
        const observationStartTime = observation.get('startTime');
        if (startMatch === null) {
          startMatch = observation.get('startTime');
        } else {
          if (observationStartTime < startMatch) {
            startMatch = observationStartTime;
          }
        }
      }
    }
    const absTimeToRelTimeConverter = time => {
      return clock.secondsElapsedBetween(startMatch, time);
    };

    this.addSyncInfo(exportData.game_info, sportingEvent, clock);

    await this._getExport(
      exportData,
      sportingEvent,
      observations,
      absTimeToRelTimeConverter
    );

    console.log(exportData);
    return exportData;
  }
}

class KorfballExport extends Exporter {
  static name() {
    return 'korfball.v1';
  }

  static async _getExport(
    exportData,
    sportingEvent,
    observations,
    absTimeToRelTimeConverter
  ) {
    await PersonCollection.fetchIfEmpty();

    const lineUp = await lineUpCollection.getOrFetch(
      sportingEvent.get('lineUpId')
    );
    await sportingEvent.loadTeams();

    this.addLineUp(exportData.game_info, sportingEvent, lineUp, observations);

    exportData.tags = [];
    this.addShots(exportData.tags, observations, absTimeToRelTimeConverter);

    exportData.sectiontags = [];
    this.addSectionTags(
      exportData.sectiontags,
      lineUp,
      observations,
      absTimeToRelTimeConverter
    );

    exportData.ballLossTags = [];
    this.addBallLosses(
      exportData.ballLossTags,
      observations,
      absTimeToRelTimeConverter
    );
  }

  static pol2cart({ distance, angle }) {
    const REAL_FIELD_WIDTH = 20;
    const REAL_FIELD_HEIGHT = 20;
    const EXPORT_IMAGE_HEIGHT = 400;
    const EXPORT_IMAGE_WIDTH = 400;
    const EXPORT_IMAGE_CENTER_X = 200;
    const EXPORT_IMAGE_CENTER_Y = 133;

    if (angle > 90) {
      angle -= 360;
    }

    angle += 90;

    const phi = (angle / 360) * 2 * Math.PI;
    const rho = distance;

    const x =
      ((rho * Math.cos(phi)) / REAL_FIELD_WIDTH) * EXPORT_IMAGE_WIDTH +
      EXPORT_IMAGE_CENTER_X;
    const y =
      ((rho * Math.sin(phi)) / REAL_FIELD_HEIGHT) * EXPORT_IMAGE_HEIGHT +
      EXPORT_IMAGE_CENTER_Y;
    return { x, y };
  }

  static addShots(tags, observations, absTimeToRelTimeConverter) {
    const shotTypeLabels = {
      LONG: 'Afstandschot',
      SHORT: 'Korte kans',
      'RUNNING-IN': 'Doorloopbal',
      'FREE-BALL': 'Vrije bal',
      PENALTY: 'Strafworp',
    };

    const resultLabels = {
      GOAL: 'goal',
      MISS: 'mis',
      HIT: 'korf',
    };

    const shots = observations.filter(observation => {
      return observation.get('code') === 'SHOT';
    });
    for (const shot of shots) {
      const attributes = shot.get('attributes');
      tags.push({
        triggertime: absTimeToRelTimeConverter(shot.get('startTime')),
        description: shotTypeLabels[attributes.type],
        attacker: attributes.personId
          ? PersonCollection.get(attributes.personId).fullName
          : null,
        result: resultLabels[attributes.result] || 'mis',
        ...this.pol2cart(attributes),
        id: shot.id,
      });
    }
  }

  static addBallLosses(ballLossTags, observations, absTimeToRelTimeConverter) {
    const losses = observations.filter(observation => {
      return observation.get('code') === 'BALL-LOSS';
    });

    for (const loss of losses) {
      ballLossTags.push({
        triggertime: absTimeToRelTimeConverter(loss.get('startTime')),
        description: 'balverlies',
        id: loss.id,
      });
    }
  }

  static addSectionTags(
    sectiontags,
    lineUp,
    observations,
    absTimeToRelTimeConverter
  ) {
    const possessions = observations.filter(observation => {
      return observation.get('code') === 'POSSESSION';
    });
    const names = {};
    for (const possession of possessions) {
      const attributes = possession.get('attributes');
      const key = attributes.teamId + '-' + attributes.position;
      if (!names[key]) {
        names[key] = this.getPositionName(
          TeamCollection.get(attributes.teamId),
          lineUp.getTeamRoles(attributes.teamId),
          attributes.position
        );
      }
      sectiontags.push({
        description: names[key],
        starttime: absTimeToRelTimeConverter(possession.get('startTime')),
        endtime: absTimeToRelTimeConverter(possession.get('endTime')),
        id: possession.id,
      });
    }
  }

  static getPositionName(team, teamRoles, position_) {
    const alternativeName = `Vak ${
      position_ === 'ATTACK' ? 'aanval' : 'verdediging'
    } ${team.label}`;
    const leader = teamRoles.filter(
      ({ position }) => position === position_ + ':1'
    );
    return leader.length > 0
      ? `Vak ${leader[0].person.fullName}`
      : alternativeName;
  }

  static addTeamPositionLineUp(positionInfo, team, teamRoles, position_) {
    const players = teamRoles
      .filter(({ roleName }) => {
        return roleName === 'player';
      })
      .filter(({ position }) => position.startsWith(position_ + ':'))
      .map(({ person }) => person);

    positionInfo.name = this.getPositionName(team, teamRoles, position_);
    positionInfo.players = players.map(person => {
      return {
        gender: person.gender,
        name: person.fullName,
      };
    });
  }

  static addTeamLineUp(teamInfo, team, teamRoles, observations) {
    teamInfo.attack = {};
    teamInfo.defence = {};

    this.addTeamPositionLineUp(teamInfo.attack, team, teamRoles, 'ATTACK');
    this.addTeamPositionLineUp(teamInfo.defence, team, teamRoles, 'DEFENCE');

    const playerPersonIds = teamRoles
      .filter(({ roleName }) => {
        return roleName === 'player';
      })
      .map(({ person }) => person.id);

    const substitutePersonIds = [
      ...new Set(
        observations
          .filter(
            observation =>
              observation.get('code') === 'SUBSTITUTION' &&
              observation.get('attributes').teamId === team.id
          )
          .map(observation => {
            return observation.get('attributes').inPersonId;
          })
          .filter(personId => {
            return playerPersonIds.indexOf(personId) === -1;
          })
      ),
    ];

    teamInfo.substitutes = {
      players: substitutePersonIds.map(personId => {
        const person = PersonCollection.get(personId);
        return {
          gender: person.gender,
          name: person.fullName,
        };
      }),
    };
  }

  static addLineUp(gameInfo, sportingEvent, lineUp, observations) {
    gameInfo.home_team = {};
    gameInfo.away_team = {};

    this.addTeamLineUp(
      gameInfo.home_team,
      sportingEvent.homeTeam,
      lineUp.getTeamRoles(sportingEvent.homeTeam.id),
      observations
    );

    this.addTeamLineUp(
      gameInfo.away_team,
      sportingEvent.awayTeam,
      lineUp.getTeamRoles(sportingEvent.awayTeam.id),
      observations
    );
  }
}

class DefaultExporter extends Exporter {
  static name() {
    return 'default.v1';
  }

  static async _getExport(
    exportData,
    sportingEvent,
    observations,
    absTimeToRelTimeConverter
  ) {
    exportData.tags = [];
    this.addTags(exportData.tags, observations, absTimeToRelTimeConverter);
  }

  static addTags(tags, observations, absTimeToRelTimeConverter) {
    for (const observation of observations) {
      tags.push({
        description: observation.get('description'),
        starttime: absTimeToRelTimeConverter(observation.get('startTime')),
        endtime: absTimeToRelTimeConverter(observation.get('endTime')),
        id: observation.id,
      });
    }
  }
}

export { KorfballExport, DefaultExporter };
