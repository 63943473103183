import React from 'react';
import { withTranslation } from 'react-i18next';

const LicenseOverview = withTranslation('module.reseller')(
  ({ t, licenses, revoke, startChangeEndDate }) => (
    <div className="tenant-license-overview">
      <table className="table">
        <thead>
          <tr>
            <th>{t('licenseType')}</th>
            <th>{t('productType')}</th>
            <th>{t('licenseStart')}</th>
            <th>{t('licenseEnd')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {licenses.map(license => {
            return (
              <tr key={license.licenseId} className="tenant-license">
                <td>{license.licenseType}</td>
                <td>{license.productId}</td>
                <td>
                  {license.startDate &&
                    new Date(license.startDate).toLocaleDateString(undefined, {
                      timeZone: 'UTC',
                    })}
                </td>
                <td>
                  {license.endDate &&
                    new Date(license.endDate).toLocaleDateString(undefined, {
                      timeZone: 'UTC',
                    })}
                </td>
                <td className="actions">
                  <button
                    className="btn btn-sm btn-default"
                    type="button"
                    onClick={revoke(license.licenseId)}
                  >
                    {t('licenseRevoke')}
                  </button>
                  <button
                    className="btn btn-sm btn-default"
                    type="button"
                    onClick={startChangeEndDate(license.licenseId)}
                  >
                    {t('licenseChangeEndDate')}
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
);

export { LicenseOverview };
