class ObservationInputOptions {
  constructor(
    canEnterBallLoss,
    canSubstitePlayer,
    canManageTime,
    canEnterEvent,
    canUndo
  ) {
    this.canEnterBallLoss = canEnterBallLoss;
    this.canSubstitePlayer = canSubstitePlayer;
    this.canManageTime = canManageTime;
    this.canEnterEvent = canEnterEvent;
    this.canUndo = canUndo;
  }
}

class ObservationInput {
  constructor({
    name,
    version,
    component,
    options = null,
    usedKeys = [],
    title,
    previewImgSrc,
    args,
  }) {
    this.name = name;
    this.version = version;
    this.component = component;
    this.options = options;
    this.usedKeys = usedKeys;
    this.title = title;
    this.previewImgSrc = previewImgSrc;
    this.args = args;
  }

  key() {
    if (!!this.args) {
      return `${this.name}=${this.args}`;
    } else {
      return this.name;
    }
  }

  clone(args) {
    return new ObservationInput({
      name: this.name,
      version: this.version,
      component: this.component,
      options: this.options,
      usedKeys: this.usedKeys,
      title: this.title,
      previewImgSrc: this.previewImgSrc,
      args: args || this.args,
    });
  }
}

export { ObservationInputOptions, ObservationInput };
