import React, { Component, Fragment, useEffect, useState } from 'react';
import omit from 'lodash/omit';

const LicenseTypeSelect = props => {
  const { t } = props;

  const options = [
    { value: 'demo', label: 'licenseTypes.demo' },
    { value: 'free', label: 'licenseTypes.free' },
    { value: 'paid', label: 'licenseTypes.paid' },
  ];

  const propsSanitized = omit(props, 't');

  return (
    <select {...propsSanitized}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export { LicenseTypeSelect };
