import { useState } from 'react';
import './fileUpload.scss';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import Loading from '../Loading';

export const FileUploader = ({
  filetypes = {},
  allowMultiple = false,
  onUpload,
  labels = {},
  onDropFiles = () => {},
  maxSize = 64 * 1000 * 1000, // 64MB
}) => {
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState();

  const onDrop = (acceptedFiles, rejectedFiles) => {
    setFiles(acceptedFiles);
    setRejectedFiles(rejectedFiles);
    onDropFiles(acceptedFiles);
  };

  const { t } = useTranslation('fileUpload');
  const {
    isDragActive,
    getRootProps,
    getInputProps,
    acceptedFiles,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: filetypes,
    maxSize,
    multiple: allowMultiple,
  });
  if (!!files && files.length > 0) {
    return (
      <div className={'fileuploader__files'}>
        {files.map((file, index) => {
          return (
            <div key={`file-${index}`} className={'file file--accepted'}>
              {files[index].name}
              <button
                className={'btn btn-link'}
                onClick={() =>
                  setFiles((o) => {
                    if (o.length === 1) {
                      return [];
                    }
                    let n = o;
                    return [...n.slice(0, index), ...n.slice(index + 1)];
                  })
                }
              >
                <i className={'i-delete i-dark'} />
              </button>
            </div>
          );
        })}
        {typeof onUpload !== 'undefined' && (
          <button
            className={'btn btn-primary'}
            onClick={() => {
              setIsUploading(true);
              onUpload(acceptedFiles);
            }}
            disabled={isUploading}
          >
            {isUploading && (
              <Loading type={'spinner'} size={16} color={'white'} />
            )}{' '}
            {labels?.uploadFile ?? t('uploadFile')}
          </button>
        )}
        {rejectedFiles.length > 0 && (
          <div className={'rejected-files'}>
            <h5>Rejected files</h5>
            {rejectedFiles?.map((file, index) => (
              <div
                key={`${file.file.name}${index}`}
                className={'file file--rejected'}
              >
                <span className={'rejectedMessage'}>
                  {file.errors[0].message}
                </span>{' '}
                {file.file.name}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      className={'fileuploader fileuploader__fileselect'}
      {...getRootProps()}
    >
      <div className="dropzone__wrapper">
        <div id="dropzone" className={'dropzone'}>
          <input {...getInputProps()} />
          <div>
            {!isDragActive && (labels?.dropFileOrClick ?? t('dropFileOrClick'))}
            {isDragActive &&
              !isDragReject &&
              (labels?.dropFile ?? t('dropFile'))}
            {isDragReject && (labels?.dropReject ?? t('dropReject'))}
          </div>
          {labels?.allowedFiles && (
            <span className={'muted'}>{labels.allowedFiles}</span>
          )}
        </div>
        {typeof onUpload !== 'undefined' && (
          <button className={'btn btn-primary'} disabled={true}>
            {labels?.uploadFile ?? t('uploadFile')}
          </button>
        )}
        {rejectedFiles?.map((file) => (
          <div style={{ color: 'red' }}>
            {t(`errors.${file.errors[0].code}`)} {file.file.name}
          </div>
        ))}
      </div>
    </div>
  );
};
