import React, { Component, Fragment } from 'react';

import { observable, action, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';
import { Session } from 'domain/Session';

const isPlayerRole = (roleName) => {
  const playerRoleNames = ['Player', 'Speler', 'player'];
  return playerRoleNames.indexOf(roleName) !== -1;
};
const defaultSelectedRoleName = 'player';

const NewUsersInput = withTranslation('module.settings.group')(
  observer(
    class NewUsersInput extends Component {
      constructor() {
        super();
        this.users = observable([]);
      }

      componentDidMount() {
        this.addEmptyUser();
      }

      addEmptyUser = action(() => {
        const t = this.props.t;

        const userNumber = this.users.length + 1;
        const roleName =
          this.props.roles.indexOf(defaultSelectedRoleName) !== -1
            ? this.props.roles.map((role) => role.roleName)
            : this.props.roles[0].roleName;

        const override = this.props?.overrides?.unnamedMemberLabelKey;
        const unnamedMemberLabelKey = !this.props.manageAdmins
          ? t('member')
          : t('admin');
        this.users.push({
          roleNames: [roleName],
          firstName: '',
          lastName: '',
          email: '',
          number: '',
          gender: 'male',
          get label() {
            if (this.firstName || this.lastName) {
              return `${this.firstName ? this.firstName : ''} ${
                this.lastName ? this.lastName : ''
              }`;
            }
            return `${override ?? unnamedMemberLabelKey} ${userNumber}`;
          },
        });
      });

      setValue = action((userIndex, field, value) => {
        this.users[userIndex][field] = value;
      });

      setRole = action((userIndex, roleName) => {
        this.users[userIndex].roleNames = [roleName];
      });

      onAddUsers() {
        const users = this.users
          .filter((user) => user.firstName || user.lastName || user.email)
          .map((user) => toJS(user))
          .map((user) => {
            let isPlayer =
              user.roleNames.filter((roleName) => isPlayerRole(roleName))
                .length > 0;
            return { ...user, isPlayer };
          });
        this.props.onAddUsers(users);
      }

      showUserNumberInput(userIndex) {
        const playerRoleValue = 'player';

        return (
          Session.current().currentResourceGroupType() === 'team' &&
          this.users[userIndex].roleNames.includes(playerRoleValue)
        );
      }

      render() {
        const roleOptions = this.props.roles.map((role) => ({
          value: role.roleName,
          label: this.props.t(`common.role:${role.roleName}`),
        }));

        return (
          <Fragment>
            <div className="row">
              <div className="col-12">
                <b>
                  {this.props?.overrides?.addMembers ??
                    this.props.t(
                      !this.props.manageAdmins
                        ? 'addMembersMessage'
                        : 'addMembersMessageAdmin',
                      {
                        resourceGroupName: this.props.resourceGroupName,
                      }
                    )}
                </b>
              </div>
            </div>
            {this.users.map((user, userIndex) => {
              return (
                <Fragment key={userIndex}>
                  <div className="row">
                    <div className="col-12">
                      <div className="width-100 relative new-user-name">
                        <b>{user.label}</b>
                      </div>
                    </div>
                    <div className="col-9" />
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-2">
                      <input
                        type="text"
                        className="width-100"
                        value={user.firstName}
                        onChange={(e) =>
                          this.setValue(userIndex, 'firstName', e.target.value)
                        }
                        placeholder={this.props.t('firstName')}
                      />
                    </div>
                    <div className="col-12 col-md-2 mt-3 mt-md-0">
                      <input
                        type="text"
                        className="width-100"
                        value={user.lastName}
                        onChange={(e) =>
                          this.setValue(userIndex, 'lastName', e.target.value)
                        }
                        placeholder={this.props.t('lastName')}
                      />
                    </div>
                    <div className="col-12 col-md-2 mt-3 mt-md-0">
                      <input
                        type="text"
                        className="width-100"
                        value={user.email}
                        onChange={(e) =>
                          this.setValue(userIndex, 'email', e.target.value)
                        }
                        placeholder={this.props.t('emailAddress')}
                      />
                    </div>
                    {this.showUserNumberInput(userIndex) && (
                      <div className="col-12 col-md-2 mt-3 mt-lg-0 mt-md-1">
                        <input
                          type="text"
                          className="width-100"
                          value={user.number}
                          onChange={(e) =>
                            this.setValue(userIndex, 'number', e.target.value)
                          }
                          placeholder={this.props.t('number')}
                        />
                      </div>
                    )}
                    <div className="col-12 col-md mt-3 mt-lg-0 mt-md-1">
                      <div className="form-items break-after relative role-segments">
                        <ButtonGroupSelect
                          radio
                          options={roleOptions}
                          value={user.roleNames[0]}
                          onChange={(value) => this.setRole(userIndex, value)}
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}

            <div className="row">
              <div className="col-12">
                <div
                  className="btn btn-link"
                  onClick={() => this.addEmptyUser()}
                >
                  {this.props?.overrides?.addAnother ??
                    this.props.t(
                      !this.props.manageAdmins
                        ? 'addAnotherMember'
                        : 'addAnotherMemberAdmin'
                    )}{' '}
                  +
                </div>
              </div>
            </div>
            <div className="row last-row">
              <div className="col-12">
                <div
                  className="btn btn-primary"
                  onClick={() => this.onAddUsers()}
                >
                  {this.props.t('common:add')}
                </div>
              </div>
            </div>
          </Fragment>
        );
      }
    }
  )
);

export { NewUsersInput };
