import React, { createContext } from 'react';
import { useCurrentPlayerFrame } from '../../../remotion/helpers/use-current-player-frame';

export const VideoTimeContext = createContext<number>(0);
export const VideoTimeProvider: React.FC<{
  id: string;
  playerRef: React.RefObject;
  fps: number;
}> = ({ id, children, playerRef, fps = 25 }) => {
  // const [videoTime, setVideoTime] = useState(0.0);
  const frameTime = useCurrentPlayerFrame(playerRef);
  return (
    <VideoTimeContext.Provider value={Math.floor(frameTime / fps)}>
      {children}
    </VideoTimeContext.Provider>
  );
};
