import { DomainEvent } from '../../infra/Messaging';

DomainEvent.register(
  'ObservationLogger.ObservationAttributesSet',
  'groupId attributes'
);
DomainEvent.register(
  'ObservationLogger.RangeObservationStarted',
  'groupId code attributes time'
);
DomainEvent.register(
  'ObservationLogger.RangeObservationEnded',
  'groupId partialObservationId code triggerTime startTime endTime attributes description'
);
DomainEvent.register(
  'ObservationLogger.PartialObservationRemoved',
  'groupId partialObservationId'
);
DomainEvent.register('ObservationLogger.EmptyRangeObservationEnded', 'groupId');
DomainEvent.register(
  'ObservationLogger.ObservationUpdated',
  'observationId attributes description triggerTime startTime endTime'
);
