import { Collection } from 'mobx-rest';
import { LearningLine } from './LearningLine';

class LearningLineTreeCollection extends Collection {
  url() {
    return '/learningLines?format=tree';
  }

  model() {
    return LearningLine;
  }
}

const learningLineTreeCollection = new LearningLineTreeCollection();
window.learningLineTreeCollection = LearningLineTreeCollection;

export default learningLineTreeCollection;
