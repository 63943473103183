import { handballReportDefinition } from './ReportDefinition';
import { ReportFetcher } from '../../ReportFetcher';
import { Report } from './Report';

export function handballBuilder(sportingEvent: any) {
  return new ReportFetcher<Report>(
    handballReportDefinition,
    sportingEvent,
    Report
  );
}
