import React from 'react';

import { NavLink } from 'react-router-dom';

import Authentication from 'utils/Authentication';
import {
  ResourceGroupSelection,
  MembershipIcon,
} from './resource-group-selection';
import { MainMenu } from './resource-group-main-menu';

import styles from './index.module.scss';

// build data structure

const buildNavigationData = ({ session, app, t }) => {
  const navigationData = {
    primary: [
      {
        url: '/development/dpd',
        title: 'Spelerontwikkeling',
        isDisabled: false,
      },
      () => (
        <span className="nav-link clickable marketing-wiggle">Trainingen</span>
      ),
    ],
    secondary: [
      {
        title: t('personalSettingsHeader'),
        items: [
          {
            url: '/user-management/user-settings',
            title: t('settings'),
          },
          {
            url: '/user-management/user-profile',
            title: t('personalSettingsProfile'),
          },
        ],
      },
      {
        title: t('teamSettingsHeader'),
        items: [
          {
            url: '/user-management/team',
            title: t('teamMembers'),
            isDisabled: !session.isFeatureAvailable('manageUsers'),
          },
        ].concat(
          app.appId === 'teamtv'
            ? [
                {
                  url: '/user-management/team-tags',
                  title: t('teamtags'),
                  isDisabled: !session.isFeatureAvailable('manageCustomTags'),
                },
              ].concat(
                session.isFeatureAvailable('exchange')
                  ? [
                      {
                        url: '/user-management/shares/incoming',
                        title: t('exchange'),
                        isDisabled: !session.isFeatureAvailable('manageShares'),
                      },
                    ]
                  : []
              )
            : []
        ),
      },
      {
        title: t('clubSettingsHeader'),
        items: [
          {
            url: '/user-management/teams',
            title: t('teams'),
            isDisabled: !session.isFeatureAvailable('manageTeams'),
          },
          {
            url: '/user-management/admins',
            title: t('admins'),
            isDisabled: !session.isFeatureAvailable('manageTeams'),
          },

          {
            url: '/apps/launch/store/subscriptions',
            title: t('licenses'),
            isDisabled: !session.isFeatureAvailable('manageTeams'),
          },
        ].concat(
          session.isFeatureAvailable('manageSchedule')
            ? {
                url: '/training-schedule',
                title: t('module.schedule:title'),
                isDisabled: !session.isFeatureAvailable('manageSchedule'),
              }
            : []
        ),
      },
    ],
  };

  return navigationData;
};

// use new data structure

export const ResourceGroupMenu = ({
  targetRef,
  memberships,
  activeMembership,
  session,
  toggle,
  app,
  t,
}) => {
  const navigationData = buildNavigationData({ session, t, app });
  const isNavGroupDisabled = (navGroup) =>
    navGroup.items.every((item) => item.isDisabled);
  const navGroupClassName = (navGroup) =>
    `nav-settings-group ${isNavGroupDisabled(navGroup) ? 'disabled' : ''}`;
  const navItemClassName = (navItem) =>
    `nav-link ${navItem.isDisabled ? 'disabled' : ''}`;

  return (
    <div
      className={`menu-panel resource-group-menu ${styles.resourceGroupMenu}`}
      ref={targetRef}
    >
      <ResourceGroupSelection
        memberships={memberships}
        activeMembership={activeMembership}
        onChange={(membership) =>
          session.setResourceGroupId(membership.resourceGroupId)
        }
      />
      <MainMenu session={session} t={t} toggle={toggle} app={app} />
      <div className="nav-settings">
        {navigationData.secondary.map((navGroup) => (
          <div key={navGroup.title} className={navGroupClassName(navGroup)}>
            <h5>{navGroup.title}</h5>
            <ul className="nav">
              {navGroup.items.map((navItem) => (
                <li key={navItem.title} className="nav-item">
                  <NavLink
                    onClick={() => toggle()}
                    className={navItemClassName(navItem)}
                    to={navItem.url}
                  >
                    {navItem.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <ul className="nav nav-secondary">
        {t('helpLink') && (
          <li className="nav-item">
            <a
              className="nav-link"
              href={t('helpLink')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('help')}
            </a>
          </li>
        )}
        {false && t('liveChat') && (
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.Trengo.Api.Widget.render();
                window.Trengo.Api.Widget.open('chat');
              }}
            >
              {t('liveChat')}
            </a>
          </li>
        )}
        {t('feedback') && (
          <li className="nav-item">
            <a className="nav-link" target="_blank" href={t('feedbackLink')}>
              {t('feedback')}
            </a>
          </li>
        )}
        {t('updatesLink') && (
          <li className="nav-item">
            <a
              className="nav-link"
              href={t('updatesLink')}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('updates')}
              {/*Updates <span className="badge">3</span>*/}
            </a>
          </li>
        )}
        {t('status') && (
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://status.teamtvsport.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('status')}
            </a>
          </li>
        )}
        <li className="nav-item nav-logout">
          <a
            className="nav-link"
            href="#"
            onClick={() => Authentication.logout()}
          >
            {t('logout')}
          </a>
        </li>
      </ul>
    </div>
  );
};

export { MembershipIcon };
