import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';

import { LearningLine } from '../components/learning-line';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import PersonCollection from 'domain/Person';
import { withTranslation } from 'react-i18next';
import { getRoutePath, gotoPath } from 'modules/route';
import { NavLink } from 'react-router-dom';
import { Error } from 'lib/PlaceHolder';

const PDPDetailPage = withTranslation('module.development')(
  observer(
    class PDPDetailPage extends Component {
      constructor(props) {
        super(props);
        // this.learningLine = observable({ learningLine: undefined });
        this.dataState = observable('loading');
      }

      get personId() {
        return this.props.match.params.personId;
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          this.person = yield PersonCollection.getOrFetch(this.personId);

          this.learningLines =
            yield LearningLineQueryService.learningLinesByPerson(
              this.person.id
            );

          this.developmentPlan =
            yield DevelopmentPlanQueryService.developmentPlanByPerson(
              this.person.id
            );

          if (!this.developmentPlan.isNew) {
            yield this.developmentPlan.conversations.fetchIfEmpty();
            yield this.developmentPlan.growthAmbitions.fetchIfEmpty();
          }

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'unable to load personal development plan',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      handleSave(args) {
        console.debug('TODO implement handleSave. args:', args);
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              path: '/development/pdp',
              title: this.props.t('playerDevelopment'),
            },
            { title: this.person.fullName },
          ],
          subtitle: this.person.fullName,
          primaryAction: {
            title: 'Kies ontwikkeldoelen' /*TODO:TRANSLATE*/,
            onClick: () => gotoPath('create-growth-ambition'),
          },
          filters: [
            {
              title: 'Overzicht' /*TODO:TRANSLATE*/,
              path: 'learning-line-overview',
              isActive: true,
            },
            {
              title: 'Huidige doelen' /*TODO:TRANSLATE*/,
              path: 'monitor-growth-ambition',
            },
          ],
        };

        return (
          <Page {...pageProps}>
            <div className="row learning-lines-detail">
              {this.learningLines.map((learningLine) => (
                <div
                  className="col-xs-12 col-sm-6 col-md-4"
                  key={learningLine.id}
                >
                  <LearningLine
                    learningLine={learningLine}
                    developmentPlan={this.developmentPlan}
                  >
                    <NavLink
                      className="btn btn-primary"
                      to={getRoutePath('development.pdp-update', {
                        personId: this.personId,
                        learningLineId: learningLine.id,
                      })}
                    >
                      {this.props.t('updateDevelopment')}
                    </NavLink>
                  </LearningLine>
                </div>
              ))}
            </div>
          </Page>
        );
      }
    }
  )
);

export { PDPDetailPage };
