import { AbsoluteFill, useCurrentFrame } from 'remotion';
import { SponsorRuleRoundRobin } from './SponsorRuleRoundRobin';

export const Sponsors = ({
  sponsors,
  sponsorTimeBlocks,
  sponsorDuration,
  absoluteFrame,
}) => {
  const frame = useCurrentFrame();

  if (sponsors.length === 0) {
    return null;
  }

  return (
    <AbsoluteFill
      style={{
        position: 'absolute',
        zIndex: 199,
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '14em',
          opacity: 0.7,
          bottom: '4.2em',
          left: '2.2em',
          height: '10em',
        }}
      >
        <SponsorRuleRoundRobin
          sponsors={sponsors}
          frame={frame}
          sponsorTimeBlocks={sponsorTimeBlocks}
          sponsorDuration={sponsorDuration}
          absoluteFrame={absoluteFrame}
        />
      </div>
    </AbsoluteFill>
  );
};
