import { AbsoluteFill, useCurrentFrame, interpolate } from 'remotion';
import type { TransitionPresentation } from '@remotion/transitions';
import type { TransitionPresentationComponentProps } from '@remotion/transitions';
import { useMemo } from 'react';
import zindexes from '../../helpers/zIndexes.json';

export const TriangleWipePresentation = (
  props: CustomPresentationProps
): TransitionPresentation<CustomPresentationProps> => {
  return { component: Wrapper, props };
};

type CustomPresentationProps = {
  colorPrimary: string;
  colorSecondary: string;
};

const Wrapper: React.FC<
  TransitionPresentationComponentProps<CustomPresentationProps>
> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps,
}) => {
  const isEntering = presentationDirection === 'entering';
  const style: React.CSSProperties = useMemo(() => {
    return {
      opacity: !isEntering ? 1 : presentationProgress > 0.5 ? 1 : 0,
    };
  }, [isEntering, presentationDirection, presentationProgress]);
  return (
    <AbsoluteFill style={{ backgroundColor: 'transparent' }}>
      <AbsoluteFill id={'triangleWipeContent'} style={style}>
        {children}
      </AbsoluteFill>
      <AbsoluteFill id={'triangleWipe'} style={{ zIndex: zindexes.transition }}>
        {presentationDirection == 'entering' && (
          <TriangleWipe
            clip={{
              options: {
                color1: passedProps?.colorPrimary,
                color2: passedProps?.colorSecondary,
              },
            }}
            durationInFrames={50}
            frame={50 * presentationProgress}
          />
        )}
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export const TriangleWipe = ({ frame, clip, durationInFrames }) => {
  // const frame = useCurrentFrame();
  const totalDuration = durationInFrames;
  const animationDuration = durationInFrames * 0.9;

  const position1 = interpolate(frame, [0, animationDuration], [-100, 100], {
    extrapolateRight: 'clamp',
  });
  const position2 = interpolate(
    frame,
    [durationInFrames * 0.2, totalDuration],
    [-100, 100],
    {
      extrapolateRight: 'clamp',
    }
  );
  const rotate1 = interpolate(frame, [0, animationDuration], [-70, 15], {
    extrapolateRight: 'clamp',
  });
  const rotate2 = interpolate(
    frame,
    [durationInFrames * 0.1, totalDuration],
    [195, 315],
    {
      extrapolateRight: 'clamp',
    }
  );

  return (
    <AbsoluteFill style={{ zIndex: zindexes.transition }}>
      <div
        style={{
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          transform: `translateX(${position1}%) rotate(${rotate1}deg)`,
          transformOrigin: 'center',
          width: '200%',
          height: '200%',
        }}
      >
        <Triangle color={clip.options.color1 ?? '#fa01aa'} />
      </div>
      <div
        style={{
          position: 'absolute',
          top: '-50%',
          left: '-50%',
          transform: `translateX(${position2}%) rotate(${rotate2}deg)`,
          transformOrigin: 'center',
          width: '200%',
          height: '200%',
        }}
      >
        <Triangle color={clip.options.color2 ?? '#48a4a1'} />
      </div>
    </AbsoluteFill>
  );
};

const Triangle = ({ color, props }) => (
  <svg
    style={{ width: '100%' }}
    viewBox="0 0 1437 1303"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M372.827 396.678C526.622 132.542 603.519 0.474181 718.5 0.474181C833.481 0.474181 910.378 132.542 1064.17 396.678L1241.79 701.729C1397.31 968.819 1475.06 1102.36 1417.39 1202.68C1359.72 1303 1205.18 1303 896.119 1303H540.881C231.815 1303 77.2823 1303 19.6089 1202.68C-38.0645 1102.36 39.6931 968.819 195.208 701.729L372.827 396.678Z"
      fill={color}
    />
  </svg>
);
