import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import liveRecord from './ad_liverecord.jpg';
import playlists from './ad_playlist.jpg';
import 'swiper/css/effect-fade';
import { useTranslation } from 'react-i18next';

export const GraphicSelector = ({ availableCategories = [] }) => {
  const { t } = useTranslation('upgradeModal');
  const slides = [
    {
      img: liveRecord,
      label: t('graphics.liveRecord'),
      category: 'Pro',
    },
    {
      img: playlists,
      label: t('graphics.playlist'),
      category: 'Basic',
    },
  ].filter((slide) => availableCategories.includes(slide.category));
  return (
    <Swiper
      spaceBetween={0}
      effect="fade"
      loop
      autoplay={{ delay: 6000 }}
      modules={[Autoplay, EffectFade]}
      initialSlide={Math.random(0, slides.length - 1)}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={`slide-${index}`}>
            <div className={'upgrade-popup__graphic'}>
              <h5 dangerouslySetInnerHTML={{ __html: slide.label }}></h5>
              <img src={slide.img} />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
