import React, { useEffect, useState } from 'react';

import { withTranslation } from 'react-i18next';
import RjsfForm, { Form as IForm, FormProps } from '@rjsf/core';
import Select from 'react-select';

import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';
import { Page } from 'lib/Page';

import Survey from 'lib/Survey';
import { Popup } from 'lib/notifications';
import surveyData from 'lib/Survey/example.json';

const schemaTraverseQueryKeys = async (schema: object, acc = {}) => {
  for (const [key, value] of Object.entries(schema)) {
    if (key === 'queryKey') {
      acc['enum'] = await store.get(value);
    } else {
      if (Array.isArray(value)) {
        const list = [];
        for (const item of value) {
          if (typeof item === 'object') {
            list.push(await schemaTraverseQueryKeys(item));
          } else {
            list.push(item);
          }
          // TODO handle arrays
        }
        acc[key] = list;
      } else if (typeof value === 'object') {
        acc[key] = await schemaTraverseQueryKeys(value);
      } else {
        acc[key] = value;
      }
    }
  }

  return acc;
};

const schemaTraverseI18n = ({ schema, acc = {}, translate }) => {
  for (const [key, value] of Object.entries(schema)) {
    if (key === 'i18n' && typeof value === 'object' && schema.enum) {
      for (const [i18nKey, i18nValue] of Object.entries(value)) {
        if (i18nKey === 'enumNames') {
          acc[i18nKey] = schema.enum.map((enumValue) =>
            translate(`${i18nValue}.${enumValue}`)
          );
        } else {
          acc[i18nKey] = translate(i18nValue);
        }
      }
    } else {
      if (Array.isArray(value)) {
        const list = [];
        for (const item of value) {
          if (typeof item === 'object') {
            list.push(schemaTraverseI18n({ schema: item, translate }));
          } else {
            list.push(item);
          }
          // TODO handle arrays
        }
        acc[key] = list;
      } else if (typeof value === 'object') {
        acc[key] = schemaTraverseI18n({ schema: value, translate });
      } else {
        acc[key] = value;
      }
    }
  }

  return acc;
};

const beers = ['PILS', 'SESSION', 'IPA', 'DONKER'] as const;
const countries = [
  {
    value: 'NL',
    label: 'Nederland',
  },
  {
    value: 'BE',
    label: 'Belgie',
  },
  {
    value: 'DE',
    label: 'Duitsland',
  },
] as const;

const _repo = {
  beers,
};

const store = {
  get: async (key: keyof typeof _repo) => {
    return timeout(_repo[key], 1);
  },
};

window.store = store;

interface ReactSelectOption {
  value: any;
  label: string;
}

const ReactSelectWidget = function ({
  options,
  onChange,
  value,
  schema,
  ...props
}) {
  const handleChange = (options: ReactSelectOption | ReactSelectOption[]) => {
    if (Array.isArray(options)) {
      onChange(options.map((o) => o.value));
    } else {
      onChange(options.value);
    }
  };

  const valueOption = Array.isArray(value)
    ? options?.enumOptions?.filter((option: ReactSelectOption) =>
        value.includes(option.value)
      )
    : options?.enumOptions?.find(
        (option: ReactSelectOption) => option.value === value
      );

  return (
    <Select
      options={options.enumOptions}
      onChange={handleChange}
      value={valueOption}
      {...props}
    />
  );
};

// fetch options from registry, use formData as value
const SelectField = ({ formData, registry, schema, ...props }) => {
  // TODO HERE fixme
  const getDefinition = (name = 'beerType') =>
    registry.rootSchema.definitions[name];
  const getEnumOptions = (definition) =>
    (definition.enum || []).map((value, idx) => ({
      value,
      label: definition.enumNames[idx],
    }));

  const def = getDefinition();
  const options = {
    enumOptions: getEnumOptions(def),
  };

  // return <pre>TODO</pre>;
  return (
    <ReactSelectWidget
      {...props}
      options={options}
      value={formData}
      isMulti={schema.type === 'array'}
    />
  );
};

const ButtonGroupSelectWidget = function ({ options, ...props }) {
  return <ButtonGroupSelect options={options.enumOptions} {...props} />;
};

const fields = {
  select: SelectField,
};

const widgets = {
  ButtonGroupSelectWidget,
  ReactSelectWidget,
};

const schemaWithKeys = {
  definitions: {
    beerType: {
      title: 'soort',
      type: 'string',
      enum: beers,
      enumNames: beers.map((beer) => beer.toLowerCase()),
    },
    beerTypeAsync: {
      title: 'beerTypeAsync',
      type: 'string',
      queryKey: 'beers',
      i18n: {
        title: 'dev.beers.title',
        enumNames: 'dev.beers.types',
      },
    },
    beer: {
      type: 'object',
      properties: {
        beerType: {
          $ref: '#/definitions/beerType',
        },
        amount: {
          title: 'aantal',
          type: 'number',
        },
      },
      required: ['amount'],
    },
  },
  title: 'bier form',
  description: 'ga voor bier',
  type: 'object',
  properties: {
    country: {
      title: 'Land',
      type: 'string',
      enum: countries.map((country) => country.value),
      enumNames: countries.map((country) => country.label),
    },
    preferred_beer: {
      title: 'Voorkeur en aantal (enkel)',
      $ref: '#/definitions/beer',
      // Q: how to set default value for ref?
    },
    preferred_beer_types: {
      title: 'Voorkeur types (meerdere)',
      //
      // type: 'array',
      // items: {
      //   type: 'string',
      // },
      //
      //
      type: 'array',
      // NOTE: this is resolved TODO HERE
      $ref: '#/definitions/beerType',
      // items: {
      //   // NOTE: this is not resolved
      //   $ref: '#/definitions/beerType',
      // },
    },
    async_beers: {
      $ref: '#/definitions/beerTypeAsync',
      title: 'soort async',
      // type: 'string',
      queryKey: 'beers',
      i18n: {
        title: 'dev.beers:title',
        enumNames: 'dev.beers:types',
      },
    },
    disliked_beer: {
      title: 'Afkeur',
      $ref: '#/definitions/beer',
    },
  },
};

// Resolve $ref in uiSchema:
// https://gist.github.com/arjan/bf90aa09808d4aa0be15f798c900d6fc
const uiSchema = {
  preferred_beer: {
    beerType: {
      'ui:widget': 'ButtonGroupSelectWidget',
    },
  },
  preferred_beer_types: {
    'ui:field': 'select',
  },
  async_beers: {
    'ui:widget': 'ReactSelectWidget',
  },
  country: {
    'ui:widget': 'ReactSelectWidget',
  },
};

const formData = {
  country: 'DE',
  preferred_beer: {
    beerType: 'IPA',
    amount: 3,
  },
  preferred_beer_types: ['PILS', 'IPA'],
};

const timeout = <T extends object | undefined>(value: T, ms = 1000) =>
  new Promise<T>((resolve) => window.setTimeout(resolve, ms, value));

const loadSchemaData = async (schemaOrig) => {
  return await store.get('beers');
};

const useSchema = (orig, translate) => {
  const [schema, setSchema] = useState();

  useEffect(() => {
    (async () => {
      const queriesResolved = await schemaTraverseQueryKeys(orig);
      const translated = await schemaTraverseI18n({
        schema: queriesResolved,
        translate,
      });

      setSchema(translated);
    })();
  }, [orig, translate]);

  return schema;
};

export const RjfTest = withTranslation()(({ t }) => {
  const schema = useSchema(schemaWithKeys, t);

  console.debug('BEN schema', schema);
  const data = {
    imageUrl: 'http://nu.nl/',
    body: `

    <cta>

    </cta>
    <a href="https://nu.nl/" id="nu.nl">
        nu
    </a>
    <a href="https://nos.nl/" id="nos.nl">
        nos
    </a>
    `,
  };

  return schema ? (
    <Page>
      <Popup
        useTemplate
        data={data}
        onClickCallback={(linkId) => alert(`clicked link ${linkId}`)}
      >
        {/*<Survey data={surveyData} />*/}
      </Popup>
      <Form
        schema={schema}
        // schema={schemaWithKeys}
        uiSchema={uiSchema}
        widgets={widgets}
        fields={fields}
        formData={formData}
        onChange={log('changed')}
        onSubmit={log('submitted')}
        onError={log('errors')}
      >
        <div>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </Form>
    </Page>
  ) : null;
});
//
// const log = type => console.debug.bind(console, type);
//
// // const Form: FunctionComponent<T extends IForm<T>> = <T>({
const Form: FunctionComponent<any> = ({ children, ...props }) => (
  <RjsfForm {...props}>
    {' '}
    {children ? (
      children
    ) : (
      <div>
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    )}
  </RjsfForm>
);
// export const RjfTest;
