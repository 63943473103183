import React from 'react';

export const Donut = ({
  title = 'TODO',
  color = '#29E79D',
  radius = 50,
  stroke = 10,
  amount = 0,
  total = 0,
  percentageSize = 18,
  ...props
}) => {
  const titleSize = 12;
  const amountSize = 12;
  const progress = total > 0 ? amount / total : total;
  const percentage = parseInt(progress * 100, 10);
  const textBaselineCorrection = 0.6;

  return (
    <svg
      height={radius * 2 + titleSize + amountSize}
      width={radius * 2}
      {...props}
    >
      <text x={radius} y={titleSize} textAnchor="middle" fontSize={titleSize}>
        <tspan>{title}</tspan>
      </text>
      <g transform={`translate(0, ${titleSize})`}>
        <ProgressRadius
          color="#F2F2F2"
          radius={radius}
          stroke={stroke}
          progress={1}
        />
        <ProgressRadius
          color={color}
          radius={radius}
          stroke={stroke}
          progress={progress}
        />
        <text
          x={radius}
          y={radius + percentageSize - percentageSize * textBaselineCorrection}
          textAnchor="middle"
          fontSize={percentageSize}
        >
          <tspan>{percentage}%</tspan>
        </text>
        <text
          x={radius}
          y={2 * radius + amountSize - amountSize * textBaselineCorrection}
          textAnchor="middle"
          fontSize={amountSize}
          fontWeight="400"
        >
          <tspan>
            {amount} / {total}
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export const ProgressRadius = ({
  color = '#000000',
  radius = 50,
  stroke = 10,
  progress = 0,
}) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress * circumference;

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        stroke={color}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
        transform={`rotate(-90 ${radius} ${radius})`}
      />
    </svg>
  );
};
