import { AbsoluteFill } from 'remotion';

export const PlaceHolder = ({ clip }) => {
  return (
    <AbsoluteFill style={{ backgroundColor: '#c0c0c0' }}>
      {clip?.imageSrc && (
        <img
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
          src={clip?.imageSrc}
        />
      )}
    </AbsoluteFill>
  );
};
