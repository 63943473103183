import React, { Component } from 'react';
import { Dropdown } from 'reactstrap';

export const NavDropDown = withIsOpenToggle(Dropdown);

// TODO: USE withToggle HOC component

function withIsOpenToggle(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = {
        isToggled: false,
      };
    }

    toggle() {
      this.setState(({ isToggled }) => ({
        isToggled: !isToggled,
      }));
    }

    render() {
      return (
        <WrappedComponent
          isOpen={this.state.isToggled}
          toggle={this.toggle}
          {...this.props}
        />
      );
    }
  };
}
