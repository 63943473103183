export const calculateAspectRatioToBounds = (
  x: number,
  y: number,
  limit: number
) => {
  if (x > y) {
    return {
      x: limit,
      y: Math.round((y / x) * limit),
    };
  } else {
    return {
      x: Math.round((x / y) * limit),
      y: limit,
    };
  }
};
