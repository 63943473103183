import { Model, Collection } from 'mobx-rest';

import { PersistentCollection } from './PersistentCollection';

import teamtvLogo from 'img/teamtv_logo_text_only.svg';
import { gotoPath } from 'modules/route';
import { landingsPageForType } from 'modules/navigation/resource-group-types';

class App extends Model {
  get primaryKey() {
    return 'appId';
  }

  getMenuItems(resourceGroupType) {
    // TODO
    return [
      {
        url: '/user-management/user-settings',
        isDisabled: false,
        label: 'test menu item 1',
      },
    ];
  }

  get appId() {
    return this.id;
  }

  get name() {
    return this.get('name');
  }

  launch() {
    return this.rpc('launch', {});
  }

  get imgSrc() {
    return `/appLogo/${this.id}.svg`;
  }

  get color() {
    if (this.appId === 'spelenderwijs-verbeteren') {
      return '#00632C';
    }
  }
}

class AppCollection extends PersistentCollection {
  url() {
    return '/apps';
  }

  model() {
    return App;
  }
}

class AppManager {
  constructor(session) {
    this.session = session;
  }

  getAvailableApps() {
    if (!this.session.user.isReady) {
      return [];
    }

    const licensePrivileges = this.session.licensePrivileges();
    const apps = [];

    let teamtvCoreLogo;
    if (window.isSkillReflect) {
      teamtvCoreLogo = '/logo_sr.svg';
    } else {
      teamtvCoreLogo = teamtvLogo;
    }

    // TODO: fix this check!!
    const teamLikeResourceGroupTypes = ['team', 'user'];
    if (
      licensePrivileges.hasPrivilege('sporting-event:view') ||
      appCollection.toArray().length === 0 || // REMOVE FOR STORE
      teamLikeResourceGroupTypes.indexOf(
        this.session.currentResourceGroupType()
      ) === -1
    ) {
      apps.push({
        imgSrc: teamtvCoreLogo,
        name: 'TeamTV core',
        appId: 'teamtv',
      });
    }

    if (this.session.isFeatureAvailable('hasStore')) {
      apps.push({
        imgSrc: `https://web-assets.teamtv.nl/app_logo/store${
          window.isSkillReflect ? '_sr' : ''
        }.svg`,
        name: 'AppStore',
        appId: 'store',
        // state: 'unlisted',
      });
    }

    return apps.concat(appCollection.toArray());
  }

  getListedApps() {
    return (
      this.getAvailableApps()
        .filter((app) => app.state !== 'unlisted')
        // Remove highlights here for now
        .filter((app) => app.id !== 'teamtv-highlights')
    );
  }

  getAppById(appId) {
    if (appId === 'store') {
      appId = this.getAvailableApps()[0].appId;
    }
    return this.getAvailableApps().find(
      ({ appId: _appId }) => _appId === appId
    );
  }

  getActiveAppByPath(pathname) {
    const availableApps = this.getAvailableApps();
    if (availableApps.length === 0) {
      return null;
    }
    const regex = new RegExp('^/apps/launch/([^/]+)');
    const match = pathname.match(regex);
    if (!match) {
      return availableApps[0];
    } else {
      return this.getAppById(match[1]);
    }
  }

  switchToApp(app) {
    window.localStorage.setItem(
      'com.teamtv.lastActiveAppId',
      JSON.stringify(app.appId)
    );

    gotoPath(this.getAppPath(app));
  }

  getAppPath(app) {
    if (app.appId === 'teamtv') {
      const activeGroupType = this.session.currentResourceGroupType();
      return landingsPageForType(activeGroupType, this.session);
    } else {
      return `/apps/launch/${app.appId}`;
    }
  }

  possibleAppId() {
    let lastActiveAppId = window.localStorage.getItem(
      'com.teamtv.lastActiveAppId'
    );
    if (
      lastActiveAppId !== null &&
      lastActiveAppId !== undefined &&
      lastActiveAppId !== 'undefined'
    ) {
      try {
        lastActiveAppId = JSON.parse(lastActiveAppId);
      } catch (e) {
        window.localStorage.removeItem('com.teamtv.lastActiveAppId');
        lastActiveAppId = undefined;
      }
    } else {
      lastActiveAppId = undefined;
    }
    return lastActiveAppId;
  }

  getLastActiveApp() {
    const possibleAppId = this.possibleAppId();
    let activeApp = null;
    if (!!possibleAppId) {
      activeApp = this.getAppById(possibleAppId);
    }
    if (!activeApp) {
      activeApp = this.getListedApps()[0];
    }
    if (!activeApp) {
      // this is probably the store
      activeApp = this.getAvailableApps()[0];
    }
    return activeApp;
  }

  getAppCount() {
    return this.getListedApps().filter((app) => app.appId !== 'store').length;
  }
}

const appCollection = new AppCollection();
window.appCollection = appCollection;

export { appCollection, AppManager };
