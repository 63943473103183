import React, { useState } from 'react';
import { IconFilter } from 'lib/icons/filter';
import { toPairs, groupBy } from 'lodash';
import { FilterModal } from '../../../lib/FilterModal/filter-modal';
import { useTranslation } from 'react-i18next';

export const LearningLineFilter = ({
  learningLine,
  onChange,
  values,
  observations,
}: {
  learningLine: object;
  values: string[];
  observations: object[];
  onChange: (value: object) => void;
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };
  const handleChange = (selection: object) => {
    onChange(selection);
    setShowFilterModal(false);
  };
  const { t } = useTranslation('common.filters');

  const videoOptions = (observations, learningLine) => {
    const pairs = toPairs(
      groupBy(
        observations.filter(
          // video fragments with matching indicatorIds
          observation =>
            learningLine.indicatorIds.indexOf(
              observation.attributes.indicatorId
            ) !== -1
        ),
        observation => observation.videoId
      )
    );
    return pairs.map(([videoId, observationsPerVideo]) => {
      return {
        value: videoId,
        label: observationsPerVideo[0].sportingEvent.label,
      };
    });
  };

  const roleOptions: { value: any; label: any }[] = observations
    .map(({ creatorRoleName }) => creatorRoleName)
    .filter((value, index, self) => self.indexOf(value) === index && value)
    .map(role => ({ value: role, label: role }));

  const filterConfiguration = [
    {
      filterKey: 'videoIds',
      filter: {
        label: t('video'),
        options: videoOptions(observations, learningLine),
      },
      values: values['videoIds'],
    },
    {
      filterKey: 'roles',
      filter: {
        label: t('role'),
        options: roleOptions,
      },
      values: values['roles'],
    },
  ];

  const numberOfActiveFilters: number = filterConfiguration.filter(
    ({ values }) => values.length > 0
  ).length;

  const numberOfAvailableFilters: number =
    roleOptions.length + videoOptions(observations, learningLine).length;

  return (
    <span>
      <button className="btn btn-link" onClick={toggleFilterModal}>
        <IconFilter light numberOfActiveFilters={numberOfActiveFilters} />
      </button>
      {showFilterModal && (
        <FilterModal
          toggleFilterModal={toggleFilterModal}
          filterConfiguration={filterConfiguration}
          onChange={handleChange}
          numberOfAvailableFilters={numberOfAvailableFilters}
          values={values}
        />
      )}
    </span>
  );
};
