import React, { Component, useEffect } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import { asyncAction } from 'mobx-utils';

import auth from '../../../utils/Authentication';
import UserCollection from '../../../domain/User';
import Loading from 'lib/Loading';
import { Notification } from 'lib/Notification';
import { Session } from '../../../domain/Session';
import { gotoRoute, gotoPath } from '../../route';
import { Trans, withTranslation } from 'react-i18next';
import { Error } from 'lib/PlaceHolder';
import { AnonymousPageWrapper } from '../../../sessionlessPages/AnonymousPageWrapper';
import srIcon from '../../../img/sr_icon.svg';
import ttvIcon from '../../../img/ttv_icon.svg';
import i18n from 'i18next';
import logger from '../../../utils/Logger';

const Invitation = withTranslation('module.invitation')(
  observer(
    class Invitation extends Component {
      constructor(props) {
        super(props);

        if (auth.isAuthenticated) {
          gotoRoute('user-management.accept-invitation', {
            token: this.token,
          });
        }

        this.invitationInfo = null;
        this.dataState = observable('loading');
        this.password = observable('');
        this.legalDocumentsAccepted = observable(false);
        this.legalDocuments = [];
        this.checkboxWrapperRef = React.createRef();
      }
      componentDidMount() {
        if (!auth.isAuthenticated) {
          this.loadData();
        }
      }

      loadData = asyncAction(function* () {
        this.dataState.set('loading');
        try {
          this.invitationInfo = yield UserCollection.getInvitationInfo(
            this.token
          );
          if (this.invitationInfo.userExists) {
            gotoRoute('user-management.accept-invitation', {
              token: this.token,
            });
            return;
          }
          const brandCode = window.isSkillReflect ? 'skillreflect' : 'teamtv';
          this.legalDocuments = yield UserCollection.getLegalDocuments(
            brandCode
          );
          this.dataState.set('loaded');
        } catch (e) {
          this.dataState.set('error');

          // TODO: error should be checked. But in 99.9% of the cases it's because invitation token is already used
          logger.error(e, {
            transactionName: 'Unable to accept invitation',
          });
          gotoPath('/');
        }
      });

      addUser = asyncAction(function* (e) {
        e.preventDefault();

        const password = this.password.get();
        const acceptedLegalDocumentIds = this.legalDocuments.map(
          (legalDocument) => legalDocument.documentId
        );
        yield UserCollection.addUser(
          this.token,
          password,
          acceptedLegalDocumentIds
        );

        const afterSignUpAction = this.invitationInfo.afterSignUpAction;
        switch (afterSignUpAction.action) {
          case 'gotoPath':
            gotoPath(afterSignUpAction.path);
            break;
          case 'redirect':
            window.location.href = afterSignUpAction.url;
            break;
        }
      });

      get token() {
        return this.props.match.params.token;
      }

      checkboxToggle = () => {
        if (this.checkboxWrapperRef.current) {
          this.legalDocumentsAccepted.set(
            this.checkboxWrapperRef.current.querySelectorAll(
              'input[type="checkbox"]:checked'
            ).length === this.legalDocuments.length
          );
        }
      };

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        return (
          <AnonymousPageWrapper
            className={'page--userlogin'}
            title={'register'}
          >
            <div className="userlogin-layout">
              <div className="userlogin__formwrapper">
                <img
                  className="userlogin__icon"
                  src={window.isSkillReflect ? srIcon : ttvIcon}
                />
                <form className={'userlogin-form-step'}>
                  <h1 style={{ whiteSpace: 'pre-wrap' }}>
                    {this.props.t('title', {
                      invitationInfo: this.invitationInfo,
                    })}
                  </h1>
                  <p>
                    {this.props.t('description', {
                      invitationInfo: this.invitationInfo,
                    })}
                  </p>
                  <div className={'userlogin-form-input'}>
                    <div className={'userlogin-form-editable'}>
                      <div className="d-none">
                        <input
                          type={'email'}
                          autoComplete={'mail'}
                          value={this.invitationInfo.email}
                        />
                      </div>
                      {this.invitationInfo.email}
                    </div>
                  </div>
                  <div className={'userlogin-form-input'}>
                    <input
                      onChange={action(({ target: { value } }) =>
                        this.password.set(value)
                      )}
                      value={this.password.get()}
                      type="password"
                      placeholder={this.props.t(
                        'registerPage:form.passwordPlaceholder'
                      )}
                    />
                    <label>
                      {this.props.t('registerPage:form.passwordPlaceholder')}
                    </label>
                  </div>
                  <div className={'userlogin-form-input'}>
                    <div ref={this.checkboxWrapperRef}>
                      {this.legalDocuments.map((checkBox) => (
                        <div
                          key={checkBox.documentId}
                          className={'styled-checkbox'}
                        >
                          <input
                            id={checkBox.documentId}
                            name={checkBox.documentId}
                            type="checkbox"
                            onChange={action(() => {
                              return this.checkboxToggle();
                            })}
                          />
                          <label htmlFor={checkBox.documentId}>
                            <span>
                              {this.props.t('registerPage:form.agreeTo')}
                            </span>
                            <a
                              href={
                                checkBox.url[
                                  `${i18n.language === 'nl' ? 'nl' : 'en'}`
                                ]
                              }
                            >
                              {
                                checkBox.name[
                                  `${i18n.language === 'nl' ? 'nl' : 'en'}`
                                ]
                              }
                            </a>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    style={{ borderBottom: '0' }}
                    className={'userlogin-form-actions'}
                  >
                    <button
                      className="btn btn-primary"
                      disabled={
                        this.password.get().trim() === '' ||
                        !this.legalDocumentsAccepted.get()
                      }
                      onClick={this.addUser.bind(this)}
                    >
                      {this.props.t('createAccount')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </AnonymousPageWrapper>
        );
      }
    }
  )
);

const AcceptInvitation = ({ match }) => {
  // make sure we pass token around so noone is able to send a direct link to someone
  // to accept the invite
  const token = match.params.token;
  useEffect(() => {
    const acceptInvitation = async () => {
      const currentSession = Session.current();
      await currentSession.isReady();

      let invitation;
      try {
        invitation = await UserCollection.getInvitationInfo(token);
      } catch (e) {
        gotoPath('/');
        return;
      }

      try {
        await currentSession.user.acceptInvitation(invitation.invitationId);
        Notification.show('Invitation accepted', 2000);
        setTimeout(() => {
          currentSession.setResourceGroupId(invitation.resourceGroupId);
        }, 2000);
      } catch (e) {
        gotoPath('/');
        // await currentSession.reloadSettings(true);
      }
    };
    acceptInvitation();
  }, []);

  return <Loading type={'fullscreen'} />;
};

export { Invitation, AcceptInvitation };
