import React, { Fragment, useRef } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import { NavDropDown } from '../NavDropdown';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './card.scss';

export const Card = ({
  title,
  subtitle,
  user,
  meta,
  onClick,
  poster,
  actions,
}) => {
  const cardRef = useRef();
  return (
    <div onClick={() => onClick()} className="card" ref={cardRef}>
      <div className="card__image-wrapper">
        {poster && (
          <PosterImage
            image={poster.imageUrl}
            video={poster.videoUrl}
            cardRef={cardRef}
          />
        )}
        {!poster && (
          <div className="card__placeholder">
            <svg
              width="379"
              height="379"
              viewBox="0 0 379 379"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M337.25 57.466C334.43 51.4894 329.973 46.431 324.398 42.88C318.824 39.3292 312.359 37.4308 305.75 37.3995H72.4102C63.129 37.3995 54.2262 41.087 47.6642 47.6535C41.0978 54.216 37.4102 63.1185 37.4102 72.3995V305.739C37.4414 312.349 39.3399 318.813 42.8907 324.387C46.4415 329.962 51.5001 334.418 57.4767 337.239L77.3087 317.407L72.4102 317.404C65.9688 317.404 60.7463 312.181 60.7463 305.74V192.11L63.8947 190.711V190.707C72.8908 188.172 82.3987 188.082 91.4377 190.438C100.481 192.797 108.735 197.52 115.344 204.126L153.028 241.81L169.594 225.244L131.91 187.56C113.219 169.076 86.3197 161.537 60.7457 167.611V72.4077C60.7457 65.9663 65.9684 60.7437 72.4097 60.7437H305.75C308.843 60.7437 311.808 61.9703 314 64.1578C316.187 66.3492 317.414 69.314 317.414 72.4078V77.3062L337.25 57.466ZM317.414 110.318V273.648L271.68 227.914L271.684 227.918C262.454 219.148 250.336 214.059 237.614 213.609C224.891 213.156 212.442 217.374 202.618 225.464L169.602 258.48L172.637 261.515H172.633C176.305 264.652 180.989 266.351 185.817 266.3C190.466 266.273 194.978 264.714 198.649 261.867L200.751 259.066L214.985 244.832H214.981C220.555 239.539 227.946 236.59 235.633 236.59C243.317 236.59 250.711 239.539 256.281 244.832L314.617 303.168C312.894 307.242 310.051 310.742 306.414 313.262C302.777 315.781 298.5 317.219 294.082 317.399H110.332L87 340.731L294.08 340.734C306.455 340.734 318.326 335.816 327.08 327.066C335.83 318.313 340.748 306.441 340.748 294.066V86.9865L317.414 110.318Z"
                fill="#ffffff"
              />
              <path
                d="M241.579 84.066C226.298 84.0816 211.778 90.7574 201.817 102.347C191.856 113.937 187.438 129.292 189.719 144.406C192 159.515 200.754 172.883 213.696 181.015L230.962 163.749C221.802 160.159 215.067 152.19 213.056 142.558C211.044 132.925 214.025 122.929 220.986 115.972C227.943 109.011 237.939 106.031 247.572 108.042C257.205 110.054 265.174 116.788 268.763 125.948L286.029 108.682C276.435 93.3733 259.646 84.0773 241.58 84.0653L241.579 84.066ZM294.079 133.765L238.778 189.066H241.579C255.493 189.035 268.829 183.496 278.669 173.656C288.509 163.816 294.048 150.48 294.079 136.566C294.138 135.632 294.138 134.699 294.079 133.765L294.079 133.765Z"
                fill="#ffffff"
              />
              <path
                d="M361.66 0L378.16 16.5L16.5 378.16L0 361.66L361.66 0Z"
                fill="#ffffff"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="card__bottom">
        {user && <div className="card__user">{user}</div>}
        <div className="card__info">
          <label>{title}</label>
          <span className="card__name">{subtitle}</span>
          <div className={'card__meta'}>{meta}</div>
          {actions.length > 0 && (
            <div className="card__actions-secondary">
              <NavDropDown>
                <DropdownToggle
                  onClick={(event) => event.stopPropagation()}
                  color="link"
                  className="card__-action dropdown-menu-right"
                >
                  <i className="i-dots" />
                </DropdownToggle>
                <DropdownMenu right>
                  <Fragment>
                    {actions.map((action) => (
                      <DropdownItem
                        key={action.label}
                        onClick={(event) => {
                          event.stopPropagation(); // Stop further delegation of click (eg. the default Playlist card click)
                          action.onClick();
                        }}
                      >
                        {action.label}
                      </DropdownItem>
                    ))}
                  </Fragment>
                </DropdownMenu>
              </NavDropDown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const PosterImage = ({ image, video, cardRef }) => {
  return (
    <HoverVideoPlayer
      className="video-card-player"
      videoSrc={video}
      crossOrigin={null}
      pausedOverlay={
        <div className="pausedoverlay">
          {image && (
            <img
              loading="lazy"
              style={{
                maxWidth: '100%',
                width: '100%',
                height: 'auto',
              }}
              src={image}
            />
          )}
        </div>
      }
      hoverTarget={cardRef}
      style={{
        width: '100%',
        paddingTop: '56.25%',
      }}
      preload="none"
      renderThumbnail={true}
      sizingMode="container"
      loadingOverlay={<div className="loading-overlay"></div>}
    />
  );
};
