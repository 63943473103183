import Popup from '../notifications/popup';
import './style.scss';
import { ButtonGroupSelect } from '../ButtonGroupSelect';
import { useTranslation } from 'react-i18next';

import { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useQuery } from 'react-query';
import api from '../../utils/ApiClient';
import { SubmittingButton } from '../SubmittingButton';
import { Session } from '../../domain/Session';
import ResourceGroupCollection from '../../domain/ResourceGroup';
import { AuthAwareAdapterProxy } from '../../utils/AuthAwareAdapterProxy';

import doNotDisturbPaths from '../DoNotDisturbPaths';
import { GraphicSelector } from './graphics/graphicsSelector';
import moment from 'moment';
import { sendGA4Event } from '../../utils/ga4';

export const UpgradePopup = (props) => {
  const [activePlan, setActivePlan] = useState('prod_M6VawgYVcjpbtb');
  const [interval, setInterval] = useState('yearly');
  const [planId, setPlanId] = useState('yearly');
  const [link, setLink] = useState(null);
  const { t, i18n } = useTranslation('upgradeModal');

  const { currentProducts } = props;

  const { data: productData, isFetched: productsFetched } = useQuery(
    ['productdata', i18n.language],
    async () => {
      const { data } = await api.get(
        `anonymous/productData/product/${i18n.language}`
      );
      return data;
    },
    { refetchOnMount: false }
  );
  const { data: catalog, isFetched: catalogFetched } = useQuery(
    ['catalogData'],
    async () => {
      const { data } = await api.get('anonymous/catalog');
      return data;
    },
    { refetchOnMount: false }
  );
  let plans = [];
  if (productsFetched && catalogFetched) {
    plans.push({
      ...productData.find(
        (p) => p.field_product_id.value === 'prod_M6VawgYVcjpbtb'
      ),
      ...catalog.find(
        (stripeProduct) => stripeProduct.productId === 'prod_M6VawgYVcjpbtb'
      ),
    });
    if (!currentProducts.includes('Basic')) {
      plans.push({
        ...productData.find(
          (p) => p.field_product_id.value === 'prod_M6VVmPpGiMU0vR'
        ),
        ...catalog.find(
          (stripeProduct) => stripeProduct.productId === 'prod_M6VVmPpGiMU0vR'
        ),
      });
    }
  }

  const mustHidePopup =
    !props.isOpen ||
    !props.session.hasClubAdminPrivileges() ||
    doNotDisturbPaths(props.location.pathname) ||
    !productsFetched ||
    !catalogFetched ||
    plans.length === 0;

  useEffect(() => {
    // if (mustHidePopup) {
    //   return;
    // }
    setLink(null);
    if (!mustHidePopup) {
      (async () => {
        const planId = getPricingPlanId(
          plans.find((p) => p.field_product_id.value === activePlan),
          interval
        );
        let resourceGroup;
        setPlanId(planId);
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();
        resourceGroup = ResourceGroupCollection.build({
          resourceGroupId: adminResourceGroupId,
        });

        AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          async () => {
            const resourceGroup = new (ResourceGroupCollection.model())({
              resourceGroupId: adminResourceGroupId,
            });
            resourceGroup.collection = ResourceGroupCollection;

            const cancelUrl =
              window.location.origin +
              '/apps/launch/store/catalog/cancelled/' +
              planId;
            const successUrl =
              window.location.origin + '/apps/launch/store/success/' + planId;
            let query = `priceId=${planId}&successUrl=${successUrl}&cancelUrl=${cancelUrl}`;
            const { data: _link } = await api.get(
              `store/createCheckoutPortal?${query}`
            );
            setLink(_link);
          }
        );
      })();
    }
  }, [mustHidePopup, activePlan, interval, productsFetched, catalogFetched]);

  // do not distrub paths or not eligable to upgrade or popup should be closed
  if (mustHidePopup) {
    return null;
  }

  const goToUpgradePage = () => {
    Session.current().user.logAction('purchaseStarted', {
      component: 'Lib:upgradePopup',
      url: window.location.href,
      product: `${planId}|${
        props.availableProducts[activePlan] ?? activePlan
      }|${interval}`,
    });
    sendGA4Event(
      'purchase_started',
      'Store',
      'Started',
      window.isSkillReflect ? 'SR' : 'TTV',
      `${planId}|${
        props.availableProducts[activePlan] ?? activePlan
      }|${interval}`,
      window.location.href
    );
    window.open(link, '_blank');
    props.onClose();
  };

  const options = [
    {
      label: (
        <div className={'select--yearly'}>
          <div className="name">{t('yearly')}</div>
          <span className="pill">
            €{' '}
            {Math.round(
              (getProductPrice(
                plans.find((p) => p.field_product_id.value === activePlan),
                'monthly'
              ) *
                2) /
                100
            )}{' '}
            {t('discount')}
          </span>
        </div>
      ),
      value: 'yearly',
    },
    { label: t('monthly'), value: 'monthly' },
  ];

  return (
    <Popup
      data={{ style: 'dark' }}
      noPadding
      className={'popup--upgrade-popup'}
      onCloseCallback={props.onClose}
      onOpenCallback={props.onOpenPopup}
    >
      {({ closePopup }) => (
        <div className="upgrade-popup">
          <div className="upgrade-popup__top">
            <GraphicSelector
              availableCategories={
                currentProducts.includes('Basic') ? ['Pro'] : ['Basic', 'Pro']
              }
            />
          </div>
          <div className="upgrade-popup__bottom">
            <div>
              <h4>{t('title')}</h4>

              {plans.map((plan, index) => {
                const isActive = plan.field_product_id.value === activePlan;
                return (
                  <ProductButton
                    key={plan.field_product_id.value}
                    product={plan}
                    onClick={() => setActivePlan(plan.field_product_id.value)}
                    active={isActive}
                    interval={interval}
                  />
                );
              })}
              <div className={'upgrade-popup__pricing'}>
                <ButtonGroupSelect
                  options={options}
                  value={interval}
                  radio={true}
                  onChange={(v) => setInterval(v)}
                ></ButtonGroupSelect>
              </div>

              {plans.map((plan, index) => {
                const isActive = plan.field_product_id.value === activePlan;
                return (
                  <div key={plan.field_product_id.value}>
                    {isActive && (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: plan.field_short_description.value,
                          }}
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="upgrade-popup__actions">
              <button
                className={'btn btn-primary upgradebutton'}
                onClick={goToUpgradePage}
                disabled={!link}
              >
                {t('checkout', {
                  product: props.availableProducts[activePlan],
                })}
              </button>
              <a
                onClick={closePopup}
                href={'/apps/launch/store'}
                className={'btn btn-link'}
                target={'_blank'}
                rel={'noopener nofollow'}
              >
                {t('showMore')}
              </a>
              <button
                className={'btn btn-secondary btn-muted'}
                onClick={closePopup}
              >
                {t('cancel')}
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

const TrialDays = ({ trial, t }) => {
  const start = moment(trial.startDate);
  const end = moment(trial.endDate);
  const remaining = end.diff(start, 'days');
  return <span>{t('trialDays', { days: remaining })}</span>;
};

const getProductPrice = (product, interval) => {
  const pricingPlan = product.pricingPlans.find((p) => {
    return p.name === interval;
  });
  const price = pricingPlan.amount / (interval === 'yearly' ? 12 : 1);
  return price;
};

const getPricingPlanId = (product, interval) => {
  const pricingPlan = product.pricingPlans.find((p) => {
    return p.name === interval;
  });
  return pricingPlan.planId;
};

export const ProductButton = ({ product, onClick, active, interval }) => {
  const price = getProductPrice(product, interval);
  const { t } = useTranslation('upgradeModal');
  return (
    <div onClick={onClick} className={`productbutton ${active && 'is-active'}`}>
      <div className="radio"></div>
      <div className={'font-heavy'}>TeamTV {product.title.value}</div>
      <div className="price">
        € {Math.round(price / 100)} <span className="muted">/{t('month')}</span>
        <div>
          <span className="mini">{t(`billed${interval}`)}</span>
        </div>
      </div>
    </div>
  );
};

export const UpgradeNotificationContainer = withRouter(
  // Use router in notificationspaths
  UpgradePopup
);
