import React, { Component } from 'react';
import { runInAction, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';
// import { withTranslation } from 'react-i18next';
import logger from 'utils/Logger';
// import Select from 'react-select';

import { IntroView } from './intro-view';
import { SyncTagView } from './sync-tag-view';
import { ConfirmView } from './confirm-view';

import './index.scss';
import { Modal } from 'lib/Modal';
import { TimeMapper, VideoClock } from 'domain/ObservationLogger/Clock';
import { Command, CommandBus } from 'infra/Messaging';
import { withTranslation } from 'react-i18next';
import { getOrdinalPeriod } from './get-ordinal-period';

const DataSyncView = withTranslation('module.dataSync')(
  observer(
    class DataSyncView extends Component {
      static defaultProps = {
        onCancel: console.warn,
      };

      constructor(props) {
        super(props);

        this.activeItem = observable(0);
        this.state = {
          viewIdx: 0,
          unSyncedLiveObservations: 0,
          cancelled: false,
        };

        this.multiVideo =
          this.props.observationContext.sportingEvent.multiVideo();

        this.periodStarts = {};
      }

      componentDidMount() {
        const { observationContext } = this.props;

        const mappingStats =
          observationContext.observationLog.getMappingStats();
        if (mappingStats.failures['U1'] > 0 && !mappingStats.success['U1']) {
          console.warn('Should be mapping!');
          this.setState({
            viewIdx: 0,
            cancelled: false,
            unSyncedLiveObservations: mappingStats.failures['U1'],
          });
          observationContext.clock.stop();
        }
      }

      showNextView = () => {
        this.setState({
          ...this.state,
          periodNr: 1,
          viewIdx: this.state.viewIdx + 1,
        });
      };

      goBackView = () => {
        this.setState({
          ...this.state,
          viewIdx: this.state.viewIdx - 1,
        });
      };

      setPeriodStart(period, time) {
        this.periodStarts[period] = time;
      }

      getClock() {
        const syncPoints = [];
        if (typeof this.periodStarts[1] !== 'undefined') {
          syncPoints.push({
            type: 'START_PERIOD',
            key: '1',
            time: this.periodStarts[1],
          });
        }
        if (typeof this.periodStarts[2] !== 'undefined') {
          syncPoints.push({
            type: 'START_PERIOD',
            key: '2',
            time: this.periodStarts[2],
          });
        }

        return new VideoClock(
          this.props.observationContext.clock.clockId(),
          syncPoints
        );
      }

      getEstimatedStartPeriod1() {
        return 0;
      }

      getEstimatedStartPeriod2() {
        return (
          this.periodStarts[1] +
          this.props.observationContext.getPeriodLength(1)
        );
      }

      getEstimatedStartPeriod3() {
        return (
          this.periodStarts[2] +
          this.props.observationContext.getPeriodLength(2)
        );
      }

      getEstimatedStartPeriod4() {
        return (
          this.periodStarts[3] +
          this.props.observationContext.getPeriodLength(3)
        );
      }

      addSyncPoints() {
        const commands = [];
        for (const key in this.periodStarts) {
          commands.push(
            Command.create('SportingEvent.AddSynchronizationPoint', {
              sportingEventId: this.props.observationContext.sportingEvent.id,
              clockId: this.props.observationContext.clock.clockId(),
              type: 'START_PERIOD',
              key,
              time: this.periodStarts[key],
            })
          );
        }
        CommandBus.dispatch(commands);

        this.props.onSynced && this.props.onSynced();
      }

      getObservationsToVerify(period) {
        const { observationContext } = this.props;

        const projection = TimeMapper.mapObservations(
          observationContext.observationLog.getRawObservations(),
          this.getClock(),
          observationContext.sportingEvent
        );

        const thisPeriodStart = projection.projectedObservations.findIndex(
          (observation) => {
            if (observation.code.includes('TIME:START-PERIOD')) {
              return parseInt(observation.description.match(/\d/g)) === period;
            }
          }
        );

        const thisPeriodEnd = projection.projectedObservations.findIndex(
          (observation) => {
            if (observation.code.includes('TIME:END-PERIOD')) {
              return parseInt(observation.description.match(/\d/g)) === period;
            }
          }
        );

        let goalObservations;

        if (thisPeriodStart >= 0 && thisPeriodEnd > 0) {
          goalObservations = projection.projectedObservations
            .slice(thisPeriodStart, thisPeriodEnd + 1) // Show only from current period.
            .filter((observation) => {
              return (
                observation.startTime > this.periodStarts[period] &&
                ((observation.code === 'SHOT' &&
                  observation.get('attributes').result === 'GOAL') ||
                  observation.code === 'GOAL')
              );
            })
            .slice(0, 5);
        } else {
          goalObservations = projection.projectedObservations
            .filter((observation) => {
              return (
                observation.startTime > this.periodStarts[period] &&
                ((observation.code === 'SHOT' &&
                  observation.get('attributes').result === 'GOAL') ||
                  observation.code === 'GOAL')
              );
            })
            .slice(0, 5);
        }

        let observations, tagName;
        if (goalObservations.length > 0) {
          observations = goalObservations;
          tagName = 'goals';
        } else {
          observations = [
            {
              description: this.props.t('syncTagButtonText', { period }),
              startTime: this.periodStarts[period],
              endTime: this.periodStarts[period] + 10,
            },
          ];
          tagName = 'Start';
        }
        return [observations, tagName];
      }

      handleSubmit(time) {
        if (this.state.viewIdx === 0) {
          // intro view
          this.showNextView();
        } else if (this.state.viewIdx % 2) {
          // sync view
          this.setPeriodStart(this.state.periodNr, time);
          this.setState((state) => ({
            ...state,
            viewIdx: 2,
          }));
        } else {
          // confirm view
          if (
            this.state.periodNr === this.props.observationContext.periodCount
          ) {
            // last period confirmed
            this.addSyncPoints();
            this.setState((state) => ({
              ...state,
              // getView() returns null & hides modal
              viewIdx: 3,
            }));
          } else {
            // sync next period
            this.setState((state) => ({
              ...state,
              viewIdx: 1,
              periodNr: state.periodNr + 1,
            }));
          }
        }
      }

      getView() {
        switch (this.state.viewIdx) {
          case 0:
            return (
              <IntroView
                itemCount={this.state.unSyncedLiveObservations}
                onSave={() => this.handleSubmit()}
                onCancel={() => this.onCancel()}
              />
            );
          case 1:
            return (
              <SyncTagView
                video={this.props.video}
                multiCam={this.multiVideo}
                onSave={(time) => this.handleSubmit(time)}
                onCancel={() => this.onCancel()}
                startTime={this[
                  `getEstimatedStartPeriod${this.state.periodNr}`
                ]()}
                periodCount={this.props.observationContext.periodCount}
                period={this.state.periodNr}
              />
            );
          case 2: {
            const [observations, tagName] = this.getObservationsToVerify(
              this.state.periodNr
            );
            const { period: _period, periodCount: _periodCount } =
              getOrdinalPeriod(
                this.props.t,
                this.state.periodNr ?? 0,
                this.props.observationContext.periodCount
              );
            return (
              <ConfirmView
                video={this.props.video}
                observations={observations}
                tagName={tagName}
                onSave={() => this.handleSubmit()}
                onGoBack={this.goBackView}
                onCancel={() => this.onCancel()}
                multiCam={this.multiVideo}
                period={this.state.periodNr}
                periodCount={this.props.observationContext.periodCount}
                confirmText={this.props.t(
                  this.state.periodNr ===
                    this.props.observationContext.periodCount
                    ? 'confirmTagSave'
                    : `confirmPeriod`,
                  {
                    period: _period,
                    periodCount: _periodCount,
                  }
                )}
              />
            );
          }
          case 3:
            return null;
          default:
            logger.warning(`Dont have a view for index ${this.state.viewIdx}`);
            return null;
        }
      }

      onCancel() {
        this.setState({
          ...this.state,
          cancelled: true,
        });
      }

      render() {
        if (this.state.unSyncedLiveObservations === 0 || this.state.cancelled) {
          return null;
        }

        const view = this.getView();
        if (view === null) {
          return null;
        }

        return (
          <Modal size="lg" isOpen={true} onCloseClick={() => this.onCancel()}>
            {this.getView()}
          </Modal>
        );
      }
    }
  )
);

export { DataSyncView };
