import React, { useState, useEffect } from 'react';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';

import {
  VideoObservationPanel,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';

const TrainingObservationInput = ({ observationContext, args }) => {
  const { observationLogger } = observationContext;
  return (
    <VideoObservationPanel
      observationContext={observationContext}
    ></VideoObservationPanel>
  );
};

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'training.default',
  component: TrainingObservationInput,
  options,
  title: 'Training',
  usedKeys: [],
});
