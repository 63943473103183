import { asyncAction } from 'mobx-utils';
import { runInAction, observable } from 'mobx';

import Handler from './Handler';
import { apiClient } from 'mobx-rest';
import { RemoteCommandDispatcher } from '../RemoteCommandDispatcher';
import { Session } from '../../domain/Session';

class CommandHandler extends Handler {
  static handlers = [];

  constructor() {
    super();

    CommandHandler.addHandler(this);
  }
}

class RemoteCommandHandler extends CommandHandler {
  addUnitOfWork(command, requests) {
    RemoteCommandDispatcher.instance().dispatch(command, requests);
  }

  getResourceGroupId() {
    const currentSession = Session.current();
    return currentSession.resourceGroupId();
  }

  createRpcRequest(rootAggregate, methodName, body) {
    return {
      method: 'post',
      path: `${rootAggregate.url()}/${methodName}`,
      body: body || {},
      resourceGroupId: this.getResourceGroupId(),
    };
  }

  createAddRequest(collection, body) {
    return {
      method: 'POST',
      path: collection.url(),
      body: body,
      resourceGroupId: this.getResourceGroupId(),
    };
  }

  createAddWithIdRequest(rootAggregate, body) {
    return {
      method: 'PUT',
      path: rootAggregate.url(),
      body: body,
      resourceGroupId: this.getResourceGroupId(),
    };
  }

  createUpdateRequest(rootAggregate, body) {
    return {
      method: 'PATCH',
      path: rootAggregate.url(),
      body: body,
      resourceGroupId: this.getResourceGroupId(),
    };
  }

  createDeleteRequest(rootAggregate) {
    return {
      method: 'DELETE',
      path: rootAggregate.url(),
      resourceGroupId: this.getResourceGroupId(),
    };
  }
}

class LocalCommandHandler extends CommandHandler {
  _doHandle(command) {
    return runInAction(() => this.doHandle(command));
  }
}

export { CommandHandler, LocalCommandHandler, RemoteCommandHandler };
