import React from 'react';
import { observer } from 'mobx-react';

const formatTime = (time) => {
  const seconds = Math.floor(parseFloat(time)).toFixed(0);
  let s = seconds % 60;
  let m = (seconds - s) / 60;
  //let h = parseInt(seconds / 3600);
  //h += ""; if (h.length == 1) h = "0" + h;
  m += '';
  if (m.length === 1) m = '0' + m;
  s += '';
  if (s.length === 1) s = '0' + s;
  return `${m}:${s}`;
};

// https://github.com/moment/moment/issues/2781#issuecomment-416499369
const timeSince = function (date) {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  const seconds = Math.floor((new Date() - date) / 1000);
  let intervalType;

  let interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = 'j';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'm';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'd';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = 'u';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = 'm';
          } else {
            interval = 0;
            intervalType = 'now';
          }
        }
      }
    }
  }
  //
  // if (interval > 1 || interval === 0) {
  //   intervalType += 's';
  // }

  return `${interval ? interval + ' ' : ''}${intervalType}`;
};

const FormattedTime = ({ time }) => {
  const seconds = Math.floor(parseFloat(time || 0)).toFixed(0);
  let s = seconds % 60;
  let m = (seconds - s) / 60;
  //let h = parseInt(seconds / 3600);
  //h += ""; if (h.length == 1) h = "0" + h;
  m += '';
  if (m.length == 1) m = '0' + m;
  s += '';
  if (s.length == 1) s = '0' + s;
  return (
    <span>
      {m}
      <span className="separator">:</span>
      {s}
    </span>
  );
};

const FormattedTimeObserver = observer(({ timeFn }) => {
  const time = timeFn();
  if (typeof time === 'string') {
    return time;
  }
  return <FormattedTime time={time} />;
});

export { FormattedTimeObserver, FormattedTime, formatTime, timeSince };
