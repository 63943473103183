import {
  computed,
  observable,
  extendObservable,
  runInAction,
  when,
} from 'mobx';
import mobxLocalStorage from 'mobx-localstorage';
import auth0 from 'auth0-js';

import history from './History';
import { EventPublisher } from 'utils/EventPublisher';
import logger from 'utils/Logger';
import { apiClient } from 'mobx-rest';
import { AuthAwareAdapterProxy } from 'utils/AuthAwareAdapterProxy';
import sweetAlert from '../lib/sweetAlert';
import i18next from 'i18next';
import { isDev } from '../lib/dev';

const wait = async (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

class Auth {
  userProfile;
  tokenRenewalTimeout;

  auth0 = new auth0.WebAuth({
    domain: 'teamtv.eu.auth0.com',
    clientID: 'xAF86uR2cH4ute1zbrt21IL6UaLxq6qB',
    redirectUri: `${window.location.origin}/callback`,
    audience: 'platform', //https://teamtv.eu.auth0.com/userinfo',
    responseType: 'token id_token',
    scope: 'openid',
    autoParseHash: false,

    // https://github.com/googleapis/google-api-php-client/issues/1630
    leeway: 300,
  });

  constructor() {
    this.login = this.login.bind(this);
    this.inAppLogin = this.inAppLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  inAppLogin(options = {}, callback = () => {}) {
    return this.auth0.login(options, callback);
  }

  inAppPasswordReset(options = {}, callback = () => {}) {
    return this.auth0.changePassword(options, callback);
  }

  handleAuthentication() {
    console.log('handling....');

    return new Promise(async (success, error) => {
      await this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          return success();
          //history.replace('/home');
        } else if (err) {
          this.logout(null);
          logger.error(err, {
            transactionName: 'Unable to set session correctly',
            hasAuthResult: authResult !== undefined,
            hasAccessToken: authResult?.accessToken !== undefined,
            hasIdToken: authResult?.idToken !== undefined,
          });
          return error();
        }
        logger.error(err, {
          transactionName: 'Unable to set session, no error given',
          hasAuthResult: authResult !== undefined,
          hasAccessToken: authResult?.accessToken !== undefined,
          hasIdToken: authResult?.idToken !== undefined,
        });
        this.logout(null);
      });
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    try {
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);
    } catch (e) {
      console.error('Error in setSession():', e);
    }

    // schedule a token renewal
    this.scheduleRenewal();

    // navigate to the home route
    //history.replace('/home');
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      sweetAlert({
        title: i18next.t('common:sessionExpired'),
        text: i18next.t('common:returnToLogin'),
      }).then(() => {
        this.logout();
      });

      throw new Error('No access token found');
    }
    return accessToken;
  }

  getAccessTokenString() {
    return localStorage.getItem('access_token') || '';
  }

  logout(returnTo) {
    // Clear access token and ID token from local storage
    //this.auth0.logout();

    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('scopes');
    clearTimeout(this.tokenRenewalTimeout);
    // navigate to the home route
    //history.replace('/home');
    if (returnTo !== null) {
      // SUPER UGLY HACK to prevent loop from checkSession
      this.auth0.logout(returnTo);
    }
  }

  isAuthenticated() {
    if (typeof localStorage.getItem('expires_at') !== 'undefined') {
      let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      return new Date().getTime() < expiresAt;
    } else {
      return false;
    }
  }

  renewToken() {
    return new Promise((success, error) => {
      this.auth0.checkSession({}, (err, result) => {
        if (err) {
          this.logout(null);
          setIsAuthenticated(false);
          console.warn(
            `Could not get a new token (${err.error}: ${err.error_description}).`
          );
          error();
          // window.location.reload();
        } else {
          setIsAuthenticated(true);
          this.setSession(result);
          success();
        }
      });
    });
  }

  scheduleRenewal() {
    const SAFETY_MARGIN = 1800 * 1000; // make sure we fetch a new token 30 minutes before it expires
    if (typeof localStorage.getItem('expires_at') !== 'undefined') {
      if (localStorage.getItem('expires_at') === null) {
        return;
      }
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      const delay = Math.max(0, expiresAt - Date.now() - SAFETY_MARGIN);
      if (delay >= 0) {
        clearTimeout(this.tokenRenewalTimeout);

        this.tokenRenewalTimeout = setTimeout(() => {
          this.renewToken();
        }, delay);
      }
    }
  }
}

function setIsAuthenticated(isAuthenticated) {
  runInAction(() => {
    mobxLocalStorage.setItem('isAuthenticated', isAuthenticated);
  });
}

class Authentication {
  constructor() {
    this.auth = new Auth();
    if (!this.auth.isAuthenticated()) {
      // make sure we don't think we are authenticated when we are not
      setIsAuthenticated(false);
    }

    window._auth = this;
    extendObservable(this, {
      get isAuthenticated() {
        return mobxLocalStorage.getItem('isAuthenticated');
      },
    });
  }

  init() {
    this.auth.scheduleRenewal();
  }

  async login(currentPage) {
    try {
      await this.auth.renewToken(false);
      history.replace(currentPage);
    } catch (e) {
      localStorage.setItem('afterLoginPage', currentPage);
      this.auth.login();
    }
    // await wait(1500);
    // if (username === "koen") {
    //    return false;
    // } else {
    //     runInAction(() => {
    //         localStorage.set("isAuthenticated", true);
    //     });
    //     //this.session.set("isAuthenticated", true);
    //     return true;
    // }
  }

  handleAuthentication(location) {
    this.auth.handleAuthentication().then(
      () => {
        const previousPage = localStorage.getItem('afterLoginPage') || '/';
        localStorage.removeItem('afterLoginPage');
        runInAction(() => {
          setIsAuthenticated(this.auth.isAuthenticated());
          history.replace(previousPage);
        });

        EventPublisher.dispatch('LOGGED_IN');
      },
      () => {
        alert('fout met inloggen');
        setIsAuthenticated(false);
        // Force login screen.
        this.logout();
      }
    );
  }

  handleInAppLogin(options = {}, callback = () => {}) {
    return this.auth.inAppLogin(options, callback);
  }

  handleInAppPasswordReset(options = {}, callback = () => {}) {
    return this.auth.inAppPasswordReset(options, callback);
  }

  async setTokenFromLoginTokenCode(code) {
    let token;
    try {
      token = await AuthAwareAdapterProxy.withSkipAuthentication(async () => {
        const { promise, abort } = apiClient().post(
          '/anonymous/consumeOneTimeLoginToken',
          {
            code,
          }
        );
        return await promise;
      });
    } catch (e) {
      alert('Invalid code');
      throw e;
    }
    this.auth.setSession({
      expiresIn: 3600 * 24,
      idToken: '',
      accessToken: token,
    });
    setIsAuthenticated(true);
  }

  setToken(token) {
    this.auth.setSession({
      expiresIn: 3600 * 24,
      idToken: '',
      accessToken: token,
    });
    setIsAuthenticated(true);
  }

  getAccessToken() {
    return this.auth.getAccessToken();
  }

  isEmbed() {
    const token = this.auth.getAccessTokenString();
    if (!token) {
      return false;
    }
    const [head, body, sign] = token.split('.');
    const tokenBody = JSON.parse(atob(body));
    return !!tokenBody.issuerUserId;
  }

  logout() {
    this.auth.logout({ returnTo: `${window.origin}/` });
    // setIsAuthenticated(false);
  }
}

export default new Authentication();
