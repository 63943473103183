import React, { Component } from 'react';
import { withToggle } from 'lib/hocs/withToggle';

export const withVisibleToggle = WrappedComponent => {
  return withToggle(
    class extends Component {
      constructor() {
        super();
        this.targetRef = React.createRef();
        this.toggleRef = React.createRef();
        this.lastKeyDownTS = 0;
      }

      componentDidMount() {
        document.addEventListener('mousedown', this.maybeToggle);
        window.addEventListener('blur', this.lostFocus);
        window.addEventListener('keydown', this.lastKeyDown);
        this.toggleTarget();
      }

      componentWillUnmount() {
        document.removeEventListener('mousedown', this.maybeToggle);
        window.removeEventListener('blur', this.lostFocus);
        window.removeEventListener('keydown', this.lastKeyDown);
      }

      componentDidUpdate(prevProps) {
        if (prevProps.isToggled !== this.props.isToggled) {
          this.toggleTarget();
        }
      }

      lastKeyDown = e => {
        this.lastKeyDownTS = e.timeStamp;
      };

      lostFocus = e => {
        if (this.props.isToggled) {
          // this fixes ctrl-f when you want to search in something toggled
          if (e.timeStamp - this.lastKeyDownTS > 100) {
            this.props.toggle();
          }
        }
      };

      /**
       * Toggles when:
       *   - toggled & clicked outside of target
       *   - not toggled & clicked on toggleRef
       */
      maybeToggle = event => {
        if (
          (this.props.isToggled &&
            !this.targetRef.current.contains(event.target)) ||
          (!this.props.isToggled &&
            this.toggleRef.current.contains(event.target))
        ) {
          this.props.toggle();
        }
      };

      toggleTarget = () => {
        const hiddenClass = 'd-none';

        this.props.isToggled
          ? this.targetRef.current.classList.remove(hiddenClass)
          : this.targetRef.current.classList.add(hiddenClass);
      };

      render() {
        return (
          <WrappedComponent
            targetRef={this.targetRef}
            toggleRef={this.toggleRef}
            {...this.props}
          />
        );
      }
    }
  );
};
