import React from 'react';
import MediaQuery from 'react-responsive';

interface Set {
  [0]: number;
  [1]: number;
}

export const SportingEventSetsScore = ({ sets }: { sets: Set[] }) => (
  <MediaQuery query="(min-width: 1024px)">
    {(matches: boolean) => {
      if (matches) {
        return <SetDetailsScore sets={sets} />;
      } else {
        return <SetTotalsScore sets={sets} />;
      }
    }}
  </MediaQuery>
);

const SetTotalsScore = ({ sets }: { sets: Set[] }) => {
  const totals = sets.reduce(
    (acc, cur) => {
      return cur[0] > cur[1]
        ? { ...acc, home: acc.home + 1 }
        : { ...acc, away: acc.away + 1 };
    },
    { home: 0, away: 0 }
  );

  return (
    <span>
      {totals.home} – {totals.away}
    </span>
  );
};

const SetDetailsScore = ({ sets }: { sets: Set[] }) =>
  sets
    .map((set, idx) => (
      <>
        <span key={idx}>
          {set[0]}–{set[1]}
        </span>
      </>
    ))
    .reduce((prev, curr) => [prev, ' / ', curr]);
