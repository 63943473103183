import React, { Fragment, Component } from 'react';

import { observer } from 'mobx-react';
import { observable, action, runInAction } from 'mobx';

import CustomTagsCollection from 'domain/CustomTag/CustomTag';
import {
  observationInputConfigurationCollection,
  ObservationInputConfiguration,
} from 'domain/ObservationInputConfiguration';
import { CreateCustomTag } from './CreateCustomTag';
import { withTranslation } from 'react-i18next';

import './index.scss';

const SelectCustomTags = withTranslation('module.observe.video')(
  observer(
    ({
      observationInputConfiguration,
      onCreateCustomTag,
      onSave,
      onDone,
      t,
    }) => {
      return (
        <div className="manage-custom-tags-view">
          <div className="modal-header">
            <h5 className="modal-title">{t('titleTeamTagsConfig')}</h5>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-8">
                <p>{t('standardTagsVisibile')}</p>
              </div>
              <div className="col-4">
                <input
                  type="checkbox"
                  defaultChecked={observationInputConfiguration.getStandardTagsVisibility()}
                  onChange={e => {
                    observationInputConfiguration.setStandardTagsVisibility(
                      e.target.checked
                    );
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p>{t('chooseTeamTags')}</p>
              </div>
              {/*<div className="col-4 text-right">*/}
              {/*<button className="button button-link">*/}
              {/*Teamtags bewerken <i className="i-dots"/>*/}
              {/*</button>*/}
              {/*</div>*/}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="labels">
                  {CustomTagsCollection.sorted().map(customTag => {
                    const customTagSelected = observationInputConfiguration.contains(
                      customTag.id
                    );

                    return (
                      <div
                        key={customTag.id}
                        className={`label clickable ${
                          customTagSelected ? 'active' : ''
                        }`}
                        onClick={() => {
                          customTagSelected
                            ? observationInputConfiguration.removeCustomTag(
                                customTag.id
                              )
                            : observationInputConfiguration.addCustomTag(
                                customTag.id
                              );
                        }}
                      >
                        {customTag.description}{' '}
                        <span className="button-hotkey">{customTag.key}</span>
                      </div>
                    );
                  })}
                  <div
                    className="label label-link label-i-lft clickable"
                    onClick={onCreateCustomTag}
                  >
                    <span className="i-add" /> {t('addTeamTag')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="form-actions">
              <button className="btn btn-primary" onClick={onSave}>
                {t('saveSettings')}
              </button>
              <button className="btn btn-link" onClick={onDone}>
                {t('common:cancel')}
              </button>
            </div>
          </div>
        </div>
      );
    }
  )
);

const ManageCustomTags = observer(
  class ManageCustomTags extends Component {
    constructor() {
      super();

      this.tab = observable('select');

      this.observationInputConfiguration = new ObservationInputConfiguration(
        'main'
      );
      this.loadObservationInputConfiguration();
    }

    loadObservationInputConfiguration() {
      let observationInputConfiguration = observationInputConfigurationCollection.observationInputConfigurationById(
        'main'
      );
      if (typeof observationInputConfiguration !== 'undefined') {
        this.observationInputConfiguration = observationInputConfiguration;
      }
    }

    setTab = action(tab => {
      this.tab.set(tab);
    });

    onSave() {
      runInAction(() => {
        observationInputConfigurationCollection.save(
          this.observationInputConfiguration
        );
      });
      this.props.onDone();
    }

    render() {
      switch (this.tab.get()) {
        case 'select':
          return (
            <SelectCustomTags
              observationInputConfiguration={this.observationInputConfiguration}
              onSave={() => this.onSave()}
              onDone={() => this.props.onDone()}
              onCreateCustomTag={() => this.setTab('create')}
            />
          );
        case 'create':
          return (
            <CreateCustomTag
              observationInputConfiguration={this.observationInputConfiguration}
              onCustomTagAdded={customTagId => {
                this.observationInputConfiguration.addCustomTag(customTagId);
                this.setTab('select');
              }}
              onCancel={() => this.setTab('select')}
            />
          );
      }
    }
  }
);

export { ManageCustomTags };
