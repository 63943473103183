import { registry as observationInputRegistry } from 'utils/ObservationInput';
import { Session } from 'domain/Session';

import CustomTagCollection from './CustomTag';

class CustomTagManager {
  static getObservationInputKeys() {
    const observationInputName = Session.current().getDefaultObservationInputName();
    if (!observationInputName) {
      return [];
    }
    const observationInput = observationInputRegistry.getObservationInputByName(
      observationInputName
    );
    return observationInput.usedKeys;
  }

  static getAvailableKeys(standardTagsInUse = false) {
    const keys = '0123456789abcdefghijklmnopqrstuvwxyz'.split('');
    const usedStandardTags = this.getObservationInputKeys();
    const usedCustomTags = CustomTagCollection.toArray().map(
      customTag => customTag.key
    );

    return keys.filter(
      key =>
        (!standardTagsInUse || usedStandardTags.indexOf(key) === -1) &&
        usedCustomTags.indexOf(key) === -1
    );
  }

  static async create(values) {
    return CustomTagCollection.create(values, { optimistic: false });
  }

  static getCustomTags() {
    return CustomTagCollection.sorted();
  }

  static async fetchIfEmpty() {
    return CustomTagCollection.fetchIfEmpty();
  }
}

export { CustomTagManager };
