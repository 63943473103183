import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';

import { Progress } from 'reactstrap';
import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';
import { Modal } from 'lib/Modal';

import { LearningLine } from '../../components/learning-line';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import PersonCollection from 'domain/Person';
import TeamCollection from 'domain/Team';

import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, NavLink } from 'react-router-dom';
import { MatchOverviewItem } from '../../../match/components/MatchOverviewItem';
// import { GrowthAmbitionCreateSelectPeriod } from './select-period';
// import { GrowthAmbitionCreateSelectLearningIndicators } from './select-learning-indicators';
import { AddFragmentToLearningIndicator } from './add-fragment-to-learning-indicator';

import noGrowthAmbitionImg from 'img/no_growthambition.svg';

import SportingEventCollection from 'domain/SportingEvent';
import { OverviewItem } from '../../../../lib/OverviewItem';
import { VideoFragmentsPlayer } from 'lib/VideoFragmentsPlayer';
import { gotoPath } from 'modules/route';
import { Error, NoResults } from 'lib/PlaceHolder';

// import './index.scss';

// const VideoFragmentPlayerModal = observer(({isOpen, videoFragments}) => {
//   if (!isOpen.get()) {
//     return null;
//   }
//
//
// });
//
// const VideoFragmentPlayer = ({children, vidoFragments}) => {
//   return (e
//     <div onClick=""   />;
//   );
// };

const addVideoFragmentRating = (videoFragment, ratings) => {
  const rating = videoFragment.rating;

  if (typeof ratings[rating] === 'undefined') {
    ratings[rating] = [];
  }
  ratings[rating].push(videoFragment);
};

const groupVideoFragmentsPerSportingEventPerRating = (videoFragments) => {
  const videoFragmentsPerSportingEventPerRating = {};
  for (const videoFragment of videoFragments) {
    if (!videoFragment.metadata.sportingEvent) {
      continue;
    }

    const sportingEventId =
      videoFragment.metadata.sportingEvent.sportingEventId;
    if (!sportingEventId) {
      continue;
    }

    if (
      typeof videoFragmentsPerSportingEventPerRating[sportingEventId] ===
      'undefined'
    ) {
      videoFragmentsPerSportingEventPerRating[sportingEventId] = {};
    }

    addVideoFragmentRating(
      videoFragment,
      videoFragmentsPerSportingEventPerRating[sportingEventId]
    );
  }
  const groupedVideoFragments = [];
  for (const sportingEventId of Object.keys(
    videoFragmentsPerSportingEventPerRating
  )) {
    const sportingEvent = SportingEventCollection.get(sportingEventId);
    if (!sportingEvent) {
      console.warn(`Unknown sportingEvent ${sportingEventId}`);
      continue;
    }
    groupedVideoFragments.push({
      sportingEvent,
      ratings: videoFragmentsPerSportingEventPerRating[sportingEventId],
    });
  }

  groupedVideoFragments.sort(({ sportingEvent: a }, { sportingEvent: b }) => {
    return a.get('scheduledAt') === b.get('scheduledAt')
      ? 0
      : a.get('scheduledAt') > b.get('scheduledAt') ||
        b.get('scheduledAt') === false
      ? -1
      : 1;
  });

  return groupedVideoFragments;
};

const groupVideoFragmentsPerRating = (videoFragments) => {
  const groupedVideoFragments = {};
  for (const videoFragment of videoFragments) {
    addVideoFragmentRating(videoFragment, groupedVideoFragments);
  }
  return groupedVideoFragments;
};

const GrowthAmbitionMonitor = withTranslation(
  'module.development.growthAmbition.monitor'
)(
  observer(
    class GrowthAmbitionMonitor extends Component {
      constructor(props) {
        super(props);
        this.state = observable({
          period: {
            from: new Date(),
            until: new Date(),
          },
        });
        this.dataState = observable('loading');
        this.dialogIsOpen = observable(false);
        this.mobileCollapsedGoalIds = observable([]);
        this.sportingEvents = [];
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          this.currentSession = Session.current();
          yield this.currentSession.isReady();

          yield TeamCollection.fetchIfEmpty();

          yield SportingEventCollection.fetchIfEmpty();

          if (!this.props.developmentPlan.isNew) {
            yield Promise.all([
              this.props.developmentPlan.conversations.fetchIfEmpty(),
              this.props.developmentPlan.videoFragments.fetchIfEmpty(),
              this.props.developmentPlan.growthAmbitions.fetchIfEmpty(),
            ]);
          }

          this.sportingEvents = SportingEventCollection.getPastMatches();

          this.growthAmbition =
            this.props.developmentPlan.getActiveGrowthAmbition();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Growth ambition',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      openDialog = action((indicatorId) => {
        this.dialogIsOpen.set(indicatorId);
      });

      closeDialog = action(() => {
        this.dialogIsOpen.set(null);
      });

      toggleGoal = action((goalId) => {
        const index = this.mobileCollapsedGoalIds.indexOf(goalId);
        if (index !== -1) {
          this.mobileCollapsedGoalIds.splice(index, 1);
        } else {
          this.mobileCollapsedGoalIds.push(goalId);
        }
      });

      isMobileCollapsed(goalId) {
        return this.mobileCollapsedGoalIds.indexOf(goalId) !== -1;
      }

      render() {
        const { t, children } = this.props;
        const ratedSportingEvents = this.sportingEvents;

        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const breadcrumbs = [{ path: '/', title: 'Home' }];
        if (this.props.person) {
          breadcrumbs.push(
            ...[
              {
                path: '/development/pdp',
                title: this.props.t('module.development:playerDevelopment'),
              },
              { title: this.props.person.fullName },
            ]
          );
        }
        if (this.props.team) {
          breadcrumbs.push({
            title: this.props.t('module.development:teamDevelopment'),
          });
        }

        const pageProps = {
          title: t('module.development:title'),
          breadcrumbs,
          subtitle: this.props.subtitle,
          primaryAction:
            this.growthAmbition === null
              ? {
                  title: 'Kies ontwikkeldoelen' /*TODO:TRANSLATE*/,
                  onClick: () => gotoPath('create-growth-ambition'),
                }
              : this.currentSession.isFeatureAvailable('evaluateDevelopment')
              ? {
                  title: 'Evalueer' /*TODO:TRANSLATE*/,
                  onClick: () => gotoPath('evaluate-growth-ambition'),
                }
              : undefined,
          filters: [
            {
              title: 'Huidig ontwikkelniveau' /*TODO:TRANSLATE*/,
              path: 'learning-line-overview',
            },
            {
              title: 'Huidige doelen' /*TODO:TRANSLATE*/,
              path: 'monitor-growth-ambition',
              isActive: true,
            },
          ],
        };

        if (this.growthAmbition === null) {
          return (
            <Page {...pageProps}>
              <NoResults
                imgSrc={noGrowthAmbitionImg}
                description={t('module.development.growthAmbition:noResults')}
              />
            </Page>
          );
        }

        const ratingOptions = [
          { key: 'unknown', color: 'grey', i18nKey: 'ratingUnknown' },
        ].concat(...this.props.developmentPlan.ratingOptions);

        const { startDate, endDate } = this.growthAmbition;
        const today = moment();
        today.set({ h: 0, m: 0, s: 0 });

        const value = today.diff(startDate, 'days');
        const total = endDate.diff(startDate, 'days');

        return (
          <Page {...pageProps}>
            <div className="growth-ambition-show">
              <div className="card">
                <div className="card-header">
                  <span className="growth-ambition-start-date">
                    {startDate.format('DD MMMM YYYY')}
                  </span>
                  <h4>{}</h4>
                  <span className="growth-ambition-end-date">
                    {endDate.format('DD MMMM YYYY')}
                  </span>
                </div>
                <Progress value={(value / total) * 100} />
                <div className="card-body">
                  {this.growthAmbition.goals.map(
                    ({ goalId, indicatorId, description }, goalIdx) => {
                      const videoFragments =
                        this.props.developmentPlan.videoFragmentsOfIndicatorId(
                          indicatorId
                        );

                      const videoFragmentsPerRating =
                        groupVideoFragmentsPerRating(videoFragments);
                      const videoFragmentsPerSportingEventPerRating =
                        groupVideoFragmentsPerSportingEventPerRating(
                          videoFragments
                        );
                      return (
                        <div
                          key={goalId}
                          className="panel growth-ambition-show-goal"
                        >
                          <button
                            type="button"
                            className="btn btn-link collapse-toggle d-md-none"
                            onClick={() => this.toggleGoal(goalId)}
                          >
                            <i
                              className={`i-chevron-down ${
                                this.isMobileCollapsed(goalId)
                                  ? 'i-flipped'
                                  : ''
                              } i-dark`}
                            />
                          </button>
                          <div className="growth-ambition-show-goal-header">
                            <div className="growth-ambition-show-goal-title">
                              <span className="growth-ambition-show-goal-number">
                                Doel {goalIdx + 1}
                              </span>
                              {description}
                            </div>
                            <button
                              type="button"
                              onClick={() => this.openDialog(indicatorId)}
                              className="btn btn-link growth-ambition-show-goal-add-fragment"
                            >
                              Fragmenten toevoegen +
                            </button>
                          </div>
                          <div
                            className={`growth-ambition-show-goal-body d-md-block ${
                              this.isMobileCollapsed(goalId) ? 'd-none' : ''
                            }`}
                          >
                            {/*<div className="rating-overview-totals">
                              <OverviewItem title="Alle fragmenten" />
                              {ratingOptions.map((ratingOption, idx) => {
                                const videoFragments =
                                  videoFragmentsPerRating[ratingOption.key] ||
                                  [];
                                const disabledClass =
                                  videoFragments.length === 0 ? 'disabled' : '';

                                return (
                                  <VideoFragmentsPlayer
                                    key={idx}
                                    videoFragments={videoFragments}
                                    title={description}
                                  >
                                    {({ toggle }) => (
                                      <div
                                        className={`rating rating-${
                                          ratingOption.color
                                        } ${disabledClass} clickable`}
                                        onClick={toggle}
                                      >
                                        {videoFragments.length}
                                      </div>
                                    )}
                                  </VideoFragmentsPlayer>
                                );
                              })}
                            </div> */}
                            {videoFragmentsPerSportingEventPerRating.map(
                              ({ sportingEvent, ratings }, idx) => (
                                <div
                                  key={sportingEvent.id}
                                  className="rating-overview-item"
                                >
                                  <MatchOverviewItem
                                    sportingEvent={sportingEvent}
                                  />
                                  {ratingOptions.map((ratingOption, idx) => {
                                    const videoFragments =
                                      ratings[ratingOption.key] || [];
                                    const disabledClass =
                                      videoFragments.length === 0
                                        ? 'disabled'
                                        : '';

                                    return (
                                      <VideoFragmentsPlayer
                                        key={idx}
                                        videoFragments={videoFragments}
                                        title={description}
                                      >
                                        {({ isToggled, toggle }) => (
                                          <div
                                            className={`rating rating-${ratingOption.color} ${disabledClass} clickable`}
                                            onClick={toggle}
                                          >
                                            {videoFragments.length}
                                          </div>
                                        )}
                                      </VideoFragmentsPlayer>
                                    );
                                  })}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                  {children}
                </div>
              </div>
            </div>
            {this.dialogIsOpen.get() && (
              <Modal onCloseClick={this.closeDialog} size="lg">
                <AddFragmentToLearningIndicator
                  indicatorId={this.dialogIsOpen.get()}
                  developmentPlan={this.props.developmentPlan}
                  onSubmit={this.closeDialog}
                  onCancel={this.closeDialog}
                />
              </Modal>
            )}
          </Page>
        );
      }
    }
  )
);

export { GrowthAmbitionMonitor };
