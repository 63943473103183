import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import countdownLottie from 'lib/animations/lottie/countDown3.json';
import loginAnimationLottie from 'lib/animations/lottie/login-sr-animation.json';
import {
  EmailForm,
  ClubForm,
  AccountForm,
  CreateAccount,
  TenantForm,
} from './steps';
import Lottie from 'react-lottie';
import i18n from 'i18next';

import endpoint from '../../../utils/ApiEndpoint';
import { sendGA4Event } from '../../../utils/ga4';
import { UserInquiryForm } from './steps/4_userInquiryForm';

export const RegisterForm = ({ step, stepHandler, tags, email }) => {
  const [formData, setFormData] = useState(
    window.isSkillReflect
      ? {
          email: email,
          countryCode: 'GB',
          tenantName: '',
          password: '',
          role: '',
          acceptedDocuments: false,
          productId: 'prod_LSPRzq4dL8igOL',
          brandCode: 'sr',
          phoneNumber: null,
          tags: tags,
        }
      : {
          email: email,
          countryCode: '',
          tenantName: '',
          sportType: '',
          sportTypeLabel: '',
          password: '',
          role: '',
          acceptedDocuments: false,
          productId: 'prod_M6VawgYVcjpbtb',
          brandCode: 'ttv',
          tags: tags ?? '',
        }
  );

  const loginCreatedUser = () => {
    // Authentication.handleInAppLogin(
    //   {
    //     realm: 'Username-Password-Authentication',
    //     email: formData.email,
    //     password: formData.password,
    //   },
    //   (response) => {
    //     if (response.code !== 200) {
    //       setTimeout(() => {
    //         window.location.href = '/login';
    //       }, 3000); //automatic login failed user has to login manually. redirect to login page.
    //     }
    //   }
    // );
    setTimeout(() => {
      window.location.href = '/login';
    }, 3000);
  };

  if (step === 6 && formData.password.length) {
    setTimeout(() => {
      loginCreatedUser();
    }, 3000);
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: window.isSkillReflect
      ? loginAnimationLottie
      : countdownLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const [checkBoxes, setCheckBoxes] = useState();

  useEffect(() => {
    (async () => {
      let type = new FormData();
      type.append(
        'brandCode',
        window.isSkillReflect ? 'skillreflect' : 'teamtv'
      );
      let response = await fetch(`${endpoint}/anonymous/getLegalDocuments`, {
        method: 'post',
        body: type,
      });
      setCheckBoxes(await response.json());
    })();
  }, []);

  useEffect(() => {
    switch (step) {
      case 0:
        sendGA4Event(
          'account_registration',
          'Register',
          'Start Registration',
          window.isSkillReflect ? 'SR' : 'TTV',
          formData.tags
        );
        stepHandler.goto(1);
        break;
      case 6:
        sendGA4Event(
          'account_registration_complete',
          'Register',
          'Complete Registration',
          window.isSkillReflect ? 'SR' : 'TTV',
          formData.tags
        );
        break;
      default:
        return;
    }
  }, [step]);

  switch (step) {
    case 0:
    case 1:
      return (
        <EmailForm
          stepHandler={stepHandler}
          setFormData={setFormData}
          formData={formData}
        ></EmailForm>
      );
    case 2:
      if (window.isSkillReflect) {
        return (
          <TenantForm
            stepHandler={stepHandler}
            formData={formData}
            setFormData={setFormData}
          />
        );
      }
      return (
        <ClubForm
          stepHandler={stepHandler}
          formData={formData}
          setFormData={setFormData}
        ></ClubForm>
      );
    case 3:
      if (window.isSkillReflect) {
        stepHandler.next();
      } else {
        return (
          <UserInquiryForm
            stepHandler={stepHandler}
            formData={formData}
            setFormData={setFormData}
          />
        );
      }
    case 4:
      return (
        <AccountForm
          stepHandler={stepHandler}
          formData={formData}
          setFormData={setFormData}
          checkBoxes={
            <AcceptLegalDocuments
              formData={formData}
              setFormData={setFormData}
              checkBoxes={checkBoxes}
            />
          }
        ></AccountForm>
      );
    case 5:
      return <CreateAccount stepHandler={stepHandler} formData={formData} />;
    case 6:
      return (
        <Lottie style={{ height: 200, width: 200 }} options={defaultOptions} />
      );
  }
};

export const Error = ({ title = '', body, action }) => {
  return (
    <div className={'userlogin__warning'}>
      <label>{title}</label>
      {body && <p>{body}</p>}
      {action}
    </div>
  );
};

export const AcceptLegalDocuments = ({ checkBoxes, formData, setFormData }) => {
  const { t } = useTranslation();

  const checkboxWrapperRef = useRef();
  const checkBoxToggle = (e) => {
    if (e.target.checked) {
      setFormData((o) => {
        return { ...o, acceptedDocuments: true };
      });
    } else {
      if (formData.acceptedDocuments) {
        setFormData((o) => {
          return { ...o, acceptedDocuments: false };
        });
      }
    }
  };

  return (
    <div ref={checkboxWrapperRef} className={'userlogin-form-input'}>
      <div className={'styled-checkbox'}>
        <input
          id={'checkBox'}
          name={'checkBox'}
          type="checkbox"
          onChange={(e) => checkBoxToggle(e)}
        />
        <label htmlFor={'checkBox'}>
          <div>
            <span>{t('registerPage:form.agreeTo')}</span>{' '}
            {checkBoxes.map((checkBox, index) => (
              <>
                <a
                  href={checkBox.url[`${i18n.language === 'nl' ? 'nl' : 'en'}`]}
                >
                  {checkBox.name[`${i18n.language === 'nl' ? 'nl' : 'en'}`]}
                </a>
                {index !== checkBoxes.length - 1 ? ', ' : ''}
              </>
            ))}
          </div>
        </label>
      </div>
    </div>
  );
};
