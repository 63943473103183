import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';
import { Launch } from './launch';

import './index.scss';

const MODULE_NAME = 'embedding';

export default new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('launch', Launch, 'launch', {
      protectedRoute: false,
    }),
  ]
);
