import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';

import { withTranslation } from 'react-i18next';

import {
  VideoObservationPanel,
  PossessionButtonGroup,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';

const OtherObservationInput = withTranslation('module.observe.video')(
  observer(
    class OtherObservationInput extends Component {
      componentWillMount() {
        const {
          observationLogger,
          homeTeam,
          awayTeam,
        } = this.props.observationContext;

        observationLogger.on(
          'afterObservationAdded',
          (
            observationCode,
            attributes,
            triggerTime,
            startTime,
            endTime,
            description
          ) => {
            // only 1 history item is added instead of two (SHOT + POSSESSION)
            CommandHistory.instance().tagHistoryItem(description);
          }
        );
      }

      render() {
        const homeTeam = this.props.observationContext.homeTeam;
        const awayTeam = this.props.observationContext.awayTeam;
        const observationLogger = this.props.observationContext
          .observationLogger;

        return (
          <VideoObservationPanel
            observationContext={this.props.observationContext}
          >
            {null}
          </VideoObservationPanel>
        );
      }
    }
  )
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'other.video.default',
  component: OtherObservationInput,
  options,
  usedKeys: [],
  title: 'Video',
});
