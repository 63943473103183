import Authentication from './Authentication';
import { Session } from '../domain/Session';
import TagManager from 'react-gtm-module';

export function initTagManager(history) {
  const currentSession = Session.current();

  (async () => {
    let userProperties;

    if (Authentication.isAuthenticated) {
      await currentSession.isReady();
      await currentSession.user.isReady;

      userProperties = {
        // For audience selection
        tenant_id: currentSession.currentTenantId(),
        sport_type: currentSession.sportType() ?? 'education',
        new_user: currentSession.user.isNewUser,
        new_tenant: currentSession.currentTenantIsNew(),
      };
    } else {
      userProperties = {
        tenant_id: 'not-set',
      };
    }

    if (
      !window.dataLayer &&
      (process.env.NODE_ENV !== 'development' ||
        process.env.REACT_APP_GA4_DEBUG === 'true') &&
      process.env.NODE_ENV !== 'test' &&
      !window.location.href.includes('amplifyapp.com')
    ) {
      window.dataLayer = [];

      TagManager.initialize({
        gtmId: 'GTM-5KQR4CB',
        dataLayer: {
          user_id: Authentication.isAuthenticated
            ? currentSession.user.realUserId
            : 'anonymous',
          userProperties,
        },
        dataLayerName: 'dataLayer',
      });
    }
    // Add pageview listener to history function.
    history.listen(function (location, action) {
      specialPageEvents(history.location.pathname);

      // Clear gtm datalayer in window. https://developers.google.com/tag-platform/devguides/datalayer#reset
      // This resets window.google_tag_manager["__GTM_ID__"].dataLayer
      //   // DISABLED this will make us lose track of variables set on initialisation.
      //   window.dataLayer.push(function () {
      //     this.reset();
      //   });

      // Clear local dataLayer variable.
      if (window.dataLayer) {
        window.dataLayer.length = 0;
      }
    });
    // Trigger initial pageview.
    // Check for events on specific page url
    specialPageEvents(history.location.pathname);
  })();

  if (!window.dataLayer && process.env.REACT_APP_GA4_DEBUG === 'true') {
    console.warn('Ga4 Failed');
  }
}

export const sendGA4Event = (event_name, category, action, label, values) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'in_app_event:' + event_name,
      category: category,
      action: action,
      label: label,
      values: values,
      page_location: preprocess(document.location.href),
    });
  } else {
    if (process.env.REACT_APP_GA4_DEBUG === 'true') {
      console.warn('GA4 not initialized');
    }
  }
};
/* Special events to track */
const specialPageEvents = (page) => {
  if (page.includes('store/catalog/cancelled')) {
    let product = page.split('/')[page.split('/').length - 1];
    sendGA4Event('purchase_cancelled', 'Store', 'cancelled', '', product, page);
  }
};

const preprocess = (string) => {
  // Clean data from pathnames for analytics
  let _string = string;
  // Remove ?email= value (personal details) from register steps;
  _string = _string.replace(/email=([^&]*)&?/i, '');
  return _string;
};
