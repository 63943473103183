declare global {
  interface Window {
    Trengo?: Trengo;
  }
}

export interface Trengo {
  Api?: any;
  contact_data?: object;
  render?: boolean;
  key?: string;
}

export const init = (onLoad: (trengo: Trengo) => void) => {
  window.Trengo = window.Trengo || {};
  window.Trengo.render = false;
  window.Trengo.key = 'xXB6zu7ZrAV8MiZYtgdJ';

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.addEventListener('load', () => {
    onLoad(window.Trengo as Trengo);
  });
  script.src = 'https://static.widget.trengo.eu/embed.js';

  document.getElementsByTagName('head')[0].appendChild(script);
};
