import React, { useEffect, useState } from 'react';
import { IconFilter } from 'lib/icons/filter';
import { FilterModal } from '../../../lib/FilterModal/filter-modal';
import { useTranslation } from 'react-i18next';

// TODO: consider moving this to a more generic place
export const TagsFilter = ({
  onChange,
  values,
  observationsFetcher,
  light = false,
}: {
  values: string[];
  observationsFetcher: any;
  onChange: (value: string[]) => void;
  light: boolean;
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [observations, setObservations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('common.filters');

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setObservations([]);
      if (showFilterModal) {
        setObservations(await observationsFetcher());
      }
      setIsLoading(false);
    })();
  }, [showFilterModal]);

  const toggleFilterModal = () => {
    const willShowFilterModal = !showFilterModal;
    if (willShowFilterModal) {
      setIsLoading(true);
    }
    setShowFilterModal(willShowFilterModal);
  };

  const handleChange = (selection: string[]) => {
    onChange(selection);
    setShowFilterModal(false);
  };

  const roleOptions: { value: any; label: any }[] = observations
    .map(observation => observation.creatorRoleName)
    .filter((value, index, self) => self.indexOf(value) === index && value)
    .map(role => ({ value: role, label: role }));

  const setOptions: { value: any; label: any }[] = observations
    .map(
      observation =>
        observation.toJS().attributes.learningLineId +
        '|' +
        observation.toJS().attributes.learningLineName
    )
    .filter(
      (value, index, self) =>
        self.indexOf(value) === index && value.split('|')[1] !== 'undefined'
    )
    .map(set => ({ value: set.split('|')[0], label: set.split('|')[1] }));

  // Combine phases inside set options.
  observations
    .map(
      observation =>
        observation.attributes_.phaseLearningLine +
        '|' +
        observation.attributes_.phaseName
    )
    .sort((a, b) => {
      return a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    })
    .reverse()
    .filter(
      (value, index, self) =>
        self.indexOf(value) === index && value.split('|')[1] !== 'undefined'
    )
    .map(phase => {
      let index;
      setOptions.map((setOption, i) => {
        if (phase.split('|')[0].includes(setOption.value)) {
          index = i;
        }
      });
      setOptions.splice(index + 1, 0, {
        value: phase.split('|')[0],
        label: phase.split('|')[1],
      });
    });

  const filterConfiguration = [
    {
      filterKey: 'roles',
      filter: {
        label: t('role'),
        options: roleOptions,
      },
      values: values['roles'],
    },
    {
      filterKey: 'sets',
      filter: {
        label: t('competentionSet'),
        options: setOptions,
      },
      values: values['sets'],
    },
  ];

  const numberOfActiveFilters: number = filterConfiguration.filter(
    ({ values }) => values.length > 0
  ).length;

  const numberOfAvailableFilters: number =
    roleOptions.length + setOptions.length;

  return (
    <span>
      <button className="btn btn-link" onClick={toggleFilterModal}>
        <IconFilter
          numberOfActiveFilters={numberOfActiveFilters}
          light={light}
        />
      </button>
      {showFilterModal && !isLoading && (
        <FilterModal
          toggleFilterModal={toggleFilterModal}
          filterConfiguration={filterConfiguration}
          numberOfAvailableFilters={numberOfAvailableFilters}
          onChange={handleChange}
          values={values}
        />
      )}
    </span>
  );
};
