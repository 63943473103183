import localStorage from 'mobx-localstorage';
import { observable, action } from 'mobx';

import { CustomTagManager } from 'domain/CustomTag';

class LocalCollection {
  get name() {
    throw 'Implement me';
  }

  get model() {
    throw 'Implement me';
  }

  getFQK(key) {
    return 'com.teamtv.collection.' + this.name;
  }

  setItem(key, item) {
    const data = JSON.stringify(item.serialize());
    localStorage.setItem(this.getFQK(key), data);
  }

  getItem(key) {
    const fqk = this.getFQK(key);
    const data = localStorage.getItem(fqk);
    if (data) {
      let properties = {};
      try {
        properties = JSON.parse(data);
      } catch (e) {
        return undefined;
      }
      return this.model.unserialize(properties);
    } else {
      return undefined;
    }
  }
}

class ObservationInputConfigurationCollection extends LocalCollection {
  get model() {
    return ObservationInputConfiguration;
  }

  get name() {
    return 'observationInputConfiguration';
  }

  observationInputConfigurationById(observationInputConfigurationId) {
    return this.getItem(observationInputConfigurationId);
  }

  save(observationInputConfiguration) {
    this.setItem(
      observationInputConfiguration.observationInputConfigurationId,
      observationInputConfiguration
    );
  }
}

class ObservationInputConfiguration {
  constructor(
    observationInputConfigurationId,
    customTagIds = [],
    standardTagsVisibility = true
  ) {
    this.observationInputConfigurationId = observationInputConfigurationId;

    this.customTagIds = observable(customTagIds);

    this.standardTagsVisibility = observable(standardTagsVisibility);
    this.customTagsVisibility = true;
  }

  serialize() {
    return {
      observationInputConfigurationId: this.observationInputConfigurationId,
      customTagIds: this.customTagIds.slice(),
      standardTagsVisibility: this.standardTagsVisibility.get(),
    };
  }

  static unserialize(data) {
    return new ObservationInputConfiguration(
      data.observationInputConfigurationId,
      data.customTagIds,
      data.standardTagsVisibility
    );
  }

  getCustomTags() {
    return CustomTagManager.getCustomTags().filter(customTag => {
      return this.contains(customTag.id);
    });
  }

  contains(customTagId) {
    return this.customTagIds.indexOf(customTagId) !== -1;
  }

  addCustomTag = action(customTagId => {
    this.customTagIds.push(customTagId);
  });

  removeCustomTag = action(customTagId => {
    const index = this.customTagIds.indexOf(customTagId);
    if (index !== -1) {
      this.customTagIds.splice(index, 1);
    }
  });

  setStandardTagsVisibility = action(visibility => {
    this.standardTagsVisibility.set(visibility);
  });

  setCustomTagsVisibility(visibility) {
    this.customTagsVisibility = visibility;
  }

  getStandardTagsVisibility() {
    return this.standardTagsVisibility.get();
  }
}

const observationInputConfigurationCollection = new ObservationInputConfigurationCollection();

export {
  observationInputConfigurationCollection,
  ObservationInputConfiguration,
};
