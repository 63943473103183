import { observable, action } from 'mobx';

import { CommandHistory } from './CommandBus';

class HistoryObserver {
  static _instance = null;
  static instance() {
    if (this._instance === null) {
      this._instance = new HistoryObserver();
    }
    return this._instance;
  }

  constructor() {
    this._canUndo = observable(false);

    const commandHistory = CommandHistory.instance();
    commandHistory.on(
      'tagAdded',
      action(() => {
        this._canUndo.set(commandHistory.historyLength > 0);
      })
    );

    commandHistory.on(
      'tagRemoved',
      action(() => {
        this._canUndo.set(commandHistory.historyLength > 0);
      })
    );

    commandHistory.on(
      'historyReset',
      action(() => {
        this._canUndo.set(commandHistory.historyLength > 0);
      })
    );
  }

  canUndo() {
    return this._canUndo.get();
  }
}

export { HistoryObserver };
