import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { LineUpDetail } from './containers';
const MODULE_NAME = 'lineup';

export default new Module(
  MODULE_NAME,
  [],
  [new RouteExact('detail/:sportingEventId', LineUpDetail, 'detail')]
);
