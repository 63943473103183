import React from 'react';

function Stats({ teams, report }) {
  const homeTeamReport = report.c(`team_id:${teams.$homeTeam.teamId}`);
  const awayTeamReport = report.c(`team_id:${teams.$awayTeam.teamId}`);

  const homePossession = homeTeamReport.c('possession');
  const awayPossession = awayTeamReport.c('possession');

  let totalDuration = homePossession.duration + awayPossession.duration;
  if (totalDuration === 0) totalDuration = 1; // make sure division is possible
  const homeDurationPercentage = homePossession.duration / totalDuration;
  const awayDurationPercentage = awayPossession.duration / totalDuration;

  const homePenaltyCornerTotal = homeTeamReport.c(
    'overall.penalty_corner'
  ).count;
  const homePenaltyCornerGoal = homeTeamReport.c(
    'overall.goal.type:PENALTY-CORNER'
  ).count;
  const homePenaltyCornerPercentage =
    homePenaltyCornerTotal > 0
      ? `${((homePenaltyCornerGoal / homePenaltyCornerTotal) * 100).toFixed(
          0
        )}%`
      : '';

  const awayPenaltyCornerTotal = awayTeamReport.c(
    'overall.penalty_corner'
  ).count;
  const awayPenaltyCornerGoal = awayTeamReport.c(
    'overall.goal.type:PENALTY-CORNER'
  ).count;
  const awayPenaltyCornerPercentage =
    awayPenaltyCornerTotal > 0
      ? `${((awayPenaltyCornerGoal / awayPenaltyCornerTotal) * 100).toFixed(
          0
        )}%`
      : '';

  // Original
  let colors = {
    bg: '#03559E',
    stars: '#153C7B',
    overlayAccent: '#00368C',
    text: '#FFFFFF',
  };

  // SouthGateHC
  colors = {
    bg: '#180202',
    stars: '#862e29',
    overlayAccent: '#da463a',
    text: '#f8f8f8',
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="$width"
      height="$height"
      viewBox="0 0 1187 519"
    >
      <defs>
        <path
          id="path-1"
          d="M0 0h1187v512a7 7 0 01-7 7H7a7 7 0 01-7-7V0z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-105 -216)">
          <g transform="translate(105 216)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1"></use>
            </mask>
            <path fill={colors.bg} d="M0 0H1187V519H0z" opacity="0.95"></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M136.883 81.52l-64.328 30.817a2 2 0 01-2.802-2.297l15.219-59.895-54.239-40.72a2 2 0 011.025-3.591L104.8-.622l6.09-3.295a2 2 0 011.334-.204l56.983 11.076 56.398 10.963a2 2 0 01.83 3.554l-37.64 28.673 15.219 59.895a2 2 0 01-2.803 2.297L136.883 81.52z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(-11 128.578 54.188)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M940.994 288.99l-17.892 8.634a2 2 0 01-2.809-2.291l4.143-16.421-14.494-10.96a2 2 0 011.029-3.587l19.79-1.762 8.468-15.888a2 2 0 013.53 0l8.468 15.888 19.79 1.762a2 2 0 011.03 3.587l-14.495 10.96 4.143 16.421a2 2 0 01-2.808 2.29l-17.893-8.632z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(17 940.994 274.404)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M990.994 168.99l-17.892 8.634a2 2 0 01-2.809-2.291l4.143-16.421-14.494-10.96a2 2 0 011.029-3.587l19.79-1.762 8.468-15.888a2 2 0 013.53 0l8.468 15.888 19.79 1.762a2 2 0 011.03 3.587l-14.495 10.96 4.143 16.421a2 2 0 01-2.808 2.29l-17.893-8.632z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(17 990.994 154.404)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M780.994 192.518l-52.571 25.543a2 2 0 01-2.814-2.285l12.424-49.594-44.239-33.685a2 2 0 011.033-3.583l59.616-5.345 24.783-46.825a2 2 0 013.536 0l24.784 46.825 59.615 5.345a2 2 0 011.033 3.583l-44.239 33.685 12.425 49.594a2 2 0 01-2.814 2.285l-52.572-25.543z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(7 780.994 154.404)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M244.754 230.982l-17.893 8.632a2 2 0 01-2.808-2.29l4.143-16.422-14.495-10.96a2 2 0 011.03-3.587l19.79-1.761 8.468-15.888a2 2 0 013.53 0l8.468 15.888 19.79 1.761a2 2 0 011.03 3.588l-14.495 10.96 4.143 16.42a2 2 0 01-2.809 2.291l-17.892-8.632z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(-13 244.754 216.395)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M434.754 120.982l-17.893 8.632a2 2 0 01-2.808-2.29l4.143-16.422-14.495-10.96a2 2 0 011.03-3.587l19.79-1.761 8.468-15.888a2 2 0 013.53 0l8.468 15.888 19.79 1.761a2 2 0 011.03 3.588l-14.495 10.96 4.143 16.42a2 2 0 01-2.809 2.291l-17.892-8.632z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(-13 434.754 106.395)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M94.754 210.982l-17.893 8.632a2 2 0 01-2.808-2.29l4.143-16.422-14.495-10.96a2 2 0 011.03-3.587l19.79-1.761 8.468-15.888a2 2 0 013.53 0l8.468 15.888 19.79 1.761a2 2 0 011.03 3.588l-14.495 10.96 4.143 16.42a2 2 0 01-2.809 2.291l-17.892-8.632z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(-13 94.754 196.395)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M4.124 334.043l-7.655-28.569 6.82-13.532a2 2 0 013.55-.04l8.42 15.794 19.79 1.761a2 2 0 011.029 3.588l-14.495 10.96 4.162 16.496a2 2 0 01-2.783 2.303l-18.838-8.761z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(15 16.67 316.916)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M1109.359 242.941l-10.252 4.92a2 2 0 01-2.804-2.295l2.315-9.128-7.921-5.957a2 2 0 011.026-3.59l10.998-.974 4.875-9.097a2 2 0 013.525 0l4.875 9.097 10.999.973a2 2 0 011.025 3.591l-7.921 5.957 2.315 9.128a2 2 0 01-2.804 2.294l-10.251-4.919z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(15 1109.359 233.53)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M1031.359 56.941l-10.252 4.92a2 2 0 01-2.804-2.295l2.315-9.128-7.921-5.957a2 2 0 011.026-3.59l10.998-.974 4.875-9.097a2 2 0 013.525 0l4.875 9.097 10.999.973a2 2 0 011.025 3.591l-7.921 5.957 2.315 9.128a2 2 0 01-2.804 2.294l-10.251-4.919z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(15 1031.359 47.53)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M743.359 462.941l-10.252 4.92a2 2 0 01-2.804-2.295l2.315-9.128-7.921-5.957a2 2 0 011.026-3.59l10.998-.974 4.875-9.097a2 2 0 013.525 0l4.875 9.097 10.999.973a2 2 0 011.025 3.591l-7.921 5.957 2.315 9.128a2 2 0 01-2.804 2.294l-10.251-4.919z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(15 743.359 453.53)"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M252.5 466.115l-29.355 14.062a2 2 0 01-2.802-2.296l6.873-27.047-24.278-18.225a2 2 0 011.025-3.592l32.91-2.909 13.865-25.826a2 2 0 013.524 0l13.865 25.826 32.91 2.909a2 2 0 011.025 3.592l-24.278 18.225 6.873 27.047a2 2 0 01-2.802 2.296L252.5 466.115z"
              mask="url(#mask-2)"
              opacity="0.396"
            ></path>
            <path
              fill={colors.stars}
              fillRule="nonzero"
              d="M401.43 340.033l-29.355 14.061a2 2 0 01-2.802-2.296l6.873-27.047-24.278-18.225a2 2 0 011.024-3.592l32.911-2.909 13.865-25.825a2 2 0 013.524 0l13.865 25.825 32.91 2.91a2 2 0 011.025 3.59l-24.278 18.226 6.873 27.047a2 2 0 01-2.802 2.296l-29.355-14.061z"
              mask="url(#mask-2)"
              opacity="0.396"
              transform="rotate(-11 401.43 317.917)"
            ></path>
            <rect
              width="328"
              height="67"
              x="429"
              y="52"
              fill="#FFF"
              fillRule="nonzero"
              mask="url(#mask-2)"
              rx="7"
            ></rect>
            <text
              fill={colors.overlayAccent}
              fontFamily="Lato, sans-serif"
              fontSize="50"
              fontWeight="700"
              mask="url(#mask-2)"
              textAnchor="middle"
            >
              <tspan x="510" y="102">
                {homeTeamReport.c('overall.goal.all').count}
              </tspan>
            </text>
            <text
              fill={colors.overlayAccent}
              fontFamily="Lato, sans-serif"
              fontSize="50"
              fontWeight="700"
              mask="url(#mask-2)"
              textAnchor="middle"
            >
              <tspan x="675" y="102">
                {awayTeamReport.c('overall.goal.all').count}
              </tspan>
            </text>
            <text
              fill={colors.overlayAccent}
              fontFamily="Lato, sans-serif"
              fontSize="50"
              fontWeight="700"
              mask="url(#mask-2)"
            >
              <tspan x="585.6" y="98">
                -
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="29"
              fontWeight="700"
              mask="url(#mask-2)"
            >
              <tspan x="92" y="95">
                {teams.$homeTeam?.name}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="29"
              fontWeight="700"
              mask="url(#mask-2)"
              textAnchor="end"
            >
              <tspan x="1105" y="95">
                {teams.$awayTeam?.name}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="35"
              fontWeight="700"
              mask="url(#mask-2)"
            >
              <tspan x="92" y="202">
                {(homeDurationPercentage * 100).toFixed(0)}%
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="35"
              fontWeight="700"
              mask="url(#mask-2)"
              textAnchor="end"
            >
              <tspan x="1105" y="202">
                {(awayDurationPercentage * 100).toFixed(0)}%
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="92" y="258">
                {homeTeamReport.c('overall.circle_entry').count}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
              textAnchor="end"
            >
              <tspan x="1105" y="258">
                {awayTeamReport.c('overall.circle_entry').count}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="92" y="318">
                {homeTeamReport.c('overall.scoring_opportunity').count}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
              textAnchor="end"
            >
              <tspan x="1105" y="318">
                {awayTeamReport.c('overall.scoring_opportunity').count}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="700"
              mask="url(#mask-2)"
            >
              <tspan x="220" y="378">
                {homePenaltyCornerPercentage}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="92" y="378">
                {homePenaltyCornerGoal}/{homePenaltyCornerTotal}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
              textAnchor="end"
            >
              <tspan x="1105" y="378">
                {awayPenaltyCornerGoal}/{awayPenaltyCornerTotal}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="700"
              mask="url(#mask-2)"
            >
              <tspan x="888.536" y="379">
                {awayPenaltyCornerPercentage}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="92" y="438">
                {homeTeamReport.c('overall.turnover').count}
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
              textAnchor="end"
            >
              <tspan x="1105" y="438">
                {awayTeamReport.c('overall.turnover').count}
              </tspan>
            </text>
            <rect
              width="328"
              height="40"
              x="429"
              y="170"
              fill={colors.overlayAccent}
              fillRule="nonzero"
              mask="url(#mask-2)"
              rx="7"
            ></rect>
            <rect
              width="328"
              height="40"
              x="429"
              y="230"
              fill={colors.overlayAccent}
              fillRule="nonzero"
              mask="url(#mask-2)"
              rx="7"
            ></rect>
            <rect
              width="328"
              height="40"
              x="429"
              y="289"
              fill={colors.overlayAccent}
              fillRule="nonzero"
              mask="url(#mask-2)"
              rx="7"
            ></rect>
            <rect
              width="328"
              height="40"
              x="429"
              y="349"
              fill={colors.overlayAccent}
              fillRule="nonzero"
              mask="url(#mask-2)"
              rx="7"
            ></rect>
            <rect
              width="328"
              height="40"
              x="429"
              y="409"
              fill={colors.overlayAccent}
              fillRule="nonzero"
              mask="url(#mask-2)"
              rx="7"
            ></rect>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="457.232" y="258">
                CIRCLE PENETRATIONS
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="553.448" y="318">
                SHOTS
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="478.784" y="378">
                PENALTY CORNERS
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="520.556" y="438">
                TURNOVERS
              </tspan>
            </text>
            <text
              fill="#FFF"
              fontFamily="Lato, sans-serif"
              fontSize="24"
              fontWeight="600"
              mask="url(#mask-2)"
            >
              <tspan x="524.292" y="198">
                POSSESSION
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Stats;
