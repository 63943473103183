import React from 'react';

import { withTranslation } from 'react-i18next';

import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';
import sweetAlert from 'lib/sweetAlert';

export const ObservationDetailsFormButtons = withTranslation('common')(
  ({
    onUpdate,
    onDelete,
    className = '',
    t,
  }: {
    onUpdate: (observationId) => void;
    onDelete: () => void;
    className?: string;
  }) => {
    const { setCurrent, current: observation } =
      useObservationsEditCollectionContext();

    const handleSave = () => {
      onUpdate(observation.observationId);
      setCurrent(null);
    };

    const handleDelete = () => {
      sweetAlert('', {
        title: t('common:deleteTag'),
        text: t('common:confirmDeleteMessageTag'),
        dangerMode: true,
        buttons: [t('common:cancel'), t('common:delete')],
      }).then((willDelete) => {
        if (willDelete) {
          onDelete();
          setCurrent(null);
        }
      });
    };

    return (
      <div className={`form-actions ${className}`}>
        <button
          style={{ marginLeft: '4px' }}
          className="btn btn-primary"
          onClick={handleSave}
        >
          {t('common:save')}
        </button>
        <button
          style={{ marginLeft: '4px', opacity: '0.5' }}
          className="btn btn-text text-danger"
          onClick={handleDelete}
        >
          {t('common:delete')}
        </button>
      </div>
    );
  }
);
