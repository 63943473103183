class ModuleRegistry {
  constructor() {
    this.modules = [];
  }

  register(module) {
    this.modules.push(module);
  }

  getModuleByName(name) {
    for (const module of this.modules) {
      if (module.name === name) {
        return module;
      }
    }
    throw 'Module not found';
  }

  getModules() {
    return this.modules;
  }
}

const registry = new ModuleRegistry();

export default registry;
