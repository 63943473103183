import React, { useRef, useState } from 'react';
import { InputWithActionButton } from '../../../../lib/InputWithActionButton/inputWithActionButton';
import { writeToClipboard } from '../../../../lib/writeToClipboard';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../../utils/ApiClient';
import Loading from '../../../../lib/Loading';
import { SubmittingButton } from '../../../../lib/SubmittingButton';

export const BroadcastIntegration = ({ sportingEvent }) => {
  const [isCopied, setIsCopied] = useState(null);

  const queryClient = useQueryClient();

  const queryKey = ['broadcast', sportingEvent.id];

  const broadcast = useQuery(
    queryKey,
    async () => {
      const { data } = await api.get(
        `sportingEvents/${sportingEvent.id}/broadcast`
      );
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const createBroadCast = async () => {
    const { data } = await api.post(
      `sportingEvents/${sportingEvent.id}/createBroadcast`
    );
    queryClient.invalidateQueries(queryKey);
  };

  if (broadcast.isLoading || broadcast.isFetching) {
    return <Loading type={'dots'} />;
  }
  if (!broadcast.data) {
    return (
      <div className={'form-group'}>
        <p>No broadcast set for this match</p>
        <SubmittingButton
          className={'btn btn-primary'}
          onClick={createBroadCast}
        >
          Create broadcast
        </SubmittingButton>
      </div>
    );
  } else {
    return (
      <div>
        <div className={'form-group'}>
          <p>
            Your broadcast for <b>{sportingEvent.label}</b> is ready
          </p>
          <label>URL</label>
          <InputWithActionButton
            value={broadcast.data.stream.url}
            inputId={'stream_url'}
            button={{
              action: () => {
                setIsCopied(
                  writeToClipboard(broadcast.data.stream.url)
                    ? 'stream_url'
                    : false
                );
              },
              label: isCopied === 'stream_url' ? 'Copied ✓' : 'Copy',
            }}
          />
        </div>
        <label>Stream key</label>
        <div className={'form-group'}>
          <InputWithActionButton
            value={broadcast.data.stream.key}
            inputId={'stream_key'}
            button={{
              action: () => {
                setIsCopied(
                  writeToClipboard(broadcast.data.stream.key)
                    ? 'stream_key'
                    : false
                );
              },
              label: isCopied === 'stream_key' ? 'Copied ✓' : 'Copy',
            }}
          />
        </div>
      </div>
    );
  }
};
