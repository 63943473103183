import React from 'react';
import { Collapse } from 'reactstrap';
import { withToggle } from 'lib/hocs/withToggle';

import clubPlaceholderIcon from 'img/icons/club_placeholder.svg';
import personPlaceholderIcon from 'img/icons/person_placeholder.svg';
import parentClubPlaceholderIcon from 'img/icons/parentclub_placeholder.svg';
import educationPlaceholderIcon from 'img/icons/education_placeholder.svg';
import learningGroupPlaceholderIcon from 'img/icons/learninggroup_placeholder.svg';
import { groupBy, toPairs } from 'lodash';

import './index.scss';
import { Trans } from 'react-i18next';

export const MembershipIcon = ({ membership, className }) => {
  let membershipType = 'team';
  if (membership.targetResourceId) {
    membershipType = membership.targetResourceId.split(':')[0];
  }
  if (membershipType === 'person') {
    return <PersonIcon icon={membership.logoUrl} className={className} />;
  } else if (membershipType === 'club') {
    return <ParentClubIcon icon={membership.logoUrl} className={className} />;
  } else if (membershipType === 'education') {
    return <EducationIcon icon={membership.logoUrl} className={className} />;
  } else if (membershipType === 'LearningGroup') {
    return (
      <LearningGroupIcon icon={membership.logoUrl} className={`${className}`} />
    );
  } else {
    return <TeamIcon icon={membership.logoUrl} className={className} />;
  }
};

export const ResourceGroupSelection = withToggle(
  ({ isToggled, toggle, memberships, activeMembership, onChange }) => {
    const groupedMemberships = groupBy(
      memberships,
      (membership) => membership.tenantId
    );

    const currentMembership =
      activeMembership.resourceGroupId !== 'not-set'
        ? {
            [activeMembership.tenantId]:
              groupedMemberships[activeMembership.tenantId],
          }
        : null;
    if (currentMembership) delete groupedMemberships[activeMembership.tenantId];

    return (
      <div className="collapse-dropdown">
        <div className="collapse-dropdown-selected" onClick={() => toggle()}>
          <div className="collapse-dropdown-icon">
            <MembershipIcon membership={activeMembership} />
          </div>
          <div className="collapse-dropdown-selected-label">
            <div className="collapse-dropdown-label">
              {activeMembership.label}
            </div>
            <div className="collapse-dropdown-label-small">
              <Trans
                i18nKey="menu:activeRole"
                values={{ roleName: activeMembership.roleName }}
              >
                Role: <em>Trainer</em>
              </Trans>
            </div>
          </div>
          <div className="collapse-dropdown-selected-icon" />
        </div>
        <Collapse isOpen={isToggled}>
          <ul>
            {toPairs({ ...currentMembership, ...groupedMemberships }).map(
              ([tenantId, tenantMembership]) => {
                return (
                  <div
                    style={{
                      borderBottom: 'solid 1px #495057',
                    }}
                    data-tenant-id={tenantId}
                    key={tenantId}
                  >
                    {tenantMembership.map((membership) => {
                      return (
                        <Item
                          key={membership.resourceGroupId}
                          isActive={
                            membership.resourceGroupId ===
                            activeMembership.resourceGroupId
                          }
                          onClick={() => onChange(membership)}
                        >
                          <div className="collapse-dropdown-icon">
                            <MembershipIcon membership={membership} />
                          </div>
                          <div
                            data-rolenames={membership.roleNames
                              .toJS()
                              .join(', ')}
                            data-type={
                              membership.targetResourceId.split(':')[0]
                            }
                            className="collapse-dropdown-label"
                          >
                            {membership.targetResourceName}
                          </div>
                        </Item>
                      );
                    })}
                  </div>
                );
              }
            )}
          </ul>
        </Collapse>
      </div>
    );
  }
);

const Item = ({ isActive, children, onClick }) => {
  const classNames = `collapse-dropdown-item ${isActive ? 'active' : ''}`;
  return (
    <li className={classNames} onClick={onClick}>
      {children}
    </li>
  );
};

const TeamIcon = ({ icon, className }) => (
  <img className={`i-logo ${className}`} src={icon || clubPlaceholderIcon} />
);

const PersonIcon = ({ icon, className }) => (
  <img className={`i-logo ${className}`} src={icon || personPlaceholderIcon} />
);
const ParentClubIcon = ({ icon, className }) => (
  <img
    className={`i-logo ${className}`}
    src={icon || parentClubPlaceholderIcon}
  />
);

const EducationIcon = ({ icon, className }) => (
  <img
    className={`i-logo ${className}`}
    src={icon || educationPlaceholderIcon}
  />
);

const LearningGroupIcon = ({ icon, className }) => (
  <img
    className={`i-logo ${className}`}
    src={icon || learningGroupPlaceholderIcon}
  />
);
