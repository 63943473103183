import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import Joyride from 'react-joyride';

import { KeyBoardInputHelper } from 'lib/KeyBoardInputHelper';
import { RenderIfElementAvailable } from 'lib/RenderIfElementAvailable';
import { EventPublisher } from 'utils/EventPublisher';
import { Session } from 'domain/Session';

import tourHistory from './TourHistory';
import tourRegistry from './TourRegistry';

import './index.scss';
import './tours';

const TOUR_OPTIONS = {
  arrowColor: '#fff',
  backgroundColor: '#fff',
  beaconSize: 36,
  overlayColor: 'rgba(0, 0, 0, 0.5)',
  primaryColor: '#28E89E',
  spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
  textColor: '#333',
  width: undefined,
  zIndex: 100,
};

const getCallbackWrapper = (tour, stopTour) => {
  const keyboardInputHandler = new KeyBoardInputHelper();
  return (data) => {
    const { action, index, status, type, lifecycle } = data;
    const callbacks = tour.callbacks || {};

    switch (true) {
      case action === 'start' && lifecycle === 'ready':
        try {
          callbacks.start && callbacks.start();
        } finally {
          keyboardInputHandler.disable();
          EventPublisher.dispatch('TOUR_STARTED', tour);
        }

        break;
      case action === 'next' && lifecycle === 'ready':
        callbacks.nextStep && callbacks.nextStep(index);
        EventPublisher.dispatch('TOUR_NEXT_STEP', tour);
        break;
      case action === 'reset' && lifecycle === 'init':
        try {
          callbacks.end && callbacks.end();
        } finally {
          keyboardInputHandler.enable();
          EventPublisher.dispatch('TOUR_FINISHED', tour);
        }
        break;
      case action === 'close' && lifecycle === 'ready':
        try {
          callbacks.close && callbacks.close();
        } finally {
          keyboardInputHandler.enable();
          EventPublisher.dispatch('TOUR_CLOSED', tour);
          // setTimeout(onClose, 0);
        }
        break;
    }
  };
};

const TourAPI = {};

const TourComponent = withRouter(
  withTranslation('module.tours')(
    class TourComponent extends Component {
      constructor() {
        super();

        this.state = {
          tourToShow: null,
          run: false,
        };

        TourAPI.startTour = () => this.startTour();
      }

      onPathChanged(path) {
        const tour = tourRegistry.getApplicableTour(path, Session.current());
        const isTourNewForUser = tour && !tourHistory.hasSeen(tour);

        this.setState({
          tourToShow: tour && isTourNewForUser ? tour : null,
          run: !!(tour && tour.autoStart && isTourNewForUser),
        });
      }

      startTour() {
        const tour = tourRegistry.getApplicableTour(
          this.props.location.pathname,
          Session.current()
        );

        this.setState(
          {
            tourToShow: tour,
            run: false,
          },
          () => {
            this.setState({
              tourToShow: tour,
              run: true,
            });
          }
        );
      }

      componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
          this.setPathWithSession();
        }
      }

      async setPathWithSession() {
        await Session.current().isReady();
        this.onPathChanged(this.props.location.pathname);
      }

      componentDidMount() {
        this.setPathWithSession();
      }

      stopTour() {
        this.setState({
          ...this.state,
          run: false,
        });
      }

      render() {
        if (this.state.tourToShow === null) {
          return null;
        }

        if (this.state.run) {
          tourHistory.seen(this.state.tourToShow);
        }

        const currentSession = Session.current();

        const tour = this.state.tourToShow;

        const { t } = this.props;

        const locale = {
          back: t('back'),
          close: t('close'),
          last: t('last'),
          next: t('next'),
          skip: t('skip'),
        };
        const steps = tour.steps(currentSession).map((step) => {
          return {
            disableBeacon: true,
            title: t(step.i18nKey + 'Title'),
            content: (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {t(step.i18nKey + 'Content')}
              </div>
            ),
            ...step,
          };
        });

        return (
          <RenderIfElementAvailable cssSelector={steps[0].target}>
            <Joyride
              continuous
              run={this.state.run}
              scrollToFirstStep
              showProgress
              showSkipButton
              disableOverlay
              steps={steps}
              disableScrollParentFix
              styles={{ options: TOUR_OPTIONS }}
              locale={locale}
              floaterProps={{
                disableAnimation: true,
              }}
              callback={getCallbackWrapper(tour, () => this.stopTour())}
            />
          </RenderIfElementAvailable>
        );
      }
    }
  )
);

const StartTourButton = withRouter(
  withTranslation('module.tours')(({ location, t }) => {
    const tour = tourRegistry.getApplicableTour(
      location.pathname,
      Session.current()
    );

    if (!tour) {
      return null;
    }

    return (
      <a href="#" onClick={() => TourAPI.startTour()}>
        <span>{t('startTour')}</span>
        <i className="i-guide-sign" />
      </a>
    );
  })
);

export { TourComponent, StartTourButton };
