import { Tour } from '../Tour';

import { matchRuleShort } from 'utils/matchRuleShort';

export default new Tour({
  key: 'matchOverview',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return (
      matchRuleShort(path, '/match/overview/*') && currentSession.isTrainer()
    );
  },
  steps: (currentSession) => {
    const _steps = [];

    if (currentSession.isFeatureAvailable('createSportingEvent')) {
      _steps.push({
        target: '.page-header-cta button',
        i18nKey: 'module.tours.match:step1AddMatch',
      });
    }
    _steps.push(
      {
        target: ".view-filters [data-id='mymatches']",
        i18nKey: 'module.tours.match:step2MyMatches',
      },
      {
        target: ".view-filters [data-id='upcoming']",
        i18nKey: 'module.tours.match:step3UpcomingMatches',
      },
      {
        target: '.match-overview-item:first-child',
        i18nKey: 'module.tours.match:step4PlayedMatches',
      }
    );
    return _steps;
  },
});
