import React, { useState, useEffect } from 'react';

import { observer } from 'mobx-react';

import { TennisReducer } from '@teamtv/teamtv-match-state';

const TennisScore = ({
  homeParty,
  awayParty,
  homeScore,
  awayScore,
  tiebreakType,
  server,
}) => {
  const GameScore = points => {
    if (tiebreakType === 'NoTiebreak') {
      if (points === 1) return 15;
      if (points === 2) return 30;
      if (points >= 3) return 40;
      return 0;
    } else {
      return points;
    }
  };

  const playedSets = homeScore.sets + awayScore.sets;

  const isAdvantage = (myGamePoints, opponentGamePoints) => {
    if (tiebreakType !== 'NoTiebreak') return '';
    if (myGamePoints < 3 || opponentGamePoints < 3) return '';
    if (myGamePoints > opponentGamePoints) return 'A';
    return '';
  };

  const homeAdvantage = isAdvantage(homeScore.points, awayScore.points);
  const awayAdvantage = isAdvantage(awayScore.points, homeScore.points);

  let setsHome = (
    <g key="sets-home">
      {homeScore.setScores.map((game, index) => {
        let scoreX = ['17.356', '34.356', '51.356', '68.356'];
        let winnerX = ['14.5', '31.5', '48.5', '65.5'];

        let setCount = homeScore.setScores.length;
        let setsPosX = scoreX[4 - setCount + index];
        let winnerPosX = winnerX[4 - setCount + index];

        const isSetWon =
          homeScore.setScores[index].games > awayScore.setScores[index].games;

        return (
          <g key={`g-${index}`}>
            <text
              key={'Set' + index}
              id="set1Home"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan x={setsPosX} y="24">
                {homeScore.setScores[index].games}
              </tspan>
            </text>
            {isSetWon ? (
              <rect
                key={'homeSetRect' + index}
                id={'Rect' + index}
                stroke="#28E89E"
                fillRule="nonzero"
                x={winnerPosX}
                y="10.5"
                width="14"
                height="18"
                rx="7"
              ></rect>
            ) : null}
          </g>
        );
      })}
    </g>
  );

  let setsAway = (
    <g key="sets-away">
      {awayScore.setScores.map((game, index) => {
        let scoreX = ['17.356', '34.356', '51.356', '68.356'];
        let winnerX = ['14.5', '31.5', '48.5', '65.5'];

        let setCount = awayScore.setScores.length;
        let setsPosX = scoreX[4 - setCount + index];
        let winnerPosX = winnerX[4 - setCount + index];

        const isSetWon =
          homeScore.setScores[index].games < awayScore.setScores[index].games;

        return (
          <g key={index}>
            <text
              id={'ASet' + index}
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan x={setsPosX} y="46">
                {awayScore.setScores[index].games}
              </tspan>
            </text>
            {isSetWon ? (
              <rect
                key={'awaySetRect' + index}
                id={'ARect' + index}
                stroke="#28E89E"
                fillRule="nonzero"
                x={winnerPosX}
                y="32.5"
                width="14"
                height="18"
                rx="7"
              ></rect>
            ) : null}
          </g>
        );
      })}
    </g>
  );

  return (
    <svg width="282px" height="61px" viewBox="0 0 282 61" version="1.1">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g id="Play" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-16.000000, -16.000000)">
          <g id="Group" transform="translate(16.000000, 16.000000)">
            <rect
              key="currentSet"
              id="currentSet"
              fill="#424242"
              fillRule="nonzero"
              x="0"
              y="0"
              width="282"
              height="61"
              rx="7"
            ></rect>
            {setsHome}
            {setsAway}
            <rect
              key="awayPlayer"
              id="awayPlayer"
              fill="#393939"
              fillRule="nonzero"
              x="96"
              y="10"
              width="113"
              height="19"
              rx="7"
            ></rect>
            <text
              id="Player1"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="9"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan x="112" y="23">
                {homeParty}
              </tspan>
            </text>
            <rect
              key="homePlayer"
              id="homePlayer"
              fill="#393939"
              fillRule="nonzero"
              x="96"
              y="32"
              width="113"
              height="19"
              rx="7"
            ></rect>
            <text
              id="Player2"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="9"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan x="112" y="45">
                {awayParty}
              </tspan>
            </text>
            {server === 'HomeParty' && (
              <circle
                id="Oval1"
                fill="#F2F2F2"
                fillRule="nonzero"
                cx="105.5"
                cy="19.5"
                r="2.5"
              ></circle>
            )}
            {server === 'AwayParty' && (
              <circle
                id="Oval2"
                fill="#F2F2F2"
                fillRule="nonzero"
                cx="105.5"
                cy="41.5"
                r="2.5"
              ></circle>
            )}
            <rect
              id="Rectangle"
              fill="#D8D8D8"
              fillRule="nonzero"
              x="225"
              y="10"
              width="15"
              height="41"
              rx="7"
            ></rect>
            <text
              id="1"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#424242"
            >
              <tspan x="228.356" y="24">
                {homeScore.games}
              </tspan>
            </text>
            <text
              id="6"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#424242"
            >
              <tspan x="228.356" y="46">
                {awayScore.games}
              </tspan>
            </text>
            <text
              id="15-copy"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan x="248" y="24">
                {GameScore(homeScore.points)}
                {homeAdvantage}
              </tspan>
            </text>
            <text
              id="40-copy"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan x="248" y="46">
                {GameScore(awayScore.points)}
                {awayAdvantage}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

const TennisScoreBoard = ({
  currentObservations,
  matchConfig,
  homeParty,
  awayParty,
}) => {
  const [observations, setObservations] = useState([]);
  const [matchState, setMatchState] = useState(null);

  useEffect(() => {
    const reducer = new TennisReducer(matchConfig);

    for (const observation of observations) {
      try {
        reducer.reduce(observation.toJS());
      } catch (e) {
        break;
      }
    }
    setMatchState(reducer.get_state());
    reducer.free();
  }, [observations]);

  if (observations.length !== currentObservations.length) {
    setObservations(currentObservations);
    return null;
  }

  if (matchState === null) {
    return null;
  }

  return (
    <div
      className="video-scoreboard"
      style={{
        alignSelf: 'start',
        justifyContent: 'start',
        padding: '12px',
      }}
    >
      <TennisScore
        homeParty={homeParty}
        awayParty={awayParty}
        homeScore={matchState.score.homeScore}
        awayScore={matchState.score.awayScore}
        tiebreakType={matchState.tiebreakType}
        server={matchState.server}
      />
    </div>
  );
};

const TennisScoreBoardWrapper = observer(({ observationContext }) => {
  return (
    <TennisScoreBoard
      currentObservations={observationContext.observations()}
      matchConfig={observationContext.sportingEvent.matchConfig}
      homeParty={observationContext.homeTeam.label}
      awayParty={observationContext.awayTeam.label}
    />
  );
});

export { TennisScoreBoardWrapper as TennisScoreBoard };
