import Handler from './Handler';

class EventHandler extends Handler {
  static handlers = [];

  constructor() {
    super();

    EventHandler.addHandler(this);
  }
}

export { EventHandler };
