import React, { Component, Fragment } from 'react';
import { Observer, observer } from 'mobx-react';

import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { PlayerSelect } from '../components/PlayerSelect';

import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';

import './live-input.scss';
import previewImgSrc from './broadcast.live.default.png';
import { Modal } from 'lib/Modal';
import { useTranslation, withTranslation } from 'react-i18next';

const PointTriggerWithPlayerSelect = ({
  observationLogger,
  observationCode,
  team,
  description,
  attributes,
  render,
}) => {
  const players = team.players.toArray();

  if (players.length === 0) {
    return (
      <InputTrigger
        type="point"
        observationCode={observationCode}
        attributes={{ teamId: team.id, ...attributes }}
        observationLogger={observationLogger}
        description={description}
        render={render}
      />
    );
  } else {
    return (
      <>
        <Observer>
          {() => {
            const observationAttributes =
              observationLogger.getCurrentPartialObservationAttributes(
                observationCode
              );
            if (!!observationAttributes) {
              const cancel = () => {
                const command = Command.create(
                  'ObservationLogger.RemovePartialObservation',
                  { groupId: observationCode }
                );
                CommandBus.dispatch(command);
              };
              return (
                <Modal onCloseClick={cancel} size="lg">
                  <div className="row">
                    <PlayerSelect
                      observationLogger={observationLogger}
                      players={team.players.toArray()}
                      observationCode={observationCode}
                      triggerType="end-point"
                    />
                  </div>
                </Modal>
              );
            }
            return null;
          }}
        </Observer>
        <InputTrigger
          type="start-point"
          observationCode={observationCode}
          attributes={{ teamId: team.id, ...attributes }}
          observationLogger={observationLogger}
          description={description}
          groupId={observationCode}
          render={render}
        />
      </>
    );
  }
};

const SoccerObservationInput = withTranslation('module.observe.live')(
  observer(
    class SoccerObservationInput extends Component {
      componentWillMount() {
        const { observationLogger, homeTeam, awayTeam } =
          this.props.observationContext;

        observationLogger.on(
          'afterObservationAdded',
          (code, attributes, triggerTime, startTime, endTime, description) => {
            CommandHistory.instance().tagHistoryItem(description);
          }
        );

        observationLogger.on('descriptionRequested', (code, attributes) => {
          let team, player;
          if (attributes.has('teamId')) {
            if (attributes.get('teamId') === homeTeam.teamId) {
              team = homeTeam;
            } else {
              team = awayTeam;
            }
          }

          if (attributes.has('personId')) {
            player = team.players.get(attributes.get('personId'));
          }

          switch (code) {
            case 'GOAL': {
              let description = this.props.t('goal');
              if (!!player) {
                description += ` - ${player.fullName}`;
              } else {
                description += ` - ${team.label}`;
              }
              return description;
            }
            case 'PENALTY-GIVEN': {
              let description = {
                'GREEN-CARD': this.props.t('greenCard'),
                'YELLOW-CARD': this.props.t('yellowCard'),
                'RED-CARD': this.props.t('redCard'),
              }[attributes.get('type')];

              if (!!player) {
                description += ` - ${player.fullName}`;
              } else {
                description += ` - ${team.label}`;
              }
              return description;
            }
          }
        });
      }

      getTimeButton(currentPeriod) {
        const { observationLogger, timeObservations } =
          this.props.observationContext;
        const [currentState, periodNr, startTime, endTime] = currentPeriod;
        let paused = false;
        const currentPeriodTimeObservations = timeObservations.filter(
          (observation) => {
            return observation.startTime >= startTime;
          }
        );
        for (const timeObservation of currentPeriodTimeObservations) {
          switch (timeObservation.code) {
            case 'TIME:PAUSE':
              paused = true;
              break;
            case 'TIME:RESUME':
              paused = false;
              break;
          }
        }

        const started = currentState === 'STARTED';

        return (
          <div>
            <InputTrigger
              type="point"
              observationCode="TIME:RESUME"
              observationLogger={observationLogger}
              description="Hervat tijd"
              disabled={!started || !paused}
              triggerOnce
              render={(props) => (
                <PointTriggerButton
                  classNames={`tag-standardtag-button ${
                    props.disabled ? 'disabled' : ''
                  }`}
                  {...props}
                  description={
                    <Fragment>&#9654; {this.props.t('resume')}</Fragment>
                  }
                  keys=""
                  disableAfterTrigger
                />
              )}
            />
            <InputTrigger
              type="point"
              observationCode="TIME:PAUSE"
              observationLogger={observationLogger}
              description="Pauseer tijd"
              disabled={!started || paused}
              triggerOnce
              render={(props) => (
                <PointTriggerButton
                  classNames={`tag-standardtag-button ${
                    props.disabled ? 'disabled' : ''
                  }`}
                  {...props}
                  description={
                    <Fragment>
                      <b>||</b>&nbsp;&nbsp;{this.props.t('pause')}
                    </Fragment>
                  }
                  disableAfterTrigger
                  keys=""
                />
              )}
            />
          </div>
        );
      }
      render() {
        const wide = {
          width: '100%',
          textAlign: 'center',
          marginTop: '5%',
        };
        const spacing = {
          padding: '20px',
        };
        const homeTeam = this.props.observationContext.homeTeam;
        const awayTeam = this.props.observationContext.awayTeam;
        const observationLogger =
          this.props.observationContext.observationLogger;
        const currentPeriod = this.props.observationContext.currentPeriod;
        const periodState = currentPeriod[0];

        return (
          <table style={wide} className="larger-button">
            <thead />
            <tbody>
              <tr>
                <td style={spacing}>
                  <PointTriggerWithPlayerSelect
                    observationCode="GOAL"
                    team={homeTeam}
                    observationLogger={observationLogger}
                    description={this.props.t('goal')}
                    render={(props) => (
                      <PointTriggerButton
                        classNames="tag-standardtag-button"
                        {...props}
                        keys=""
                      />
                    )}
                  />
                </td>
                <td />
                <td>
                  <PointTriggerWithPlayerSelect
                    observationCode="GOAL"
                    team={awayTeam}
                    observationLogger={observationLogger}
                    description={this.props.t('goal')}
                    render={(props) => (
                      <PointTriggerButton
                        classNames="tag-standardtag-button"
                        {...props}
                        keys=""
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td style={spacing}>
                  <InputTrigger
                    type="point"
                    observationCode="HIGHLIGHT"
                    attributes={{ teamId: homeTeam.id }}
                    observationLogger={observationLogger}
                    description={this.props.t('highlight')}
                    render={(props) => (
                      <PointTriggerButton {...props} keys="" />
                    )}
                  />
                </td>
                <td>
                  <InputTrigger
                    type="point"
                    observationCode="TIME:CORRECTION"
                    attributes={{ seconds: -1 }}
                    observationLogger={observationLogger}
                    description={this.props.t('timeSubtract1')}
                    render={(props) => (
                      <PointTriggerButton
                        classNames={`tag-standardtag-button ${
                          periodState !== 'STARTED' ? 'disabled' : ''
                        }`}
                        {...props}
                        keys=""
                      />
                    )}
                  />
                  <InputTrigger
                    type="point"
                    observationCode="TIME:CORRECTION"
                    attributes={{ seconds: 1 }}
                    observationLogger={observationLogger}
                    description={this.props.t('timeAdd1')}
                    render={(props) => (
                      <PointTriggerButton
                        classNames={`tag-standardtag-button ${
                          periodState !== 'STARTED' ? 'disabled' : ''
                        }`}
                        {...props}
                        keys=""
                      />
                    )}
                  />
                </td>
                <td>
                  <InputTrigger
                    type="point"
                    observationCode="HIGHLIGHT"
                    attributes={{ teamId: awayTeam.id }}
                    observationLogger={observationLogger}
                    description={this.props.t('highlight')}
                    render={(props) => (
                      <PointTriggerButton {...props} keys="" />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td style={spacing}>
                  <PointTriggerWithPlayerSelect
                    observationCode="PENALTY-GIVEN"
                    attributes={{ type: 'RED-CARD' }}
                    team={homeTeam}
                    observationLogger={observationLogger}
                    description={this.props.t('redCard')}
                    render={(props) => (
                      <PointTriggerButton {...props} keys="" />
                    )}
                  />
                </td>
                <td>{this.getTimeButton(currentPeriod)}</td>
                <td>
                  <PointTriggerWithPlayerSelect
                    observationCode="PENALTY-GIVEN"
                    attributes={{ type: 'RED-CARD' }}
                    team={awayTeam}
                    observationLogger={observationLogger}
                    description={this.props.t('redCard')}
                    render={(props) => (
                      <PointTriggerButton {...props} keys="" />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td style={spacing}>
                  <PointTriggerWithPlayerSelect
                    observationCode="PENALTY-GIVEN"
                    attributes={{ type: 'YELLOW-CARD' }}
                    team={homeTeam}
                    observationLogger={observationLogger}
                    description={this.props.t('yellowCard')}
                    render={(props) => (
                      <PointTriggerButton {...props} keys="" />
                    )}
                  />
                </td>
                <td />
                <td>
                  <PointTriggerWithPlayerSelect
                    observationCode="PENALTY-GIVEN"
                    attributes={{ type: 'YELLOW-CARD' }}
                    team={awayTeam}
                    observationLogger={observationLogger}
                    description={this.props.t('yellowCard')}
                    render={(props) => (
                      <PointTriggerButton {...props} keys="" />
                    )}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        );
      }
    }
  )
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'broadcast.live.default',
  version: 1,
  component: SoccerObservationInput,
  options,
  title: 'Broadcast Live',
  previewImgSrc,
});
