import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { PlaylistOverviewPage } from './overview-page';
import { PlaylistViewPage } from './view-page';

import './index.scss';
import { PlaylistView } from './view';

const MODULE_NAME = 'playlist';

const playlistModule = new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('overview', PlaylistView, 'overview'),
    new RouteExact('view/:playlistId', PlaylistViewPage, 'view'),
    new RouteRedirect('', 'overview'),
  ]
);

export default playlistModule;
