import React from 'react';
import { MarketingToggle } from 'modules/upcoming';
import _ from 'lodash';
import { Session } from '../../../domain/Session';

// TODO HERE receive active app
const resourceGroupTypes = {
  reseller: {
    menuItems: (session, t, toggle) => {
      return [
        {
          url: '/reseller/overview',
          title: t('clubs'),
        },
      ];
    },
    landingsPage: '/reseller/overview',
  },
  team: {
    menuItems: (session, t, toggle) => {
      const menuItems = [
        {
          url: '/match',
          title: t('matches'),
        },
      ];
      menuItems.push({
        url: '/trainings',
        title: _.startCase(t('module.match:typePlural.training')),
      });

      menuItems.push({
        url: '/playlist',
        title: t('playlists'),
        locked: [
          {
            check: !session.isFeatureAvailable('viewPlaylist'),
            reason: 'playlistsNoLicense',
          },
        ],
      });
      menuItems.push({
        url: '/highlights/overview',
        title: t('module.highlights:highlights'),
        locked: [
          {
            check: !session
              .licensePrivileges()
              .hasPrivilege('highlights/video:view'),
            reason: 'highlightsNoLicense',
          },
        ],
      });

      menuItems.push({
        url: '/development/tdp',
        title: t('development'),
        locked: [
          {
            check: !session.isFeatureAvailable('viewDevelopment'),
            reason: 'developmentNoLicense',
          },
        ],
      });
      // () => <MarketingToggle messageKey="trainings" onClick={toggle} />,
      return menuItems;
    },
    landingsPage: '/home',
  },
  SharingGroup: {
    menuItems: (session, t, toggle) => {
      return [
        {
          url: '/match',
          title: t('matches'),
        },
        {
          url: '/shares/outgoing',
          title: t('participants'),
        },
      ];
    },
    landingsPage: '/match',
  },
  club: {
    menuItems: (session, t, toggle) => {
      return [
        {
          url: '/user-management/teams',
          title: t('teams'),
        },
      ];
    },
    landingsPage: '/user-management/teams',
  },
  education: {
    menuItems: (session, t, toggle) => {
      return [
        {
          url: '/user-management/teams',
          title: t('teams'),
        },
      ];
    },
    landingsPage: '/user-management/teams',
  },
  learningGroupAdmin: {
    menuItems: (session, t, toggle) => {
      return [
        {
          url: '/development/learningGroups',
          title: t('courses'),
        },
      ];
    },
    landingsPage: '/development/learningGroups',
  },
  learningGroupTrainee: {
    menuItems: (session, t, toggle) => {
      const menuItems = [
        {
          url: '/match',
          title: t('matches'),
        },
        {
          url: '/development/tdp/detail',
          title: t('development'),
        },
      ];
      const parentResourceGroup = session.currentParentResourceGroup();
      if (parentResourceGroup) {
        menuItems.push({
          url: '/user-management/team/all',
          title: t('teachersAndPeers'),
        });
        menuItems.push({
          url: `/development/learningGroups/current?resource-group-id=${session.currentParentResourceGroupId()}`,
          title: (
            <div className={'d-flex align-content-center align-items-center'}>
              <i className="i-corner-left-up i-light i-xs"></i>
              {parentResourceGroup.targetResourceName}
            </div>
          ),
        });
      }
      return menuItems;
    },
    landingsPage: '/development/tdp/detail',
  },
  LearningGroup: {
    // Docent

    menuItems: (session, t, toggle) => {
      const menuItems = [
        {
          url: '/development/learningGroups/current',
          title: t('course'),
        },
        {
          url: '/user-management/user-settings/invitations',
          title: t('module.settings.account:invitations'),
        },
      ];
      const parentResourceGroup = session.currentParentResourceGroup();
      if (parentResourceGroup) {
        menuItems.push({
          url: `/development/learningGroups?resource-group-id=${session.currentParentResourceGroupId()}`,
          title: (
            <div className={'d-flex align-content-center align-items-center'}>
              <i className="i-corner-left-up i-light i-xs"></i>
              {parentResourceGroup?.targetResourceName}
            </div>
          ),
        });
      }
      return menuItems;
    },
    landingsPage: `/development/learningGroups/current`,
  },
  person: {
    /// SR
    menuItems: (session, t, toggle) => {
      const menuItems = [
        {
          url: '/recordings',
          title: t('matches'),
        },
      ];

      menuItems.push({
        url: '/development/tdp',
        title: t('development'),
        locked: {
          check: !session.isFeatureAvailable('viewDevelopment'),
          reason: 'developmentNoLicense',
        },
      });

      if (session.isFeatureAvailable('viewPOP')) {
        menuItems.push({
          url: '/development/pdp/table',
          title: t('pdp'),
        });
      }

      menuItems.push({
        url: '/playlist',
        title: t('playlists'),
        locked: [
          {
            check: !session.isFeatureAvailable('viewPlaylist'),
            reason: 'playlistsNoLicense',
          },
        ],
      });
      // () => <MarketingToggle messageKey="trainings" onClick={toggle} />,
      return menuItems;
    },
    landingsPage: '/home',
  },
  exchange: {
    menuItems: (session, t, toggle) => [
      {
        url: '/shares/overview',
        title: 'Poule overzicht',
      },
    ],
    landingsPage: '/shares/overview',
  },
  'app-developer': {
    menuItems: (session, t, toggle) => {
      return [
        {
          url: '/exercise',
          title: 'Oefeningen',
        },
        {
          url: '/training-templates',
          title: 'Kant-en-klare trainingen',
        },
      ];
    },
    landingsPage: '/exercise',
  },
};

const menuItemsForType = ({ session, t, toggle, app }, groupType) => {
  if (app.appId !== 'teamtv') {
    return [];
  }

  if (groupType === 'education') {
    if (
      session.isFeatureAvailable('editLearningGroups') &&
      !window.isSkillReflect
    ) {
      groupType = 'learningGroupAdmin'; // todo: Grouptype for learningGroupAdmin
    }
  }
  if (groupType === 'person') {
    if (!window.isSkillReflect) {
      groupType = 'learningGroupTrainee'; //todo: Grouptype for learningGroupTrainee
    }
  }
  const resourceGroupType = resourceGroupTypes[groupType];
  if (resourceGroupType) {
    return resourceGroupType.menuItems(session, t, toggle);
  } else {
    return [];
  }
};

const landingsPageForType = (groupType, currentSession) => {
  if (groupType === 'education') {
    if (
      currentSession.isFeatureAvailable('editLearningGroups') &&
      !window.isSkillReflect
    ) {
      groupType = 'learningGroupAdmin';
    }
  }

  if (groupType === 'person') {
    if (!window.isSkillReflect) {
      groupType = 'learningGroupTrainee';
    }
  }

  const resourceGroupType = resourceGroupTypes[groupType];

  if (resourceGroupType) {
    return resourceGroupType.landingsPage;
  } else {
    return '/home';
  }
};

export { menuItemsForType, landingsPageForType };
