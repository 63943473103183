export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    fontWeight: 'normal',
    maxHeight: 150,
  },

  '&multiLine': {
    control: {
      minHeight: 36,
      maxHeight: 150,
      color: '#000',
    },
    highlighter: {
      padding: '5px 8px',
      border: '1px solid transparent',
      overflow: 'hidden',
      maxHeight: 150,
    },
    input: {
      padding: '5px 8px',
      maxHeight: 150,
      border: '1px solid silver',
    },
    substring: {
      visibility: 'shown',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      borderRadius: 7,
      overflow: 'hidden',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#e3fff1',
      },
    },
  },
};
