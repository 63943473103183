import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';
import { withTranslation } from 'react-i18next';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';
import {
  VideoObservationPanel,
  PossessionButtonGroup,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';

// observationContext and onOpenManageCustomTags should be fetched
// from context
const SoccerObservationInput = withTranslation('module.observe.video.soccer')(
  observer(
    class SoccerObservationInput extends Component {
      componentWillMount() {
        const {
          observationLogger,
          homeTeam,
          awayTeam,
          t,
        } = this.props.observationContext;

        const switchPossession = () => {
          {
            const teamSwitchMap = {
              [homeTeam.id]: awayTeam.id,
              [awayTeam.id]: homeTeam.id,
            };
            const partialObservationAttributeBag = observationLogger.getCurrentPartialObservationAttributes(
              'POSSESSION'
            );
            if (!partialObservationAttributeBag) {
              return;
            }

            const newTeamId = {
              teamId:
                teamSwitchMap[partialObservationAttributeBag.get('teamId')],
            };

            const command = Command.create(
              'ObservationLogger.ToggleRangeObservationExclusive',
              {
                groupId: 'POSSESSION',
                code: 'POSSESSION',
                attributes: newTeamId,
              }
            );
            CommandBus.dispatch(command);
          }
        };

        const setBuildUpPhase = phase => {
          const command = Command.create(
            'ObservationLogger.ToggleRangeObservationExclusive',
            {
              groupId: 'BUILD-UP',
              code: 'BUILD-UP',
              attributes: { phase },
            }
          );
          CommandBus.dispatch(command);
        };

        observationLogger.on(
          'beforeObservationAdded',
          (observationCode, attributes) => {
            switch (observationCode) {
              case 'POSSESSION':
                const command = Command.create(
                  'ObservationLogger.TurnoffGroup',
                  {
                    groupId: 'BUILD-UP',
                  }
                );
                CommandBus.dispatch(command);
                break;
            }
          }
        );

        observationLogger.on(
          'afterObservationAdded',
          (observationCode, attributes) => {
            switch (observationCode) {
              case 'SHOT':
                if (attributes.get('result') === 'GOAL') {
                  switchPossession();

                  setBuildUpPhase('OWNHALF');
                }
                break;

              case 'BALL-LOSS': {
                const buildUpPartialObservationAttributeBag = observationLogger.getCurrentPartialObservationAttributes(
                  'BUILD-UP'
                );

                {
                  const command = Command.create(
                    'ObservationLogger.TurnoffGroup',
                    {
                      groupId: 'BUILD-UP',
                    }
                  );
                  CommandBus.dispatch(command);
                }
                switchPossession();
                {
                  const phaseSwitchMap = {
                    OWNHALF: 'OPPONENTSHALF',
                    OPPONENTSHALF: 'OWNHALF',
                    ATTACK: 'OWNHALF',
                  };
                  if (!buildUpPartialObservationAttributeBag) {
                    return;
                  }

                  const newBuildUpPhase =
                    phaseSwitchMap[
                      buildUpPartialObservationAttributeBag.get('phase')
                    ];
                  setBuildUpPhase(newBuildUpPhase);
                }
                break;
              }
            }
          }
        );

        observationLogger.on('descriptionRequested', (code, attributes) => {
          switch (code) {
            case 'POSSESSION':
              return t('possession', {
                teamName:
                  attributes.get('teamId') === homeTeam.id
                    ? homeTeam.label
                    : awayTeam.label,
              });
              break;
            case 'BUILD-UP':
              switch (attributes.get('phase')) {
                case 'OWNHALF':
                  return t('attack.buildUpOwnHalf');
                case 'OPPONENTSHALF':
                  return t('attack.buildUpOpponent');
                case 'ATTACK':
                  return t('attack.buildUpAttack');
                default:
                  throw "Don't know how to describe build-up phase" +
                    attributes.get('phase');
              }

            default:
              throw "Don't know how to describe " + code;
          }
        });

        observationLogger.on(
          'afterObservationAdded',
          (code, attributes, triggerTime, startTime, endTime, description) => {
            CommandHistory.instance().tagHistoryItem(description);
          }
        );
      }

      render() {
        const t = this.props.t;
        const homeTeam = this.props.observationContext.homeTeam;
        const awayTeam = this.props.observationContext.awayTeam;
        const observationLogger = this.props.observationContext
          .observationLogger;

        const possessionLabel = t('possession');

        return (
          <VideoObservationPanel
            observationContext={this.props.observationContext}
          >
            <PossessionButtonGroup>
              <InputTrigger
                type="toggle-range"
                observationCode="POSSESSION"
                groupId="POSSESSION"
                attributes={{ teamId: homeTeam.id }}
                observationLogger={observationLogger}
                description={t('possession', homeTeam.label)}
                render={({ active, trigger }) => (
                  <div
                    onClick={trigger}
                    className={`clickable set-homepossession-button label ${
                      active ? 'active' : ''
                    } `}
                  >
                    <HotKey keys={['1']} onKeysPressed={trigger} />
                    {t('possession', { teamName: homeTeam.shortLabel })}
                    <span className="button-hotkey">1</span>
                    <img className="i" src={homeTeam.logoUrl} />
                  </div>
                )}
              />

              <InputTrigger
                type="point"
                observationCode="BALL-LOSS"
                attributes={{ type: 'TURNOVER' }}
                observationLogger={observationLogger}
                description={t('turnover')}
                render={props => <PointTriggerButton {...props} keys="o" />}
              />

              <InputTrigger
                type="point"
                observationCode="BALL-LOSS"
                observationLogger={observationLogger}
                description={t('ball-loss')}
                render={props => <PointTriggerButton {...props} keys="b" />}
              />

              <InputTrigger
                type="toggle-range"
                observationCode="POSSESSION"
                groupId="POSSESSION"
                attributes={{ teamId: awayTeam.id }}
                description={t('possession', { teamName: awayTeam.label })}
                observationLogger={observationLogger}
                render={({ active, trigger }) => (
                  <div
                    onClick={trigger}
                    className={`clickable label label-i-lft ${
                      active ? 'active' : ''
                    } `}
                  >
                    <HotKey keys={['2']} onKeysPressed={trigger} />
                    <img className="i" src={awayTeam.logoUrl} />
                    {t('possession', { teamName: awayTeam.label })}
                    <span className="button-hotkey">2</span>
                  </div>
                )}
              />
            </PossessionButtonGroup>
            <EventButtonGroup>
              <InputTrigger
                type="toggle-range"
                observationCode="BUILD-UP"
                attributes={{ phase: 'OWNHALF' }}
                groupId="BUILD-UP"
                observationLogger={observationLogger}
                description={t('attack.buildUpOwn')}
                render={props => (
                  <RangeTriggerButton
                    {...props}
                    classNames="tag-rangetag-button"
                    keys="q"
                  />
                )}
              />

              <InputTrigger
                type="toggle-range"
                observationCode="BUILD-UP"
                attributes={{ phase: 'OPPONENTSHALF' }}
                groupId="BUILD-UP"
                observationLogger={observationLogger}
                description={t('attack.buildUpOpponent')}
                render={props => (
                  <RangeTriggerButton
                    {...props}
                    classNames="tag-rangetag-button"
                    keys="w"
                  />
                )}
              />

              <InputTrigger
                type="toggle-range"
                observationCode="BUILD-UP"
                attributes={{ phase: 'ATTACK' }}
                groupId="BUILD-UP"
                observationLogger={observationLogger}
                description={t('attack.buildUpAttack')}
                render={props => (
                  <RangeTriggerButton
                    {...props}
                    classNames="tag-rangetag-button"
                    keys="e"
                  />
                )}
              />

              <InputTrigger
                type="point"
                observationCode="CORNER-KICK"
                observationLogger={observationLogger}
                description={t('setPieces.corner')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="c"
                  />
                )}
              />

              <InputTrigger
                type="point"
                observationCode="FREE-KICK"
                observationLogger={observationLogger}
                description={t('setPieces.freeKick')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="v"
                  />
                )}
              />

              <InputTrigger
                type="point"
                observationCode="GOAL-KICK"
                observationLogger={observationLogger}
                description={t('setPieces.goalKick')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="d"
                  />
                )}
              />

              <InputTrigger
                type="point"
                observationCode="SHOT"
                attributes={{ result: 'OFF-TARGET' }}
                observationLogger={observationLogger}
                description={t('shot.offTarget')}
                render={props => <PointTriggerButton {...props} keys="m" />}
              />
              <InputTrigger
                type="point"
                observationCode="SHOT"
                attributes={{ result: 'SAVED' }}
                observationLogger={observationLogger}
                description={t('shot.saved')}
                render={props => <PointTriggerButton {...props} keys="k" />}
              />
              <InputTrigger
                type="point"
                observationCode="SHOT"
                attributes={{ result: 'GOAL' }}
                observationLogger={observationLogger}
                description={t('shot.goal')}
                render={props => <PointTriggerButton {...props} keys="g" />}
              />
              <InputTrigger
                type="point"
                observationCode="SHOT"
                attributes={{ result: 'BLOCKED' }}
                observationLogger={observationLogger}
                description={t('shot.blocked')}
                render={props => <PointTriggerButton {...props} keys="h" />}
              />

              <InputTrigger
                type="point"
                observationCode="THROW-IN"
                observationLogger={observationLogger}
                description={t('throwIn')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="i"
                  />
                )}
              />
              <InputTrigger
                type="point"
                observationCode="FOUL"
                observationLogger={observationLogger}
                description={t('foul')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="t"
                  />
                )}
              />

              <InputTrigger
                type="point"
                observationCode="PENALTY-KICK"
                observationLogger={observationLogger}
                description={t('setPieces.penalty')}
                render={props => (
                  <PointTriggerButton
                    classNames="tag-standardtag-button"
                    {...props}
                    keys="p"
                  />
                )}
              />
            </EventButtonGroup>
          </VideoObservationPanel>
        );
      }
    }
  )
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'soccer.video.default',
  component: SoccerObservationInput,
  options,
  usedKeys: [
    '1',
    '2',
    'b',
    'c',
    'd',
    'e',
    'g',
    'h',
    'i',
    'k',
    'm',
    'o',
    'p',
    'q',
    't',
    'v',
    'w',
  ],
  title: 'Soccer Video',
});
