import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { action, observable, runInAction } from 'mobx';

import SportingEventCollection from '../../../domain/SportingEvent';
import PersonCollection from '../../../domain/Person';
import { lineUpCollection } from '../../../domain/LineUp';

import { Session } from './../../../domain/Session';
import { ObservationContext } from '../../../domain/ObservationLogger';
import { CommandHistory } from '../../../infra/Messaging';
import { registry as observationInputRegistry } from '../../../utils/ObservationInput';

import { Modal, Overlay } from 'lib/Modal';
import { ButtonBar } from '../components/ButtonBar';
import { EventInput } from '../components/EventInput';
import { FormattedTimeObserver } from 'lib/FormattedTime';
import {
  KorfballLineUpLayout,
  LineUpInput,
} from '../../lineup/components/index';
import { CommandBus, Command, HistoryObserver } from '../../../infra/Messaging';
import Loading from 'lib/Loading';
import { asyncAction } from 'mobx-utils';
import logger from '../../../utils/Logger';
import { withTranslation } from 'react-i18next';
import { Page } from 'lib/Page';
import './live.scss';
import { Error, TurnScreenToUseFunctionality } from 'lib/PlaceHolder';
import { ObservationDetails } from './observation-details';
import {
  ObservationsEditCollectionContextProvider,
  ObservationsEditCollectionContext,
} from 'modules/observe/edit-collection-context';
import { ObservationContextProvider } from 'domain/ObservationLogger/ObservationContextProvider';

const GameHeader = observer(({ observationContext }) => (
  <div className="invoer-title relative break-after">
    <div className="relative break-after height-100">
      <div className="flex-content">
        <div id="team-1" className="team-title float-left text-right">
          <div id="team-score-1" className="float-right team-score">
            <h1>{observationContext.homeGoalCounter}</h1>
          </div>
          <img
            alt="home team"
            className="team-thumb float-right"
            src={observationContext.homeTeam.logoUrl}
          />
          <h1 className="inline-block float-right team-name">
            {observationContext.homeTeam.label}
          </h1>
        </div>
        <div id="team-2" className="team-title float-left text-left">
          <div id="team-score-1" className="float-left team-score">
            <h1>{observationContext.awayGoalCounter}</h1>
          </div>
          <img
            alt="away team"
            className="team-thumb float-left"
            src={observationContext.awayTeam.logoUrl}
          />
          <h1 className="inline-block float-left team-name">
            {observationContext.awayTeam.label}
          </h1>
        </div>
        <div className="invoer-time text-center">
          <FormattedTimeObserver timeFn={observationContext.currentTimeFn} />
        </div>
      </div>
    </div>
  </div>
));

const SubstitutionInput = withTranslation('module.observe.live')(
  ({ observationContext, onDone, t }) => {
    const teamRoles = {
      [observationContext.sportingEvent.homeTeam.id]:
        observationContext.getTeamRoles(
          observationContext.sportingEvent.homeTeam.id
        ),
      [observationContext.sportingEvent.awayTeam.id]:
        observationContext.getTeamRoles(
          observationContext.sportingEvent.awayTeam.id
        ),
    };

    return (
      <LineUpInput
        layout={KorfballLineUpLayout}
        sportingEvent={observationContext.sportingEvent}
        teamRoles={teamRoles}
        inModal
        doneButton={
          <div className="btn btn-primary" onClick={onDone}>
            {t('common:cancel')}
          </div>
        }
        onCreateAndAddToTeam={({
          firstName,
          lastName,
          gender,
          teamId,
          number,
        }) => {
          const command = Command.create('PersonService.CreateAndAddToTeam', {
            firstName,
            lastName,
            gender,
            teamId,
            number,
          });
          CommandBus.dispatch(command);
        }}
        onSwapUnAssignedWithPlayer={({
          teamId,
          inPersonId,
          outPersonId,
          position,
        }) => {
          observationContext.addSubstitution(
            teamId,
            inPersonId,
            outPersonId,
            position
          );

          //make sure the onDragEnd is finished before this component might be unloaded
          setTimeout(onDone, 10);
        }}
      />
    );
  }
);

const SportingEventObservation = withTranslation('module.observe.live')(
  observer(
    class SportingEventObservation extends Component {
      constructor() {
        super();

        this.dataState = observable('loading');

        this._observationContext = null;
        this.eventModalVisible = observable(false);
        this.substituteModalVisible = observable(false);
        this.selectedObservation = observable(null);
      }

      selectObservation = action((observation) => {
        this.selectedObservation.set(observation);
      });

      unsetSelectedObservation = action(() => {
        this.selectedObservation.set(null);
      });

      componentWillMount() {
        CommandHistory.instance().resetHistory();

        this.loadData();
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          this.sportingEvent = yield SportingEventCollection.getOrFetch(
            this.props.match.params.sportingEventId
          );
          let lineUp;
          if (this.sportingEvent.get('type') === 'match') {
            const _lineUp = lineUpCollection.getOrFetch(
              this.sportingEvent.get('lineUpId')
            );

            yield Promise.all([
              PersonCollection.fetchIfEmpty(),
              this.sportingEvent.loadTeams(),
              _lineUp,
            ]);
            lineUp = yield _lineUp;
          } else {
            yield Promise.all([PersonCollection.fetchIfEmpty()]);
          }

          this.observationContext = new ObservationContext(
            this.sportingEvent,
            lineUp,
            'U1'
          );

          yield this.observationContext.init();

          if (Session.current().isFeatureAvailable('observeDirectDetails')) {
            const observationLogger = this.observationContext.observationLogger;

            observationLogger.on('afterObservationAdded', () => {
              setTimeout(() => {
                const observations = observationLogger.observationLog.toArray();

                runInAction(() => {
                  const lastObservation = observations[observations.length - 1];
                  if (lastObservation.code !== 'CUSTOM') {
                    this.selectObservation(lastObservation);
                  }
                });
              }, 10);
            });
          }

          this.dataState.set('loaded');
        } catch (e) {
          console.log(e);
          logger.error(e, {
            transactionName: 'Unable to start Live Tagging',
          });
          this.dataState.set('error');
        }
      });

      speak(string) {
        const msg = new window.SpeechSynthesisUtterance(string);
        msg.lang = 'nl-NL';
        window.speechSynthesis.speak(msg);
      }

      onGoBack() {
        this.props.history.push(
          `/match/detail/${this.props.match.params.sportingEventId}`
        );
      }

      updateObservation(observation, { attributes = null }) {
        let changedAttributes = {};

        if (attributes !== null) {
          for (const key of Object.keys(attributes)) {
            if (observation.attributes_[key] !== attributes[key]) {
              changedAttributes[key] = attributes[key];
            }
          }
        }

        if (Object.keys(changedAttributes).length > 0) {
          const commandParams = {
            observationId: observation.id, // Q: why not observation.observationId?
            attributes: changedAttributes,
            clockId: observation.clockId,
            description: observation.description,
            triggerTime: observation.triggerTime,
            startTime: observation.startTime,
            endTime: observation.endTime,
          };
          const command = Command.create(
            'ObservationLogger.UpdateObservation',
            commandParams
          );
          CommandBus.dispatch(command);
        }
      }

      deleteObservation(observation) {
        const command = Command.create('ObservationLog.RemoveObservation', {
          observationId: observation.id,
        });
        CommandBus.dispatch(command);
        if (observation._mergedObservationId) {
          const command = Command.create('ObservationLog.RemoveObservation', {
            observationId: observation._mergedObservationId,
          });
          CommandBus.dispatch(command);
        }
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const observationContext = this.observationContext;
        // const observationLogger = observationContext.observationLogger;

        const actionHandler = (action) => {
          switch (action) {
            case 'undo':
              CommandHistory.instance().undoToPreviousTaggedHistoryItem();
              break;
            case 'togglePlayDirection':
              this.observationContext.togglePlayDirection();
              runInAction(() => {
                this.eventModalVisible.set(false);
              });
              break;
            case 'togglePeriod':
              this.observationContext.togglePeriod();
              break;
            case 'enterEvent':
              runInAction(() => {
                this.eventModalVisible.set(true);
              });
              break;
            case 'substitutePlayer':
              runInAction(() => {
                this.substituteModalVisible.set(true);
              });
              break;

            case 'goal-home':
            case 'goal-away':
              this.observationContext.addGoalCorrection(action.split('-')[1]);
              runInAction(() => {
                this.eventModalVisible.set(false);
              });
              break;
            case 'yellow-card':
            case 'red-card':
            case 'penalty-shot':
            case 'free-ball':
              this.observationContext.addPenaltyGiven(action);
              runInAction(() => {
                this.eventModalVisible.set(false);
              });
              break;
            case 'ball-loss':
              this.observationContext.addBallLoss();
              break;
            case 'observation-error':
              this.observationContext.addObservationError();
              runInAction(() => {
                this.eventModalVisible.set(false);
              });
              break;
            default:
              console.warn('unhandled action: ', action);
          }
        };

        const observationInputKey = this.props.match.params.observationInputKey;

        const observationInput =
          observationInputRegistry.getObservationInputByKey(
            observationInputKey
          );
        const ObservationInputComponent = observationInput.component;

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { path: '/match', title: this.props.t('module.match:matches') },
            {
              title: `${this.sportingEvent.homeTeam.label} - ${this.sportingEvent.awayTeam.label}`,
            },
          ],
        };

        const sportType = Session.current().sportType();

        if (observationInput.version !== 2) {
          return [
            <Page hideFooter fullWidth key="observation-input" {...pageProps}>
              <ObservationsEditCollectionContextProvider
                observations={this.observationContext.observationsWithTime()}
              >
                <div
                  className={`observation-input-live ${
                    sportType ? '' : 'no-sport'
                  } period-state-${observationContext.periodLabelProps.periodState.toLowerCase()} landscape-show`}
                >
                  {sportType &&
                    observationContext.sportingEvent.get('type') !==
                      'training' && (
                      <GameHeader observationContext={observationContext} />
                    )}

                  <div
                    className={`${
                      sportType &&
                      observationContext.sportingEvent.get('type') !==
                        'training'
                        ? 'invoer-veld'
                        : ''
                    } relative`}
                  >
                    <ObservationsEditCollectionContext.Consumer>
                      {({ startEdit }) => (
                        <ObservationInputComponent
                          observationContext={observationContext}
                          args={observationInput.args}
                          afterAdd={startEdit}
                        />
                      )}
                    </ObservationsEditCollectionContext.Consumer>
                  </div>
                  <ButtonBar
                    actionHandler={actionHandler}
                    periodLabel={this.props.t(
                      `buttonBarPeriodLabel.${observationContext.periodLabelProps.periodState}`,
                      { periodNr: observationContext.periodLabelProps.periodNr }
                    )}
                    observationInputOptions={{
                      ...observationInput.options,
                      canUndo:
                        observationInput.options.canUndo &&
                        HistoryObserver.instance().canUndo(),
                    }}
                  />
                  {this.eventModalVisible.get() && (
                    <Modal
                      size="lg"
                      onCloseClick={action(() =>
                        this.eventModalVisible.set(false)
                      )}
                    >
                      <EventInput
                        homeTeamName={observationContext.homeTeam.label}
                        awayTeamName={observationContext.awayTeam.label}
                        actionHandler={actionHandler}
                      />
                    </Modal>
                  )}

                  <ObservationDetails
                    onCloseClick={this.unsetSelectedObservation}
                    onDelete={(observation) => {
                      this.deleteObservation(observation);
                    }}
                    onUpdateObservation={(
                      observation,
                      { attributes, description }
                    ) => {
                      this.updateObservation(observation, {
                        attributes,
                        description,
                      });
                    }}
                    sportType={sportType}
                  />
                </div>
                <div key="turn-device-to-use" className="portrait-show">
                  <TurnScreenToUseFunctionality />
                </div>
              </ObservationsEditCollectionContextProvider>
            </Page>,

            this.substituteModalVisible.get() && (
              <Overlay>
                <SubstitutionInput
                  observationContext={observationContext}
                  onDone={action(() => this.substituteModalVisible.set(false))}
                />
              </Overlay>
            ),
          ];
        } else {
          return (
            <Page hideFooter fullWidth {...pageProps}>
              <div className="landscape-show">
                <ObservationContextProvider
                  observationContext={observationContext}
                  sportType={Session.current().sportType()}
                >
                  <ObservationInputComponent />
                </ObservationContextProvider>
              </div>
              <div key="turn-device-to-use" className="portrait-show">
                <TurnScreenToUseFunctionality />
              </div>
            </Page>
          );
        }
      }
    }
  )
);

export default SportingEventObservation;
