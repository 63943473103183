import React from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';

import failWhale from 'img/error.svg';
import pageNotFound from 'img/pagenotfound.svg';
import noLicense from 'img/no_license.svg';
import rotate from 'img/icons/rotate.svg';
import noContent from 'img/no-content.png';
import upgrade from 'img/upgrade.svg';

import styles from './index.module.scss';

const PlaceHolder = ({ imgSrc, imgStyle, description }) => (
  <div className={styles.PlaceHolder}>
    {imgSrc && <img src={imgSrc} alt={description} style={imgStyle} />}
    <p className={styles.description}>{description}</p>
  </div>
);

const NoResults = ({ imgSrc = noContent, description = 'No results' }) => (
  <PlaceHolder imgSrc={imgSrc} description={description} />
);

const NotFound = ({ imgSrc = noContent, description = 'Not found' }) => (
  <PlaceHolder imgSrc={imgSrc} description={description} />
);

const Error = () => <MainPageErrorFallback />;

const NoLicense = withTranslation('placeholder')(({ t }) => (
  <PlaceHolder
    imgSrc={noLicense}
    description={
      <Trans i18nKey="noLicense">
        No license found. <a href="/apps/launch/store">visit store</a>
        and choose a license.
      </Trans>
    }
  />
));
const NoPermissions = withTranslation('placeholder')(({ t, resource }) => {
  return (
    <div style={{ margin: 'auto', textAlign: 'center' }}>
      <img src={upgrade} style={{ marginBottom: '2rem', width: '220px' }} />
      <p>{t('upgrade', { resource: resource })}</p>
      <a className="btn btn-primary" rel={'noopener'} href="/apps/launch/store">
        {t('trial')}
      </a>
    </div>
  );
});

const TurnScreenToUseFunctionality = withTranslation('common')(({ t }) => (
  <PlaceHolder
    imgSrc={rotate}
    imgStyle={{ animation: 'rotate 2s infinite', width: '44px' }}
    description={t('common:turnDeviceToUseFunctionality')}
  />
));

const MainPageErrorFallback = ({ errorType = 'errorMessage' }) => {
  const { t } = useTranslation('errorPage');
  return (
    <div className="container" data-test-id="main-error">
      <div className="row">
        <div
          style={{
            margin: '40px auto',
            textAlign: 'center',
            maxWidth: '460px',
            padding: '32px',
          }}
        >
          <img
            src={errorType === 'pageNotFound' ? pageNotFound : failWhale}
            style={{ marginBottom: '32px' }}
          />
          <h2 style={{ opacity: 0.7 }}>{t(`${errorType}.title`)}</h2>
          <p>{t(`${errorType}.body`)}</p>
          <div className="d-flex justify-content-center flex-wrap">
            <a
              className={'btn btn-secondary'}
              href=""
              onClick={() => window.location.reload()}
              style={{ margin: '12px' }}
            >
              {t(`${errorType}.ctaReload`)}
            </a>

            <a
              className={'btn btn-primary'}
              href="/"
              style={{ margin: '12px' }}
            >
              {t(`${errorType}.ctaBack`)}
            </a>
          </div>
          <div style={{ marginTop: '45px' }}>
            <p style={{ fontSize: '14px' }}>
              {t(`${errorType}.errorPersists`)}{' '}
              <a href={t('module.knowledgeBase:url')}>
                {t('module.knowledgeBase:openKnowledgeBase')}
              </a>{' '}
              {t(`${errorType}.or`)}{' '}
              <a
                href={`mailto:${
                  window.isSkillReflect
                    ? 'support@skillreflect.com'
                    : 'support@teamtv.nl'
                }`}
              >
                {t(`${errorType}.contact`)}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export {
  NoResults,
  NotFound,
  TurnScreenToUseFunctionality,
  Error,
  NoLicense,
  NoPermissions,
  MainPageErrorFallback,
};
