import { getClipConfig } from '../clipRegistry';

export const preprocessContent = (
  template,
  content,
  videos,
  dynamicMediaUrl = false,
  _imageFn = () => {}
) => {
  // The dynamicMediaUrl allows us to update the video id in the frontend, without a pass to the server
  const getClipVideo = (clipVideoId) => {
    for (const video of videos) {
      if (video.videoUri === clipVideoId) {
        return video;
        break;
      }
    }
  };
  let totalDuration = 0;

  const _content = [];

  const _sponsorTimeBlocks = [];

  // Move outro to end of clip list always.
  const outro = content.findIndex((c) => c?.type.includes('outro'));
  if (outro !== -1) {
    content.push(content.splice(outro, 1)[0]);
  }

  content.forEach((item, index) => {
    if (!item) {
      return;
    }
    if (item?.type !== 'clip.Video') {
      const _config = getClipConfig(item.type);

      if (item.type.includes('intro')) {
        // Exception to get thumbnail from first videoClip.
        let firstClip = content.filter((c) => c.type == 'clip.Video')[0];
        if (firstClip) {
          let _video = getClipVideo(firstClip.videoUri);
          content[index].options.thumbnailUrl = _video.thumbnailUrl;
        }
      }
      let duration = _config.duration;
      if (item.type === 'clip.placeholder') {
        if (content[index + 1] && content[index + 1]?.transition) {
          duration = transitionDurationInSeconds(content[index + 1].transition);
        }
      }

      _content.push({
        ...item,
        contentId: item.contentId,
        type: item.type,
        startTime: totalDuration,
        endTime: totalDuration + duration,
        offset: {
          start: _config?.offset?.start ?? 0,
          end: _config?.offset?.end ?? 0,
        },
        options: item.options,
      });

      totalDuration += _config.duration;
    } else {
      let duration = (item.end - item.start) / (item?.playbackRate ?? 1);

      const startPoint = totalDuration;
      const endPoint = totalDuration + duration;
      content[index].startTime = startPoint;
      content[index].endTime = endPoint;
      content[index].offset = {};

      const transitionIn =
        content[index]?.effects && content[index]?.effects[0];
      // next clip has transition
      if (content[index + 1] && content[index + 1].transition) {
        content[index].offset.end =
          0.5 * transitionDurationInSeconds(content[index + 1].transition);
      }
      if (index > 0 && transitionIn) {
        // duration += transitionDurationInSeconds(transitionIn);
        content[index].offset.start =
          0.5 * transitionDurationInSeconds(transitionIn);
      }

      if (item.type === 'clip.Video') {
        // https://github.com/teamtv/platform-frontend/blob/40f2fe6ef5b2c5d28917b41814d4d009e5ea3a4a/src/domain/Video.js#L54
        // use this here since we don;y have VideoCollection.
        const video = getClipVideo(item.videoUri);
        if (dynamicMediaUrl) {
          const start = parseInt(Math.max(0, item.start));
          const end = parseInt(Math.min(video.content.duration, item.end));
          //
          content[
            index
          ].videoUrl = `${video.mediaUrl}&start=${start}&end=${end}`;
        } else {
          content[index].videoUrl = item.videoUrl;
        }

        content[index].thumbnailUrl = video.thumbnailUrl;
        content[index].generateThumbnail = async (size) =>
          _imageFn(video.videoId, item.start, size);
        content[index].videoDuration = video.content.duration;
        content[index].videoId = video.videoId;
      }
      if (item?.overlays?.includes('sponsors')) {
        _sponsorTimeBlocks.push({ start: startPoint, end: endPoint });
      }

      totalDuration += duration;

      _content.push(item);
    }
  });

  // Merge sponsor time blocks over multiple clips
  const mergedIntervals = mergeOverlappingIntervals(_sponsorTimeBlocks);

  return {
    totalDuration,
    content: _content,
    sponsorTimeBlocks: mergedIntervals,
  };
};

function mergeOverlappingIntervals(intervals) {
  // Merge array of start/end times where endtimes overlap starttime.
  /*
  [
    { start: 5, end: 34 },
    { start: 33, end: 45 },
    { start: 50, end: 57 }
  ]

  becomes

  [
    {start: 5, end: 45},
    {start: 50, end: 57}
  ]
  * */
  if (intervals.length <= 1) {
    return intervals;
  }

  // Step 1: Sort the array based on start times
  intervals.sort((a, b) => a.start - b.start);

  // Step 2: Merge overlapping intervals
  let mergedIntervals = [intervals[0]];

  for (let i = 1; i < intervals.length; i++) {
    const currentInterval = intervals[i];
    const lastMergedInterval = mergedIntervals[mergedIntervals.length - 1];

    if (currentInterval.start <= lastMergedInterval.end) {
      // Merge overlapping intervals
      lastMergedInterval.end = Math.max(
        lastMergedInterval.end,
        currentInterval.end
      );
    } else {
      // Add non-overlapping intervals
      mergedIntervals.push(currentInterval);
    }
  }

  return mergedIntervals;
}

export const transitionDurationInSeconds = (effect) => {
  switch (effect) {
    case 'replay':
      return 3.2;
    default:
      return 2;
  }
};
