import { Route, RouteRedirect } from '../route';
import { Module } from '../base';

import './styles/index.scss';

import { Launch, ChooseApp } from './containers';
import { Redirect } from 'react-router-dom';
const MODULE_NAME = 'apps';

export default new Module(
  MODULE_NAME,
  [],
  [
    new Route(
      'launch/teamtv-highlights',
      () => <Redirect to={'/highlights/overview'} />,
      'redirect' // Redirect old route to new module.
    ),
    new Route('launch/:appId/:path', Launch, 'launch-with-path'),
    new Route('launch/:appId', Launch, 'launch'),
    new Route('choose-app', ChooseApp, 'choose-app'),
  ]
);
