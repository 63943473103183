class TourRegistry {
  constructor() {
    this.tours = [];
  }

  register(tour) {
    this.tours.push(tour);
  }

  getTours() {
    return this.tours;
  }

  getApplicableTour(path, currentSession) {
    if (window.isSkillReflect) {
      return null;
    }
    return this.getTours().find(tour =>
      tour.isApplicableTo(path, currentSession)
    );
  }
}

const registry = new TourRegistry();

export default registry;
