import { Model } from 'mobx-rest';
import PersonCollection from './Person';
import { PersistentCollection } from './PersistentCollection';

class Player extends Model {
  get primaryKey() {
    return 'personId';
  }

  changePosition(position) {
    return this.rpc('changePosition', { position }).then(() => {
      return this.fetch();
    });
  }

  changeNumber(number) {
    return this.rpc('changeNumber', { number }).then(() => {
      return this.fetch();
    });
  }

  get person() {
    if (this.has('person')) {
      return this.get('person');
    }
    return PersonCollection.get(this.get('personId'));
  }

  get personId() {
    return this.get('personId');
  }

  get number() {
    return this.get('number');
  }

  get gender() {
    const person = this.person;
    if (person) {
      return person.gender;
    } else {
      return '';
    }
  }

  get code() {
    const lastName = !!this.lastName ? this.lastName : '';
    return (
      this.firstName.substring(0, 1).toUpperCase() +
      lastName.substring(0, 1).toUpperCase()
    );
  }

  get fullName() {
    const person = this.person;
    if (person) {
      return person.fullName;
    } else {
      return '';
    }
  }

  get profilePictureUrl() {
    const person = this.person;
    if (person) {
      return person.profilePictureUrl;
    } else {
      return '';
    }
  }

  get firstName() {
    const person = this.person;
    if (person) {
      return person.firstName;
    } else {
      return '';
    }
  }
  get lastName() {
    const person = this.person;
    if (person) {
      return person.lastName;
    } else {
      return '';
    }
  }
}

class PlayerCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Player;
  }
}

export default PlayerCollection;
