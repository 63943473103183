import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { withTranslation } from 'react-i18next';

export interface ObservationPlayersAttributes {
  personId: string | null;
  participantsPersonIds: string[] | null;
}

export const ObservationDetailsPlayers = withTranslation(
  'module.observe.video'
)(
  ({
    t,
    observation,
    attributesOptions,
    onChange,
  }: {
    t: (key: string) => string | undefined;
    observation: Observation;
    attributesOptions: {};
    onChange: (value: ObservationPlayersAttributes) => void;
  }) => {
    const [personId, setPersonId] = useState(observation.attributes_.personId);
    const [participantsPersonIds, setParticipantsPersonIds] = useState(
      observation.attributes_.participantsPersonIds || []
    );

    useEffect(() => {
      onChange({
        personId: personId || null,
        participantsPersonIds:
          participantsPersonIds.length > 0 ? participantsPersonIds : null,
      });
    }, [personId, participantsPersonIds, onChange]);

    return (
      <div>
        {t('whoPerformsAction')}
        <Select
          options={attributesOptions.personId}
          value={attributesOptions.personId.find(
            option => option.value === personId
          )}
          onChange={option => setPersonId(option.value)}
        />
        {t('playersInvolvedInAction')}
        <Select
          options={attributesOptions.personId}
          isMulti
          value={attributesOptions.personId.filter(
            option => participantsPersonIds.indexOf(option.value) !== -1
          )}
          onChange={options =>
            setParticipantsPersonIds(options.map(option => option.value))
          }
        />
      </div>
    );
  }
);
