import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';
import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';

import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import DevelopmentGoalCollection from 'domain/Development/DevelopmentGoals';
import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';

import { Error } from 'lib/PlaceHolder';
import { PDPTable } from '../components/pdp-table';
import DataState from './../enums/data-state';

const PDPTablePage = withTranslation('module.development.pdp')(
  observer(
    class PDPTablePage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable(DataState.LOADING);
        this.changes = observable([]);
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set(DataState.LOADING);
          const currentSession = Session.current();

          yield currentSession.isReady();

          this.learningLines =
            yield LearningLineQueryService.learningLineTree();
          this.developmentGoals = yield DevelopmentGoalCollection.fetch();
          this.dataState.set(DataState.LOADED);
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load personal plan table',
          });
          this.dataState.set(DataState.ERROR);
        }
      });

      componentDidMount() {
        this.loadData();
      }

      syncOpenChanges = action(async () => {
        this.dataState.set(DataState.UPDATING);

        const updates = this.changes.map(
          (val) => () => DevelopmentGoalCollection.update(val.id, val.updates)
        );

        updates
          .reduce((prev, task) => {
            return prev.then(task).catch((err) => {
              console.warn('err', err.message);
            });
          }, Promise.resolve())
          .then(
            action(() => {
              this.dataState.set(DataState.UPDATED);
              this.changes.clear();
            })
          );
      });

      onSaveComplete = action(({ developmentGoalId }) => {
        this.dataState.set(DataState.UPDATED);

        return developmentGoalId;
      });

      saveNewRow = action(async (row) => {
        this.dataState.set(DataState.UPDATING);
        const developmentGoalId = await DevelopmentGoalCollection.create(
          row
        ).then(this.onSaveComplete);
        return { developmentGoalId };
      });

      updateExistingRow = action(async (id, field, value) => {
        this.dataState.set(DataState.DIRTY);
        const indexForExistingChange = this.changes.findIndex(
          (obj) => obj.id === id
        );

        if (indexForExistingChange < 0) {
          // Create a new update
          this.changes.push({
            type: 'update',
            id,
            updates: {
              [field]: value,
            },
          });
        } else {
          // Add to existing update
          this.changes.replace(
            this.changes.map((obj) => {
              if (obj.id !== id) return obj;
              return {
                ...obj,
                updates: {
                  ...obj.updates,
                  [field]: value,
                },
              };
            })
          );
        }
        return id;
      });

      deleteRow = action(async (id) => {
        this.dataState.set(DataState.UPDATING);
        await DevelopmentGoalCollection.delete(id).then(
          action(() => this.dataState.set(DataState.UPDATED))
        );
        return id;
      });

      renderOpenChangesLabel = (numberOfChanges) => {
        if (numberOfChanges === 0) return undefined;
        const translationKey =
          numberOfChanges === 1 ? 'unsavedChange' : 'unsavedChanges';
        return (
          <span className="changes">
            {this.props.t(translationKey, { numberOfChanges })}
          </span>
        );
      };

      render() {
        if (this.dataState.get() === DataState.LOADING) {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === DataState.ERROR) {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              path: '/development/tdp',
              title: this.props.t('module.development:playerDevelopment'),
            },
            { title: this.props.t('menu:pdp') },
          ],
        };

        return (
          <Page {...pageProps}>
            <PDPTable
              learningLines={this.learningLines}
              saveNewRow={this.saveNewRow}
              deleteRow={this.deleteRow}
              updateExistingRow={this.updateExistingRow}
              dataArray={this.developmentGoals}
              dataState={this.dataState.get()}
              syncOpenChanges={this.syncOpenChanges}
              openChanges={this.changes}
              {...this.props}
            />
          </Page>
        );
      }
    }
  )
);

export { PDPTablePage };
