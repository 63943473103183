import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { ReportDetail } from './containers';
import { getRoutePath } from 'modules/route';

const MODULE_NAME = 'reporting';

export default new Module(
  MODULE_NAME,
  [],
  [
    new RouteRedirect('detail/:sportingEventId', ({ sportingEventId }) => {
      return getRoutePath(`${MODULE_NAME}.detail`, {
        sportingEventId,
        reportState: 'default',
      });
    }),

    new RouteExact(
      'detail/:sportingEventId/:reportState',
      ReportDetail,
      'detail',
      { defaultArgs: { reportState: 'default' } }
    ),
  ]
);
