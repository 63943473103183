import {
  LocalCommandHandler,
  RemoteCommandHandler,
} from '../../infra/Messaging/CommandHandler';

import TeamCollection from './Team';
import { CommandBus } from '../../infra/Messaging/CommandBus';
import Command from '../../infra/Messaging/Command';

class LocalTeamCommandHandler extends LocalCommandHandler {
  constructor() {
    super();

    this.teamRepository = TeamCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'Team.Create':
        this.teamRepository.addNew({
          teamId: command.teamId,
          name: command.name,
          sportType: command.sportType,
          appearance: {},
        });
        break;

      case 'Team.AddPlayer':
        const team = this.teamRepository.get(command.teamId);
        team.addPlayer(command.personId, command.number);

        break;
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['Team.*'];
  }
}

class RemoteTeamCommandHandler extends RemoteCommandHandler {
  constructor() {
    super();

    this.teamRepository = TeamCollection;
  }

  doHandle(command) {
    switch (command.commandType) {
      case 'Team.Create': {
        const team = TeamCollection.build({
          teamId: command.teamId,
        });

        this.addUnitOfWork(command, [
          this.createAddWithIdRequest(team, {
            name: command.name,
            sportType: command.sportType,
          }),
        ]);
        break;
      }
      case 'Team.AddPlayer': {
        const team = this.teamRepository.get(command.teamId);

        this.addUnitOfWork(command, [
          this.createAddRequest(team.players, {
            personId: command.personId,
            number: command.number,
          }),
        ]);
        break;
      }
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['Team.*'];
  }
}

export default () => {
  new LocalTeamCommandHandler();
  new RemoteTeamCommandHandler();
};
