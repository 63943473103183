import React from 'react';

const ObservationFilter = ({
  observationGroups,
  selectedDescription,
  onSelect,
}) => {
  return (
    <div className="labels play-description-filters">
      {observationGroups.map(
        ({ description, label, observations = {}, videoFragmentCount }, n) => {
          description = description || label;
          return (
            <div
              className={`label clickable ${
                description === selectedDescription ? 'active' : ''
              }`}
              key={n}
              onClick={() => onSelect(description)}
            >
              {description}{' '}
              <div className="badge">
                {observations.length || videoFragmentCount}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export { ObservationFilter };
