import { Model, apiClient } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';
import { api } from 'utils/ApiClient';
import downloadjs from 'downloadjs';
import { dotPropGet } from '../lib/util';

class Report extends Model {
  get primaryKey() {
    return 'reportId';
  }

  async download() {
    const response = await api.post(
      `${this.url()}/download`,
      {
        format: 'xlsx',
      },
      { responseType: 'arraybuffer' }
    );
    downloadjs(response.data, 'export.xlsx');
  }

  async getHTML(reportArgs) {
    return (
      await api.post(`${this.url()}/download`, {
        format: 'html',
        reportArgs,
      })
    ).data;
  }

  getVideoPlaylist(
    cellPath,
    durationBefore = undefined,
    durationAfter = undefined
  ) {
    const { promise, abort } = apiClient().post(
      `${this.url()}/getVideoPlaylist`,
      {
        cellPath,
        durationBefore,
        durationAfter,
      }
    );
    return promise;
  }

  c(cellPath) {
    const data = this.has('data') ? this.get('data') : {};
    const getCell = this.c.bind(this);

    return {
      get path() {
        return cellPath;
      },
      get value() {
        return dotPropGet(data, cellPath, undefined);
      },
      get count() {
        return dotPropGet(data, cellPath + '.total_count', 0);
      },
      get duration() {
        return dotPropGet(data, cellPath + '.duration', 0);
      },
      v(relativeCellPath) {
        return dotPropGet(data, cellPath + '.' + relativeCellPath, 0);
      },
      c(cellPath) {
        return getCell(this.path + '.' + cellPath);
      },

      iterate(prefix) {
        prefix += ':';

        const matches = [];
        const value = dotPropGet(data, this.path, {});
        for (const path of Object.keys(value)) {
          if (path.startsWith(prefix)) {
            matches.push({
              cell: this.c(path),
              value: path.substr(prefix.length),
            });
          }
        }
        return matches;
      },

      g(relativeCellPath, p1, p2) {
        return (
          (
            dotPropGet(data, cellPath + '.' + relativeCellPath, '').split(',')[
              p1
            ] || ''
          ).split(':')[p2] || ''
        );
      },
      getGrouped(relativeCellPath, fieldNames) {
        return dotPropGet(data, cellPath + '.' + relativeCellPath, '')
          .split(',')
          .map((item) => {
            const obj = {};
            const values = item.split(':');
            fieldNames.map((fieldName, idx) => {
              obj[fieldName] = values[idx];
            });
            return obj;
          });
      },
      e(relativeCellPath, successMetric2, precision = 2) {
        const totalCount = this.c(relativeCellPath).v('total_count');
        const successCount = this.c(relativeCellPath).v(successMetric2);

        let value = 0;
        if (totalCount > 0) {
          value = (successCount / totalCount) * 100;
        }
        return value.toFixed(precision);
      },
    };
  }

  loaded() {
    return this.has('data');
  }

  markAsStale() {
    this.set({ stale: true });
  }

  isStale() {
    return this.has('stale') && this.get('stale');
  }
}

class ReportCollection extends PersistentCollection {
  constructor(url) {
    super();
    this.url_ = url;
  }

  url() {
    return this.url_;
  }
  model() {
    return Report;
  }

  ensureLoaded() {
    if (this.isEmpty()) this.fetch();
  }

  loading() {
    return this.isRequest('fetching');
  }
}

export default ReportCollection;
