import React, { FC, useState } from 'react';

import {
  closeEditor,
  updateCellValue,
  updateEditorValue,
} from 'ka-table/actionCreators';
import 'ka-table/style.scss';
import { ICellEditorProps } from 'ka-table/props';

export const TextAreaEditor: FC<ICellEditorProps> = ({
  column,
  dispatch,
  rowKeyValue,
  value,
}) => {
  const [state, setState] = useState(value);

  return (
    <div>
      <textarea
        className="form-control"
        rows={3}
        autoFocus={typeof rowKeyValue === 'string'}
        value={state}
        onBlur={() => {
          console.log('blur', column.key);
          if (typeof rowKeyValue === 'string') {
            // update existing
            dispatch(updateCellValue(rowKeyValue, column.key, state));
            dispatch(closeEditor(rowKeyValue, column.key));
          } else {
            // update new row
            dispatch(updateEditorValue(rowKeyValue, column.key, state));
          }
        }}
        onChange={evt => {
          setState(evt.target.value);
        }}
      />
    </div>
  );
};
