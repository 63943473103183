import React, { Component, useState } from 'react';

import { observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { observer } from 'mobx-react';

import logger from 'utils/Logger';
import VisibilitySensor from 'react-visibility-sensor';

import { SportingEventSetsScore } from './SportingEventSetsScore';

const SportingEventScore = observer(
  class SportingEventScore extends Component {
    constructor() {
      super();

      this.dataState = observable('');
      this.fetchInterval = null;
    }

    loadData = asyncAction(function* () {
      try {
        this.dataState.set('loading');
        this.report = yield this.props.sportingEvent.getReport('score');

        if (!!this.props.liveUpdate) {
          console.log('Set autorefresh');
          this.fetchInterval = setInterval(() => {
            this.refreshData();
          }, 10000);
        }

        this.dataState.set('loaded');
      } catch (e) {
        logger.error(e, { transactionName: 'sportingEventScore' });
        this.dataState.set('error');
      }
    });

    componentWillUnmount() {
      this.clearRefreshInterval();
    }

    clearRefreshInterval() {
      if (this.fetchInterval) {
        clearInterval(this.fetchInterval);
      }
    }

    refreshData = asyncAction(function* () {
      yield this.props.sportingEvent.fetch();
      if (this.props.sportingEvent.ended()) {
        this.clearRefreshInterval();
      }
      this.report.fetch();
    });

    componentDidMount() {
      this.loadData();
    }

    render() {
      if (this.dataState.get() !== 'loaded') {
        return ' - ';
      }

      let isTennis = false;
      if (this.report.has('data')) {
        const { sets } = this.report.get('data');
        isTennis = !!sets?.length;
      }

      if (isTennis) {
        const { sets } = this.report.get('data');

        return <SportingEventSetsScore sets={sets} />;
      } else {
        return (
          <DefaultScore
            report={this.report}
            sportingEvent={this.props.sportingEvent}
          />
        );
      }
    }
  }
);

const DefaultScore = ({ report, sportingEvent }) => {
  const homeScore = report.c(
    `team_id:${sportingEvent.homeTeam.id}.score`
  ).count;
  const awayScore = report.c(
    `team_id:${sportingEvent.awayTeam.id}.score`
  ).count;

  return `${homeScore} - ${awayScore}`;
};

const VisibleSportingEventScore = ({ sportingEvent }) => {
  const [visible, setVisibility] = useState(false);

  return (
    <VisibilitySensor onChange={setVisibility} active={!visible}>
      {({ isVisible }) => {
        return isVisible ? (
          <SportingEventScore sportingEvent={sportingEvent} />
        ) : (
          <span>-</span>
        );
      }}
    </VisibilitySensor>
  );
};

export { VisibleSportingEventScore as SportingEventScore };
