import React from 'react';
import { Formik } from 'formik';

import { Page } from 'lib/Page';
import { Notification } from 'lib/Notification';

import { withTranslation } from 'react-i18next';
import { gotoRoute } from 'modules/route';

import { themes } from './themes';

export const EditTrainingTemplatePage = withTranslation(
  'module.trainingTemplate'
)(props => {
  const { t, exercises, trainingTemplate } = props;

  const onCancel = () => {
    gotoRoute('training-templates.overview');
  };

  const onDelete = async () => {
    const shouldDelete = window.confirm('Are you sure');

    if (shouldDelete) {
      await trainingTemplate.destroy({ optimistic: false });
      Notification.show('Training verwijderd', 2500);
      gotoRoute('training-templates.overview');
    }
  };

  const handleSave = async (values, formikBag) => {
    await trainingTemplate.save(values);
    formikBag.setSubmitting(false);
    Notification.show('Training opgeslagen', 2500);
    gotoRoute('training-templates.overview');
  };

  const validate = values => {
    let errors = {};
    if (!values.title || values.title === '') {
      errors.title = true;
    }
    if (!values.categoryId || values.categoryId === '') {
      errors.categoryId = true;
    }
    if (!values.themeId || values.themeId === '') {
      errors.themeId = true;
    }

    return errors;
  };

  const initialValues = {
    title: trainingTemplate.title,
    categoryId: trainingTemplate.categoryId,
    themeId: trainingTemplate.themeId,
    exercises: trainingTemplate.exercises.toJS(),
  };

  const pageProps = {
    title: 'Edit Training Template',
    breadcrumbs: [
      { path: '/', title: 'Home' },
      {
        title: 'Training Templates',
        path: '/training-templates/overview',
      },
      {
        title: 'Edit Training Template',
      },
    ],
  };

  return (
    <Page {...pageProps}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSave}
        validate={validate}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          submitCount,
          errors,
          setFieldValue,
          onReset,
        }) => {
          const handleAddExercise = () => {
            setFieldValue('exercises', [
              ...values.exercises,
              { exerciseId: '', comment: '' },
            ]);
          };

          const handleDeleteExercise = idx => () => {
            if (!window.confirm('Are you sure?')) {
              return false;
            }

            setFieldValue(
              'exercises',
              values.exercises.filter((_, exerciseIdx) => exerciseIdx !== idx)
            );
          };

          // let exerciseTemplateId = 'exercise-template-sv';
          // switch ((values.category || '').split(' / ')[0]) {
          //   case 'Vaardigheden':
          //   case 'Warming-up':
          //     exerciseTemplateId = 'exercise-template-sv-skill';
          //     break;
          //   default:
          //     exerciseTemplateId = 'exercise-template-sv';
          //     break;
          // }

          // const exerciseTemplate = exerciseTemplateCollection.get(
          //   exerciseTemplateId
          // );

          return (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div
                    className={`form-group ${validClassName({
                      errors,
                      key: 'title',
                      submitCount,
                    })}`}
                  >
                    <label htmlFor="title">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={values.title}
                      autoComplete="off"
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div
                    className={`form-group ${validClassName({
                      errors,
                      key: 'categoryId',
                      submitCount,
                    })}`}
                  >
                    <label htmlFor="title">Categorie</label>
                    <select
                      type="text"
                      className="form-control"
                      name="categoryId"
                      value={values.categoryId}
                      autoComplete="off"
                      onChange={handleChange}
                    >
                      <option value="">-- kies categorie --</option>
                      <option value="E-F">E-F</option>
                      <option value="C-D">C-D</option>
                      <option value="A-B">A-B</option>
                      <option value="Senioren">Senioren</option>
                    </select>
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div
                    className={`form-group ${validClassName({
                      errors,
                      key: 'themeId',
                      submitCount,
                    })}`}
                  >
                    <label htmlFor="title">Theme</label>
                    <select
                      type="text"
                      className="form-control"
                      name="themeId"
                      value={values.themeId}
                      autoComplete="off"
                      onChange={handleChange}
                    >
                      <option key={0} value="">
                        -- select theme --
                      </option>
                      {themes.map(theme => (
                        <option key={theme.id} value={theme.id}>
                          {theme.label}
                        </option>
                      ))}
                    </select>
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <h3>Exercises</h3>
                  <table className="table training-template-exercises">
                    <thead>
                      <tr>
                        <th width="30%">Title</th>
                        <th>Comment</th>
                        <th width="20%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.exercises.map((templateExercise, idx) => (
                        <tr key={idx}>
                          <td>
                            <select
                              className="form-control"
                              name={`exercises[${idx}].exerciseId`}
                              value={templateExercise.exerciseId}
                              onChange={handleChange}
                            >
                              <option key={0}>-- select an exercise --</option>
                              {exercises.map(exercise => (
                                <option
                                  key={exercise.exerciseId}
                                  value={exercise.exerciseId}
                                >
                                  {exercise.tags.category} / {exercise.title}
                                </option>
                              ))}
                            </select>{' '}
                          </td>
                          <td>
                            <textarea
                              className="form-control"
                              size="3"
                              name={`exercises[${idx}].comment`}
                              value={templateExercise.comment}
                              onChange={handleChange}
                            ></textarea>
                          </td>
                          <td>
                            <button
                              onClick={handleDeleteExercise(idx)}
                              type="button"
                              className="btn btn-link"
                            >
                              delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="3">
                          <button
                            type="button"
                            className="btn btn-link"
                            onClick={handleAddExercise}
                          >
                            + Add Exercise
                          </button>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div className="mt-4 form-actions">
                <button type="submit" className="btn btn-primary">
                  {t('common:save')}
                </button>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onCancel}
                >
                  {t('common:cancel')}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={onDelete}
                >
                  {t('common:delete')}
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Page>
  );
});

const validClassName = ({ submitCount, errors, key }) => {
  if (!submitCount) {
    return '';
  }

  return errors[key] ? 'is-invalid' : 'is-valid';
};
