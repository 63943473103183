import { ReportDefinition } from './ReportDefinition';
import { DataBuilder } from './DataBuilder';

const _throw = (msg: string) => {
  throw msg;
};

export class ReportFetcher<ReportType> {
  private report: ReportType;
  private dataBuilder: DataBuilder;
  private callbacks: ((report: ReportType) => {})[];

  constructor(
    readonly reportDefinition: ReportDefinition<ReportType>,
    readonly sportingEvent: any,
    readonly reportConstructor: new (...args: any[]) => ReportType
  ) {
    this.dataBuilder = DataBuilder.fromDataViews(
      Object.keys(reportDefinition.dataSources),
      reportDefinition.dataViews
    );

    this.callbacks = [];
    this.report = new reportConstructor();
  }

  onReportFetched(cb: (report: ReportType) => {}) {
    this.callbacks.push(cb);
    return () => {
      const index = this.callbacks.indexOf(cb);
      this.callbacks.splice(index, 1);
    };
  }

  async fetch() {
    // 1. Fetch all required data
    const inputData: { [key: string]: any } = {};
    for (const [key, fetcher] of Object.entries(
      this.reportDefinition.dataSources
    )) {
      inputData[key] = await fetcher(this.sportingEvent);
    }

    const startTime = performance.now();
    // 2. Resolve the dataView graph
    const data = this.dataBuilder.resolve(inputData, this.sportingEvent);
    console.log(data);

    // 3. Run queries
    const report = new this.reportConstructor();
    for (const query of Object.values(this.reportDefinition.queries)) {
      const [dependencies, fn] = query;

      fn(
        report,
        ...dependencies.map(
          dep => data[dep] || _throw(`Dep "${dep}" not found`)
        )
      );
    }
    console.log(`Building report took ${performance.now() - startTime}ms`);
    this.report = report;

    for (const cb of this.callbacks) {
      cb(this.report);
    }
  }

  getReport() {
    return this.report;
  }
}
