import React, { useState, createRef, useEffect } from 'react';
import noresultImg from 'img/no_playlists.svg';
import { useQuery, useQueryClient } from 'react-query';
import debounce from 'lodash/debounce';
import { NoResults } from 'lib/PlaceHolder';
import { Page } from 'lib/Page';
import api from '../../utils/ApiClient';
import Loading from '../../lib/Loading';
import { Card } from 'lib/Card';
import moment from 'moment';
import { gotoRoute } from '../route';
import { HighlightsNewView } from './new-view';
import { Modal } from 'lib/Modal';
import sweetAlert from '../../lib/sweetAlert';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'formik';
import { Session } from '../../domain/Session';
import { getTemplate } from '../../remotion/templateRegistry';

export const HighlightsOverview = () => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState();
  const [modal, setModal] = useState();
  const highlightVideos = useQuery(['highlightVideos'], getHighlightVideos);
  const { t } = useTranslation('module.highlights');
  const currentSession = Session.current();

  const viewFilters = [
    {
      type: 'search',
      placeholder: t('common:search'),
      onChange: debounce((value) => {
        setSearch(value);
      }, 200),
    },
  ];

  const pageProps = {
    title: t('highlights'),
    breadcrumbs: [{ title: 'Overview' }],
    viewFilters: viewFilters,
    primaryAction: {
      title: t('addNew'),
      onClick: () => {
        addHighlightVideo();
      },
      disabled: !currentSession
        .licensePrivileges()
        .hasPrivilege('highlights/video:create'),
    },
  };
  if (highlightVideos.status === 'error') {
    return new Error('cannot load highlight data');
  }
  if (!highlightVideos.data) {
    return <Loading type={'fullscreen'} />;
  }

  const addHighlightVideo = () => {
    setModal(
      <Modal onCloseClick={() => setModal()}>
        <HighlightsNewView
          onSuccess={async (highlightVideo) => {
            gotoRoute('highlights.detail', {
              id: highlightVideo.highlightVideoId,
            });
          }}
          onClose={() => setModal()}
        />
      </Modal>
    );
  };

  const handleDeleteVideo = async (highlightVideoId: string) => {
    sweetAlert({
      title: t('deleteTitle'),
      text: t('deleteSubtitle'),
      dangerMode: true,
      buttons: [t('common:cancel'), t('common:confirm')],
    }).then(async (willDelete) => {
      if (willDelete) {
        await api.delete(`highlights/videos/${highlightVideoId}`);
        queryClient.invalidateQueries(['highlightVideos']);
      }
    });
  };

  return (
    <Page className={'page--playlist-view'} {...pageProps}>
      {modal}
      <div className="playlist-view">
        {highlightVideos.data
          .filter((h) => {
            if (search) {
              return h.name.toLowerCase().includes(search.toLowerCase());
            }
            return true;
          })
          .map((highlightVideo) => {
            const clipCount =
              highlightVideo?.content.filter((v) => v.type === 'clip.Video')
                .length ?? 0;
            const meta = (
              <>
                <span className={'clips'}>
                  <i className="i-playlist i-dark i-xs"></i> {clipCount ?? 0}{' '}
                  Clips
                </span>
                <span>
                  <i className="i-calendar-small i-dark i-xs"></i>
                  {moment(highlightVideo.createdAt).format('DD-MM-YYYY')}
                </span>
              </>
            );
            const actions = [
              {
                label: t('deleteTitle'),
                onClick: () =>
                  handleDeleteVideo(highlightVideo.highlightVideoId),
              },
            ];
            const onClick = () => {
              gotoRoute('highlights.detail', {
                id: highlightVideo.highlightVideoId,
              });
            };
            return (
              <div
                className="playlist-view__card"
                key={highlightVideo.highlightVideoId}
              >
                <Card
                  onClick={onClick}
                  title={highlightVideo.name}
                  meta={meta}
                  subtitle={
                    getTemplate(highlightVideo?.templateId)?.templateName
                  }
                  poster={highlightVideo.poster}
                  actions={actions}
                />
              </div>
            );
          })}
        {!highlightVideos.data.length && <NoResults imgSrc={noresultImg} />}
      </div>
    </Page>
  );
};

const getHighlightVideos = async () => {
  const { status, data: videos } = await api.get(
    'highlights/v2/highlightVideos'
  );
  // sort by date
  if (status !== 200) {
    throw new Error('Highlights network response was not ok');
  }
  videos?.sort((a, b) => {
    return b.createdAt.localeCompare(a.createdAt);
  });
  return videos;
};

export const getTemplates = async () => {
  const { status, data: templates } = await api.get('highlights/templates');
  if (status !== 200) {
    throw new Error('Templates network response was not ok');
  }
  return templates;
};
