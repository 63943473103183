import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { formatTime } from 'lib/FormattedTime';
import { useTranslation } from 'react-i18next';

// https://codepen.io/naicuoctavian/pen/GRgzqBg

export const RecordButton = forwardRef(
  (
    {
      onRecord,
      onDone,
      hasRecording,
    }: {
      onRecord?: () => void;
      onDone?: (duration: number) => void;
      hasRecording: any;
    },
    ref
  ) => {
    const [isRecording, setIsRecording] = useState(false);
    const interval = useRef<any>(null);
    const [duration, setDuration] = useState(0);
    const { t } = useTranslation('module.highlights');

    useImperativeHandle(ref, () => ({
      stop: () => {
        if (isRecording) {
          stopRecording();
        }
      },
    }));

    const stopRecording = async () => {
      onDone && (await onDone(duration));
      setIsRecording(false);
      if (interval.current) {
        clearInterval(interval.current);
      }
    };

    const startRecording = async () => {
      onRecord && (await onRecord());
      const startTime = new Date().getTime() / 1000;
      interval.current = setInterval(() => {
        setDuration(new Date().getTime() / 1000 - startTime);
      }, 1000);
      setIsRecording(true);
      setDuration(0);
    };

    const toggle = () => {
      if (isRecording) {
        stopRecording();
      } else {
        startRecording();
      }
    };

    return (
      <button
        type="button"
        className={`btn btn-link ${isRecording ? 'pulse-red' : ''}`}
        onClick={toggle}
      >
        {isRecording ? (
          <>
            <IconSquare /> &nbsp; {t('record')}&hellip; {formatTime(duration)}
          </>
        ) : hasRecording ? (
          <>
            <i className={'i-delete i-dark i-sm'}></i> Record new voiceover{' '}
          </>
        ) : (
          <>
            <IconMicrophone /> &nbsp; {t('recordVoiceOver')}
          </>
        )}
      </button>
    );
  }
);

const IconSquare = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="#ff0000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect x="4" y="4" width="16" height="16" rx="2" fill="#ff0000" />
  </svg>
);

const IconMicrophone = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="#ff0000"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect x="9" y="2" width="6" height="11" rx="3" />
    <path d="M5 10a7 7 0 0 0 14 0" />
    <line x1="8" y1="21" x2="16" y2="21" />
    <line x1="12" y1="17" x2="12" y2="21" />
  </svg>
);

export const AudioRecordButton = forwardRef(
  (
    {
      onRecord,
      onDone,
      onRecordDone,
      hasRecording,
    }: {
      onRecord: () => void;
      onDone: () => void;
      onRecordDone: (data: Blob, duration: number) => void;
      hasRecording: any;
    },
    ref
  ) => {
    const recordButton = useRef();
    const recorderData = useRef<any>();
    useImperativeHandle(ref, () => ({
      stop: () => {
        (recordButton as any).current.stop();
      },
    }));

    const startRecord = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const recorder = new (window as any).MediaRecorder(stream);

      recorderData.current = {
        recorder,
        stream,
      };
      recorder.start();
      onRecord();
    };

    const stopRecord = (duration: number) => {
      const { stream, recorder } = recorderData.current;

      recorder.ondataavailable = async (e) => {
        onDone();
        onRecordDone(e.data, duration);
      };
      recorder.stop();
      stream.getAudioTracks()[0].stop();
    };

    return (
      <RecordButton
        hasRecording={hasRecording}
        ref={recordButton}
        onRecord={startRecord}
        onDone={stopRecord}
      />
    );
  }
);
