import React from 'react';

import { withVisibleToggle } from 'lib/hocs/withVisibleToggle';

import styles from './index.module.scss';
import { Session } from '../../../domain/Session';

export const AppNav = ({ apps, activeApp, onChange, ...props }) => {
  const currentUserRole = Session.current()?.currentMembership()?.roleNames[0];

  if (currentUserRole === 'trainee') {
    // Remove store when person type is trainee.
    const index = apps.find((a) => a.appId === 'store');
    if (index > -1) {
      apps.splice(index, 1);
    }
  }

  if (apps.length === 0 && !activeApp) {
    return null;
  } else if (apps.length > 1) {
    return (
      <MultiAppSelect
        apps={apps}
        activeApp={activeApp}
        onChange={onChange}
        {...props}
      />
    );
  } else {
    return (
      <div className="app-nav">
        <div
          className={`clickable ${styles.toggle}`}
          onClick={() => onChange(activeApp)}
        >
          <img
            className={styles.appLogo}
            src={activeApp.imgSrc}
            alt={activeApp.name}
            border="0"
          />
        </div>
      </div>
    );
  }
};

const MultiAppSelect = withVisibleToggle(
  ({ targetRef, toggleRef, apps, activeApp, onChange, toggle, children }) => {
    const appColumnSize = apps.length >= 3 ? 'w-33' : '';
    return (
      <div className="app-nav">
        <div className={`clickable ${styles.toggle}`}>
          <i className={styles.activeIcon} ref={toggleRef} />
          <img
            className={styles.appLogo}
            src={activeApp.imgSrc}
            alt={activeApp.name}
            border="0"
            onClick={() => {
              onChange(activeApp);
            }}
          />
        </div>
        <div className={`menu-panel ${styles.menu}`} ref={targetRef}>
          {apps.map((app) => (
            <div
              key={app.appId}
              className={styles.menuItem + ` menuItem w-${appColumnSize}`}
              onClick={() => {
                toggle();
                onChange(app);
              }}
            >
              <img
                className={styles.appLogo}
                src={app.imgSrc}
                alt={app.name}
                border="0"
              />
              {/*<div className={styles.menuItemTitle}>{app.name}</div>*/}
            </div>
          ))}
        </div>
      </div>
    );
  }
);
//
// export const AppNav = ({ apps, activeApp }) => (
//   <div className="app-nav">
//     <div className={`clickable ${styles.toggle}`}>
//       <NavLink to="/">
//         <img
//           className={styles.appLogo}
//           src={activeApp.imgSrc}
//           alt={activeApp.title}
//           border="0"
//         />
//       </NavLink>
//     </div>
//   </div>
// );
