import './style.scss';
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Session } from '../../domain/Session';
import moment from 'moment';

import VisibilitySensor from 'react-visibility-sensor';
import uuidv4 from 'uuid/v4';

import UserPlaceholder from 'img/icons/profile-picture-placeholder.svg';
import { ChatBoxInput } from './ChatBoxInput';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addComment,
  getComments,
  setSeenComment,
} from '../../domain/Observation';
import reactStringReplace from 'react-string-replace';
import { getMembers } from '../../domain/ResourceGroup';

export const ChatBox = forwardRef(
  ({ conversations, onAddMessage, onSeenComment, members }, ref) => {
    useImperativeHandle(ref, () => ({
      scrollToBottom: () => {
        scrollElementTop(chatBoxMessageRef.current);
      },
    }));

    const { t } = useTranslation('conversation');

    const currentSessionUserId = Session.current().user.realUserId;

    const scrollElementTop = (element, amount) => {
      // Scroll to bottom of list on load. || to where you where in scroll when new messages appear.
      element.scrollTo(0, amount >= 0 ? amount : element.scrollHeight);
    };
    const chatBoxMessageRef = useRef();
    const chatBoxRef = useRef();
    const mounted = useRef();

    let userMessagesGroup = []; // Combine messages from the same user.

    let conversationParticipants = []; // Users to be includes in checks for seenBy.

    const [chatboxScroll, setChatBoxScroll] = useState(-1);
    const [chatBoxSize, setChatboxSize] = useState();

    useEffect(() => {
      if (!mounted.current) {
        // only on first load
        scrollElementTop(chatBoxMessageRef.current);
        mounted.current = true;
      } else {
        scrollElementTop(chatBoxMessageRef.current, chatboxScroll);
      }
      setChatboxSize(chatBoxRef.current.getBoundingClientRect().width);
    }, [conversations]);

    const replaceIdWithMember = message => {
      return reactStringReplace(message, /\{\{([^}]+)\}\}/g, userId => {
        const member = members?.find(member => member.id === userId);
        if (member) {
          return (
            <span
              className={`conversation__mention  ${
                userId === currentSessionUserId
                  ? 'conversation__mention--own'
                  : ''
              }`}
            >
              @{member?.display}
            </span>
          );
        } else {
          // return original string
          return `{{${userId}}}`;
        }
      });
    };

    if (conversations && conversations.length) {
      // Convert list of comments to comments grouped by user.
      conversations.map((conversation, idx) => {
        let prevIndex = idx - 1;
        if (
          !idx ||
          conversation.commenterUser.userId !==
            conversations[prevIndex].commenterUser.userId
        ) {
          userMessagesGroup.push({
            commenterUser: conversation.commenterUser,
            comments: [],
          });
        }
        userMessagesGroup[userMessagesGroup.length - 1].comments.push({
          comment: replaceIdWithMember(conversation.comment),
          commentId: conversation.commentId,
          created:
            moment().startOf('day') < moment(conversation.created)
              ? moment(conversation.created).format('hh:mm')
              : moment(conversation.created).format('hh:mm - DD/MM/YYYY'),
          seenBy: conversation.seenBy,
          seenByMe: !!conversation.seenBy.find(
            seen => seen.user.userId === currentSessionUserId
          ),
        });
        if (
          !conversationParticipants.includes(conversation.commenterUser.userId)
        ) {
          conversationParticipants.push(conversation.commenterUser.userId);
        }
        for (const mentionUserId of conversation.mentions) {
          const member = members?.find(member => member.id === mentionUserId);
          if (member && !conversationParticipants.includes(member.id)) {
            conversationParticipants.push(member.id);
          }
        }
      });
    }
    return (
      <div
        ref={chatBoxRef}
        className={`chatbox__wrapper ${
          chatBoxSize < 400 ? 'chatbox--small' : ''
        }`}
      >
        <div
          className="chatbox__conversations"
          onScroll={e => setChatBoxScroll(e.target.scrollTop)}
          ref={chatBoxMessageRef}
        >
          {!conversations.length && ( // empty view
            <div className={'empty'}>
              <h3 style={{ textAlign: 'center' }}>{t('title')}</h3>
              <p style={{ textAlign: 'center' }}>
                <Trans
                  i18nKey={'conversation:body'}
                  values={{ subject: 'tag' }}
                >
                  body <i>subject</i>
                </Trans>
              </p>
            </div>
          )}
          {userMessagesGroup.map((messages, index) => {
            return (
              <div
                key={`${index + messages.commenterUser.userId}`}
                className={`conversation ${
                  messages.commenterUser.userId === currentSessionUserId
                    ? 'own'
                    : ''
                }`}
              >
                <div className="conversation__user">
                  <img
                    src={
                      messages.commenterUser.profilePictureUrl ??
                      UserPlaceholder
                    }
                  />
                </div>
                <div className="conversation__messages">
                  {messages.comments.map((comment, idx) => {
                    return (
                      <MessageComponent
                        messages={messages}
                        currentSessionUserId={currentSessionUserId}
                        comment={comment}
                        showUserInfo={idx === 0}
                        key={comment.commentId}
                        conversationParticipants={conversationParticipants}
                        onSeenComment={onSeenComment}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <ChatBoxInput
          parentRef={chatBoxRef}
          onAddMessage={onAddMessage}
          members={members}
        />
      </div>
    );
  }
);

const MessageComponent = ({
  messages,
  comment,
  currentSessionUserId,
  showUserInfo,
  conversationParticipants,
  onSeenComment,
}) => {
  const isSeen = comment.seenByMe;
  return (
    <VisibilitySensor
      onChange={visibility => {
        if (visibility && !isSeen) {
          onSeenComment(comment.commentId);
        }
      }}
      active={!isSeen}
    >
      <div className={`conversation__message ${!isSeen ? 'new' : ''}`}>
        {showUserInfo &&
          messages.commenterUser.userId !== currentSessionUserId && (
            <label>
              {messages.commenterUser.firstName +
                ' ' +
                messages.commenterUser.lastName}{' '}
            </label>
          )}
        <div>{comment.comment}</div>
        <div className="conversation__meta">
          <div className="conversation__timestamp">{comment.created}</div>
          {messages.commenterUser.userId === currentSessionUserId &&
            conversationParticipants.length > 1 && (
              <SeenBy
                participants={conversationParticipants.filter(
                  id => id !== currentSessionUserId
                )}
                seenBy={comment.seenBy.filter(seen => {
                  return seen.user.userId !== currentSessionUserId;
                })}
              ></SeenBy>
            )}
        </div>
      </div>
    </VisibilitySensor>
  );
};

const SeenBy = ({ participants, seenBy }) => {
  const { t } = useTranslation('conversation');
  const checkSeenByAll = (a, b) => {
    if (a == null || b == null) return false;
    if (a.length > b.length) return false;
    for (var i = 0; i < a.length; i++) {
      if (!b.includes(a[i])) {
        return false;
      }
    }
    return true;
  };
  return (
    <div
      className={`seenBy ${
        checkSeenByAll(
          participants,
          seenBy.map(seen => {
            return seen.user.userId;
          })
        )
          ? 'all'
          : ''
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#d5d5d5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
        <circle cx="12" cy="12" r="3"></circle>
      </svg>

      <div className="seen__list">
        <span className="seenBy__counter">
          {seenBy.length > 0 && t('seenBy', { count: seenBy.length })}
        </span>
        <ul>
          {seenBy.length > 0 &&
            seenBy.map(seen => {
              return (
                <li key={`${uuidv4() + seen.seen}`}>{`${seen.user.firstName} ${
                  seen.user.lastName
                } - ${moment(seen.seen).format('DD-MM-YY')}`}</li>
              );
            })}
          {seenBy.length === 0 && <li>{t('unread')}</li>}
        </ul>
      </div>
    </div>
  );
};

export const ObservationChatBox = ({ observationId }) => {
  const chatBoxRef = useRef();

  const queryKey = ['observation', 'conversation', observationId];
  const queryClient = useQueryClient();

  const { data: conversation } = useQuery(
    queryKey,
    async () => {
      return await getComments(observationId);
    },
    {
      refetchInterval: 10000,
      placeholderData: [],
      refetchOnWindowFocus: 'always',
    }
  );
  const addCommentMutation = useMutation(addComment, {
    onSuccess: async comment => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const conversationCached = queryClient.getQueryData(queryKey);
      // Optimistically update to the new value
      const conversationUpdated = [...conversation, comment];

      queryClient.setQueryData(queryKey, conversationUpdated);
      setTimeout(() => {
        chatBoxRef.current.scrollToBottom();
      }, 100);

      return { previous: conversationCached, current: conversationUpdated };
    },
  });

  const fetchMembers = async () => {
    const members = await getMembers();
    return members.map(member => {
      return {
        id: member.userId,
        display: `${member.firstName} ${member.lastName}`,
      };
    });
  };

  const { data: members } = useQuery(['members'], fetchMembers, {
    placeholderData: [],
    refetchOnWindowFocus: 'always',
  });

  const onAddComment = async (comment, mentions) => {
    await addCommentMutation.mutate({
      observationId,
      comment,
      mentions,
    });
  };
  const onSeenComment = async commentId => {
    await setSeenComment(observationId, commentId);
    await queryClient.invalidateQueries(queryKey);
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  return (
    <ChatBox
      conversations={conversation}
      members={members}
      onAddMessage={onAddComment}
      onSeenComment={onSeenComment}
      ref={chatBoxRef}
    />
  );
};
