import React from 'react';
import { deleteRow } from 'ka-table/actionCreators';
import sweetAlert from 'lib/sweetAlert';

const DeleteButton = ({ dispatch, rowData, t }) => {
  const deleteGoal = () => {
    sweetAlert({
      title: t('deleteGoalConfirmTitle'),
      text: t('deleteGoalConfirmMessage'),
      dangerMode: true,
      buttons: [t('common:cancel'), t('common:confirm')],
    }).then(willDelete => {
      if (willDelete) {
        dispatch(deleteRow(rowData.developmentGoalId));
      }
    });
  };
  return <span className="delete" onClick={deleteGoal} />;
};

export default DeleteButton;
