import { Modal } from 'lib/Modal';
import ReactJWPlayer from 'react-jw-player';
import React, { useEffect, useRef, useState } from 'react';
import { TimeSlider } from './TimeSlider';
import { ObservationLogVideoPlayer } from './Observation/ObservationLogVideoPlayer';
import videoCollection from '../domain/Video';
import Video from '../domain/Video';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Checkbox } from './Checkbox';
import { InputCheckboxRadio } from './InputCheckBoxRadio';
import { CopyToClipboard } from './copyToClipboard';
import Loading from './Loading';
import { NavigatorShareButton } from './NavigatorShareButton';
import { Session } from 'domain/Session';

import completedLottie from 'lib/animations/lottie/completed.json';
import Lottie from 'react-lottie';
import { SocialMediaLink } from './socialMedia';

export const ShareVideo = ({ playerRef, onClose }) => {
  if (playerRef) {
    playerRef.pause();
  }

  // Current video
  const currentVideo = playerRef.getPlaylistItem();

  const padding = 5;
  const maxwidth = 15;

  const [isShared, setIsShared] = useState();

  const maxValue = currentVideo.sources[0].videoFragment.endTime;
  const initialStartTime = Math.ceil(
    playerRef.getCurrentTime() + currentVideo.sources[0].videoFragment.startTime
  );
  const initialClipStart = Math.max(initialStartTime - padding, 0);
  const initialClipEnd = Math.min(initialStartTime + padding, maxValue);

  const { t } = useTranslation('shareVideo');

  const videoPlayerProps = {
    repeat: true,
    visualplaylist: false,
    controls: true,
    displaytitle: false,
    playbackRateControls: false,
    nextUpDisplay: false,
    hideFeatures: true,
  };

  const [video, setVideo] = useState();
  useEffect(() => {
    (async () => {
      setVideo(
        await videoCollection.getOrFetch(
          currentVideo.sources[0].videoFragment.videoId
        )
      );
    })();
  });

  if (!video) {
    return <div></div>;
  }

  const initialValues = {
    startTime: initialClipStart,
    endTime: initialClipEnd,
    name: '',
    description: '',
    privacy: false,
  };

  const onSubmit = async (values) => {
    await Session.current().user.logAction('VideoShared', {
      ...values,
      videoId: video.videoId,
      videoUri: video.videoUri,
    });

    setIsShared(true);

    return true;
  };

  const validate = (values) => {
    const errors = {};
    if (values.name.length < 1) {
      errors.name = 'required';
    }
    if (values.description.length < 1) {
      errors.description = 'required';
    }
    if (!values.privacy) {
      errors.privacy = 'required';
    }

    return errors;
  };
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: completedLottie,
    width: 300,
    height: 300,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    eventListeners: [
      {
        eventName: 'loopComplete',
      },
    ],
  };
  if (isShared) {
    return (
      <Modal
        title={t('title')}
        description={t('subTitle')}
        onCloseClick={() => onClose()}
      >
        <h2 style={{ textAlign: 'center' }}>{t('successLabel')}</h2>
        <Lottie
          style={{ height: '200px', width: '200px', margin: 'auto' }}
          options={lottieOptions}
        />
        <div style={{ paddingBottom: '24px' }} className="row">
          <div className="col-sm-6">
            <SocialMediaLink
              type={'instagram'}
              link={'https://www.instagram.com/teamtvsport/'}
              label={t('social.insta', { brand: 'TeamTV' })}
            />
            <SocialMediaLink
              type={'facebook'}
              link={'https://www.facebook.com/teamtvsport/'}
              label={t('social.facebook', { brand: 'TeamTV' })}
            />
            <SocialMediaLink
              type={'youtube'}
              link={'https://www.youtube.com/@TeamTVnl/featured'}
              label={t('social.youtube', { brand: 'TeamTV' })}
            />
          </div>
          <div className="col-sm-6">
            <SocialMediaLink
              type={'instagram'}
              link={'https://www.instagram.com/nlkorfbal/'}
              label={t('social.insta', { brand: 'NLKorfbal' })}
            />
            <SocialMediaLink
              type={'facebook'}
              link={'https://www.facebook.com/NLKorfbal/'}
              label={t('social.facebook', { brand: 'NLKorfbal' })}
            />
            <SocialMediaLink
              type={'youtube'}
              link={'https://www.youtube.com/user/medianlkorfbal'}
              label={t('social.youtube', { brand: 'NLKorfbal' })}
            />
          </div>
        </div>
        <button onClick={() => onClose()} className="btn btn-primary btn-large">
          {t('common:close')}
        </button>
      </Modal>
    );
  }

  return (
    <Modal
      title={t('title')}
      description={t('subTitle')}
      onCloseClick={() => onClose()}
      size={'lg'}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
          submitCount,
          errors,
          setFieldValue,
        }) => {
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }
            return errors[key] ? 'is-invalid' : 'is-valid';
          };
          // const shareData = {
          //   title: 'MDN',
          //   text: 'Learn web development on MDN!',
          //   url: 'https://developer.mozilla.org',
          // };
          return (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!isSubmitting) {
                  handleSubmit();
                }
              }}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <ObservationLogVideoPlayer
                      repeat
                      video={video}
                      observations={[
                        {
                          startTime: values.startTime,
                          endTime: values.endTime,
                        },
                      ]}
                      {...videoPlayerProps}
                      heightAttribute="height"
                    />
                    <TimeSlider
                      min={Math.max(initialClipStart - maxwidth, 0)}
                      max={Math.min(initialClipStart + maxwidth, maxValue)}
                      startTime={values.startTime}
                      endTime={values.endTime}
                      onChange={(v) => {
                        setFieldValue('startTime', v[0]);
                        setFieldValue('endTime', v[1]);
                      }}
                      height="30px"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={`form-group ${validClassName('name')}`}>
                    <label htmlFor="sharevideo-title">{t('form.title')}</label>
                    <input
                      type="text"
                      id="sharevideo-title"
                      name="sharename"
                      className="form-control"
                      value={values.name}
                      onChange={(e) => setFieldValue('name', e.target.value)}
                      placeholder={t('form.titlePlaceholder')}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                  <div
                    className={`form-group ${validClassName('description')}`}
                  >
                    <label htmlFor="sharevideo-description">
                      {t('form.description')}
                    </label>
                    <textarea
                      type="text"
                      id="sharevideo-title"
                      name="sharedescription"
                      className="form-control"
                      style={{ resize: 'none' }}
                      value={values.description}
                      onChange={(e) =>
                        setFieldValue('description', e.target.value)
                      }
                      placeholder={t('form.descriptionPlaceholder')}
                      rows={2}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                  <div className={`form-group ${validClassName('privacy')}`}>
                    <InputCheckboxRadio
                      checked={values.privacy}
                      onChange={() => setFieldValue('privacy', !values.privacy)}
                      label={t('form.privacyCheck')}
                    />
                    <div className="invalid-feedback">
                      {t('common.form:required-field')}
                    </div>
                  </div>
                  <div className="form-actions">
                    <button
                      disabled={
                        !values.privacy || Object.keys(errors).length > 0
                          ? 'disabled'
                          : false
                      }
                      type="submit"
                      className="btn btn-primary btn-large"
                    >
                      {isSubmitting && (
                        <Loading type={'spinner'} size={16} color={'white'} />
                      )}
                      <svg
                        width="31"
                        height="12"
                        viewBox="0 0 31 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: '.5em' }}
                      >
                        <path
                          d="M20.1081 0H2.01081L0 11.3611H18.0973L20.1081 0Z"
                          fill="#FF6D00"
                        />
                        <path
                          d="M20.1085 0H31.0004L28.9895 11.3611H18.0977L20.1085 0Z"
                          fill="#08193F"
                        />
                        <path
                          d="M10.877 1.67578L9.45265 9.68551H6.2186L4.87806 3.73686L3.88941 9.68551H1.91211L3.33643 1.67578H6.45319L7.87751 7.74173L8.91643 1.67578H10.877Z"
                          fill="white"
                        />
                        <path
                          d="M16.9945 7.92605L16.6761 9.68551H10.9453L12.3696 1.67578H14.6821L13.5761 7.92605H16.9945Z"
                          fill="white"
                        />
                        <path
                          d="M25.2879 5.49632L27.1982 9.68551H24.7015L22.9588 5.56335L22.2382 9.68551H19.9258L21.3501 1.67578H23.6625L23.009 5.37902L25.975 1.67578H28.5723L25.2879 5.49632Z"
                          fill="white"
                        />
                      </svg>
                      {t('form.share')}
                    </button>
                  </div>
                </div>
              </div>

              {/*<hr />*/}
              {/*<div className="form-group">*/}
              {/*  <input*/}
              {/*    className="form-control"*/}
              {/*    disabled={'disabled'}*/}
              {/*    value={values.name}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*<div className="form-actions">*/}
              {/*  <div className="row">*/}
              {/*    <div className="col-6">*/}
              {/*      <CopyToClipboard*/}
              {/*        label={*/}
              {/*          <span>*/}
              {/*            <i className={'i-link i-xs i-light'} />*/}
              {/*            Kopieer link*/}
              {/*          </span>*/}
              {/*        }*/}
              {/*        copyValue={values.name}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div className="col-6">*/}
              {/*      <NavigatorShareButton*/}
              {/*        label={*/}
              {/*          <span>*/}
              {/*            <i className={'i-link i-xs i-light'} />*/}
              {/*            Delen*/}
              {/*          </span>*/}
              {/*        }*/}
              {/*        shareData={shareData}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div className="col-12">*/}
              {/*      <button type="button" className="btn btn-primary">*/}
              {/*        Deel op nlkorfbal*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
