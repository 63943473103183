import React, { useEffect, useState } from 'react';

import VideoCollection from '../../../domain/Video';

export const MultiCamThumbnails = ({ videoId, onSelect, selected, index }) => {
  const [video, setVideo] = useState(null);
  useEffect(() => {
    (async () => {
      const video = await VideoCollection.getOrFetch(videoId);
      setVideo(video);
    })();
  });
  if (!video) {
    return null;
  }
  if (video) {
    // todo: Replace with rerendering thumbs, maybe within a sprite.
    return (
      <div
        onClick={() => {
          onSelect(videoId, video?.description);
        }}
        className={`angle ${selected ? 'active' : ''}`}
      >
        <img src={video.thumbnailUrl} />
        <span>{video?.description ?? `stream ${index + 1}`}</span>
      </div>
    );
  }
};
