import React from 'react';
import omit from 'lodash/omit';

const ProductSelect = props => {
  const { t } = props;

  // @koen TODO: fetch produtc types from backend
  const options = [
    {
      value: 'teamtv-video-analyse-default',
      label: 'productIds.teamtv-video-analyse-default',
    },
    {
      value: 'teamtv-free-exchange',
      label: 'productIds.teamtv-free-exchange',
    },
    {
      value: 'teamtv-player-development-korfball-default',
      label: 'productIds.teamtv-player-development-korfball-default',
    },
    {
      value: 'teamtv-player-development-korfball-mental',
      label: 'productIds.teamtv-player-development-korfball-mental',
    },
    {
      value: 'teamtv-player-development-korfball-highperf',
      label: 'productIds.teamtv-player-development-korfball-highperf',
    },
    {
      value: 'teamtv-player-development-korfball-demo',
      label: 'productIds.teamtv-player-development-korfball-demo',
    },
    {
      value: 'footballvision-player-development-soccer-prehighperf',
      label: t(
        'productIds.footballvision-player-development-soccer-prehighperf'
      ),
    },
    {
      value: 'footballvision-player-development-soccer-highperf',
      label: 'productIds.footballvision-player-development-soccer-highperf',
    },
    {
      value: 'footballvision-player-development-soccer-demo',
      label: 'productIds.footballvision-player-development-soccer-demo',
    },
    {
      value: 'app-teamtv-data-studio-default',
      label: 'productIds.app-teamtv-data-studio-default',
    },
    {
      value: 'app-teamtv-analyst-plus-default',
      label: 'productIds.app-teamtv-analyst-plus-default',
    },
    {
      value: 'app-teamtv-importer-default',
      label: 'productIds.app-teamtv-importer-default',
    },
    {
      value: 'app-teamtv-spelenderwijsverbeteren-default',
      label: 'productIds.app-teamtv-spelenderwijsverbeteren-default',
    },
    {
      value: 'app-teamtv-livestreaming-default',
      label: 'productIds.app-teamtv-livestreaming-default',
    },
    {
      value: 'app-teamtv-usportfor-default',
      label: 'productIds.app-teamtv-usportfor-default',
    },
    {
      value: 'app-teamtv-virtual-learning-environment-default',
      label: 'ELO',
    },
    {
      value: 'app-teamtv-organizer-default',
      label: 'Organizer',
    },
    {
      value: 'app-teamtv-highlights-default',
      label: 'Highlights',
    },
  ];

  const propsSanitized = omit(props, ['t', 'setFieldValue']);

  return (
    <select {...propsSanitized}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export { ProductSelect };
