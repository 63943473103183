import { Model } from 'mobx-rest';
import { action, observable } from 'mobx';
import uuidv4 from 'uuid/v4';
import moment from 'moment';

import { PersistentCollection } from '../PersistentCollection';

import VideoCollection from '../Video';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';

class VideoFragment extends Model {
  get primaryKey() {
    return 'videoFragmentId';
  }

  get indicatorId() {
    return this.has('indicatorId') && this.get('indicatorId');
  }

  get description() {
    return this.has('description') ? this.get('description') : '';
  }

  get created() {
    return this.get('created');
  }

  get videoId() {
    return this.get('videoId');
  }

  get video() {
    return VideoCollection.get(this.videoId);
  }

  get rating() {
    return (this.has('rating') && this.get('rating')) || 'unknown';
  }

  get comment() {
    return this.has('comment') && this.get('comment');
  }

  get mediaUrl() {
    return this.video.getVideoFragmentUrl(
      this.get('startTime'),
      this.get('endTime')
    );
  }

  get sportingEvent() {
    return this.get('sportingEvent');
  }

  get metadata() {
    return this.get('metadata');
  }

  get videoFragmentUrl() {
    return this.get('videoFragmentUrl');
  }
}

class VideoFragmentCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return VideoFragment;
  }
}

class Conversation extends Model {
  get primaryKey() {
    return 'conversationId';
  }

  get subjectTRN() {
    return this.get('subjectTRN');
  }

  get comments() {
    return this.get('comments');
  }

  get ratings() {
    return this.get('ratings');
  }
}

class ConversationCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Conversation;
  }
}

class GrowthAmbition extends Model {
  get primaryKey() {
    return 'growthAmbitionId';
  }

  get growthAmbitionId() {
    return this.get('growthAmbitionId');
  }

  get startDate() {
    return moment(this.get('startDate'));
  }

  get endDate() {
    return moment(this.get('endDate'));
  }

  get goals() {
    return this.get('goals');
  }

  isEvaluated() {
    return (
      this.has('finalEvaluationId') && this.get('finalEvaluationId') !== null
    );
  }
}

class DraftGrowthAmbition {
  constructor() {
    this._indicatorIds = observable([]);
    this._startDate = observable(null);
    this._endDate = observable(null);
  }

  addGoal = action((indicatorId) => {
    if (this._indicatorIds.length === 3) {
      alert('Maximaal 3 doelen');
      return;
    }
    this._indicatorIds.push(indicatorId);
  });

  removeGoal = action((indicatorId) => {
    const index = this._indicatorIds.indexOf(indicatorId);
    if (index !== -1) {
      this._indicatorIds.splice(index, 1);
    }
  });

  isGoal(indicatorId) {
    return this._indicatorIds.indexOf(indicatorId) !== -1;
  }

  get indicatorIds() {
    return this._indicatorIds.slice();
  }

  setStartDate = action((date) => {
    this._startDate.set(date);
  });

  get startDate() {
    return this._startDate.get();
  }

  setEndDate = action((date) => {
    this._endDate.set(date);
  });

  get endDate() {
    return this._endDate.get();
  }
}

class GrowthAmbitionCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return GrowthAmbition;
  }
}

class DevelopmentPlan extends Model {
  constructor(attributes) {
    super(attributes);

    this.videoFragmentCollection = null;
    this.conversationCollection = null;
    this.growthAmbitionCollection = null;

    this.videoFilterIds = observable([]);
  }

  setVideoFragmentCollectionUrl() {
    this.videoFragments.setUrl(`${this.url()}/videoFragmentsV2`);
  }

  getDraftGrowthAmbition() {
    return new DraftGrowthAmbition();
  }

  get primaryKey() {
    return 'developmentPlanId';
  }

  addIndicatorEvaluation(indicatorId, isMastered) {
    return this.rpc('addIndicatorEvaluation', {
      indicatorId,
      isMastered,
    }).then(() => this.fetch());
  }

  isIndicatorMastered(indicatorId) {
    return !!this.currentIndicators.find(
      (evaluation) =>
        evaluation.indicatorId === indicatorId && evaluation.isMastered
    );
  }

  isIndicatorMasteredByPerspective(indicatorId, perspective) {
    return !!this.get('lastIndicatorEvaluations').find(
      (evaluation) =>
        evaluation.perspective === perspective &&
        evaluation.indicatorId === indicatorId &&
        evaluation.isMastered
    );
  }

  isPhaseCompleted(phase) {
    const skillsMastered = phase.skills.filter((skill) => {
      return this.isIndicatorMastered(skill.skillId);
    });
    const practicesMastered = phase.practices.filter((practice) => {
      return this.isIndicatorMastered(practice.practiceId);
    });
    return (
      skillsMastered.length == phase.skills.length &&
      practicesMastered.length === phase.practices.length
    );
  }

  learningLinePhasesComplete(learningline) {
    const phases = learningline.phases.toJS();
    return (
      phases.filter((phase) => {
        return this.isPhaseCompleted(phase);
      }).length === learningline.phases.length
    );
  }

  getLastMasteredIndicatorIdsByPerspective(perspective) {
    return this.get('lastIndicatorEvaluations')
      .filter(
        (evaluation) =>
          evaluation.perspective === perspective && evaluation.isMastered
      )
      .map(({ indicatorId }) => indicatorId);
  }

  getTRNOfVideoFragment(videoFragment) {
    return `trn:development:developmentplan:${this.id}/indicator/${videoFragment.indicatorId}/videofragment/${videoFragment.id}`;
  }

  get videoFragments() {
    if (this.videoFragmentCollection === null) {
      this.videoFragmentCollection = new VideoFragmentCollection(
        `${this.url()}/videoFragmentsV2`
      );
    }
    return this.videoFragmentCollection;
  }

  get conversations() {
    if (this.conversationCollection === null) {
      this.conversationCollection = new ConversationCollection(
        `${this.url()}/conversations`
      );
    }
    return this.conversationCollection;
  }

  get growthAmbitions() {
    if (this.growthAmbitionCollection === null) {
      this.growthAmbitionCollection = new GrowthAmbitionCollection(
        `${this.url()}/growthAmbitions`
      );
    }
    return this.growthAmbitionCollection;
  }

  getActiveGrowthAmbition() {
    const growthAmbitions = this.growthAmbitions.toArray();
    //growthAmbitions.sort((a, b) => a.startDate - b.startDate);

    const today = moment();
    today.set({ h: 0, m: 0, s: 0 });

    for (const growthAmbition of growthAmbitions) {
      if (
        growthAmbition.startDate.isSameOrBefore(today) &&
        growthAmbition.endDate.isSameOrAfter(today) &&
        !growthAmbition.isEvaluated()
      ) {
        return growthAmbition;
      }
    }
    return null;
  }

  addIndicatorVideoFragment(
    indicatorId,
    videoId,
    startTime,
    endTime,
    metadata,
    { comment = null, rating = null }
  ) {
    return this.rpc('addIndicatorVideoFragment', {
      indicatorId,
      videoId,
      startTime,
      endTime,
      metadata,
      comment,
      rating,
    }).then(() => {
      this.videoFragments.fetch();
      if (comment || rating) {
        this.conversations.fetch();
      }
    });
  }

  addGrowthAmbition(startDate, endDate, indicatorIds) {
    return this.rpc('addGrowthAmbition', {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      description: '',
      goals: indicatorIds.map((indicatorId) => {
        const indicator =
          LearningLineQueryService.indicatorOfIndicatorId(indicatorId);
        return {
          goalId: uuidv4(),
          indicatorId: indicatorId,
          description: `${indicator.learningLine.name}: ${indicator.indicator.name}`,
        };
      }),
    }).then(() => this.growthAmbitions.fetch());
  }

  videoFragmentFilter(videoFragment) {
    const videoFilterIds = this.videoFilterIds.toJS();

    if (!Array.isArray(videoFilterIds)) {
      console.warn('videoFilterIds is not an array!');
    }
    if (videoFilterIds !== null && videoFilterIds.length > 0) {
      return videoFilterIds.indexOf(videoFragment.videoId) !== -1;
    }
    return true;
  }

  setVideoFilter = (videoIds) => {
    this.videoFilterIds.set(videoIds);
  };

  getVideoFilter() {
    return this.videoFilterIds.toJS();
  }

  addFinalEvaluation(indicatorsEvaluations, growthAmbitionId = null) {
    return this.rpc('addFinalEvaluation', {
      indicatorsEvaluations,
      growthAmbitionId,
    }).then(() => {
      const promises = [this.fetch()];

      if (growthAmbitionId !== null) {
        promises.push(this.growthAmbitions.fetch());
      }
      return Promise.all(promises);
    });
  }

  videoFragmentsOfIndicatorId(indicatorId) {
    const ratingOptionKeyToColor = {
      unknown: 'grey',
    };
    for (const ratingOption of this.ratingOptions) {
      ratingOptionKeyToColor[ratingOption.key] = ratingOption.color;
    }
    return this.videoFragments
      .toArray()
      .filter(
        (videoFragment) =>
          videoFragment.indicatorId === indicatorId &&
          this.videoFragmentFilter(videoFragment)
      )
      .map((videoFragment) =>
        Object.assign(videoFragment, {
          ratingColor:
            ratingOptionKeyToColor[videoFragment.rating || 'unknown'],
        })
      );
    // .map(videoFragment => {
    //   const videoFragmentTRN = this.getTRNOfVideoFragment(videoFragment);
    //
    //   const conversations = this.conversations
    //     .toArray()
    //     .filter(conversation => conversation.subjectTRN === videoFragmentTRN);
    //   let rating = 'unknown';
    //   if (conversations.length > 0 && conversations[0].ratings.length > 0) {
    //     rating = conversations[0].ratings[0].rating;
    //   }
    //   return Object.assign(videoFragment, {
    //     conversations,
    //     rating,
    //     ratingColor: ratingOptionKeyToColor[rating],
    //   });
    // });
  }

  get currentIndicators() {
    const perspectives = this.get('perspectives');
    let perspectiveFilterValue;

    if (perspectives.indexOf('learning-responsible') !== -1) {
      perspectiveFilterValue = 'learning-responsible';
    } else {
      perspectiveFilterValue = 'learner';
    }
    return this.get('finalIndicatorEvaluations').concat(
      this.get('lastIndicatorEvaluations').filter(
        (indicatorEvaluation) =>
          indicatorEvaluation.perspective === perspectiveFilterValue
      )
    );
  }

  get learningIndicators() {
    return this.get('lastIndicatorEvaluations').filter(
      (indicatorEvaluation) => indicatorEvaluation.perspective === 'learner'
    );
  }

  get learningResponsibleIndicators() {
    return this.get('lastIndicatorEvaluations').filter(
      (indicatorEvaluation) =>
        indicatorEvaluation.perspective === 'learning-responsible'
    );
  }

  lastUpdated(learningLine) {
    const learningLineIndicatorIds = [];
    for (const phase of learningLine.phases) {
      for (const skill of phase.skills) {
        learningLineIndicatorIds.push(skill.skillId);
      }
      for (const practice of phase.practices) {
        learningLineIndicatorIds.push(practice.practiceId);
      }
    }

    let lastUpdated = '';
    this.currentIndicators
      .filter(
        (evaluation) =>
          learningLineIndicatorIds.indexOf(evaluation.indicatorId) !== -1
      )
      .map((evaluation) => {
        if (evaluation.created > lastUpdated) {
          lastUpdated = evaluation.created;
        }
      });

    return lastUpdated || null;
  }

  phaseProgress(phase) {
    const skillsMastered = phase.skills.filter((skill) => {
      return this.isIndicatorMastered(skill.skillId);
    });
    const practicesMastered = phase.practices.filter((practice) => {
      return this.isIndicatorMastered(practice.practiceId);
    });
    return (
      ((skillsMastered.length + practicesMastered.length) /
        (phase.skills.length + phase.practices.length)) *
      100
    );
  }

  phaseProgressText(phase) {
    const skillsMastered = phase.skills.filter((skill) => {
      return this.isIndicatorMastered(skill.skillId);
    });
    const practicesMastered = phase.practices.filter((practice) => {
      return this.isIndicatorMastered(practice.practiceId);
    });

    return `${skillsMastered.length + practicesMastered.length} / ${
      phase.skills.length + phase.practices.length
    }`;
  }

  get subjectType() {
    return this.get('subject').type;
  }

  get subjectId() {
    return this.get('subject').id;
  }

  get ratingOptions() {
    return [
      {
        key: 'positive',
        color: 'green',
        i18nKey: 'module.development:ratingPositive',
      },
      {
        key: 'neutral',
        color: 'orange',
        i18nKey: 'module.development:ratingNeutral',
      },
      {
        key: 'negative',
        color: 'red',
        i18nKey: 'module.development:ratingNegative',
      },
    ];
  }
}

class DevelopmentPlanCollection extends PersistentCollection {
  url() {
    return '/developmentPlans';
  }

  model() {
    return DevelopmentPlan;
  }
}

const developmentPlanCollection = new DevelopmentPlanCollection();
window.developmentPlanCollection = developmentPlanCollection;

export default developmentPlanCollection;
