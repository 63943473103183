import React, { useEffect, useRef, useState } from 'react';

import { PlaylistTree } from 'modules/playlist/playlist-tree';
import { VideoPlayerPagination } from 'lib/VideoPlayerPagination';
import { VideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { VideoClock } from 'domain/ObservationLogger/Clock';
import { Modal } from 'lib/Modal';
import { DefaultScoreBoardView as ScoreBoard } from 'lib/Observation/ScoreBoard/Default';
import VideoAngleContextProvider, {
  useVideoAngleContext,
} from '../../lib/VideoAngleContextProvider';
import { VideoAngleSelector } from '../../lib/VideoAngles';

export const Player = ({ videoFragments }) => {
  return (
    <VideoAngleContextProvider>
      <PlayerWithContext videoFragments={videoFragments} />
    </VideoAngleContextProvider>
  );
};

const PlayerWithContext = ({ videoFragments }) => {
  const [currentVideoFragmentIdx, setCurrentVideoFragmentIdx] = useState(0);
  const [clock] = useState(new VideoClock());

  const videoFragmentsParsed = videoFragments.map((original) => ({
    ...original,
    startTime: original.startTime ? Math.floor(original.startTime) : undefined,
    endTime: original.endTime ? Math.floor(original.endTime) : undefined,
    videoId: original.getVideo().videoId,
  }));
  const resizeRef = useRef();

  const resizeHandler = (aspectRatio) => {
    let width, height;
    width = window.innerWidth * 0.8 - 350;
    height = window.innerHeight - 100;

    if (resizeRef.current) {
      // Use width of parent container when available.
      width = resizeRef.current.clientWidth;
    }

    if (width / height < aspectRatio) {
      height = width / aspectRatio;
    } else {
      width = height * aspectRatio;
    }

    return { width: width, height };
  };

  return (
    <>
      <div className="main video-player-container" key="item1">
        <div ref={resizeRef} className="resizeTarget"></div>
        <VideoPlayer
          id="embedding-video-player"
          playerId={'embedded-player'}
          heightAttribute="height"
          videoFragments={videoFragmentsParsed}
          activePlayListItem={currentVideoFragmentIdx}
          onPlaylistItem={(index) => {
            setCurrentVideoFragmentIdx(index);
          }}
          clock={clock}
          disableHotKeys={false}
          resizeHandler={resizeHandler}
          multiCam={videoFragmentsParsed[currentVideoFragmentIdx]
            .getVideo()
            .getOtherAngles()}
        />
        <VideoPlayerPagination
          currentItem={currentVideoFragmentIdx}
          totalItems={videoFragmentsParsed.length}
          setCurrentItem={(index) => {
            setCurrentVideoFragmentIdx(index);
          }}
        />
      </div>

      <div className="sidebar" key="item2">
        <PlaylistTree
          currentIdx={currentVideoFragmentIdx}
          videoFragments={videoFragmentsParsed}
          onSelect={(videoFragment) => {
            setCurrentVideoFragmentIdx(
              videoFragmentsParsed.indexOf(videoFragment)
            );
          }}
        />
      </div>
    </>
  );
};

// Wrap modal outside of player to prevent re-renders.
export const ModalVideoPlayer = ({
  videoFragments,
  onClose,
  fullscreen = true,
}) => {
  return (
    <Modal
      size={fullscreen ? 'full-screen' : 'lg'}
      className={'modal--embedding'}
      onCloseClick={onClose}
    >
      <div className="modal-video-sidebar">
        <Player videoFragments={videoFragments} />
      </div>
    </Modal>
  );
};
