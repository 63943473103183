import { apiClient } from 'mobx-rest';

const logEvent = async (attributes) => {
  await apiClient().post('/usage/log', {
    source: `${window.location.href} # ${navigator.userAgent}`,
    ...attributes,
  });
};

export { logEvent };
