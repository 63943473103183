import React from 'react';
import MediaQuery from 'react-responsive';

import { FilterOptions } from 'lib/FilterOptions';
import { CustomerSupportWrapper } from 'lib/customer-support-wrapper';

import './index.scss';
import { NavLink } from 'react-router-dom';
import { StartTourButton } from 'modules/tour';
import { CurrentLanguage } from '../ChangeLanguage';
import { ViewActionsAndFilters } from './viewFilters';
import { LockedContent } from '../LicenseLimitEnforcing';
import { Helparticles } from './Help/Helparticles';

// Example props:jb
// const exampleProps = {
//   title: 'Leerlijnen',
//   breadcrumbs: [
//     { path: '#', title: 'Home' },
//     { path: '#', title: 'Development' },
//     { path: '#', title: 'Personal' },
//     { title: this.person.fullName },
//   ],
//   primaryAction: {
//     title: 'my primary action',
//     onClick: console.debug,
//   },
//   subtitle: this.person.fullName,
//   icon:
//     'https://web-assets.teamtv.nl/profile_picture/77927dee3348fed6c5456c7eb1ab5b43-84fe249e3b8724aed5e63389f36ae1ce.jpg',
//   filters: [
//     { path: '#', title: 'Teamontwikkeling' },
//     { path: '#', title: 'Spelerontwikkeling', isActive: true },
//   ],
// };
const Page = ({
  className = '',
  title,
  subtitle,
  icon,
  primaryAction,
  breadcrumbs = [],
  filters = [],
  viewFilters = [],
  showPageTour = true,
  showCustomerSupport = true,
  children,
  fullWidth = false,
  noTopPadding = false,
  hideFooter = false,
  hideInset = false,
  showHeaderBackground = false,
  headerChildren = [],
}) => {
  const iconStyles = icon ? { backgroundImage: `url(${icon})` } : {};
  const hasHeaderChildren =
    React.isValidElement(headerChildren) ||
    React.isValidElement(headerChildren[0]);

  const pageStyle = {};
  if (hideFooter) {
    pageStyle.paddingBottom = 0;
  }
  if (hideInset) {
    pageStyle.paddingTop = 0;
  }

  const insetStyle = {};
  if (noTopPadding) {
    insetStyle.marginBottom = 0;
  }

  return (
    <div className={`page ${className}`} style={pageStyle}>
      <header
        className={`page-header ${
          showHeaderBackground ? 'page-header-with-bg' : ''
        }`}
      >
        {!hideInset && (
          <div className="page-header-inset-wrapper">
            <div className="page-header-inset" style={insetStyle}>
              {title && <div className="page-title">{title}</div>}
              <ol className="breadcrumb">
                {breadcrumbs.map((breadcrumb, idx) => (
                  <li key={breadcrumb.title + idx} className="breadcrumb-item">
                    {breadcrumb.path ? (
                      <NavLink
                        className="breadcrumb-item-link"
                        to={breadcrumb.path}
                      >
                        {breadcrumb.title}
                      </NavLink>
                    ) : (
                      <span>{breadcrumb.title}</span>
                    )}
                  </li>
                ))}
              </ol>
              {showPageTour && (
                <div className="page-header-tour">
                  <StartTourButton />
                </div>
              )}
              {showCustomerSupport && (
                <div className="page-header-knowledge-base">
                  <Helparticles />
                </div>
              )}
              <CurrentLanguage />
            </div>
          </div>
        )}
        {(primaryAction ||
          subtitle ||
          icon ||
          filters.length > 0 ||
          viewFilters.length > 0 ||
          hasHeaderChildren) && (
          <div className="page-header-content">
            {((primaryAction && !Array.isArray(primaryAction)) ||
              viewFilters.length > 0) && (
              <ViewActionsAndFilters
                primaryAction={primaryAction}
                filters={viewFilters}
              />
            )}
            <div className="page-subtitle">
              {subtitle && (
                <div className="page-subtitle-text">
                  {typeof subtitle === 'function' ? subtitle() : subtitle}
                </div>
              )}
              {icon && (
                <div className="page-subtitle-icon" style={iconStyles || {}} />
              )}
            </div>
            {filters.length > 0 && (
              <div className="page-actions">
                <FilterOptions filters={filters} />
              </div>
            )}
            {headerChildren}
          </div>
        )}
      </header>

      <div
        className={fullWidth ? '' : 'page-body'}
        style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        {children}
      </div>
    </div>
  );
};

export { Page };
