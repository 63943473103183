import React, { Component } from 'react';

import Select from 'react-select';

import { withTranslation } from 'react-i18next';

import { MatchConfigurationInput } from './base';

const TennisMatchConfigurationInputComponent = withTranslation(
  'module.match.new.tennis'
)(props => {
  const { errors, values, setFieldValue, validClassName, t } = props;

  const matchType = [
    { value: 'BestOf1', label: 'Best of 1' },
    { value: 'BestOf3', label: 'Best of 3' },
    { value: 'BestOf5', label: 'Best of 5' },
  ];

  const setType = [
    { value: 'SetUntil4', label: 'Set until 4' },
    { value: 'SetUntil6', label: 'Set until 6' },
    { value: 'SuperTiebreak', label: 'Supertiebreak' },
  ];

  const endOfSetType = [
    { value: 'Tiebreak', label: 'Tiebreak' },
    { value: 'NoTiebreak', label: 'No-Tiebreak' },
    { value: 'SuperTiebreak', label: 'Supertiebreak' },
  ];

  const finalSetType = [
    { value: 'Tiebreak', label: 'Tiebreak' },
    { value: 'NoTiebreak', label: 'No-Tiebreak' },
    { value: 'SuperTiebreak', label: 'Supertiebreak' },
  ];

  const firstServingParty = [
    { value: 'HomeParty', label: 'Home team' },
    { value: 'AwayParty', label: 'Away team' },
  ];

  const endOfGameType = [
    { value: 'Ad', label: 'Ad' },
    { value: 'NoAd', label: 'No-ad' },
  ];

  const v = (array, value) => {
    for (const option of array) {
      if (option.value === value) {
        return option;
      }
    }
    return null;
  };

  return (
    <div className="col-12">
      <div>
        <div className={`form-group ${validClassName('firstServingParty')}`}>
          <label htmlFor="new-match-definition">
            {t('firstServingPartyLabel')}
          </label>
          <Select
            id="new-match-firstserving_party"
            name="firstServingParty"
            className="custom-form-control"
            value={v(firstServingParty, values.firstServingParty)}
            onChange={option =>
              setFieldValue('firstServingParty', option.value)
            }
            options={firstServingParty}
            placeholder={t('firstServingPlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
      <div>
        <div className={`form-group ${validClassName('matchType')}`}>
          <label htmlFor="new-match-definition">{t('matchTypeLabel')}</label>
          <Select
            id="new-match-match-type"
            name="matchType"
            className="custom-form-control"
            value={v(matchType, values.scoreSystemConfig.matchType)}
            onChange={option =>
              setFieldValue('scoreSystemConfig.matchType', option.value)
            }
            options={matchType}
            placeholder={t('matchTypePlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
      <div>
        <div className={`form-group ${validClassName('setType')}`}>
          <label htmlFor="new-match-game-definition">{t('setTypeLabel')}</label>
          <Select
            id="new-match-set-type"
            name="setType"
            className="custom-form-control"
            value={v(setType, values.scoreSystemConfig.setType)}
            onChange={option =>
              setFieldValue('scoreSystemConfig.setType', option.value)
            }
            options={setType}
            placeholder={t('setTypePlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
      <div>
        <div className={`form-group ${validClassName('endOfGameType')}`}>
          <label htmlFor="new-match-game-definition">
            {t('endOfGameTypeLabel')}
          </label>
          <Select
            id="new-match-endofgame-type"
            name="endOfGameType"
            className="custom-form-control"
            value={v(endOfGameType, values.scoreSystemConfig.endOfGameType)}
            onChange={option =>
              setFieldValue('scoreSystemConfig.endOfGameType', option.value)
            }
            options={endOfGameType}
            placeholder={t('endOfGameTypePlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
      <div>
        <div className={`form-group ${validClassName('endOfSetType')}`}>
          <label htmlFor="new-match-game-definition">
            {t('endOfSetTypeLabel')}
          </label>
          <Select
            id="new-match-endofgame-type"
            name="endOfSetType"
            className="custom-form-control"
            value={v(endOfSetType, values.scoreSystemConfig.endOfSetType)}
            onChange={option =>
              setFieldValue('scoreSystemConfig.endOfSetType', option.value)
            }
            options={endOfSetType}
            placeholder={t('endOfSetTypePlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
      <div>
        <div className={`form-group ${validClassName('finalSetType')}`}>
          <label htmlFor="new-match-game-definition">
            {t('finalSetTypeLabel')}
          </label>
          <Select
            id="new-match-final-type"
            name="finalSetType"
            className="custom-form-control"
            value={v(finalSetType, values.scoreSystemConfig.finalSetType)}
            onChange={option =>
              setFieldValue('scoreSystemConfig.finalSetType', option.value)
            }
            options={finalSetType}
            placeholder={t('finalSetTypePlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
    </div>
  );
});

const tennisMatchConfigurationInput = new MatchConfigurationInput({
  Component: TennisMatchConfigurationInputComponent,
  getInitialValues: () => {
    return {
      scoreSystemConfig: {},
    };
  },
  getValues: matchConfig => {
    return {
      scoreSystemConfig: {
        matchType: matchConfig.scoreSystemConfig.matchType,
        setType: matchConfig.scoreSystemConfig.setType,
        finalSetType: matchConfig.scoreSystemConfig.finalSetType,
        endOfSetType: matchConfig.scoreSystemConfig.endOfSetType,
        endOfGameType: matchConfig.scoreSystemConfig.endOfGameType,
      },
      firstServingParty: matchConfig.firstServingParty,
    };
  },
  validator: matchConfig => {
    const errors = {};
    if (!matchConfig.scoreSystemConfig.matchType) {
      errors.matchType = true;
    }
    if (!matchConfig.scoreSystemConfig.setType) {
      errors.setType = true;
    }
    if (!matchConfig.scoreSystemConfig.finalSetType) {
      errors.finalSetType = true;
    }
    if (!matchConfig.scoreSystemConfig.endOfSetType) {
      errors.endOfSetType = true;
    }
    if (!matchConfig.scoreSystemConfig.endOfGameType) {
      errors.endOfGameType = true;
    }
    if (!matchConfig.firstServingParty) {
      errors.firstServingParty = true;
    }
    return errors;
  },
});

export { tennisMatchConfigurationInput };
