import { CountDown } from 'lib/Countdown';
import premium from '../../../img/advertisements/premium.svg';
import premiumNL from '../../../img/advertisements/premiumnl.svg';
import premiumDE from '../../../img/advertisements/premiumde.svg';
import Popup from 'lib/notifications/popup';
import React, { useEffect, useState } from 'react';
import { useTranslation, i18n } from 'react-i18next';
import { Session } from '../../../domain/Session';
import uuidv4 from 'uuid/v4';

export const DownloadWaitTime = ({
  time = 60,
  completeCallback,
  closeCallback,
}) => {
  const [completed, setCompleted] = useState(false);
  const [transactionId, _] = useState(() => uuidv4());

  const { t, i18n } = useTranslation('downloadWait');

  const logAction = (event, attributes = {}) => {
    return Session.current().user.logAction('LicenseLimitEnforcing', {
      component: 'DownloadWait',
      transactionId,
      event,
      ...attributes,
    });
  };
  const image = () => {
    if (i18n.language == 'nl') {
      return premiumNL;
    } else if (i18n.language == 'de') {
      return premiumDE;
    } else {
      return premium;
    }
  };

  useEffect(() => {
    logAction('Opened', { time });
  }, []);

  return (
    <Popup
      noPadding
      onCloseCallback={() => {
        closeCallback();
        logAction('Closed', {
          completed,
        });
      }}
    >
      <CountDown
        time={time}
        onCompleteCallback={() => {
          logAction('Completed');
          setCompleted(true);
        }}
        wait={
          <>
            <a
              onClick={() => {
                logAction('UpgradeCtaClick', { item: 'image' });
              }}
              target="_blank"
              rel="noopener"
              href={t('ctaLink')}
            >
              <img
                className="premium-banner-video"
                src={image()}
                style={{ width: '100%' }}
              />
            </a>
            <div className="pop-inner">
              <h3>{t('title')}</h3>
              <p>
                {t('ctaText')}{' '}
                <a
                  onClick={() => {
                    logAction('UpgradeCtaClicked', { item: 'link' });
                  }}
                  target="_blank"
                  rel="noopener"
                  href={t('ctaLink')}
                >
                  {t('cta')}
                </a>{' '}
                {t('ctaText1')}
              </p>
            </div>
          </>
        }
        complete={
          <>
            <a
              onClick={() => {
                logAction('UpgradeCtaClick', { item: 'image' });
              }}
              target="_blank"
              rel="noopener"
              href={t('ctaLink')}
            >
              <img
                className="premium-banner-video"
                src={image()}
                style={{ width: '100%' }}
              />
            </a>
            <div className="pop-inner">
              <h3>{t('titleDone')}</h3>
              <p>
                {t('ctaText')}{' '}
                <a
                  onClick={() => {
                    logAction('UpgradeCtaClick', { item: 'link' });
                  }}
                  target="_blank"
                  rel="noopener"
                  href={t('ctaLink')}
                >
                  {t('cta')}
                </a>{' '}
                {t('ctaText1')}
              </p>
              <p>{t('textDone')}</p>
              <a
                className="btn btn-primary download fadeInLeft"
                href="#"
                onClick={e => {
                  completeCallback();
                  logAction('CtaClicked');
                  e.preventDefault();
                }}
              >
                {t('download')}
              </a>
            </div>
          </>
        }
      />
    </Popup>
  );
};
