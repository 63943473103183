import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import React, { useEffect, useRef, useState } from 'react';
import sportingEventCollection from '../../../domain/SportingEvent';
import Loading from '../../../lib/Loading';
import { getMembers } from '../../../domain/ResourceGroup';
import api from '../../../utils/ApiClient';
import { Session } from '../../../domain/Session';

import { useTranslation } from 'react-i18next';

import Lottie from 'react-lottie';
import completeLottie from 'lib/animations/lottie/completed.json';
import completeLottieSR from 'lib/animations/lottie/completed_sr.json';

import moment from 'moment';

export const Onboarding = () => {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState();
  const [customTags, setCustomTags] = useState();
  const [playlists, setPlaylists] = useState();
  const { t } = useTranslation('module.onboarding');

  useEffect(() => {
    (async () => {
      try {
        await sportingEventCollection.fetchIfEmpty();
        await getMembers().then((result) => setMembers(result));
      } catch (e) {
        // No permission on fetches.
        return null;
      }
      try {
        const { data: playlists } = await api.get('playlists');
        setPlaylists(playlists);
      } catch (e) {
        setPlaylists([]);
      }
      try {
        const { data: customTags } = await api.get('customTags');
        setCustomTags(customTags);
      } catch (e) {
        setCustomTags([]);
      }
      setLoading(false);
    })();
  }, [sportingEventCollection]);

  const UserMadeMatches = sportingEventCollection.getUserMadeMatches();
  const currentSession = Session.current();
  const currentUser = currentSession.user;

  const logAction = (event, attributes = {}) => {
    return Session.current().user.logAction('OnboardingLinkClicked', {
      component: 'Home:Onboarding',
      event,
      ...attributes,
    });
  };
  let onboardingItems = [];

  // Created an account
  onboardingItems.push({
    label: t('createAccount'),
    isCompleted: true,
  });

  // Complete profile
  onboardingItems.push({
    label: t('completeProfile'),
    isCompleted:
      currentUser.lastName &&
      currentUser.firstName &&
      currentUser.profilePictureUrl,
    link: '/user-management/user-profile',
  });

  // Verify your email
  onboardingItems.push({
    label: t('verifyEmail'),
    isCompleted: currentUser.emailVerified,
    link: '/user-management/user-profile',
  });

  // Add teams
  if (currentSession.isFeatureAvailable('manageTeams')) {
    onboardingItems.push({
      label: t('addTeams'),
      isCompleted:
        currentSession
          .memberships()
          .toJS()
          .filter((m) => m.targetResourceId.search(/(education|team)/) !== -1)
          .length > 1,
      link: '/user-management/teams',
    });
  }

  // Add users
  if (currentSession.isFeatureAvailable('manageUsers')) {
    onboardingItems.push({
      label: t('addMembers'),
      isCompleted: members?.length > 1,
      link: '/user-management/team',
    });
  }

  // Add match
  if (currentSession.isFeatureAvailable('createSportingEvent')) {
    onboardingItems.push({
      label: t('addMatch'),
      isCompleted: UserMadeMatches.length > 0,
      link: window.isSkillReflect
        ? '/recordings/overview#add'
        : '/match/overview/all#add',
    });
  }

  // Upload video
  if (
    currentSession.isFeatureAvailable('createSportingEvent') &&
    currentSession.isFeatureAvailable('uploadVideo')
  ) {
    onboardingItems.push({
      label: t('addMatchVideo'),
      isCompleted: UserMadeMatches.filter((s) => s.hasVideo()).length > 0,
      tooltip: t('AddMatchVideoTooltip'),
    });
  }

  // Add custom tags
  if (currentSession.isFeatureAvailable('manageCustomTags')) {
    onboardingItems.push({
      label: t('addCustomTag'),
      isCompleted: customTags?.length > 0,
      link: '/user-management/team-tags',
    });
  }

  // Create your first playlist
  if (currentSession.currentPrivileges().hasPrivilege('playlist:create')) {
    onboardingItems.push({
      label: t('addPlaylist'),
      isCompleted:
        playlists?.length > 0 &&
        playlists.filter((p) => p.creator.id === currentUser.realUserId)
          .length > 0,
      tooltip: t('addPlaylistTooltip'),
    });
  }

  let percentage =
    (onboardingItems.filter((i) => i.isCompleted).length /
      onboardingItems.length) *
    100;

  const [isOpen, setIsOpen] = useState(false);
  const onboardingListRef = useRef();

  let listHeight = onboardingListRef.current?.scrollHeight;
  if (loading) {
    return (
      <div className="onboarding">
        <Loading type={'dots'} size={40} />
      </div>
    );
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: window.isSkillReflect ? completeLottieSR : completeLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    eventListeners: [
      {
        eventName: 'loopComplete',
      },
    ],
  };

  // Check if users is older than 14 days to show onboarding.
  let createdDate = Session.current().user.created;
  let today = moment().startOf('day');
  if (today.diff(createdDate, 'days') > 14) {
    return null;
  }
  const LIST_MAX_SIZE = 200;

  const isCompleted =
    onboardingItems.filter((item) => !item.isCompleted).length === 0;

  return (
    <div className="row">
      <div className="col-12">
        <div
          className={`block block--onboarding ${
            isCompleted ? 'onboarding--complete' : ''
          }`}
        >
          <div className="pill">{t('module.home:onboarding')}</div>
          <div className="onboarding">
            <div className="onboarding__checklist">
              <div className="onboarding__status">
                <span>{Math.round(percentage)}%</span>
                <div className="percentage">
                  <div style={{ width: Math.round(percentage) + '%' }}></div>
                </div>
              </div>
              <ul
                className="onboarding__list"
                ref={onboardingListRef}
                style={{
                  maxHeight: `${isOpen ? listHeight : LIST_MAX_SIZE}px`,
                }}
              >
                {onboardingItems.map((item, index) => (
                  <li
                    key={`onboardingItem-${index}`}
                    className={`onboarding__item ${
                      item.isCompleted ? 'onboarding__item--completed' : ''
                    }`}
                  >
                    <OnboardingItem
                      logAction={() => logAction(item?.link)}
                      item={item}
                    />
                  </li>
                ))}
              </ul>

              {listHeight > LIST_MAX_SIZE && (
                <button
                  className={`onboarding__showmore ${
                    isOpen ? 'onboarding__showmore--open' : ''
                  }`}
                  onClick={() => setIsOpen((old) => !old)}
                >
                  {isOpen ? 'Show less' : 'Show more'}
                  <i className={'i-chevron-down i-xs i-dark'}></i>
                </button>
              )}
            </div>
            {isCompleted && (
              <div className="onboarding__complete">
                <Lottie
                  style={{ height: 200, width: 200 }}
                  options={defaultOptions}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const OnboardingItem = ({ item, logAction = () => {} }) => {
  if (item.isCompleted) {
    return <span>{item.label}</span>;
  }
  if (item.link && !item.isCompleted) {
    return (
      <Link to={item.link} onClick={() => logAction()}>
        <i className={'i-link i-xs'}></i>
        {item.label}
      </Link>
    );
  }

  if (!item.link) {
    return (
      <span>
        <div
          data-tip=""
          data-for={item.label}
          className={`locked-content-tip`}
          style={{ display: 'display' }}
        >
          {item.tooltip && (
            <ReactTooltip
              id={item.label}
              type="dark"
              effect="solid"
              // clickable={true}
              className={'tooltip--styled lock-tooltip'}
              backgroundColor="#495057"
              place={'right'}
            >
              {item.tooltip}
            </ReactTooltip>
          )}
          {item.label}
          {item.tooltip && <span className="help"> ?</span>}
        </div>
      </span>
    );
  }
};
