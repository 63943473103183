import React, { useState } from 'react';

import { ObservationInput } from '../../ObservationInput';
import { Modal } from 'lib/Modal';

import './keepers.scss';

export const LiveObservationInputHandballKeeperView = ({
  keeperSelections,
  setKeeperSelections,
  playDirection,
  players,
}) => {
  const [keeperModalTeam, setKeeperModalTeam] = useState(null);

  const showKeeperModal = team => _evt => {
    setKeeperModalTeam(team);
  };

  const handleKeeperSelection = team => playerId => _evt => {
    setKeeperSelections(selections => ({ ...selections, [team]: playerId }));
    setKeeperModalTeam(null);
  };

  const keeperButtonText = team => {
    const keeper =
      keeperSelections[team] &&
      players[team].find(player => player.id === keeperSelections[team]);

    return keeper
      ? `${keeper.number} – ${keeper.fullName}`
      : `Selecteer ${team === 'home' ? 'thuis' : 'uit'} keeper`;
  };

  const playDirectionClassName =
    playDirection === 'home-away' ? 'play-direction-ltr' : 'play-direction-rtl';

  return (
    <>
      <div
        className={`live-observation-layer handball-keepers ${playDirectionClassName}`}
      >
        <button
          type="button"
          className="handball-keeper-button"
          onClick={showKeeperModal('home')}
        >
          {keeperButtonText('home')}
        </button>
        <button
          type="button"
          className="handball-keeper-button"
          onClick={showKeeperModal('away')}
        >
          {keeperButtonText('away')}
        </button>
      </div>
      {keeperModalTeam && (
        <Modal onCloseClick={() => setKeeperModalTeam(null)} size="sm">
          <KeeperSelectionView
            team={keeperModalTeam}
            onSelect={handleKeeperSelection(keeperModalTeam)}
            players={players[keeperModalTeam]}
            selectedPlayerId={keeperSelections[keeperModalTeam]}
          />
        </Modal>
      )}
    </>
  );
};

const KeeperSelectionView = ({ players, selectedPlayerId, onSelect }) => {
  if (players.length === 0) {
    return (
      <div className="keeper-selection">
        <p>Geen spelers ingevoerd.</p>
      </div>
    );
  } else {
    return (
      <div className="keeper-selection">
        {selectedPlayerId && (
          <button type="button" className="keeper" onClick={onSelect(null)}>
            <div className="player-number"></div>
            <div className="player-fullname">— geen keeper —</div>
          </button>
        )}
        {players.map((player, idx) => (
          <KeeperSelectionButton
            key={player.id}
            player={player}
            onSelect={onSelect(player.id)}
          />
        ))}
      </div>
    );
  }
};

const KeeperSelectionButton = ({ player, onSelect }) => {
  return (
    <button type="button" className="keeper" onClick={onSelect}>
      <div className="player-number">{player.number}</div>
      <div className="player-fullname">{player.fullName}</div>
    </button>
  );
};
