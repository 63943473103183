import React, { Component, Fragment } from 'react';
import { tennisMatchConfigurationInput } from './tennis.js';
import { korfballMatchConfigurationInput } from './korfball.js';
import { MatchConfigurationInput } from './base.js';
import { hockeyMatchConfigurationInput } from './hockey';
//
// const MatchConfigurationInput = ({ sportType, ...props }) => {
//   switch (sportType) {
//     case 'tennis':
//       return <TennisMatchConfigurationInput {...props} />;
//     default:
//       return null;
//   }
// };

const EmptyComponent = () => {
  return null;
};

const emptyMatchConfigurationInput = new MatchConfigurationInput({
  Component: null,
  getInitialValues: () => ({}),
  getValues: () => ({}),
  validator: () => ({}),
});

const getMatchConfigurationInput = (sportType, sportingEvent) => {
  switch (sportType) {
    case 'tennis':
      return tennisMatchConfigurationInput;
    case 'korfball':
      if (sportingEvent?.type === 'match') {
        return korfballMatchConfigurationInput;
      } else {
        return emptyMatchConfigurationInput;
      }

    case 'hockey':
      return hockeyMatchConfigurationInput;
    default:
      return emptyMatchConfigurationInput;
  }
};

export { getMatchConfigurationInput };
