import { useTranslation } from 'react-i18next';
import moment, { isMoment } from 'moment';
import { Modal } from '../../../lib';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import CreatableSelect from 'react-select/creatable';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import CheckboxScheduling from '../../match/components/MatchConfigurationInput/CheckboxScheduling';
import Loading from '../../../lib/Loading';
import React from 'react';

export const AddOrEditSchedule = ({
  onClose,
  scheduleEntry,
  teamValues,
  onSubmit,
}) => {
  const { t } = useTranslation('module.schedule');
  const initialValues = {
    name: scheduleEntry?.name ?? '',
    start: scheduleEntry?.start ? moment(scheduleEntry.start) : null,
    end: scheduleEntry?.end ? moment(scheduleEntry.end) : null,
    sportingEventType: 'training',
    automaticCameraConfig: scheduleEntry?.tags?.automaticCameraConfig ?? {
      record: true,
      recordingDuration: 90,
    },
    repeat: scheduleEntry?.repeat ?? 'weekly',
    resourceGroupId: scheduleEntry?.resourceGroupId ?? null,
  };
  let title = scheduleEntry ? t('editSchedule') : t('addSchedule');
  const handleSubmit = async (values) => {
    await onSubmit(values);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'required';
    }
    if (!values.start) {
      errors.start = 'required';
    }
    if (!values.end || moment(values.end).isBefore(values.start)) {
      errors.end = 'required';
    }
    if (!values.resourceGroupId) {
      errors.resourceGroupId = 'required';
    }
    return errors;
  };

  return (
    <Modal onCloseClick={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {({
          handleSubmit,
          values,
          submitCount,
          isSubmitting,
          errors,
          setFieldValue,
          onReset,
        }) => {
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };
          return (
            <form
              onSubmit={
                !isSubmitting ? handleSubmit : (e) => e.preventDefault()
              }
            >
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12">
                    <div className={`form-group ${validClassName('start')}`}>
                      <label htmlFor="schedule-start">
                        {t('scheduleStart')}
                      </label>
                      <DatePicker
                        id="schedule-start"
                        name="start"
                        className="form-control-datepicker"
                        selected={values.start}
                        onChange={(value) => {
                          value &&
                            isMoment(value) &&
                            setFieldValue('start', value.startOf('minute'));
                        }}
                        showTimeSelect
                        autoComplete="off"
                        dateFormat="LLL"
                        timeFormat="HH:mm"
                        isClearable={false}
                        // maxDate={values.end}
                        timeIntervals={5}
                        placeholderText={t('scheduleStartPlaceholder')}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                    <div className={`form-group ${validClassName('end')}`}>
                      <label htmlFor="scheduled-until">
                        {t('scheduleEnd')}
                      </label>
                      <DatePicker
                        id="scheduled-until"
                        name="end"
                        className="form-control-datepicker"
                        selected={values.end}
                        onChange={(value) => {
                          value &&
                            isMoment(value) &&
                            setFieldValue('end', value.startOf('minute'));
                        }}
                        autoComplete="off"
                        minDate={values.start}
                        dateFormat="LL"
                        isClearable={false}
                        placeholderText={t('scheduleEndPlaceholder')}
                      />
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                    <div className={`form-group ${validClassName('name')}`}>
                      <label htmlFor="name">{t('scheduleName')}</label>

                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        placeholder={t('scheduleNamePlaceholder')}
                      />
                      <span className="field-description">
                        {t('scheduleNameDescription', {
                          type: `${t('module.match:typePlural:training')}`,
                        })}
                      </span>
                      <div className="invalid-feedback">
                        {t('common.form:required-field')}
                      </div>
                    </div>
                    {teamValues && (
                      <div
                        className={`form-group ${validClassName(
                          'resourceGroupId'
                        )}`}
                      >
                        <label>{t('selectTeam')}</label>
                        <CreatableSelect
                          value={teamValues.find(
                            (v) => v.value === values.resourceGroupId
                          )}
                          options={teamValues}
                          onChange={(option) => {
                            setFieldValue('resourceGroupId', option.value);
                          }}
                        />
                        <div className="invalid-feedback">
                          {t('common.form:required-field')}
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <label>{t('repeat')}</label>
                      {/*{['weekly', 'biWeekly', 'monthly'].map((c) => (*/}
                      {/*  <InputCheckboxRadio*/}
                      {/*    key={c}*/}
                      {/*    label={c}*/}
                      {/*    disabled={c !== 'weekly'}*/}
                      {/*    onChange={() => setFieldValue('repeat', c)}*/}
                      {/*    checked={values.repeat === c}*/}
                      {/*  />*/}
                      {/*))}*/}
                      <InputCheckboxRadio
                        label={t('weekly')}
                        disabled={true}
                        onChange={() => setFieldValue('repeat', 'weekly')}
                        checked={true}
                      />
                    </div>
                    <div className="form-group">
                      <CheckboxScheduling
                        callback={(value) =>
                          setFieldValue('automaticCameraConfig', value)
                        }
                        type={'training'}
                        values={values}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="form-actions">
                  <button type="submit" className="btn btn-primary">
                    {t('common:save')}
                    {isSubmitting && (
                      <Loading size={16} color={'#fff'} type={'spinner'} />
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={onClose}
                  >
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
