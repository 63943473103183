import { AuthAwareAdapterProxy } from '../../../utils/AuthAwareAdapterProxy';
import ResourceGroupCollection from '../../../domain/ResourceGroup';
import api from '../../../utils/ApiClient';

export const joinResourceGroup = async (
  resourceGroupId,
  parentResourceGroupId
) => {
  await AuthAwareAdapterProxy.withUseResourceGroup(
    parentResourceGroupId,
    async () => {
      const resourceGroup = await ResourceGroupCollection.getOrFetch(
        resourceGroupId
      );
      await resourceGroup.join();
    }
  );
};

export const getLearningGroup = async (learningGroupId) => {
  let { data } = await api.get(`learningGroups/${learningGroupId}`);
  return data ? data : {};
};
