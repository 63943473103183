import { withTranslation } from 'react-i18next';

import momentjs from 'moment';

const DaysAgo = withTranslation('common')(({ date, t }) => {
  date = momentjs(date);
  date.set({ h: 23, m: 59, s: 59 });

  let count = momentjs().diff(date, 'days');

  if (count === 0) {
    return t('daysAgo_0');
  } else if (count === 1) {
    return t('daysAgo_1');
  } else {
    return t('daysAgo_2', { count });
  }
});

export { DaysAgo };
