import React from 'react';
import moment from 'moment';

import { OverviewItem } from 'lib/OverviewItem';
import { SportingEventScore } from 'lib/SportingEventScore';

import RecSVG from '../../../img/icons/rec.svg';

import exchangeIcon from 'img/icons/exchange.png';

import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { AutomaticRecordingTooltip } from './SportingEventComponent/AutomaticRecordingTooltip';

export const MatchOverviewItem = (props) => {
  const { sportingEvent, locked } = props;
  const { t } = useTranslation();

  const ended = props.emphasis
    ? sportingEvent.ended()
    : sportingEvent.probablyEnded();
  const started = sportingEvent.started();
  const itemDate = moment(sportingEvent.get('scheduledAt'));
  let centerText = null;

  if (!!locked) {
    centerText = '-';
  } else if (sportingEvent.hasScore()) {
    centerText = sportingEvent.getScore();
  } else if (started || ended) {
    centerText = (
      <SportingEventScore
        sportingEvent={sportingEvent}
        liveUpdate={props.active}
      />
    );
  } else {
    centerText = itemDate.format('HH:mm');
  }

  const details = () => {
    if (sportingEvent.get('type') === 'recording') {
      return sportingEvent.label;
    } else if (
      !sportingEvent.hasTeams() &&
      sportingEvent.get('type') !== 'training'
    ) {
      return sportingEvent.label;
    } else {
      return (
        <MatchOverviewItemDetails
          sportingEvent={sportingEvent}
          centerText={centerText}
          t={t}
        />
      );
    }
  };

  const overviewItemProps = {
    ...props,
    details,
    itemDate,
  };
  if (sportingEvent.isDemo()) {
    overviewItemProps.label = 'demo';
    overviewItemProps.isDemo = true;
  }

  return (
    <div
      className={`match-overview-item ${
        overviewItemProps.locked ? 'overview-item--islocked' : ''
      }`}
      data-id={sportingEvent.id}
      data-copy-id={sportingEvent.tags?.copyOf}
    >
      <OverviewItem {...overviewItemProps} />
    </div>
  );
};

const MatchOverviewItemDetails = ({ sportingEvent, centerText, t }) => {
  return (
    /*
        Do something cool in case of sportingEvent.isDemo()
         */
    <>
      {!sportingEvent.isDemo() &&
        (sportingEvent.isShared() || sportingEvent.isCopy()) && (
          <div className="overview-item-attribute">
            <img
              className={`sharing-icon ${
                !!sportingEvent.tags.copyOf ? 'in-use' : ''
              }`}
              src={exchangeIcon}
              alt="exchange icon"
            />
          </div>
        )}
      {sportingEvent.tags.automaticCameraConfig?.record && (
        <AutomaticRecordingTooltip />
      )}
      {sportingEvent.get('type') === 'match' && (
        <>
          <div className="overview-item-team overview-item-team-home">
            <div className="hidden-md">{sportingEvent.homeTeam.label}</div>
            <div className="show-md">{sportingEvent.homeTeam.shortLabel}</div>
            <div
              className="i-team hidden-md"
              style={{
                backgroundImage: `url(${sportingEvent.homeTeam.logoUrl})`,
              }}
            />
          </div>
          <div className="overview-item-score">{centerText}</div>
          <div className="overview-item-team overview-item-team-away">
            <div className="hidden-md">{sportingEvent.awayTeam.label}</div>
            <div className="show-md">{sportingEvent.awayTeam.shortLabel}</div>
            <div
              className="i-team hidden-md"
              style={{
                backgroundImage: `url(${sportingEvent.awayTeam.logoUrl})`,
              }}
            />
          </div>
        </>
      )}
      {sportingEvent.get('type') !== 'match' && `${sportingEvent.label}`}
    </>
  );
};
