import React, { Component } from 'react';
import Authentication from '../utils/Authentication';
import { withRouter } from 'react-router-dom';
import Loading from 'lib/Loading';

// Icoontje voor de input is before-input-icon. Bewust niet met pseudo :after gewerkt omdat je via deze weg de background aan kan passen, met de kleuren van de club
// TeamTV logo staat in de branding-container. Je kunt hier eventueel het logo van de club laden na initialisatie via bijvoorbeeld localStorage (of dingen zoals PouchDB).
// Voor de onClick voor wachtwoord aanvragen en demo aanvragen kun je het beste zelf even aankijken wat je hier mee wilt.

class LoginPage extends React.Component {
  login() {
    let targetPath = this.props.location.state
      ? this.props.location.state.from.pathname
      : '/';
    if (targetPath === '/login') {
      targetPath = '/';
    }

    Authentication.login(targetPath);
  }

  componentDidMount() {
    this.login();
  }

  render() {
    return <Loading type={'fullscreen'} />;
  }
}

export default withRouter(LoginPage);
