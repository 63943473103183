import React, { useContext, useState } from 'react';

import { VideoTimeContext } from './utils/VideoTimeProvider';
import { formatTime } from 'lib/FormattedTime';
import { getClipConfig } from '../../remotion/clipRegistry';
import i18n from 'i18next';
import { Tooltip } from '../../lib/Tooltip';

export const HighlightContentBlock = ({
  name,
  duration,
  onClick,
}: {
  name: string;
  duration: string;
  onClick: () => void;
}) => (
  <div className="highlight-content-block clickable" onClick={onClick}>
    <div className="highlight-content-block-name">{name}</div>
    <div className="highlight-content-block-duration">{duration}</div>
  </div>
);

export const HighlightContentBlockClip = ({
  name,
  contentId,
  onDelete,
  onClick,
  startTime,
  endTime,
  angle,
  sportingEventLabel,
  seStart,
  seEnd,
  img,
  onEditClip,
  clip,
}: {
  name: string;
  duration: number;
  highlightVideoId: string;
  templateBlockId: string;
  contentId: string;
  onDelete: () => void;
  onClick: () => void;
  startTime: number;
  seStart: number;
  seEnd: number;
  endTime: number;
  index: number;
  angle: string;
  sportingEventLabel: string;
  img: JSX.Element;
  onEditClip: () => void;
  clip: any[];
}) => {
  const videoTime = useContext(VideoTimeContext);
  return (
    <div
      className={`clip draggableclip ${
        startTime <= videoTime && videoTime <= endTime ? 'active' : ''
      }`}
    >
      <div className="clip_l">
        <div onClick={onClick} className="clip_thumb">
          {img}
        </div>
      </div>
      <div className="clip_m">
        <h5>
          {sportingEventLabel}{' '}
          <span className="small">{`@${formatTime(seStart)}`}</span>
        </h5>
        <h4>{name}</h4>
        <div className="clip_duration">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1008_600)">
              <path
                d="M5.49984 10.0837C8.03114 10.0837 10.0832 8.03163 10.0832 5.50033C10.0832 2.96902 8.03114 0.916992 5.49984 0.916992C2.96853 0.916992 0.916504 2.96902 0.916504 5.50033C0.916504 8.03163 2.96853 10.0837 5.49984 10.0837Z"
                stroke="#A4A4A4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 2.75V5.5L7.33333 6.41667"
                stroke="#A4A4A4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1008_600">
                <rect width="11" height="11" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {formatTime(startTime)} - {formatTime(endTime)}{' '}
          {angle && (
            <div className={'clip_angle'}>
              <svg
                width="17"
                height="11"
                viewBox="0 0 17 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 2.25L11 5.75L16 9.25V2.25Z"
                  stroke="#A4A4A4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.66667 1.25H2.33333C1.59695 1.25 1 1.82563 1 2.53571V8.96429C1 9.67437 1.59695 10.25 2.33333 10.25H9.66667C10.403 10.25 11 9.67437 11 8.96429V2.53571C11 1.82563 10.403 1.25 9.66667 1.25Z"
                  stroke="#A4A4A4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {angle}
            </div>
          )}
          {clip?.transition && (
            <div className={'clip_effect'}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#A4A4A4"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polygon points="5 4 15 12 5 20 5 4"></polygon>
                <line x1="19" y1="5" x2="19" y2="19"></line>
              </svg>
              {clip?.transition}
            </div>
          )}
        </div>
      </div>
      <div className="clip_r">
        <button
          onClick={() => {
            onEditClip(contentId, 'edit', startTime);
          }}
          className="clip_button clip_button-edit"
        >
          <Tooltip tooltip={'Edit clip'}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Tooltip>
        </button>
        <button
          onClick={() => onEditClip(contentId, 'copy', endTime + 0.1)} // Start at next clip
          className="clip_button clip_button-copy"
        >
          <Tooltip tooltip={'Copy clip'}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 7.125H8.70833C7.83388 7.125 7.125 7.83388 7.125 8.70833V15.8333C7.125 16.7078 7.83388 17.4167 8.70833 17.4167H15.8333C16.7078 17.4167 17.4167 16.7078 17.4167 15.8333V8.70833C17.4167 7.83388 16.7078 7.125 15.8333 7.125Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.95825 11.8747H3.16659C2.74666 11.8747 2.34393 11.7079 2.047 11.4109C1.75007 11.114 1.58325 10.7113 1.58325 10.2913V3.16634C1.58325 2.74642 1.75007 2.34369 2.047 2.04676C2.34393 1.74982 2.74666 1.58301 3.16659 1.58301H10.2916C10.7115 1.58301 11.1142 1.74982 11.4112 2.04676C11.7081 2.34369 11.8749 2.74642 11.8749 3.16634V3.95801"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Tooltip>
        </button>
        {typeof onDelete == 'function' && (
          <button className="clip_button clip_button-delete" onClick={onDelete}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.375 4.75H3.95833H16.625"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.0416 4.74967V15.833C15.0416 16.2529 14.8748 16.6557 14.5778 16.9526C14.2809 17.2495 13.8782 17.4163 13.4583 17.4163H5.54159C5.12166 17.4163 4.71893 17.2495 4.422 16.9526C4.12507 16.6557 3.95825 16.2529 3.95825 15.833V4.74967M6.33325 4.74967V3.16634C6.33325 2.74641 6.50007 2.34369 6.797 2.04676C7.09393 1.74982 7.49666 1.58301 7.91659 1.58301H11.0833C11.5032 1.58301 11.9059 1.74982 12.2028 2.04676C12.4998 2.34369 12.6666 2.74641 12.6666 3.16634V4.74967"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.91675 8.70801V13.458"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.0833 8.70801V13.458"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export const HighlightContentBlockGeneric = ({
  content,
  onEditClip,
  onDeleteContent,
  onClick,
  template,
  openEditContent,
}) => {
  const videoTime = useContext(VideoTimeContext);
  const config = getClipConfig(content.type);
  const isRemovable = config?.removable;
  const isDuplicateable = template.availableContent.includes(content.type);
  const currentLanguage = i18n?.language ?? 'en';

  return (
    <div
      className={`clip draggableclip ${
        content.startTime <= videoTime && videoTime <= content.endTime
          ? 'active'
          : ''
      }`}
    >
      <div className="clip_l">
        <div
          onClick={onClick}
          className="clip_thumb"
          style={{ backgroundImage: `url("${config?.thumbnail}")` }}
        ></div>
      </div>
      <div className="clip_m">
        <h5>
          <span className="small">{content.type.split('.')[0]}</span>
        </h5>
        <h4>{config.name[currentLanguage]}</h4>
        <div className="clip_duration">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1008_600)">
              <path
                d="M5.49984 10.0837C8.03114 10.0837 10.0832 8.03163 10.0832 5.50033C10.0832 2.96902 8.03114 0.916992 5.49984 0.916992C2.96853 0.916992 0.916504 2.96902 0.916504 5.50033C0.916504 8.03163 2.96853 10.0837 5.49984 10.0837Z"
                stroke="#A4A4A4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 2.75V5.5L7.33333 6.41667"
                stroke="#A4A4A4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1008_600">
                <rect width="11" height="11" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {formatTime(content.startTime)} - {formatTime(content.endTime)}{' '}
        </div>
      </div>
      <div className="clip_r">
        <button
          onClick={() => openEditContent(content)}
          className="clip_button clip_button-edit"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {isDuplicateable && (
          <button className="clip_button clip_button-copy">
            <svg
              width="15"
              height="15"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 7.125H8.70833C7.83388 7.125 7.125 7.83388 7.125 8.70833V15.8333C7.125 16.7078 7.83388 17.4167 8.70833 17.4167H15.8333C16.7078 17.4167 17.4167 16.7078 17.4167 15.8333V8.70833C17.4167 7.83388 16.7078 7.125 15.8333 7.125Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3.95825 11.8747H3.16659C2.74666 11.8747 2.34393 11.7079 2.047 11.4109C1.75007 11.114 1.58325 10.7113 1.58325 10.2913V3.16634C1.58325 2.74642 1.75007 2.34369 2.047 2.04676C2.34393 1.74982 2.74666 1.58301 3.16659 1.58301H10.2916C10.7115 1.58301 11.1142 1.74982 11.4112 2.04676C11.7081 2.34369 11.8749 2.74642 11.8749 3.16634V3.95801"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        {isRemovable && (
          <button
            className="clip_button clip_button-delete"
            onClick={() => onDeleteContent(content.contentId)}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.375 4.75H3.95833H16.625"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.0416 4.74967V15.833C15.0416 16.2529 14.8748 16.6557 14.5778 16.9526C14.2809 17.2495 13.8782 17.4163 13.4583 17.4163H5.54159C5.12166 17.4163 4.71893 17.2495 4.422 16.9526C4.12507 16.6557 3.95825 16.2529 3.95825 15.833V4.74967M6.33325 4.74967V3.16634C6.33325 2.74641 6.50007 2.34369 6.797 2.04676C7.09393 1.74982 7.49666 1.58301 7.91659 1.58301H11.0833C11.5032 1.58301 11.9059 1.74982 12.2028 2.04676C12.4998 2.34369 12.6666 2.74641 12.6666 3.16634V4.74967"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.91675 8.70801V13.458"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.0833 8.70801V13.458"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};
