import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { LearningLineDetails } from './learning-line-details';
import { withTranslation } from 'react-i18next';

const GrowthAmbitionCreateSelectLearningIndicators = withTranslation(
  'module.development.growthAmbition.create.selectLearningIndicators'
)(props => {
  const { t, learningLine, developmentPlan, draftGrowthAmbition } = props;

  return (
    <>
      <h2 className="learning-line-title">{learningLine.name}</h2>
      <LearningLineDetails
        learningLine={learningLine}
        developmentPlan={developmentPlan}
        draftGrowthAmbition={draftGrowthAmbition}
        t={t}
      />
    </>
  );
});

export { GrowthAmbitionCreateSelectLearningIndicators };
