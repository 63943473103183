import React, { useState, useEffect } from 'react';
import './index.scss';
import defaultImage from './popup-default.png';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { get, Notification, patch } from '../index';

import { withRouter } from 'react-router-dom';
import doNotDisturbPaths from '../../DoNotDisturbPaths';
import Survey from 'lib/Survey';
import HTMLReactParser, { domToReact } from 'html-react-parser';

import { NavLink } from 'react-router-dom';

let instancesCount = 0;

const Popup = ({
  onCloseCallback,
  onClickCallback,
  onOpenCallback,
  data,
  children,
  useTemplate,
  noPadding = false,
  className,
}) => {
  const [isShown, setShown] = useState<boolean>(true);

  useEffect(() => {
    if (typeof onOpenCallback !== 'undefined') {
      onOpenCallback();
    }
  }, []);

  const closePopup = () => {
    setShown(false);
    instancesCount -= 1;
    if (instancesCount === 0) {
      document.documentElement.classList.remove('popup-open');
    }
    if (typeof onCloseCallback !== 'undefined') {
      onCloseCallback();
    }
  };

  useEffect(() => {
    instancesCount += 1;
    if (instancesCount === 1) {
      document.documentElement.classList.add('popup-open');
    }
  }, []);

  const popupStyle = data && data.style ? data.style : 'light';

  return (
    <div
      className={`popup-container ${
        isShown ? 'is-shown' : 'is-hidden'
      } ${popupStyle} ${className}`}
    >
      <div className={`popup-overlay`} />
      <div className={`popup-wrapper`}>
        <button className="close" onClick={closePopup}>
          <i className={`i-cross ${popupStyle == 'dark' ? 'i-light' : ''}`} />
        </button>
        <div className={`pop-inner ${noPadding ? 'no-padding' : ''}`}>
          {useTemplate === true && data ? (
            <>
              <PopupTemplate
                image={data.image}
                body={data.body}
                onClickCallback={onClickCallback}
              />
            </>
          ) : typeof children === 'function' ? (
            children({ closePopup: () => closePopup() })
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

const PopupTemplate = ({ image, body, onClickCallback }) => {
  const children = HTMLReactParser(body, {
    replace: ({ name, children, attribs }) => {
      if (name === 'a') {
        if (!attribs.target) {
          return (
            <NavLink
              onClick={() =>
                onClickCallback && onClickCallback(attribs.id || attribs.href)
              }
              to={attribs.href}
              {...attribs}
            >
              {domToReact(children)}
            </NavLink>
          );
        } else {
          return (
            <a
              onClick={() =>
                onClickCallback && onClickCallback(attribs.id || attribs.href)
              }
              {...attribs}
            >
              {domToReact(children)}
            </a>
          );
        }
      }
    },
  });

  return (
    <>
      <div
        className={'popup-visual'}
        style={{
          backgroundImage: `url(${image ? image : defaultImage})`,
        }}
      />
      <div className={'spacer'} />
      <div className="popup-content">
        <div>{children}</div>
        <div className="preload">
          <img src={image} />
        </div>
      </div>
    </>
  );
};

const PopupNotificationContainerWithRouter = (props) => {
  const queryKey = 'popupNotifications';
  const queryClient = useQueryClient();

  const { status, data } = useQuery(queryKey, () => get({ type: 'popup' }));

  const closeMutation = useMutation<any, any, Notification>(
    ({ notificationId }) => patch(notificationId, { closed: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  const onClick = async (notification, linkId) => {
    await patch(notification.notificationId, {
      clicked: { linkId },
    });
    await queryClient.invalidateQueries(queryKey);
  };

  const sendSurvey = async (notification, surveyData) => {
    patch(notification.notificationId, {
      submitted: { title: notification.config.survey.title, surveyData },
    });
  };
  // Check if path is allowed to disturb user.
  if (doNotDisturbPaths(props.location.pathname)) {
    return null;
  }

  if (status !== 'success') {
    return null;
  }

  let notifications = data as Notification[];

  if (props.session) {
    const currentTenantId = props.session.currentTenantId();
    notifications = notifications.filter((notification: Notification) => {
      if (!!notification.config.showAtTenantId) {
        return notification.config.showAtTenantId === currentTenantId;
      }
      return true;
    });
  }

  // no notification returned
  if (!notifications.length) {
    return null;
  }

  const popups = notifications
    .sort((left, right) => right.config.priority - left.config.priority)
    .map((notification) => {
      if (
        props.location.pathname &&
        notification.config.paths &&
        !notification.config.paths.includes(props.location.pathname)
      ) {
        return false;
      }

      if (notification.config.survey) {
        return (
          <Popup
            key={notification.notificationId}
            useTemplate
            noPadding
            onCloseCallback={() => {
              closeMutation.mutate(notification);
            }}
          >
            <Survey
              onSubmit={(surveyResult) => {
                sendSurvey(notification, surveyResult);
              }}
              onCloseCallback={() => {
                closeMutation.mutate(notification);
              }}
              data={notification.config.survey}
            />
          </Popup>
        );
      }

      const popupData = {
        // Convert notification data to readable props.
        image: notification.config.imageUrl,
        body: notification.messageHtml,
        style: notification.config.style,
      };
      return (
        <Popup
          key={notification.notificationId}
          useTemplate
          noPadding={false}
          data={popupData}
          onCloseCallback={() => {
            closeMutation.mutate(notification);
          }}
          onClickCallback={(linkId) => onClick(notification, linkId)}
        />
      );
    });

  return popups;
};

const PopupNotificationContainer = withRouter(
  // Use router in notificationspaths
  PopupNotificationContainerWithRouter
);

export default Popup;

export { Popup, PopupNotificationContainer };
