import React, { Component, Fragment } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';

import {
  Button,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { Modal } from 'lib/Modal';

import logger from 'utils/Logger';

import Loading from 'lib/Loading';
import { CustomTagManager } from 'domain/CustomTag';
import { CreateCustomTag } from 'lib/CustomTag/CreateCustomTag';

import noCustomTagsImage from 'img/no_customtags.svg';
import { withTranslation } from 'react-i18next';
import { Page } from 'lib/Page';
import { Error, NoResults } from 'lib/PlaceHolder';
import sweetAlert from 'lib/sweetAlert';

const TeamTagManagement = withTranslation('module.teamTags')(
  observer(
    class TeamTagManagement extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');

        this.createCustomTagsDialogIsOpen = observable(false);
      }

      openCreateCustomTagsDialog = action(() => {
        this.createCustomTagsDialogIsOpen.set(true);
      });

      closeCreateCustomTagsDialog = action(() => {
        this.createCustomTagsDialogIsOpen.set(false);
      });

      componentWillMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield CustomTagManager.fetchIfEmpty();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Team Tags',
          });
          this.dataState.set('error');
        }
      });

      _deleteCustomTag(customTag) {
        customTag.destroy();
        this.loadData();
      }

      deleteCustomTag(customTag) {
        sweetAlert({
          title: this.props.t('deleteTeamTagConfirmTitle'),
          text: this.props.t('deleteTeamTagConfirmTitle', { customTag }),
          dangerMode: true,
          buttons: [
            this.props.t('common:cancel'),
            this.props.t('deleteTeamTag'),
          ],
        }).then((willDelete) => {
          if (willDelete) {
            this._deleteCustomTag(customTag);
          }
        });
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const customTags = CustomTagManager.getCustomTags();

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { title: this.props.t('title') },
          ],
          primaryAction: {
            title: this.props.t('addTeamTag'),
            onClick: () => this.openCreateCustomTagsDialog(),
          },
        };

        return (
          <Page {...pageProps}>
            {customTags.length === 0 && (
              <NoResults
                imgSrc={noCustomTagsImage}
                description={this.props.t('noTeamTagsPlaceholder')}
              />
            )}

            <div className="team-tags">
              {customTags.map((customTag) => {
                return (
                  <TeamTagButton
                    description={customTag.description}
                    key={customTag.id}
                    key_={customTag.key}
                    actions={[
                      {
                        onClick: () => this.deleteCustomTag(customTag),
                        label: this.props.t('common:delete'),
                      },
                    ]}
                  />
                );
              })}
            </div>
            {/* /.page-body */}
            {this.createCustomTagsDialogIsOpen.get() && (
              <Modal onCloseClick={() => this.closeCreateCustomTagsDialog()}>
                <CreateCustomTag
                  onCustomTagAdded={(customTagId) =>
                    this.closeCreateCustomTagsDialog()
                  }
                  onDone={() => {
                    this.closeCreateCustomTagsDialog();
                  }}
                  onCancel={() => {
                    this.closeCreateCustomTagsDialog();
                  }}
                />
              </Modal>
            )}
          </Page>
        );
      }
    }
  )
);

class TeamTagButton extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  render() {
    return (
      <div className="team-tag">
        <ButtonGroup className="btn-block">
          <Button block={true} color="black">
            <span>{this.props.description}</span>
            <span className="button-hotkey">{this.props.key_}</span>
          </Button>
          {this.props.actions.length > 0 && (
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
            >
              <DropdownToggle color="default" caret>
                <i className="i-dots" />
              </DropdownToggle>
              <DropdownMenu right>
                {this.props.actions.map((action, n) => (
                  <DropdownItem onClick={action.onClick} key={n}>
                    {action.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          )}
        </ButtonGroup>
      </div>
    );
  }
}

export { TeamTagManagement };
