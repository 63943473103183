import React, { Component } from 'react';
import { observer, Observer } from 'mobx-react';
import { reaction, observable, action } from 'mobx';

import VideoCollection from 'domain/Video';
import {
  ImportState,
  XMLReader,
  BulkObservationLogger,
} from '../../../infra/Importer';
import { Modal } from 'lib/Modal';
import { MatchOverviewItem } from './MatchOverviewItem';
import { groupObservationsPerDescription } from 'utils/observation';
import { ObservationFilter } from 'lib/Observation/ObservationFilter';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';
import { asyncAction } from 'mobx-utils';
import logger from 'utils/Logger';
import { ObservationLogVideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { withTranslation } from 'react-i18next';
import { EventPublisher } from 'utils/EventPublisher';

const FileSelect = ({ xmlReader, label }) => {
  return (
    <div>
      <input
        onChange={(e) =>
          e.target.files[0] && xmlReader.setFile(e.target.files[0])
        }
        type="file"
        id="fileselect"
        accept="text/xml"
        style={{ display: 'none' }}
      />
      <label htmlFor="fileselect" className="btn btn-primary">
        {label}
      </label>
    </div>
  );
};
//
//
// const getObservationCodeMap = (sportingEvent, observations) => {
//   const seen = [];
//   const observations = observations.filter((observation) => {
//     const found = seen.indexOf(observation.code) !== -1;
//     if (!found) {
//       seen.push(observation.code);
//     }
//     return !found;
//   });
//
// };
//
// const enrichObservations = (sportingEvent, observations) => {
//
//
// };

const State = observer(({ importState }) => {
  const seen = [];
  const observations = importState.observations.filter((observation) => {
    const found = seen.indexOf(observation.code) !== -1;
    if (!found) {
      seen.push(observation.code);
    }
    return !found;
  });
  return (
    <div>
      {observations.map((observation, k) => {
        return <div key={k}>{observation.code}</div>;
      })}
    </div>
  );
});

const ImportObservations = withTranslation('module.match.importXML')(
  observer(
    class ImportObservations extends Component {
      constructor() {
        super();

        this.importState = new ImportState();
        this.xmlReader = new XMLReader(this.importState);

        this.observationGroups = observable([]);
        this.observationFilter = observable(null);
        this.dataState = observable('loading');

        reaction(
          () => this.importState.observations.slice(),
          (observations) => {
            const observationGroups =
              groupObservationsPerDescription(observations);
            this.observationGroups.replace(observationGroups);
            if (observationGroups.length > 0) {
              this.observationFilter.set(observationGroups[0].description);
            } else {
              this.observationFilter.set(null);
            }
          }
        );
      }

      componentDidMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        try {
          const sportingEvent = this.props.sportingEvent;

          const videoId = sportingEvent.mainVideoId();

          this.video = yield VideoCollection.getOrFetch(videoId);
          this.multiVideo = sportingEvent.multiVideo();

          const clockId = sportingEvent.clocks()[videoId].clockId;

          this.observationContext = new ObservationContext(
            sportingEvent,
            null,
            clockId,
            false
          );
          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to Import Observations',
          });
        }
      });

      setObservationFilter = action((description) => {
        this.observationFilter.set(description);
      });

      async importObservations() {
        const logger = new BulkObservationLogger(this.importState);
        await logger.logToSportingEvent(this.props.sportingEvent);
        this.props.onReady();

        EventPublisher.dispatch('XML_IMPORTED');
      }

      render() {
        console.log('Rerender');

        const selectedObservationGroup = this.observationGroups.filter(
          (observationGroup) => {
            return (
              observationGroup.description === this.observationFilter.get()
            );
          }
        );

        const selectedObservations =
          selectedObservationGroup.length > 0
            ? selectedObservationGroup[0].observations
            : [];

        return (
          <div>
            {/* TODO @Koen */}
            <Modal
              size="lg"
              onCloseClick={() => {
                this.props.onReady();
              }}
            >
              <div className="row">
                <div className="col-12">
                  <b>{this.props.t('title')}</b>
                </div>
                {this.importState.observations.length === 0 && (
                  <>
                    <div className="col-12">
                      <MatchOverviewItem
                        sportingEvent={this.props.sportingEvent}
                      />
                    </div>
                    <div className="col-6">
                      <label htmlFor="xml-file-select">
                        1. {this.props.t('selectFileMessage')}
                      </label>
                    </div>
                    <div className="col-6">
                      <FileSelect
                        xmlReader={this.xmlReader}
                        label={this.props.t('selectFile')}
                      />
                    </div>
                  </>
                )}
                {this.importState.observations.length > 0 && (
                  <>
                    <div className="col-12">
                      <label htmlFor="xml-file-select">
                        2. {this.props.t('verifyTagsMessage')}
                      </label>
                    </div>
                    <div className="col-12 col-lg-7">
                      {/*<Observer>{this.observationContext.clockTimeFn}</Observer>*/}
                      {this.dataState.get() === 'loaded' &&
                        selectedObservations.length > 0 && (
                          <div
                            style={{
                              aspectRatio: '16/9',
                              width: '100%',
                              display: 'flex',
                            }}
                          >
                            <ObservationLogVideoPlayer
                              clock={this.observationContext.clock}
                              observations={selectedObservations}
                              video={this.video}
                              multiCam={this.multiVideo}
                            />
                          </div>
                        )}
                    </div>
                    <div className="col-12 col-lg-5 flex-grow-0">
                      <div className={'import-observations-list'}>
                        <ObservationFilter
                          observationGroups={this.observationGroups}
                          selectedDescription={this.observationFilter.get()}
                          onSelect={(description) =>
                            this.setObservationFilter(description)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="modal-footer form-actions col-12">
                  <button
                    disabled={this.importState.observations.length === 0}
                    className="btn btn-primary"
                    onClick={() => this.importObservations()}
                  >
                    {this.props.t('import')}
                  </button>
                  <button className="btn btn-link" onClick={this.props.onReady}>
                    {this.props.t('common:cancel')}
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        );
      }
    }
  )
);

export { ImportObservations };
