import React from 'react';

import { observer } from 'mobx-react';

const BasePagination = ({ items, activeItem, onClick }) => {
  return (
    <div className="pagination">
      {items.map((item, n) => {
        return (
          <div
            key={n}
            className={`clickable pagination-item ${
              n === activeItem ? 'active' : ''
            } ${item.className ? item.className : ''}`}
            onClick={() => onClick(n)}
          >
            {n + 1}
          </div>
        );
      })}
    </div>
  );
};

const Pagination = observer(({ items, activeItem, onClick }) => (
  <BasePagination
    items={items}
    activeItem={activeItem.get()}
    onClick={onClick}
  />
));

export { Pagination, BasePagination };
