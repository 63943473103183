import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';

import PersonCollection from 'domain/Person';
import VideoCollection from 'domain/Video';

import { LearningLineDetails } from '../components/learning-line-details';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import { withTranslation } from 'react-i18next';
import { Error } from 'lib/PlaceHolder';

const PDPUpdatePage = withTranslation('module.development')(
  observer(
    class PDPUpdatePage extends Component {
      constructor(props) {
        super(props);
        // this.learningLine = observable({ learningLine: undefined });
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          yield VideoCollection.fetchIfEmpty();

          this.personId = this.props.match.params.personId;

          this.person = yield PersonCollection.getOrFetch(this.personId);

          this.developmentPlan =
            yield DevelopmentPlanQueryService.developmentPlanByPerson(
              this.personId
            );

          if (!this.developmentPlan.isNew) {
            yield this.developmentPlan.videoFragments.fetchIfEmpty();
            yield this.developmentPlan.conversations.fetchIfEmpty();
          }

          this.learningLine = yield LearningLineQueryService.learningLineById(
            this.props.match.params.learningLineId
          );

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load personal plan',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              path: '/development/pdp',
              title: this.props.t('playerDevelopment'),
            },
            {
              path: `/development/pdp/${this.personId}/learning-line-overview`,
              title: this.person.fullName,
            },
            { title: this.learningLine.name },
          ],
        };

        return (
          <Page {...pageProps}>
            <div>Selecteer hieronder welke ontwikkelpunten je al beheerst.</div>

            <LearningLineDetails
              learningLine={this.learningLine}
              developmentPlan={this.developmentPlan}
            />
          </Page>
        );
      }
    }
  )
);

export { PDPUpdatePage };
