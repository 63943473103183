import React, { useEffect, useState } from 'react';
import { VideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import VideoCollection from 'domain/Video';
import { Session } from 'domain/Session';

import { Modal } from 'lib/Modal';
import { ScoreBoard } from 'lib/Observation/ScoreBoard/index';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';
// import { BasePagination as Pagination } from 'lib/Pagination';

const Pagination = ({ playlistItems, activeItem, onClick }) => {
  return playlistItems.map((item, n) => {
    return (
      <div
        key={n}
        className={`observation-circle float-left text-center clickable ${
          n === activeItem ? 'active' : ''
        } ${item.labels}`}
        onClick={() => onClick(n)}
      >
        {n + 1}
      </div>
    );
  });
};
const PaginatedVideoPlayer = ({ clock, videoFragmentsToShow, video }) => {
  const [activeItem, setActiveItem] = useState(0);

  return (
    <>
      <VideoPlayer
        clock={clock}
        videoFragments={videoFragmentsToShow}
        video={video}
        activePlayListItem={activeItem}
        onPlaylistItem={item => setActiveItem(item)}
      />
      <div className="row">
        <div className="col-12">
          <Pagination
            playlistItems={videoFragmentsToShow}
            activeItem={activeItem}
            onClick={n => {
              setActiveItem(n);
            }}
          />
        </div>
      </div>
    </>
  );
};

export const DashboardV2 = ({
  layoutComponent: LayoutComponent,
  reportFetcher,
  sportingEvent,
  reportState,
}) => {
  const [report, setReport] = useState(reportFetcher.getReport());
  const [videoFragmentsToShow, setVideoFragmentsToShow] = useState(null);
  const [observationContext, setObservationContext] = useState(null);

  const [video, setVideo] = useState(null);

  useEffect(() => {
    // used for live dashboards
    return reportFetcher.onReportFetched(report => {
      setReport(report);
    });
  }, [reportFetcher]);

  useEffect(() => {
    setVideo(null);
    (async () => {
      const videoId = sportingEvent.mainVideoId();
      if (!!videoId) {
        const video = await VideoCollection.getOrFetch(videoId);
        const observationContext = new ObservationContext(
          sportingEvent,
          null,
          sportingEvent.clocks()[video.id].clockId,
          false
        );
        await observationContext.init();

        setObservationContext(observationContext);
        setVideo(video);
      }
    })();
  }, [sportingEvent]);

  const sportType = Session.current().sportType();

  const clickHandler = cell => {
    if (!video) {
      alert('No video');
      return;
    }
    const videoFragments = cell.videoFragments.map(videoFragment => {
      return video.getVideoFragment(
        videoFragment.startTime,
        videoFragment.endTime,
        videoFragment.description,
        !!videoFragment.label ? 'success' : ''
      );
    });
    setVideoFragmentsToShow(videoFragments);
  };

  return (
    <>
      <LayoutComponent
        onClick={clickHandler}
        report={report}
        reportState={reportState}
      />
      {videoFragmentsToShow !== null && video !== null && (
        <Modal size="lg" onCloseClick={() => setVideoFragmentsToShow(null)}>
          <div className="video-player-container in-modal">
            <ScoreBoard
              observationContext={observationContext}
              sportType={sportType}
            />
            <PaginatedVideoPlayer
              clock={observationContext.clock}
              videoFragmentsToShow={videoFragmentsToShow}
              video={video}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
