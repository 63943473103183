import { Tour } from '../Tour';

import { matchRuleShort } from 'utils/matchRuleShort';

export default new Tour({
  key: 'dashboardKorfball',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return (
      matchRuleShort(path, '/reporting/detail/*/default') &&
      currentSession.sportType() === 'korfball'
    );
  },
  steps: () => [
    {
      target: '.attack',
      i18nKey: 'module.tours.dashboardKorfball:step1Attack',
    },
    {
      target: '.shot',
      i18nKey: 'module.tours.dashboardKorfball:step2Shot',
    },
    {
      target: '.rebound',
      i18nKey: 'module.tours.dashboardKorfball:step3Rebound',
    },
    {
      target: '.ball_loss',
      i18nKey: 'module.tours.dashboardKorfball:step4BallLoss',
    },
    {
      target: '.last10attacks',
      i18nKey: 'module.tours.dashboardKorfball:step5Last10Attacks',
    },
    {
      target: '.shotzone',
      i18nKey: 'module.tours.dashboardKorfball:step6ShotZone',
    },
    {
      target: '.playerstats',
      offset: -10,
      i18nKey: 'module.tours.dashboardKorfball:step7PlayerStats',
    },
    {
      target: '.dashboard-time-frame .rc-slider',
      i18nKey: 'module.tours.dashboardKorfball:step8TimeSelection',
    },
  ],
  callbacks: {
    start: () => {
      // console.log("tour started");
    },
    nextStep: index => {
      // console.log(`tour nextStep ${index}`);
    },
    end: () => {
      // console.log("tour ended");
    },
  },
});
