import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import styles from './court.module.scss';

type AreaSelectHandler = (areaId: string) => void;

const Area = ({
  selected,
  onAreaSelect,
  ...props
}: React.SVGProps<SVGRectElement> & {
  selected: boolean;
  onAreaSelect: AreaSelectHandler;
}) => {
  const handleClick = (event: MouseEvent<SVGRectElement>) => {
    onAreaSelect(
      (event.target as SVGRectElement).id || 'TennisCourt Area id not set'
    );
  };

  return (
    <rect
      {...props}
      onClick={handleClick}
      className={classNames(styles.area, { [styles.areaSelected]: selected })}
    />
  );
};

export const Court = ({
  width = 190,
  height = 340,
  strokeWidth = 2,
  value,
  onAreaSelect,
}: {
  width?: number;
  height?: number;
  strokeWidth?: number;
  value?: string;
  onAreaSelect: AreaSelectHandler;
}) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 190 340">
      <path fill="#2CB915" d="M0 0H190V340H0z"></path>
      <path
        fill="#5E9EFF"
        stroke="#fff"
        strokeWidth={strokeWidth}
        d="M173 314.001H327V458.001H173z"
        transform="rotate(-180 173 314.001)"
      ></path>
      <path
        fill="#5E9EFF"
        stroke="#fff"
        strokeWidth={strokeWidth}
        d="M150 314.001H258V458.001H150z"
        transform="rotate(-180 150 314.001)"
      ></path>
      <path
        stroke="#fff"
        d="M149.5 245.501H256.5V246.502H149.5z"
        transform="rotate(-180 149.5 245.501)"
      ></path>
      <rect
        width="77"
        height="1"
        x="95.5"
        y="245.501"
        stroke="#fff"
        rx="0.5"
        transform="rotate(-90 95.5 245.501)"
      ></rect>
      <path
        fill="#5E9EFF"
        stroke="#fff"
        strokeWidth={strokeWidth}
        d="M19 24H173V168H19z"
      ></path>
      <path
        fill="#5E9EFF"
        stroke="#fff"
        strokeWidth={strokeWidth}
        d="M42 24H150V168H42z"
      ></path>
      <path stroke="#fff" d="M42.5 92.5H149.5V93.501H42.5z"></path>
      <rect
        width="77"
        height="1"
        x="96.5"
        y="92.5"
        stroke="#fff"
        rx="0.5"
        transform="rotate(90 96.5 92.5)"
      ></rect>
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-1'}
        id="tennis-court-area-1"
        x="18"
        y="23"
        width="24"
        height="69"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-2'}
        id="tennis-court-area-2"
        x="150"
        y="23"
        width="24"
        height="69"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-3'}
        id="tennis-court-area-3"
        x="18"
        y="92"
        width="24"
        height="77"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-4'}
        id="tennis-court-area-4"
        x="150"
        y="92"
        width="24"
        height="77"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-5'}
        id="tennis-court-area-5"
        x="42"
        y="23"
        width="54"
        height="35"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-6'}
        id="tennis-court-area-6"
        x="96"
        y="23"
        width="54"
        height="35"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-7'}
        id="tennis-court-area-7"
        x="42"
        y="58"
        width="54"
        height="35"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-8'}
        id="tennis-court-area-8"
        x="96"
        y="58"
        width="54"
        height="35"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-9'}
        id="tennis-court-area-9"
        x="42"
        y="93"
        width="54"
        height="38"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-10'}
        id="tennis-court-area-10"
        x="96"
        y="93"
        width="54"
        height="38"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-11'}
        id="tennis-court-area-11"
        x="42"
        y="131"
        width="54"
        height="38"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-12'}
        id="tennis-court-area-12"
        x="96"
        y="131"
        width="54"
        height="38"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-13'}
        id="tennis-court-area-13"
        x="42"
        y="169"
        width="54"
        height="38"
      />
      <Area
        onAreaSelect={onAreaSelect}
        selected={value === 'tennis-court-area-14'}
        id="tennis-court-area-14"
        x="96"
        y="169"
        width="54"
        height="38"
      />
    </svg>
  );
};
