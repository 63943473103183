import { Report } from './Report';
import { tennisReportDefinition } from './ReportDefinition';
import { ReportFetcher } from '../../ReportFetcher';

export function tennisBuilder(sportingEvent: any) {
  return new ReportFetcher<Report>(
    tennisReportDefinition,
    sportingEvent,
    Report
  );
}
