import React, { useEffect, useState } from 'react';

import IframeResizer from 'iframe-resizer-react';
import Loading from 'lib/Loading';
import { Notification } from 'lib/Notification';
import { appCollection } from 'domain/App';
import { Page } from 'lib/Page';
import { EventPublisher } from 'utils/EventPublisher';
import { Session } from 'domain/Session';
import { AuthAwareAdapterProxy } from 'utils/AuthAwareAdapterProxy';
import { ModalVideoPlayer } from '../../embedding/player';
import SportingEventCollection from '../../../domain/SportingEvent';
import VideoCollection from '../../../domain/Video';
import i18n from 'lib/i18n';
import logger from '../../../utils/Logger';

function getSearchParams() {
  const hashParams = {};
  let e,
    a = /\+/g, // Regex for replacing addition symbol with a space
    r = /([^&;=]+)=?([^&;]*)/g,
    d = function (s) {
      return decodeURIComponent(s.replace(a, ' '));
    },
    q = window.location.search.substring(1);

  while ((e = r.exec(q))) hashParams[d(e[1])] = d(e[2]);

  return hashParams;
}

function isValidPath(path) {
  return path.match(/^(([a-zA-Z0-9\\?\\.]+\/?)+)$/);
}

const getPlayer = async (clips, onClose) => {
  const videoFragments = [];
  for (const clip of clips) {
    let video;
    if (clip.videoId) {
      video = await VideoCollection.getOrFetch(clip.videoId);
    } else {
      const sportingEvent = await SportingEventCollection.getOrFetch(
        clip.sportingEventId
      );
      video = await VideoCollection.getOrFetch(sportingEvent.mainVideoId());
    }
    videoFragments.push({
      ...clip,
      getVideo() {
        return video;
      },
    });
  }

  return (
    <ModalVideoPlayer
      videoFragments={videoFragments}
      onClose={onClose}
      fullscreen={false}
    />
  );
};

const preloadResources = () => {
  // PRELOAD SportingEvents and Videos
  // TODO: not all apps need this tho
  SportingEventCollection.fetchIfEmpty();
  VideoCollection.fetchIfEmpty();
};

const Launch = ({
  match: {
    params: { appId, path = '' },
  },
}) => {
  const [launchData, setLaunchData] = useState(null);
  const [videoPlayer, setVideoPlayer] = useState(null);

  if (!isValidPath(path)) {
    path = '';
  }

  const currentSession = Session.current();

  useEffect(() => {
    const receiveMessage = (event) => {
      const iframe = document.getElementById('app-launch-iframe');

      if (!iframe || event.origin !== new URL(iframe.src).origin) {
        return;
      }
      const data = event.data;
      if (typeof data !== 'string') {
        return;
      }
      if (data.substring(0, 9) === '[teamtv]-') {
        try {
          const { command, args } = JSON.parse(data.substring(9));
          switch (command) {
            case 'showNotification':
              Notification.show(...args);
              break;
            case 'openApp':
              const appId_ = args[0];
              const appManager = currentSession.getAppManager();
              const app = appManager.getAppById(appId_);
              if (app) {
                appManager.switchToApp(app);
              }
              break;
            case 'showClips':
              (async () => {
                setVideoPlayer(
                  await getPlayer(args[0], () => setVideoPlayer(null))
                );
              })();
          }
        } catch (e) {}
      }
    };
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
    // FIXME: exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launchData]);

  useEffect(() => {
    const interval = setInterval(() => {
      const iframe = document.getElementById('app-launch-iframe');
      if (!iframe) return;
      const args = {
        command: 'setViewPort',
        args: {
          // Q: @koenvo why add 100px?
          height: `${window.innerHeight - 100}px`,
          width: `${window.innerWidth}px`,
        },
      };
      iframe.contentWindow.postMessage(`[teamtv]-${JSON.stringify(args)}`, '*');
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    (async () => {
      const app = appCollection.build({ appId });
      setLaunchData(null);
      try {
        const adminResourceGroupId =
          currentSession.getClubAdminResourceGroupId();
        let _launchData;
        if (!!adminResourceGroupId && appId === 'store') {
          _launchData = await AuthAwareAdapterProxy.withUseResourceGroup(
            adminResourceGroupId,
            () => app.launch()
          );
        } else {
          _launchData = await app.launch();
          preloadResources();
        }
        setLaunchData({ nonce: new Date() / 1, ..._launchData });
        EventPublisher.dispatch('APP_LAUNCHED', _launchData.appId);
      } catch (e) {
        logger.error(e, { transactionName: `Unable to launch ${appId}` });
        console.log(e);
        setLaunchData(false);
      }
    })();
    // FIXME: exhaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  if (launchData === false) {
    return <div>You don't have permission to access this app.</div>;
  }

  const pageProps = {
    // breadcrumbs: [
    //   { path: '#', onClick: () => alert('hoi'), title: 'Menu item 1' },
    //   { onClick: () => alert('hoi2'), title: 'Menu item 2' },
    // ],
    hideInset: true,
    hideFooter: true,
  };

  let launchUrl = '';
  let queryHash = '';
  if (launchData !== null) {
    const searchParams = getSearchParams();
    if (searchParams.dev) {
      launchUrl = searchParams.dev;
    } else {
      launchUrl = launchData.appOrigin;
    }
    if (launchData.appAuthMethod === 'HASH') {
      queryHash = `#token=${launchData.token}`;
    } else {
      queryHash = `&token=${launchData.token}`;
    }
  }

  path += `?r=${launchData?.nonce}`;
  try {
    const clientId = window.ga.getAll()[0].get('clientId');
    path += `&clientId=${clientId}`;
  } catch (e) {}

  if (appId === 'store') {
    path += '&lang=' + i18n.language;

    const brandCode = window.isSkillReflect ? 'skillreflect' : 'teamtv';
    path += '&brandCode=' + brandCode;

    if (currentSession.sportType()) {
      path += '&sportType=' + currentSession.sportType();
    }
  }

  return (
    <Page className="app-page" fullWidth {...pageProps}>
      {videoPlayer}
      {launchData === null && <Loading type={'fullscreen'} />}
      {launchData !== null && (
        <IframeResizer
          className="app-iframe"
          style={{ border: '0', minHeight: '100%' }}
          id="app-launch-iframe"
          allow="fullscreen *"
          allowusermedia="true"
          allowfullcreen="true"
          border="0"
          checkOrigin={false}
          src={`${launchUrl}/${path}${queryHash}`}
        >
          <Loading type={'fullscreen'} />;
        </IframeResizer>
      )}
    </Page>
  );
};

export { Launch };
