import { Page } from '../../lib/Page';
import React from 'react';

import Lottie from 'react-lottie';
import completeLottie from 'lib/animations/lottie/completed.json';
import completeLottieSR from 'lib/animations/lottie/completed_sr.json';
import srIcon from '../../img/sr_icon.svg';
import ttvIcon from '../../img/ttv_icon.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const VerifyEmailPage = () => {
  const { t } = useTranslation('registerPage');

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: window.isSkillReflect ? completeLottieSR : completeLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    eventListeners: [
      {
        eventName: 'loopComplete',
      },
    ],
  };

  return (
    <Page className={'page--userlogin'}>
      <div className="userlogin-layout">
        <div className="userlogin__formwrapper">
          <img
            className={'userlogin__icon'}
            src={window.isSkillReflect ? srIcon : ttvIcon}
          />
          <div className={'user-login-form-step'}>
            <Lottie
              style={{ height: 200, width: 200 }}
              options={defaultOptions}
            />
            <h1>{t('emailVerified')}</h1>
            <p> {t('emailVerifiedSub')}</p>
            <Link to={'/'} className={'btn btn-primary'}>
              {t('emailVerifiedContinue')}
            </Link>
          </div>
          <div className={'userlogin__bottom'}></div>
        </div>
      </div>
    </Page>
  );
};
