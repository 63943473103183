import { baseDurationAfter, baseDurationBefore } from 'lib/Config';

const additionalDuration = 0; // used for variable length observations

class VideoFragmentCollection {
  constructor(video) {
    this.videoFragments = {};
    this.categories = [];
    this.video = video;
  }

  addCategories(categories) {
    for (const category of categories) {
      if (
        this.categories.find(({ key }) => category.key === key) !== undefined
      ) {
        continue;
      }
      this.categories.push(category);
    }
  }

  partialAdd(categoryKey) {
    // pre-fill categoryKey
    return {
      add: (categoryKey_, ...args) => {
        return this.add(categoryKey, ...args);
      },
    };
  }

  add(categoryKey, label, { startTime, endTime, labels, description }) {
    if (typeof this.videoFragments[categoryKey] === 'undefined') {
      this.videoFragments[categoryKey] = {};
    }

    const categoryVideoFragments = this.videoFragments[categoryKey];
    if (typeof categoryVideoFragments[label] === 'undefined') {
      categoryVideoFragments[label] = [];
    }

    if (startTime === endTime) {
      // negative and videoDuration passing values will be corrected in baseclass
      startTime = startTime - baseDurationBefore;
      endTime = endTime + baseDurationAfter;
    } else {
      startTime -= additionalDuration;
      endTime += additionalDuration;
    }

    categoryVideoFragments[label].push(
      this.video.getVideoFragment(startTime, endTime, description, labels)
    );
  }

  getNonEmptyCategories() {
    return this.categories.filter(({ key }) => {
      return typeof this.videoFragments[key] !== 'undefined';
    });
  }

  getVideoFragments(categoryKey, label) {
    return this.videoFragments[categoryKey][label];
  }

  getLabels(categoryKey) {
    const labels = Object.keys(this.videoFragments[categoryKey]);
    labels.sort();
    return labels.map(label => ({
      label,
      videoFragmentCount: this.videoFragments[categoryKey][label].length,
    }));
  }
}

export { VideoFragmentCollection };
