import React from 'react';
import Default from './templates/default.json';
import Basic from './templates/ht-basic-teamtv.json';
import Pro from './templates/ht-pro.json';
import Empty from './templates/empty.json';
import Free from './templates/free.json';
import Ehtv from './templates/ht-ehtv.json';
import Southgate from './templates/ht-southgate.json';

const templates = Object.fromEntries(
  [Pro, Basic, Free, Default, Empty, Ehtv, Southgate].map((template) => [
    template.templateId,
    template,
  ])
);

export const getTemplate = (templateId) => {
  if (templates[templateId]) {
    return templates[templateId];
  }
};

export const getTemplates = () => {
  return templates;
};
