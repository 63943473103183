import { Session } from 'domain/Session';
import React, { useEffect, useState } from 'react';
import { Modal } from 'lib';
import Loading from 'lib/Loading';
import { InputCheckboxRadio } from 'lib/InputCheckBoxRadio';
import { useTranslation } from 'react-i18next';
import { SubmittingButton } from 'lib/SubmittingButton';

import uuidv4 from 'uuid/v4';
import ReactTooltip from 'react-tooltip';
import { getMembers } from '../../domain/ResourceGroup';

export const SetResourceACL = ({
  resourceType,
  resourceId,
  initialACL,
  onClose,
  individualUsers = false,
}) => {
  const currentSession = Session.current();
  const resourceGroup = currentSession.currentResourceGroup();

  const currentRoleName = currentSession.currentRoleName();

  const [allowedRoles, setAllowedRoles] = useState([]);
  const { t } = useTranslation('common.role');
  const [roleValues, setRoleValues] = useState([]);
  const [userValues, setUserValues] = useState([]);
  let options = allowedRoles.map((role) => {
    return { label: t(role.get('roleName')), value: role.id };
  });

  const getInitialValues = (type) => {
    if (!initialACL) {
      return null;
    } else {
      return initialACL.rules.map((rule) => {
        switch (type) {
          case 'roleName':
            return rule.identityPattern.attributes.roleName;
          case 'userId':
            return rule.identityPattern.attributes.userId;
        }
      });
    }
  };

  let initialValues = {
    role: getInitialValues('roleName'),
    users: getInitialValues('userId'),
  };
  let [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      let roles = resourceGroup.roles;
      await roles.fetchIfEmpty();
      setAllowedRoles(roles.toArray());
      setRoleValues(
        initialValues.role?.filter((i) => i).length > 0
          ? initialValues.role?.filter((i) => i)
          : roles.toArray().map((o) => o.id)
      );
      if (individualUsers) {
        setUsers(await _getMembers());
      }
      setUserValues(initialValues?.users.filter((i) => i) ?? []);
    })();
  }, []);

  if (allowedRoles.length < 1) {
    return (
      <Modal onCloseClick={onClose}>
        <Loading />
      </Modal>
    );
  }

  const onSubmit = async () => {
    let values_;
    // TODO: check values instead of length
    if (roleValues.length === allowedRoles.length) {
      // All roles are allowed
      values_ = ['*'];
    } else {
      values_ = roleValues;
    }
    await resourceGroup.setResourceACL(`${resourceType}:${resourceId}`, {
      allowedRoleNames: values_,
      allowedUserIds: userValues,
    });

    onClose();
  };
  const onRoleSelect = (value) => {
    let newValue = roleValues;
    if (newValue.includes(value)) {
      setRoleValues(newValue.filter((v) => v !== value));
    } else {
      setRoleValues([...newValue, value]);
      /* If al roles are selected, clear user selection*/
      if ([...newValue, value].length === allowedRoles.length) {
        setUserValues([]);
      }
    }
  };

  const onUserSelect = (value) => {
    let newValue = userValues;
    if (newValue.includes(value)) {
      setUserValues(newValue.filter((v) => v !== value));
    } else {
      setUserValues([...newValue, value]);
    }
  };

  let userOptions;
  if (users.length > 1) {
    userOptions = users.map((user) => {
      return {
        label: user.fullName ?? `${user.firstName} ${user.lastName}`,
        value: user.userId,
      };
    });
    userOptions.sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  return (
    <Modal size={'md'} onCloseClick={onClose}>
      <div className="modal-header">
        <h5 className="modal-title">{t('common:changePermissions')}</h5>
        <p>{t('common:changePermissionsDescription')}</p>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              {options.map((option) => (
                <InputCheckboxRadio
                  key={option.value}
                  checked={roleValues.includes(option.value)}
                  onChange={() => onRoleSelect(option.value)}
                  disabled={option.value === currentRoleName}
                  label={option.label}
                />
              ))}
            </div>
          </div>
          {users.length > 0 && roleValues.length < allowedRoles.length && (
            <div className="col-12">
              <div className="form-group">
                <label>
                  {t('common:setUserPermissions', {
                    type: t(`common.resourceTypes:${resourceType}`),
                  })}
                  :
                </label>
                <div className="row">
                  {userOptions.map((user) => {
                    return (
                      <div className="col-sm-6" key={user.value}>
                        <InputCheckboxRadio
                          key={user.value}
                          checked={userValues.includes(user.value)}
                          onChange={() => onUserSelect(user.value)}
                          disabled={user.value === currentRoleName}
                          label={user.label}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <div className="form-actions">
          <SubmittingButton onClick={onSubmit} className={'btn btn-primary'}>
            {t('common:save')}
          </SubmittingButton>
          <button className={'btn btn-link'} onClick={onClose}>
            {t('common:cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const _getMembers = async () => {
  const members = await getMembers();
  if (members?.length) {
    return members.filter((member) => {
      return !member?.invitationId;
    });
  } else {
    return [];
  }
};

export const AclLock = ({ acl, onClick }) => {
  const { t } = useTranslation('common.role');

  const [members, setMembers] = useState([]);
  useEffect(() => {
    (async () => {
      setMembers(await _getMembers());
    })();
  }, []);
  if (!acl) {
    return null;
  }
  const id = uuidv4();
  const roleNames = acl.rules.map(
    (rule) => rule.identityPattern.attributes.roleName
  );

  const userIds = acl.rules.map(
    (rule) => rule.identityPattern.attributes.userId
  );

  let roleNamesLocale = roleNames.filter((i) => i).map((role) => t(role));
  let userNames = members
    .filter((m) => userIds.includes(m.userId))
    .map((u) => `${u.firstName} ${u.lastName}`);

  if (!members.length) {
    return null;
  }

  return (
    <div className={'acl_lock'} onClick={onClick}>
      <i data-tip={''} data-for={id} className={'i-aclLock i-light i-sm'} />

      <ReactTooltip
        id={id}
        type="dark"
        effect="solid"
        place={'bottom'}
        clickable={true}
        className={'tooltip--styled lock-tooltip'}
        backgroundColor="#495057"
      >
        {t('common:availableFor')}
        {roleNamesLocale.length > 0 && (
          <div>
            <b>
              {t('common.filters:role')}: {roleNamesLocale.join(', ')}
            </b>
          </div>
        )}
        {userNames.length > 0 && (
          <div>
            <b>
              {t('common.filters:users')}: {userNames.join(', ')}
            </b>
          </div>
        )}
      </ReactTooltip>
    </div>
  );
};
