import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import registry from '../../../modules/registry';
import { useState } from 'react';

import './helpcontent.scss';
import { sendGA4Event } from '../../../utils/ga4';
import { Aside } from 'lib/Aside';
import { useQuery } from 'react-query';
import api from '../../../utils/ApiClient';
import Loading from '../../Loading';

const HelpArticlesComponent = ({ props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const fetchArticles = async () => {
    const response = await api.get('contenthub/helpContent', {
      params: {
        pageId: registryRouteResolver(props?.match?.path),
      },
    });
    return response?.data;
  };

  const { isLoading, data: articles } = useQuery(
    ['articles', registryRouteResolver(props?.match?.path)],
    fetchArticles
  );
  if (isLoading) {
    return <Loading type={'spinner'} size={12} color={'white'} />;
  }
  if (
    !isLoading &&
    (articles?.help_articles?.length > 0 || articles?.page_description)
  ) {
    return (
      <>
        <a
          href={'#help'}
          onClick={(e) => {
            e.preventDefault();
            sendGA4Event(
              'help_opened',
              'help',
              'clicked',
              window.isSkillReflect ? 'SR' : 'TTV',
              registryRouteResolver(props?.match?.path)
            );
            setIsOpen(true);
          }}
        >
          <span>{t('menu:help')}</span>
          <i className="i-knowledge-base i-light"></i>
        </a>
        {isOpen && (
          <HelpArticlesAside
            articles={articles}
            onCloseClick={() => setIsOpen(false)}
          />
        )}
      </>
    );
  } else {
    return <KnowledgeBaseLink />;
  }
};

const KnowledgeBaseLink = () => {
  const { t } = useTranslation();
  return (
    <a
      className={'hidden-xs'}
      target={'_blank'}
      href={t('module.knowledgeBase:url')}
    >
      <span>{t('module.knowledgeBase:openKnowledgeBase')}</span>
      <i className="i-knowledge-base i-light"></i>
    </a>
  );
};

export const Helparticles = withRouter(
  withTranslation('module.knowledgeBase')((props) => {
    return window.isSkillReflect ? (
      <KnowledgeBaseLink />
    ) : (
      <HelpArticlesComponent props={props} />
    );
  })
);

const registryRouteResolver = (path) => {
  const _path = path.split('/').slice(2).join('/');
  const module = registry.modules.find((item) => {
    return item.name === path.split('/')[1];
  });

  const routeItem = module.routingItems.find((ri) => {
    return ri.relativePath === _path;
  });
  const routeName = routeItem?.routeName;

  return `${module.name}.${routeName}`;
};

const HelpArticlesAside = ({ onCloseClick, articles }) => {
  const [limit, setLimit] = useState(5);
  const { t } = useTranslation('common');
  return (
    <Aside width={560} onClose={onCloseClick}>
      {articles?.page_description && (
        <div className={'pagedescription'}>
          <h3>{articles.page_description.title}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: articles.page_description.body }}
          ></div>
        </div>
      )}
      <div>
        <div className="helparticles">
          <h4>{t('module.knowledgeBase:helpArticles')}</h4>
          {articles?.help_articles?.map((article, idx) => {
            if (idx < limit) {
              return (
                <HelpArticleTeaser
                  key={`helparticle_${idx}`}
                  article={article}
                />
              );
            }
            if (idx === limit && idx + 1 < articles.help_articles.length) {
              return (
                <button
                  key={'showmore-button'}
                  onClick={() => setLimit(999)} // pretty
                  className={'btn btn-link'}
                >
                  <i className={'i-chevron-down i-dark i-sm'} />
                  {t('showMore')} ({articles.help_articles.length - 5})
                </button>
              );
            }
            if (idx > limit) {
              return;
            }
          })}
        </div>
      </div>
      <div className="helparticles-actions">
        <a href={t('module.knowledgeBase:url')} className={'btn btn-primary'}>
          {t('module.knowledgeBase:goToKnowledgeBase')}
        </a>
      </div>
    </Aside>
  );
};

const HelpArticleTeaser = ({ article }) => {
  return (
    <a
      onClick={() => {
        sendGA4Event(
          'article_opened',
          'help',
          'clicked',
          window.isSkillReflect ? 'SR' : 'TTV',
          article.path
        );
      }}
      target={'_blank'}
      href={article.path}
      className="helparticle--teaser"
    >
      <h3>
        {article.title}{' '}
        <span className={`helparticle__label label--${article.type}`}>
          {article.type_long}
        </span>
      </h3>
      <p>{article.body}</p>
    </a>
  );
};

/*
const debug = () => {
    // List all routes
    let AllItems = [];
    registry.modules.forEach((e) => {
        e.routingItems
            .filter((ri) => {
                return ri?.routeName;
            })
            .forEach((ri) => {
                if (ri?.routeName) {
                    AllItems.push(e.name + '.' + ri.routeName);
                }
            });
    });
    return AllItems;
};
*/
