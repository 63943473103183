import React, { Component } from 'react';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { CommandBus, Command } from '../../../infra/Messaging';

import { LineUpInput, KorfballLineUpLayout } from '../components';

//import '../css/MainPage.css';

import SportingEventCollection from '../../../domain/SportingEvent';
import TeamCollection from '../../../domain/Team';
import PersonCollection from '../../../domain/Person';
import { lineUpCollection } from '../../../domain/LineUp';

import { asyncAction } from 'mobx-utils';
import { gotoRoute } from '../../route';
import Loading from 'lib/Loading';
import logger from '../../../utils/Logger';
import { Page } from 'lib/Page/index';
import { withTranslation } from 'react-i18next';
import { Error } from 'lib/PlaceHolder';
// import GameActions from "../components/BottomGameActions";

const getEventHandlers = (lineUpId) => {
  const dispatchCommand = (commandName, args) => {
    const command = Command.create(commandName, args);
    CommandBus.dispatch(command);
  };

  const fail = () => {
    throw 'Not implemented';
  };

  return {
    onCreateAndAddToTeam: ({ firstName, lastName, gender, teamId, number }) =>
      dispatchCommand('PersonService.CreateAndAddToTeam', {
        firstName,
        lastName,
        gender,
        teamId,
        number,
      }),

    onRemovePlayer: ({ teamId, personId }) =>
      dispatchCommand('LineUp.RemovePlayer', { lineUpId, teamId, personId }),
    onAddPlayer: ({ teamId, personId, position }) =>
      dispatchCommand('LineUp.AddPlayer', {
        lineUpId,
        teamId,
        personId,
        position,
      }),

    onChangePlayerPosition: ({ teamId, personId, position }) =>
      dispatchCommand('LineUp.ChangePlayerPosition', {
        lineUpId,
        teamId,
        personId,
        position,
      }),

    onSwapPlayerWithPlayer: ({
      teamId,
      inPersonId,
      outPersonId,
      inPosition,
      outPosition,
    }) =>
      dispatchCommand('LineUp.SwapPlayerWithPlayer', {
        lineUpId,
        teamId,
        inPersonId,
        outPersonId,
        inPosition,
        outPosition,
      }),

    // Substitution
    onSwapUnAssignedWithPlayer: ({
      teamId,
      inPersonId,
      outPersonId,
      position,
    }) =>
      dispatchCommand('LineUp.SwapUnAssignedWithPlayer', {
        lineUpId,
        teamId,
        inPersonId,
        outPersonId,
        position,
      }),

    // not used yet
    onMovePlayerToSubstitute: ({ teamId, personId }) =>
      fail() &&
      dispatchCommand('LineUp.MovePlayerToSubstitute', {
        lineUpId,
        teamId,
        personId,
      }),
    onMoveSubstituteToPlayer: ({ teamId, personId, position }) =>
      fail() &&
      dispatchCommand('LineUp.MoveSubstituteToPlayer', {
        lineUpId,
        teamId,
        personId,
        position,
      }),
    onAddSubstitute: ({ teamId, personId }) =>
      fail() &&
      dispatchCommand('LineUp.AddSubstitute', { lineUpId, teamId, personId }),
    onRemoveSubstitute: ({ teamId, personId }) =>
      fail() &&
      dispatchCommand('LineUp.RemoveSubstitute', {
        lineUpId,
        teamId,
        personId,
      }),
    onSwapUnAssignedWithSubstitute: ({ teamId, inPersonId, outPersonId }) =>
      fail() &&
      dispatchCommand('LineUp.SwapUnAssignedWithSubstitute', {
        lineUpId,
        teamId,
        inPersonId,
        outPersonId,
      }),
    onSwapAttackDefence: ({ teamId }) => {
      dispatchCommand('LineUp.SwapAttackDefence', {
        lineUpId,
        teamId,
      });
    },
    onSwapSubstituteWithPlayer: ({
      teamId,
      inPersonId,
      outPersonId,
      position,
    }) =>
      fail() &&
      dispatchCommand('LineUp.SwapSubstituteWithPlayer', {
        lineUpId,
        teamId,
        inPersonId,
        outPersonId,
        position,
      }),
  };
};

const LineUpDetail = withTranslation('module.lineup')(
  observer(
    class LineUpDetail extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');
      }

      componentWillMount() {
        this.loadLineUpDetails();
      }

      loadLineUpDetails = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          // preload some
          yield Promise.all([
            SportingEventCollection.fetchIfEmpty(),
            PersonCollection.fetchIfEmpty(),
            lineUpCollection.fetchIfEmpty(),
            TeamCollection.fetchIfEmpty(),
          ]);

          const sportingEvent = yield SportingEventCollection.getOrFetch(
            this.props.match.params.sportingEventId
          );
          const lineUp = yield lineUpCollection.getOrFetch(
            sportingEvent.get('lineUpId')
          );
          yield sportingEvent.loadTeams();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load LineUp',
          });
          this.dataState.set('error');
        }
      });

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const sportingEvent = SportingEventCollection.get(
          this.props.match.params.sportingEventId
        );
        const lineUp = lineUpCollection.get(sportingEvent.get('lineUpId'));

        const teamRoles = {
          [sportingEvent.homeTeam.id]: lineUp.getTeamRoles(
            sportingEvent.homeTeam.id
          ),
          [sportingEvent.awayTeam.id]: lineUp.getTeamRoles(
            sportingEvent.awayTeam.id
          ),
        };

        const doneButton = (
          <button
            className="btn btn-primary"
            onClick={() =>
              gotoRoute('match.overview', { timeFilterName: 'future' })
            }
          >
            {this.props.t('continue')}
          </button>
        );

        const pageProps = {
          title: this.props.t('title'),
          className: 'page--lineup',
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { path: '/match', title: this.props.t('module.match:matches') },
            {
              title: `${sportingEvent.homeTeam.label} - ${sportingEvent.awayTeam.label}`,
            },
          ],
        };

        return (
          <LineUpInput
            layout={KorfballLineUpLayout}
            sportingEvent={sportingEvent}
            teamRoles={teamRoles}
            doneButton={doneButton}
            pageProps={pageProps}
            {...getEventHandlers(lineUp.id)}
          />
        );
      }
    }
  )
);

export { LineUpDetail };
