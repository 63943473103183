import React, { useEffect, useState } from 'react';
import { observer, Observer } from 'mobx-react';
import { action } from 'mobx';

import { ObservationInput } from '../ObservationInput';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';

import { LiveObservationView } from './live-observation';
import { Modal } from 'lib/Modal';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';
import { useObservationContext } from 'domain/ObservationLogger/ObservationContextProvider';

import { LiveObservationGoalView } from './handball-live-default/goal';
import { LiveObservationInputHandballKeeperView } from './handball-live-default/keepers';
import { LiveObservationInputHandballField } from './handball-live-default/field';

import previewImgSrc from './handball-live-default/preview.png';

const LiveObservationInputHandballInnerView = observer(
  ({ playDirection, keeperSelections, nextPossession }) => {
    const observation = 1; // TODO: remove?
    const observationContext = ObservationContext.instance();

    const possessionAttributes = observationContext.observationLogger.getCurrentPartialObservationAttributes(
      'POSSESSION'
    );
    // let possessionTeamKey = 'home';
    let possessionTeamKey = nextPossession;
    let players = [];
    let keeperPersonId = null;

    if (possessionAttributes) {
      possessionTeamKey =
        possessionAttributes.get('teamId') === observationContext.homeTeam.id
          ? 'home'
          : 'away';

      if (possessionTeamKey === 'home') {
        players = observationContext.homeTeam.players.toArray();
        keeperPersonId = keeperSelections.away; // keeper of opposite team
      } else {
        players = observationContext.awayTeam.players.toArray();
        keeperPersonId = keeperSelections.home; // keeper of opposite team
      }
      players.sort((a, b) => a.number - b.number);
    }

    const shotAttributes = observationContext.observationLogger.getCurrentPartialObservationAttributes(
      'SHOT'
    );

    return (
      <div
        className="live-observation-input live-observation-input-handball"
        style={{ backgroundColor: '#2a80b9' }}
      >
        <LiveObservationInputHandballField
          playDirection={playDirection}
          possession={possessionTeamKey}
          teamName={
            possessionTeamKey === 'home'
              ? observationContext.homeTeam.label
              : observationContext.awayTeam.label
          }
          className="live-observation-input-background"
          onClick={({ distance, x }) => {
            if (possessionTeamKey === null) {
              return;
            }
            observationContext.observationLogger.startRangeObservation(
              'SHOT',
              'SHOT',
              { distance, x, keeperPersonId }
            );
          }}
        />
        {!!shotAttributes && (
          <Modal
            size="full-screen"
            onCloseClick={() =>
              observationContext.observationLogger.removePartialObservation(
                'SHOT'
              )
            }
          >
            <LiveObservationGoalView
              type={shotAttributes.has('type') && shotAttributes.get('type')}
              personId={
                shotAttributes.has('personId') && shotAttributes.get('personId')
              }
              goalPosition={
                shotAttributes.has('goalPosition') &&
                shotAttributes.get('goalPosition')
              }
              players={players}
              onSetAttributes={action(attributes => {
                if (Object.keys(attributes)[0] === 'result') {
                  observationContext.observationLogger.endRangeObservation(
                    'SHOT',
                    attributes,
                    'start',
                    undefined,
                    'Schot'
                  );
                } else {
                  observationContext.observationLogger.setObservationAttributes(
                    'SHOT',
                    'SHOT',
                    attributes
                  );
                }
              })}
            />
          </Modal>
        )}
        {/*<div className="layer-0" style={{ color: '#fff', fontWeight: 'bold' }}>*/}
        {/*/!*<div>Aanval Aalsmeer 1</div>*!/*/}
        {/*</div>*/}
      </div>
    );
  }
);

const LiveObservationInputHandballView = ({}) => {
  const [initialPossession, setInitialPossession] = useState('home');
  const [playDirection, setPlayDirection] = useState('home-away');
  const [keeperSelections, setKeeperSelections] = useState({
    home: null,
    away: null,
  });

  const observationContext = ObservationContext.instance();

  const dispatchPossession = teamId => {
    CommandBus.dispatch(
      Command.create('ObservationLogger.TurnoffGroup', {
        groupId: 'POSSESSION',
      })
    );

    CommandBus.dispatch(
      Command.create('ObservationLogger.SetPointObservation', {
        code: 'GAME:TURNOVER',
        attributes: {},
        description: 'Turnover',
      })
    );

    CommandBus.dispatch(
      Command.create('ObservationLogger.StartRangeObservation', {
        groupId: 'POSSESSION',
        code: 'POSSESSION',
        attributes: { teamId },
      })
    );

    CommandBus.dispatch(
      Command.create('ObservationLogger.SetPointObservation', {
        code: 'START-POSSESSION',
        attributes: { teamId },
        description: 'Start balbezit',
      })
    );
  };

  const swapPossession = () => {
    const possessionAttributes = observationContext.observationLogger.getCurrentPartialObservationAttributes(
      'POSSESSION'
    );
    let possessionTeamId;
    if (possessionAttributes) {
      possessionTeamId =
        possessionAttributes.get('teamId') === observationContext.homeTeam.id
          ? observationContext.awayTeam.id
          : observationContext.homeTeam.id;
    } else {
      // first ball possession
      possessionTeamId =
        initialPossession === 'home'
          ? observationContext.homeTeam.id
          : observationContext.awayTeam.id;
    }

    dispatchPossession(possessionTeamId);
  };

  const turnover = () => {
    swapPossession();
    CommandHistory.instance().tagHistoryItem('Turnover');
  };

  useEffect(() => {
    const observationContext = ObservationContext.instance();

    const commandHistory = CommandHistory.instance();

    const observationLogger = observationContext.observationLogger;

    observationLogger.on(
      'afterObservationStarted',
      action((code, attributes) => {
        if (code === 'SHOT') {
          const newAttributes = {
            type: 'NORMAL',
          };
          const { distance = 0, x = 0 } = attributes;
          if (
            Math.sqrt(
              (attributes.distance - 7.1) ** 2 + (attributes.x - 0) ** 2
            ) < 1
          ) {
            newAttributes.type = '7-METER';
          } else if (attributes.distance > 17) {
            // shot from own half is mostly empty goal
            newAttributes.type = 'EMPTY-GOAL';
          }

          const observations = observationContext.observations();
          observations.reverse();
          for (const observation of observations) {
            if (
              observation.code === 'GAME:TURNOVER' ||
              observation.code === 'GAME:FOUL'
            ) {
              const lastTurnover = observationContext.clock.secondsElapsedSince(
                observation.startTime
              );
              if (lastTurnover < 7) {
                newAttributes.type = 'BREAK';
              }
              break;
            }
          }

          const command = Command.create(
            'ObservationLogger.SetObservationAttributes',
            {
              groupId: 'SHOT',
              code: 'SHOT',
              attributes: newAttributes,
            }
          );
          CommandBus.dispatch(command);
        }
      })
    );

    observationLogger.on(
      'afterObservationAdded',
      action(
        (code, attributes, triggerTime, startTime, endTime, description) => {
          switch (code) {
            case 'SHOT':
              if (!attributes.has('rebound')) {
                swapPossession();
                commandHistory.tagHistoryItem(`Schot en balbezit wissel`);
              } else {
                commandHistory.tagHistoryItem(description);
              }
              break;
            case 'POSSESSION':
            case 'START-POSSESSION':
            case 'GAME:TURNOVER':
            case 'TIME:START-PERIOD':
            case 'TIME:END-PERIOD':
              break;

            case 'TIME:CORRECTION':
            case 'TIME:RESUME':
            case 'TIME:PAUSE':
              commandHistory.tagHistoryItem(description, {
                showNotification: false,
              });
              break;
            default:
              commandHistory.tagHistoryItem(description);
          }
        }
      )
    );

    observationContext.observationLogger.on(
      'descriptionRequested',
      (code, attributes) => {
        switch (code) {
          case 'POSSESSION':
            const team =
              attributes.get('teamId') === observationContext.homeTeam.id
                ? observationContext.homeTeam
                : observationContext.awayTeam;

            return `Aanval ${team.label}`;
        }
      }
    );

    observationContext.on('periodStarted', periodNr => {
      CommandBus.dispatch(
        Command.create('ObservationLogger.SetPointObservation', {
          code: 'TIME:START-PERIOD',
          attributes: { period: periodNr },
          description: `Start period ${periodNr}`,
        })
      );
    });

    observationContext.on('periodEnded', periodNr => {
      const command = Command.create('ObservationLogger.TurnoffGroup', {
        groupId: 'POSSESSION',
      });
      CommandBus.dispatch(command);
      CommandBus.dispatch(
        Command.create('ObservationLogger.SetPointObservation', {
          code: 'TIME:END-PERIOD',
          attributes: { period: periodNr },
          description: `End period ${periodNr}`,
        })
      );
    });
  }, []);

  const handleInitialBallPossession = (team = 'home') => () => {
    setInitialPossession(team);
  };

  const switchInitialBallPossession = () => {
    setInitialPossession(prev => (prev === 'home' ? 'away' : 'home'));
  };

  const teamIdForPossession = possession =>
    observationContext[`${possession}Team`].id;

  useEffect(() => {
    const removePeriodStart = observationContext.on(
      'periodStarted',
      periodNr => {
        dispatchPossessionUsingInitialState(initialPossession);
      }
    );

    const removePeriodEnd = observationContext.on('periodEnded', periodNr => {
      switchInitialBallPossession();
    });

    return () => {
      removePeriodStart();
      removePeriodEnd();
    };
  }, [initialPossession, switchInitialBallPossession]);

  const dispatchPossessionUsingInitialState = possession => {
    dispatchPossession(teamIdForPossession(possession), false);
  };

  const actionsPrimary = [
    {
      onClick: turnover,
      children: ['Turnover'],
    },
    {
      onClick: () => {
        observationContext.observationLogger.setPointObservation(
          'GAME:FOUL',
          {},
          'Technische fout'
        );
        swapPossession();
        CommandHistory.instance().tagHistoryItem('Technische fout');
      },
      children: ['Technisch'],
    },
    {
      onClick: () => {
        // Q: can i just change these values?
        observationContext.observationLogger.setPointObservation(
          'GAME:SPECIAL',
          {},
          'Special'
        );
      },
      children: ['Special'],
    },
    {
      onClick: () => observationContext.togglePeriod(),
      children: <Observer>{() => observationContext.periodLabel}</Observer>,
    },
  ];

  const actionsPrimaryBeforeFirstPeriod = [
    {
      onClick: handleInitialBallPossession('home'),
      children: ['Bal uit ' + observationContext.homeTeam.name],
      active: initialPossession === 'home', // set initial game state right away
    },
    {
      onClick: handleInitialBallPossession('away'),
      children: ['Bal uit ' + observationContext.awayTeam.name],
      active: initialPossession === 'away',
    },
    {
      onClick: () => observationContext.togglePeriod(),
      children: <Observer>{() => observationContext.periodLabel}</Observer>,
    },
  ];

  const actionsSecondary = [
    // {
    //   onClick: () => observationContext.togglePeriod(),
    //   children: <i className="i-time-out" />,
    // },
    {
      onClick: () =>
        setPlayDirection(playDirection =>
          playDirection === 'home-away' ? 'away-home' : 'home-away'
        ),
      children: <i className="i-flip" />,
    },
    {
      onClick: () =>
        CommandHistory.instance().undoToPreviousTaggedHistoryItem(),
      children: <i className="i-undo2" />,
    },

    // {
    //   onClick: console.debug,
    //   children: <i className="i-export" />,
    // },
  ];

  const players = {
    home: observationContext.homeTeam.players
      .toArray()
      .sort((a, b) => a.number - b.number),
    away: observationContext.awayTeam.players
      .toArray()
      .sort((a, b) => a.number - b.number),
  };

  const { clock } = useObservationContext('clock');
  const isGameStarted = clock.state !== 'STOPPED';

  if (observationContext === null) {
    return null;
  } else {
    return (
      <div className="live-observation-view-container">
        <LiveObservationView
          actionsPrimary={
            isGameStarted ? actionsPrimary : actionsPrimaryBeforeFirstPeriod
          }
          actionsSecondary={actionsSecondary}
        >
          <LiveObservationInputHandballInnerView
            playDirection={playDirection}
            keeperSelections={keeperSelections}
            nextPossession={initialPossession}
          />
        </LiveObservationView>
        <LiveObservationInputHandballKeeperView
          {...{ keeperSelections, setKeeperSelections, playDirection, players }}
        />
      </div>
    );
  }
};

export default new ObservationInput({
  name: 'handball.live.default',
  version: 2,
  component: LiveObservationInputHandballView,
  title: 'Handball Live',
  previewImgSrc,
});
