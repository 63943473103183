class MessageRejected {}
class MessageNotHandled {}

function shExpMatch(input, pattern) {
  pattern = pattern.replace(/\./g, '\\.');
  pattern = pattern.replace(/\*/g, '.*');
  pattern = pattern.replace(/\?/g, '.');
  const newRe = new RegExp('^' + pattern + '$');
  return newRe.test(input);
}

class Handler {
  static addHandler(handler) {
    this.handlers.push(handler);
  }

  static handle(message) {
    for (let handler of this.handlers) {
      let canHandle = false;
      for (let understoodType of handler.understoodTypes()) {
        if (
          shExpMatch(message.commandType || message.eventType, understoodType)
        ) {
          canHandle = true;
          break;
        }
      }

      if (canHandle) {
        const result = handler._doHandle(message);
        if (result === false) {
          debugger;
          throw new MessageRejected('Message Rejected by ' + handler);
        } else if (result !== true) {
          debugger;
          throw new MessageNotHandled();
        }
      }
    }
  }

  _doHandle(message) {
    return this.doHandle(message);
  }

  doHandle(message) {
    throw 'doHandle not implemented';
  }

  understoodTypes() {
    debugger;
    throw 'understoodTypes not implemented';
  }
}

export default Handler;
