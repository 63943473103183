import React from 'react';
import { ObservationDetailsTennisBase } from './base';

const schema = {
  type: 'object',
  properties: {
    attributes: {
      type: 'object',
      properties: {
        area: {
          type: 'area',
        },
        comment: {
          type: 'string',
          i18n: {
            title: 'comment',
          },
        },
      },
    },
  },
};

export const ObservationDetailTennisDefault = props => (
  <ObservationDetailsTennisBase {...props} schema={schema} />
);
