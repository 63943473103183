import { groupObservationsPerTeam } from 'utils/observation';
import { withTranslation } from 'react-i18next';

class VideoFragmentGenerator {
  generate(
    collection /*: VideoFragmentCollection */,
    { sportingEvent, observations }
  ) {
    throw 'Implement me!';
  }
}

class DescriptionVideoFragmentGenerator extends VideoFragmentGenerator {
  generate(collection, { observations }) {
    observations.map(
      ({ code, displayStartTime, displayEndTime, description }) => {
        // Never show time or eventStream related observations
        if (!code.startsWith('TIME:') && code !== 'START-POSSESSION') {
          collection.add('unknown', description, {
            startTime: displayStartTime,
            endTime: displayEndTime,
            description,
          });
        }
      }
    );
  }
}

class TennisVideoFragmentGenerator extends VideoFragmentGenerator {
  generate(collection, { observations }) {
    const services = observations
      .filter(({ code }) => code === 'SERVICE')
      .reverse();
    const endpoints = observations.filter(({ code }) => code === 'ENDPOINT');
    const hits = observations
      .filter(({ code }) => code !== 'ENDPOINT')
      .reverse();

    observations.map(
      ({ code, startTime, endTime, description, attributes_: attributes }) => {
        switch (code) {
          case 'ENDPOINT':
            const rallyStartTime = services.find(
              ({ startTime: serviceStartTime }) => serviceStartTime < startTime
            ).startTime;

            // We hebben nu een startime en een endtime
            // Zoek nu eens de ralleys op met slagen > 6
            // En noem dat de Long-rally
            // startTime = endTime, so ignore endTime
            const hitCount = hits.filter(
              ({ startTime: hitTime }) =>
                rallyStartTime < hitTime && hitTime < endTime
            ).length;

            if (hitCount > 6) {
              description = 'Long-rally';
            } else {
              description = 'Rally';
            }

            collection.add('unknown', description, {
              startTime: rallyStartTime,
              endTime,
              description,
            });
            break;

          case 'RETURN':
            collection.add('unknown', description, {
              startTime: startTime - 1,
              endTime: endTime + 2,
              description,
            });
            break;

          case 'BASELINE':
            collection.add('unknown', description, {
              startTime: startTime - 1,
              endTime: endTime + 2,
              description,
            });
            break;

          case 'SERVICE':
            // eerste service UIT
            // eerste service IN
            // tweede service IN
            switch (attributes.type) {
              case 'FIRST':
                description = 'Eerste service';
                break;
              case 'SECOND':
                description = 'Tweede service';
                break;
            }

            collection.add('unknown', description, {
              startTime: startTime - 1,
              endTime: endTime + 3,
              description,
            });
            break;

          default:
            collection.add('unknown', description, {
              startTime,
              endTime,
              description,
            });
        }
      }
    );

    endpoints.map(
      ({ code, startTime, endTime, description, attributes_: attributes }) => {
        // zoek de laatste hit hiervoor op
        const last_hit = hits.find(
          ({ startTime: lastHitStartTime }) => lastHitStartTime < startTime
        );

        switch (attributes.result) {
          case 'UNFORCED-ERROR':
            description = 'Unforced error';
            break;
          case 'FORCED-ERROR':
            description = 'Forced error';
            break;
          case 'WINNER':
            description = 'Winner';
        }
        collection.add('unknown', description, {
          startTime: last_hit.startTime - 1,
          endTime: endTime + 2,
          description: description,
        });
      }
    );

    /*

    */
  }
}

class KorfballVideoFragmentGenerator extends VideoFragmentGenerator {
  generate(collection, { observations }) {
    const shots = observations.filter(({ code }) => code === 'SHOT');
    const possessions = observations.filter(
      ({ code }) => code === 'POSSESSION'
    );
    const ballLoss = observations.filter(({ code }) => code === 'BALL-LOSS');

    const reboundResult = (shotTime) => {
      // when it was a goal this will return false, so dont call this when it's a goal
      const possession = possessions.find(
        ({ startTime, endTime }) => startTime <= shotTime && shotTime <= endTime
      );

      if (!possession) {
        return { result: undefined };
      }

      const ballLossAfterShot = !!ballLoss.find(
        ({ startTime: ballLossTime }) =>
          shotTime < ballLossTime && ballLossTime <= possession.endTime
      );
      const shotAfterShot = !!shots.find(
        ({ startTime: secondShotTime }) =>
          shotTime < secondShotTime && secondShotTime <= possession.endTime
      );

      return {
        result: ballLossAfterShot || shotAfterShot,
        possession,
      };
    };

    observations.map(
      ({
        code,
        startTime,
        endTime,
        displayStartTime,
        displayEndTime,
        description,
        attributes_: attributes,
      }) => {
        const labels = {};
        const originalDescription = description;

        switch (code) {
          case 'POSSESSION':
            const shotsInAttack = shots.filter(
              ({ startTime: shotTime }) =>
                startTime <= shotTime && shotTime <= endTime
            );
            const scored = !!shotsInAttack.find(
              ({ attributes_: attributes }) => attributes.result === 'GOAL'
            );
            if (scored) {
              labels.rating = 'positive';
              displayEndTime += 5; // When attack is ended by a goal, often the goal itself is missing.
            }
            const shotCount = shotsInAttack.length;
            labels.count = shotsInAttack.length;
            if (attributes.position === 'ATTACK') {
              description = 'Aanvallen 1e aanval';
            } else {
              description = 'Aanvallen 1e verdediging';
            }
            break;

          case 'BALL-LOSS':
            description = 'Balverlies';
            break;

          case 'SHOT':
            if (typeof attributes.distance !== 'undefined') {
              switch (attributes.type) {
                case 'PENALTY':
                  description = 'Strafworp';
                  break;

                case 'FREE-BALL':
                  description = 'Vrije bal';
                  break;

                default:
                  switch (true) {
                    case attributes.distance < 3:
                      description = 'Schot < 3m';
                      break;
                    case attributes.distance < 6:
                      description = 'Schot < 6m';
                      break;
                    case attributes.distance < 9:
                      description = 'Schot < 9m';
                      break;
                    default:
                      description = 'Schot > 9m';
                      break;
                  }
              }
            } else if (typeof attributes.type !== 'undefined') {
              switch (attributes.type) {
                case 'PENALTY':
                  description = 'Strafworp';
                  break;

                case 'FREE-BALL':
                  description = 'Vrije bal';
                  break;

                case 'RUNNING-IN':
                  description = 'Doorloopbal';
                  break;

                case 'LONG':
                  description = 'Afstandschot';
                  break;

                case 'SHORT':
                  description = 'Korte kans';
                  break;
              }
              if (attributes.result === 'GOAL') {
                description += ' goal';
              }
            }

            if (attributes.result === 'GOAL') {
              labels.rating = 'positive';

              collection.add('unknown', 'Goal', {
                startTime: displayStartTime,
                endTime: displayEndTime,
                description: originalDescription,
              });
            } else {
              const { result, possession } = reboundResult(startTime);
              if (result !== undefined) {
                const reboundDescription = `Rebound ${
                  possession.attributes_.position === 'ATTACK'
                    ? '1e aanval'
                    : '1e verdediging'
                }`;
                collection.add('unknown', reboundDescription, {
                  startTime,
                  endTime,
                  description: reboundDescription,
                  labels: result
                    ? {
                        rating: 'positive',
                      }
                    : {},
                });
              }
            }

            break;
          case 'PENALTY-GIVEN':
            switch (attributes.type) {
              case 'PENALTY-SHOT':
                description = 'Strafworp gegeven';
                break;
              case 'FREE-BALL':
                description = 'Vrije bal gegeven';
                break;
            }
            break;

          default:
          //pass along
        }

        collection.add('unknown', description, {
          startTime: displayStartTime,
          endTime: displayEndTime,
          description,
          labels,
        });
      }
    );
  }
}

class HandballVideoFragmentGenerator extends VideoFragmentGenerator {
  generate(collection, { observations }) {
    observations.map(
      ({
        code,
        displayStartTime,
        displayEndTime,
        description,
        attributes_: attributes,
      }) => {
        if (code.startsWith('TIME:') || code === 'START-POSSESSION') {
          return;
        }
        if (code === 'GAME:TURNOVER') {
          return;
        }
        switch (code) {
          case 'SHOT':
            if (attributes.result === 'GOAL') {
              collection.add('unknown', 'Goal', {
                startTime: displayStartTime,
                endTime: displayEndTime,
                description: 'Goal',
              });
            }

            if (typeof attributes.distance !== 'undefined') {
              const distanceToMiddleOfGoal = Math.sqrt(
                Math.pow(attributes.distance, 2),
                Math.pow(attributes.x, 2)
              );
              switch (true) {
                case distanceToMiddleOfGoal < 7:
                  description = 'Schot 6-7';
                  break;
                case distanceToMiddleOfGoal < 9:
                  description = 'Schot 8-9';
                  break;
                default:
                  description = 'Schot >9';
                  break;
              }
            } else {
              // Fragment was already added to the 'Goal' video. Don't add it twice
              if (description === 'Goal') {
                return;
              }
            }

            break;
        }
        collection.add('unknown', description, {
          startTime: displayStartTime,
          endTime: displayEndTime,
          description,
        });
      }
    );
  }
}

class PersonVideoFragmentGenerator extends VideoFragmentGenerator {
  constructor(personId) {
    super();
    this.personId = personId;
  }

  generate(
    collection /*: VideoFragmentCollection */,
    { sportingEvent, observations }
  ) {
    observations.map(
      ({
        displayStartTime,
        displayEndTime,
        attributes_: attributes,
        description,
      }) => {
        if (
          attributes.personId === this.personId ||
          (attributes.participantsPersonIds || []).indexOf(this.personId) !== -1
        ) {
          collection.add('personal', description, {
            startTime: displayStartTime,
            endTime: displayEndTime,
            description,
          });
        }
      }
    );
    collection.addCategories([
      {
        key: 'personal',
        label: 'Persoonlijk',
      },
    ]);
  }
}

class AllGoalsVideoFragmentGenerator extends VideoFragmentGenerator {
  generate(
    collection /*: VideoFragmentCollection */,
    { sportingEvent, observations }
  ) {
    observations.map(
      ({
        displayStartTime,
        displayEndTime,
        code,
        description,
        attributes_: attributes,
      }) => {
        if (
          (code === 'SHOT' && attributes.result === 'GOAL') ||
          code === 'GOAL-CORRECTION'
        ) {
          collection.add('unknown', 'Alle goals', {
            startTime: displayStartTime,
            endTime: displayEndTime,
            description,
          });
        }
      }
    );
  }
}

class TeamVideoFragmentGenerator extends VideoFragmentGenerator {
  subGenerators = [new DescriptionVideoFragmentGenerator()];

  setSubGenerators(subGenerators) {
    this.subGenerators = subGenerators;
  }

  generate(
    collection /*: VideoFragmentCollection */,
    { sportingEvent, observations }
  ) {
    const observationsPerTeam = groupObservationsPerTeam(observations);
    for (const teamId of Object.keys(observationsPerTeam)) {
      const subCollection = collection.partialAdd(teamId);

      for (const generator of this.subGenerators) {
        generator.generate(subCollection, {
          sportingEvent,
          observations: observationsPerTeam[teamId],
        });
      }
    }

    const categories = [];
    categories.push({
      key: sportingEvent.homeTeam.id,
      label: sportingEvent.homeTeam.label,
    });
    if (sportingEvent.homeTeam.isCopy()) {
      categories.push({
        key: sportingEvent.homeTeam.original.id,
        label: sportingEvent.homeTeam.label,
      });
    }
    categories.push({
      key: sportingEvent.awayTeam.id,
      label: sportingEvent.awayTeam.label,
    });
    if (sportingEvent.awayTeam.isCopy()) {
      categories.push({
        key: sportingEvent.awayTeam.original.id,
        label: sportingEvent.awayTeam.label,
      });
    }
    categories.push({
      key: 'unknown',
      label: 'Other',
    });

    collection.addCategories(categories);
  }
}

export {
  TeamVideoFragmentGenerator,
  KorfballVideoFragmentGenerator,
  HandballVideoFragmentGenerator,
  TennisVideoFragmentGenerator,
  PersonVideoFragmentGenerator,
  AllGoalsVideoFragmentGenerator,
};
