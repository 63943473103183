import { Session } from 'domain/Session';
import { NewMatchInput, NewTrainingInput } from './NewMatchInput';
import logger from 'utils/Logger';
import { Command } from 'infra/Messaging';
import SportingEventCollection from 'domain/SportingEvent';
import { runInAction } from 'mobx';
import { gotoPath } from '../../route';
import { observationSelection } from '../../observe/components/InputNav';
import { Modal } from 'lib/Modal';
import history from 'utils/History';
import TeamCollection from 'domain/Team';

export const AddSportingEventModal = ({
  type,
  onClose = () => {},
  onSportingEventAdded,
}) => {
  const currentSession = Session.current();

  let modalContent;
  switch (type) {
    case 'match': {
      modalContent = (
        <NewMatchInput
          onAdd={async (values) => {
            await onAddSportingEvent({
              ...values,
              type: type,
            });
            onSportingEventAdded();
          }}
          onCancel={() => {
            onClose();
          }}
          type={type}
          sportType={currentSession.sportType()}
        />
      );
      break;
    }
    case 'training': {
      modalContent = (
        <NewTrainingInput
          onAdd={async (values) => {
            await onAddSportingEvent({
              ...values,
              type: type,
            });
            onSportingEventAdded();
          }}
          type={type}
          onCancel={() => {
            onClose();
          }}
          sportType={currentSession.sportType()}
        />
      );
      break;
    }
    case 'recording':
      modalContent = (
        <NewTrainingInput
          onAdd={async (values) => {
            await onAddSportingEvent({
              ...values,
              type: type,
            });
            onSportingEventAdded();
          }}
          onCancel={() => {
            onClose();
          }}
          type={type}
          sportType={currentSession.sportType()}
        />
      );
      break;
    default: {
      modalContent = <h2>error</h2>;
    }
  }

  return <Modal onCloseClick={() => onClose()}>{modalContent}</Modal>;
};

const onAddSportingEvent = async ({
  homeTeam = 'not_set',
  awayTeam = 'not_set',
  name = null,
  type, // sportingEvent.type
  scheduledAt,
  matchConfig,
  automaticCameraConfig,
  recordingType,
}) => {
  const sportType = Session.current().sportType();
  if (!sportType && type == 'match') {
    logger.warning('SportType is not set', { extra: Session.current() });
  }

  let homeTeamId,
    awayTeamId,
    _homeTeam,
    _awayTeam,
    _name = name;
  if (homeTeam !== 'not_set' && awayTeam !== 'not_set') {
    // Create or fetch team id's
    _homeTeam = await createOrFetchTeam(homeTeam);
    _awayTeam = await createOrFetchTeam(awayTeam);
    homeTeamId = _homeTeam.teamId;
    awayTeamId = _awayTeam.teamId;
  } else {
    homeTeamId = homeTeam;
    awayTeamId = awayTeam;
  }
  if (!_name) {
    _name = `${_homeTeam.name} - ${_awayTeam.name}`;
  }
  const { sportingEventId } = await SportingEventCollection.create(
    {
      name: _name,
      scheduledAt: scheduledAt.toISOString(),
      sportType: sportType,
      homeTeamId: homeTeamId,
      awayTeamId: awayTeamId,
      type: type,
      tags: { matchConfig, automaticCameraConfig },
    },
    { optimistic: false }
  );
  if (automaticCameraConfig?.useTaggingData) {
    // TODO: should be done for edit too
    const sportingEvent = SportingEventCollection.get(sportingEventId);
    await sportingEvent.eventStreams.create();
  }
  onSportingEventAdded(recordingType, type);
};

const onSportingEventAdded = (recordingType, type) => {
  setTimeout(() => {
    const sportingEvents = SportingEventCollection.toArray();

    runInAction(() => {
      const sportingEvent = sportingEvents[sportingEvents.length - 1];
      switch (recordingType) {
        case 'video':
          // Add this to query params.
          addToQueryParameters(sportingEvent.id);
          break;
        case 'live':
          observationSelection(
            false,
            null,
            { sportingEventId: sportingEvent.id },
            false,
            true
          );
          break;
        case 'tagging':
          observationSelection(
            true,
            null,
            { sportingEventId: sportingEvent.id },
            false,
            true
          );
          break;
        case 'future':
        default:
          switch (type) {
            case 'match':
              gotoPath('/match/overview/all/upcoming');
              break;
            default:
              // Other types show upcoming by default in overview.
              return;
          }
          break;
      }
    });
  }, 10);
};

const addToQueryParameters = (sportingEventId) => {
  return gotoPath(`${history.location.pathname}?upload=${sportingEventId}`);
};

const createOrFetchTeam = async (nameOrID, sportType) => {
  let team;
  if (TeamCollection.get(nameOrID)) {
    team = TeamCollection.get(nameOrID);
  } else {
    let newTeamId = TeamCollection.nextIdentity();
    let teamName = nameOrID;
    team = await TeamCollection.create({
      teamId: newTeamId,
      name: teamName,
      sportType: sportType,
    });
  }
  return team;
};
