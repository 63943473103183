import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from './Modal';
import { withTranslation } from 'react-i18next';

const MessageUI = withTranslation('common')(
  ({ title, body, actionHandler, actionLabel, onClose, t }) => {
    return (
      <Modal onCloseClick={onClose} size="sm">
        <div className="row">
          <div className="col-12">
            <b>{title}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{body}</div>
        </div>
        <div className="modal-input">
          <div className="btn btn-primary" onClick={onClose}>
            {t('ok')}
          </div>
        </div>
      </Modal>
    );
  }
);

export function message({ title, body }) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return <MessageUI title={title} body={body} onClose={onClose} />;
    },
  });
}
