import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import ReactFlagsSelect from 'react-flags-select';

import nlTranslations from './nl.json';
import enTranslations from './en.json';
import deTranslations from './de.json';
import esTranslations from './es.json';

import enOverrides from './en_skillreflect_override.json';
import { useState } from 'react';
import { Session } from '../../domain/Session';

import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/es';

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

const getLanguageFromParameter = () => {
  const params = new URLSearchParams(window.location.search);
  const name = params.get('lang');
  if (name) {
    return name;
  } else {
    return 'en';
  }
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

if (window.isSkillReflect) {
  // in case of SkillReflect.. always use English
  mergeDeep(nlTranslations, enOverrides);
  mergeDeep(enTranslations, enOverrides);
  mergeDeep(deTranslations, enOverrides);
  mergeDeep(esTranslations, enOverrides);
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: getLanguageFromParameter(),
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    parseMissingKeyHandler: () => undefined,

    resources: {
      nl: nlTranslations,
      en: enTranslations,
      de: deTranslations,
      es: esTranslations,
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

/* Set language to user locale when session is ready. */
async function getUserLanguage() {
  let currentSession = Session.current();
  await currentSession.isReady();
  await currentSession.user;
  return currentSession.user.language;
}

getUserLanguage().then((language) => {
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }
  moment.locale(language);
});

export default i18n;
