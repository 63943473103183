import React, { useState, useEffect } from 'react';

import Select from 'react-select';

import { Column, Input, ObservationDetailsContainer } from './base';

import sportingEventCollection from 'domain/SportingEvent';

import learningLineCollection from 'domain/Development/LearningLine';
import videoCollection from 'domain/Video';
import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';
import {
  displayTime,
  sportingEvent as observationSportingEvent,
} from 'domain/Observation';
import { ObservationChatBox } from 'lib/Conversation';

function buildMap() {
  const map = {};
  // sportingEventCollection.toArray() === []
  for (const sportingEvent of sportingEventCollection.toArray()) {
    for (const [clockSource, clock] of Object.entries(sportingEvent.clocks())) {
      if (clockSource !== 'U1') {
        map[
          `${sportingEvent.toJS().observationLogId}-${clock.clockId}`
        ] = clockSource;
      }
    }
  }
  return map;
}

function videoByObservation(observation) {
  const videoIds = buildMap();
  const videoId =
    videoIds[`${observation.observationLogId}-${observation.clockId}`];

  return videoCollection.get(videoId);
}

const normalizeObservation = objectOrObservable =>
  !!objectOrObservable.toJS ? objectOrObservable.toJS() : objectOrObservable;

const DevelopmentPlanObservationDetails = ({ attributesOptions, ...props }) => {
  const { current, observations } = useObservationsEditCollectionContext();
  const observation = normalizeObservation(current);

  const [indicatorId, setIndicatorId] = useState(null);
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState(null);

  const [sportingEvent, setSportingEvent] = useState();

  const [indicatorOptions, setIndicatorOptions] = useState(null);

  useEffect(() => {
    (async () => {
      await sportingEventCollection.fetchIfEmpty();
      await learningLineCollection.fetchIfEmpty();

      const learningLine = learningLineCollection.get(
        observation.attributes.learningLineId
      );
      setIndicatorOptions(
        learningLine
          .indicatorsOfPhase(observation.attributes.phaseId)
          .map(({ indicator }) => ({
            value: indicator.indicatorId,
            label: indicator.name,
          }))
      );

      setSportingEvent(
        observationSportingEvent(sportingEventCollection, observation)
      );
    })();
  }, [
    observation.attributes.learningLineId,
    observation.attributes.phaseId,
    observations,
  ]);

  useEffect(() => {
    setIndicatorId(observation.attributes.indicatorId);
    setRating(observation.attributes.rating);
    setComment(observation.attributes.comment);
  }, [
    observation.attributes.comment,
    observation.attributes.indicatorId,
    observation.attributes.rating,
    observation.observationId,
  ]);

  const ratingOptions = [
    { value: 'positive', label: 'Positive' },
    { value: 'neutral', label: 'Neutral' },
    { value: 'negative', label: 'Negative' },
    { value: 'unknown', label: 'Unrated' },
  ];

  if (!observation || indicatorOptions === null) {
    return null;
  }

  const video = props.video || videoByObservation(observation);

  const { displayStartTime, displayEndTime } = displayTime(current);

  return (
    <ObservationDetailsContainer
      observationId={observation.id}
      startTime={displayStartTime}
      endTime={displayEndTime}
      observation={observation}
      observationProps={{
        attributes: {
          indicatorId,
          rating,
          comment,
        },
      }}
      title={sportingEvent?.name}
      {...props}
      video={video}
    >
      <Column>{null}</Column>
      <Column>
        <p>
          {sportingEvent
            ? new Date(sportingEvent.createdAt).toLocaleDateString()
            : ''}{' '}
          - {observation.description}
        </p>
        <p>Created by role: {observation.creatorRoleName}</p>
        <Input label="Marker">
          <Select
            options={indicatorOptions}
            value={
              indicatorOptions.find(option => option.value === indicatorId) ||
              null
            }
            onChange={option => setIndicatorId(option.value)}
          />
        </Input>
        <Input label="Rating">
          <Select
            options={ratingOptions}
            value={
              ratingOptions.find(option => option.value === rating) || null
            }
            onChange={option => setRating(option.value)}
          />
        </Input>
        <Input label="Comment">
          <textarea
            className="form-control"
            rows="5"
            value={comment || ''}
            onChange={e => setComment(e.target.value)}
          />
        </Input>
      </Column>
      <Column>
        <ObservationChatBox observationId={observation.observationId} />
      </Column>
    </ObservationDetailsContainer>
  );
};

export { DevelopmentPlanObservationDetails };
