export interface DropdownOption {
  id: string;
  label: string;
  value: string;
}

// TODO: check translations with Koen, maybe with Trans
export const statusOptions: DropdownOption[] = [
  {
    id: 'OPEN',
    value: 'OPEN',
    label: 'Open',
  },
  {
    id: 'ACTIVE',
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    id: 'FINISHED',
    value: 'FINISHED',
    label: 'Finished',
  },
];

export const weightOptions: DropdownOption[] = [...Array(10).keys()].map(i => ({
  id: i,
  label: i,
  value: i,
}));
