// Step 3
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import _ from 'lodash';
import Loading from 'lib/Loading';
import { Error } from '../index';

export const AccountForm = ({
  stepHandler,
  formData,
  setFormData,
  checkBoxes,
}) => {
  const [value, setValue] = useState({
    password: '',
    passwordRepeat: '',
    role: window.isSkillReflect ? '__DEFAULT__' : formData?.role, // Add default value in case of skillReflect (no roletypes)
  });
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();

  const checkValue = async () => {
    setError(false);
    setIsChecking(true);

    if (value.password !== value.passwordRepeat) {
      setError(true);
      setIsChecking(false);
      return;
    }
    setFormData((o) => {
      return { ...o, ...{ password: value.password, role: value.role } };
    });
    stepRef.current.classList.add('animateOut');

    setTimeout(stepHandler.next(), 500);
  };

  const stepRef = useRef();

  return (
    <form
      data-test-id={'step-account'}
      ref={stepRef}
      className={'userlogin-form-step'}
      onSubmit={(e) => {
        e.preventDefault();
        if (!isChecking) {
          checkValue();
        }
      }}
    >
      <h1>{t('form.confirmAccountTitle')}</h1>
      <p>{t('form.confirmAccountBody')}</p>

      <div className={'userlogin-form-input'}>
        <label>{t('form.email')}</label>
        <div
          onClick={() => stepHandler.goto(1)}
          className={'userlogin-form-editable'}
        >
          <div className="d-none">
            <input
              type={'email'}
              autoComplete={'mail'}
              value={formData.email}
            />
          </div>
          {formData.email}
          <i className={'i-edit i-dark i-xs'}></i>
        </div>
      </div>
      <div className={'userlogin-form-input'}>
        <label>{t('form.clubName')}</label>
        <div
          onClick={() => stepHandler.goto(2)}
          className={'userlogin-form-editable'}
        >
          {formData.sportTypeLabel && (
            <span className={'pill'}>{formData.sportTypeLabel}</span>
          )}{' '}
          {formData.tenantName}
          <i className={'i-edit i-dark i-xs'}></i>
        </div>
      </div>
      <div className={'userlogin-form-input'}>
        <input
          type={'password'}
          id={'password'}
          autoComplete={'new-password'}
          placeholder={t('form.passwordPlaceholderNew')}
          value={value.password}
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, ...{ password: e.target.value } };
            });
          }}
        />
        <label htmlFor={'password'}>{t('form.password')}</label>
      </div>
      <div className={'userlogin-form-input'}>
        <input
          type={'password'}
          id={'passwordRepeat'}
          placeholder={t('form.passwordRepeatPlaceholder')}
          autoComplete={'new-password'}
          value={value.passwordRepeat}
          onChange={(e) => {
            e.persist();
            setValue((o) => {
              return { ...o, ...{ passwordRepeat: e.target.value } };
            });
          }}
        />
        <label htmlFor={'passwordRepeat'}>{t('form.passwordRepeat')}</label>
      </div>
      {error && (
        <Error
          title={t('form.passwordError')}
          body={t('form.passwordErrorBody')}
        ></Error>
      )}
      {checkBoxes}
      <div className={'userlogin-form-actions'}>
        <button
          type={'submit'}
          className={'btn btn-primary'}
          disabled={!_.every(value) || !formData.acceptedDocuments}
        >
          {t('form.complete')}
          {isChecking && (
            <Loading type={'spinner'} color={'white'} size={16}></Loading>
          )}
        </button>
      </div>
    </form>
  );
};
