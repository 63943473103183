import { CommandHandler } from './CommandHandler';
import { ObservationContext } from '../../domain/ObservationLogger';
import Command from './Command';

class CommandHistory {
  static _instance = null;
  static instance() {
    if (this._instance === null) {
      this._instance = new CommandHistory();
    }
    return this._instance;
  }

  constructor() {
    this._callbacks = {};
    this.resetHistory();
    this.undoToPreviousTaggedHistoryItem = this.undoToPreviousTaggedHistoryItem.bind(
      this
    );
  }

  get historyLength() {
    return this.tags.length;
  }

  resetHistory() {
    this.nextHistoryItemId = 1;
    this.historyItems = [];
    this.tags = [];

    this.triggerEvent('historyReset');
  }

  handle(command) {
    // don't handle Commands we created ourself
    if (command.__undoCommand) {
      return;
    }

    const aggregateType = command.commandType.split('.')[0];
    let aggregateSnapshot;
    switch (aggregateType) {
      case 'LineUp':
        break;
      case 'ObservationLog':
        break;
      case 'ObservationLogger':
        const observationLogger = ObservationContext.instance()
          .observationLogger;
        aggregateSnapshot = observationLogger.getSnapshot();
        break;
    }

    this.addHistoryItem(aggregateType, aggregateSnapshot, command);
  }

  addHistoryItem(aggregateType, aggregateSnapshot, command) {
    this.historyItems.push({
      aggregateType,
      aggregateSnapshot,
      command,
      id: this.getNextHistoryItemId(),
    });
  }

  getUndoCommands(historyItem) {
    let undoCommand = null;
    const createUndoCommand = (commandType, attributes) =>
      Command.create(commandType, attributes, {
        __undoCommand: true,
        __originalCommandId: historyItem.command.id,
      });

    switch (historyItem.aggregateType) {
      case 'ObservationLog':
        switch (historyItem.command.commandType) {
          case 'ObservationLog.AddObservation':
            undoCommand = createUndoCommand(
              'ObservationLog.RemoveObservation',
              { observationId: historyItem.command.observationId }
            );
            break;
          case 'ObservationLog.RemoveObservation':
            undoCommand = undefined; // CANNOT UNDO
            break;
        }
        break;
      case 'ObservationLogger':
        undoCommand = createUndoCommand('ObservationLogger.RestoreSnapshot', {
          snapshot: historyItem.aggregateSnapshot,
        });
        break;
      case 'SportingEvent':
        switch (historyItem.command.commandType) {
          case 'SportingEvent.AddSynchronizationPoint':
            undoCommand = createUndoCommand(
              'SportingEvent.RemoveSynchronizationPoint',
              {
                sportingEventId: historyItem.command.sportingEventId,
                clockId: historyItem.command.clockId,
                type: historyItem.command.type,
                key: historyItem.command.key,
              }
            );
            break;
        }
        break;

      case 'Person':
      case 'Team':
      case 'PersonService':
      case 'SportingEventService':
        undoCommand = undefined; // CANNOT UNDO
        break;
    }

    if (undoCommand === null) {
      throw "Don't know how to undo " + historyItem.command.commandType;
    }

    if (undoCommand === undefined) {
      // don't undo it
      return [];
    }
    return [undoCommand];
  }

  undoHistory(historyItemCount) {
    const undoCommands = [];
    historyItemCount = Math.min(historyItemCount, this.historyItems.length);
    for (let i = 0; i < historyItemCount; i++) {
      const historyItem = this.historyItems.pop();
      const commands = this.getUndoCommands(historyItem);
      undoCommands.push(...commands);
    }

    for (const command of undoCommands) {
      CommandBus.dispatch(command);
    }
  }

  undoToHistoryItem(historyItemId) {
    for (let i = 0; i < this.historyItems.length; i++) {
      const historyItem = this.historyItems[i];
      if (historyItem.id === historyItemId) {
        // -1 because everything upto matching historyItem must be undoed
        //    not item itself
        this.undoHistory(this.historyItems.length - i - 1);
        return;
      }
    }
    throw 'History item not found';
  }

  tagHistoryItem(description, extra) {
    this.tags.push({
      historyItemId: this.getCurrentHistoryItemId(),
      description,
      time: new Date(),
    });

    this.triggerEvent('tagAdded', description, extra);
  }

  getNextHistoryItemId() {
    return this.nextHistoryItemId++;
  }

  getCurrentHistoryItemId() {
    return this.historyItems[this.historyItems.length - 1].id;
  }

  getLastTagDescription() {
    if (this.tags.length > 0) {
      return this.tags[this.tags.length - 1].description;
    } else {
      return null;
    }
  }

  undoToPreviousTaggedHistoryItem() {
    if (this.tags.length === 0) {
      return;
    }

    const undoedTag = this.tags.pop();
    if (this.tags.length === 0) {
      // get rid of entire history
      this.undoHistory(this.historyItems.length);
    } else {
      const undoToTag = this.tags[this.tags.length - 1];
      this.undoToHistoryItem(undoToTag.historyItemId);
    }

    this.triggerEvent('tagRemoved', undoedTag.description);
  }

  on(event, cb) {
    if (this._callbacks[event] === undefined) {
      this._callbacks[event] = [];
    }
    this._callbacks[event].push(cb);
  }

  triggerEvent(event, ...args) {
    const cbs = this._callbacks[event] || [];
    let ret;
    for (let cb of cbs) {
      ret = cb(...args);
    }
    return ret;
  }
}

class CommandBus {
  static dispatch(commands) {
    if (!Array.isArray(commands)) {
      commands = [commands];
    }
    for (const command of commands) {
      console.log('dispatch c', JSON.stringify(command));
      CommandHistory.instance().handle(command);
      CommandHandler.handle(command);
    }
  }
}

window.CommandBus = CommandBus;
window.Command = Command;

export { CommandBus, CommandHistory };
