import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ModalView } from './modal-view';

import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';

// TODO: @Koen extract this to some generic video fragment thingy?
import { Pagination } from 'lib/Pagination';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { ObservationLogVideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { getOrdinalPeriod } from './get-ordinal-period';

const ConfirmView = withTranslation('module.dataSync')(
  observer(
    class ConfirmView extends Component {
      constructor() {
        super();

        this.activeItem = observable(0);
        this.dataState = observable('unknown');
      }

      setActiveItem = action((idx) => {
        this.activeItem.set(idx);
      });

      setDataState = action((state) => {
        this.dataState.set(state);
      });

      render() {
        const {
          onSave,
          onCancel,
          onGoBack,
          t,
          observations,
          video,
          period,
          tagName,
          confirmText,
          periodCount,
        } = this.props;

        const { period: _period, periodCount: _periodCount } = getOrdinalPeriod(
          t,
          period,
          periodCount
        );

        return (
          <ModalView
            onCancel={onCancel}
            onSave={this.dataState.get() === 'incorrect' ? onGoBack : onSave}
            saveButtonDisabled={this.dataState.get() === 'unknown'}
            onSaveText={
              this.dataState.get() === 'incorrect' ? t('goBack') : confirmText
            }
          >
            <form className="row">
              <div className="col-12 col-lg-7">
                <div
                  style={{
                    display: 'flex',
                    aspectRatio: '16 / 9',
                    paddingBottom: '12px',
                  }}
                >
                  <ObservationLogVideoPlayer
                    repeat
                    onPlayListItem={action((index) =>
                      this.setActiveItem(index)
                    )}
                    video={video}
                    observations={observations}
                    activePlayListItem={this.activeItem.get()}
                    videoDuration={video.get('content').duration}
                    multiCam={this.props.multiCam}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <p className="mb-4">
                  {t('confirmTagContent', {
                    tagName: tagName,
                    period: _period,
                    periodCount: _periodCount,
                  })}
                </p>
                <div className="form-group">
                  <Pagination
                    items={[...Array(observations.length).keys()].map(
                      (i) => i + 1
                    )}
                    activeItem={this.activeItem}
                    onClick={(idx) => this.setActiveItem(idx)}
                  />
                </div>
                <div className="form-group">
                  {observations[this.activeItem.get()].description}
                </div>
                <div className="form-group">
                  <ButtonGroupSelect
                    options={[
                      { value: 'correct', label: t('confirmTagTimingText') },
                      { value: 'incorrect', label: t('declineTagTimingText') },
                    ]}
                    radio
                    onChange={(state) => this.setDataState(state)}
                  />
                </div>
              </div>
            </form>
          </ModalView>
        );
      }
    }
  )
);

export { ConfirmView };
