import React, { useState } from 'react';
import Select from 'react-select';
import { Modal } from 'lib/Modal';
import { useTranslation } from 'react-i18next';

export const FilterModal = ({
  toggleFilterModal,
  onChange,
  filterConfiguration,
  values,
  numberOfAvailableFilters,
}: {
  toggleFilterModal: () => void;
  filterConfiguration: object[];
  values: { [key: string]: string };
  onChange: (value: object) => void;
}) => {
  const [selected, setSelected] = useState(values);

  const { t } = useTranslation('common.filters');

  const onFilterChange = (key: String, value: Any) => {
    setSelected({
      ...selected,
      [key]: value,
    });
  };

  const emptySelection = Object.fromEntries(
    filterConfiguration.map(config => [config.filterKey, []])
  );

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    onChange(selected);
  };

  return (
    <Modal
      onCloseClick={toggleFilterModal}
      className="learning-line-filter-modal"
    >
      <form className="form learning-line-filter-form" onSubmit={handleSubmit}>
        <div className="modal-header">
          <h5 className="modal-title">Filter</h5>
        </div>

        {filterConfiguration.map((config, i) => {
          return (
            config.filter.options.length > 0 && ( // Show filter only when options are available
              <div className={`form-group`} key={i}>
                <label htmlFor="videos">{config.filter.label}:</label>
                <Select
                  options={config.filter.options}
                  value={config.filter.options.filter(option =>
                    selected[config.filterKey].includes(option.value)
                  )}
                  isMulti
                  onChange={options =>
                    onFilterChange(
                      config.filterKey,
                      options.map(o => o.value)
                    )
                  }
                />
              </div>
            )
          );
        })}

        {numberOfAvailableFilters === 0 && <span>{t('empty')}</span>}
        {numberOfAvailableFilters > 0 && (
          <div className="form-group actions">
            <button type="submit" className="btn btn-primary">
              Apply filter
            </button>
            <button
              type="button"
              className="btn btn-link"
              onClick={toggleFilterModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                onChange(emptySelection);
                toggleFilterModal();
              }}
            >
              Reset
            </button>
          </div>
        )}
      </form>
    </Modal>
  );
};
