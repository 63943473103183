import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import ReactJWPlayer from 'react-jw-player';

import { confirmModal } from 'lib/Confirmation';
import { Modal } from 'lib/Modal';

import VideoCollection from 'domain/Video';
import { Pagination } from 'lib/Pagination';
import { withToggle } from 'lib/hocs/withToggle';

const VideoFragmentsView = withTranslation('module.development')(
  observer(
    class VideoFragmentsView extends Component {
      constructor(props) {
        super(props);

        this.playerReady = this.playerReady.bind(this);
        this.activeItem = observable(0);
      }

      playerReady() {
        this.playerRef = window.jwplayer('playlistPlayer');
        this.playerRef.on(
          'playlistItem',
          action(({ index }) => {
            this.activeItem.set(index);
          })
        );
      }

      render() {
        const { t, title, videoFragments } = this.props;

        const playlistData = videoFragments.map(videoFragment => {
          return {
            file: videoFragment.videoFragmentUrl,
            description: videoFragment.title,
          };
        });
        const currentVideoFragment = videoFragments[this.activeItem.get()];

        const paginationData = videoFragments.map(videoFragment => {
          return { className: `rating-${videoFragment.ratingColor}` };
        });

        const comment = currentVideoFragment.comment || '';

        return (
          <div className="show-development-plan-video-fragment">
            <div className="modal-header">
              <h5 className="modal-title">Video fragmenten</h5>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <p>{title}</p>
                  <div className="dpvf-header">
                    <strong>Fragment {this.activeItem.get() + 1}</strong>
                    <span
                      className={`pagination-item ${
                        paginationData[this.activeItem.get()].className
                      }`}
                    >
                      {this.activeItem.get() + 1}
                    </span>
                    <span>
                      {moment(currentVideoFragment.created).format('LL')}
                    </span>
                  </div>
                  <div className="dpvf-video">
                    <ReactJWPlayer
                      playerId="playlistPlayer"
                      isAutoPlay={true}
                      playerScript="https://cdn.jwplayer.com/libraries/iJqhMiBf.js"
                      playlist={playlistData}
                      customProps={{
                        repeat: true,
                        visualplaylist: false,
                        controls: true,
                        playbackRateControls: true,
                        nextUpDisplay: false,
                        playbackRates: [0.25, 0.5, 1, 2, 4],
                      }}
                      onReady={this.playerReady}
                    />
                  </div>
                  <div>
                    <label>{this.props.t('common:description')}</label>
                    <div className="text-value">{comment}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-8 fieldset-inline">
                      <label>Fragment</label>
                      <Pagination
                        items={paginationData}
                        activeItem={this.activeItem}
                        onClick={n => {
                          this.playerRef.playlistItem(n);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

const VideoFragmentsPlayer = withToggle(
  class VideoFragmentsPlayer extends Component {
    componentDidUpdate(prevProps) {
      if (
        prevProps.videoFragments.length !== this.props.videoFragments.length
      ) {
        if (this.props.videoFragments.length === 0) {
          if (this.props.isToggled) {
            this.props.toggle();
          }
        }
      }
    }

    render() {
      const { children, toggle, isToggled, videoFragments, title } = this.props;

      return (
        <Fragment>
          {children({ toggle, isToggled })}
          {isToggled && videoFragments.length > 0 && (
            <Modal onCloseClick={toggle}>
              <VideoFragmentsView
                videoFragments={videoFragments}
                title={title}
              />
            </Modal>
          )}
        </Fragment>
      );
    }
  }
);

export { VideoFragmentsPlayer };
