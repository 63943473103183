import { Model, PersistentCollection } from './PersistentCollection';
import VideoCollection from './Video';
import { Conversation, ConversationCollection } from './Conversation';

class Playlist extends Model {
  constructor(attributes) {
    super(attributes);

    this.conversationCollection = null;
  }
  //
  // constructor() {
  //     super();
  //
  //     if (!this.isNew)
  //     {
  //         this.videoFragments = new VideoFragmentCollection(this.url() + "/videoFragments");
  //         this.videoFragments.fetch();
  //     }
  // }

  get primaryKey() {
    return 'playlistId';
  }

  get groupId() {
    return this.get('groupId');
  }

  get name() {
    return this.get('name');
  }

  get itemCount() {
    return this.get('videoFragments').length;
  }

  get playlistId() {
    return this.get('playlistId');
  }

  get poster() {
    return this.has('poster') && this.get('poster');
  }

  get videoFragments() {
    return this.get('videoFragments');
  }

  get conversations() {
    if (this.conversationCollection === null) {
      this.conversationCollection = new ConversationCollection(
        `${this.url()}/conversations`
      );
    }
    return this.conversationCollection;
  }

  addVideoFragment(videoId, startTime, endTime, description) {
    return this.rpc('addVideoFragment', {
      videoId,
      startTime,
      endTime,
      description,
    }).then(async (videoFragmentId) => {
      await this.fetch();
      return videoFragmentId;
    });
  }

  canEdit() {
    const metadata = this.has('_metadata') && this.get('_metadata');
    if (metadata) {
      return metadata.privilegesV2['playlist:edit'].ok;
    } else {
      return false;
    }
  }

  removeVideoFragment(videoFragmentId) {
    return this.rpc('removeVideoFragment', { videoFragmentId }).then(() => {
      return this.fetch();
    });
  }

  editVideoFragment(videoFragment) {
    const videoFragments = this.videoFragments.toJS();
    const index = videoFragments.findIndex((v) => {
      return (
        parseInt(v.videoFragmentId) === parseInt(videoFragment.videoFragmentId) // id can be 1-copy.
      );
    });
    if (videoFragment.videoFragmentId?.indexOf('copy') !== -1) {
      // Do not send a videoFragmentId to backend to create a new fragment.
      delete videoFragment.videoFragmentId;
      videoFragments.splice(index + 1, 0, videoFragment);
    } else {
      videoFragments[index] = videoFragment;
    }
    return this.setVideoFragments(videoFragments);
  }

  copyVideoFragment(videoFragmentId) {
    const videoFragments = this.videoFragments.toJS();

    const index = videoFragments.findIndex((v) => {
      return v.videoFragmentId === videoFragmentId;
    });
    const newVideoFragment = structuredClone(videoFragments[index]);
    newVideoFragment.videoFragmentId += '-copy';
    newVideoFragment.description += ' copy';
    newVideoFragment.getVideo = () => {
      return VideoCollection.getOrPlaceholder(newVideoFragment.videoId);
    };

    return {
      newVideoFragment,
      newVideoFragments: videoFragments.splice(index, 0, newVideoFragment),
    };
  }

  setVideoFragments(videoFragments) {
    return this.rpc('setVideoFragments', { videoFragments }).then(() => {
      return this.fetch();
    });
  }

  changeNameAndDescription(name, description) {
    return this.rpc('changeNameAndDescription', { name, description }).then(
      () => {
        return this.fetch();
      }
    );
  }

  getVideoFragments() {
    return this.videoFragments.map((videoFragment) => {
      return {
        ...videoFragment,
        getVideo() {
          return VideoCollection.getOrPlaceholder(videoFragment.videoId);
        },
      };
    });
  }
}

class PlaylistCollection extends PersistentCollection {
  url() {
    return '/playlists';
  }

  model() {
    return Playlist;
  }

  toGroups(t) {
    const groups = [
      {
        groupId: 'own',
        groupName: t ? t('module.playlist:groupNameOwn') : 'own',
        playlists: [],
      },
      {
        groupId: 'other',
        groupName: t ? t('module.playlist:groupNameOther') : 'other',
        playlists: [],
      },
      {
        groupId: 'generated-own',
        groupName: t
          ? t('module.playlist:groupNameGeneratedOther')
          : 'generated-own',
        playlists: [],
      },
      {
        groupId: 'generated-other',
        groupName: t
          ? t('module.playlist:groupNameGeneratedOther')
          : 'generated-other',
        playlists: [],
      },
    ];
    for (const playlist of this.toArray()) {
      for (const group of groups) {
        if (group.groupId === playlist.groupId) {
          if (playlist.videoFragments.length > 0) {
            group.playlists.push(playlist);
          }
          break;
        }
      }
    }
    return groups.filter(({ playlists }) => playlists.length > 0);
  }
}

console.log('init playlist collection');
const playlistCollection = new PlaylistCollection();
window.playlistCollection = playlistCollection;

export { playlistCollection };
