import { Model } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';

import SHIELD_IMAGE from '../img/icons/club_placeholder.svg';

class Team extends Model {
  get primaryKey() {
    return 'teamId';
  }

  get name() {
    return this.get('name');
  }

  get teamId() {
    return this.get('teamId');
  }

  get shareState() {
    return this.get('shareState');
  }
}

class TeamCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Team;
  }
}

class SharingGroup extends Model {
  constructor(attributes) {
    super(attributes);

    this.teamCollection = null;
  }

  get primaryKey() {
    return 'sharingGroupId';
  }

  get sharingGroupId() {
    return this.get('sharingGroupId');
  }

  get name() {
    return this.get('name');
  }

  get description() {
    return this.get('description');
  }

  get isCurrentSeason() {
    return this.get('isCurrentSeason');
  }

  get seasonId() {
    return this.get('attributes').seasonId;
  }

  get teams() {
    if (this.teamCollection === null) {
      this.teamCollection = new TeamCollection(`${this.url()}/teams`);
    }
    return this.teamCollection;
  }

  requestShare(roleName, teamId) {
    return this.rpc('requestShare', {
      roleName,
      teamId,
    });
  }
}

class SharingGroupCollection extends PersistentCollection {
  url() {
    return '/sharingGroups';
  }

  model() {
    return SharingGroup;
  }
}

const sharingGroupCollection = new SharingGroupCollection();
window.sharingGroupCollection = sharingGroupCollection;

export { sharingGroupCollection };
