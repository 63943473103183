class KeyBoardInputHelper {
  constructor() {
    this.originalOnKeyDownHandler = document.body.onkeydown;
    this.newOnKeyHandler = e => {
      e.stopImmediatePropagation();
    };
  }

  disable() {
    document.body.onkeydown = this.newOnKeyHandler;
  }

  enable() {
    document.body.onkeydown = this.originalOnKeyDownHandler;
  }
}

export { KeyBoardInputHelper };
