import React, { Component, Fragment } from 'react';

import { Progress } from 'reactstrap';
// import { Checkbox } from 'lib/Checkbox';

import { observer } from 'mobx-react';

const getHighestActivePhaseIdx = (phases, developmentPlan) => {
  for (let i = phases.length - 1; i > 0; i--) {
    if (developmentPlan.phaseProgress(phases[i]) > 0) {
      return i;
    }
  }
  return 0;
};

const LearningLineDetails = observer(
  ({ t, learningLine, developmentPlan, draftGrowthAmbition }) => {
    // const highestActivePhaseIdx = getHighestActivePhaseIdx(
    //   learningLine.phases,
    //   developmentPlan
    // );
    return (
      <div className="learning-line">
        {learningLine.phases.map((phase, phaseIdx) => {
          return (
            <div key={phase.phaseId} className="learning-phase">
              <div className="learning-phase-progress">
                <div className="learning-phase-progress-title">
                  {phase.name}
                </div>
                <Progress value={developmentPlan.phaseProgress(phase)} />
              </div>
              {phase.skills.length > 0 && (
                <div className="card learning-indicators-group">
                  <div className="card-body">
                    <h4>{t('skills')}</h4>
                    {phase.skills
                      .map(({ skillId, name }) => ({
                        name,
                        indicatorId: skillId,
                      }))
                      .map(indicator => (
                        <LearningIndicatorMarker
                          key={indicator.indicatorId}
                          indicator={indicator}
                          draftGrowthAmbition={draftGrowthAmbition}
                          disabled={developmentPlan.isIndicatorMastered(
                            indicator.indicatorId
                          )}
                        />
                      ))}
                  </div>
                </div>
              )}
              {phase.practices.length > 0 && (
                <div className="card learning-indicators-group">
                  <div className="card-body">
                    <h4>{t('practices')}</h4>
                    {phase.practices
                      .map(({ practiceId, name }) => ({
                        name,
                        indicatorId: practiceId,
                      }))
                      .map(indicator => (
                        <LearningIndicatorMarker
                          key={indicator.indicatorId}
                          indicator={indicator}
                          draftGrowthAmbition={draftGrowthAmbition}
                          disabled={developmentPlan.isIndicatorMastered(
                            indicator.indicatorId
                          )}
                        />
                      ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
);

const LearningIndicatorMarker = observer(
  ({ indicator, draftGrowthAmbition, disabled = false }) => (
    <div className={`learning-indicator marker ${disabled ? 'disabled' : ''}`}>
      <Checkbox
        onChange={isChecked => {
          if (isChecked) {
            draftGrowthAmbition.addGoal(indicator.indicatorId);
          } else {
            draftGrowthAmbition.removeGoal(indicator.indicatorId);
          }
        }}
        checked={draftGrowthAmbition.isGoal(indicator.indicatorId)}
      >
        {indicator.name}
      </Checkbox>
    </div>
  )
);

class Checkbox extends Component {
  // Uses label for styling, therefore use "active" instead of "checked"
  checkedStyleClass = () => (this.props.checked ? 'active' : '');

  render() {
    return (
      <div
        className={`clickable label checkbox ${this.checkedStyleClass()}`}
        {...this.props}
        onClick={() => this.props.onChange(!this.props.checked)}
      >
        {this.props.children}
        <i className="i-add" />
        {/* TODO: update icon */}
      </div>
    );
  }
}

export { LearningLineDetails };
