import { Model } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';
import videoCollection from './Video';

class ExerciseTemplate extends Model {
  get primaryKey() {
    return 'exerciseTemplateId';
  }

  get exerciseTemplateId() {
    return this.get('exerciseId');
  }

  get contentBlocks() {
    return this.get('contentBlocks');
  }
}

class ExerciseTemplateCollection extends PersistentCollection {
  url() {
    return '/exerciseTemplates';
  }

  model() {
    return ExerciseTemplate;
  }
}

console.log('init exerciseTemplate collection');
const exerciseTemplateCollection = new ExerciseTemplateCollection();
window.exerciseTemplateCollection = exerciseTemplateCollection;

exerciseTemplateCollection.add([
  {
    exerciseTemplateId: 'exercise-template-sv',
    contentBlocks: [
      {
        description: {
          path: 'instruction.description',
          type: 'VARIABLE',
        },
        title: {
          value: 'Opdracht',
          type: 'CONSTANT',
        },
        type: 'title-description',
      },
      {
        type: 'title-description',
        title: {
          value: 'Impliciete oefening',
          type: 'CONSTANT',
        },
        description: {
          type: 'VARIABLE',
          path: 'implicit.description',
        },
      },
      {
        type: 'title-description',
        title: {
          type: 'CONSTANT',
          value: 'Materiaal',
        },
        description: {
          type: 'VARIABLE',
          path: 'material.description',
        },
      },
      {
        tabs: [
          {
            content: {
              path: '3l.loopt_het.description',
              type: 'VARIABLE',
            },
            title: {
              type: 'CONSTANT',
              value: 'Loopt het?',
            },
          },
          {
            title: {
              type: 'CONSTANT',
              value: 'Lukt het?',
            },
            content: {
              path: '3l.lukt_het.description',
              type: 'VARIABLE',
            },
          },
          {
            title: {
              type: 'CONSTANT',
              value: 'Leert het?',
            },
            content: {
              path: '3l.leert_het.description',
              type: 'VARIABLE',
            },
          },
        ],
        type: 'tabs',
      },
    ],
  },
  {
    exerciseTemplateId: 'exercise-template-sv-skill',
    contentBlocks: [
      {
        description: {
          path: 'instruction.description',
          type: 'VARIABLE',
        },
        title: {
          value: 'Opdracht',
          type: 'CONSTANT',
        },
        type: 'title-description',
      },
      {
        type: 'title-description',
        title: {
          type: 'CONSTANT',
          value: 'Materiaal',
        },
        description: {
          type: 'VARIABLE',
          path: 'material.description',
        },
      },
      {
        tabs: [
          {
            content: {
              path: '3l.loopt_het.description',
              type: 'VARIABLE',
            },
            title: {
              type: 'CONSTANT',
              value: 'Loopt het?',
            },
          },
          {
            title: {
              type: 'CONSTANT',
              value: 'Lukt het?',
            },
            content: {
              path: '3l.lukt_het.description',
              type: 'VARIABLE',
            },
          },
        ],
        type: 'tabs',
      },
    ],
  },
  {
    exerciseTemplateId: 'exercise-template-sv-warming-up',
    contentBlocks: [
      {
        description: {
          path: 'organisation.description',
          type: 'VARIABLE',
        },
        title: {
          value: 'Organisatie',
          type: 'CONSTANT',
        },
        type: 'title-description',
      },
      {
        description: {
          path: 'exercise.description',
          type: 'VARIABLE',
        },
        title: {
          value: 'Oefening',
          type: 'CONSTANT',
        },
        type: 'title-description',
      },
      {
        description: {
          path: 'points_of_attention.description',
          type: 'VARIABLE',
        },
        title: {
          value: 'Aandachtspunten',
          type: 'CONSTANT',
        },
        type: 'title-description',
      },
      {
        description: {
          path: 'goal.description',
          type: 'VARIABLE',
        },
        title: {
          value: 'Doel',
          type: 'CONSTANT',
        },
        type: 'title-description',
      },
    ],
  },
]);

export { exerciseTemplateCollection };
