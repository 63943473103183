import React, { createContext, useContext, useState } from 'react';
import { api } from '../../../utils/ApiClient';
import { Session } from 'domain/Session';
import { isDev } from '../../../lib/dev';
import { useQuery, useQueryClient } from 'react-query';

const AssignmentContext = createContext(null);

export const useAssignmentContext = () => {
  return useContext(AssignmentContext);
};

interface AssignmentVersion {
  state: string;
  created: string;
  updated: string;
  versionId: number;
  archived: boolean;
  submissionTRN: string;
  assessorUserId: string;
  submitterUserId: string;
}

interface Assignment {
  tenantId: string;
  assignmentId: string;
  taskTRN: string;
  assigneeUserId: string;
  name: string;
  state: string;
  versions: AssignmentVersion[];
  created: string;
  updated: string;
}

export const AssignmentProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isFetching, data: assignments } = useQuery(
    ['assignments'],
    () => fetchAssignments(),
    {
      refetchOnMount: false,
      placeholderData: [],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const { data: assignmentFiles } = useQuery(
    ['assignments', 'files'],
    () => fetchAssignmentFileList(),
    {
      refetchOnMount: false,
      placeholderData: [],
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  const post = async (url, data, headers = {}) => {
    // Prevent duplicate submissions
    if (!isSubmitting) {
      setIsSubmitting(true);
      let response;
      response = await api
        .post(url, data, headers)
        .then(() => {
          return response;
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
      setIsSubmitting(false);
      return response;
    }
  };

  const addVersion = async (data, assignment: Assignment) => {
    if (!assignment) {
      setIsSubmitting(true);
      assignment = await createAssignment(data.taskTRN, data.name);
      setIsSubmitting(false);
    }

    if (!assignment || !assignment.assignmentId) {
      console.log(Error('No Assignment'));
      return new Error('No Assignment');
    }
    if (!data || (!data.submissionTRN && !data.file)) {
      console.log(Error('no data or VersionTRN not set'));
      return new Error('no data or VersionTRN not set');
    }

    let _response;
    if (data.file) {
      const body = new FormData();
      body.append('comment', data?.comment);
      body.append('file', data.file);
      _response = await post(
        `/assignments/${assignment.assignmentId}/submitFileVersion`,
        body,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    } else {
      _response = await post(
        `/assignments/${assignment.assignmentId}/submitVersion`,
        {
          submissionTRN: data.submissionTRN,
          comment: data?.comment,
        }
      );
    }
    isDev() && console.log('addVersion done');
    refresh();
    return _response;
  };

  const createAssignment = async (taskTRN, name, description = '') => {
    isDev() && console.log('no assignment for task, creating..');
    const data = {
      taskTRN: taskTRN,
      name: name,
      description: description,
    };
    let _assignment;

    // Use api.post here because we do not want to mess with state of context here.
    // The function calling createAssignment provides state manegement.
    _assignment = await api
      .post('/assignments', data)
      .then((response) => response)
      .catch((e) => {
        console.log(e);
        return Error('cannot create assignment');
      });
    if (_assignment?.data) {
      return _assignment?.data;
    } else {
      return Error('cannot create assignment');
    }
  };

  const fetchAssignments = async () => {
    const _assignments = await api.get('assignments');
    return _assignments.data;
  };

  // const fetchAssignment = async (assignmentId) => {
  //   const _assignments = await api.get(`assignments/${assignmentId}`);
  //   return _assignments.data;
  // };

  const getAssignmentByTaskID = (taskId) => {
    // Return most recent updated assignment of taskId in case of doubles having been made (which should not be possible)
    const taskAssignments = assignments?.filter((assignment) =>
      assignment.taskTRN.includes(taskId)
    );
    // It might happen due to a bug in the backend that we end up with multiple assignments for the same
    // task. Always pick the last one.
    return (
      taskAssignments.sort((a, b) => b.updated.localeCompare(a.updated))[0] ??
      null
    );
  };

  const fetchAssignmentFileList = async () => {
    const files = await api.get('/assignments-files');
    return files.data;
  };

  const acceptVersion = async (assignmentId, comment, file) => {
    if (!file) {
      await post(`/assignments/${assignmentId}/acceptLastVersion`, {
        comment: comment,
      });
    } else {
      await sendFormData(
        `/assignments/${assignmentId}/acceptLastVersion`,
        comment,
        file
      );
    }
    refresh();
  };

  const rejectVersion = async (assignmentId, comment, file) => {
    if (!file) {
      await post(`/assignments/${assignmentId}/rejectLastVersion`, {
        comment: comment,
      });
    } else {
      await sendFormData(
        `/assignments/${assignmentId}/rejectLastVersion`,
        comment,
        file
      );
    }
    refresh();
  };
  const requestChangesVersion = async (assignmentId, comment, file) => {
    if (!file) {
      await post(`/assignments/${assignmentId}/requestChangesLastVersion`, {
        comment: comment,
      });
    } else {
      await sendFormData(
        `/assignments/${assignmentId}/requestChangesLastVersion`,
        comment,
        file
      );
    }
    refresh();
  };

  const sendFormData = async (url, comment, file) => {
    const body = new FormData();
    body.append('comment', comment);
    body.append('file', file);
    const response = await post(url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  };

  const retractVersion = async (assignmentId, comment) => {
    const _response = await post(
      `/assignments/${assignmentId}/retractLastVersion`,
      {
        comment: comment,
      }
    );
    return _response;
  };

  const getCompletionStatus = (tasks) => {
    // const amountToComplete = tasks.filter((t) => t.taskType !== 'read').length;
    const amountToComplete = tasks.length;
    let completedTasks = [];
    tasks.forEach((task) => {
      const assignment = getAssignmentByTaskID(task.taskId);
      if (
        task.taskType === 'read' &&
        ['submitted', 'accepted'].indexOf(assignment?.state) !== -1
      ) {
        completedTasks.push(assignment); // Do not count for Completion?
      }
      if (task.taskType !== 'read' && assignment?.state === 'accepted') {
        completedTasks.push(assignment);
      }
    });

    return [completedTasks.length, amountToComplete];
  };

  const getCompletionStatusText = (tasks) => {
    const completion = getCompletionStatus(tasks);
    return `${completion[0]} / ${completion[1]}`;
  };

  const getCompletionStatusBoolean = (tasks) => {
    const completion = getCompletionStatus(tasks);
    return completion[0] === completion[1];
  };

  const getCompletionStatusPercentage = (tasks) => {
    const status = getCompletionStatus(tasks);
    return (status[0] / status[1]) * 100;
  };

  const getActionStatus = (tasks) => {
    // Return which action are needed, in case of submitted, teacher should have a notification of this one.
    const currentSession = Session.current();
    // Todo: implement role check.
    const isTrainee = currentSession.isFeatureAvailable('submitVersion');
    let actionableArray = [];
    tasks
      .filter((t) => t.taskType !== 'read')
      .forEach((task) => {
        const assignment = getAssignmentByTaskID(task.taskId);
        actionableArray.push(assignment?.state);
      });
    if (!isTrainee && actionableArray.includes('submitted')) {
      // Submitted should not be able to co-exist with other states.
      return 'submitted';
    }
    if (
      isTrainee &&
      actionableArray.includes('rejected') &&
      !actionableArray.includes('accepted')
    ) {
      return 'rejected';
    }
    if (
      isTrainee &&
      actionableArray.includes('changes_requested') &&
      !actionableArray.includes('accepted')
    ) {
      return 'changes_requested';
    }
    return null;
  };
  const refresh = () => queryClient.invalidateQueries(['assignments']);

  const value = {
    assignments,
    assignmentFiles,
    addVersion,
    getAssignmentByTaskID,
    fetchAssignmentFileList,
    acceptVersion,
    rejectVersion,
    requestChangesVersion,
    retractVersion,
    getCompletionStatus,
    getCompletionStatusText,
    getCompletionStatusBoolean,
    getCompletionStatusPercentage,
    getActionStatus,
    isSubmitting,
    isFetching,
    refresh,
  };

  return (
    <AssignmentContext.Provider value={value}>
      {children}
    </AssignmentContext.Provider>
  );
};
