import Hammer from 'hammerjs';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

var hammer = Hammer;

var _DEBUG_ = false;

class ReactPlayerDragZoomHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomState: {
        imgWidth: null,
        imgHeight: null,
        curWidth: 0,
        curHeight: 0,
        x: 0,
        lastX: 0,
        y: 0,
        lastY: 0,
        scale: 1,
        lastScale: null,
        viewportWidth: null,
        viewportHeight: null,
        img: this.img(),
        container: this.container(),
        playState: this.props.player.getState(),
        playbackRate: this.props.player.getPlaybackRate(),
      },
      storeContainer: null,
    };
    this.MIN_SCALE = 1; // 1=scaling when first loaded
    this.MAX_SCALE = 12;

    this.zoomAmount = 1.25;

    this.updateZoom = this.updateZoom.bind(this);
    this.rawCenter = this.rawCenter.bind(this);
    this.restrictScale = this.restrictScale.bind(this);
    this.zoom = this.zoom.bind(this);
    this.zoomAround = this.zoomAround.bind(this);
    this.updateLastScale = this.updateLastScale.bind(this);
    this.updateLastPos = this.updateLastPos.bind(this);
    this.translate = this.translate.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.updateConstraints = this.updateConstraints.bind(this);
    this.reset = this.reset.bind(this);
    this.onFullscreenChange = this.onFullscreenChange.bind(this);
  }

  componentWillUnmount() {
    // Remove all scaling and zooming.
    // Destroy created event listeners.
    this.reset();
    hammer.destroy();
  }

  updateZoom(newValue, callback) {
    // Update state with new values
    // Optional: Run callback
    this.setState(
      (state) => {
        return { zoomState: { ...state.zoomState, ...newValue } };
      },
      typeof callback !== 'undefined' ? callback() : () => {}
    );
  }

  componentDidMount() {
    this.bindEvents();
  }

  disableImgEventHandlers(img) {
    // We need to disable the following event handlers so that the browser doesn't try to
    // automatically handle our image drag gestures.
    var events = [
      'onclick',
      'onmousedown',
      'onmousemove',
      'onmouseout',
      'onmouseover',
      'onmouseup',
      'ondblclick',
      'onfocus',
      'onblur',
    ];

    events.forEach(function (event) {
      img[event] = function () {
        return false;
      };
    });
  }

  absolutePosition(el) {
    // Traverse the DOM to calculate the absolute position of an element
    var x = 0,
      y = 0;

    while (el !== null) {
      x += el.offsetLeft;
      y += el.offsetTop;
      el = el.offsetParent;
    }

    return { x: x, y: y };
  }

  restrictScale(scale) {
    // Add bounds to zooming actions
    if (scale < this.MIN_SCALE) {
      scale = this.MIN_SCALE;
    } else if (scale > this.MAX_SCALE) {
      scale = this.MAX_SCALE;
    }
    return scale;
  }

  restrictRawPos(pos, viewportDim, imgDim, newScale) {
    if (pos < viewportDim / newScale - imgDim) {
      // too far left/up?
      pos = viewportDim / newScale - imgDim;
    } else if (pos > 0) {
      // too far right/down?
      pos = 0;
    }
    return pos;
  }

  rawCenter(event) {
    var pos = this.absolutePosition(this.img());
    // We need to account for the scroll position
    var scrollLeft = window.pageXOffset
      ? window.pageXOffset
      : document.body.scrollLeft;
    var scrollTop = window.pageYOffset
      ? window.pageYOffset
      : document.body.scrollTop;

    var zoomX =
      -this.state.zoomState.x +
      (event.center.x - pos.x + scrollLeft) / this.state.zoomState.scale;
    var zoomY =
      -this.state.zoomState.y +
      (event.center.y - pos.y + scrollTop) / this.state.zoomState.scale;
    return { x: zoomX, y: zoomY };
  }

  zoom(scaleBy) {
    var scale = this.restrictScale(this.state.zoomState.lastScale * scaleBy);

    var curWidth = this.state.zoomState.imgWidth * scale;
    var curHeight = this.state.zoomState.imgHeight * scale;

    this.updateZoom({ curWidth: curWidth, curHeight: curHeight });

    this.img().style.width = Math.ceil(curWidth) + 'px';
    this.img().style.height = Math.ceil(curHeight) + 'px';

    // Adjust margins to make sure that we aren't out of bounds
    this.updateZoom({ scale: scale });
    return scale;
  }

  translate(deltaX, deltaY, newScale) {
    // We restrict to the min of the viewport width/height or current width/height as the
    // current width/height may be smaller than the viewport width/height
    var newX = this.restrictRawPos(
      this.state.zoomState.lastX + deltaX / newScale,
      Math.min(
        this.state.zoomState.viewportWidth,
        this.state.zoomState.curWidth
      ),
      this.state.zoomState.imgWidth,
      newScale
    );

    this.img().style.marginLeft = Math.ceil(newX * newScale) + 'px';

    var newY = this.restrictRawPos(
      this.state.zoomState.lastY + deltaY / newScale,
      Math.min(
        this.state.zoomState.viewportHeight,
        this.state.zoomState.curHeight
      ),
      this.state.zoomState.imgHeight,
      newScale
    );
    this.img().style.marginTop = Math.ceil(newY * newScale) + 'px';

    this.updateZoom({ x: newX, y: newY });

    return { x: newX, y: newY };
  }

  updateLastPos(deltaX, deltaY) {
    this.updateZoom({
      lastX: deltaX ?? this.state.zoomState.x,
      lastY: deltaY ?? this.state.zoomState.y,
    });
  }

  updateLastScale(value) {
    this.updateZoom({ lastScale: value ?? this.state.zoomState.scale });
  }

  zoomAround(scaleBy, rawZoomX, rawZoomY, doNotUpdateLast) {
    // Zoom
    let newScale = this.zoom(scaleBy);

    // New raw center of viewport
    var rawCenterX =
      -this.state.zoomState.x +
      Math.min(
        this.state.zoomState.viewportWidth,
        this.state.zoomState.curWidth
      ) /
        2 /
        newScale;
    var rawCenterY =
      -this.state.zoomState.y +
      Math.min(
        this.state.zoomState.viewportHeight,
        this.state.zoomState.curHeight
      ) /
        2 /
        newScale;

    // Delta
    var deltaX = (rawCenterX - rawZoomX) * newScale;
    var deltaY = (rawCenterY - rawZoomY) * newScale;

    // Translate back to zoom center
    let translate = this.translate(deltaX, deltaY, newScale);

    if (!doNotUpdateLast) {
      this.updateLastScale(newScale);
      this.updateLastPos(translate.x, translate.y);
    }
  }

  zoomCenter(scaleBy) {
    // Center of viewport
    var zoomX =
      -this.state.zoomState.x +
      Math.min(
        this.state.zoomState.viewportWidth,
        this.state.zoomState.curWidth
      ) /
        2 /
        this.state.zoomState.scale;
    var zoomY =
      -this.state.zoomState.y +
      Math.min(
        this.state.zoomState.viewportHeight,
        this.state.zoomState.curHeight
      ) /
        2 /
        this.state.zoomState.scale;

    this.zoomAround(scaleBy, zoomX, zoomY, false);
  }

  zoomIn() {
    this.zoomCenter(this.zoomAmount);
  }

  zoomOut() {
    this.zoomCenter(1 / this.zoomAmount);
  }

  reset() {
    if (this.img()) {
      this.img().removeAttribute('style');
      this.updateZoom(
        {
          imgWidth: this.img().offsetWidth,
          imgHeight: this.img().offsetHeight,
          viewportWidth: this.img().parentElement.offsetWidth,
          viewportHeight: this.img().parentElement.offsetHeight,
          scale: 1,
          curWidth:
            (this.img().offsetWidth * this.img().offsetWidth) /
            this.img().parentElement.offsetWidth,
          curHeight:
            (this.img().offsetHeight * this.img().offsetWidth) /
            this.img().parentElement.offsetWidth,
          lastScale:
            this.img().offsetWidth / this.img().parentElement.offsetWidth,
          x: 0,
          y: 0,
          lastX: 0,
          lastY: 0,
        },
        () => {
          this.zoom(0);
        }
      );
    }
  }

  img() {
    return document
      .getElementById(this.props.player.id)
      .getElementsByClassName('jw-video')[0];
  }

  container() {
    return this.img().parentElement;
  }

  updateConstraints(img, callback = () => {}) {
    this.updateZoom(
      {
        imgWidth: img.offsetWidth,
        imgHeight: img.offsetHeight,
        viewportWidth: img.parentElement.offsetWidth,
        viewportHeight: img.parentElement.offsetHeight,
        scale: img.offsetWidth / img.parentElement.offsetWidth,
        curWidth:
          (img.offsetWidth * img.offsetWidth) / img.parentElement.offsetWidth,
        curHeight:
          (img.offsetHeight * img.offsetWidth) / img.parentElement.offsetWidth,
        lastScale: img.offsetWidth / img.parentElement.offsetWidth,
      },
      callback()
    );
  }

  onFullscreenChange() {
    // For now reset and not recalculate.
    this.reset();
  }

  updatePlayerState() {
    setTimeout(() => {
      this.updateZoom({
        playState: this.props.player.getState(),
        playbackRate: this.props.player.getPlaybackRate(),
      });
    }, 100);
  }
  hasNextItem() {
    return (
      this.props.player.getPlaylist().length > 1 &&
      this.props.player.getPlaylistIndex() <
        this.props.player.getPlaylist().length - 1
    );
  }
  hasPrevItem() {
    return (
      this.props.player.getPlaylist().length > 1 &&
      this.props.player.getPlaylistIndex() > 0
    );
  }

  bindEvents() {
    // HammerJS fires "pinch" and "pan" events that are cumulative in nature and not
    // deltas. Therefore, we need to store the "last" values of scale, x and y so that we can
    // adjust the UI accordingly. It isn't until the "pinchend" and "panend" events are received
    // that we can set the "last" values.

    // Our "raw" coordinates are not scaled. This allows us to only have to modify our stored
    // coordinates when the UI is updated. It also simplifies our calculations as these
    // coordinates are without respect to the current scale.

    var pinchCenter = null;
    var pinchCenterOffset;

    var rawCenter = this.rawCenter;
    var restrictScale = this.restrictScale;
    var translate = this.translate;
    var updateLastPos = this.updateLastPos;
    var zoomAround = this.zoomAround;
    var updateLastScale = this.updateLastScale;
    var updateconstraints = this.updateConstraints;
    var onFullscreenChange = this.onFullscreenChange;
    var zoomAmount = this.zoomAmount;

    var img = () => this.img();
    var container = () => this.container();

    var zoomState = () => {
      return this.state.zoomState;
    };

    this.disableImgEventHandlers(img());
    this.updateConstraints(img());

    hammer = new Hammer(this.dragZone, {
      domEvents: true,
    });

    hammer.get('pinch').set({
      enable: true,
    });

    hammer.on('pan', function (e) {
      translate(e.deltaX, e.deltaY, zoomState().scale);
    });

    hammer.on('panend', function (e) {
      updateLastPos();
    });

    hammer.on('pinch', function (e) {
      // We only calculate the pinch center on the first pinch event as we want the center to
      // stay consistent during the entire pinch
      if (pinchCenter === null) {
        pinchCenter = rawCenter(e);
        var offsetX =
          pinchCenter.x * zoomState().scale -
          (-zoomState().x * zoomState().scale +
            Math.min(zoomState().viewportWidth, zoomState().curWidth) / 2);
        var offsetY =
          pinchCenter.y * zoomState().scale -
          (-zoomState().y * zoomState().scale +
            Math.min(zoomState().viewportHeight, zoomState().curHeight) / 2);
        pinchCenterOffset = { x: offsetX, y: offsetY };
      }

      // When the user pinch zooms, she/he expects the pinch center to remain in the same
      // relative location of the screen. To achieve this, the raw zoom center is calculated by
      // first storing the pinch center and the scaled offset to the current center of the
      // image. The new scale is then used to calculate the zoom center. This has the effect of
      // actually translating the zoom center on each pinch zoom event.
      var newScale = restrictScale(zoomState().scale * e.scale);
      var zoomX = pinchCenter.x * newScale - pinchCenterOffset.x;
      var zoomY = pinchCenter.y * newScale - pinchCenterOffset.y;
      var zoomCenter = { x: zoomX / newScale, y: zoomY / newScale };
      zoomAround(e.scale, zoomCenter.x, zoomCenter.y, true);
      // pinchCenter = null;
    });

    hammer.on('pinchend', function (e) {
      updateLastScale();
      updateLastPos();
      pinchCenter = null;
    });

    hammer.on('doubletap', function (e) {
      var c = rawCenter(e);
      zoomAround(zoomAmount, c.x, c.y);
    });

    this.props.player.on('fullscreen', () => {
      onFullscreenChange();
    });

    this.props.player.on('resize', () => this.onFullscreenChange());

    this.props.player.on('playlistItem nextAutoAdvance', (e) => {
      this.onFullscreenChange();
    });

    this.props.player.on('play pause playbackRateChanged', (e) => {
      this.updatePlayerState();
    });
  }

  render() {
    return (
      <>
        {ReactDOM.createPortal(
          <div className={'drag__wrapper'}>
            <div
              className={'drag__zone'}
              ref={(el) => (this.dragZone = el)}
            ></div>
            <div className="form-group closeButton">
              <button
                className="zoombutton closeButton"
                onClick={this.props.onClose}
              >
                <i className="i-cross i-xs i-light"></i>
              </button>
            </div>
            <div className={'zoomcontrols'}>
              <div className="form-group">
                <button className="zoombutton" onClick={() => this.zoomIn()}>
                  <i className="i-zoomin i-sm i-light"></i>
                </button>
                <button className="zoombutton" onClick={() => this.zoomOut()}>
                  <i className="i-zoomout i-sm i-light"></i>
                </button>

                <button className="zoombutton" onClick={() => this.reset()}>
                  <i className="i-reset i-sm i-light"></i>
                </button>
              </div>
              <div className="form-group">
                {[
                  { value: 0.25, label: '.25x' },
                  { value: 0.5, label: '.5x' },
                  { value: 1, label: '1x' },
                ].map((button) => {
                  return (
                    <button
                      className={`zoombutton ${
                        this.state.zoomState.playbackRate === button.value
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => {
                        this.props.player.setPlaybackRate(button.value);
                        this.updatePlayerState();
                      }}
                    >
                      {button.label}
                    </button>
                  );
                })}
              </div>
              <div className="form-group">
                <button
                  className={`zoombutton ${
                    this.hasPrevItem() ? '' : 'disabled'
                  }`}
                  onClick={() => {
                    if (this.hasPrevItem()) {
                      this.props.player.playlistPrev();
                      this.updatePlayerState();
                    }
                  }}
                >
                  <i className="i-playlistprev i-light i-xs"></i>
                </button>
                <button
                  className="zoombutton"
                  onClick={() => {
                    this.state.zoomState.playState === 'paused'
                      ? this.props.player.play()
                      : this.props.player.pause();
                    this.updatePlayerState();
                  }}
                >
                  {this.state.zoomState.playState === 'paused' ? (
                    <i className={'i-play i-light i-xs'} />
                  ) : (
                    <i className={'i-pause i-light i-xs'} />
                  )}
                </button>
                <button
                  className={`zoombutton ${
                    this.hasNextItem() ? '' : 'disabled'
                  }`}
                  onClick={() => {
                    if (this.hasNextItem()) {
                      this.props.player.next();
                      this.updatePlayerState();
                    }
                  }}
                >
                  <i className="i-playlistnext i-light i-xs"></i>
                </button>
              </div>
              {_DEBUG_ && (
                <table>
                  {Object.entries(this.state.zoomState).map(([k, v]) => {
                    return (
                      <tr>
                        <th>{`${k}`}</th>
                        <td>{`${v}`}</td>
                      </tr>
                    );
                  })}
                </table>
              )}
            </div>
          </div>,
          this.props.player.getContainer()
        )}
      </>
    );
  }
}

export { ReactPlayerDragZoomHandler };
