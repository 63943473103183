export function dragToScroll(element) {
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  if (!element) {
    return null;
  }

  const mouseDownHandler = function(event) {
    pos = {
      // The current scroll
      left: element.scrollLeft,
      top: element.scrollTop,
      // Get the current mouse position
      x: event.clientX,
      y: event.clientY,
    };
    // Change the cursor and prevent user from selecting the text
    element.style.cursor = 'grabbing';
    element.style.userSelect = 'none';

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };
  const mouseMoveHandler = function(event) {
    // How far the mouse has been moved
    const dx = event.clientX - pos.x;
    const dy = event.clientY - pos.y;

    // Scroll the element
    element.scrollTop = pos.top - dy;
    element.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    element.style.cursor = 'grab';
    element.style.removeProperty('user-select');
  };

  element.addEventListener('mousedown', mouseDownHandler);
}
