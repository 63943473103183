import { Model, PersistentCollection } from './PersistentCollection';

import { AuthAwareAdapterProxy } from '../utils/AuthAwareAdapterProxy';
import moment from 'moment';

class User extends Model {
  get primaryKey() {
    return 'userId';
  }

  loaded() {
    return this.has('currentMembership');
  }

  setProfilePicture(profilePicture, refresh = true) {
    return this.rpc('setProfilePicture', { profilePicture }).then(
      () => refresh && this.fetch()
    );
  }

  setRealName(firstName, lastName, refresh = true) {
    return this.rpc('setRealName', { firstName, lastName }).then(
      () => refresh && this.fetch()
    );
  }
  setLocale(localeCode, refresh = true) {
    return this.rpc('setLocale', { localeCode }).then(
      () => refresh && this.fetch()
    );
  }

  acceptInvitation(invitationId) {
    return this.rpc('acceptInvitation', { invitationId }).then(() =>
      this.fetch()
    );
  }

  declineInvitation(invitationId) {
    return this.rpc('declineInvitation', { invitationId }).then(() =>
      this.fetch()
    );
  }

  leaveResourceGroup(resourceGroupId) {
    return this.rpc('leaveResourceGroup', { resourceGroupId }).then(() =>
      this.fetch()
    );
  }

  logAction(action, attributes) {
    return this.rpc('log', {
      action,
      attributes: {
        url: window.location.href,
        ...attributes,
      },
    });
  }

  get firstName() {
    return this.has('firstName') ? this.get('firstName') : '';
  }

  get lastName() {
    return this.has('lastName') ? this.get('lastName') : '';
  }

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  get email() {
    return this.get('email');
  }

  get emailVerified() {
    return this.get('emailVerified');
  }

  async requestEmailVerification() {
    return this.rpc('requestEmailVerification');
  }

  get locale() {
    if (this.get('locale')) {
      try {
        return new Intl.Locale(this.get('locale'));
      } catch (e) {
        return null;
      }
    } else {
      return null;
    }
  }

  get language() {
    return this.locale?.language;
  }

  get label() {
    return this.firstName ? this.firstName : this.email;
  }

  get profilePictureUrl() {
    return this.has('profilePictureUrl') ? this.get('profilePictureUrl') : '';
  }

  get isReady() {
    return this.has('realUserId');
  }

  get realUserId() {
    return this.get('realUserId');
  }

  get memberships() {
    return this.get('memberships');
  }

  get invitations() {
    return this.get('invitations');
  }

  get settings() {
    return this.get('settings');
  }

  get created() {
    return this.get('created');
  }
  get isNewUser() {
    return moment().startOf('day').diff(this.get('created'), 'days') < 60;
  }
}

class AnonymousAPI extends Model {
  url() {
    return '/anonymous';
  }

  addUser(invitationToken, password, acceptedLegalDocumentIds) {
    return AuthAwareAdapterProxy.withSkipAuthentication(() =>
      this.rpc('addUser', {
        invitationToken,
        password,
        acceptedLegalDocumentIds,
      })
    );
  }

  getInvitationInfo(invitationToken) {
    return AuthAwareAdapterProxy.withSkipAuthentication(() =>
      this.rpc('getInvitationInfo', { invitationToken })
    );
  }

  getLegalDocuments(brandCode) {
    return AuthAwareAdapterProxy.withSkipAuthentication(() =>
      this.rpc('getLegalDocuments', { brandCode })
    );
  }
}

class UserCollection extends PersistentCollection {
  url() {
    return '/users';
  }

  model() {
    return User;
  }

  addUser(invitationToken, password, acceptedLegalDocumentIds) {
    return new AnonymousAPI().addUser(
      invitationToken,
      password,
      acceptedLegalDocumentIds
    );
  }

  getInvitationInfo(invitationToken) {
    return new AnonymousAPI().getInvitationInfo(invitationToken);
  }

  getLegalDocuments(brandCode) {
    return new AnonymousAPI().getLegalDocuments(brandCode);
  }
}

const userCollection = new UserCollection();
window.userCollection = userCollection;

export default userCollection;
