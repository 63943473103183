import React, { Component } from 'react';

class RenderIfElementAvailable extends Component {
  constructor() {
    super();

    this.interval = null;

    this.state = {
      elementAvailable: false,
    };
  }

  startWatching() {
    this.interval = setInterval(() => {
      const element = document.querySelector(this.props.cssSelector);
      if (element !== null) {
        this.setState({
          elementAvailable: true,
        });
        clearInterval(this.interval);
      }
    }, 100);
  }

  componentDidMount() {
    this.startWatching();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cssSelector !== this.props.cssSelector) {
      this.setState({
        elementAvailable: false,
      });
      this.startWatching();
    }
  }

  render() {
    if (!this.state.elementAvailable) {
      return null;
    }
    return this.props.children;
  }
}

export { RenderIfElementAvailable };
