import React, { useEffect, useState } from 'react';
import { Progress } from 'reactstrap';
import { Observer } from 'mobx-react';

import { LearningIndicatorsGroup } from './learning-indicators-group';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PhaseCompletedLottie } from './medal/PhaseCompleted';

import _ from 'lodash';
import { Tooltip } from 'lib/Tooltip';
import { useAssignmentContext } from '../assignments/assignmentContext';

const LearningPhaseFC = ({
  t,
  phase,
  developmentPlan,
  observations,
  learningLine,
  isDisabled,
}) => {
  // Declare indicator from different sources.

  const assignmentContext = useAssignmentContext();
  const assignments = assignmentContext.assignments;

  let indicators = [
    ...phase?.skills,
    ...phase?.practices,
    ...(phase?.mental ?? []),
  ].map(({ skillId, practiceId, mentalId, name, tasks }) => {
    const indicatorId = skillId ?? practiceId ?? mentalId ?? 'undefined';
    const type = skillId
      ? 'skill'
      : practiceId
      ? 'practice'
      : mentalId
      ? 'mental'
      : 'undefined';

    return {
      name,
      indicatorId,
      type,
      tasks: tasks?.toJS() ?? [],
      assignments:
        assignments?.filter((assignment) =>
          tasks
            ?.toJS()
            .map((task) => task.taskId)
            .includes(assignment.taskTRN.slice(-36))
        ) ?? [],
    };
  });
  const phaseTasks = _.compact(
    _.flatMap(phase?.practices.map((p) => p?.tasks?.toJS()))
  );

  const phaseProgressText = () => {
    if (phaseTasks.length > 0) {
      return `${assignmentContext.getCompletionStatusText(phaseTasks)} ${t(
        'module.development.tasks:tasks'
      )}`;
    } else {
      return `${developmentPlan.phaseProgressText(phase)} ${t('practices')}`;
    }
  };
  const isCompleted =
    phaseTasks.length > 0
      ? assignmentContext.getCompletionStatusBoolean(phaseTasks)
      : developmentPlan.isPhaseCompleted(phase);

  const progressPercentage =
    phaseTasks.length > 0
      ? assignmentContext.getCompletionStatusPercentage(phaseTasks)
      : developmentPlan.phaseProgress(phase);

  const notification = assignmentContext.getActionStatus(phaseTasks);

  return (
    <div className="learning-line-card-wrapper">
      <div className="card">
        <Observer>
          {() => {
            return (
              <>
                <div className="card-body flex-grow-0 card-body--title">
                  <h5>{phase.name}</h5>
                  <p>{phaseProgressText()}</p>
                  {isCompleted && (
                    <div className={'phase-medal'}>
                      <PhaseCompletedLottie />
                    </div>
                  )}
                </div>
                <Progress value={progressPercentage} />
                {notification && (
                  <div
                    className={`learning-line-card-notification notification--${notification}`}
                  >
                    <Tooltip
                      tooltip={t(`module.development.tasks:${notification}`)}
                    ></Tooltip>
                  </div>
                )}
              </>
            );
          }}
        </Observer>
        <div
          className="card-body"
          data-learningline-id={learningLine.learningLineId}
        >
          <LearningIndicatorsGroup
            phaseId={phase.phaseId}
            indicators={indicators}
            observations={observations}
            developmentPlan={developmentPlan}
            learningLine={learningLine}
            t={t}
          />
        </div>
      </div>
    </div>
  );
};

const LearningPhase = withTranslation('module.development')((props) => (
  <Observer>
    {() => {
      return <LearningPhaseFC {...props} />;
    }}
  </Observer>
));

export { LearningPhase };
