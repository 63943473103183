import { Tour } from '../Tour';
import { matchRuleShort } from 'utils/matchRuleShort';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';

export default new Tour({
  key: 'liveObserveKorfball',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return (
      matchRuleShort(path, '/observe/*/live') &&
      currentSession.sportType() === 'korfball'
    );
  },
  steps: () => [
    {
      target: '.possession',
      i18nKey: 'module.tours.liveObserveKorfball:step1Possession',
    },
    {
      target: '.ballloss',
      offset: -10,
      i18nKey: 'module.tours.liveObserveKorfball:step2BallLoss',
    },
    {
      target: '.substitute',
      offset: -10,
      i18nKey: 'module.tours.liveObserveKorfball:step3Substitute',
    },
    {
      target: '.period',
      offset: -10,
      i18nKey: 'module.tours.liveObserveKorfball:step4Period',
    },
    {
      target: '.events',
      offset: -10,
      i18nKey: 'module.tours.liveObserveKorfball:step5Events',
    },
    {
      target: '.undo',
      offset: -10,
      i18nKey: 'module.tours.liveObserveKorfball:step6Undo',
    },
  ],
  callbacks: {
    start: () => {},
    nextStep: index => {
      // console.log(`tour nextStep ${index}`);
    },
    end: () => {
      // console.log("tour ended");
    },
  },
});
