import React, { useState } from 'react';
import { Page } from '../../../lib/Page';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { Session } from '../../../domain/Session';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/ApiClient';
import { NavLink, withRouter } from 'react-router-dom';
import { gotoPath, gotoRoute } from '../../route';

import { AddSportingEventModal } from '../components/addSportingEventModal';
import { filterSportingEvents } from '../components/sportingEventFilterHandler';
import SportingEventCollection from '../../../domain/SportingEvent';
import TeamCollection from '../../../domain/Team';
import VideoCollection from '../../../domain/Video';
import { LockedContent } from '../../../lib/LicenseLimitEnforcing';
import { InfiniteScroll } from '../../../utils/ObservationInput/components/infiniteScroll';
import noresultImg from '../../../img/no_matches.svg';
import { NoResults } from 'lib/PlaceHolder';
import { SportingEventComponent } from '../components/SportingEventComponent';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';

export const SportingEventOverview = withRouter(
  ({ match, location, history, type }) => {
    const { t } = useTranslation('module.match');
    const currentSession = Session.current();
    const queryClient = useQueryClient();
    const getInitialModalComponent = () => {
      if (window.location.hash && window.location.hash.indexOf('add') !== -1) {
        return (
          <AddSportingEventModal
            type={type}
            onClose={() => setModalComponent(null)}
            onSportingEventAdded={() => {
              setModalComponent(null);
              queryClient.invalidateQueries('SportingEvents');
            }}
          />
        );
      }
    };
    const [modalComponent, setModalComponent] = useState(
      getInitialModalComponent()
    );

    const [search, setSearch] = useState('');
    const viewArguments =
      type === 'match'
        ? {
            search: search,
            type: type,
            filters: match.params.ARRAYfilters?.split('+') ?? [],
            group: match.params.group ?? 'all',
          }
        : {
            search: search,
            type: type,
            filters: ['upcoming', 'archived'],
            group: 'all',
          };

    const manageFilters = (type, value) => {
      let routeParts = match.path.split('/');
      let urlParts = match.url.split('/');
      const arraySeparator = '+';
      for (var i = 0; i < routeParts.length; i++) {
        if (routeParts[i] === type) {
          if (type.includes('ARRAY')) {
            let values = urlParts[i]?.split(arraySeparator) ?? [];
            if (values.includes(value)) {
              values = values.filter((_) => {
                return _ !== value;
              });
            } else {
              values.push(value);
            }
            urlParts[i] = values.filter((v) => v).join('+'); // Remove empty '' values first
          } else {
            urlParts[i] = value;
          }
        }
      }
      if (type === 'search') {
        setSearch(value);
      }
      gotoPath(urlParts.join('/'));
    };

    const viewFilterButtons = [];

    if (type === 'match') {
      viewFilterButtons.push({
        label: t('all'),
        onClick: () => {
          manageFilters(':group', 'all');
        },
        isActive: viewArguments.group === 'all',
      });

      viewFilterButtons.push({
        dataId: 'mymatches',
        label: t('myMatches'),
        onClick: () => {
          manageFilters(':group', 'my-matches');
        },
        isActive: viewArguments.group === 'my-matches',
      });

      viewFilterButtons.push({
        dataId: 'upcoming',
        label: t('showUpcoming'),
        type: 'checkBox',
        onClick: () => {
          manageFilters(':ARRAYfilters?', 'upcoming');
        },
        isActive: viewArguments.filters.includes('upcoming'),
      });

      if (0) {
        viewFilterButtons.push({
          label: t('showArchived'),
          type: 'checkBox',
          onClick: () => {
            manageFilters(':ARRAYfilters?', 'archived');
          },
          isActive: viewArguments.filters.includes('archived'),
        });
      }
    }

    const viewFilters = [
      {
        type: 'search',
        placeholder: t('search', { type: t(`typePlural.${type}`) }),
        onChange: debounce((value) => {
          manageFilters('search', value);
        }, 200),
      },
      {
        type: 'buttonGroup',
        buttons: viewFilterButtons.length > 1 ? viewFilterButtons : [],
      },
    ];

    const pageProps = {
      title: _.startCase(t(`typePlural.${type}`)),
      breadcrumbs: [
        { path: '/', title: 'Home' },
        {
          title: _.startCase(t(`typePlural.${type}`)),
        },
      ],
      viewFilters: viewFilters,

      primaryAction: [
        {
          title: `${t('common:addT', { type: t(`type.${type}`) })}`,
          locked: [
            {
              check:
                !currentSession.isFeatureAvailable('createSportingEvent') &&
                !Session.current()
                  .licensePrivileges()
                  .hasPrivilege('sporting-event:create'),
              reason: 'addMatchNoLicense',
              lockType: 'large',
            },
            {
              check:
                !currentSession.isFeatureAvailable('createSportingEvent') &&
                Session.current()
                  .licensePrivileges()
                  .hasPrivilege('sporting-event:create'),
              reason: 'addMatchNoPermission',
              lockType: 'hidden',
            },
          ],
          onClick: () => {
            setModalComponent(
              <AddSportingEventModal
                type={type}
                onClose={() => setModalComponent()}
                onSportingEventAdded={() => {
                  setModalComponent(null);
                  queryClient.invalidateQueries('SportingEvents');
                }}
              />
            );
          },
          disabled: !currentSession.isFeatureAvailable('createSportingEvent'),
        },
      ].concat(
        type === 'training' &&
          currentSession.isFeatureAvailable('manageSchedule')
          ? {
              title: t('module.schedule:title'),
              onClick: () => {
                gotoRoute('training-schedule');
              },
              locked: { check: false, lockType: 'hidden' },
            }
          : []
      ),
    };
    return (
      <Page className={'sportingEvent-overview'} {...pageProps}>
        {modalComponent}
        <SportingEventView
          viewArguments={viewArguments}
          manageFilters={manageFilters}
          queryClient={queryClient}
        />
      </Page>
    );
  }
);

const SportingEventView = ({ viewArguments, manageFilters, queryClient }) => {
  const fetchSportingEvents = async () => {
    const [{ data }, ...other] = await Promise.all([
      api.get('sportingEvents'),
      TeamCollection.fetchIfEmpty(),
      VideoCollection.fetchIfEmpty(),
    ]);
    SportingEventCollection.set(data);

    return data ? data : [];
  };

  const { t } = useTranslation('module.match');

  const sportingEvents = useQuery(
    'SportingEvents',
    () => fetchSportingEvents(),
    { placeholderData: [], refetchOnWindowFocus: true, refetchInterval: 30000 }
  );

  const whenMutated = () => {
    queryClient.invalidateQueries('SportingEvents');
  };

  if (!sportingEvents.isFetched) {
    return (
      <div className="sportingEvent-overview__wrapper">
        <div className={'sportingEvent-overview__category'}>
          <div>
            <div className="infinite-scroll__wrapper">
              {[...Array(10)].map((v, index) => (
                <div
                  key={`skeleton-${index}`}
                  className={'match-overview-item'}
                >
                  <Skeleton
                    duration={0.9}
                    style={{
                      height: '57px',
                      width: '100%',
                      marginBottom: '18px',
                      borderRadius: '7px',
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const sportingEventGroups = filterSportingEvents(
      viewArguments,
      sportingEvents.data
    );

    let archivedReason = null;
    if (sportingEventGroups.archivedSportingEvents.length) {
      const denials =
        sportingEventGroups.archivedSportingEvents[0]._metadata.privilegesV2[
          'sporting-event:view-video'
        ].denials;
      for (const denial of denials) {
        if (denial.code === 'license') {
          archivedReason = t('archivedSportingEvents', {
            amount: parseInt(denial.msg),
            type: `${t('typePlural.' + viewArguments.type)}`,
          });
        }
      }
    }

    let isEmpty = true;
    if (
      viewArguments.filters.includes('archived') &&
      sportingEventGroups.archivedSportingEvents.length
    ) {
      isEmpty = false;
    }
    if (
      viewArguments.filters.includes('upcoming') &&
      sportingEventGroups.upcomingSportingEvents.length
    ) {
      isEmpty = false;
    }
    if (sportingEventGroups.pastSportingEvents.length) {
      isEmpty = false;
    }
    if (sportingEventGroups.todaySportingEvents.length) {
      isEmpty = false;
    }

    if (isEmpty && sportingEvents.isFetched) {
      return (
        <div className={'sportingEvent-overview__wrapper'}>
          <div className={' d-flex justify-content-center'}>
            <div className="view--empty d-inline-block">
              <NoResults imgSrc={noresultImg} description={''} />
              <div className={'empty-feedback'}>
                <strong>0</strong> {t(`typePlural.${viewArguments.type}`)}{' '}
                {t('common:found')}{' '}
                {viewArguments.search && (
                  <>
                    {t('common:for')} <strong>"{viewArguments.search}"</strong>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={'sportingEvent-overview__wrapper'}>
        {sportingEventGroups.todaySportingEvents.length > 0 && (
          <div className={'sportingEvent-overview__category'}>
            <InfiniteScroll
              array={sportingEventGroups.todaySportingEvents.sort((a, b) => {
                return a.scheduledAt === b.scheduledAt
                  ? 0
                  : a.scheduledAt > b.scheduledAt || b.scheduledAt === false
                  ? -1
                  : 1;
              })}
              component={(sportingEvent) => (
                <SportingEventComponent
                  key={sportingEvent.sportingEventId}
                  refetch={whenMutated}
                  sportingEvent={sportingEvent}
                />
              )}
            />
          </div>
        )}
        {viewArguments.filters.includes('upcoming') &&
          sportingEventGroups.upcomingSportingEvents.length > 0 && (
            <div className={'sportingEvent-overview__category'}>
              <div>
                <InfiniteScroll
                  array={sportingEventGroups.upcomingSportingEvents}
                  component={(sportingEvent) => (
                    <SportingEventComponent
                      key={sportingEvent.sportingEventId}
                      refetch={whenMutated}
                      sportingEvent={sportingEvent}
                    />
                  )}
                />
              </div>
            </div>
          )}

        {sportingEventGroups.pastSportingEvents.length > 0 && (
          <div className={'sportingEvent-overview__category'}>
            <InfiniteScroll
              array={sportingEventGroups.pastSportingEvents.sort((a, b) => {
                return a.scheduledAt === b.scheduledAt
                  ? 0
                  : a.scheduledAt > b.scheduledAt || b.scheduledAt === false
                  ? -1
                  : 1;
              })}
              component={(sportingEvent) => (
                <SportingEventComponent
                  key={sportingEvent.sportingEventId}
                  refetch={whenMutated}
                  sportingEvent={sportingEvent}
                />
              )}
            />
          </div>
        )}
        {sportingEventGroups.archivedSportingEvents.length > 0 && (
          <div className={'sportingEvent-overview__category'}>
            <div className="match-archive">
              <div className="match-archive__text">
                <h3>
                  {t('archived') + ' ' + t(`typePlural.${viewArguments.type}`)}
                </h3>
                <span>{archivedReason}</span>
              </div>
              <div className="match-archive__cta">
                <NavLink
                  className="btn btn-primary"
                  onClick={() => {
                    return Session.current().user.logAction(
                      'LicenseLimitEnforcing',
                      {
                        component: 'ArchivedMatchesCTA',
                        event: 'UpgradeCtaClick',
                      }
                    );
                  }}
                  to="/apps/launch/store"
                >
                  {t('upgrade')}
                </NavLink>
              </div>
            </div>
            <InfiniteScroll
              array={sportingEventGroups.archivedSportingEvents}
              component={(sportingEvent) => {
                let lockedReasons = [
                  {
                    check: true,
                    reason: 'archivedMatch',
                  },
                ];
                return (
                  <LockedContent
                    reasons={lockedReasons}
                    component="tagging"
                    place="left"
                    size="small"
                    locked={true}
                    display="block"
                    key={sportingEvent.sportingEventId}
                  >
                    {({ isLocked }) => (
                      <SportingEventComponent
                        refetch={whenMutated}
                        sportingEvent={sportingEvent}
                      />
                    )}
                  </LockedContent>
                );
              }}
            />
          </div>
        )}
      </div>
    );
  }
};

export const TrainingOverview = () => {
  return <SportingEventOverview type={'training'} />;
};

export const MatchOverview = () => {
  return <SportingEventOverview type={'match'} />;
};

export const RecordingOverview = () => {
  return <SportingEventOverview type={'recording'} />;
};
