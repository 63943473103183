import React, { Component } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import JsxParser from 'react-jsx-parser';
import jp from 'jsonpath';

import { DynamicTriggerButton } from './TriggerButton';
import { ObservationInputBase } from './ObservationInputBase';
import Command from '../../../infra/Messaging/Command';
import {
  CommandBus,
  CommandHistory,
} from '../../../infra/Messaging/CommandBus';
import { useTranslation } from 'react-i18next';

class mProxy {
  constructor(observable_) {
    for (const key of observable_.attributes.keys()) {
      Object.defineProperty(this, key, {
        get: function () {
          return observable_.get(key);
        },
      });
    }

    Object.defineProperty(this, 'id', {
      get: function () {
        return observable_.id;
      },
    });
  }
}

class ObservableContextProxy {
  constructor(object) {
    for (const k of ['homeTeam', 'awayTeam']) {
      this[k] = new mProxy(object[k]);
    }
  }
}

const DynamicObservationInput = observer(
  class DynamicObservationInput extends ObservationInputBase {
    get observationContext_() {
      if (typeof this._observationContext === 'undefined') {
        this._observationContext = new ObservableContextProxy(
          this.observationContext
        );
      }
      return this._observationContext;
    }

    resolveVariables(inp) {
      let string = JSON.stringify(inp);

      string = string.replace(/{(\$\.[a-zA-Z0-9\[\]\.]+?)}/g, (_, path) => {
        return jp.value(this.observationContext_, path);
      });
      return JSON.parse(string);
    }

    renderButton(buttonId, props) {
      // pass a function with all the data to the button. Within the function the resolving of variables is done
      // and when a variable is changed only the affected buttons needs a re-render
      if (typeof this.buttons[buttonId] === 'undefined') {
        throw 'ButtonId ' + buttonId + ' not found';
      }
      return (
        <DynamicTriggerButton
          key={buttonId}
          observationLogger={this.observationLogger}
          buttonProps={() => {
            return this.resolveVariables({
              ...this.buttons[buttonId],
              ...props,
            });
          }}
        />
      );
    }

    get buttons() {
      return this.props.template.buttons;
    }

    get layout() {
      return this.resolveVariables(this.props.template.layout);
    }
    get namespace() {
      return this.resolveVariables(this.props.template.namespace);
    }

    setupObservationLoggerHooks() {
      const context = {
        observationLogger: this.observationLogger,
        dispatchCommand: (commandType, attributes) => {
          const command = Command.create(commandType, attributes);
          CommandBus.dispatch(command);
        },
        observationContext: this.observationContext,
      };
      this.observationLogger.resetHooks();
      const hooks = this.resolveVariables(
        (this.props.template || {}).hooks || {}
      );
      for (const eventName in hooks) {
        const hookFn = new Function('observationCode', hooks[eventName]).bind(
          context
        );
        this.observationLogger.on(eventName, hookFn);
      }

      const commandHistory = CommandHistory.instance();

      this.observationLogger.on(
        'afterObservationAdded',
        (code, attributes, triggerTime, startTime, endTime, description) => {
          commandHistory.tagHistoryItem(description);
        }
      );
    }

    componentWillMount() {
      super.componentWillMount();

      this.unmountAutorun = autorun(() => {
        this.setupObservationLoggerHooks();
      });
    }

    componentWillUnmount() {
      this.unmountAutorun();
      this.observationLogger.resetHooks();
    }

    render() {
      console.log('rerender everything');
      const TemplateLayoutRenderer = this.props.templateLayoutRenderer;

      return (
        <TemplateLayoutRenderer
          layout={this.layout}
          namespace={this.namespace}
          renderButton={(...props) => this.renderButton(...props)}
          observationContext={this.observationContext_}
        />
      );
    }
  }
);

const JsxTemplateLayoutRenderer = (props) => {
  const Button = ({ id, ...props }) => {
    return props.renderButton(id, props);
  };

  const SimpleButton = ({
    label,
    description,
    observationCode,
    attributes,
  }) => {
    return (
      <DynamicTriggerButton
        observationLogger={props.observationContext.observationLogger}
        type="point"
        groupId="BUTTON"
        observationCode={observationCode || 'CUSTOM'}
        attributes={attributes}
        label={label}
        color="#ccc"
        description={description || label}
      />
    );
  };

  const Label = ({ color, text }) => {
    return (
      <div
        className="font-heavy"
        style={{
          textAlign: 'center',
          minWidth: '100px',
          padding: '15px',
          backgroundColor: color,
          color: '#000',
        }}
      >
        {text}
      </div>
    );
  };

  const jsx = props.layout;

  const { t } = useTranslation(props.namespace);

  return (
    <JsxParser
      components={{ Button, Label, SimpleButton }}
      renderInWrapper={false}
      showWarnings={true}
      bindings={{
        t,
      }}
      jsx={jsx.replace(/>\s+</g, '><')}
    />
  );
};

const createComponentFactoryFromTemplate = (template) => {
  return ({ observationContext, templateLayoutRenderer }) => {
    templateLayoutRenderer =
      templateLayoutRenderer || JsxTemplateLayoutRenderer;

    return (
      <DynamicObservationInput
        observationContext={observationContext}
        template={template}
        templateLayoutRenderer={templateLayoutRenderer}
      />
    );
  };
};

export { DynamicObservationInput, createComponentFactoryFromTemplate };
