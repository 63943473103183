class Tour {
  constructor({ key, autoStart, isApplicableTo, steps, callbacks }) {
    this.key = key;
    this.autoStart = autoStart;
    this.isApplicableTo = isApplicableTo;
    this.steps = steps;
    this.callbacks = callbacks;
  }
}

export { Tour };
