import React, { Component, useEffect } from 'react';

import Select from 'react-select';

import { withTranslation } from 'react-i18next';

import { MatchConfigurationInput } from './base';

const KorfballMatchConfigurationInputComponent = withTranslation(
  'module.match.new'
)((props) => {
  const { errors, values, setFieldValue, validClassName, t } = props;

  const periodCount = [
    // Select periodCount for this match = 2 halves(default) || 4 quarters
    { value: 2, label: t('halves') },
    { value: 4, label: t('quarters') },
  ];

  const v = (array, value) => {
    for (const option of array) {
      if (option.value === value) {
        return option;
      }
    }
    return null;
  };

  return (
    <div className="col-12">
      <div>
        <div className={`form-group ${validClassName('periodCount')}`}>
          <label htmlFor="new-match-set-periodCount">{t('periods')}</label>
          <Select
            id="new-match-set-periodCount"
            name="periodCount"
            className="custom-form-control"
            value={v(periodCount, values?.periodCount ?? 2)}
            onChange={(option) => setFieldValue('periodCount', option.value)}
            options={periodCount}
            placeholder={t('periodPlaceholder')}
          />
          <div className="invalid-feedback">
            {t('common.form:required-field')}
          </div>
        </div>
      </div>
    </div>
  );
});

const korfballMatchConfigurationInput = new MatchConfigurationInput({
  Component: KorfballMatchConfigurationInputComponent,
  getInitialValues: () => {
    return {
      periodCount: 2, // use 2 halves as default value
    };
  },
  getValues: (matchConfig) => {
    return {
      periodCount: matchConfig?.periodCount ?? 2,
    };
  },
  validator: (matchConfig) => {
    const errors = {};
    if (!matchConfig.periodCount) {
      errors.periodCount = true;
    }
    return errors;
  },
});

export { korfballMatchConfigurationInput };
