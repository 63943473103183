import React from 'react';

export const HandballTeamField = ({ shots, onClick, ...props }) => {
  return (
    <svg
      viewBox="0 0 506 470"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <path d="M506 470H0V112h506v358z" fill="#2980b9" />
      <path d="M468 470V158H45v312h423z" fill="#3498db" />
      <path
        d="M88 159c0 74.551 65.755 135 146.875 135h35.25C351.245 294 417 233.56 417 159H88z"
        fill="#3498db"
      />
      <path
        d="M263.5 318.5v-4h-21v4h21zM260 257v-4h-12v4h12zM456.484 231.5a157.528 157.528 0 005.016-19.197l-4.124-.803a153.362 153.362 0 01-4.876 18.682l3.984 1.318zM416.891 301A178.368 178.368 0 00430 285.729L426.791 283A173.513 173.513 0 01414 297.898l2.891 3.102zM83.212 283L80 285.729A178.31 178.31 0 0093.106 301L96 297.88A173.715 173.715 0 0183.212 283zM438.254 269A180.792 180.792 0 00447 250.826L443.53 249a175.814 175.814 0 01-8.53 17.691l3.254 2.309zM63.478 249L60 250.876A179.646 179.646 0 0068.746 269L72 266.659A175.126 175.126 0 0163.478 249zM344.358 347A183.678 183.678 0 00362 339.633L360.246 336A179.013 179.013 0 01343 343.199l1.358 3.801zM275.175 353.101H263V357h12.175c2.6 0 5.234-.148 7.825-.234l-.168-3.766c-2.529.101-5.111.101-7.657.101zM233.055 353.661c-2.493 0-4.987-.069-7.397-.161l-.158 3.777c2.493.092 5.045.223 7.555.223H244.5v-3.839h-11.445zM49.612 211.5l-4.112.794a158.088 158.088 0 004.984 19.206l4.016-1.309a154.05 154.05 0 01-4.888-18.691zM306.084 357.5a171.938 171.938 0 0019.416-4.299l-1.003-4.701a168.17 168.17 0 01-18.997 4.208l.584 4.792zM382.177 328A179.644 179.644 0 00398 315.612L395.449 312A175.202 175.202 0 01380 324.102l2.177 3.898zM162.053 346.5l1.447-3.801a194.471 194.471 0 01-18.145-7.199l-1.855 3.633a199.173 199.173 0 0018.553 7.367zM201.916 357.5l.584-4.781a168.387 168.387 0 01-18.997-4.219l-1.003 4.691a172.15 172.15 0 0019.416 4.309zM125.837 328l2.163-3.89A175.821 175.821 0 01112.554 312L110 315.604A180.075 180.075 0 00125.837 328z"
        fill="#fff"
      />
      <path
        d="M40 470V159h426v311h-4.503V195.802l-1.342-.607c.225-3.434 1.342-8.109 1.342-10.827V163.53h-39.988c-1.612 75.198-67.593 135.9-148.479 135.9h-35.125c-80.886 0-146.866-60.702-148.478-135.9H44.503v20.838h4.9c0 2.718-.901 7.384-.676 10.827l-4.224.67V470H40zm377.177-306.47H93.759c1.612 73.386 65.656 131.37 144.146 131.37h35.125c78.49 0 142.534-57.984 144.147-131.37z"
        fill="#fff"
        fillRule="nonzero"
      />
      <g>
        <path
          d="M355.698 161V14.142H152.395V161H136V0h235v161h-15.302z"
          fill="#b1b1b1"
          fillRule="nonzero"
        />
        <path fill="#424242" d="M136 121.273H152.711V136.95499999999998H136z" />
        <path
          fill="#424242"
          d="M355.333 121.273H371V136.95499999999998H355.333z"
        />
        <path fill="#424242" d="M136 150.545H152.711V161H136z" />
        <path fill="#424242" d="M355.333 150.545H371V161H355.333z" />
        <path fill="#424242" d="M136 89.909H152.711V105.59100000000001H136z" />
        <path
          fill="#424242"
          d="M355.333 89.909H371V105.59100000000001H355.333z"
        />
        <path fill="#424242" d="M136 59.591H152.711V75.273H136z" />
        <path fill="#424242" d="M355.333 59.591H371V75.273H355.333z" />
        <path fill="#424242" d="M136 28.227H152.711V43.909H136z" />
        <path fill="#424242" d="M355.333 28.227H371V43.909H355.333z" />
        <g fill="#424242">
          <path d="M136 0H152.49099999999999V17.773H136z" />
          <path d="M156.889 0v14.39H136V0h20.889z" fillRule="nonzero" />
        </g>
        <g fill="#424242">
          <path d="M355.608 0H371V17.773H355.608z" />
          <path d="M350.111 0v14.39H371V0h-20.889z" fillRule="nonzero" />
        </g>
        <path
          d="M192.922.523v13.591h-18.8V.523h18.8zM227.389.523v13.591h-18.8V.523h18.8zM262.9.523v13.591h-18.8V.523h18.8zM298.411.523v13.591h-18.8V.523h18.8zM334.967.523v13.591h-18.8V.523h18.8z"
          fill="#424242"
          fillRule="nonzero"
        />
        {shots.map((shotCell, idx) => {
          const { metadata, metrics } = shotCell;
          // console.log(metadata);
          const [fromX, fromY] = [
            ((metadata.position.x + 10) / 20) * 420 + 44,
            (metadata.position.distance / 20) * 430 + 165,
          ];
          const [toX, toY] = [
            (metadata.goalPosition.x / 3) * 202 + 136 + 119,
            160 - (metadata.goalPosition.y / 2) * 146,
          ];

          const color = metrics.scored ? 'green' : 'red';
          return (
            <g
              key={idx}
              style={{ pointerEvents: 'all' }}
              className="clickable"
              onClick={() => onClick(shotCell)}
            >
              <circle cx={toX} cy={toY} r="5" fill={color} />
              <circle cx={fromX} cy={fromY} r="5" fill={color} />
              <line
                x1={fromX}
                y1={fromY}
                x2={toX}
                y2={toY}
                stroke={color}
                strokeWidth="2"
              />
            </g>
          );
        })}
      </g>
    </svg>
  );
};
