import React, { useEffect, useRef, useState } from 'react';
import './live-recording.scss';
import RecordRTC, { MediaStreamRecorder, invokeSaveAsDialog } from 'recordrtc';
import { webmFixDuration } from 'webm-fix-duration';
import { Modal } from '../Modal';
import { useTranslation } from 'react-i18next';

import { VisualSoundMeter } from './components/VisualSoundMeter';

import { SelectUserMedia } from './components/SelectUserMedia';

let recorder = window.recorder || null;

export const LiveRecording = ({
  onStartRecording = () => {},
  onFinishRecording,
  extra,
}) => {
  const [recordingStartTime, setRecordingStartTime] = useState();

  const settings = {
    videoWidth: 1280,
    videoHeight: 720,
    aspectRatio: 16 / 9,
    blobDuration: 10000,
  };
  // Set recording preview (here we store the actual recording stream while recording)
  const recordingPreviewPlayerRef = useRef();
  const [recordingStream, setRecordingStream] = useState();

  const [recordingConstraints, setRecordingConstraints] = useState({
    video: {
      width: { ideal: settings.videoWidth },
      height: { ideal: settings.videoHeight },
      facingMode: 'environment',
      deviceId: undefined, // Declare to be overwritten by select.
      aspectRatio: { exact: settings.aspectRatio },
    },
    audio: {
      autoGainControl: true,
      noiseSuppression: true,
      deviceId: undefined, // Declare to be overwritten by select.
    },
  });

  // set Testing stream component (Check vitals before starting recording)
  const testingPlayerRef = useRef('testingPlayer');
  const [testingStream, setTestingStream] = useState();
  const [error, setError] = useState();

  const { t } = useTranslation('liveRecording');

  useEffect(() => {
    if (testingStream) {
      stopStream(() => startStream(true));
    }
  }, [recordingConstraints]);

  // Declare functions
  const initiateStream = (callback) => {
    navigator.mediaDevices
      .getUserMedia(recordingConstraints)
      .then(function (stream) {
        callback(stream);
      })
      .catch(function (error) {
        setError(t('error'));
        console.error(
          'Unable to capture your stream. Please check console logs.\n' + error
        );
      });
  };

  const startStream = (isTesting = false) => {
    initiateStream(function (stream) {
      let streamOutputElement = isTesting
        ? testingPlayerRef.current
        : recordingPreviewPlayerRef.current;

      // Set stream in component
      window.recorder = null;

      if (!isTesting) {
        window.recorder = RecordRTC(stream, {
          type: 'video',
          mimeType: 'video/mp4', // vp8, vp9, h264, mkv, opus/vorbis
          // audioBitsPerSecond: 256 * 8 * 1024,
          // videoBitsPerSecond: 256 * 8 * 1024,
          // bitsPerSecond: 256 * 8 * 1024, // if this is provided, skip above two
          // checkForInactiveTracks: true,
          timeSlice: 1000, // concatenate intervals based blobs
          ondataavailable: function (blob) {}, // get intervals based blobs
        });
      } else {
        window.recorder = RecordRTC(stream, {
          type: 'video',
        });
      }

      streamOutputElement.muted = true;
      streamOutputElement.volume = 0;
      streamOutputElement.srcObject = stream;
      streamOutputElement.play();

      window.recorder.startRecording();
      window.recorder.stream = stream;

      if (!isTesting) {
        setRecordingStream(stream);
      } else {
        setTestingStream(stream);
      }
    });
  };
  const stopStream = (callback) => {
    window.recorder.stopRecording(callback);
  };

  const startRecording = () => {
    // Stop testing stream
    const recordingStartTime_ = Date.now();
    setRecordingStartTime(recordingStartTime_);
    setTestingStream(null);
    stopStream(() => {
      testingPlayerRef.current.muted = false;
      testingPlayerRef.current.volume = 0;
      testingPlayerRef.current.src = null;
    });
    startStream(false);
    onStartRecording(recordingStartTime_); // Callback
  };

  const finishRecording = () => {
    stopStream(finishRecordingCallback);
  };

  const finishRecordingCallback = async () => {
    const blob = window.recorder.getBlob();
    const mime = blob.type;
    const duration = Date.now() - recordingStartTime;
    const fixedSeekableBlob = await webmFixDuration(blob, duration, mime);
    window.recorder.stream.stop();
    window.recorder.destroy();
    window.recorder = null;

    let blobToFile = new File([fixedSeekableBlob], 'file_name.mp4', {
      lastModified: Date.now(),
      type: mime,
    });

    onFinishRecording(blobToFile);
  };

  const selectDevice = (type, deviceId) => {
    // handle selection of audio/video inputs.
    let newConstraints = recordingConstraints;
    if (type === 'audioinput') {
      newConstraints.audio.deviceId = { exact: deviceId };
      setRecordingConstraints({ ...recordingConstraints, newConstraints });
    }
    if (type === 'videoinput') {
      newConstraints.video.deviceId = { exact: deviceId };
      setRecordingConstraints({ ...recordingConstraints, newConstraints });
    }
  };

  return (
    <div className="live-recording__wrapper">
      <div className="live-recording__videowrapper">
        <video
          className="live-recording__testplayer"
          ref={recordingPreviewPlayerRef}
          muted
          playsInline
          autoPlay="false"
        />
      </div>

      {recordingStream && (
        <button
          onClick={() => finishRecording()}
          className={'btn-finish-recording btn btn-primary'}
        >
          {t('finish')} <span className="rec-orb"></span>
        </button>
      )}
      {!recordingStream && (
        <Modal onCloseClick={() => window.history.back()}>
          <h3>{t('setup')}</h3>
          <SelectUserMedia
            type={'audioinput'}
            mediaSelectedCallback={(audioSourceDeviceId) => {
              selectDevice('audioinput', audioSourceDeviceId);
            }}
          />
          <SelectUserMedia
            type={'videoinput'}
            mediaSelectedCallback={(audioSourceDeviceId) => {
              selectDevice('videoinput', audioSourceDeviceId);
            }}
          />
          <div className="live-recording__testwrapper">
            <video
              className="live-recording__testplayer"
              ref={testingPlayerRef}
              style={{
                width: '100%',
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
              }}
              muted
              playsInline
              autoPlay="false"
            ></video>

            <VisualSoundMeter stream={testingStream}></VisualSoundMeter>
            {!testingStream && (
              <button
                onClick={() => startStream(true)}
                className="btn-start-test btn btn-primary"
              >
                {t('startSetup')}
              </button>
            )}
          </div>
          <div>
            {error}
            <p>{t('helpText')}</p>
          </div>
          <div className="modal-input">
            <button
              onClick={() => stopStream(() => startRecording())}
              disabled={!testingStream}
              className={`btn-start-recording btn btn-primary ${
                !testingStream ? 'disabled' : ''
              }`}
            >
              {t('startRecording')} <span className="rec-orb"></span>
            </button>
          </div>
        </Modal>
      )}
      {extra}
    </div>
  );
};
