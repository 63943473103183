import React, { Component, Fragment } from 'react';

import { observable, action, extendObservable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';

import { Progress } from 'reactstrap';
import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';
import { Modal } from 'lib/Modal';

import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import PersonCollection from 'domain/Person';
import TeamCollection from 'domain/Team';

import { withTranslation } from 'react-i18next';
import { Redirect, Switch, Route, NavLink } from 'react-router-dom';
import { MatchOverviewItem } from '../../../match/components/MatchOverviewItem';

import SportingEventCollection from 'domain/SportingEvent';
import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';
import userPlaceHolder from 'img/icons/profile-picture-placeholder.svg';
import { withToggle } from 'lib/hocs/withToggle';
import { CollapseIcon } from 'lib/CollapseIcon';
import { VideoFragmentsPlayer } from 'lib/VideoFragmentsPlayer';
import { gotoPath } from 'modules/route';
import { Error } from 'lib/PlaceHolder';

// import './index.scss';

// const VideoFragmentPlayerModal = observer(({isOpen, videoFragments}) => {
//   if (!isOpen.get()) {
//     return null;
//   }
//
//
// });
//
// const VideoFragmentPlayer = ({children, videoFragments}) => {
//   return (
//     <div onClick=""   />;
//   );
// };

class GrowthAmbitionEvaluation {
  constructor() {
    this.indicatorsEvaluations = observable.map();
  }

  setIsMastered = action((indicatorId, isMastered) => {
    this.indicatorsEvaluations.set(indicatorId, isMastered);
  });

  isMastered(indicatorId) {
    // if (typeof this.indicatorsEvaluations[indicatorId] === 'undefined') {
    //   extendObservable(this.indicatorsEvaluations, { [indicatorId]: null });
    // }

    return this.indicatorsEvaluations.get(indicatorId);
  }

  toMap() {
    return this.indicatorsEvaluations.toJS();
  }
}

const groupVideoFragmentsPerSportingEvent = (videoFragments) => {
  const videoFragmentsPerSportingEventPerRating = {};
  for (const videoFragment of videoFragments) {
    if (!videoFragment.metadata.sportingEvent) {
      continue;
    }

    const sportingEventId =
      videoFragment.metadata.sportingEvent.sportingEventId;
    if (!sportingEventId) {
      continue;
    }

    if (
      typeof videoFragmentsPerSportingEventPerRating[sportingEventId] ===
      'undefined'
    ) {
      videoFragmentsPerSportingEventPerRating[sportingEventId] = {};
    }
  }
  const groupedVideoFragments = [];
  for (const sportingEventId of Object.keys(
    videoFragmentsPerSportingEventPerRating
  )) {
    const sportingEvent = SportingEventCollection.get(sportingEventId);
    if (!sportingEvent) {
      console.warn(`Unknown sportingEvent ${sportingEventId}`);
      continue;
    }
    groupedVideoFragments.push({
      sportingEvent,
      ratings: videoFragmentsPerSportingEventPerRating[sportingEventId],
    });
  }

  groupedVideoFragments.sort(({ sportingEvent: a }, { sportingEvent: b }) => {
    return a.get('scheduledAt') === b.get('scheduledAt')
      ? 0
      : a.get('scheduledAt') > b.get('scheduledAt') ||
        b.get('scheduledAt') === false
      ? -1
      : 1;
  });

  return groupedVideoFragments;
};

const GrowthAmbitionEvaluate = withTranslation(
  'module.development.growthAmbition.evaluate'
)(
  observer(
    class GrowthAmbitionEvaluate extends Component {
      constructor(props) {
        super(props);
        this.state = observable({
          period: {
            from: new Date(),
            until: new Date(),
          },
        });
        this.dataState = observable('loading');
        this.mobileCollapsedGoalIds = observable([]);
        this.sportingEvents = [];
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          yield TeamCollection.fetchIfEmpty();
          yield SportingEventCollection.fetchIfEmpty();
          yield Promise.all([
            this.props.developmentPlan.conversations.fetchIfEmpty(),
            this.props.developmentPlan.videoFragments.fetchIfEmpty(),
            this.props.developmentPlan.growthAmbitions.fetchIfEmpty(),
          ]);

          this.sportingEvents = SportingEventCollection.getPastMatches();

          this.growthAmbition =
            this.props.developmentPlan.getActiveGrowthAmbition();

          this.growthAmbitionEvaluation = new GrowthAmbitionEvaluation();

          this.props.developmentPlan
            .getLastMasteredIndicatorIdsByPerspective('learning-responsible')
            .map((indicatorId) => {
              this.growthAmbitionEvaluation.setIsMastered(indicatorId, true);
            });

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, { transactionName: 'Unable to load evaluation' });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      toggleGoal = action((goalId) => {
        const index = this.mobileCollapsedGoalIds.indexOf(goalId);
        if (index !== -1) {
          this.mobileCollapsedGoalIds.splice(index, 1);
        } else {
          this.mobileCollapsedGoalIds.push(goalId);
        }
      });

      isMobileCollapsed(goalId) {
        return this.mobileCollapsedGoalIds.indexOf(goalId) !== -1;
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const breadcrumbs = [{ path: '/', title: 'Home' }];
        let subject = '';
        if (this.props.person) {
          breadcrumbs.push(
            ...[
              {
                path: '/development/pdp',
                title: this.props.t('module.development:playerDevelopment'),
              },
              { title: this.props.person.fullName },
            ]
          );
          subject = this.props.person.fullName;
        }
        if (this.props.team) {
          breadcrumbs.push({
            title: this.props.t('module.development:teamDevelopment'),
          });
          subject = 'het team';
        }

        const pageProps = {
          title: this.props.t('module.development:title'),
          breadcrumbs,
          subtitle: this.props.subtitle,
          primaryAction: {
            title: 'Evaluatie opslaan' /*TODO:TRANSLATE*/,
            onClick: async () => {
              await this.props.developmentPlan.addFinalEvaluation(
                this.growthAmbitionEvaluation.toMap(),
                this.growthAmbition.growthAmbitionId
              );
              gotoPath('learning-line-overview');
            },
          },
          filters: [
            {
              title: 'Overzicht' /*TODO:TRANSLATE*/,
              path: 'learning-line-overview',
            },
            {
              title: 'Huidige doelen' /*TODO:TRANSLATE*/,
              path: 'monitor-growth-ambition',
              isActive: true,
            },
          ],
        };

        const growthAmbitionIndicatorIds = this.growthAmbition.goals.map(
          (goal) => goal.indicatorId
        );

        const otherIndicatorIds = this.props.developmentPlan
          .getLastMasteredIndicatorIdsByPerspective('learner')
          .filter(
            (indicatorId) =>
              growthAmbitionIndicatorIds.indexOf(indicatorId) === -1
          );

        return (
          <Page {...pageProps}>
            <div className="growth-ambition-evaluate">
              <GrowthAmbitionPrimaryBlock
                title="Huidige groeiambitie"
                developmentPlan={this.props.developmentPlan}
                indicatorIds={growthAmbitionIndicatorIds}
                growthAmbitionEvaluation={this.growthAmbitionEvaluation}
                person={this.props.person}
              />
              <GrowthAmbitionPrimaryBlock
                title={`Ontwikelpunten waar ${subject} ook aan gewerkt heeft`}
                developmentPlan={this.developmentPlan}
                indicatorIds={otherIndicatorIds}
                growthAmbitionEvaluation={this.growthAmbitionEvaluation}
                person={this.props.person}
              />
            </div>
          </Page>
        );
      }
    }
  )
);

const GrowthAmbition = withToggle(
  ({ growthAmbition, person, isToggled, toggle }) => (
    <div className="growth-ambition">
      <h2 className="growth-ambition-title" onClick={toggle}>
        {growthAmbition.title}
        <CollapseIcon collapsed={!isToggled} />
      </h2>
      {isToggled &&
        growthAmbition.learningLines.map((learningLine) => (
          <LearningLine
            key={learningLine.id}
            learningLine={learningLine}
            person={person}
          />
        ))}
    </div>
  ),
  { isToggled: false }
);

const GrowthAmbitionPrimaryBlock = ({
  title,
  indicatorIds,
  developmentPlan,
  growthAmbitionEvaluation,
  person,
}) => {
  const indicators = indicatorIds
    .map((indicatorId) =>
      LearningLineQueryService.indicatorOfIndicatorId(indicatorId)
    )
    .filter((indicator) => indicator !== null);

  const indicatorsPerLearningLine = {};
  for (const indicator of indicators) {
    if (
      typeof indicatorsPerLearningLine[
        indicator.learningLine.learningLineId
      ] === 'undefined'
    ) {
      indicatorsPerLearningLine[indicator.learningLine.learningLineId] = [];
    }
    indicatorsPerLearningLine[indicator.learningLine.learningLineId].push(
      indicator
    );
  }
  return (
    <div className="growth-ambition">
      <h2 className="growth-ambition-title primary">{title}</h2>
      {Object.keys(indicatorsPerLearningLine).map((learningLineId) => {
        const indicators = indicatorsPerLearningLine[learningLineId];
        const learningLine = indicators[0].learningLine;

        return (
          <LearningLine
            key={learningLineId}
            learningLine={learningLine}
            indicators={indicators.map(({ indicator }) => indicator)}
            person={person}
            developmentPlan={developmentPlan}
            growthAmbitionEvaluation={growthAmbitionEvaluation}
          />
        );
      })}
    </div>
  );
};

const LearningLine = ({
  learningLine,
  indicators,
  person,
  developmentPlan,
  growthAmbitionEvaluation,
}) => (
  <div className="card growth-ambition-evaluate-learning-line">
    <div className="card-body">
      <div className="learning-line-body">
        <div className="learning-line-evaluation-title">
          {learningLine.name}
        </div>
        <div className="learning-line-fragment">Fragmenten</div>
        {!!person && (
          <div className="learning-line-player-check">
            <img
              className="i-logo growth-ambition-evaluate-profile-icon"
              src={person.profilePictureUrl}
            />
            {person.firstName}
          </div>
        )}
        <div className="learning-line-final-check">Eindbeoordeling</div>
      </div>
      {indicators.map((indicator) => (
        <Indicator
          person={person}
          key={indicator.indicatorId}
          indicator={indicator}
          developmentPlan={developmentPlan}
          growthAmbitionEvaluation={growthAmbitionEvaluation}
        />
      ))}
    </div>
  </div>
);

const Indicator = observer(
  ({ person, indicator, developmentPlan, growthAmbitionEvaluation }) => {
    const videoFragments = developmentPlan.videoFragmentsOfIndicatorId(
      indicator.indicatorId
    );

    return (
      <div className="card growth-ambition-evaluate-indicator">
        <div className="indicator-title">{indicator.name}</div>
        <div className="indicator-fragment">
          {videoFragments.length > 0 && (
            <VideoFragmentsPlayer
              videoFragments={videoFragments}
              title={indicator.name}
            >
              {({ toggle }) => (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={toggle}
                >
                  <PlayIcon />
                </button>
              )}
            </VideoFragmentsPlayer>
          )}
        </div>
        {!!person && (
          <div className="indicator-player-check">
            <label>Speler</label>
            {developmentPlan.isIndicatorMasteredByPerspective(
              indicator.indicatorId,
              'learner'
            ) && <CheckIcon />}
          </div>
        )}
        <div className="indicator-final-check">
          <label>Eindbeoordeling</label>
          {/* {goal.hasFinalCheck ? 'check' : 'cross'} */}
          <ButtonGroupSelect
            options={[
              { value: true, label: <CheckIcon /> },
              { value: false, label: <CrossIcon /> },
            ]}
            value={growthAmbitionEvaluation.isMastered(indicator.indicatorId)}
            onChange={(isMastered) =>
              growthAmbitionEvaluation.setIsMastered(
                indicator.indicatorId,
                isMastered
              )
            }
          />
        </div>
      </div>
    );
  }
);

const CheckIcon = () => (
  <span className="growth-ambition-evaluate-check">
    <i className="i-check" />
  </span>
);
const CrossIcon = () => (
  <span className="growth-ambition-evaluate-cross">
    <i className="i-cross-red" />
  </span>
);
const PlayIcon = () => (
  <span className="growth-ambition-evaluate-play">
    <i className="i-play i-light" />
  </span>
);

export { GrowthAmbitionEvaluate };
