import React from 'react';

import userPlaceHolder from 'img/icons/profile-picture-placeholder.svg';
import { Session } from 'domain/Session';
import clubPlaceholderIcon from '../../../img/icons/club_placeholder.svg';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Skeleton } from 'survey-react';
const UserInfo = ({ t }) => {
  const session = Session.current();
  const currentUser = session.user;
  const membership = session.currentMembership();
  return (
    <div className="personal-profile">
      <div className="info">
        <div className="info--user">
          <div className={`picture`}>
            <img src={currentUser.profilePictureUrl || userPlaceHolder} />
          </div>
          {currentUser.firstName && (
            <div>
              <h3>{currentUser.firstName}</h3>
              <h4>{currentUser.lastName}</h4>
            </div>
          )}
          {!currentUser.firstName && (
            <div>
              <h3 className="empty">-</h3>
              <h4 className="empty">{currentUser.email}</h4>
            </div>
          )}
        </div>
        {!currentUser.firstName && (
          <Link
            to="/user-management/user-profile"
            className={`btn btn-primary btn-primary ${
              !currentUser.firstName ? 'warning' : ''
            }`}
          >
            {t('module.onboarding:completeProfile')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
