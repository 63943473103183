import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

// https://brightdrops.com/inspirational-sports-quotes
const quotes = [
  '"Hard work beats talent when talent doesn\'t work hard." - Tim Notke',
  '"It\'s hard to beat a person who never gives up." - Babe Ruth',
  '"The harder the battle, the sweeter the victory." - Les Brown',
  '"Never say never because limits, like fears, are often just an illusion." - Michael Jordan',
  '"You miss 100% of the shots you don\'t take." - Wayne Gretzky',
  '"Today, you have 100% of your life left." - Tom Landry',
  '"You have to expect things of yourself before you can do them." - Michael Jordan',
  '"Winning isn\'t everything, but wanting to win is." - Vince Lombardi',
  '"There may be people that have more talent than you, but there’s no excuse for anyone to work harder than you do." - Derek Jeter',
  '"I became a good pitcher when I stopped trying to make them miss the ball and started trying to make them hit it." - Sandy Koufax',
  '"When you’ve got something to prove, there’s nothing greater than a challenge." - Terry Bradshaw',
  '"If you can’t outplay them, outwork them." - Ben Hogan',
  '"Most people never run far enough on their first wind to find out they’ve got a second." - William James',
  '"I\'ve failed over and over and over again in my life. And that is why I succeed." - Michael Jordan',
  '"It\'s not whether you get knocked down; it\'s whether you get up." - Vince Lombardi',
  '"Do you know what my favorite part of the game is? The opportunity to play." - Mike Singletary',
  '"The more difficult the victory, the greater the happiness in winning." - Pele',
  '"Nobody who ever gave his best regretted it." - George Halas',
  '"If you can believe it, the mind can achieve it." - Ronnie Lott',
  '"Without self-discipline, success is impossible, period." - Lou Holtz',
  '"Make each day your masterpiece." - John Wooden',
  '"If you aren’t going all the way, why go at all?" - Joe Namath',
  '"Do not let what you can not do interfere with what you can do." - John Wooden',
  '"A champion is someone who gets up when he can’t." - Jack Dempsey',
  "\"It ain't over 'til it's over.\" - Yogi Berra",
  '"Winners never quit and quitters never win." - Vince Lombardi',
  '"One man practicing sportsmanship is far better than a hundred teaching it." - Knute Rockne',
  '"Success is where preparation and opportunity meet." - Bobby Unser',
  '"In baseball and in business, there are three types of people. Those who make it happen, those who watch it happen, and those who wonder what happened."  - Tommy Lasorda',
  '"Champions keep playing until they get it right."  - Billie Jean King',
  '"It isn\'t the mountains ahead to climb that wear you out; it\'s the pebble in your shoe."  - Muhammad Ali',
  '"You can’t put a limit on anything. The more you dream, the farther you get."  - Michael Phelps',
  '"Always make a total effort, even when the odds are against you." - Arnold Palmer',
  '"Winning is not a sometime thing, it is an all the time thing. You don’t do things right once in a while. You do them right all the time. " - Vince Lombardi',
  '"I’d like to thank everyone who voted for me. And the one guy who didn’t vote for me, thank you, too." - Shaquille O\'Neal',
  '"It’s not the will to win that matters — everyone has that. It’s the will to prepare to win that matters." - Paul "Bear" Bryant',
  '"What makes something special is not just what you have to gain, but what you feel there is to lose." - Andre Agassi',
  '"A trophy carries dust. Memories last forever." - Mary Lou Retton',
  '"Nobody beat Vitas Gerulaitis 17 times in a row." - Vitas Gerulaitis',
  '"I never worry about the problem. I worry about the solution." - Shaquille O\'Neal',
  '"If you are afraid of failure you don’t deserve to be successful!" - Charles Barkley',
  '"I’ve learned that something constructive comes from every defeat." - Tom Landry',
  '"You’re never a loser until you quit trying." - Mike Ditka',
  '"If a team wants to intimidate you physically and you let them, they’ve won." - Mia Hamm',
  '"It is more difficult to stay on top than to get there." - Mia Hamm',
  '"He who is not courageous enough to take risks will accomplish nothing in life." - Muhammad Ali',
  '"It is not the size of a man but the size of his heart that matters." - Evander Holyfield',
  '"Persistence can change failure into extraordinary achievement." - Matt Biondi',
  '"To give any less than your best is to sacrifice a gift." - Steve Prefontaine',
  '"Run when you can, walk if you have to, crawl if you must; just never give up." - Dean Karnazes',
  '"It’s all about the journey, not the outcome."  - Carl Lewis',
  '"When I race my mind is full of doubts – who will finish second, who will finish third?" - Noureddine Morceli',
  '"Strength does not come from winning. Your struggles develop your strengths." - Arnold Schwarzenegger',
  '"Sports do not build character. They reveal it." - Heywood Broun',
];

function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

const QuoteCarousel = observer(
  class QuoteCarousel extends Component {
    constructor() {
      super();
      shuffle(quotes);
      this._quoteIndex = 0;
      this._interval = null;
      this._quote = observable('');
      this.nextQuote();
    }

    nextQuote = action(() => {
      this._quote.set(quotes[this._quoteIndex]);

      this._quoteIndex++;
      if (this._quoteIndex === quotes.length) {
        this._quoteIndex = 0;
      }
    });

    componentDidMount() {
      this._interval = setInterval(this.nextQuote, 30000);
    }

    componentWillUnmount() {
      clearInterval(this._interval);
    }

    render() {
      return <span>{this._quote.get()}</span>;
    }
  }
);

export { QuoteCarousel };
