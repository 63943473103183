import React from 'react';

export const VideoPlayerPagination: React.FC<{
  currentItem: number;
  totalItems: number;
  setCurrentItem: (item: number) => void;
}> = ({ currentItem, totalItems, setCurrentItem }) => {
  return (
    <div className="video-pagination pagination">
      <div
        className={`nav-btn pagination-item ${
          currentItem > 0 ? 'clickable' : 'disabled'
        }`}
        onClick={() => setCurrentItem(0)}
      >
        &lt;&lt;
      </div>
      <div
        className={`nav-btn pagination-item ${
          currentItem > 0 ? 'clickable' : 'disabled'
        }`}
        onClick={() => setCurrentItem(currentItem - 1)}
      >
        &lt;
      </div>
      <div className="current">
        {currentItem + 1} / {totalItems}
      </div>
      <div
        className={`nav-btn pagination-item ${
          currentItem < totalItems - 1 ? 'clickable' : 'disabled'
        }`}
        onClick={() => setCurrentItem(currentItem + 1)}
      >
        &gt;
      </div>
      <div
        className={`nav-btn pagination-item ${
          currentItem < totalItems - 1 ? 'clickable' : 'disabled'
        }`}
        onClick={() => setCurrentItem(totalItems - 1)}
      >
        &gt;&gt;
      </div>
    </div>
  );
};
