import React, { useEffect } from 'react';

import { PlaylistOverviewSmall } from '../../playlist/overview-page';
import { useQuery } from 'react-query';
import Loading from 'lib/Loading';
import api from '../../../utils/ApiClient';
import { PlaylistCard } from '../../playlist/card/Card';
import { getMembers } from 'domain/ResourceGroup';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { useTranslation } from 'react-i18next';
import noresultImg from '../../../img/no_playlists.svg';
import { NoResults, Error, NoPermissions } from 'lib/PlaceHolder';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import { Session } from '../../../domain/Session';

const PlaylistInfo = () => {
  const { t } = useTranslation('module.playlist');
  const currentSession = Session.current();

  const fetchMembers = async () => {
    const members = await getMembers();
    return members.map((member) => {
      return {
        id: member.userId,
        display: `${member.firstName} ${member.lastName}`,
        profilePictureUrl: member.profilePictureUrl
          ? member.profilePictureUrl
          : 'default',
      };
    });
  };
  const { data: members } = useQuery(['members'], fetchMembers, {
    placeholderData: [],
    refetchOnWindowFocus: 'always',
  });
  if (!currentSession.isFeatureAvailable('viewPlaylist')) {
    return <NoPermissions resource={t('title')} />;
  }
  if (members.length > 0) {
    return <OwnPlaylists members={members} />;
  } else {
    return <Loading type={'dots'} size={40} />;
  }
};
export default PlaylistInfo;

const OwnPlaylists = ({ members }) => {
  const fetchDefault = async () => {
    let { data } = await api.get('playlists');
    return data ? data : [];
  };
  const { t } = useTranslation('module.playlist');

  const defaultPlaylists = useQuery(['playlist', 'default'], fetchDefault, {
    placeholderData: [],
  });
  if (!defaultPlaylists.isFetched) {
    return <Loading type={'dots'} size={40} />;
  }

  if (defaultPlaylists.data.length) {
    return (
      <>
        <div className="playlist-group">
          <div className="pill">{t('groupNameOther')}</div>
          <div className={'playlistRow'}>
            <PlaylistCarousel
              playlists={defaultPlaylists.data}
              members={members}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="pill">{t('groupNameOther')}</div>
        <div className={'playlistRow'}>
          <NoResults
            imgSrc={noresultImg}
            description={t('placeholder:noPlaylists')}
          />
        </div>
      </>
    );
  }
};

const GeneratedPlaylistsOwn = ({ members }) => {
  const fetchGenerated = async (type) => {
    let { data } = await api.get('generatedPlaylists', {
      params: { type: type },
    });
    return data ? data : [];
  };
  const { t } = useTranslation('module.playlist');

  const generatedPlaylistsOwn = useQuery(
    ['playlist', 'generatedOwn'],
    () => fetchGenerated('own'),
    { placeholderData: [] }
  );
  if (!generatedPlaylistsOwn.isFetched) {
    return <Loading type={'dots'} size={40} />;
  }

  if (generatedPlaylistsOwn.data.length) {
    return (
      <>
        <div className="playlist-group">
          <div className="pill">{t('groupNameGeneratedOwn')}</div>
          <div className={'playlistRow'}>
            <PlaylistCarousel
              playlists={generatedPlaylistsOwn.data}
              members={members}
            />
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

const PlaylistCarousel = ({ playlists, members }) => {
  return (
    <Swiper
      spaceBetween={8}
      slidesPerView={1.25}
      navigation
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      breakpoints={{
        768: {
          slidesPerView: 1.5,
          spaceBetween: 12,
        },
        1200: {
          slidesPerView: 2.25,
          spaceBetween: 16,
        },
        1600: {
          slidesPerView: 3.25,
          spaceBetween: 16,
        },
      }}
    >
      {playlists.map((playlist) => {
        return (
          <SwiperSlide key={playlist.playlistId} className={'swiper-slide'}>
            <PlaylistCard actions={[]} playlist={playlist} members={members} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
