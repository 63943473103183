import React from 'react';

import { PercentageLabel } from './percentage-label';

import './efficiency-label.scss';

export const EfficiencyLabel: React.FC<{
  success: number;
  total: number;
  ty?: number;
  tx?: number;
  className?: (args: any) => string | string;
  onClick?: () => void;
}> = ({ success, total, className = '', tx = 0, ty = 0, onClick }) => {
  const percentage = total ? success / total : 0;

  let classNameParsed = className || '';
  if (typeof className === 'function') {
    classNameParsed = className(percentage);
  }

  return (
    <g
      transform={`translate(${tx}, ${ty})`}
      className={`efficiency-label ${classNameParsed} ${
        !!onClick ? 'clickable' : ''
      }`}
      onClick={onClick}
    >
      <g transform="translate(0, 0)">
        <text
          textAnchor="end"
          y="-2"
          x="36"
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="10"
          fontWeight="normal"
          fill="#424242"
        >
          <tspan>
            {success}/{total}
          </tspan>
        </text>
      </g>
      <PercentageLabel percentage={percentage} />
    </g>
  );
};
