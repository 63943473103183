import React, { Component, Fragment, useState, useEffect } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';

import Select from 'react-select';

import SportingEventCollection from 'domain/SportingEvent';
import VideoCollection from 'domain/Video';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Notification } from 'lib/Notification';

import { withTranslation } from 'react-i18next';
import { useSportingEventVideoPlayer } from 'modules/play/components/SportingEventVideoPlayerHOC';
import { Error } from 'lib/PlaceHolder';

const PlayerComponent = withTranslation('module.play')(
  ({ t, sportingEvent, video, clockRef, loader }) => {
    const {
      isLoaded,
      observationContext,
      videoPlayer,
      categorySelector,
      labelSelector,
    } = useSportingEventVideoPlayer(sportingEvent, video, t('fullMatch'));
    if (!isLoaded) {
      return loader;
    }

    clockRef.clock = observationContext.clock;

    return (
      <Fragment>
        <div className="form-group">
          <div className="video-player-container in-modal">{videoPlayer}</div>
        </div>
        <div className="form-group">
          <div className="row match-video-actions">{categorySelector}</div>
          {labelSelector}
        </div>
      </Fragment>
    );
  }
);

const SportingEventVideoPlayer = ({ sportingEvent, clockRef }) => {
  const [video, setVideo] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const video = await VideoCollection.getOrFetch(
        sportingEvent.mainVideoId()
      );
      setVideo(video);
    };
    loadData();
  }, [sportingEvent]);

  const loader = (
    <div
      style={{
        width: '100%',
        paddingTop: '56.65%',
        backgroundColor: 'black',
      }}
    />
  );
  if (video === null || sportingEvent.mainVideoId() !== video.id) {
    return loader;
  } else {
    return (
      <PlayerComponent
        sportingEvent={sportingEvent}
        video={video}
        clockRef={clockRef}
        loader={loader}
      />
    );
  }
};

const AddFragmentToLearningIndicator = withTranslation(
  'module.development.growthAmbition.addFragmentToLearningIndicator'
)(
  observer(
    class AddFragmentToLearningIndicator extends Component {
      constructor(props) {
        super(props);

        this.sportingEventOptions = [];
        this.dataState = observable('loading');
        this.description = observable('');
        this.rating = observable(null);
        this.selectedSportingEventId = observable(null);

        this.clockRef = { clock: null };

        this.onSave = this.onSave.bind(this);
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          const currentSession = Session.current();
          yield currentSession.isReady();
          yield SportingEventCollection.fetchIfEmpty();

          this.sportingEventOptions = SportingEventCollection.getPastMatches()
            .filter((sportingEvent) => sportingEvent.hasVideo())
            .map((sportingEvent) => ({
              label: sportingEvent.label,
              value: sportingEvent.id,
            }));

          this.selectedSportingEventId.set(this.sportingEventOptions[0].value);

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Fragments to learningLine',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      onSave = asyncAction(function* () {
        const currentTime = this.clockRef.clock.getTime();
        const { indicatorId, developmentPlan } = this.props;

        const selectedSportingEvent = SportingEventCollection.get(
          this.selectedSportingEventId.get()
        );
        const video = VideoCollection.get(selectedSportingEvent.mainVideoId());

        const startTime = Math.max(0, currentTime - 5);
        const endTime = Math.min(
          video.get('content').duration,
          currentTime + 5
        );

        yield developmentPlan.addIndicatorVideoFragment(
          indicatorId,
          video.id,
          startTime,
          endTime,
          { sportingEvent: selectedSportingEvent.toMetaData() },
          {
            comment: this.description.get(),
            rating: this.rating.get(),
          }
        );

        this.description.set('');
        this.rating.set(null);
        this.clockRef.clock.play();

        Notification.show('Videofragment toegevoegd');
      });

      onCancel = (args) => {
        console.debug('TODO implement onCancel args:', args);
        this.props.onCancel();
      };

      render() {
        const { t, children } = this.props;

        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const selectedOption = this.sportingEventOptions.filter(
          (option) => option.value === this.selectedSportingEventId.get()
        )[0];

        return (
          <div className="add-fragment-to-learning-indicator">
            {/*<div className="modal-header">*/}
            {/*<h5 className="modal-title">*/}
            {/*Fragment toevoegen aan ontwikkeldoel*/}
            {/*</h5>*/}
            {/*</div>*/}
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="aftli-match">{t('selectMatch')}</label>
                    <Select
                      id="aftli-match"
                      value={selectedOption}
                      options={this.sportingEventOptions}
                      onChange={action(({ value }) =>
                        this.selectedSportingEventId.set(value)
                      )}
                    />
                  </div>

                  <SportingEventVideoPlayer
                    sportingEvent={SportingEventCollection.get(
                      this.selectedSportingEventId.get()
                    )}
                    clockRef={this.clockRef}
                  />

                  <div className="form-group">
                    {/*<ButtonGroupSelect*/}
                    {/*options={this.props.developmentPlan.ratingOptions.map(option => {*/}
                    {/*return {*/}
                    {/*value: option.key,*/}
                    {/*label: <div style={{height: "20px", width: "20px"}} className={`rating-${option.color}`} />*/}
                    {/*}*/}
                    {/*})}*/}
                    {/*value={this.rating.get()}*/}
                    {/*onChange={action(e => {*/}
                    {/*this.rating.set(e.target.value);*/}
                    {/*this.clockRef.clock.pause();*/}
                    {/*})}*/}
                    {/*/>*/}

                    {this.props.developmentPlan.ratingOptions.map((option) => (
                      <div
                        key={option.key}
                        className="form-check form-check-inline"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="rating"
                          id={`rating-${option.key}`}
                          value={option.key}
                          checked={this.rating.get() === option.key}
                          onChange={action((e) => {
                            this.rating.set(e.target.value);
                            this.clockRef.clock.stop();
                          })}
                        />
                        <label
                          className={`form-check-label rating rating-${option.color}`}
                          htmlFor={`rating-${option.key}`}
                        >
                          {t(option.i18nKey)}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="aftli-comment"
                      rows="3"
                      onFocus={() => this.clockRef.clock.pause()}
                      onChange={action((e) =>
                        this.description.set(e.target.value)
                      )}
                      placeholder={t('commentPlaceholder')}
                      value={this.description.get()}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.onSave}
                    >
                      {t('common:save')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={this.onCancel}
                    >
                      {t('common:cancel')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  )
);

export { AddFragmentToLearningIndicator };
