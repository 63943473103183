enum DataState {
  LOADING,
  LOADED,
  DIRTY,
  UPDATING,
  UPDATED,
  ERROR,
}

export default DataState;
