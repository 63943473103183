import React, { useEffect, useState } from 'react';
import { Modal } from '../../../lib';

import {
  ObservationsEditCollectionContextProvider,
  ObservationsEditCollectionContext,
} from 'modules/observe/edit-collection-context';
import { DevelopmentPlanObservationDetails } from '../../observe/containers/observation-details/development-plan';
import { CustomObservationDetails } from '../../observe/containers/observation-details/custom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  changeset,
  delete_ as deleteObservation,
  get as getObservations,
  getComments,
  update as updateObservation,
} from '../../../domain/Observation';
import { VideoFragmentsPlayer } from 'lib/VideoFragmentsPlayer';

import { merge, cloneDeep } from 'lodash';
import { GetObservationUpdateMutation } from './utils/observationUpdateMutation';
import { GetObservationDeleteMutation } from './utils/observationDeleteMutation';

export const UnreadMessagesInLearningLine = learningLine => {
  const [refreshEnabled, setRefreshEnabled] = useState(true);
  const queryClient = useQueryClient();

  const queryKey = 'unread-comments';

  const fetchObservations = () => getObservations('?unseenComments=true');
  const { isLoading, data: unreadObservations } = useQuery(
    queryKey,
    fetchObservations,
    { refetchInterval: refreshEnabled ? 10000 : false, placeholderData: [] }
  );

  const observationUpdateMutation = GetObservationUpdateMutation(queryClient);

  const observationDeleteMutation = GetObservationDeleteMutation(queryClient);

  if (!unreadObservations.length) {
    return <UnreadBadge count={unreadObservations.length} />;
  } else {
    return (
      <>
        <ObservationsEditCollectionContextProvider
          observations={unreadObservations}
          initial={unreadObservations[0]}
        >
          <ObservationsEditCollectionContext.Consumer>
            {({ current, setCurrent }) => {
              if (!current) {
                return null;
              }

              let ObservationDetailsComponent = null;
              switch (true) {
                case current.code === 'DEVELOPMENT-PLAN':
                  ObservationDetailsComponent = DevelopmentPlanObservationDetails;
                  break;
                case current.code === 'CUSTOM':
                  ObservationDetailsComponent = CustomObservationDetails;
                  break;
              }
              return (
                current && (
                  <Modal
                    size="full-screen"
                    onCloseClick={() => {
                      queryClient.invalidateQueries(queryKey);
                      setRefreshEnabled(true);
                      setCurrent();
                    }}
                  >
                    <ObservationDetailsComponent
                      observation={current}
                      onCancel={() => setCurrent()}
                      onDelete={() =>
                        observationDeleteMutation.mutate({
                          observationId: current.observationId,
                        })
                      }
                      onUpdateObservation={attrs => {
                        observationUpdateMutation.mutate({
                          observation: current,
                          params: attrs,
                        });
                      }}
                    />
                  </Modal>
                )
              );
            }}
          </ObservationsEditCollectionContext.Consumer>
          <ObservationsEditCollectionContext.Consumer>
            {({
              current,
              setCurrent,
              startEdit,
              setSelector,
              observations,
            }) => {
              return (
                <UnreadBadge
                  onClick={() => {
                    setRefreshEnabled(false);
                    startEdit({ id: unreadObservations[0].observationId });
                  }}
                  count={unreadObservations.length}
                />
              );
            }}
          </ObservationsEditCollectionContext.Consumer>
        </ObservationsEditCollectionContextProvider>
      </>
    );
  }
};

const UnreadBadge = ({ onClick, count }) => {
  return (
    <div
      onClick={() => onClick()}
      className={`learningline-messages-unread btn btn-link ${
        count < 1 ? 'disabled' : ''
      }`}
    >
      <i className="i-messages i-light"></i>
      {count > 0 && <span className="counter">{count}</span>}
    </div>
  );
};
