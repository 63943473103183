import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';
import { VerifyEmailPage } from './verifyEmailPage';

const MODULE_NAME = 'email-verified';

export default new Module(
  MODULE_NAME,
  [
    {
      title: 'Email verified',
    },
  ],
  [new RouteExact('', VerifyEmailPage, 'emailVerified')]
);
