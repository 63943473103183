import './input-with-action.scss';
import { SubmittingButton } from '../SubmittingButton';

export interface ActionButtonProps {
  placeholder: string;
  value: string;
  button: { action: () => void; label: string };
  inputId: string;
}

export const InputWithActionButton = ({
  placeholder,
  value,
  button,
  inputId,
}: ActionButtonProps) => {
  return (
    <div className={'input-action-button'}>
      <input
        id={inputId}
        className={'form-control'}
        type={'text'}
        placeholder={placeholder}
        value={value}
        onChange={(e) => e.preventDefault()}
      />
      <SubmittingButton onClick={button.action} className={'btn btn-primary'}>
        {button.label}
      </SubmittingButton>
    </div>
  );
};
