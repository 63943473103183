import React from 'react';
import { withVisibleToggle } from 'lib/hocs/withVisibleToggle';

import clubPlaceholderIcon from 'img/icons/club_placeholder.svg';
import userPlaceHolder from 'img/icons/profile-picture-placeholder.svg';

import './user.scss';

import { withTranslation } from 'react-i18next';
import { ResourceGroupMenu, MembershipIcon } from './resource-group-menu';
import { Observer } from 'mobx-react';

const UserNavigation = withTranslation('menu')(
  withVisibleToggle(
    ({ targetRef, toggleRef, toggle, currentUser, session, app, t }) => {
      const teamMemberships = session
        .memberships({ resourceTypeName: 'team' })
        .slice();
      const clubMemberships = session
        .memberships({ resourceTypeName: 'club' })
        .slice();
      const educationMemberships = session
        .memberships({ resourceTypeName: 'education' })
        .slice();
      const resellerMembership = session
        .memberships({ resourceTypeName: 'reseller' })
        .slice();
      const exchangeMembership = session
        .memberships({ resourceTypeName: 'exchange' })
        .slice();
      const sharingGroupMembership = session
        .memberships({ resourceTypeName: 'SharingGroup' })
        .slice();
      const appDeveloperMembership = session
        .memberships({ resourceTypeName: 'app-developer' })
        .slice();
      const userMembership = session
        .memberships({ resourceTypeName: 'user' })
        .slice();
      const personMemberships = session
        .memberships({ resourceTypeName: 'person' })
        .slice();
      const learningGroupMemberships = session
        .memberships({ resourceTypeName: 'LearningGroup' })
        .slice();

      const memberships = teamMemberships;
      for (const clubMembership of clubMemberships) {
        const otherClubMembership = clubMemberships.find(
          (_otherClubMembership) => {
            return (
              _otherClubMembership !== clubMembership &&
              _otherClubMembership.tenantId === clubMembership.tenantId
            );
          }
        );
        const teamMembership = teamMemberships.find((teamMembership) => {
          return teamMembership.tenantId === clubMembership.tenantId;
        });
        if (typeof teamMembership === 'undefined' || !!otherClubMembership) {
          memberships.push(clubMembership);
        }
      }
      memberships.push(...educationMemberships);

      memberships.sort((a, b) =>
        a.targetResourceName.localeCompare(b.targetResourceName)
      );

      sharingGroupMembership.sort((a, b) =>
        a.targetResourceName.localeCompare(b.targetResourceName)
      );

      memberships.push(...resellerMembership);
      memberships.push(...exchangeMembership);
      memberships.push(...sharingGroupMembership);
      memberships.push(...appDeveloperMembership);
      memberships.push(...userMembership);
      memberships.push(...personMemberships);
      memberships.push(...learningGroupMemberships);

      let activeMembership_ = session.currentMembership();

      if (!activeMembership_) {
        activeMembership_ = {
          targetResourceName: '',
          roleNames: [''],
          resourceGroupId: 'not-set',
        };
      }

      const activeMembership = {
        ...activeMembership_,
        label: activeMembership_.targetResourceName,
        logoUrl: session.currentMembershipLogo(),
        roleName: t(
          'common.role:' + activeMembership_.roleNames[0].toLowerCase()
        ),
        resourceGroupId: activeMembership_.resourceGroupId,
      };

      return (
        <>
          <div className="user-nav" ref={toggleRef}>
            <div className="user-nav-profile">
              <Observer>
                {() => {
                  const profilePictureUrl = currentUser.profilePictureUrl
                    ? currentUser.profilePictureUrl
                    : userPlaceHolder;
                  return (
                    <>
                      <img
                        className="user-nav-logo i-logo"
                        src={profilePictureUrl}
                      />
                      <span className="user-nav-label">
                        {currentUser.label}
                      </span>
                    </>
                  );
                }}
              </Observer>
              <span className="user-nav-divider">|</span>
            </div>
            <div className="user-nav-role">
              <MembershipIcon
                membership={activeMembership}
                className="user-nav-logo"
              />
              <span className="user-nav-label">{activeMembership.label}</span>
            </div>
          </div>
          <ResourceGroupMenu
            targetRef={targetRef}
            memberships={memberships}
            activeMembership={activeMembership}
            session={session}
            toggle={toggle}
            app={app}
            t={t}
          />
        </>
      );
    }
  )
);

export { UserNavigation };
