export const calculateFocalPointOffset = (
    focusSize,
    contentSize,
    containerSize
) => {
    if (0) {
        console.log('Focus: ', focusSize);
        console.log('contentSize: ', contentSize);
        console.log('containerSize: ', containerSize);
    }

    const containerCenter = Math.round(containerSize / 2);
    const contentCenter = Math.round(contentSize / 2);
    const focusPointInImage = Math.round(
        ((parseFloat(focusSize) + 1) / 2) * contentSize
    );

    const contentOverflowPerSide = contentCenter - containerCenter;

    const focalpointDistanceToCenter = Math.round(
        focusPointInImage - contentSize / 2
    );

    let pxAmountToMove = 0;

    if (focusSize > 0) {
        pxAmountToMove = Math.min(
            contentOverflowPerSide,
            focalpointDistanceToCenter
        ).toFixed(0);
    } else {
        pxAmountToMove = Math.max(
            contentOverflowPerSide * -1,
            focalpointDistanceToCenter
        ).toFixed(0);
    }
    // return percentage to move (because of scale transforms by remotion player)

    // console.log('moving: ', Math.round((pxAmountToMove * 100) / contentCenter));
    return Math.round((pxAmountToMove * 100) / contentCenter);
};
