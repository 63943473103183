import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Session } from '../../../domain/Session';
import { sharingGroupCollection } from '../../../domain/SharingGroup';
import { useEffect, useState } from 'react';
import Loading from '../../../lib/Loading';

export const UserActionlist = ({ type }) => {
  const { t } = useTranslation('module.actionList');
  const currentSession = Session.current();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (currentSession.isFeatureAvailable('exchange'))
        sharingGroupCollection.fetchIfEmpty();
      setLoading(false);
    })();
  });

  const logAction = (event, attributes = {}) => {
    return currentSession.user.logAction('ActionLinkClicked', {
      component: 'Home:ActionList',
      event,
      ...attributes,
    });
  };
  if (loading) {
    return <Loading type={'spinner'} size={40} />;
  }

  const actionListPerRole = () => {
    const actionList = {
      player: [
        {
          link: '/match',
          label: t('match'),
          check: () => {
            return currentSession.isFeatureAvailable('play');
          },
        },
        {
          link: '/match/overview/poule',
          label: t('poule'),
          check: () => {
            return (
              currentSession.isFeatureAvailable('exchange') &&
              sharingGroupCollection
                .toArray()
                .filter((sharingGroup) => sharingGroup.seasonId === '2022-2023')
                .length
            );
          },
        },
        {
          // src/modules/navigation/resource-group-types/index.js
          link: '/development/pdp',
          label: t('development'),
          check: () => {
            const activeGroupType = currentSession.currentResourceGroupType();
            return (
              activeGroupType === 'team' &&
              currentSession.isFeatureAvailable('viewDevelopment')
            );
          },
        },
        {
          // source: src/modules/navigation/resource-group-types/index.js
          link: '/development/tdp',
          label: t('development'),
          check: () => {
            const activeGroupType = currentSession.currentResourceGroupType();
            return (
              activeGroupType === 'person' &&
              currentSession.isFeatureAvailable('viewDevelopment')
            );
          },
        },
        {
          link: '/playlist/overview',
          label: t('playlists'),
          check: () => {
            return currentSession.isFeatureAvailable('viewPlaylist');
          },
        },
        {
          link: '/user-management/user-profile',
          label: t('profile'),
          check: () => {
            return true;
          },
        },
      ],
      club: [
        {
          link: '/user-management/team',
          label: t('members'),
          check: () => {
            return currentSession.isFeatureAvailable('manageUsers');
          },
          tooltip: t('membersTooltip'),
        },
        {
          link: '/user-management/teams',
          label: t('addTeams'),
          check: () => currentSession.isFeatureAvailable('manageTeams'),
          tooltip: t('addTeamsTooltip'),
        },
        {
          link: '/user-management/shares/incoming',
          label: t('exchange'),
          check: () => {
            return currentSession.isFeatureAvailable('manageShares');
          },
          tooltip: t('exchangeTooltip'),
        },
        {
          link: '/user-management/team-tags',
          label: t('tags'),
          check: () => {
            return currentSession.isFeatureAvailable('manageCustomTags');
          },
          tooltip: t('tagsTooltip'),
        },
        {
          link: window.isSkillReflect
            ? '/recordings/overview#add'
            : '/match/overview/all#add',
          label: t('addMatch'),
          check: () => {
            return currentSession.isFeatureAvailable('createSportingEvent');
          },
          tooltip: t('addMatchTooltip'),
        },
      ],
    };
    if (type === 'player') {
      return actionList.player;
    } else {
      return actionList.club;
    }
  };

  let currentActions = actionListPerRole().filter((a) => a.check());

  if (currentActions.length) {
    return (
      <>
        <div className={'spacer'}></div>
        <ul className="user-action-list">
          {currentActions.map((action, index) => {
            return (
              <li key={`action-link-${index}`}>
                <Link
                  onClick={() => logAction(action.link)}
                  rel="noopener"
                  to={action.link}
                >
                  <div
                    data-tip=""
                    data-for={action.link}
                    className={`locked-content-tip`}
                    style={{ display: 'display' }}
                  >
                    {action?.tooltip && (
                      <ReactTooltip
                        id={action.link}
                        type="dark"
                        effect="solid"
                        // clickable={true}
                        className={'tooltip--styled lock-tooltip'}
                        backgroundColor="#495057"
                        place={'right'}
                      >
                        {action.tooltip}
                      </ReactTooltip>
                    )}
                    {action.label}
                  </div>
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  } else {
    return null;
  }
};
