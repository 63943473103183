import React from 'react';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { formatTime } from 'lib/FormattedTime';
import { CollapseIcon } from 'lib/CollapseIcon';
import { withToggle } from 'lib/hocs/withToggle';
import ReactTooltip from 'react-tooltip';

import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';

import './index.scss';

export const ObservationTree = withTranslation('module.observe.tree')(
  ({ onSelect, onEdit, rootObservations, clockTime }) => {
    // rootObservations = observations;

    // WARN: throws unique child key prop warning
    // Somehow observationId is not always set, why?!
    if (window.isSkillReflect) {
      rootObservations.sort((a, b) => {
        return observationTime(a).localeCompare(observationTime(b));
      });
    }

    return (
      <div className="observation-tree">
        <ReactTooltip className="tooltip" />
        {rootObservations.map(node => (
          <ObservationNode
            key={node.nodeId}
            node={node}
            onSelect={onSelect}
            onEdit={onEdit}
            clockTime={clockTime}
          />
        ))}
      </div>
    );
  }
);

// TODO: consider check if node has children before wrapping it in withToggle
const ObservationNode = observer(
  withToggle(
    ({
      level = 0,
      node,
      onSelect,
      onEdit,
      toggle,
      isToggled,
      clockTime,
      // current,
    }) => {
      // console.debug('BEN node.current', toJS(node.current));
      const {
        observations,
        startEdit,
      } = useObservationsEditCollectionContext();

      const handleSelect = () => {
        onSelect(node);
      };
      const handleEdit = () => {
        startEdit({ id: node.nodeId });

        // NOTE: merging required for merged tennis nodes
        const observation = observations.find(
          observation => observation.observationId === node.nodeId
        );
        observation._mergedObservationId = node.mergedObservationId;

        onEdit(node);
      };

      let postLabel = null;
      if (typeof node.postLabel === 'function') {
        postLabel = node.postLabel({ current: node.current.get() });
      } else {
        postLabel = node.postLabel;
      }

      let firstName, lastName, roleName, profilePictureUrl;
      if (!!node.creatorUser) {
        firstName = node.creatorUser.firstName;
        lastName = node.creatorUser.lastName;
        roleName = node.creatorUser.roleName;
        profilePictureUrl = node.creatorUser.profilePictureUrl;
      }

      return (
        <div
          className={`observation-node observation-node-level-${level} ${
            !node.children.length && level !== 0 ? 'observation-node-leaf' : ''
          }`}
        >
          <div
            className={classnames(`observation-node-item`, {
              current: node.current.get(),
              [`rating-${node.rating}`]: node.rating,
              [`rating-unknown`]: !node.rating,
            })}
          >
            <div className="observation-node-toggle">
              {!!node.children.length && (
                <CollapseIcon
                  dark={true}
                  collapsed={isToggled}
                  onClick={toggle}
                />
              )}
            </div>

            <div
              className={`observation-node-icon observation-node-icon-${roleName} `}
              style={
                profilePictureUrl
                  ? { backgroundImage: `url('${profilePictureUrl}')` }
                  : {}
              }
              data-tip={`${firstName} ${lastName} (${roleName})`}
            />
            <div className="observation-node-text" onClick={handleSelect}>
              {node.text}
            </div>
            <div className="observation-node-post-label" onClick={handleSelect}>
              {!!postLabel && (
                <div className="observation-node-badge">{postLabel}</div>
              )}
            </div>
            <div
              className="observation-node-pre-label"
              data-tip={node.rating ? `Rating: ${node.rating}` : ''}
              onClick={handleSelect}
            >
              {node.preLabel === false ? (
                ''
              ) : !!node.preLabel ? (
                <div className="observation-node-badge">{node.preLabel}</div>
              ) : (
                <span>&middot;</span>
              )}
            </div>
            <div className="observation-node-time" onClick={handleSelect}>
              {observationTime(node)}
            </div>
            <div className="observation-node-actions">
              {node.can('EDIT') && (
                <i className="i-edit" onClick={handleEdit} />
              )}
            </div>
          </div>
          {!isToggled && (
            <div className="observation-node-children">
              {node.children.map(child => (
                <ObservationNode
                  key={child.nodeId}
                  node={child}
                  onSelect={onSelect}
                  onEdit={onEdit}
                  level={level + 1}
                  clockTime={clockTime}
                />
              ))}
            </div>
          )}
        </div>
      );
    },
    { isToggled: true }
  )
);

const observationTime = observation => {
  if (observation.time.startTime !== undefined) {
    return duration(observation.time.startTime, observation.time.endTime);
  } else {
    return formatTime(observation.time.eventTime);
  }
};

const duration = (start, end) => {
  if (end === undefined) {
    return '-';
  }
  const SECS_PER_MIN = 60;
  const seconds = end - start;
  const minutes = Number.parseInt(seconds / SECS_PER_MIN);

  return seconds < SECS_PER_MIN ? `${seconds} s` : `${minutes} m`;
};
