import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { ResellerOverviewPage } from './overview';
import { NewClubPage } from './NewClubPage';
import { ViewClubPage } from './ViewClubPage';

const MODULE_NAME = 'reseller';

const resellerModule = new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('overview', ResellerOverviewPage, 'overview'),
    new RouteExact('new', NewClubPage, 'new'),
    new RouteExact('view/:tenantId', ViewClubPage, 'view'),
    new RouteRedirect('', 'overview'),
  ]
);

export default resellerModule;
