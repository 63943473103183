import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { HighlightsOverview } from './highlightsOverview';
import { HighlightsDetail } from './highlightsDetail';
import './highlights.scss';

const MODULE_NAME = 'highlights';

export default new Module(
  MODULE_NAME,
  [
    {
      title: 'Highlights',
    },
  ],
  [
    new RouteRedirect('', 'overview'),
    new RouteExact('overview', HighlightsOverview, 'overview'),
    new RouteExact(':id', HighlightsDetail, 'detail'),
  ]
);
