import React, { createRef, useState, useEffect, Fragment } from 'react';
import { withTranslation } from 'react-i18next';

import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Page } from 'lib/Page';
import { NoResults, Error, NoPermissions } from 'lib/PlaceHolder';
import { playlistCollection } from 'domain/Playlist';
import { Session } from 'domain/Session';
import { gotoRoute } from 'modules/route';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavDropDown } from 'lib/NavDropdown';
import { confirmModal } from 'lib/Confirmation';
import { Trans } from 'react-i18next';

import noresultImg from 'img/no_playlists.svg';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

const showFilters = window.isSkillReflect;

type DataState = 'loading' | 'loaded' | 'error' | 'upgrade';
type FilterId = string;

// const filters = () => {
//   if (!window.isSkillReflect) {
//     return [];
//   } else {
//   }
// };

const PlaylistOverviewPage = withTranslation('module.playlist')(({ t }) => {
  const [dataState, setDataState] = useState<DataState>('loading');
  const [currentFilterId, setCurrentFilterId] = useState<FilterId>('all');
  const [groups, setGroups] = useState<any>([]);

  const isFilterActive = (filter: FilterId) => currentFilterId === filter;

  const actions = [
    // User actions per video.
    {
      // Delete Playlist Function
      onClick: function (playlist) {
        const deleteAction = async () => {
          // Create action for confirmation window
          try {
            await playlist.destroy();
            setGroups(playlistCollection.toGroups(t));
          } catch (e) {
            logger.error(e, {
              transactionName: 'Unable to load Delete playlist',
            });
            setDataState('error');
          }
        };
        // Open confirmation window
        confirmModal({
          title: t('module.playlist:delete'),
          body: (
            <Trans
              i18nKey="module.playlist:deleteConfirm"
              values={{ label: playlist.name }}
            >
              Are you sure that you want to remove
              <strong>{playlist.name}</strong>?
            </Trans>
          ),
          actionLabel: t('common:confirm'),
          actionHandler: deleteAction,
        });
        return;
      },
      label: t('common:delete'),
      permission: function (playlist) {
        return playlist.groupId == 'own';
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const currentSession = Session.current();
        await currentSession.isReady();
        if (!currentSession.isFeatureAvailable('viewPlaylist')) {
          setDataState('upgrade');
          return Promise.resolve();
        }
        await playlistCollection.fetchIfEmpty();
        setGroups(playlistCollection.toGroups(t));
        setDataState('loaded');
      } catch (e) {
        logger.error(e, {
          transactionName: 'Unable to load Playlists',
        });
        setDataState('error');
      }
    })();
  }, []);

  if (dataState === 'loading') {
    return <Loading type={'fullscreen'} />;
  } else if (dataState === 'error') {
    return <Error />;
  } else if (dataState === 'upgrade') {
    return <NoPermissions resource={t('title')} />;
  } else {
    const pageProps = {
      title: t('title'),
      breadcrumbs: [
        { path: '/', title: 'Home' },
        {
          title: t('title'),
        },
      ],
      filters: showFilters
        ? ['all', 'trainer', 'trainee', 'peer'].map((role) => ({
            path: '#',
            title: t(`filterOptions.${role}`),
            onClick: () => setCurrentFilterId(role),
            isActive: isFilterActive(role),
          }))
        : [],
    };
    return (
      <Page {...pageProps}>
        {groups.length === 0 ? (
          <NoResults
            imgSrc={noresultImg}
            description={t('placeholder:noPlaylists')}
          />
        ) : (
          <PlaylistGroupsView
            groups={groups}
            filter={currentFilterId}
            actions={actions}
          />
        )}
      </Page>
    );
  }
});

const PlaylistGroupsView = ({ groups, filter, actions }) => (
  <div className="playlist-groups">
    {groups.map((group) => {
      const playlistsFiltered =
        filter === 'all'
          ? group.playlists
          : group.playlists.filter((playlist) => {
              const videoFragmentCreatorRoles = playlist.videoFragments.flatMap(
                (videoFragment) => videoFragment.creatorRoleName
              );

              return videoFragmentCreatorRoles.includes(filter);
            });
      return (
        <div key={group.groupId} className="playlist-group">
          <h2>{group.groupName}</h2>
          <div className="playlist-cards">
            {playlistsFiltered.map((playlist) => (
              <PlaylistCard
                key={playlist.playlistId}
                playlist={playlist}
                actions={actions}
              />
            ))}
          </div>
        </div>
      );
    })}
  </div>
);

const PlaylistCard = ({ playlist, actions }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = createRef();
  const agent = window.navigator.userAgent;
  // const isSafari = /^((?!chrome|android).)*safari/i.test(agent);
  const isIphone =
    agent.indexOf('iPhone') !== -1 || agent.indexOf('iPod') !== -1;
  // const autoPlay = isSafari;

  const playFunctions = {
    [isIphone ? 'onTouchStart' : 'onMouseEnter']: (_e) => {
      setIsPlaying(true);
    },
    [isIphone ? 'onTouchEnd' : 'onMouseLeave']: (_e) => {
      setIsPlaying(false);
      // e.stopPropagation();
      // e.preventDefault();
    },
  };
  const isDemo = playlist.isDemo();

  const availableActions = actions
    .filter((action) => action.permission(playlist) && !isDemo)
    .map((action) => {
      return (
        <DropdownItem
          key={action.label}
          onClick={(event) => {
            event.stopPropagation(); // Stop further delegation of click (eg. the default Playlist card click)
            action.onClick(playlist);
          }}
        >
          {action.label}
        </DropdownItem>
      );
    });

  return (
    <div
      className={`playlist-card ${
        playlist.itemCount >= 3
          ? playlist.itemCount <= 10
            ? 'stack'
            : 'stack stack--large'
          : ''
      }
        ${isDemo ? 'demo' : ''}`}
      {...(playlist.itemCount > 0 ? playFunctions : {})}
      onClick={() =>
        playlist.itemCount > 0 &&
        gotoRoute('playlist.view', { playlistId: playlist.playlistId })
      }
    >
      {isDemo && <span className="demo">Demo</span>}
      <div className={`playlist-card-overlay ${isPlaying ? 'is-playing' : ''}`}>
        <h3 className="playlist-card-title">{playlist.name}</h3>
        <h4 className="playlist-card-subtitle">
          <i className="i-playlist i-xs i-light" />
          {playlist.itemCount} clips
        </h4>
        {availableActions.length > 0 && (
          <div className="playlist-item-actions-secondary">
            <NavDropDown>
              <DropdownToggle
                onClick={(event) => event.stopPropagation()}
                color="link"
                className="playlist-item-action dropdown-menu-right"
              >
                <i className="i-dots-white" />
              </DropdownToggle>
              <DropdownMenu right>
                <Fragment>{availableActions}</Fragment>
              </DropdownMenu>
            </NavDropDown>
          </div>
        )}
      </div>
      <div
        className="playlist-card-background"
        style={
          playlist.poster
            ? { backgroundImage: `url(${playlist.poster.imageUrl})` }
            : {}
        }
      >
        {playlist.poster && isPlaying && (
          <video
            onPlay={(e) => isPlaying || e.target.pause()}
            autoPlay
            playsInline={true}
            loop
            src={playlist.poster.videoUrl}
            muted
            ref={videoRef}
          />
        )}
      </div>
      {playlist.itemCount >= 10 && <div className="stack-large" />}
      {playlist.itemCount >= 3 && <div className="stack-small" />}
    </div>
  );
};

const PlaylistOverviewSmall = withTranslation('module.playlist')(({ t }) => {
  const [dataState, setDataState] = useState<DataState>('loading');
  const [currentFilterId, setCurrentFilterId] = useState<FilterId>('all');
  const [groups, setGroups] = useState<any>([]);

  const isFilterActive = (filter: FilterId) => currentFilterId === filter;

  const actions = [
    // User actions per video.
    {
      // Delete Playlist Function
      onClick: function (playlist) {
        const deleteAction = async () => {
          // Create action for confirmation window
          try {
            await playlist.destroy();
            setGroups(playlistCollection.toGroups(t));
          } catch (e) {
            logger.error(e, {
              transactionName: 'Unable to Delete Playlist',
            });
            setDataState('error');
          }
        };
        // Open confirmation window
        confirmModal({
          title: t('module.playlist:delete'),
          body: (
            <Trans
              i18nKey="module.playlist:deleteConfirm"
              values={{ label: playlist.name }}
            >
              Are you sure that you want to remove{' '}
              <strong>{playlist.name}</strong>?
            </Trans>
          ),
          actionLabel: t('common:confirm'),
          actionHandler: deleteAction,
        });
        return;
      },
      label: t('common:delete'),
      permission: function (playlist) {
        return playlist.groupId == 'own';
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const currentSession = Session.current();
        await currentSession.isReady();
        if (!currentSession.isFeatureAvailable('viewPlaylist')) {
          setDataState('upgrade');
          return Promise.resolve();
        }
        await playlistCollection.fetchIfEmpty();
        setGroups(playlistCollection.toGroups(t));
        setDataState('loaded');
      } catch (e) {
        logger.error(e, {
          transactionName: 'Unable to load Playlist overview',
        });
        setDataState('error');
      }
    })();
  }, []);

  if (dataState === 'loading') {
    return <Loading type={'fullscreen'} />;
  } else if (dataState === 'error') {
    return <Error />;
  } else if (dataState === 'upgrade') {
    return (
      <div style={{ color: '#777' }}>
        <div className="pill">{t('groupNameOwn')}</div>
        <NoPermissions resource={t('title')} />
      </div>
    );
  } else {
    return (
      <>
        {groups.length === 0 ? (
          <NoResults
            imgSrc={noresultImg}
            description={t('placeholder:noPlaylists')}
          />
        ) : (
          groups.map((group, i) => (
            <div key={group.groupName}>
              {i >= 1 ? <div className={'spacer'} /> : ''}
              <div className="pill">{group.groupName}</div>
              <div className={'playlistRow'}>
                <Swiper
                  spaceBetween={8}
                  slidesPerView={1.25}
                  navigation
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  breakpoints={{
                    768: {
                      slidesPerView: 2.5,
                      spaceBetween: 12,
                    },
                    1200: {
                      slidesPerView: 3.25,
                      spaceBetween: 16,
                    },
                    1600: {
                      slidesPerView: 4.25,
                      spaceBetween: 16,
                    },
                  }}
                >
                  {group.playlists.map((playlist) => (
                    <SwiperSlide
                      key={playlist.playlistId}
                      className={'swiper-slide'}
                    >
                      <PlaylistCard
                        key={playlist.playlistId}
                        playlist={playlist}
                        actions={actions}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ))
        )}
      </>
    );
  }
});

export { PlaylistOverviewPage, PlaylistOverviewSmall };
