import React, { useEffect, useState, useRef } from 'react';

import {
  Form as LibForm,
  uiSchemaDefaults as libUiSchemaDefaults,
} from 'lib/Form';
import { ObservationDetailsFormButtons } from '../containers/observation-details/form-buttons';
import { ObservationDetailsNavigationButtons } from '../containers/observation-details/navigation-buttons';
import { ObservationDetailsVideoTime } from '../containers/observation-details/video-time';

const FormFieldObservationVideoTime = ({
  onChange,
  schema,
  uiSchema,
  ...props
}) => {
  return (
    <ObservationDetailsVideoTime
      observation={uiSchema.options.observation}
      video={uiSchema.options.video}
      onChange={onChange}
      {...props}
    />
  );
};

const uiSchemaDefaults = {
  classNames: `${libUiSchemaDefaults.classNames} observe-form`,
};

const fieldsDefaults = {
  //   FormFieldObservationVideoTime,
};

export const Form = ({
  children,
  uiSchema = {},
  fields = {},
  onSubmit,
  onDelete,
  observation,
  video,
  ...props
}) => {
  const uiSchemaMerged = { ...uiSchemaDefaults, ...uiSchema };
  const fieldsMerged = { ...fieldsDefaults, ...fields };
  const data = useRef<object>();
  const [startTime, setStartTime] = useState(observation.startTime);
  const [endTime, setEndTime] = useState(observation.endTime);

  const handleVideoTimeChange = ({
    startTime,
    endTime,
  }: {
    startTime: number;
    endTime: number;
  }) => {
    setStartTime(startTime);
    setEndTime(endTime);
  };

  useEffect(() => {
    setStartTime(observation.startTime);
    setEndTime(observation.endTime);
  }, [observation]);

  const handleChange = ({ formData }: { formData: object }) => {
    data.current = formData;
  };

  const handleUpdate = () => {
    const submitData = { ...data.current, startTime, endTime };
    onSubmit(submitData);
  };

  return (
    <div className="observation-form">
      <div>
        <ObservationDetailsVideoTime
          observation={observation}
          video={video}
          onChange={handleVideoTimeChange}
          {...props}
        />
        <ObservationDetailsNavigationButtons onUpdate={handleUpdate} />
      </div>
      <div>
        <LibForm
          uiSchema={uiSchemaMerged}
          fields={fieldsMerged}
          onChange={handleChange}
          {...props}
        >
          {children}

          {/* <ObservationDetailsNavigationButtons onUpdate={handleUpdate} /> */}
          <ObservationDetailsFormButtons
            className="jsonschema-form-actions"
            onUpdate={handleUpdate}
            onDelete={onDelete}
          />
        </LibForm>
      </div>
    </div>
  );
};
