import Command from '../../infra/Messaging/Command';

Command.register(
  'ObservationLogger.StartRangeObservation',
  'groupId code attributes'
);
Command.register(
  'ObservationLogger.EndRangeObservation',
  'groupId attributes time ?description'
);
Command.register('ObservationLogger.RemovePartialObservation', 'groupId');
Command.register(
  'ObservationLogger.SetObservationAttributes',
  'groupId code attributes'
);
Command.register(
  'ObservationLogger.SetPointObservation',
  'code attributes description'
);
Command.register(
  'ObservationLogger.SetCustomPointObservation',
  'code attributes description durationBefore durationAfter'
);

Command.register(
  'ObservationLogger.ToggleRangeObservationExclusive',
  'groupId code attributes'
);
Command.register('ObservationLogger.TurnoffGroup', 'groupId');

Command.register('ObservationLogger.RestoreSnapshot', 'snapshot');

Command.register(
  'ObservationLogger.UpdateObservation',
  'observationId attributes description clockId triggerTime startTime endTime'
);

Command.register(
  'ObservationLog.AddObservation',
  'clockId observationId code attributes triggerTime startTime endTime description'
);
Command.register('ObservationLog.RemoveObservation', 'observationId');

Command.register(
  'ObservationLog.UpdateObservation',
  'observationId attributes description clockId triggerTime startTime endTime'
);
