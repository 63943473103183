import { Tour } from '../Tour';

import { matchRuleShort } from 'utils/matchRuleShort';

export default new Tour({
  key: 'dashboardHockey',
  autoStart: true,
  isApplicableTo: (path, currentSession) => {
    return (
      matchRuleShort(path, '/reporting/detail/*/default') &&
      currentSession.sportType() === 'hockey'
    );
  },
  steps: () => [
    {
      target: '.possession .clickable',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step1Possession',
    },
    {
      target: '.goal',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step2Goal',
    },
    {
      target: '.action',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step3Action',
    },
    {
      target: '.twentythreetocircle',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step4TwentyThreeToCircle',
    },
    {
      target: '.twentythreetoopp',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step5TwentyThreeToOpp',
    },
    {
      target: '.circletoopp',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step6CircleToOpp',
    },
    {
      target: '.circletogoal',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step7CircleToGoal',
    },
    {
      target: '.opptogoal',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step8OppToGoal',
    },
    {
      target: '.funnelbuildup',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step9BuildUp',
    },
    {
      target: '.turnover',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step10Turnover',
    },
    {
      target: '.freehit',
      offset: -10,
      i18nKey: 'module.tours.dashboardHockey:step11Freehit',
    },
  ],
  callbacks: {
    start: () => {
      // console.log("tour started");
    },
    nextStep: index => {
      // console.log(`tour nextStep ${index}`);
    },
    end: () => {
      // console.log("tour ended");
    },
  },
});
