import React, { Component } from 'react';

import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';

import PersonCollection from 'domain/Person';
import { withTranslation } from 'react-i18next';

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

const PercentageLabel = ({ percentage, x = 0, y = 0 }) => {
  if (isNaN(percentage)) {
    percentage = '- ';
  } else {
    percentage = (percentage * 100).toFixed(0);
  }
  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect
        className="background-rect-filler"
        fill="#ffffff"
        x="0"
        y="0"
        width="38"
        height="20"
        rx="7"
      />
      <rect
        className="background-rect"
        x="0"
        y="0"
        width="38"
        height="20"
        rx="7"
      />
      <text
        textAnchor="end"
        x="27"
        y="15"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="14"
        fontWeight="600"
        fill="#000000"
      >
        <tspan>{percentage}</tspan>
      </text>
      <text
        x="27.267"
        y="15"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="10"
        fontWeight="normal"
        fill="#000000"
      >
        <tspan>%</tspan>
      </text>
    </g>
  );
};

const EfficiencyLabel = ({
  success,
  total,
  className,
  x = 0,
  y = 0,
  onClick,
}) => {
  const percentage = total ? success / total : 0;
  return (
    <g
      transform={`translate(${x}, ${y})`}
      className={`efficiency-label ${className} ${
        !!onClick ? 'clickable' : ''
      }`}
      onClick={onClick}
    >
      <g transform="translate(0, 0)">
        <text
          textAnchor="end"
          y="-2"
          x="36"
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="10"
          fontWeight="normal"
          fill="#424242"
        >
          <tspan>
            {success}/{total}
          </tspan>
        </text>
      </g>
      <PercentageLabel percentage={percentage} />
    </g>
  );
};

const COLORS = [
  '#DEC762',
  '#62A4DE',
  '#50A352',
  '#9762DE',
  '#DE6262',
  '#DE62C2',
  // '#D4DE62',
  '#62D4DE',
  '#6270DE',
  '#69DE62',
];

const Table = ({ title, columns, rows, onRowClick, onCellClick }) => {
  const rowTitleWidth = 160;
  const columnTitleHeight = 25;

  const columnWidth = 46;
  const rowHeight = 39;

  return (
    <g>
      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="12"
        fontWeight="normal"
        fill="#000000"
      >
        <tspan>{title}</tspan>
      </text>
      {columns.map((column, idx) => (
        <text
          key={idx}
          textAnchor="middle"
          x={rowTitleWidth + (0.5 + idx) * columnWidth}
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="12"
          fontWeight="normal"
          fill="#000000"
        >
          <tspan>{column.title}</tspan>
        </text>
      ))}

      {rows.map((row, idx) => {
        const top = columnTitleHeight + idx * rowHeight;
        const rowTitle = row.title;
        const titleWidth = getTextWidth(
          rowTitle,
          '600 10pt Avenir-Heavy, Avenir'
        );
        return (
          <g
            key={idx}
            transform={`translate(0, ${top})`}
            className={`dashboard-table-row ${row.disabled ? 'disabled' : ''}`}
          >
            <g>
              <rect
                className="player-label"
                fill={row.color}
                fillRule="nonzero"
                x="40"
                y="-12"
                width={titleWidth + 20}
                height="34"
                rx="7"
              />
              {row.active && (
                <rect
                  className="player-label-filler"
                  fill={row.color}
                  fillRule="nonzero"
                  x="13"
                  y="-12"
                  width={rowTitleWidth + columns.length * columnWidth - 13}
                  height="34"
                  rx="7"
                />
              )}
              <defs>
                <clipPath id={`circle-clip-${idx}`}>
                  <circle rx="0" ry="0" r="17" transform="translate(17, 5)" />
                </clipPath>
              </defs>
              s
              <image
                x="0"
                y="-12"
                xlinkHref={row.icon}
                height="34"
                width="34"
                clipPath={`url(#circle-clip-${idx})`}
              />
              <text
                fontFamily="Avenir-Heavy, Avenir, Lato"
                fontSize="12"
                fontWeight="600"
                fill="#FFFFFF"
              >
                <tspan x="50" y="10">
                  {rowTitle}
                </tspan>
              </text>
              {/* transparent click layer */}
              <rect
                fill="#ffffff"
                opacity="0"
                x="0"
                y="-12"
                height="34"
                width={rowTitleWidth}
                onClick={() => onRowClick(row)}
                className="clickable"
              />
            </g>

            {columns.map((column, idx) => {
              const successMetric = column.successMetric || 'scored';
              const success = row.cell.c(column.key).v(successMetric);
              const total = row.cell.c(column.key).count;
              return (
                <EfficiencyLabel
                  key={idx}
                  className={column.classFn && column.classFn(success, total)}
                  x={rowTitleWidth + idx * columnWidth}
                  success={success}
                  total={total}
                  onClick={
                    total > 0
                      ? () => onCellClick(row.cell.c(column.key), successMetric)
                      : null
                  }
                />
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

const getClassFn = (positive, neutral, high, mid) => {
  return (success, total) => {
    const percentage = total ? ((success / total) * 100).toFixed(0) : 0;
    let className;
    if (percentage > positive) {
      className = 'positive';
    } else if (percentage > neutral) {
      className = 'neutral';
    } else {
      className = 'negative';
    }

    if (total > high) {
      className += ' freq-high';
    } else if (total > mid) {
      className += ' freq-mid';
    } else if (total > 0) {
      className += ' freq-low';
    } else {
      className += ' freq-none';
    }
    return className;
  };
};

const cumSum = v => {
  return v.reduce((a, x, i) => [...a, a.length > 0 ? x + a[i - 1] : x], []);
};

const BarChart = ({ title, values, width = 400, x = 0, y = 0 }) => {
  const sums = cumSum(values.map(v => v.value));
  const total = sums[sums.length - 1];

  return (
    <g transform={`translate(${x}, ${y})`}>
      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="12"
        fontWeight="normal"
        fill="#000000"
      >
        <tspan>{title}</tspan>
      </text>

      <g transform="translate(0, 20)">
        {values.map(({ value, color, disabled }, idx) => {
          const percentage = ((value / total) * 100).toFixed(0);
          const barLeft = ((sums[idx - 1] || 0) / total) * width;
          const barWidth = (value / total) * width;

          const className = disabled
            ? 'bar-chart-item disabled'
            : 'bar-chart-item';

          let rects;
          if (idx === 0) {
            rects = [
              <rect
                key={idx}
                className={className}
                width={barWidth}
                fill={color}
                y="0"
                rx="7"
                x={barLeft}
                height="31"
              />,
              <rect
                className={className}
                key={'bla-${idx}'}
                width={barWidth - 7}
                fill={color}
                y="0"
                x={barLeft + 7}
                height="31"
              />,
            ];
          } else if (idx === values.length - 1) {
            rects = [
              <rect
                key={idx}
                className={className}
                width={barWidth}
                fill={color}
                y="0"
                rx="7"
                x={barLeft}
                height="31"
              />,
              <rect
                key={'bla-${idx}'}
                className={className}
                width={barWidth - 7}
                fill={color}
                y="0"
                x={barLeft - 7}
                height="31"
              />,
            ];
          } else {
            rects = (
              <rect
                key={idx}
                className={className}
                width={barWidth}
                fill={color}
                y="0"
                x={barLeft}
                height="31"
              />
            );
          }

          const percentageLeft = barLeft + barWidth / 2;
          return [
            rects,
            !disabled && (
              <text
                key={`text-${idx}`}
                y="45"
                textAnchor="middle"
                x={percentageLeft}
                fontFamily="Avenir-Heavy, Avenir, Lato"
                fontSize="12"
                fontWeight="600"
                fill="#000000"
              >
                <tspan>{percentage}%</tspan>
                {/*<tspan x="737.576" y="83" font-family="Avenir-Book, Avenir, Lato" font-size="10" font-weight="normal">%</tspan>*/}
              </text>
            ),
          ];
        })}
      </g>
    </g>
  );
};

const REAL_FIELD_WIDTH = 20;
const REAL_FIELD_HEIGHT = 20;

const pol2cart = ({
  distance,
  angle,
  centerX,
  centerY,
  imageWidth,
  imageHeight,
}) => {
  if (angle > 90) {
    angle -= 360;
  }
  angle += 90;

  const phi = (angle / 360) * 2 * Math.PI;
  const rho = distance;

  const x = ((rho * Math.cos(phi)) / REAL_FIELD_WIDTH) * imageWidth + centerX;
  const y = ((rho * Math.sin(phi)) / REAL_FIELD_HEIGHT) * imageHeight + centerY;
  return { x, y };
};

const Label = ({ text, value, total, className, width, x = 0, y = 10 }) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text
        textAnchor="end"
        y="0"
        x={width}
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="10"
        fontWeight="normal"
        fill="#424242"
      >
        <tspan>
          {value}/{total}
        </tspan>
      </text>
      <g transform="translate(0, 6)">
        <rect fill="#F2F2F2" x="0" width={width} height="32" rx="7" />
        <text
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="14"
          fontWeight="normal"
          fill="#000000"
        >
          <tspan x="10" y="21">
            {text}
          </tspan>
        </text>
        <g
          transform={`translate(${width - 38 - 10}, 6)`}
          className={`efficiency-label ${className}`}
        >
          <PercentageLabel percentage={value / total} />
        </g>
      </g>
    </g>
  );
};

const PositionField = withTranslation('module.reporting')(
  ({ title, rows, onClick, t }) => {
    const shots = [];
    for (const row of rows) {
      const _shots = row.cell.getGrouped('positions.shot.dynamic', [
        'angle',
        'distance',
        'result',
        'observationId',
      ]);
      for (let i = 0; i < _shots.length; i++) {
        shots.push({
          ..._shots[i],
          onClick: () =>
            onClick(row.cell.c('positions.shot'), {
              observationId: _shots[i].observationId,
            }),
        });
      }
    }
    const COLOR_MAP = {
      GOAL: '#0FCF85',
      HIT: '#F5A623',
      MISS: '#CF0F0F',
    };

    const resultCount = {
      GOAL: 0,
      HIT: 0,
      MISS: 0,
    };
    shots.map(({ result }) => (resultCount[result] += 1));

    return (
      <g transform="scale(0.9)">
        <text
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="12"
          fontWeight="normal"
          fill="#000000"
        >
          <tspan y="10">{title}</tspan>
        </text>

        <g transform="translate(0, 30)">
          <rect fill="#003600" x="0" y="0" height="459" width="459" />
          <g transform="translate(22, 0)">
            <g>
              <path
                d="M57.6196248,7.36602771e-15 L357.380375,-9.504691e-15 C393.071674,37.2681298 415,87.8232474 415,143.5 C415,258.099086 322.099086,351 207.5,351 C92.9009144,351 0,258.099086 0,143.5 C0,87.8232474 21.9283259,37.2681298 57.6196248,-1.0658141e-14 Z"
                fill="#00691F"
                fillRule="nonzero"
              />
              <circle fill="#009C52" cx="208" cy="144" r="138.5" />
              <circle fill="#0FCF85" cx="208" cy="144" r="69.5" />

              <g opacity="0.2" fill="#000000" transform="translate(150, 86)">
                <circle cx="57.5" cy="115.5" r="57.5" />
                <rect fillRule="nonzero" x="0" y="61" width="115" height="58" />
                <ellipse cx="58" cy="57.5" rx="58" ry="57.5" />
              </g>
              <circle stroke="#979797" fill="#F8E71C" cx="208" cy="144" r="9" />

              <text
                fontFamily="Avenir-Book, Avenir, Lato"
                fontSize="16"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="195" y="354">
                  9m
                </tspan>
              </text>
              <text
                fontFamily="Avenir-Book, Avenir, Lato"
                fontSize="16"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="197" y="286">
                  6m
                </tspan>
              </text>
              <text
                fontFamily="Avenir-Book, Avenir, Lato"
                fontSize="16"
                fontWeight="normal"
                fill="#FFFFFF"
              >
                <tspan x="197" y="216">
                  3m
                </tspan>
              </text>
              <g>
                {shots.map((shot, idx) => {
                  const { x, y } = pol2cart({
                    distance: parseFloat(shot.distance),
                    angle: parseFloat(shot.angle),
                    centerX: 208,
                    centerY: 144,
                    imageWidth: 459,
                    imageHeight: 459,
                  });

                  return (
                    <circle
                      className="clickable"
                      onClick={shot.onClick}
                      key={idx}
                      fill={COLOR_MAP[shot.result]}
                      fillRule="nonzero"
                      cx={x}
                      cy={y}
                      r="4.5"
                    />
                  );
                })}
              </g>
            </g>
          </g>
        </g>

        <g transform="translate(0, 500)">
          <Label
            x="0"
            width="100"
            text={t('goalDescription')}
            className="positive"
            value={resultCount['GOAL']}
            total={shots.length}
          />
          <Label
            x="140"
            width="145"
            text={t('hitKorfDescription')}
            className="neutral"
            value={resultCount['HIT']}
            total={shots.length}
          />
          <Label
            x="320"
            width="140"
            text={t('missDescription')}
            className="negative"
            value={resultCount['MISS']}
            total={shots.length}
          />
        </g>
      </g>
    );
  }
);

const PlayerDashboard = withTranslation('module.reporting')(
  observer(
    class PlayerDashboard extends Component {
      COLUMNS = [
        {
          key: 'efficiency.shot.dynamic.per_circle.distance_circle:0',
          title: '3m',
          classFn: getClassFn(35, 30, 8, 3),
        },
        {
          key: 'efficiency.shot.dynamic.per_circle.distance_circle:1',
          title: '6m',
          classFn: getClassFn(30, 25, 8, 3),
        },
        {
          key: 'efficiency.shot.dynamic.per_circle.distance_circle:2',
          title: '9m',
          classFn: getClassFn(20, 15, 8, 3),
        },
        {
          key: 'efficiency.shot.dynamic.per_circle.distance_circle:3',
          title: '>9m',
          classFn: getClassFn(15, 10, 8, 3),
        },
        {
          key: 'efficiency.shot.dynamic.totals',
          title: 'TOT',
          classFn: getClassFn(20, 15, 8, 3),
        },
        {
          key: 'efficiency.shot.static.type:PENALTY',
          title: 'SW',
          classFn: getClassFn(99, 80, 8, 3),
        },
        {
          key: 'efficiency.shot.static.type:FREE-BALL',
          title: 'VW',
          classFn: getClassFn(50, 30, 8, 3),
        },
        {
          key: 'efficiency.shot.dynamic.rebound',
          title: 'RB',
          successMetric: 'ball_possession_after_shot',
          classFn: getClassFn(60, 40, 8, 3),
        },
      ];

      constructor() {
        super();

        this.selectedPersonIds = observable([]);
      }

      onClickPerson = action(personId => {
        const idx = this.selectedPersonIds.indexOf(personId);
        if (idx !== -1) {
          this.selectedPersonIds.splice(idx, 1);
        } else {
          this.selectedPersonIds.push(personId);
        }
      });

      _getRows() {
        let colorIdx = 0;

        const rows = this.props.report
          .iterate('person_id')
          .map(({ value: personId, cell }) => {
            const person = PersonCollection.get(personId) || {
              firstName: this.props.t('unkownDescription'),
              personPlaceHolder: '',
              id: personId,
              unknown: !personId,
            };

            return {
              icon: person.profilePictureUrl,
              title: person.firstName,
              cell,
              isUnknown: person.unknown,
              personId: person.id,
            };
          });

        rows.sort((a, b) => a.title.localeCompare(b.title));
        for (const row of rows) {
          row.color = !row.isUnknown ? COLORS[colorIdx++] : '#888';
          row.disabled =
            this.selectedPersonIds.length > 0 &&
            this.selectedPersonIds.indexOf(row.personId) === -1;
          row.active =
            this.selectedPersonIds.length > 0 &&
            this.selectedPersonIds.indexOf(row.personId) !== -1;
        }
        return rows;
      }

      render() {
        const rows = this._getRows();

        return (
          <svg
            viewBox="0 0 1024 535"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(0, 14)">
                <NavLink to="default">
                  <text
                    fontFamily="Avenir-Heavy, Avenir, Lato"
                    fontSize="16"
                    fontWeight="600"
                    fill="#1f99c8"
                    textDecoration="underline"
                  >
                    <tspan>{this.props.t('linkPlayerStatistics')}</tspan>
                  </text>
                </NavLink>
              </g>

              <g transform="translate(0, 30)">
                <g>
                  <PositionField
                    title={this.props.t('positionResultDescription')}
                    rows={rows.filter(({ disabled }) => !disabled)}
                    onClick={this.props.onClick}
                  />
                </g>
                <g transform="translate(490, 10)">
                  <BarChart
                    title={this.props.t('shareofShotsDescription')}
                    width="520"
                    values={rows.map(({ cell, color, disabled }) => ({
                      color,
                      disabled,
                      value: cell.c('efficiency.shot.dynamic.totals').count,
                    }))}
                  />
                </g>
                <g transform="translate(490, 120)">
                  <Table
                    columns={this.COLUMNS}
                    rows={rows}
                    onRowClick={row => this.onClickPerson(row.personId)}
                    onCellClick={(cell, successMetric) =>
                      this.props.onClick(cell, { successMetric })
                    }
                    title={this.props.t('playEfficiencyDescription')}
                  />
                </g>
              </g>
            </g>
          </svg>
        );
      }
    }
  )
);

// TODO: replace NavLink with some reportState navigation shit (like reportState.setPositionId(positionId) and s
//       don't mess with the location directly)
const PlayerDashboardLink = withTranslation('module.reporting')(
  ({ positionId, description, x, y, textAnchor = 'start', t }) => (
    <NavLink to={positionId}>
      <g>
        <text
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="16"
          fontWeight="600"
          fill="#000000"
          textAnchor={textAnchor}
          textDecoration="none"
        >
          <tspan x={x} y={y}>
            {description}
          </tspan>
        </text>
        <text
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="16"
          fontWeight="600"
          fill="#1f99c8"
          textDecoration="underline"
          textAnchor={textAnchor}
        >
          <tspan class="playerstats" x={x} y={y + 22}>
            {t('linkPlayerStatistics')}
          </tspan>
        </text>
        <text
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="14"
          fontWeight="normal"
          fill="#000000"
          textAnchor={textAnchor}
        >
          <tspan x={x} y={y + 44}>
            {t('shotEfficiencyDescription')}
          </tspan>
        </text>
      </g>
    </NavLink>
  )
);

const MainDashboard = ({
  homeTeamReport,
  awayTeamReport,
  homeTeam,
  awayTeam,
  onClick,
  qualifyStatistics,
}) => {
  return (
    <svg
      viewBox="0 0 1024 535"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(0, -217)">
          <g transform="translate(-1, 223)">
            <Section
              report={homeTeamReport}
              party="home"
              team={homeTeam}
              x="0"
              y="0"
              position="ATTACK"
              onClick={onClick}
              qualifyStatistics={qualifyStatistics}
            />

            <Section
              report={homeTeamReport}
              party="home"
              team={homeTeam}
              x="0"
              y="278"
              position="DEFENCE"
              onClick={onClick}
              qualifyStatistics={qualifyStatistics}
            />

            <Section
              report={awayTeamReport}
              party="away"
              team={awayTeam}
              x="340"
              y="0"
              position="DEFENCE"
              onClick={onClick}
              qualifyStatistics={qualifyStatistics}
            />

            <Section
              report={awayTeamReport}
              party="away"
              team={awayTeam}
              x="340"
              y="278"
              position="ATTACK"
              onClick={onClick}
              qualifyStatistics={qualifyStatistics}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const Dashboard = ({
  homeTeamReport,
  awayTeamReport,
  homeTeam,
  awayTeam,
  reportState,
  onClick,
}) => {
  const QualifyStatistics = (metric, value) => {
    const high = '#28E89E';
    const average = '#FF8300';
    const low = '#CF0F0F';

    switch (metric) {
      case 'aanval':
        if (value > 35) return high;
        if (value > 20) return average;
        else return low;

      case 'rebound':
        if (value > 65) return high;
        if (value > 45) return average;
        else return low;

      case 'ballLoss':
        if (value < 10) return high;
        if (value < 20) return average;
        else return low;

      case 'schot':
        if (value > 25) return high;
        if (value > 15) return average;
        else return low;

      case 'default':
        return low;
    }
  };

  switch (reportState) {
    case 'h_a':
    case 'h_d':
    case 'a_a':
    case 'a_d':
      const report = {
        h_a: homeTeamReport.c('position:ATTACK'),
        h_d: homeTeamReport.c('position:DEFENCE'),
        a_a: awayTeamReport.c('position:ATTACK'),
        a_d: awayTeamReport.c('position:DEFENCE'),
      }[reportState];
      return <PlayerDashboard report={report} onClick={onClick} />;

    case 'default':
      return (
        <MainDashboard
          homeTeamReport={homeTeamReport}
          awayTeamReport={awayTeamReport}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          onClick={onClick}
          qualifyStatistics={QualifyStatistics}
        />
      );
  }
};

const Section = withTranslation('module.reporting')(
  ({ report, team, x, y, position, onClick, party, qualifyStatistics, t }) => {
    let verticalAlign = '';
    let horizontalAlign = '';
    let anchor = 'start';
    let rightOffset = 0;
    let firstPositionDescription = '';
    let positionId = '';
    if (party === 'home') {
      verticalAlign = 'top';
      horizontalAlign = 'left';
      firstPositionDescription = t('firstAttackDescription');
      positionId = 'h_a';
      if (position === 'DEFENCE') {
        verticalAlign = 'bottom';
        positionId = 'h_d';
        firstPositionDescription = t('firstDefenceDescription');
      }
    } else {
      rightOffset = 680;
      anchor = 'end';
      verticalAlign = 'bottom';
      horizontalAlign = 'right';
      firstPositionDescription = t('firstAttackDescription');
      positionId = 'a_a';
      if (position === 'DEFENCE') {
        verticalAlign = 'top';
        positionId = 'a_d';
        firstPositionDescription = t('firstDefenceDescription');
      }
    }

    return (
      <g transform={`translate(${x},${y})`}>
        <g transform={`translate(${rightOffset},1)`}>
          <PlayerDashboardLink
            description={`${firstPositionDescription} ${team.shortLabel}`}
            positionId={positionId}
            x={0}
            y={20}
            textAnchor={anchor}
          />
        </g>
        <g class="shotzone" transform="translate(1, 95)">
          <DistanceChart
            party={party}
            shotPerCircle={`position:${position}.efficiency.shot.per_circle`}
            report={report}
            onClick={onClick}
          />
        </g>
        <g class="last10attacks" transform="translate(190, 224)">
          <AttackHistory
            report={report}
            metric={`position:${position}.history.last_10_attacks`}
            sideHor={horizontalAlign}
            sideVert={verticalAlign}
            onClick={onClick}
          />
        </g>
        <g class="attack" transform="translate(212,58)">
          <BigDonut
            report={report.c(`position:${position}.efficiency.attack.scored`)}
            title={t('attackDescription')}
            qualify="aanval"
            successMetric="scored"
            onClick={onClick}
            qualifyStatistics={qualifyStatistics}
          />
        </g>
        <g class="shot" transform="translate(409,0)">
          <Donut
            report={report.c(`position:${position}.efficiency.shot.totals`)}
            title={t('shotDescription')}
            qualify="schot"
            successMetric="scored"
            onClick={onClick}
            qualifyStatistics={qualifyStatistics}
          />
        </g>

        <g class="rebound" transform="translate(409,75)">
          <Donut
            report={report.c(`position:${position}.efficiency.shot.rebound`)}
            title={t('reboundDescription')}
            qualify="rebound"
            successMetric="ball_possession_after_shot"
            onClick={onClick}
            qualifyStatistics={qualifyStatistics}
          />
        </g>

        <g class="ball_loss" transform="translate(409,150)">
          <Donut
            report={report.c(
              `position:${position}.efficiency.attack.ball_loss`
            )}
            title={t('ballLossDescription')}
            qualify="ballLoss"
            successMetric="ball_loss"
            onClick={onClick}
            qualifyStatistics={qualifyStatistics}
          />
        </g>
      </g>
    );
  }
);

const Donut = ({
  report,
  title,
  successMetric,
  onClick,
  qualifyStatistics,
  qualify,
}) => {
  const successCount = report.v(successMetric);
  const totalCount = report.v('total_count');
  const successPercentage = totalCount ? successCount / totalCount : 0;
  const backgroundDonutColor = '#F2F2F2';

  const percentage = successPercentage * 100;
  const color = qualifyStatistics(qualify, percentage);

  return (
    <g className="clickable" onClick={() => onClick(report, { successMetric })}>
      <g strokeWidth="12">
        <path
          d="M22,44 C34.1502645,44 44,34.1502645 44,22 C44,9.8497355 34.1502645,0 22,0 C9.8497355,0 0,9.8497355 0,22 C0,34.1502645 9.8497355,44 22,44 Z"
          stroke={backgroundDonutColor}
          strokeDasharray="289,277"
        />
        <path
          d="M22,44 C34.1502645,44 44,34.1502645 44,22 C44,9.8497355 34.1502645,0 22,0 C9.8497355,0 0,9.8497355 0,22 C0,34.1502645 9.8497355,44 22,44 Z"
          stroke={color}
          strokeDasharray={(successPercentage * 138).toFixed(0) + ', 444'}
          transform="translate(22, 22) scale(-1, 1) rotate(180) translate(-22, -22) "
        />
      </g>
      <text
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="12"
        fontWeight="600"
        fill="#000000"
      >
        <tspan x="-77" y="27">
          {title}
        </tspan>
      </text>
      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="10"
        fontWeight="normal"
        fill="#424242"
      >
        <tspan x="45" y="51">
          {successCount + '/' + totalCount}
        </tspan>
      </text>
      <text
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="12"
        fontWeight="600"
        fill="#000000"
      >
        <tspan x="7.679" y="26">
          {(successPercentage * 100).toFixed(0)}
        </tspan>
        <tspan
          x="29"
          y="26"
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="10"
          fontWeight="normal"
        >
          %
        </tspan>
      </text>
    </g>
  );
};

const DistanceBadge = ({ party, circle, title }) => {
  let x = 0;
  let y = 0;
  let fill = '#FFFFFF';
  if (party === 'home') {
    if (circle == 0) {
      x = 19;
      y = 107;
    }
    if (circle == 1) {
      x = 19;
      y = 66;
    }
    if (circle == 2) {
      x = 19;
      y = 25;
    }
    if (circle == 3) {
      x = 144;
      y = 12;
      fill = '#424242';
    }
  } else {
    if (circle == 0) {
      x = 138;
      y = 107;
    }
    if (circle == 1) {
      x = 138;
      y = 66;
    }
    if (circle == 2) {
      x = 138;
      y = 25;
    }
    if (circle == 3) {
      x = -6;
      y = 12;
      fill = '#424242';
    }
  }

  return (
    <text
      fontFamily="Avenir-Book, Avenir, Lato"
      fontSize="12"
      fontWeight="normal"
      fill={fill}
    >
      <tspan x={x} y={y}>
        {title}
      </tspan>
    </text>
  );
};

const Badge = ({
  party,
  percentage,
  circle,
  success_count,
  total_count,
  onClick,
}) => {
  let bg = '#0FCF85';
  let color = '#FFFFFF';
  let x = 0;
  let y = 93;
  let countDisplayOffsetX = 0;
  let countDisplayOffsetY = 0;
  let countDisplayColor = '#FFFFFF';

  if (party === 'away') {
    bg = '#CF0F0F';
    countDisplayOffsetX = -5;
    countDisplayOffsetY = -5;
    switch (circle) {
      case '0':
        x = 80;
        bg = '#CF0F0F';
        break;
      case '1':
        x = 40;
        bg = '#9C0000';
        break;
      case '2':
        x = 0;
        bg = '#690000';
        countDisplayColor = '#000000';
        break;
      case '3':
        bg = '#F2F2F2';
        color = '#000000';
        countDisplayColor = '#000000';
        x = -6;
        y = 19;
        countDisplayOffsetX = 2;
        countDisplayOffsetY = 35;
        break;
      default:
        x = 0;
    }
  } else {
    countDisplayOffsetX = 15;
    countDisplayOffsetY = -5;
    switch (circle) {
      case '0':
        x = 49;
        bg = '#0FCF85';
        break;
      case '1':
        x = 94;
        bg = '#009C52';
        break;
      case '2':
        x = 138;
        bg = '#00691F';
        countDisplayColor = '#000000';
        break;
      case '3':
        x = 135;
        y = 19;
        bg = '#F2F2F2';
        color = '#000000';
        countDisplayColor = '#000000';
        countDisplayOffsetX = 20;
        countDisplayOffsetY = 35;
        break;
      default:
        x = 49;
    }
  }

  return (
    <g onClick={onClick} className={!!onClick ? 'clickable' : ''}>
      <g transform={`translate(${x}, ${y})`}>
        <rect fill={bg} x="0" y="0" width="40" height="20" rx="7" />
        <text
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="14"
          fontWeight="600"
          fill={color}
        >
          <tspan x="3" y="15">
            {percentage}
          </tspan>
          <tspan
            x="27.864"
            y="15"
            fontFamily="Avenir-Book, Avenir, Lato"
            fontSize="10"
            fontWeight="normal"
          >
            %
          </tspan>
        </text>
        <text
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="10"
          fontWeight="normal"
          fill={countDisplayColor}
        >
          <tspan x={countDisplayOffsetX} y={countDisplayOffsetY}>
            {success_count + '/' + total_count}
          </tspan>
        </text>
      </g>
    </g>
  );
};

const DistanceChart = ({ report, shotPerCircle, party, onClick }) => {
  let circles = '';
  let rectX = 0;
  let rightOffset = 0;
  let fill1 = '',
    fill2 = '',
    fill3 = '';
  if (party === 'away') {
    rectX = 146;
    rightOffset = 508;
    fill1 = '#CF0F0F';
    fill2 = '#9C0000';
    fill3 = '#690000';
    circles = (
      <g>
        <path
          d="M169,3.27718552 L169,122 L16.0246004,122 C17.3561778,54.3945177 72.5758287,0 140.5,0 C150.306097,0 159.847403,1.13370164 169,3.27718552 Z"
          fill="#690000"
        />
        <path
          d="M169,46.9901478 L169,122 L57.0720248,122 C58.9066062,77.507427 95.5567012,42 140.5,42 C150.508959,42 160.106607,43.7610314 169,46.9901513 Z"
          fill="#9C0000"
        />
        <path
          d="M169,92.293295 L169,122 L100.012102,122 C100.549535,99.8183463 118.918542,82 141.5,82 C152.048671,82 161.678108,85.8882929 169,92.293295 Z"
          fill="#CF0F0F"
        />
      </g>
    );
  } else {
    rectX = 0;
    fill1 = '#0FCF85';
    fill2 = '#009C52';
    fill3 = '#00691F';
    circles = (
      <g>
        <path
          d="M-2.30926389e-14,3.27718552 C9.15259687,1.13370164 18.6939033,0 28.5,0 C96.4241713,0 151.643822,54.3945177 152.9754,122 L0,122 L0,3.27718552 Z"
          fill="#00691F"
        />
        <path
          d="M-1.88755678e-11,46.9901511 C8.89339372,43.7610314 18.4910414,42 28.5,42 C73.4432988,42 110.093394,77.507427 111.927974,121.99997 L3.23117427e-27,122 L3.23117427e-27,46.9901511 Z"
          fill="#009C52"
        />
        <path
          d="M1.77635684e-15,92.293295 C7.32189184,85.8882929 16.9513286,82 27.5,82 C50.0814577,82 68.4504652,99.8183463 68.9878978,122 L0,122 L0,92.293295 Z"
          fill="#0FCF85"
        />
      </g>
    );
  }

  return (
    <g transform={`translate(${rightOffset},0)`}>
      <circle
        stroke="#979777"
        fill="#F8E71C"
        transform="translate(28, 100) scale(-1, 1) translate(-28, -100) "
        cx="28"
        cy="100"
        r="5"
      />
      {circles}
      <rect fill={fill3} x={rectX} y="0" width="28" height="42" />
      <rect fill={fill2} x={rectX} y="42" width="28" height="40" />
      <rect fill={fill1} x={rectX} y="82" width="28" height="40" />

      <Badge
        party={party}
        // TODO cleanup these props
        circle="0"
        percentage={report.e(shotPerCircle + '.distance_circle:0', 'scored', 0)}
        success_count={report.v(shotPerCircle + '.distance_circle:0.scored')}
        total_count={report.v(shotPerCircle + '.distance_circle:0.total_count')}
        onClick={() =>
          onClick(report.c(shotPerCircle + '.distance_circle:0'), {
            successMetric: 'scored',
          })
        }
      />

      <Badge
        party={party}
        circle="1"
        percentage={report.e(shotPerCircle + '.distance_circle:1', 'scored', 0)}
        success_count={report.v(shotPerCircle + '.distance_circle:1.scored')}
        total_count={report.v(shotPerCircle + '.distance_circle:1.total_count')}
        onClick={() =>
          onClick(report.c(shotPerCircle + '.distance_circle:1'), {
            successMetric: 'scored',
          })
        }
      />

      <Badge
        party={party}
        circle="2"
        percentage={report.e(shotPerCircle + '.distance_circle:2', 'scored', 0)}
        success_count={report.v(shotPerCircle + '.distance_circle:2.scored')}
        total_count={report.v(shotPerCircle + '.distance_circle:2.total_count')}
        onClick={() =>
          onClick(report.c(shotPerCircle + '.distance_circle:2'), {
            successMetric: 'scored',
          })
        }
      />

      <Badge
        party={party}
        circle="3"
        percentage={report.e(shotPerCircle + '.distance_circle:3', 'scored', 0)}
        success_count={report.v(shotPerCircle + '.distance_circle:3.scored')}
        total_count={report.v(shotPerCircle + '.distance_circle:3.total_count')}
        onClick={() =>
          onClick(report.c(shotPerCircle + '.distance_circle:3'), {
            successMetric: 'scored',
          })
        }
      />

      <DistanceBadge circle="0" party={party} title="3m" />
      <DistanceBadge circle="1" party={party} title="6m" />
      <DistanceBadge circle="2" party={party} title="9m" />
      <DistanceBadge circle="3" party={party} title="&gt; 9m" />
    </g>
  );
};

const BigDonut = ({
  report,
  title,
  successMetric,
  onClick,
  qualifyStatistics,
  qualify,
}) => {
  const successCount = report.v(successMetric);
  const totalCount = report.v('total_count');
  const successPercentage = totalCount ? successCount / totalCount : 0;
  const backgroundDonutColor = '#F2F2F2';

  const percentage = successPercentage * 100;
  const color = qualifyStatistics(qualify, percentage);

  return (
    <g className="clickable" onClick={() => onClick(report, { successMetric })}>
      <g strokeWidth="12">
        <path
          d="M43,86 C66.7482442,86 86,66.7482442 86,43 C86,19.2517558 66.7482442,0 43,0 C19.2517558,0 0,19.2517558 0,43 C0,66.7482442 19.2517558,86 43,86 Z"
          stroke={backgroundDonutColor}
          strokeDasharray="289,277"
        />
        <path
          d="M43,86 C66.7482442,86 86,66.7482442 86,43 C86,19.2517558 66.7482442,0 43,0 C19.2517558,0 0,19.2517558 0,43 C0,66.7482442 19.2517558,86 43,86 Z"
          stroke={color}
          strokeDasharray={(successPercentage * 270).toFixed(0) + ', 444'}
          transform="translate(43, 43) scale(-1, 1) rotate(180) translate(-43, -43) "
        />
      </g>
      <text
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="12"
        fontWeight="600"
        fill="#000000"
      >
        <tspan x="24" y="-20">
          {title}
        </tspan>
      </text>

      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="10"
        fontWeight="normal"
        fill="#424242"
      >
        <tspan x="26" y="108">
          {successCount + '/' + totalCount}
        </tspan>
      </text>
      <text
        fontFamily="Avenir-Heavy, Avenir, Lato"
        fontSize="25"
        fontWeight="600"
        fill="#000000"
      >
        <tspan x="15.6" y="52">
          {(successPercentage * 100).toFixed(0)}
        </tspan>
        <tspan
          x="60"
          y="52"
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="16"
          fontWeight="normal"
        >
          %
        </tspan>
      </text>
    </g>
  );
};

const AttackHistory = withTranslation('module.reporting')(
  ({ report, metric, sideHor, sideVert, onClick, t }) => {
    const history = [];
    let titleX = -175;
    if (sideHor === 'right') {
      titleX = 365;
    }

    if (sideHor === 'left') {
      if (sideVert === 'top') {
        history.push(
          <path
            d="M0,217 L495,217 C498.865993,217 502,220.134007 502,224 L502,244 C502,247.865993 498.865993,251 495,251 L0,251 L0,217 Z"
            fill="#F2F2F2"
            transform="translate(-190, -224)"
          />
        );
      } else {
        history.push(
          <path
            d="M0,495 L495,495 C498.865993,495 502,498.134007 502,502 L502,522 C502,525.865993 498.865993,529 495,529 L0,529 L0,495 Z"
            fill="#F2F2F2"
            transform="translate(-190, -502)"
          />
        );
      }
    } else {
      if (sideVert === 'top') {
        history.push(
          <path
            d="M523,217 L1018,217 C1021.86599,217 1025,220.134007 1025,224 L1025,244 C1025,247.865993 1021.86599,251 1018,251 L523,251 L523,217 Z"
            fill="#F2F2F2"
            transform="translate(244, 10) scale(-1, 1) translate(-244, -10) translate(-534, -224)"
          />
        );
      } else {
        history.push(
          <path
            d="M523,495 L1018,495 C1021.86599,495 1025,498.134007 1025,502 L1025,522 C1025,525.865993 1021.86599,529 1018,529 L523,529 L523,495 Z"
            fill="#F2F2F2"
            transform="translate(244, 10) scale(-1, 1) translate(-244, -10) translate(-534, -502)"
          />
        );
      }
    }

    for (var i = 0; i < 9; i++) {
      const BackgroundColor =
        report.g(metric, i, 2) > 0 ? '#0FCF85' : '#F2F2F2';
      const Color = report.g(metric, i, 2) > 0 ? '#FFFFFF' : '#424242';

      let x1 = 4 + (i - 1) * 39;
      let x2 = 10 + (i - 1) * 39;
      if (sideHor === 'right') {
        x1 = 43 + (i - 1) * 39;
        x2 = 49 + (i - 1) * 39;
      }

      history.push(
        <g key={i}>
          <rect
            fill={BackgroundColor}
            x={x1}
            y="0"
            width="20"
            height="20"
            rx="10"
          />
          <text
            fontFamily="Avenir-Heavy, Avenir, Lato"
            fontSize="14"
            fontWeight="600"
            fill={Color}
          >
            <tspan x={x2} y="15">
              {report.g(metric, i, 1)}
            </tspan>
          </text>
        </g>
      );
    }

    history.push(
      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        key={titleX}
        fontSize="14"
        fontWeight="normal"
        fill="#000000"
      >
        <tspan x={titleX} y="14">
          {t('shotsAttackDescription')}
        </tspan>
      </text>
    );

    return <g>{history}</g>;
  }
);

export default Dashboard;
