import React from 'react';
import { ObservationDetailsTennisBase } from './base';

const schema = {
  type: 'object',
  properties: {
    attributes: {
      type: 'object',
      properties: {
        area: {
          type: 'string',
        },
        result: {
          type: 'string',
          enum: ['OUT', 'NET'],
          i18n: {
            title: 'sports.tennis.serviceErrors:title',
            enumNames: 'sports.tennis.serviceErrors:results',
          },
        },
        comment: {
          type: 'string',
          i18n: {
            title: 'comment',
          },
        },
      },
    },
  },
};

export const ObservationDetailTennisServiceError = props => (
  <ObservationDetailsTennisBase {...props} schema={schema} />
);
