import React, { Component } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { CommandHistory } from '../infra/Messaging/CommandBus';

const Notification = observer(
  class Notification extends Component {
    static currentMessage = observable(null);
    static _currentTimeout = null;

    static show = action((message, delay = 700, state = 'success') => {
      let _currentTimeout = null;
      clearTimeout(Notification._currentTimeout);
      Notification.currentMessage.set({ message, state });

      Notification._currentTimeout = setTimeout(
        action(() => {
          Notification.currentMessage.set(null);
        }),
        delay
      );
    });

    render() {
      if (Notification.currentMessage.get() === null) {
        return null;
      }
      const { message, state } = Notification.currentMessage.get();
      let className = '';
      if (state === 'failed') {
        className = 'failed';
      }

      return (
        <div className={`toast text-center ${className}`}>
          <div className="relative width-100 height-100">
            <div className="flex-content">
              <div className="toast-text relative">{message}</div>
            </div>
          </div>
        </div>
      );
    }
  }
);

const commandHistory = CommandHistory.instance();
commandHistory.on(
  'tagAdded',
  (description, { showNotification } = { showNotification: true }) => {
    if (showNotification) {
      Notification.show(`"${description}" toegevoegd`);
    }
  }
);

commandHistory.on('tagRemoved', description => {
  Notification.show(`"${description}" ongedaan gemaakt`);
});

export { Notification };
