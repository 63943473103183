import tutorials from './tutorials.json';

export function getTutorials(tutorialId) {
  if (tutorialId) {
    return tutorials.find((tutorial) => tutorial.videoId === tutorialId);
  }
  return tutorials.filter((tutorial) => {
    return tutorial.brandCode === (window.isSkillReflect ? 'sr' : 'ttv');
  });
}
