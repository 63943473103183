const groupObservationsPerTeam = observations => {
  const observationsPerTeam = {};
  let currentTeamId = 'unknown';

  for (const observation of observations) {
    let teamId = 'unknown';

    if (observation.attributes_.teamId !== undefined) {
      teamId = observation.attributes_.teamId;
      if (observation.code === 'POSSESSION') {
        currentTeamId = teamId;
      }
    } else {
      if (observation.code === 'CUSTOM') {
        teamId = 'unknown'; // custom tags never belong to a team
      } else {
        teamId = currentTeamId;
      }
    }
    if (typeof observationsPerTeam[teamId] === 'undefined') {
      observationsPerTeam[teamId] = [];
    }
    observationsPerTeam[teamId].push(observation);
  }
  return observationsPerTeam;
};

const groupObservationsPerDescription = observations => {
  const groups = {};
  for (const observation of observations) {
    const key = observation.description;
    if (typeof groups[key] === 'undefined') {
      groups[key] = [];
    }

    groups[key].push(observation);
  }

  const observationGroups = Object.entries(groups).map(
    ([description, observations]) => {
      return {
        description,
        observations,
      };
    }
  );

  observationGroups.sort((a, b) => {
    const bDescription = b.description.toLowerCase();
    const aDescription = a.description.toLowerCase();
    if (aDescription < bDescription) {
      return -1;
    } else if (aDescription > bDescription) {
      return 1;
    } else {
      return 0;
    }
  });
  return observationGroups;
};

export { groupObservationsPerTeam, groupObservationsPerDescription };
