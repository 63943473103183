import React, { useState } from 'react';
import { Modal } from '../../lib';
import api from '../../utils/ApiClient';
import { Formik } from 'formik';
import { SubmittingButton } from '../../lib/SubmittingButton';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { initial } from 'lodash';
import ImagePicker from '../../lib/ImagePicker/ImagePicker';
import { useQuery } from 'react-query';
import { Session } from '../../domain/Session';
import Loading from '../../lib/Loading';
import { dotPropGet } from '../../lib/util';
import { getTemplate } from '../../remotion/templateRegistry';
import { getBackgroundMusicTracks } from '../../remotion/audio/backgroundMusicRegistry';
import { Slider, Handles, Rail, Ticks, Tracks } from 'react-compound-slider';
import { SimpleSlider } from '../../lib/TimeSlider';
import { Color } from './contentEditView';

export const VideoSettings = ({ callback, highlightVideo }) => {
  const [open, setOpen] = useState(false);
  if (open) {
    return (
      <>
        <button className={'btn btn-primary'}>
          <i className={'i-settings i-sm i-light'} /> Settings
        </button>
        <VideoSettingsModal
          highlightVideo={highlightVideo}
          onClose={() => {
            setOpen(false);
          }}
          onSave={() => {
            callback();
            setOpen(false);
          }}
        />
      </>
    );
  }
  return (
    <button onClick={() => setOpen(true)} className={'btn btn-primary'}>
      <i className={'i-settings i-sm i-light'} /> Settings
    </button>
  );
};

const VideoSettingsModal = ({ onClose, onSave, highlightVideo }) => {
  const { t } = useTranslation();
  const currentSession = Session.current();

  const { isLoading, data: sponsors } = useQuery(
    ['sponsors'],
    async () => {
      const { data } = await api.get('sponsors');
      return data;
    },
    {}
  );

  if (isLoading) {
    return null;
  }
  const template = getTemplate(highlightVideo.templateId);
  const backgroundTracks =
    template?.availableBackgroundMusic &&
    getBackgroundMusicTracks(template.availableBackgroundMusic);

  const initialValues = {
    name: highlightVideo?.name,
    sponsors: {
      sponsorIds: highlightVideo?.config?.sponsors?.sponsorIds ?? [],
      duration: highlightVideo?.config?.sponsors?.duration ?? 7,
    },
    backgroundMusic: {
      trackId: highlightVideo?.config?.backgroundMusic?.trackId ?? null,
      volume: (highlightVideo?.config?.backgroundMusic?.volume ?? 0.6) * 100,
    },
    colorPrimary: highlightVideo?.config?.colors?.colorPrimary ?? '#212529',
    colorSecondary: highlightVideo?.config?.colors?.colorSecondary ?? '#4386c5',
  };

  const formConfig = {
    name: {
      type: 'text',
      placeholder: 'Enter a name for your highlightVideo',
      label: 'Name of video',
      validate: (value) => value !== null,
    },
    colorPrimary: {
      type: 'color',
      label: 'Primary color',
    },
    colorSecondary: {
      type: 'color',
      label: 'Secondary color',
    },
  };
  if (backgroundTracks) {
    formConfig['backgroundMusic.trackId'] = {
      type: 'select',
      label: 'Background music',
      options: [...backgroundTracks, { label: '- none- ', value: null }],
    };
    formConfig['backgroundMusic.volume'] = {
      type: 'percentage',
      label: 'Volume',
      min: 0,
      max: 100,
      validate: (value) => value !== null,
    };
  }

  if (sponsors.length > 0) {
    formConfig['sponsors.sponsorIds'] = {
      type: 'imagePicker',
      placeholder: 'Enter Sponsor',
      options: sponsors,
      label: 'Sponsor',
    };
    formConfig['sponsors.duration'] = {
      type: 'number',
      label: 'Sponsor duration',
      min: 2,
      max: 16,
      validate: (value) => value !== null,
    };
  }

  const validate = (values) => {
    let errors = {};
    Object.keys(formConfig).forEach((key) => {
      const formInputConfig = formConfig[key];
      if (
        !!formInputConfig.validate &&
        !formInputConfig.validate(dotPropGet(values, key))
      ) {
        errors[key] = 'error';
      }
    });
    return errors;
  };

  // video name

  // video sponsors

  // Select intro's ?

  return (
    <Modal
      onCloseClick={() => {
        onClose();
      }}
      size={'lg'}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          await updateVideoSettings(values, highlightVideo, onSave);
        }}
        validate={validate}
      >
        {({
          handleSubmit,
          values,
          submitCount,
          errors,
          setFieldValue,
          onReset,
          isSubmitting,
        }) => {
          const validClassName = (key) => {
            if (!submitCount) {
              return '';
            }

            return errors[key] ? 'is-invalid' : 'is-valid';
          };

          return (
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="modal-header">
                <h5 className="modal-title">Edit Highlightvideo</h5>
              </div>
              <div className="modal-body">
                {Object.entries(formConfig).map(([key, input]) => {
                  return (
                    <div key={key} className="row">
                      <div className="col-12">
                        <div className={`form-group ${validClassName(key)}`}>
                          <FormInput
                            _key={key}
                            value={dotPropGet(values, key)}
                            input={input}
                            callback={setFieldValue}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="modal-footer">
                <div className="form-actions">
                  <SubmittingButton
                    onClick={async () => await handleSubmit(values)}
                    className={'btn btn-primary'}
                  >
                    {t('common:save')}
                  </SubmittingButton>
                  <button className={'btn btn-link'} onClick={onClose}>
                    {t('common:cancel')}
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const updateVideoSettings = async (values, highlightVideo, callback) => {
  await api.patch(
    `highlights/v2/highlightVideos/${highlightVideo.highlightVideoId}`,
    {
      templateId: highlightVideo.templateId,
      name: values.name,
      content: highlightVideo.content,
      config: {
        backgroundMusic: {
          volume: parseInt(values.backgroundMusic.volume) / 100,
          trackId: values.backgroundMusic.trackId,
        },
        colors: {
          colorPrimary: values.colorPrimary,
          colorSecondary: values.colorSecondary,
        },
        sponsors: values.sponsors,
      },
    }
  );
  callback();
};

const FormInput = ({ value, _key, input, callback }) => {
  switch (input.type) {
    case 'text':
      return (
        <div>
          <label>{input.label}</label>
          <input
            className={'form-control'}
            type={input.type}
            value={value}
            onChange={(e) => callback(_key, e.target.value)}
          />
        </div>
      );
    case 'select':
      return (
        <div>
          <label>{input.label}</label>
          <Select
            value={input.options.find((option) => option.value === value)}
            options={input.options}
            onChange={(e) => callback(_key, e.value)}
          />
        </div>
      );
    case 'number':
      return (
        <div>
          <label>{input.label}</label>
          <div style={{ maxWidth: '75px' }}>
            <input
              type={'number'}
              value={value}
              onChange={(e) => callback(_key, parseInt(e.target.value))}
              min={input?.min}
              max={input?.max}
              className="form-control"
            />
          </div>
        </div>
      );
    case 'percentage':
      return (
        <div>
          <label>{input.label}</label>
          <div style={{ maxWidth: '200px', zIndex: 0, position: 'relative' }}>
            <SimpleSlider
              values={[value]}
              domain={[input.min, input.max]}
              onChange={(e) => callback(_key, e)}
              step={5}
              valueSuffix={' %'}
            />
          </div>
        </div>
      );
    case 'imagePicker':
      return (
        <div>
          <label>{input.label}</label>
          <ImagePicker
            imageStyle={{ padding: '1em' }}
            aspectRatio={'3/2'}
            selectMultiple={true}
            images={input.options.map((option) => {
              return {
                selected:
                  value !== null && value.indexOf(option.sponsorId) !== -1,
                sponsorId: option.sponsorId,
                imageSrc: option.imageUrl,
                label: option.name,
              };
            })}
            callback={(value) =>
              callback(
                _key,
                value
                  .filter((option) => option.selected)
                  .map((option) => option.sponsorId)
              )
            }
          />
        </div>
      );
    case 'color':
      return (
        <Color
          onChange={(unused, value) => {
            callback(_key, value);
          }}
          name={input.label}
          label={input.label}
          value={value ?? '#212529'}
          defaults={{
            color: value,
            sponsors: [],
          }}
        />
      );
  }
};
