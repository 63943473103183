import React, { useState, useEffect } from 'react';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandHistory } from '../../../infra/Messaging';
import { PointTriggerButton } from 'utils/ObservationInput/components/TriggerButton';
import {
  VideoObservationPanel,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';
import { DevelopmentPlanQueryService } from 'domain/Development/DevelopmentPlanQueryService';
import learningLineCollection from 'domain/Development/LearningLine';

const DevelopmentObservationInput = ({
  observationContext,
  args,
  afterAdd,
}) => {
  const [buttons, setButtons] = useState([]);
  const [developmentPlanId, setDevelopmentPlanId] = useState(null);
  const [learningLineId, setLearningLineId] = useState(null);

  useEffect(() => {
    (async () => {
      const developmentPlan = await DevelopmentPlanQueryService.developmentPlanByResourceGroup();
      await learningLineCollection.fetchIfEmpty();

      const learningLine = learningLineCollection.get(args);
      setLearningLineId(learningLine.id);
      setButtons(
        learningLine.phases.map(phase => {
          return {
            phaseId: phase.phaseId,
            description: phase.name,
          };
        })
      );

      setDevelopmentPlanId(developmentPlan.id);
    })();
  }, [observationContext, args]);

  useEffect(() => {
    observationContext.observationLogger.on(
      'afterObservationAdded',
      (code, attributes, triggerTime, startTime, endTime, description) => {
        CommandHistory.instance().tagHistoryItem(description);
      }
    );
  }, [observationContext]);

  useEffect(
    () =>
      observationContext.observationLogger.on('afterObservationAdded', () => {
        if (afterAdd) {
          const observations = observationContext.observationLogger.observationLog.toArray();
          const lastObservation = observations[observations.length - 1];
          afterAdd({ id: lastObservation.id });
        }
      }),
    [observationContext, afterAdd]
  );

  if (!developmentPlanId) {
    return null;
  }

  const { observationLogger } = observationContext;

  return (
    <VideoObservationPanel
      observationContext={observationContext}
      showRecordButton={false}
    >
      <EventButtonGroup>
        {buttons.map((button, i) => {
          return (
            <InputTrigger
              key={i}
              type="point"
              observationCode="DEVELOPMENT-PLAN"
              attributes={{
                developmentPlanId,
                learningLineId,
                phaseId: button.phaseId,
              }}
              observationLogger={observationLogger}
              description={button.description}
              render={props => (
                <PointTriggerButton {...props} keys={(i + 1).toString()} />
              )}
            />
          );
        })}
      </EventButtonGroup>
    </VideoObservationPanel>
  );
};

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'development.default',
  component: DevelopmentObservationInput,
  options,
  title: 'Development',
  usedKeys: [],
});
