import React from 'react';

import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';

import styles from './navigation-buttons.module.scss';

export const ObservationDetailsNavigationButtons = ({
  onUpdate,
  className = '',
}: {
  onUpdate: (observationId) => void;
  className?: string;
}) => {
  const {
    setCurrent,
    currentIdx,
    getPrev,
    getNext,
    selectedObservations,
    current: observation,
  } = useObservationsEditCollectionContext();
  const next = getNext();
  const prev = getPrev();

  const handlePrev = () => {
    onUpdate(observation.observationId);
    if (prev) {
      setCurrent(prev);
    }
  };

  const handleNext = () => {
    onUpdate(observation.observationId);
    if (next) {
      setCurrent(next);
    }
  };

  return (
    <div className={`form-actions ${className} ${styles.container}`}>
      <button className="btn btn-primary" onClick={handlePrev} disabled={!prev}>
        &lt;
      </button>
      <div className="current">
        {currentIdx() + 1} / {selectedObservations().length}
      </div>
      <button className="btn btn-primary" onClick={handleNext} disabled={!next}>
        &gt;
      </button>
    </div>
  );
};
