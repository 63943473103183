class Module {
  constructor(name, menuItems, routingItems, requiredPrivileges = null) {
    this.name = name;
    this.menuItems = menuItems;
    this.routingItems = routingItems;
    this.requiredPrivileges = requiredPrivileges || [];
  }
}

export { Module };
