import { useEffect, useRef, useState } from 'react';
import { CachedAsyncImage } from '../../modules/highlights/utils/clipThumbnail';
import { Tooltip } from '../../lib/Tooltip';
import { useTranslation } from 'react-i18next';

export const FocusPointSelector = ({ clip, callback }) => {
  const [focalPoint, setFocalpoint] = useState({
    x: clip?.focalPoint?.x ?? 0,
    y: clip?.focalPoint?.y ?? 0,
  });

  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const imgRef = useRef();
  const containerRef = useRef();
  const indicatorRef = useRef();

  const { t } = useTranslation('module.highlights');

  const handleMove = (e) => {
    e.preventDefault();
    var clientX, clientY;
    console.log(e instanceof MouseEvent);
    if (e instanceof MouseEvent) {
      clientX = e.clientX;
      clientY = e.clientY;
    } else {
      clientX = e.touches[0].clientX;
      clientY = e.touches[0].clientY;
    }

    var rect = e.target.getBoundingClientRect();
    var x = clientX - rect.left - rect.width / 2; //x position within the element
    var y = clientY - rect.top - rect.height / 2; //y position within the element.
    setOffset({ x: x, y: y });
    calculateFocusPoint(x, y, rect.width, rect.height);
  };

  const calculateFocusPoint = (x, y, w, h) => {
    let _x = (x / (w / 2)).toPrecision(3);
    let _y = ((y * -1) / (h / 2)).toPrecision(3);
    setFocalpoint({ x: _x, y: _y });
    callback({ x: _x, y: _y });
  };

  useEffect(() => {
    imgRef.current.addEventListener('mouseup', handleMove);
    imgRef.current.addEventListener('touchend', handleMove);
    imgRef.current.addEventListener('touchstart', handleMove);
    imgRef.current.addEventListener('touchmove', handleMove);

    return () => {
      imgRef.current?.removeEventListener('mouseup');
      imgRef.current?.removeEventListener('touchend');
      imgRef.current?.removeEventListener('touchstart');
      imgRef.current?.removeEventListener('touchmove');
    };
  }, []);

  return (
    <div className={'form-group'}>
      <label>
        {t('selectFocusPoint')}
        <Tooltip tooltip={t('helpFocusPoint')}>
          <i
            style={{ cursor: 'pointer', marginLeft: '6px' }}
            className={'i-knowledge-base i-sm i-dark'}
          ></i>
        </Tooltip>
      </label>
      <div style={{ position: 'relative' }} ref={containerRef}>
        <div
          ref={imgRef}
          style={{ maxWidth: '100%', aspectRatio: 16 / 9 }}
          draggable={false}
        >
          <CachedAsyncImage
            style={{
              width: '100%',
              aspectRatio: 16 / 9,
            }}
            cacheKey={`${clip.videoId}${clip.start}-640`}
            promise={async () => await clip.generateThumbnail(640)}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            top: `calc(50% - ${offset.y * -1}px)`,
            left: `calc(50% - ${offset.x * -1}px)`,
            transform: 'translate(-50% , -50%)',
          }}
          ref={indicatorRef}
          // draggable={true}
          className="focuspoint"
        >
          <Disc size={14} />
        </div>
      </div>
      <div
        style={{
          backgroundColor: '#494949',
          padding: '6px 12px',
          display: 'flex',
        }}
      >
        <span
          className="muted"
          style={{ fontSize: '13px', color: 'white', opacity: 0.8 }}
        >{`x: ${focalPoint.x} y: ${focalPoint.y}`}</span>
        <a
          href="#"
          className={'link'}
          style={{ marginLeft: 'auto' }}
          onClick={() => {
            setFocalpoint({ x: 0, y: 0 });
            setOffset({ x: 0, y: 0 });
            callback({ x: 0, y: 0 });
          }}
        >
          Reset
        </a>
      </div>
    </div>
  );
};

//https://github.com/third774/image-focus/blob/master/src/FocusedImage.ts
const Disc = ({ size = 50, color = '#da2c2c' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="12" r="10"></circle>
    <circle cx="12" cy="12" r="3"></circle>
  </svg>
);
