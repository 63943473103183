import React, { useRef } from 'react';
import { Checkbox } from 'lib/Checkbox';

import './goal.scss';

const Goal = ({ position: { x, y } = {}, onChange }) => {
  const goalRef = useRef();

  const onClick = e => {
    const bb = goalRef.current.getBoundingClientRect();
    const x = ((e.nativeEvent.clientX - bb.left - 201) / 262) * 3;
    const y = ((257 - (e.nativeEvent.clientY - bb.top)) / 187.5) * 2;
    onChange({ x, y });
  };

  return (
    <svg
      width="402px"
      height="257px"
      viewBox="0 0 402 257"
      ref={goalRef}
      onClick={onClick}
    >
      <g
        id="Live-invoer-(handbal)"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(50, 50)"
      >
        <g id="Group-9">
          <path
            d="M282.334884,206.588223 L282.334884,18.2836172 L21.0697674,18.2836172 L21.0697674,206.588223 L0,206.588223 L0,0.150581066 L302,0.150581066 L302,206.588223 L282.334884,206.588223 Z"
            id="Combined-Shape"
            fill="#B1B1B1"
          ></path>
          <rect
            id="Rectangle"
            fill="#424242"
            x="0"
            y="156.076885"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-21"
            fill="#424242"
            x="282.160584"
            y="156.076885"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-12"
            fill="#424242"
            x="0"
            y="193.411352"
            width="19.8394161"
            height="13.1768707"
          ></rect>
          <rect
            id="Rectangle-Copy-22"
            fill="#424242"
            x="282.160584"
            y="193.411352"
            width="19.8394161"
            height="13.1768707"
          ></rect>
          <rect
            id="Rectangle-Copy-9"
            fill="#424242"
            x="0"
            y="116.546273"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-23"
            fill="#424242"
            x="282.160584"
            y="116.546273"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-10"
            fill="#424242"
            x="0"
            y="77.0156604"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-24"
            fill="#424242"
            x="282.160584"
            y="77.0156604"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-11"
            fill="#424242"
            x="0"
            y="37.4850482"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <rect
            id="Rectangle-Copy-25"
            fill="#424242"
            x="282.160584"
            y="37.4850482"
            width="19.8394161"
            height="19.7653061"
          ></rect>
          <g
            id="Group-8"
            transform="translate(0.000000, 0.150581)"
            fill="#424242"
          >
            <rect
              id="Rectangle-Copy-13"
              x="0"
              y="0"
              width="20.8835959"
              height="24.1575964"
            ></rect>
            <polygon
              id="Rectangle-Copy-14"
              transform="translate(13.226277, 9.236728) rotate(90.000000) translate(-13.226277, -9.236728) "
              points="3.98954935 -3.98954935 22.4630054 -3.98954935 22.4630054 22.4630054 3.98954935 22.4630054"
            ></polygon>
          </g>
          <g
            id="Group-8-Copy"
            transform="translate(288.500000, 12.650581) scale(-1, 1) translate(-288.500000, -12.650581) translate(275.000000, 0.150581)"
            fill="#424242"
          >
            <rect
              id="Rectangle-Copy-13"
              x="0"
              y="0"
              width="19.4913561"
              height="24.1575964"
            ></rect>
            <polygon
              id="Rectangle-Copy-14"
              transform="translate(13.226277, 9.236728) rotate(90.000000) translate(-13.226277, -9.236728) "
              points="3.98954935 -3.98954935 22.4630054 -3.98954935 22.4630054 22.4630054 3.98954935 22.4630054"
            ></polygon>
          </g>
          <polygon
            id="Rectangle-Copy-19"
            fill="#424242"
            transform="translate(243.583942, 9.517839) rotate(90.000000) translate(-243.583942, -9.517839) "
            points="234.408772 -2.53380467 252.759112 -2.53380467 252.759112 21.5694817 234.408772 21.5694817"
          ></polygon>
          <polygon
            id="Rectangle-Copy-19"
            fill="#424242"
            transform="translate(199.630890, 9.517839) rotate(90.000000) translate(-199.630890, -9.517839) "
            points="190.45572 -2.53380467 208.80606 -2.53380467 208.80606 21.5694817 190.45572 21.5694817"
          ></polygon>
          <polygon
            id="Rectangle-Copy-19"
            fill="#424242"
            transform="translate(154.259998, 9.517839) rotate(90.000000) translate(-154.259998, -9.517839) "
            points="145.084828 -2.53380467 163.435168 -2.53380467 163.435168 21.5694817 145.084828 21.5694817"
          ></polygon>
          <polygon
            id="Rectangle-Copy-19"
            fill="#424242"
            transform="translate(107.471266, 9.517839) rotate(90.000000) translate(-107.471266, -9.517839) "
            points="98.2960955 -2.53380467 116.646436 -2.53380467 116.646436 21.5694817 98.2960955 21.5694817"
          ></polygon>
          <polygon
            id="Rectangle-Copy-19"
            fill="#424242"
            transform="translate(60.682533, 9.517839) rotate(90.000000) translate(-60.682533, -9.517839) "
            points="51.5073631 -2.53380467 69.8577032 -2.53380467 69.8577032 21.5694817 51.5073631 21.5694817"
          ></polygon>
        </g>
        {!!x && !!y && (
          <circle
            cx={(x / 3) * 262 + 201}
            cy={257 - (y / 2) * 187.5}
            transform="translate(-50, -50)"
            fill="#FF3232"
            r="15"
            opacity="0.5"
          />
        )}
      </g>
    </svg>
  );
};

export const LiveObservationGoalView = ({
  type,
  personId,
  goalPosition,
  players,
  onSetAttributes,
}) => {
  return (
    <div className="live-observation-handball-goal-container">
      <h2 className="live-observation-handball-goal-title">Schot toevoegen</h2>
      <div className="live-observation-handball-goal-player-selection">
        <p>Rugnummer speler</p>
        {players.length === 0 && (
          <p>
            <i>Geen spelers ingevoerd</i>
          </p>
        )}

        <div className="live-observation-handball-goal-players">
          {players.map(player => (
            <Checkbox
              key={player.personId}
              checked={personId === player.personId}
              onChange={() => onSetAttributes({ personId: player.personId })}
            >
              {player.number}
            </Checkbox>
          ))}
        </div>
      </div>
      <div className="live-observation-handball-goal-position">
        <Goal
          position={goalPosition}
          onChange={goalPosition => onSetAttributes({ goalPosition })}
        />
      </div>
      <div className="live-observation-handball-goal-types">
        <p>Type</p>
        <Checkbox
          checked={type === '7-METER'}
          onChange={() => onSetAttributes({ type: '7 meter' })}
        >
          7 meter
        </Checkbox>
        <Checkbox
          checked={type === 'BREAK'}
          onChange={() => onSetAttributes({ type: 'BREAK' })}
        >
          Break
        </Checkbox>
        <Checkbox
          checked={type === 'EMPTY-GOAL'}
          onChange={() => onSetAttributes({ type: 'EMPTY-GOAL' })}
        >
          Empty Goal
        </Checkbox>
        <Checkbox
          checked={type === 'NORMAL'}
          onChange={() => onSetAttributes({ type: 'NORMAL' })}
        >
          Normaal
        </Checkbox>
      </div>

      <div className="live-observation-handball-goal-results">
        <p>Resultaat</p>
        <button
          type="button"
          className="btn btn-success"
          onClick={() => onSetAttributes({ result: 'GOAL' })}
        >
          Goal
        </button>
        <button
          type="button"
          className="btn btn-warning"
          style={{ color: '#fff' }}
          onClick={() => onSetAttributes({ result: 'SAVED' })}
        >
          Keeper
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => onSetAttributes({ result: 'MISSED' })}
        >
          Mis
        </button>
        <button
          type="button"
          className="btn btn-warning"
          style={{ marginTop: '40px', color: '#fff' }}
          onClick={() => onSetAttributes({ result: 'SAVED', rebound: true })}
        >
          Keeper + rebound
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => onSetAttributes({ result: 'MISSED', rebound: true })}
        >
          Mis + rebound
        </button>
      </div>
    </div>
  );
};
