import React from 'react';

import { NavLink } from 'react-router-dom';
import { MarketingToggle } from 'modules/upcoming';
import { LockedContent } from './LicenseLimitEnforcing';

export const NavPrimary = ({ session, t, toggle, menuItems = [] }) => (
  <ul className="nav nav-primary">
    {menuItems.map((item, idx) => (
      <li className={`nav-item`} key={idx}>
        <LockedContent reasons={item.locked} display={'block'} place={'bottom'}>
          {({ isLocked, onClickHandler }) => {
            if (
              typeof item?.url === 'string' &&
              item.url.indexOf('resource-group-id=') !== -1
            ) {
              // Use this to force external reload When navigating from menu to other Resourcegroup
              // https://v5.reactrouter.com/web/api/NavLink
              // doesn't work :(  https://stackoverflow.com/a/67573120
              // there is no https://reactrouter.com/en/main/components/nav-link#reloaddocument in v4 :(
              return (
                <a
                  onClick={isLocked ? onClickHandler : () => toggle()}
                  className={`nav-link ${item.isDisabled ? 'disabled' : ''} ${
                    isLocked ? 'locked' : ''
                  }`}
                  href={item.url}
                >
                  {item.title}
                </a>
              );
            }
            return (
              <>
                {typeof item === 'function' ? (
                  item()
                ) : (
                  <NavLink
                    onClick={isLocked ? onClickHandler : () => toggle()}
                    className={`nav-link ${item.isDisabled ? 'disabled' : ''} ${
                      isLocked ? 'locked' : ''
                    }`}
                    to={item.url}
                  >
                    {item.title}
                  </NavLink>
                )}
              </>
            );
          }}
        </LockedContent>
      </li>
    ))}
  </ul>
);
