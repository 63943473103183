import { ObservationTreeNode } from './ObservationTreeNode';
import { ObservationTree } from './ObservationTree';
import { Clock, VideoClock } from 'domain/ObservationLogger/Clock';

// mutates tree
export const treeSetCurrentObservation = ({
  tree,
  clock,
}: {
  tree: ObservationTree;
  clock?: Clock;
}) => {
  if (clock instanceof VideoClock) {
    tree.rootObservations.forEach((_rootNode, nodeIdx, siblings) => {
      const nextNode = siblings[nodeIdx + 1];
      nodeSetCurrentObservation({ siblings, nodeIdx, nextNode, clock });
    });
  }
};

// mutates node
const nodeSetCurrentObservation = ({
  siblings,
  nodeIdx,
  nextNode,
  clock,
}: {
  siblings: ObservationTreeNode[];
  nodeIdx: number;
  nextNode?: ObservationTreeNode;
  clock: VideoClock;
}) => {
  const node = siblings[nodeIdx];
  const time = clock.getTime();

  let current;
  if (
    typeof node.time.startTime !== 'undefined' &&
    typeof node.time.endTime !== 'undefined'
  ) {
    current = time >= node.time.startTime && time <= node.time.endTime;
  } else {
    current =
      !!nextNode &&
      time > node.time.eventTime &&
      time < nextNode.time.eventTime;
  }

  node.current.set(current ?? false);

  node.children.forEach((_child, childIdx, children) => {
    const next = children[childIdx + 1] ?? nextNode;
    nodeSetCurrentObservation({
      siblings: children,
      nodeIdx: childIdx,
      nextNode: next,
      clock,
    });
  });
};
