import { Session } from '../domain/Session';
import { useEffect, useState } from 'react';

export const RenderIfNewUser = ({ children }) => {
  const [isNew, setIsNew] = useState(false);
  useEffect(() => {
    (async () => {
      const session = Session.current();
      const user = session.user;
      await user.isReady;
      setIsNew(user.isNewUser);
    })();
  }, []);

  // Render only when user is < 60 days
  if (isNew) {
    return children;
  } else {
    return null;
  }
};
