import { Time } from './Time';
import { IObservationTreeBuilder } from './IObservationTreeBuilder';
import { ObservationTree } from './ObservationTree';
import { IObservation } from './IObservation';
import { ObservationTreeNode } from './ObservationTreeNode';
import { ITeam } from './ITeam';
import { treeSetCurrentObservation } from './treeSetCurrentObservation';

export class KorfballObservationTreeBuilder implements IObservationTreeBuilder {
  constructor(
    readonly matchConfig: any,
    readonly homeTeam: ITeam,
    readonly awayTeam: ITeam,
    readonly context: any
  ) {}

  build(observations: IObservation[]) {
    const observationTree = new ObservationTree();
    let currentAttackNode: ObservationTreeNode | null = null;
    let currentTeamId: string = '';
    let homeScore: number = 0;
    let awayScore: number = 0;

    for (const observation of observations) {
      switch (observation.code) {
        case 'POSSESSION': {
          currentAttackNode = new ObservationTreeNode(
            observation.observationId,
            observation.description.replace('Aanval ', ''),
            new Time(observation.startTime),
            false,
            undefined,
            ['EDIT'],
            observation.attributes_.rating,
            observation.creatorUser
          );
          observationTree.addNode(currentAttackNode);
          currentTeamId = observation.attributes_.teamId as string;
          break;
        }
        case 'SHOT': {
          const node = new ObservationTreeNode(
            observation.observationId,
            observation.description,
            new Time(observation.startTime),
            undefined,
            undefined,
            ['EDIT'],
            observation.attributes_.rating,
            observation.creatorUser
          );
          if (observation.attributes_.result === 'GOAL') {
            if (currentTeamId === this.homeTeam.teamId) {
              homeScore += 1;
            } else if (currentTeamId === this.awayTeam.teamId) {
              awayScore += 1;
            } else {
              node.postLabel = 'E';
            }
            node.preLabel = `${homeScore} - ${awayScore}`;
          }

          let currentAttack;
          if (currentAttackNode === null) {
            currentAttack = observationTree;
          } else {
            currentAttack = currentAttackNode as ObservationTreeNode;
            if (observation.attributes_.result === 'GOAL') {
              currentAttack.preLabel = node.preLabel;
            }
          }
          currentAttack.addNode(node);
          break;
        }
        case 'GOAL-CORRECTION': {
          const node = new ObservationTreeNode(
            observation.observationId,
            observation.description,
            new Time(observation.startTime),
            undefined,
            undefined,
            ['EDIT'],
            observation.attributes_.rating,
            observation.creatorUser
          );
          if (observation.attributes_.teamId === this.homeTeam.teamId) {
            homeScore += 1;
          } else if (observation.attributes_.teamId === this.awayTeam.teamId) {
            awayScore += 1;
          } else {
            node.postLabel = 'E';
          }
          node.preLabel = `${homeScore} - ${awayScore}`;

          observationTree.addNode(node);
          break;
        }
        case 'CUSTOM': {
          const node = new ObservationTreeNode(
            observation.observationId,
            observation.description,
            new Time(observation.startTime),
            undefined,
            'C',
            ['EDIT'],
            observation.attributes_.rating,
            observation.creatorUser
          );
          observationTree.addNode(node);
          break;
        }
        case 'START-POSSESSION': {
          break;
        }
        default: {
          const node = new ObservationTreeNode(
            observation.observationId,
            observation.description,
            new Time(observation.startTime),
            undefined,
            undefined,
            ['EDIT'],
            observation.attributes_.rating,
            observation.creatorUser
          );

          let currentAttack;
          if (currentAttackNode === null) {
            currentAttack = observationTree;
          } else {
            currentAttack = currentAttackNode as ObservationTreeNode;
          }

          currentAttack.addNode(node);
        }
      }
    }

    treeSetCurrentObservation({
      tree: observationTree,
      clock: this.context.clock,
    });

    return observationTree;
  }
}
