import {
  LocalCommandHandler,
  RemoteCommandHandler,
} from '../../infra/Messaging/CommandHandler';

import { lineUpCollection } from './LineUp';
import sportingEventRepository from '../SportingEvent';

class LocalLineUpCommandHandler extends LocalCommandHandler {
  constructor() {
    super();

    this.lineUpRepository = lineUpCollection;
    this.sportingEventRepository = sportingEventRepository;
  }

  doHandle(command) {
    // let sportingEvent;
    // if (command.commandType !== 'LineUp.Create') {
    //   sportingEvent = this.sportingEventRepository.mustFind({
    //     lineUpId: command.lineUpId,
    //   });
    //   const matchStarted = sportingEvent.started();
    //   if (matchStarted) {
    //     throw 'Cannot change lineUp when match started';
    //   }
    // }
    const lineUp = this.lineUpRepository.get(command.lineUpId);

    switch (command.commandType) {
      case 'LineUp.Create':
        this.lineUpRepository.addNew({
          lineUpId: command.lineUpId,
          rolesPerTeam: {},
        });
        break;
      case 'LineUp.RemovePlayer':
        lineUp.removePlayer(command.teamId, command.personId);
        break;
      case 'LineUp.AddPlayer':
        lineUp.addPlayer(command.teamId, command.personId, command.position);
        break;

      case 'LineUp.ChangePlayerPosition':
        lineUp.removePlayer(command.teamId, command.personId);
        lineUp.addPlayer(command.teamId, command.personId, command.position);
        break;

      case 'LineUp.SwapUnAssignedWithPlayer':
        lineUp.removePlayer(command.teamId, command.outPersonId);
        lineUp.addPlayer(command.teamId, command.inPersonId, command.position);
        break;
      case 'LineUp.SwapPlayerWithPlayer':
        lineUp.removePlayer(command.teamId, command.outPersonId);
        lineUp.removePlayer(command.teamId, command.inPersonId);
        lineUp.addPlayer(
          command.teamId,
          command.outPersonId,
          command.outPosition
        );
        lineUp.addPlayer(
          command.teamId,
          command.inPersonId,
          command.inPosition
        );
        break;
      case 'LineUp.SwapAttackDefence':
        lineUp.swapAttackAndDefence(command.lineUpId, command.teamId);
        break;

      /*
            case "LineUp.MovePlayerToSubstitute":
                // TODO: consider use substitutePlayer when matchStarted
                lineUp.removePlayer(command.teamId, command.personId);
                lineUp.addSubstitute(command.teamId, command.personId);
                break;
            case "LineUp.MoveSubstituteToPlayer":
                // TODO: consider use substitutePlayer when matchStarted
                lineUp.removeSubstitute(command.teamId, command.personId);
                lineUp.addPlayer(command.teamId, command.personId, command.position);
                break;
            case "LineUp.AddSubstitute":
                lineUp.addSubstitute(command.teamId, command.personId);
                break;
            case "LineUp.RemoveSubstitute":
                lineUp.removeSubstitute(command.teamId, command.personId);
                break;
            case "LineUp.SwapSubstituteWithPlayer":
                lineUp.removePlayer(command.teamId, command.outPersonId);
                lineUp.removeSubstitute(command.teamId, command.inPersonId);
                lineUp.addSubstitute(command.teamId, command.outPersonId);
                lineUp.addPlayer(command.teamId, command.inPersonId, command.position);
                break;
            case "LineUp.SwapUnAssignedWithSubstitute":
                lineUp.removeSubstitute(command.teamId, command.outPersonId);
                lineUp.addSubstitute(command.teamId, command.inPersonId);
                break;
            */

      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['LineUp.*'];
  }
}

class RemoteLineUpCommandHandler extends RemoteCommandHandler {
  constructor() {
    super();

    this.sportingEventRepository = sportingEventRepository;
  }

  doHandle(command) {
    // let sportingEvent;
    // if (command.commandType !== 'LineUp.Create') {
    //   sportingEvent = this.sportingEventRepository.mustFind({
    //     lineUpId: command.lineUpId,
    //   });
    //   const matchStarted = sportingEvent.started();
    //   if (matchStarted) {
    //     throw 'Cannot change lineUp when match started';
    //   }
    // }

    const lineUp = lineUpCollection.build({ lineUpId: command.lineUpId });

    switch (command.commandType) {
      case 'LineUp.Create':
        // nothing to do, handled by SportingEvent.CreateMatch
        break;

      case 'LineUp.AddPlayer':
        this.addUnitOfWork(command, [
          this.createRpcRequest(lineUp, 'addPlayer', {
            teamId: command.teamId,
            personId: command.personId,
            position: command.position,
          }),
        ]);
        break;
      case 'LineUp.RemovePlayer':
        this.addUnitOfWork(command, [
          this.createRpcRequest(lineUp, 'removePlayer', {
            personId: command.personId,
          }),
        ]);
        break;
      case 'LineUp.ChangePlayerPosition':
        this.addUnitOfWork(command, [
          this.createRpcRequest(lineUp, 'removePlayer', {
            personId: command.personId,
          }),
          this.createRpcRequest(lineUp, 'addPlayer', {
            teamId: command.teamId,
            personId: command.personId,
            position: command.position,
          }),
        ]);
        break;
      case 'LineUp.SwapUnAssignedWithPlayer':
        this.addUnitOfWork(command, [
          this.createRpcRequest(lineUp, 'removePlayer', {
            personId: command.outPersonId,
          }),
          this.createRpcRequest(lineUp, 'addPlayer', {
            teamId: command.teamId,
            personId: command.inPersonId,
            position: command.position,
          }),
        ]);
        break;
      case 'LineUp.SwapPlayerWithPlayer':
        this.addUnitOfWork(command, [
          this.createRpcRequest(lineUp, 'removePlayer', {
            personId: command.outPersonId,
          }),
          this.createRpcRequest(lineUp, 'removePlayer', {
            personId: command.inPersonId,
          }),
          this.createRpcRequest(lineUp, 'addPlayer', {
            teamId: command.teamId,
            personId: command.outPersonId,
            position: command.outPosition,
          }),
          this.createRpcRequest(lineUp, 'addPlayer', {
            teamId: command.teamId,
            personId: command.inPersonId,
            position: command.inPosition,
          }),
        ]);
        break;
      case 'LineUp.SwapAttackDefence':
        // todo: I need to add this, else the handler will give faulty messages :( why though?
        break;

      /*
            case "LineUp.AddSubstitute":
                this.addUnitOfWork(
                    command, [
                        this.createRpcRequest(lineUp, "addSubstitute", {teamId: command.teamId, personId: command.personId}),
                    ]
                );
                break;
            case "LineUp.RemoveSubstitute":
                this.addUnitOfWork(
                    command, [
                        this.createRpcRequest(lineUp, "removeSubstitute", {personId: command.personId})
                    ]
                );
                break;
            case "LineUp.SwapSubstituteWithPlayer":
                this.addUnitOfWork(
                    command, [
                        this.createRpcRequest(lineUp, "removePlayer", {personId: command.outPersonId}),
                        this.createRpcRequest(lineUp, "removeSubstitute", {personId: command.inPersonId}),
                        this.createRpcRequest(lineUp, "addSubstitute", {teamId: command.teamId, personId: command.outPersonId}),
                        this.createRpcRequest(lineUp, "addPlayer", {teamId: command.teamId, personId: command.inPersonId, position: command.position})
                    ]
                );
                break;

            case "LineUp.MovePlayerToSubstitute":
                // TODO: consider use substitutePlayer when matchStarted
                this.addUnitOfWork(
                    command, [
                        this.createRpcRequest(lineUp, "removePlayer", {personId: command.personId}),
                        this.createRpcRequest(lineUp, "addSubstitute", {teamId: command.teamId, personId: command.personId}),
                    ]
                );
                break;
            case "LineUp.MoveSubstituteToPlayer":
                // TODO: consider use substitutePlayer when matchStarted
                this.addUnitOfWork(
                    command, [
                        this.createRpcRequest(lineUp, "removeSubstitute", {personId: command.personId}),
                        this.createRpcRequest(lineUp, "addPlayer", {teamId: command.teamId, personId: command.personId, position: command.position}),
                    ]
                );
                break;

            case "LineUp.SwapUnAssignedWithSubstitute":
                this.addUnitOfWork(
                    command, [
                        this.createRpcRequest(lineUp, "removeSubstitute", {personId: command.outPersonId}),
                        this.createRpcRequest(lineUp, "addSubstitute", {teamId: command.teamId, personId: command.inPersonId}),
                    ]
                );
                break;
             */
      default:
        return null;
    }
    return true;
  }

  understoodTypes() {
    return ['LineUp.*'];
  }
}

export default () => {
  new LocalLineUpCommandHandler();
  new RemoteLineUpCommandHandler();
};
