import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Loading from '../../../Loading';
import { Error } from '../index';

import endpoint from 'utils/ApiEndpoint';

export const SVCreateAccount = ({ stepHandler, formData }) => {
  const { t } = useTranslation('registerPage');
  const defaultData = {};
  const [error, setError] = useState(false);

  useEffect(() => {
    if (_.every(Object.values(formData))) {
      const data = { ...defaultData, ...formData };
      const _FormData = new FormData();

      for (let [key, val] of Object.entries(data)) {
        if (typeof val === 'object') {
          _FormData.append(key, JSON.stringify(val));
        } else {
          _FormData.append(key, val.toString());
        }
      }
      (async () => {
        await fetch(`${endpoint}/anonymous/tenantCreationRequest/complete`, {
          method: 'post',
          body: _FormData,
        }).then((response) => {
          if (response.ok) {
            stepHandler.next();
          } else {
            setError({
              title: 'Something went wrong',
              body: 'Sorry something went wrong',
            });
          }
        });
      })();
      //
    } else {
      setError({
        title: 'FormData is incomplete or false',
        body: 'Returning to first step',
      });
      setTimeout(() => stepHandler.goto(1), 5000);
    }
  }, []);

  return (
    <div data-test-id={'step-create'} className={'userlogin-form-step'}>
      <h1>{t('form.confirmAccountTitle')}</h1>
      <p>{t('form.confirmAccountBody')}</p>
      <div className={'userlogin-form__creation'}>
        <Loading size={80} type={'spinner'} />
        <p>Creating account...</p>
      </div>
      {error && <Error title={error.title} body={error.body}></Error>}
    </div>
  );
};
