import React, {
  forwardRef,
  useContext,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Hls from 'hls.js';
import { RemotionMainVideoProps, RemotionVideoProps, Video } from 'remotion';
import { BufferContext } from './BufferManager';

const PausableVideoFunction: React.ForwardRefRenderFunction<
  HTMLVideoElement,
  RemotionVideoProps & RemotionMainVideoProps
> = ({ src, hls, ...props }, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const id = useId();

  useImperativeHandle(ref, () => videoRef.current as HTMLVideoElement);

  const { canPlay, needsToBuffer } = useContext(BufferContext);

  useEffect(() => {
    const { current } = videoRef;

    const BUFFER_THRESHOLD = 4; // seconds
    if (!current) {
      return;
    }

    const onPlay = (e) => {
      // Set canPlay event from htmlVideo Element with 1 sec delay.
      clearTimeout(timeOutBuffer);
      setTimeout(() => {
        canPlay(id);
      }, 1000);
    };
    let timeOutBuffer;
    const startBuffer = () => {
      // Set buffering state.
      // This will not be set is Canplay is triggered by player directly after wait.
      timeOutBuffer = setTimeout(() => {
        needsToBuffer(id);
      }, 200);
    };

    const onBuffer = (e) => {
      startBuffer();
    };
    if (hls !== null) {
      hls.on(Hls.Events.BUFFER_APPENDING, (event, data) => {
        var bufferLevel =
          videoRef.current.buffered.end(0) - videoRef.current.currentTime;
        // Adjust the threshold based on your requirements
        var bufferThreshold = Math.min(
          BUFFER_THRESHOLD,
          videoRef.current.duration - videoRef.current.currentTime
        ); // Buffer threshold in seconds (or smaller if less video is left

        if (bufferLevel < bufferThreshold) {
          canPlay(id);
          // onPlay(); // Pause the video when buffer level is below the threshold
        } else {
          needsToBuffer(id); // Resume playback when buffer level is sufficient
        }
      });
    } else {
      current.addEventListener('canplay', onPlay);
      current.addEventListener('waiting', onBuffer);
    }

    if (process.env?.HLS_DEBUG) {
      const logPlayerEvent = (event, type) => {
        console.log(event, type);
      };
      [
        'playing',
        'waiting',
        'seeking',
        'seeked',
        'ended',
        'loadedmetadata',
        'loadeddata',
        'canplay',
        'canplaythrough',
        'durationChange',
        'timeupdate',
        'play',
        'pause',
        'ratechange',
        'suspend',
        'stalled',
      ].forEach((eventName, i) => {
        current.addEventListener(eventName, (e) =>
          logPlayerEvent(e, eventName)
        );
      });
    }

    return () => {
      current.removeEventListener('canplay', onPlay);
      current.removeEventListener('waiting', onBuffer);

      // If component is unmounted, unblock the buffer manager
      canPlay(id);
    };
  }, [canPlay, id, needsToBuffer]);

  return (
    <Video
      acceptableTimeShiftInSeconds={100}
      {...props}
      ref={videoRef}
      src={src}
    />
  );
};

export const PausableVideo = forwardRef(PausableVideoFunction);
