import ReactTooltip from 'react-tooltip';
import React, { createRef, useState } from 'react';

import uuidv4 from 'uuid/v4';
import './style.scss';
import lock from './lock.svg';
import lockSmall from './lock-small.svg';
import lockSmallOrange from './lock-small-orange.svg';
import { Trans, useTranslation } from 'react-i18next';
import { Session } from '../../../domain/Session';

/*
 *
 *   Lock loops through reasons and returns string with reason, which we look up in 118n.
 * eg.
 * [
 * {check: !sportingEvent.privilegedTo('observe'),reason: 'tagging',},
 * {check: !currentSession.currentPrivileges().hasPrivilege('CustomTag:view'),reason: 'tagging',},
 * ]
 *
 * Will search for "locked:tagging" in i18n.
 * */

export const LockedContent = ({
  reasons = [],
  display = 'inline',
  place = 'right',
  size = 'small',
  children = ({ isLocked, onClickHandler }) => {},
}) => {
  const tipId = uuidv4();
  const position = createRef();

  let reason = false;

  if (reasons.length) {
    reasons.every((e, i) => {
      if (e.check) {
        reason = e.reason;
        if (e.lockType === 'hidden') {
          size = 'hidden';
        }
        return false; // Stop on first valid reason
      } else {
        return true;
      }
    });
  }

  if (reason) {
    // Component is locked for reason (eg. addMatchNoLicense)
    return (
      <div
        data-tip=""
        data-for={tipId}
        className={`locked-content-tip ${size}`}
        style={{ display: display }}
      >
        <ReactTooltip
          id={tipId}
          delayHide={500}
          delayShow={500}
          place={place}
          type="dark"
          effect="solid"
          clickable={true}
          className={'tooltip--styled lock-tooltip'}
          backgroundColor="#495057"
          overridePosition={(
            { left, top },
            currentEvent,
            currentTarget,
            node
          ) => {
            if (window.innerWidth > 767 && size !== 'hidden') {
              const d = position.current.getBoundingClientRect();
              let frameSize = node.clientHeight / 2;
              if (place == 'left') {
                left = d.left - d.width - 2 - node.clientWidth; // Position before lock with 2px between.
                top = d.top + 0.5 * d.height - frameSize; // Position center of lock (lock x position, add half lock height, reduce by tooltip size
              }
              if (place === 'right') {
                left = d.left + d.width + 2; // Position after lock with 2px between.
                top = d.top + 0.5 * d.height - frameSize; // Position center of lock (lock x position, add half lock height, reduce by tooltip size
              }
              if (place === 'top') {
                left = d.left + 0.5 * d.width - 0.5 * node.clientWidth; // Position center under lock.
                top = d.top + d.height + 2; // Position below lock with 2px between.
              }
            }

            return { top, left };
          }}
        >
          <LockReason component={reason} />
        </ReactTooltip>
        {children({
          isLocked: reason,
          onClickHandler: (e) => {
            e.preventDefault();
          },
        })}

        {/* Show only tooltip when locksize is hidden */}
        {size === 'hidden' && <span ref={position}></span>}

        <div className="lock-wrapper">
          {size === 'small' && (
            <img
              ref={position}
              className={'lock'}
              src={window.isSkillReflect ? lockSmallOrange : lockSmall}
            />
          )}
          {size === 'large' && (
            <img ref={position} className={'lock'} src={lock} />
          )}
        </div>
      </div>
    );
  } else {
    // Reason is false so no lock is applied
    return <>{children(reason)}</>;
  }
};

const LockReason = ({ component }) => {
  const { t } = useTranslation('locked');
  const [transactionId, _] = useState(() => uuidv4());

  const logAction = (event, attributes = {}) => {
    return Session.current().user.logAction('LicenseLimitEnforcing', {
      component: 'LockedComponent',
      transactionId,
      event,
      ...attributes,
    });
  };
  return (
    <Trans t={t} i18nKey={component}>
      before
      <a
        className="label-link"
        target="_blank"
        rel="noopener"
        onClick={() => logAction('UpgradeCtaClick', { reason: component })}
        href={t('storeLink')}
      >
        cta
      </a>
      after
    </Trans>
  );
};
