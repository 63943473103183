import { Page } from '../../lib/Page';
import Popup from '../../lib/notifications/popup';
import { withRouter } from 'react-router-dom';
import { AsideTest } from './AsideTest';

export const ComponentPage = withRouter(({ match }) => {
  const pageProps = {
    title: 'testpage',
    breadcrumbs: [
      { path: '/', title: 'Home' },
      {
        path: '/',
        title: 'testPage',
      },
    ],
  };

  const testName = match.params.testName;

  switch (testName) {
    case 'popup':
      return (
        <Page {...pageProps}>
          <Popup className={'e2e-id-1'}>
            <div></div>
          </Popup>
          <Popup className={'e2e-id-2'}>
            {({ closePopup }) => {
              return (
                <div>
                  <button data-test-id={'testbutton'} onClick={closePopup}>
                    Testbutton
                  </button>
                </div>
              );
            }}
          </Popup>
          <Popup className={'e2e-id-3'} useTemplate data={{ body: 'test' }} />
        </Page>
      );
    case 'aside':
      return (
        <Page {...pageProps}>
          <AsideTest />
        </Page>
      );
  }

  throw `Unknown test suite ${testName}`;
});
