import registry from './registry';

import { MainRoute } from './route';

import matchModule, { Trainings, Recordings } from './match';
import lineUpModule from './lineup';
import reportingModule from './reporting';
import observeModule from './observe';
import userManagementModule from './user-management';
import playModule from './play';
import developmentModule from './development';
import appsModule from './apps';
import resellerModule from './reseller';
import shareModule from './shares';
import exerciseModule from './exercise';
import playlistModule from './playlist';
import embeddingModule from './embedding';
import trainingTemplateModule from './training-templates';
import devModule from './dev';
import homeModule from './home';
import verifyEmail from './VerifyEmail';
import highlights from './highlights';
import scheduleTrainings from './scheduleTrainings';

registry.register(matchModule);
registry.register(lineUpModule);
registry.register(reportingModule);
registry.register(observeModule);
registry.register(userManagementModule);
registry.register(playModule);
registry.register(developmentModule);
registry.register(appsModule);
registry.register(resellerModule);
registry.register(shareModule);
registry.register(exerciseModule);
registry.register(playlistModule);
registry.register(embeddingModule);
registry.register(trainingTemplateModule);
registry.register(devModule);
registry.register(homeModule);
registry.register(verifyEmail);
registry.register(Trainings);
registry.register(Recordings);
registry.register(highlights);
registry.register(scheduleTrainings);

const mainMenuItems = () => {
  return registry.getModules().map((module) => {
    return {
      path: '/' + module.name,
      title: module.menuItems[0].title,
    };
  });
};

const subMenuItems = (currentPath) => {
  const moduleName = currentPath.split('/')[1];
  const module = registry.getModuleByName(moduleName);
  return module.menuItems[0].subItems.map((subMenuItem) => {
    return {
      path: '/' + module.name + '/' + subMenuItem.path,
      title: subMenuItem.title,
    };
  });
};

export { MainRoute, mainMenuItems, subMenuItems };
