import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { exerciseCollection } from 'domain/Exercise';
import { exerciseTemplateCollection } from 'domain/ExerciseTemplate';
import videoCollection from 'domain/Video';

import { ExerciseOverviewPage } from './overview';
import { EditExercisePage } from './EditExercisePage';
import { injectLoader } from 'lib/InjectLoader';

const MODULE_NAME = 'exercise';

const exerciseLoader = async props => {
  const exercise = await exerciseCollection.getOrFetch(
    props.match.params.exerciseId
  );
  if (exercise.hasVideo()) {
    await videoCollection.getOrFetch(exercise.videoId);
  }
  return {
    exercise,
    exerciseTemplateCollection,
  };
};

const exerciseModule = new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('overview', ExerciseOverviewPage, 'overview'),
    new RouteExact(
      'edit/:exerciseId',
      injectLoader(exerciseLoader, EditExercisePage),
      'edit'
    ),
    new RouteRedirect('', 'overview'),
  ]
);

export default exerciseModule;
