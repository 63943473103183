import { Tooltip } from '../../../../lib/Tooltip';
import { useTranslation } from 'react-i18next';

import api from '../../../../utils/ApiClient';
import { useQuery, useQueryClient } from 'react-query';
import { Session } from '../../../../domain/Session';
import { getRoutePath } from '../../../route';
import Loading from '../../../../lib/Loading';
import { joinResourceGroup } from '../utils';
import sweetAlert from '../../../../lib/sweetAlert';
import { SubmittingButton } from '../../../../lib/SubmittingButton';
import Logger from '../../../../utils/Logger';
import moment from 'moment';

import { MemberInfo } from 'lib/MemberInfo/MemberInfo';

export const StatusTable = ({ trainees, learningGroup }) => {
  const { t } = useTranslation('module.development.learningGroups');
  const { data: learningLines, isFetched } = useQuery(
    ['learningLines'],
    async () => {
      const response = await api.get('learningLines');
      return response.data;
    },
    {
      placeholderData: [],
    }
  );
  const { data: status, isFetched: statusFetched } = useQuery(
    ['learningGroups', learningGroup.learningGroupId, 'status'],
    async () => {
      const response = await api.get(
        `learningGroups/${learningGroup.learningGroupId}/status`
      );
      return response.data;
    },
    {
      placeholderData: {},
    }
  );

  if (!isFetched || !statusFetched) {
    return <Loading />;
  }

  const learningLine = learningLines.find(
    (l) => l.learningLineId === status.learningLineId
  );

  return (
    <div
      style={{
        overflowX: 'auto',
        width: '100%',
        padding: '1rem',
        margin: '0 -1rem',
      }}
    >
      <table className={'statustable'}>
        <thead>
          <tr>
            <th>{t('trainees')}</th>
            {learningLine.phases.map((phase) => (
              <th key={phase.phaseId}>{phase.name}</th>
            ))}
            {learningGroup.state === 'started' && <th>Afronden</th>}
          </tr>
        </thead>
        <tbody>
          {status.statuses.map((traineeStatus, index) => {
            return (
              <TraineeRow
                key={`trainee-${index}`}
                trainee={traineeStatus.trainee}
                indicatorStatuses={traineeStatus.indicatorStatuses}
                phases={learningLine.phases}
                learningGroup={learningGroup}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const TraineeRow = ({
  trainee,
  phases,
  index,
  learningGroup,
  indicatorStatuses,
}) => {
  const currentSession = Session.current();
  const { t } = useTranslation('module.development.tasks');
  // TODO: fix in backend, send isProvisioned attribute
  const canJoin = !!learningGroup.resourceGroupId;
  const queryClient = useQueryClient();

  trainee.status = trainee.completedAt && trainee.assessedBy ? 'is-done' : '';

  const goToTrainee = async () => {
    if (!currentSession.isMemberOf(learningGroup.resourceGroupId)) {
      await joinResourceGroup(
        learningGroup.resourceGroupId,
        currentSession.currentResourceGroup().id
      );
    }

    if (!currentSession.isMemberOf(trainee.resourceGroupId)) {
      await joinResourceGroup(
        trainee.resourceGroupId,
        learningGroup.resourceGroupId
      );
    }

    currentSession.setResourceGroupId(
      trainee.resourceGroupId,
      getRoutePath('development.tdp')
    );
  };

  const finalizeCourse = async () => {
    const Result = new Promise((resolve, reject) => {
      sweetAlert({
        title: t('completeCourseOf', {
          name: `${trainee.firstName} ${trainee.lastName}`,
        }),
        text: t('completeCourseOfWarning'),
        buttons: [t('common:cancel'), t('common:confirm')],
      }).then(async (willSubmit) => {
        if (willSubmit) {
          // Afronden Trainee.
          try {
            const response = await api.post(
              `learningGroups/${learningGroup.learningGroupId}/completeCourseModule`,
              { email: trainee.email }
            );
            if (response.status == 200) {
              // Refetch status
              queryClient.invalidateQueries([
                'learningGroups',
                learningGroup.learningGroupId,
                'status',
              ]);
            } else {
            }
          } catch (e) {
            Logger.error(e, {
              transactionName: 'Unable to finalize trainee',
            });
          }

          return resolve();
        } else {
          resolve();
        }
      });
    });
    await Result;
  };
  return (
    <tr className={`trainee-row ${trainee.status}`}>
      <td className={`trainee-details`}>
        <div className="trainee-info">
          {trainee.resourceGroupId && canJoin && (
            <a
              className="traineebutton"
              data-id={trainee.email}
              onClick={() => goToTrainee()}
            >{`${trainee.firstName} ${trainee.lastName}`}</a>
          )}
          {(!trainee.resourceGroupId || !canJoin) && (
            <span data-id={trainee.email}>
              {trainee.firstName} {trainee.lastName}
            </span>
          )}
        </div>
      </td>
      {phases.map((phase) => {
        return (
          <TraineePhase
            key={`traineephase-${index}-${phase.phaseId}`}
            indicatorStatuses={indicatorStatuses}
            phase={phase}
          />
        );
      })}
      {learningGroup.state === 'started' && (
        <td>
          {trainee.status !== 'is-done' && (
            <SubmittingButton
              disabled={trainee.status === 'is-done'}
              className={'btn btn-primary'}
              onClick={finalizeCourse}
            >
              Afronden
            </SubmittingButton>
          )}
          {/*Todo: add finalization date / user*/}
          {trainee.status === 'is-done' && (
            <Tooltip
              style={'light'}
              className={'tooltip-shadow'}
              style={'light'}
              place={'top'}
              delay={0}
              tooltip={
                <div>
                  {t('completedOn')}{' '}
                  {moment(trainee.completedAt).format('D-M-y')} <br />{' '}
                  {t('completedBy')}{' '}
                  <MemberInfo userId={trainee.assessedBy} type={'name'} />
                </div>
              }
            >
              <i className={'i-check'}></i>{' '}
            </Tooltip>
          )}
        </td>
      )}
    </tr>
  );
};

const TraineePhase = ({ indicatorStatuses, phase }) => {
  const { t } = useTranslation('module.development.tasks');
  return (
    <td key={`trainee-${phase.phaseId}`}>
      <div className="status-indicators d-flex">
        {phase.practices.map((practice) => {
          const { status, taskStatuses } =
            indicatorStatuses[practice.practiceId];

          const ToolTip = () => {
            return (
              <>
                <h6>{practice.name}</h6>
                <b className={`text--${status}`}>{t(`forTeacher.${status}`)}</b>
                <ul className={'task-list'}>
                  {practice.tasks.map((task) => {
                    const status = taskStatuses[task.taskId];

                    return (
                      <li key={task.taskId}>
                        <div className="status-indicator">
                          <div className={`status status--${status}`}></div>
                        </div>
                        <div>
                          {task?.name ?? practice.name} (
                          {t(`taskType.${task.taskType}`)})
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          };
          return (
            <div className="status-indicator" key={practice.practiceId}>
              <Tooltip
                className={'tooltip-shadow'}
                style={'light'}
                tooltip={ToolTip}
                place={'top'}
                delay={0}
              >
                <div
                  key={practice.practiceId}
                  className={`status status--${status}`}
                ></div>
              </Tooltip>
            </div>
          );
        })}
      </div>
    </td>
  );
};
