import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import { init, Trengo } from './Trengo';
import { Session } from 'domain/Session';
import { Observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

type WidgetInstance = Trengo;

type SessionUser = {
  isReady?: boolean;
  settings: {
    // TODO: @koenvo replace for Trengo
    intercom: {
      enabled: boolean;
    };
  };
  realUserId: string;
  email: string;
  firstName: string;
  lastName: string;
  currentMembership: any;
};

const maxInitAttempts = 3;

function matchRuleShort(subject: string, rule: string) {
  return new RegExp('^' + rule.split('*').join('.*') + '$').test(subject);
}

const isBlackListedPath = (pathname: string): boolean => {
  const blacklistedPaths = ['/observe/*/live', '/embedding/*'];
  const matchingPath = blacklistedPaths.find(blacklistedPath => {
    return matchRuleShort(pathname, blacklistedPath);
  });

  return typeof matchingPath !== 'undefined';
};

const isHiddenPath = (pathname: string): boolean => {
  const hiddenPaths = ['/playlist/view/*'];
  const matchingPath = hiddenPaths.find(hiddenPath => {
    return matchRuleShort(pathname, hiddenPath);
  });
  return typeof matchingPath !== 'undefined';
};

const isDisabledPath = (pathname: string): boolean => {
  return isBlackListedPath(pathname) || isHiddenPath(pathname);
};

const isDisabledForUser = (user?: SessionUser): boolean => {
  return user === undefined || user?.settings?.intercom.enabled === false;
};

const isDisabled = ({
  NODE_ENV,
  pathname,
  user,
}: {
  NODE_ENV: string;
  pathname: string;
  user?: SessionUser;
}): boolean => {
  return (
    // NODE_ENV === 'development' ||
    isDisabledForUser(user) //|| isDisabledPath(pathname)
  );
};

type CustomerSupportProps = RouteComponentProps & {
  user?: SessionUser;
};

class CustomerSupport extends Component<CustomerSupportProps> {
  widgetInstance: WidgetInstance | undefined;
  initIntervalId: number | undefined;

  constructor(props: CustomerSupportProps) {
    super(props);
    this.initIntervalId = undefined;
    this.widgetInstance = undefined;
    this.state = {
      helpUrl: this.props.t('url'),
    };
  }

  componentDidMount() {
    const initIntervalTimeout = 2500;
    const user = this.props.user;

    let initAttempt = 1;

    if (
      isDisabled({
        NODE_ENV: process.env.NODE_ENV,
        pathname: this.props.location.pathname,
        user,
      })
    ) {
      return;
    }

    init((widgetInstance: WidgetInstance) => {
      this.widgetInstance = widgetInstance;

      this.initIntervalId = window.setInterval(() => {
        if (widgetInstance.Api) {
          if (user) {
            this.setInstanceUserData(user);
          }

          //widgetInstance.Api.Widget.render(); // TODO: @koenvo u want default on?
          window.clearInterval(this.initIntervalId);
        } else {
          if (initAttempt === maxInitAttempts) {
            window.clearInterval(this.initIntervalId);
          } else {
            initAttempt++;
          }
        }
      }, initIntervalTimeout);
    });
  }

  componentWillUnmount() {
    if (this.initIntervalId !== undefined) {
      window.clearInterval(this.initIntervalId);
    }

    // NICETOHAVE: cleanup widget when unmounting, currently no support for this
  }

  setInstanceUserData = (user: SessionUser) => {
    if (this.widgetInstance === undefined) {
      return;
    }

    if (!user.email) {
      return;
    }

    const customFields = !!user.currentMembership.membership
      ? [
          {
            field_id: 49476,
            value: user.currentMembership.membership.tenantId,
          },
          {
            field_id: 48881,
            value: user.currentMembership.membership.targetResourceName,
          },
          {
            field_id: 48886,
            value: user.currentMembership.membership.roleNames[0],
          },
        ]
      : [];

    this.widgetInstance.contact_data = {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      custom_fields: customFields,
    };
    this.setState({
      helpUrl: `${this.props.t('url')}#contactData=${btoa(
        JSON.stringify(this.widgetInstance.contact_data)
          // get rid of non-latin characters
          .replace(/[\u0250-\ue007]/g, '')
      )}`,
    });
  };
  //
  // widgetOpen = e => {
  //   e.preventDefault();
  //
  //   const contactData = this.widgetInstance.contact_data;
  //   const item = btoa(JSON.stringify(contactData));
  //   window.open();
  //
  //   //this.widgetInstance?.Api.Widget.render();
  //   //this.widgetInstance?.Api.Widget.open('chat');
  // };

  widgetClose = () => {
    this.widgetInstance?.Api.Widget.close();
  };

  componentDidUpdate(prevProps: CustomerSupportProps) {
    // TODO only do this when enabled
    // if (prevProps.location.pathname !== this.props.location.pathname) {
    //   console.debug('path changed: ', this.props.location.pathname);
    //   // TODO: BUG: method does not work
    //   this.widgetInstance?.Api.Widget.url_updated();
    //   console.debug(
    //     'url_updated called:',
    //     this.widgetInstance?.Api.Widget.url_updated
    //   );
    // }

    if (prevProps.user !== this.props.user && this.props.user) {
      this.setInstanceUserData(this.props.user);
    }
  }

  render() {
    if (
      isDisabled({
        NODE_ENV: process.env.NODE_ENV,
        pathname: this.props.location.pathname,
        user: this.props.user,
      })
    ) {
      return null;
    } else {
      return (
        <a href={this.state.helpUrl} target="_blank" rel="noopener">
          <span>{this.props.t('openKnowledgeBase')}</span>
          <i className="i-knowledge-base i-light" />
        </a>
      );
    }
  }
}

export const CustomerSupportWrapper = withRouter(
  withTranslation('module.knowledgeBase')(props => {
    return (
      <Sentry.ErrorBoundary
        fallback={<p>An error has occurred</p>}
        beforeCapture={scope => {
          scope.setTag('location', 'customer-support');
        }}
        key="customer-support"
      >
        <Observer>
          {() => {
            const currentUser = Session.current().user;
            if (!currentUser.isReady) {
              return null;
            }
            return <CustomerSupport user={currentUser.toJS()} {...props} />;
          }}
        </Observer>
      </Sentry.ErrorBoundary>
    );
  })
);
