import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { formatTime } from 'lib/FormattedTime';
import api from '../../utils/ApiClient';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import uuidv4 from 'uuid/v4';
import { HighlightContentBlockClip } from './highlighsContentBlock';
import { CachedAsyncImage, generateClipThumbnail } from './utils/clipThumbnail';
import { ClipEditView } from './clip-edit-view';
import { Modal } from '../../lib';
import { InputCheckboxRadio } from '../../lib/InputCheckBoxRadio';
import { SubmittingButton } from '../../lib/SubmittingButton';

enum ViewStates {
  Init = 'INIT',
  Create = 'CREATE',
  Success = 'SUCCESS',
  Fail = 'FAIL',
}

type ClipCollection = {
  total: number;
  duration: number;
};

export const ClipSelectionView: React.FC<{
  onSuccess(any): void;
  onClose(any): void;
  onAddClips(templateBlockId: string, clips: any[]): void;
  sportingEvents: any[];
  videos: any[];
  highlightVideoId: string;
  template: any;
}> = ({
  onSuccess,
  onClose,
  onAddClips,
  sportingEvents,
  videos,
  highlightVideoId,
  template,
}) => {
  const [view, setView] = useState('');
  const [match, setMatch] = useState<string>();
  const [sportingEvent, setSportingEvent] = useState();
  const [angles, setAngles] = useState<any[]>([]);
  const [currentAngle, setCurrentAngle] = useState<string>();
  const [tags, setTags] = useState<any[]>([]);
  const { t } = useTranslation('module.highlights');
  const [clipCollection, setClipCollection] = useState<ClipCollection>({
    total: 0,
    duration: 0,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [clips, setClips] = useState([]);

  const [formStep, setFormStep] = useState(0);

  const [modalComponent, setModalComponent] = useState(null);

  const getMainVideoId = (sportingEvent) => {
    for (const video of videos) {
      if (sportingEvent.videoIds.includes(video.videoId)) {
        if (video.hasHls && video.state === 'ready') {
          return video.videoId;
        }
      }
    }
    return false;
  };

  const matchOptions = sportingEvents
    .filter(
      (sportingEvent) =>
        sportingEvent.videoIds.length > 0 && getMainVideoId(sportingEvent)
    )
    .sort((a, b) => {
      return a.scheduledAt === b.scheduledAt
        ? 0
        : a.scheduledAt > b.scheduledAt
        ? -1
        : 1;
    })
    .map((sportingEvent) => {
      return {
        value: sportingEvent.sportingEventId,
        label: `${sportingEvent.name} - ${new Date(
          sportingEvent.scheduledAt
        ).toLocaleDateString()}`,
        videoId: getMainVideoId(sportingEvent),
        sportingEvent,
        cameraAngles: sportingEvent.videoIds,
      };
    });

  const handleMatchChange = async (selection) => {
    let angles: any[] = [];
    for (const angle of selection.cameraAngles) {
      for (const video of videos) {
        if (video.videoId === angle && video.state === 'ready') {
          angles.push({
            label: video?.tags?.output_key ?? `stream ${angles.length + 1}`,
            value: angle,
            thumbnail: video.thumbnailUrl,
          });
          break;
        }
      }
    }
    setAngles(angles);
    setCurrentAngle(angles[0].value ?? getMainVideoId(selection.sportingEvent));
    let clockId = null;
    for (const clockName of Object.keys(selection.sportingEvent.clocks)) {
      if (clockName !== 'live') {
        clockId = selection.sportingEvent.clocks[clockName].clockId;
        break;
      }
    }
    if (clockId === null) {
      return;
    }
    setClips([]);
    setTags([]);

    setSportingEvent(selection.sportingEvent);
    setMatch(selection);
  };

  const handleAngleChange = (selection) => {
    setCurrentAngle(selection.value);
    console.log(selection);
    if (clips.length > 0) {
      let _video;
      for (const video of videos) {
        if (video.videoId === selection.value) {
          if (video.hasHls && video.state === 'ready') {
            _video = video;
          }
        }
      }
      const newClips = [...clips];
      newClips.forEach((clip, index) => {
        newClips[index].videoUri = _video?.videoUri;
        newClips[index].videoDuration = _video?.content.duration;
        newClips[index].video = _video;
      });
      setClips(newClips);
    }
  };

  const getObservations = async (_sportingEvent) => {
    let clockId = null,
      nonLiveClockId = null;

    // TODO: use getMainClockId or similar
    for (const clockName of Object.keys(_sportingEvent.clocks)) {
      const clock = _sportingEvent.clocks[clockName];
      if (clockName !== 'live') {
        nonLiveClockId = clock.clockId;
        if (clock.synchronizationPoints.length > 0) {
          clockId = clock.clockId;
          break;
        }
      }
    }
    if (clockId === null) {
      // As a fallback, select the nonLiveClock when there is no clock found with synchronizationPoints
      clockId = nonLiveClockId;
    }

    if (clockId === null) {
      return;
    }
    const _observations = await api.get(
      `sportingEvents/${_sportingEvent.sportingEventId}/observations/${clockId}`
    );
    return _observations.data.filter((observation) => {
      return observation.clockId[0] === clockId[0];
    });
  };

  const getObservationOptions = (observations, videoId) => {
    const observationGroups = {};

    let videoUri = '';
    let videoDuration;
    for (const video of videos) {
      if (video.videoId === videoId) {
        videoUri = video.videoUri;
        videoDuration = video.content.duration;
        break;
      }
    }

    for (const observation of observations) {
      let start, end;
      let contentId = uuidv4();
      if (observation.startTime === observation.endTime) {
        start = observation.startTime - 7;
        end = observation.endTime + 5;
      } else {
        start = observation.startTime;
        end = observation.endTime;
      }
      observationGroups[observation.description] = (
        observationGroups[observation.description] || []
      ).concat({
        start: parseInt(start),
        end: parseInt(end),
        contentId,
        name: observation.description,
        videoUri,
        sportingEventId: sportingEvent.sportingEventId,
        overlays: template?.clipDefaults,
        type: 'clip.Video',
        videoId: videoId,
        videoDuration: videoDuration,
        selected: true,
      });
    }
    const options: any[] = [];
    for (const description of Object.keys(observationGroups)) {
      options.push({
        value: description,
        label: description,
        clips: observationGroups[description],
      });
    }

    return options;
  };

  const observations = useQuery(
    ['observations', sportingEvent?.sportingEventId],
    async () => {
      return sportingEvent?.sportingEventId
        ? await getObservations(sportingEvent)
        : [];
    },
    {
      enabled: !!sportingEvent?.sportingEventId,
      refetchOnWindowFocus: true,
      placeholderData: [],
    }
  ).data;

  const observationOptions = getObservationOptions(observations, currentAngle);

  useEffect(() => {
    const clips: any[] = [];
    for (const tag of tags) {
      clips.push(...tag.clips);
    }
    setClips(
      clips.sort((a, b) => {
        return a.start - b.start;
      })
    );
    setClipCollection({
      total: clips.filter((c) => c.selected).length,
      duration: clips
        .filter((c) => c.selected)
        .reduce((sum, clip) => sum + clip.end - clip.start, 0),
    });
  }, [tags]);
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setIsSubmitted(true);

    if (clipCollection.total < 1) {
      return;
    }

    try {
      setView(ViewStates.Create);

      await onAddClips(clips.filter((c) => c.selected));

      setView(ViewStates.Success);
      onSuccess(true);
    } catch (e) {
      setView(ViewStates.Fail);
      throw new Error(e);
    }
  };

  const FormSelectMatchTags = () => {
    return (
      <div>
        <div className="modal-header">
          <h5 className="modal-title">{t('selectVideoData')}</h5>
          <p>{t('selectVideoDataDescription')}</p>
        </div>
        <div className="form-group">
          <label htmlFor="match">{t('selectMatch')}:</label>
          <Select
            options={matchOptions}
            onChange={handleMatchChange}
            value={match}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
          />
        </div>

        {observationOptions?.length > 0 && (
          <div className="form-group">
            <label htmlFor="match">{t('selectTags')}:</label>
            <Select
              options={observationOptions}
              isMulti
              onChange={(options) => setTags(options)}
              value={tags}
            />
          </div>
        )}
        {observationOptions?.length === 0 && sportingEvent && (
          <div>
            <label htmlFor="match">{t('selectTags')}:</label>
            <div className="noTagsWarning">
              <div>
                {t('noTagsFound')} <b>{sportingEvent.name}</b>
              </div>
              <a
                href={`/observe/${sportingEvent.sportingEventId}/video-start/default`}
                target={'_blank'}
                className={'btn btn-link'}
              >
                {t('startTagging')}
              </a>
            </div>
          </div>
        )}
        {angles && angles.length > 1 && (
          <div className="form-group">
            <label htmlFor="match">{t('selectStream')}:</label>
            <p className={'small'}> </p>
            <div className="select-stream">
              {angles.map((angle) => {
                return (
                  <div
                    className={`${
                      angle.value === currentAngle ? 'selected' : ''
                    }`}
                    onClick={() => handleAngleChange(angle)}
                  >
                    <img src={angle.thumbnail} alt="" />
                    <span>{angle.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {clipCollection.total > 0 ? (
          <p>
            {t('results') + ' '}
            <strong>
              {clipCollection.total} {t('clips')}
            </strong>
            . {t('duration')}{' '}
            <strong>{formatTime(clipCollection.duration)}</strong>.
            <ol>
              {clips
                .filter((c) => c.selected)
                .map((clip, index) => {
                  if (index == 7 && clips.length > 9) {
                    return (
                      <div key={`cliptext-${index}`}>
                        {t('andMore', { amount: clips.length - 7 })}
                      </div>
                    );
                  }
                  if (index > 7 && clips.length > 9) {
                    return;
                  }
                  return (
                    <li key={`cliptext-${index}`}>
                      {formatTime(clip.start)}- {clip.name}
                    </li>
                  );
                })}
            </ol>
          </p>
        ) : (
          <p>{t('noClipsFound')}</p>
        )}
      </div>
    );
  };
  const getClipVideo = (clipVideoId) => {
    for (const video of videos) {
      if (video.videoUri === clipVideoId) {
        return video;
      }
    }
  };

  const toggleClipSelection = (clipIndex) => {
    const newClips = [...clips];
    if (typeof clipIndex == 'number') {
      newClips[clipIndex].selected = !clips[clipIndex].selected;
    }
    if (clipIndex == 'all' || clipIndex == 'none') {
      newClips.forEach((clip, index) => {
        newClips[index].selected = clipIndex === 'all';
      });
    }
    setClipCollection({
      total: newClips.filter((c) => c.selected).length,
      duration: newClips
        .filter((c) => c.selected)
        .reduce((sum, clip) => sum + clip.end - clip.start, 0),
    });
    setClips(newClips);
  };
  const FormSelectClips = () => {
    let allSelected = clips.filter((c) => c.selected).length === clips.length;
    return (
      <div>
        <div className="modal-header">
          <h5 className="modal-title">{t('selectClips')}</h5>
          <p>{t('selectClipsDescription')}</p>
        </div>
        <InputCheckboxRadio
          checked={allSelected}
          onChange={() => {
            toggleClipSelection(allSelected ? 'none' : 'all');
          }}
          label={allSelected ? t('common:deselectAll') : t('common:selectAll')}
        />
        {clips.map((clip, index) => {
          let video = getClipVideo(clip.videoUri);
          let angle = video?.tags?.output_key;
          return (
            <div
              className={'clip-selection__clip d-flex'}
              style={{ marginBottom: '1rem', width: '100%' }}
              key={`clip_${index}`}
            >
              <InputCheckboxRadio
                checked={clip.selected}
                onChange={() => {
                  toggleClipSelection(index);
                }}
              />
              <HighlightContentBlockClip
                name={clip.name}
                duration={clip.end - clip.start}
                highlightVideoId={null}
                templateBlockId={null}
                contentId={clip.contentId}
                onDelete={null}
                onClick={() => {
                  toggleClipSelection(index);
                }}
                startTime={clip.start}
                seStart={clip.start}
                seEnd={clip.end}
                endTime={clip.end}
                index={index}
                angle={angle}
                sportingEventLabel={match?.label}
                img={
                  <CachedAsyncImage
                    promise={async () => {
                      return await generateClipThumbnail(
                        video.videoId,
                        clip.start,
                        250
                      );
                    }}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    cacheKey={`${video.videoId}${clip.start}-250`}
                  />
                }
                onEditClip={openEditClip}
              />
            </div>
          );
        })}
        <button
          className="btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            setFormStep(0);
          }}
        >
          {t('common:done')}
        </button>
      </div>
    );
  };

  const CurrentStep = () => {
    switch (formStep) {
      case 0:
        return <FormSelectMatchTags />;
      case 1:
        return <FormSelectClips />;
      default:
        return <FormSelectMatchTags />;
    }
  };

  const openEditClip = (contentId, action, time) => {
    setModalComponent(
      <Modal size={'fullscreen'} onCloseClick={() => setModalComponent(null)}>
        <ClipEditView
          highlightVideo={{ content: clips }}
          onSuccess={() => setModalComponent(null)}
          onClose={() => setModalComponent(null)}
          onSave={(_clips) => {
            setClips(_clips);
          }}
          contentId={contentId}
          action={action}
          template={template}
          videos={videos}
        />
      </Modal>
    );
  };

  return (
    <form
      className="form"
      onSubmit={(e) => {
        e.preventDefault();
        // handleSubmit()
      }}
    >
      <CurrentStep />
      <div className="form-group actions">
        {clips.length > 0 && formStep == 0 && (
          <button
            className="btn btn-primary"
            disabled={view === ViewStates.Init || clips.length === 0}
            onClick={(e) => {
              e.preventDefault();
              setFormStep(1);
            }}
          >
            {/*{t('common:next')}*/}
            <i className={'i-edit i-sm i-light'} /> {t('editClips')}
          </button>
        )}
      </div>
      {formStep == 0 && (
        <>
          <hr />
          <div style={{ marginTop: '22px' }}>
            <SubmittingButton
              type="submit"
              className="btn btn-primary btn-large"
              onClick={(e) => handleSubmit(e)}
              disabled={
                view === ViewStates.Init ||
                clips.filter((c) => c.selected).length === 0
              }
            >
              {/*{t('addClips')}*/}
              {t('insertClips', {
                amount:
                  clips.filter((c) => c.selected).length > 0
                    ? clips.filter((c) => c.selected).length
                    : '',
              })}
            </SubmittingButton>
          </div>
          <div style={{ margintop: '1rem' }}>
            <button
              type="button"
              className="btn btn-link btn-large"
              onClick={onClose}
            >
              {t('common:cancel')}
            </button>
          </div>
        </>
      )}
      {modalComponent}
    </form>
  );
};
