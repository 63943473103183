/*
 * The current setup with the markers is that we have markerLevel0 instead of learningLine etc.
 * before refactoring, we need to map these to the domain properties
 */
export const domainToInternalPropertyMapping = {
  markerLevel0: 'learningLineId',
  markerLevel1: 'phaseId',
  markerLevel2: 'indicatorId',
};
export const mapInternalPropertiesToDomain = (obj: any) => {
  return obj.reduce((acc, prop) => {
    return {
      ...acc,
      [domainToInternalPropertyMapping[prop.key] || prop.key]: prop.value,
    };
  }, {});
};

export const mapDomainPropertiesToInternal = (obj: any) => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = Object.keys(domainToInternalPropertyMapping).find(
      k => domainToInternalPropertyMapping[k] === key
    );

    return {
      ...acc,
      [newKey || key]: obj[key],
    };
  }, {});
};
