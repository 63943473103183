import Command from '../../infra/Messaging/Command';

Command.register('LineUp.Create', 'lineUpId');

Command.register('LineUp.RemoveSubstitute', 'lineUpId teamId personId');
Command.register('LineUp.RemovePlayer', 'lineUpId teamId personId');
Command.register('LineUp.AddSubstitute', 'lineUpId teamId personId');
Command.register('LineUp.AddPlayer', 'lineUpId teamId personId position');
Command.register(
  'LineUp.SwapSubstituteWithPlayer',
  'lineUpId teamId inPersonId outPersonId position'
);

Command.register('LineUp.MovePlayerToSubstitute', 'lineUpId teamId personId');
Command.register(
  'LineUp.MoveSubstituteToPlayer',
  'lineUpId teamId personId position'
);
Command.register(
  'LineUp.ChangePlayerPosition',
  'lineUpId teamId personId position'
);

Command.register(
  'LineUp.SwapUnAssignedWithSubstitute',
  'lineUpId teamId inPersonId outPersonId'
);
Command.register(
  'LineUp.SwapUnAssignedWithPlayer',
  'lineUpId teamId inPersonId outPersonId position'
);
Command.register(
  'LineUp.SwapPlayerWithPlayer',
  'lineUpId teamId inPersonId inPosition outPersonId outPosition'
);

Command.register('LineUp.SwapAttackDefence', 'lineUpId teamId');
