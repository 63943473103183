import React, { Component } from 'react';
import { asyncAction } from 'mobx-utils';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ReactJWPlayer from 'react-jw-player';
import * as mobx from 'mobx';

import { Modal } from 'lib/Modal';
import { EventPublisher } from 'utils/EventPublisher';
import { ReactPlayer } from 'lib/ReactPlayer';

//
//
// class PaginationP extends Pagination {
//     handleItemOverrides = (active, type, value) => {
//         const className = type === "pageItem" ? this.props.pageItem._classnames[value - 1] : "";
//         return (predefinedProps) => ({
//             active,
//             type,
//             className: className,
//             key: `${type}-${value}`,
//             onClick: (e, itemProps) => {
//                 this.handleItemClick(e, itemProps)
//             },
//         });
//     }
// }
//
// const VideoPagination = observer(({totalItems, activeItem, onClick, classNames}) => {
//     // Pagination is 1-based, we need 0-based
//     return <PaginationP
//         firstItem={null}
//         lastItem={null}
//         pointing
//         secondary
//         pageItem={{_classnames: classNames}}
//         onPageChange={(event, {activePage}) => { onClick(activePage - 1);} }
//         activePage={activeItem.get() + 1}
//         totalPages={totalItems}/>;
//
// });
//

const Pagination = observer(
  ({ playlistItems, activeItem, onClick, successMetric }) => {
    return playlistItems.map((item, n) => {
      return (
        <div
          key={n}
          className={`observation-circle float-left text-center clickable ${
            n === activeItem.get() ? 'active' : ''
          } ${item.attributes[successMetric] ? 'success' : ''}`}
          onClick={() => onClick(n)}
        >
          {n + 1}
        </div>
      );
    });
  }
);
const VideoPlayer = observer(({ playlist }) => {
  const activeItem = observable(0);

  const { playlistItems, options } = mobx.toJS(playlist.get());

  const playlistData = playlistItems.map(item => {
    return {
      file: item.sources[0],
      title: item.description,
    };
  });

  let playerRef = null;

  const onReady = () => {
    playerRef = window.jwplayer('playlistPlayer');
    playerRef.on(
      'playlistItem',
      action(({ index }) => {
        activeItem.set(index);
      })
    );
  };

  return [
    <div className="row" key="player">
      <div className="col-12">
        <ReactPlayer
          playerId="playlistPlayer"
          playlistItems={playlistData}
          onReady={onReady}
          noDimensions
        />
      </div>
    </div>,
    <div className="row" key="pagination">
      <div className="col-12">
        <Pagination
          playlistItems={playlistItems}
          activeItem={activeItem}
          successMetric={options.successMetric}
          onClick={n => {
            playerRef.playlistItem(n);
          }}
        />
      </div>
    </div>,
  ];
  // <VideoPagination
  //     totalItems={playlistData.length}
  //     activeItem={activeItem}
  //     classNames={classNames}
  //
});

const VideoPlayerWrapper = observer(({ playlist, showPlayer }) => {
  if (!showPlayer.get()) return null;

  return (
    <Modal
      size="lg"
      onCloseClick={action(() => {
        showPlayer.set(false);
      })}
    >
      <VideoPlayer playlist={playlist} />
    </Modal>
  );
});

const Dashboard = observer(
  class Dashboard extends Component {
    // LayoutComponent
    // Layout = <Layout videoLoader/>
    //

    constructor() {
      super();

      this.playlist = observable(false);
      this.showPlayer = observable(false);
    }

    loadVideo = asyncAction(function*(
      report,
      cell,
      {
        successMetric,
        durationBefore,
        durationAfter,
        observationId = null,
      } = {}
    ) {
      if (cell.count > 0 || cell.duration > 0 || observationId !== null) {
        let playlistItems = yield report.getVideoPlaylist(
          cell.path,
          durationBefore,
          durationAfter
        );
        if (observationId !== null) {
          playlistItems = [
            playlistItems.find(
              playlistItem => playlistItem.observationId === observationId
            ),
          ];
        }
        this.playlist.set({
          playlistItems,
          options: { successMetric },
        });
        this.showPlayer.set(true);

        EventPublisher.dispatch('DASHBOARD_VIDEO_VIEWED');
      }
    }).bind(this);

    render() {
      const {
        layoutComponent: LayoutComponent,
        report,
        sportingEvent,
        includeVideo,
        reportState,
      } = this.props;

      const onClick = includeVideo
        ? (cell, options) => this.loadVideo(report, cell, options)
        : () => {};

      onClick.setDuration = (durationBefore, durationAfter) => {
        return (cell, { successMetric } = {}) => {
          return onClick(cell, {
            successMetric,
            durationBefore,
            durationAfter,
          });
        };
      };

      const homeTeamReport = report.c(`team_id:${sportingEvent.homeTeam.id}`);
      const awayTeamReport = report.c(`team_id:${sportingEvent.awayTeam.id}`);

      return (
        <div>
          <LayoutComponent
            onClick={onClick}
            homeTeam={sportingEvent.homeTeam}
            awayTeam={sportingEvent.awayTeam}
            homeTeamReport={homeTeamReport}
            awayTeamReport={awayTeamReport}
            reportState={reportState}
          />
          {includeVideo && (
            <VideoPlayerWrapper
              playlist={this.playlist}
              showPlayer={this.showPlayer}
            />
          )}
        </div>
      );
    }
  }
);
//
// class KorfballLayout extends Component {
//     render() {
//         const Library = this.props.elementLibrary;
//         <div>
//             <Library.Counter cell={homeAttack.c("blaat")} />
//         </div>
//     }
// }
//
//
//
// const KorfballDashboard = ({sportingEvent}) => {
//     return <Dashboard layout={KorfballLayout} report={sportingEvent.getReport('korfball')} />;
// };

export { Dashboard };
