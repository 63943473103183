import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'modules/observe/components/Form';

import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';
import { Court } from './court';
import { useSchema } from 'lib/Form';
import merge from 'lodash/merge';
import { toJS } from 'mobx';

const uiSchema = {
  videoTime: {
    classNames: 'video-time',
    'ui:field': 'FormFieldObservationVideoTime',
  },
  attributes: {
    area: {
      'ui:field': 'tennisCourtArea',
    },
    result: {
      'ui:widget': 'ButtonGroupSelectWidget',
    },
    comment: {
      'ui:widget': 'textarea',
    },
  },
};

// example of view specific field
const FormFieldTennisCourtArea = ({ formData, onChange, ...props }) => {
  return (
    <Court width={200} onAreaSelect={onChange} value={formData} {...props} />
  );
};

const fields = {
  tennisCourtArea: FormFieldTennisCourtArea,
};

export const ObservationDetailsTennisBase = withTranslation(
  'module.observe.video'
)(
  ({
    schema,
    t,
    onUpdate,
    onDelete,
    video,
  }: {
    schema: any;
    t: (key: string) => string | undefined;
    onUpdate: ObservationUpdateHandler;
    onDelete: () => void;
    video?: object;
  }) => {
    const { current: observation } = useObservationsEditCollectionContext();
    const obs = toJS(observation.attributes);
    const schemaDefaults = {
      properties: {
        attributes: {
          properties: {
            area: {
              default: obs.attributes.area,
            },
            result: {
              default: obs.attributes.result,
            },
            comment: {
              default: obs.attributes.comment,
            },
          },
        },
      },
    };

    const schemaResolved = useSchema(schema, t, schemaDefaults);

    // allow for async schema loading
    if (!schemaResolved || !video || !observation) {
      return null;
    }

    const uiSchemaMerged = { ...uiSchema };

    return (
      <div className="observation-detail-video-overlay-default">
        {observation.description && (
          <h2 className="modal-title">{observation.description}</h2>
        )}
        <Form
          observation={observation}
          video={video}
          schema={schemaResolved}
          uiSchema={uiSchemaMerged}
          fields={fields}
          onSubmit={onUpdate}
          onDelete={onDelete}
        />
      </div>
    );
  }
);
