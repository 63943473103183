import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import { tenantCollection } from 'domain/Tenant';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';

import { gotoPath } from 'modules/route';
import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';

import { NoResults } from 'lib/PlaceHolder';

import './index.scss';

const ResellerOverviewPage = withTranslation('module.reseller')(
  observer(
    class ResellerOverviewPage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          const currentSession = Session.current();

          yield currentSession.isReady();
          yield tenantCollection.fetchIfEmpty();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e);
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      gotoNewClub() {
        gotoPath('/reseller/new');
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <div>Error...</div>;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              title:
                this.props.t('title') /* this.props.t('playerDevelopment') */,
            },
          ],
          primaryAction: {
            title: this.props.t('addClub'),
            onClick: this.gotoNewClub,
          },
        };

        const tenants = tenantCollection.toArray();
        tenants.sort((a, b) => a.name.localeCompare(b.name));

        return (
          <Page {...pageProps}>
            {tenants.length === 0 ? (
              <NoResults />
            ) : (
              <table className="table tenant-overview">
                <thead>
                  <tr>
                    <th>Clubnaam</th>
                    <th>Type licentie</th>
                    <th>Producten</th>
                    <th>Einddatum</th>
                    {/*<th>Aantal teams</th>*/}
                    {/*<th>Aantal gebruikers</th>*/}
                    {/*<th>Aantal GB</th>*/}
                    {/*<th>Aantal actieve teams</th>*/}
                    {/*<th>Aantal actieve spelers</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {tenants.map((tenant, idx) => {
                    const { status: licenseStatus, ...license } =
                      tenant.license;

                    return (
                      <tr
                        key={idx}
                        className={`tenant license-status-${licenseStatus}`}
                        onClick={() => gotoPath(`view/${tenant.tenantId}`)}
                      >
                        <td key="name">{tenant.name}</td>
                        <td key="licenseType">{license.licenseType}</td>
                        <td key="productIds">
                          {!!license.productIds &&
                            license.productIds.map((productId) => (
                              <div key={productId}>{productId}</div>
                            ))}
                        </td>
                        <td key="licenseEnd">
                          {license.endDate &&
                            new Date(license.endDate).toLocaleDateString(
                              undefined,
                              {
                                timeZone: 'UTC',
                              }
                            )}
                        </td>
                        {/*<td key="teamsTotal">{club.teamsTotal}</td>*/}
                        {/*<td key="usersTotal">{club.usersTotal}</td>*/}
                        {/*<td key="dataAmount">{club.license.dataAmount}</td>*/}
                        {/*<td key="teamsActiveTotal">{club.teamsActiveTotal}</td>*/}
                        {/*<td key="usersActiveTotal">{club.usersActiveTotal}</td>*/}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Page>
        );
      }
    }
  )
);

export { ResellerOverviewPage };
