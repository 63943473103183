import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import './styles/index.scss';
import { OutgoingShares } from './containers';
import { ShareOverview } from './overview/overview';
const MODULE_NAME = 'shares';

export default new Module(
  MODULE_NAME,
  [],
  [
    new RouteRedirect('', 'outgoing'),
    new RouteExact('outgoing', OutgoingShares, 'outgoing'),
    new RouteExact('overview', ShareOverview, 'overview'),
  ]
);
