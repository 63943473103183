import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import { exerciseCollection } from 'domain/Exercise';
import videoCollection from 'domain/Video';

import logger from 'utils/Logger';
import Loading from 'lib/Loading';
import { Notification } from 'lib/Notification';

import { gotoPath, gotoRoute } from 'modules/route';
import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';

import { NoResults } from 'lib/PlaceHolder';

import './index.scss';

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    if (successful) {
      Notification.show('Copied to clipboard!');
    }
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

const ExerciseOverviewPage = withTranslation('module.reseller')(
  observer(
    class ExerciseOverviewPage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          yield videoCollection.fetchIfEmpty();
          yield exerciseCollection.fetchIfEmpty();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Exercise',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      async createNewExercise() {
        const exerciseTitle = prompt('Titel van oefening?');
        if (!!exerciseTitle) {
          const exercise = await exerciseCollection.addExercise(exerciseTitle);
          gotoRoute('exercise.edit', { exerciseId: exercise.exerciseId });
        }
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <div>Error...</div>;
        }

        const pageProps = {
          title: 'Oefeningen',
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              title: 'Overzicht',
            },
          ],
          primaryAction: {
            title: 'Oefening toevoegen',
            onClick: this.createNewExercise,
          },
        };

        const exercises = exerciseCollection.toArray();
        exercises.sort((a, b) => {
          const c = (a.tags.category || '').localeCompare(
            b.tags.category || ''
          );
          if (c === 0) {
            return a.title.localeCompare(b.title);
          } else {
            return c;
          }
        });

        return (
          <Page {...pageProps}>
            {exercises.length === 0 ? (
              <NoResults />
            ) : (
              <table className="table exercise-overview">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Categorie</th>
                    <th>Titel</th>
                    <th>Video</th>
                  </tr>
                </thead>
                <tbody>
                  {exercises.map((exercise, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="exercise"
                        onClick={() => gotoPath(`edit/${exercise.exerciseId}`)}
                      >
                        <td
                          onClick={(e) => {
                            fallbackCopyTextToClipboard(exercise.exerciseId);
                            e.stopPropagation();
                          }}
                        >
                          <pre style={{ fontSize: '10px' }}>
                            {exercise.exerciseId}
                          </pre>
                        </td>
                        <td>{exercise.tags.category}</td>
                        <td>{exercise.title}</td>
                        <td>
                          {exercise.hasVideo() &&
                            `Ja: ${exercise.getVideo().parts[0].filename}`}
                          {!exercise.hasVideo() && 'Nee'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </Page>
        );
      }
    }
  )
);

export { ExerciseOverviewPage };
