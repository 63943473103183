import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';

import { Session } from 'domain/Session';
import { sharingGroupCollection } from 'domain/SharingGroup';
import Loading from 'lib/Loading';
import { OverviewItem } from 'lib/OverviewItem';

import clubPlaceholderIcon from 'img/icons/club_placeholder.svg';
import { Page } from 'lib/Page';

import noMembershipsImage from 'img/no_teams.svg';
import { NoResults } from 'lib/PlaceHolder';
import { Popup } from 'lib/notifications';

import { withTranslation } from 'react-i18next';
import { Modal } from 'lib/Modal';

import { ButtonGroupSelect } from 'lib/ButtonGroupSelect';
import { InputCheckboxRadio } from 'lib/InputCheckBoxRadio';

const RequestShare = withTranslation('module.exchange')(
  ({ t, sharingGroups, requestShare, requestModalOpen }) => {
    const [selectedSharingGroupId, setSelectedSharingGroupId] = useState(null);

    const [teams, setTeams] = useState([]);

    const [selectedRole, setSelectedRole] = useState('team');
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const [tocAccepted, setTocAccepted] = useState(false);
    const [modalOpen, setModalOpen] = useState(requestModalOpen || false);
    const currentSession = Session.current();

    useEffect(() => {
      setTeams([]);

      if (selectedSharingGroupId !== null) {
        const sharingGroup = sharingGroupCollection.get(selectedSharingGroupId);
        (async () => {
          await sharingGroup.teams.fetchIfEmpty();
          const teams = sharingGroup.teams.toArray();
          teams.sort((a, b) => a.name.localeCompare(b.name));
          setTeams(teams);
        })();
      }
    }, [sharingGroups, selectedSharingGroupId]);
    const setGroup = (inputValue, action) => {
      if (inputValue.value) {
        setSelectedSharingGroupId(inputValue.value);
      }
    };
    const setTeam = (inputValue, action) => {
      if (inputValue.value) {
        setSelectedTeamId(inputValue.value);
      }
    };

    const selectedSharingGroupName = selectedSharingGroupId
      ? sharingGroupCollection.get(selectedSharingGroupId).name
      : '';

    const roleOptions = [
      {
        value: 'team',
        label: t('common.role:team'),
      },
      {
        value: 'referee',
        label: t('common.role:referee'),
      },
      {
        value: 'spectator',
        label: t('common.role:spectator'),
      },
    ];

    const tosApplicable = selectedRole === 'team';
    const teamName = currentSession.currentMembership()?.targetResourceName;

    const pouleNotInListMessage = {
      subject: encodeURIComponent(
        t('pouleNotInListSubject', { teamName: teamName })
      ),
      body: encodeURIComponent(
        t('pouleNotInListBody', {
          teamName: teamName,
          sportType: currentSession.sportType(),
        })
      ),
    };

    const teamNotInListMessage = {
      subject: encodeURIComponent(
        t('teamNotInListSubject', {
          group: selectedSharingGroupName,
          teamName: teamName,
        })
      ),
      body: encodeURIComponent(
        t('teamNotInListBody', {
          group: selectedSharingGroupName,
          teamName: teamName,
        })
      ),
    };

    return (
      <>
        <div className={''}>
          <button
            className={'btn btn-primary'}
            onClick={() => setModalOpen(true)}
          >
            {t('ctaSubscribe')}
          </button>
        </div>
        {modalOpen && (
          <Modal onCloseClick={() => setModalOpen(false)} size={'md'}>
            <Fragment>
              <h3>{t('enable')}</h3>
              <label>{t('selectPoule')}</label>
              <br />
              <Select
                onChange={setGroup}
                options={sharingGroups.map((sharingGroup) => {
                  return {
                    value: sharingGroup.sharingGroupId,
                    label: sharingGroup.description,
                  };
                })}
              />
              <div className={'userlogin__warning userlogin__warning--info'}>
                <label>{t('pouleNotInListPre')}</label>
                <a
                  href={`mailto:support@teamtvsport.com?subject=${pouleNotInListMessage.subject}&body=${pouleNotInListMessage.body}`}
                >
                  {t('pouleNotInListLink')}
                </a>
              </div>
              <br />
              <label>{t('selectRole')}</label>
              <br />
              <ButtonGroupSelect
                radio
                options={roleOptions}
                value={selectedRole}
                onChange={(value) => setSelectedRole(value)}
              />
              <br />
              <br />
              {selectedRole === 'team' && selectedSharingGroupId !== null && (
                <>
                  <label>{t('selectTeam')}</label>
                  <br />
                  <Select
                    onChange={setTeam}
                    options={teams.map((team) => {
                      return {
                        value: team.teamId,
                        label: team.name,
                        disabled: team.shareState !== 'AVAILABLE',
                      };
                    })}
                    isOptionDisabled={(option) => option.disabled}
                  />
                  <div
                    className={'userlogin__warning userlogin__warning--info'}
                  >
                    <label> {t('teamNotInListPre')} </label>
                    <a
                      href={`mailto:support@teamtv.nl?subject=${teamNotInListMessage.subject}&body=${teamNotInListMessage.body}`}
                    >
                      {t('teamNotInListLink')}
                    </a>
                  </div>
                  <div style={{ marginTop: '24px' }} className="form-group">
                    <div className="form-check">
                      <InputCheckboxRadio
                        className="form-check-input"
                        type="checkbox"
                        disabled={selectedTeamId === ''}
                        onChange={() => setTocAccepted(!tocAccepted)}
                        checked={tocAccepted}
                        label={
                          <span>
                            {t('checkConfirm')}
                            <a
                              target={'_blank'}
                              rel={'noopener'}
                              href={t('checkConfirmLink')}
                            >
                              {t('checkConfirmRules')}
                            </a>
                          </span>
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="form-actions">
                <button
                  style={{ marginTop: '12px' }}
                  disabled={!tocAccepted && tosApplicable}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => {
                    requestShare(
                      selectedSharingGroupId,
                      selectedRole,
                      selectedTeamId
                    );
                    setModalOpen(false);
                  }}
                >
                  {t('buttonRequest')}
                </button>
              </div>
            </Fragment>
          </Modal>
        )}
      </>
    );
  }
);

const SharingGroupInfo = withTranslation('module.exchange')(
  ({ t, sharingGroup, isCurrentSeason }) => {
    const [teams, setTeams] = useState(null);
    const [popupOpen, openPopup] = useState(false);
    const currentSession = Session.current();
    useEffect(() => {
      (async () => {
        await sharingGroup.teams.fetchIfEmpty();
        const teams = sharingGroup.teams.toArray();
        teams.sort((a, b) => a.name.localeCompare(b.name));
        setTeams(teams);
      })();
    }, [sharingGroup]);
    const handleClick = () => {
      openPopup(!popupOpen);
    };

    return (
      <>
        <div
          className={`exchangeRow ${isCurrentSeason ? 'current--season' : ''}`}
          onClick={handleClick}
        >
          <OverviewItem
            key={sharingGroup.id}
            title={sharingGroup.seasonId}
            details={() => (
              <>
                <a onClick={handleClick} target="_blank" rel="noopener">
                  {sharingGroup.description}
                </a>
                {!sharingGroup.accepted && (
                  <div className={'info pending'}>
                    <i className={'i-pending'} />
                    <div className={'info-text'}>
                      <b>{t('requestPending')}</b>
                    </div>
                  </div>
                )}
              </>
            )}
            features={`${teams ? teams.length : ''} ${t('teams')}`}
          />
        </div>
        {popupOpen && (
          <Popup onCloseCallback={handleClick}>
            <h4>{sharingGroup.description}</h4>
            {teams !== null &&
              teams.map((team) => {
                const overviewItemProps = {
                  title: team.name,
                  icon: clubPlaceholderIcon,
                };
                switch (team.shareState) {
                  case 'AVAILABLE':
                    const inviteMessage = {
                      subject: encodeURIComponent(
                        t('inviteSubject', {
                          teamName: team.name,
                          senderTeamName: currentSession.targetResourceName(),
                        })
                      ),
                      body: encodeURIComponent(
                        t('inviteBody', {
                          teamName: team.name,
                          senderTeamName: currentSession.targetResourceName(),
                        })
                      ),
                    };

                    overviewItemProps.details = () => (
                      <a
                        href={`mailto:?subject=${inviteMessage.subject}&body=${inviteMessage.body}`}
                      >
                        {t('invite')}
                      </a>
                    );
                    overviewItemProps.inactive = true;
                    break;
                  case 'REQUESTED':
                    overviewItemProps.details = () => t('requested');
                    break;
                  case 'CLAIMED':
                    overviewItemProps.details = () => t('participant');
                    break;
                }
                return <OverviewItem key={team.id} {...overviewItemProps} />;
              })}
          </Popup>
        )}
      </>
    );
  }
);
const IncomingShares = withTranslation('module.exchange')(
  ({ t, requestModalOpen }) => {
    const [sharingGroups, setSharingGroups] = useState(undefined);
    const [sharingGroupList, setSharingGroupList] = useState([]);

    const currentSession = Session.current();
    const resourceGroup = currentSession.currentResourceGroup();

    const loadShares = async (reload = false) => {
      const incomingShares = resourceGroup.incomingShares;
      await Promise.all([
        reload
          ? sharingGroupCollection.fetch()
          : sharingGroupCollection.fetchIfEmpty(),
        reload ? incomingShares.fetch() : incomingShares.fetchIfEmpty(),
      ]);

      const sharingGroupList = sharingGroupCollection.toArray();
      sharingGroupList.sort((a, b) => a.name.localeCompare(b.name));
      setSharingGroupList(
        sharingGroupList.filter((sharingGroup) => sharingGroup.isCurrentSeason)
      );

      // Demo matches are incomingShares too. But demo matches
      // are not part of the sharingGroupCollection.
      // Make sure to filter out all those internal/private shares.
      const sharingGroups = incomingShares
        .toArray()
        .map((incomingShare) => {
          const share = sharingGroupCollection.get(
            incomingShare.sharingGroupId
          );
          if (!share) {
            // When not a public share, filter out
            return undefined;
          }
          // TODO: make this less ugly
          share.accepted = !incomingShare.isRequest;
          return share;
        })
        .filter((share) => share !== undefined);

      setSharingGroups(
        sharingGroups.sort((a, b) => b.seasonId.localeCompare(a.seasonId))
      );
    };

    useEffect(() => {
      loadShares();
    }, []);

    const requestShare = async (sharingGroupId, roleName, teamId) => {
      // return;
      const sharingGroup = sharingGroupCollection.get(sharingGroupId);
      await sharingGroup.requestShare(roleName, teamId);
      loadShares(true);
    };

    if (sharingGroups === undefined) {
      return <Loading type={'fullscreen'} />;
    }
    const pageProps = {
      title: t('exchange'),
    };

    return (
      <Page {...pageProps}>
        <RequestShare
          sharingGroups={sharingGroupList.filter(
            (q) => !sharingGroups.includes(q)
          )} // Only show non-listed items in select.
          requestShare={requestShare}
          requestModalOpen={requestModalOpen}
        />
        <br />
        {sharingGroups.length === 0 && (
          <NoResults
            imgSrc={noMembershipsImage}
            description={<span>{t('noExchangeVideos')}</span>}
          />
        )}
        {sharingGroups.length > 0 &&
          sharingGroups.map((sharingGroup) => (
            <SharingGroupInfo
              isCurrentSeason={sharingGroup.isCurrentSeason}
              key={sharingGroup.id}
              sharingGroup={sharingGroup}
            />
          ))}
      </Page>
    );
  }
);

export { IncomingShares };
