import {
  AbsoluteFill,
  interpolate,
  useCurrentFrame,
  Audio,
  Sequence,
} from 'remotion';
import { loadFont } from '@remotion/google-fonts/Lato';

import Play from './play.svg';
import TeamTV from './teamtv.svg';
import wave from './sweep_intro.wav';

import zIndexes from '../../helpers/zIndexes.json';

export const Default: React.FC = ({ durationInFrames, clip }) => {
  const { fontFamily } = loadFont(); // "Titan One"
  const frame = useCurrentFrame();
  const totalDuration = durationInFrames;
  const animationDuration = durationInFrames * 0.2;
  const logoPosition = interpolate(frame, [0, animationDuration], [11, 0], {
    extrapolateRight: 'clamp',
  });
  const logoSize = interpolate(frame, [0, animationDuration], [1.5, 1], {
    extrapolateRight: 'clamp',
  });
  const textWrapper = interpolate(frame, [0, animationDuration], [0, 21], {
    extrapolateRight: 'clamp',
  });
  const textOpacity = interpolate(frame, [0, animationDuration], [0, 1], {
    extrapolateRight: 'clamp',
  });
  const bgPosition = interpolate(frame, [0, totalDuration], [95, 50]);

  let WrapperOpacity = 1,
    wrapperPosition = 0;

  if (frame > durationInFrames * 0.7) {
    WrapperOpacity = interpolate(
      frame,
      [totalDuration * 0.8, totalDuration],
      [1, 0],
      {
        extrapolateRight: 'clamp',
      }
    );
    wrapperPosition = interpolate(
      frame,
      [totalDuration * 0.7, totalDuration],
      [0, 50],
      {
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }
    );
  }
  let videoThumb = clip?.options?.thumbnailUrl;

  const introStyles = {
    h3: {
      fontFamily: [fontFamily],
      color: 'white',
      margin: '0 0 0.5em 0',
      lineHeight: 1.1,
      textAlign: 'center',
      textShadow: '1em 1em 3em rgba(0,0,0,0.75)',
      fontSize: '2.5em',
    },
    h4: {
      fontFamily: [fontFamily],
      color: 'white',
      margin: '0 0 0.5em 0',
      lineHeight: 1.1,
      textAlign: 'center',
      textShadow: '1em 1em 3em rgba(0,0,0,0.75)',
      fontSize: '2em',
      fontWeight: 'normal',
    },
    wipeBg: {
      position: 'absolute',
      width: '400%',
      height: '400%',
      zIndex: 0,
      opacity: 0.75,
      borderRadius: '10em',
      boxShadow: '1em 1em 3em  rgba(0,0,0,0.75)',
    },
    bgImage: {
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      filter: 'grayscale(75%) brightness(0.5) blur(4px)',
      boxShadow: 'inset 0 0 200px rgba(0,0,0,1)',
    },
  };

  return (
    <AbsoluteFill
      className={'introDefault'}
      style={{
        opacity: WrapperOpacity,
        zIndex: zIndexes.intro,
      }}
    >
      <div
        className="bg-image"
        style={{
          ...introStyles.bgImage,
          backgroundImage: `url(${videoThumb})`,
          opacity: frame > totalDuration - animationDuration ? 0 : 1,
        }}
      ></div>
      <Sequence from={20}>
        <Audio src={wave} />
      </Sequence>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          opacity: WrapperOpacity,
          transform: `translateY(${-wrapperPosition}%)`,
          paddingBottom: '9em',
        }}
      >
        <div
          className="wipebg wipebg0"
          style={{
            ...introStyles.wipeBg,
            backgroundColor: '#2B2D60',
            transform: `rotate(-45deg) translate3d(${
              (-46 + bgPosition) * 1.1
            }%, ${(-46 + bgPosition) * 1.1}%, 0)`,
          }}
        ></div>
        <div
          className="wipebg wipebg1"
          style={{
            ...introStyles.wipeBg,
            backgroundColor: '#3F3A87',
            transform: `rotate(-45deg) translate3d(${
              (-44 + bgPosition) * 1.075
            }%, ${(-44 + bgPosition) * 1.075}%, 0)`,
          }}
        ></div>
        <div
          className="wipebg wipebg2"
          style={{
            ...introStyles.wipeBg,
            backgroundColor: '#5650A3',
            transform: `rotate(-45deg) translateX(${
              (-40 + bgPosition) * 1.05
            }%) translateY(${(-40 + bgPosition) * 1.05}%)`,
          }}
        ></div>
        <img
          style={{
            transform: `translateX(${logoPosition}em) scale(${logoSize})`,
            zIndex: 6,
            position: 'relative',
            width: '4.9em',
          }}
          src={Play}
        />
        <div style={{ width: `21em` }}>
          <div
            className="image-wrapper"
            style={{
              width: `${textWrapper}em`,
              overflow: 'hidden',
              marginLeft: '1em',
              textAlign: 'right',
              transform: `translateX(${logoPosition}em)`,
              position: 'relative',
              zIndex: 4,
              opacity: `${textOpacity}`,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <img style={{ width: '21em' }} src={TeamTV} />
          </div>
        </div>
      </div>

      <div
        className="titles"
        style={{
          position: 'absolute',
          width: '75%',
          margin: '0 auto',
          left: '12.5%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          paddingTop: '10em',
          opacity: WrapperOpacity,
          transform: `translateY(${wrapperPosition}%)`,
        }}
      >
        <h3 style={introStyles.h3}>{clip?.options?.title}</h3>
        <h4 style={introStyles.h4}>{clip?.options?.subtitle}</h4>
      </div>
    </AbsoluteFill>
  );
};
