import { Time } from './Time';
import { Action } from './Action';
import { observable } from 'mobx';

export class ObservationTreeNode {
  public current = observable(false);
  public children: ObservationTreeNode[] = [];
  public mergedObservationId?: string;

  public constructor(
    readonly nodeId: string,
    readonly text: string,

    readonly time: Time,

    public preLabel?: string | false,
    public postLabel?: string | Function,

    readonly actions: Action[] = [],
    public rating?: string,
    public creatorUser?: object
  ) {}

  public addNode(node: ObservationTreeNode) {
    this.children.push(node);
  }

  public can(action: Action) {
    return this.actions.indexOf(action) !== -1;
  }

  public setMergedObservationId(observationId: string) {
    this.mergedObservationId = observationId;
  }
}
