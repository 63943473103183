import React, { Component, Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

import { asyncAction } from 'mobx-utils';
import Loading from 'lib/Loading';
import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import { withTranslation } from 'react-i18next';
import { Page } from 'lib/Page';
import { Error } from 'lib/PlaceHolder/index';
// import { ObservationDetails } from 'modules/observe/containers/observation-details';
import { PlaylistTree } from './playlist-tree';
import { ChatList } from './chat-list/index.tsx';
import { VideoPlayerPagination } from 'lib/VideoPlayerPagination';
import { VideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { playlistCollection } from 'domain/Playlist';
import videoCollection from 'domain/Video';
import { VideoClock } from 'domain/ObservationLogger/Clock';
import sweetAlert from 'lib/sweetAlert';
import { EditFragment } from './playlist-tree/editFragment';
import { Modal } from '../../lib';
import { debounce } from 'lodash';
import { ConversationCollection } from '../../domain/Conversation';

import SportingEventCollection from '../../domain/SportingEvent';
import VideoAngleContextProvider, {
  useVideoAngleContext,
} from '../../lib/VideoAngleContextProvider';
import { VideoAngleSelector } from '../../lib/VideoAngles';
const showFilters = window.isSkillReflect;

export const PlaylistViewPage = withTranslation('module.playlist')(
  observer(
    class SportingEventObservation extends Component {
      constructor(props) {
        super(props);
        this.hasChat = Session.current().isFeatureAvailable('playlistChat');

        this.dataState = observable('loading');
        this.editState = observable(null);
        this.currentVideoFragmentIdx = observable(0);
        this.videoFragments = observable(null);
        this.conversations = observable(null);
        this.sidebarView = observable(this.hasChat ? 'chat' : 'videoFragments'); // chat | videoFragments
        this.disableHotKeys = observable(false);
        this.videoFragmentFilter = observable('all');
        this.sidebarRef = React.createRef();
        this.fragmentAngles = observable(null);
        this.deleteFragment = this.deleteFragment.bind(this);
        this.onEditFragment = this.onEditFragment.bind(this);
        this.onCopyFragment = this.onCopyFragment.bind(this);
        this.onMoveClips = this.onMoveClips.bind(this);
      }

      isVideoFragmentFilterActive = (filter) => () =>
        this.videoFragmentFilter.get() === filter;

      setVideoFragmentFilter = (filter) =>
        action(() => {
          this.videoFragmentFilter.set(filter);
        });

      videoFragmentFilters = showFilters
        ? [
            {
              title: this.props.t('filterOptions.all'),
              onClick: this.setVideoFragmentFilter('all'),
              isActive: this.isVideoFragmentFilterActive('all'),
            },
            {
              title: this.props.t('filterOptions.trainer'),
              onClick: this.setVideoFragmentFilter('trainer'),
              isActive: this.isVideoFragmentFilterActive('trainer'),
            },
            {
              title: this.props.t('filterOptions.trainee'),
              onClick: this.setVideoFragmentFilter('trainee'),
              isActive: this.isVideoFragmentFilterActive('trainee'),
            },
            {
              title: this.props.t('filterOptions.peer'),
              onClick: this.setVideoFragmentFilter('peer'),
              isActive: this.isVideoFragmentFilterActive('peer'),
            },
          ]
        : [];

      componentDidMount() {
        this.loadData();
      }

      setSidebarView = (view) => action(() => this.sidebarView.set(view));

      get playlistId() {
        return this.props.match.params.playlistId;
      }

      setCurrentVideoFragmentIdx = action((index) => {
        if (index !== this.currentVideoFragmentIdx.get()) {
          this.currentVideoFragmentIdx.set(index);
        }
      });

      setDisableHotKeys = action((disable) => {
        this.disableHotKeys.set(disable);
      });

      async getSortedConversations() {
        await this.playlist.conversations.fetch();
        const conversations = await this.playlist.conversations.toArray();
        const videoFragments = this.videoFragments.get();

        conversations.sort((a, b) => {
          const idxDiff =
            videoFragments.findIndex(
              (videoFragment_) =>
                videoFragment_.videoFragmentId ===
                a.tags.videoFragmentId?.toString()
            ) -
            videoFragments.findIndex(
              (videoFragment_) =>
                videoFragment_.videoFragmentId ===
                b.tags.videoFragmentId?.toString()
            );
          if (idxDiff === 0) {
            return a.tags.videoTime - b.tags.videoTime;
          } else {
            return idxDiff;
          }
        });

        this.setConversations(conversations);
      }

      setConversations = action((conversations) => {
        return this.conversations.set(conversations);
      });

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          yield Session.current().isReady();

          yield videoCollection.fetchIfEmpty();
          yield playlistCollection.fetchIfEmpty();
          yield playlistCollection.getOrFetch(this.playlistId);
          this.playlist = playlistCollection.get(this.playlistId);
          yield SportingEventCollection.fetchIfEmpty();
          yield this.playlist.conversations.fetchIfEmpty();
          this.setVideoFragments();
          this.getSortedConversations();

          this.clock = new VideoClock();

          this.dataState.set('ready');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Playlist',
          });
          this.dataState.set('error');
        }
      });

      setVideoFragments = action((_videoFragments) => {
        const videoFragments =
          _videoFragments ?? this.playlist.getVideoFragments();

        this.videoFragments.set(videoFragments);
        if (this.currentVideoFragmentIdx.get() >= videoFragments.length) {
          this.currentVideoFragmentIdx.set(videoFragments.length - 1);
        }
      });

      async deleteFragment(videoFragment) {
        if (this.playlist.groupId === 'own') {
          sweetAlert('', {
            title: this.props.t('removeVideoFragmentConfirm'),
            text: this.props.t('removeVideoFragmentConfirmMessage'),
            dangerMode: true,
            buttons: [
              this.props.t('common:cancel'),
              this.props.t('common:delete'),
            ],
          }).then(async (willDelete) => {
            if (willDelete) {
              await this.playlist.removeVideoFragment(
                videoFragment.videoFragmentId
              );
              this.setVideoFragments();
              this.getSortedConversations();
            }
          });
        }
      }
      async onEditFragment(videoFragment) {
        const response = await this.playlist.editVideoFragment(videoFragment);
        this.closeModal();
        this.refresh(response);
      }

      onCopyFragment = action(async (videoFragmentId) => {
        const { newVideoFragment, newVideoFragments } =
          await this.playlist.copyVideoFragment(videoFragmentId);
        this.editFragment(newVideoFragment);
      });

      async refresh(playlist) {
        this.playlist = playlistCollection.build(playlist);
        this.setVideoFragments(this.playlist.getVideoFragments());
        this.getSortedConversations();
      }

      async onMoveClips(videoFragments) {
        const currentFragments = this.videoFragments.get();
        const currentlyPlayingFragmentId =
          currentFragments[this.currentVideoFragmentIdx.get()]?.videoFragmentId;

        const isPlaying =
          window.jwplayer('video-player')?.getState() === 'playing';
        if (isPlaying) {
          window.jwplayer('video-player').pause();
        }
        this.setVideoFragments(videoFragments);
        this.setCurrentVideoFragmentIdx(
          videoFragments.findIndex(
            (videofragment) =>
              videofragment.videoFragmentId == currentlyPlayingFragmentId
          )
        );
        // Debounce state update, drag component is optimistic in itself

        debounce(async () => {
          const response = await this.playlist.setVideoFragments(
            videoFragments
          );
          this.refresh(response);
          this.setCurrentVideoFragmentIdx(
            videoFragments.findIndex(
              (videofragment) =>
                videofragment.videoFragmentId == currentlyPlayingFragmentId
            )
          );
          if (isPlaying) {
            window.jwplayer('video-player').play();
          }
        }, 500)();
      }

      resizeHandler(aspectRatio) {
        let width, height;
        width =
          window.innerWidth * 0.9 -
          (this.sidebarRef?.current.innerWidth || 550);
        height = window.innerHeight - 100;
        if (width / height < aspectRatio) {
          height = width / aspectRatio;
        } else {
          width = height * aspectRatio;
        }
        return { width: width, height };
      }

      closeModal = action(() => {
        this.editState.set(null);
      });

      editFragment = action((videoFragment) => {
        // pause video player when opening edit.
        window.jwplayer('video-player')?.pause();
        this.editState.set(videoFragment);
      });

      getAngles = (index) => {
        const videoFragments = this.videoFragments.get();
        const current = videoFragments[index];
        if (current) {
          const angles = videoCollection
            .getOrPlaceholder(current.videoId)
            .getOtherAngles();
          return angles;
        }
      };

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const videoFragments = this.videoFragments.get();

        const activeConversationIds = this.playlist.conversations
          .toArray()
          .filter(
            (conversation) =>
              conversation.tags.videoFragmentId?.toString() ===
                videoFragments[this.currentVideoFragmentIdx.get()]
                  .videoFragmentId ?? 0
          )
          .map((conversation) => conversation.conversationId);

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              path: '/playlist/overview',
              title: this.props.t('title'),
            },
            {
              title: this.playlist.name,
            },
          ],
          className: 'page--playlist',
        };
        return (
          <Page noTopPadding fullWidth hideFooter {...pageProps}>
            {this.editState.get() !== null && (
              <Modal
                onCloseClick={action(() => {
                  this.editState.set(null);
                })}
              >
                <EditFragment
                  videoFragment={this.editState.get()}
                  callback={this.onEditFragment}
                  onCancel={this.closeModal}
                />
              </Modal>
            )}
            <VideoAngleContextProvider>
              <div className="layout-with-sidebar">
                <div
                  className="main video-player-container"
                  style={{ flexGrow: '0' }}
                >
                  <VideoPlayer
                    id="video-player"
                    heightAttribute="height"
                    videoFragments={videoFragments}
                    activePlayListItem={this.currentVideoFragmentIdx.get()}
                    onPlaylistItem={(index) => {
                      this.setCurrentVideoFragmentIdx(index);
                    }}
                    clock={this.clock}
                    disableHotKeys={this.disableHotKeys.get()}
                    resizeHandler={this.resizeHandler}
                  />
                  <VideoPlayerPagination
                    currentItem={this.currentVideoFragmentIdx.get()}
                    totalItems={videoFragments.length}
                    setCurrentItem={(index) => {
                      this.setCurrentVideoFragmentIdx(index);
                    }}
                  />
                </div>

                <div ref={this.sidebarRef} className="sidebar">
                  <ul className="nav nav-tabs sidebar-header-tabs">
                    {this.hasChat && (
                      <li className="nav-item">
                        <a
                          className={`nav-link ${
                            this.sidebarView.get() === 'chat' ? 'active' : ''
                          }`}
                          onClick={this.setSidebarView('chat')}
                        >
                          {this.props.t('comments')}
                        </a>
                      </li>
                    )}
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          this.sidebarView.get() === 'videoFragments'
                            ? 'active'
                            : ''
                        }`}
                        onClick={this.setSidebarView('videoFragments')}
                      >
                        {this.props.t('playlist')}
                      </a>
                    </li>
                  </ul>
                  {this.sidebarView.get() === 'chat' && (
                    <ChatList
                      translator={this.props.t}
                      conversations={this.conversations.get()}
                      activeConversationIds={activeConversationIds}
                      onSelect={(chatItem) => {
                        const conversation = this.playlist.conversations.get(
                          chatItem.conversationId
                        );
                        const videoFragment = videoFragments.find(
                          (videoFragment_) =>
                            videoFragment_.videoFragmentId ===
                            conversation.tags.videoFragmentId?.toString()
                        );
                        if (!videoFragment) {
                          return;
                        }
                        this.setCurrentVideoFragmentIdx(
                          videoFragments.indexOf(videoFragment)
                        );
                        setTimeout(() => {
                          this.clock.setTime(
                            videoFragment.startTime +
                              Math.max(0, conversation.tags.videoTime - 1)
                          );
                        }, 1);
                      }}
                      onStartTyping={() => {
                        this.setDisableHotKeys(true);
                        this.clock.pause();
                      }}
                      onStopTyping={() => {
                        this.setDisableHotKeys(false);
                      }}
                      onReply={async ({ chatItem, message }, callback) => {
                        if (!chatItem) {
                          const videoFragment =
                            videoFragments[this.currentVideoFragmentIdx.get()];
                          this.playlist.conversations.create(
                            {
                              comment: message,
                              videoFragmentId: videoFragment.videoFragmentId,
                              videoTime:
                                this.clock.getTime() - videoFragment.startTime,
                            },
                            { optimistic: false }
                          );

                          // This one refreshes very very fast on production. Optionally add setTimeout.
                          setTimeout(() => {
                            callback();
                            this.getSortedConversations();
                          }, 500);
                        } else {
                          const conversation = this.playlist.conversations.get(
                            chatItem.conversationId
                          );
                          await conversation.addComment(message);
                          // TODO: replace with conversation.fetch()
                          this.playlist.conversations.fetch();
                          this.getSortedConversations();
                          callback();
                        }
                      }}
                    />
                  )}
                  {this.sidebarView.get() === 'videoFragments' && (
                    <PlaylistTree
                      videoFragments={
                        this.videoFragmentFilter.get() === 'all'
                          ? videoFragments
                          : videoFragments.filter(
                              (fragment) =>
                                fragment.creatorRoleName ===
                                this.videoFragmentFilter.get()
                            )
                      }
                      onSelect={(videoFragment) => {
                        this.setCurrentVideoFragmentIdx(
                          videoFragments.indexOf(videoFragment)
                        );
                      }}
                      canEdit={this.playlist.canEdit()}
                      onDelete={this.deleteFragment}
                      onEdit={this.editFragment}
                      onCopy={this.onCopyFragment}
                      onMoveClips={this.onMoveClips}
                      currentIdx={this.currentVideoFragmentIdx.value}
                      header={
                        <div className="sidebar-list-item filters">
                          {this.videoFragmentFilters.map((filter, idx) => (
                            <span
                              key={idx}
                              className={`filter ${
                                filter.isActive() ? 'active' : ''
                              }`}
                              onClick={filter.onClick}
                            >
                              {filter.title}
                            </span>
                          ))}
                        </div>
                      }
                    />
                  )}
                </div>
              </div>
            </VideoAngleContextProvider>
          </Page>
        );
      }
    }
  )
);
