import React, { Fragment } from 'react';

const Funnel = ({ title, data, onClick }) => {
  const yPos = [44, 70, 96, 122, 148, 174, 200];

  const opacity = [0.9, 0.75, 0.6, 0.45, 0.3, 0.15];

  let maxValue = 0;
  for (const row of data) {
    maxValue = Math.max(maxValue, row.leftCell.count, row.rightCell.count);
  }

  // make sure we don't get crap when all values are 0
  maxValue = Math.max(1, maxValue);

  const centerX = 140;

  return (
    <g id={`Funnel-${title}`}>
      <text
        x={centerX}
        y="16"
        fontFamily="Avenir-Heavy, Avenir, Lato"
        textAnchor="middle"
        fontSize="16"
        fontWeight="600"
        fill="#000000"
      >
        <tspan>{title}</tspan>
      </text>

      {data.map((row, i) => (
        <text
          key={`label-${i}`}
          x={centerX}
          y={yPos[i]}
          textAnchor="middle"
          fontFamily="Avenir-Book, Avenir, Lato"
          fontSize="10"
          fontWeight="300"
          fill="#000000"
        >
          <tspan>{row.title}</tspan>
        </text>
      ))}

      {/* left */}
      {data.map((row, i) => {
        const leftValue = row.leftCell.count;
        const x = (leftValue / maxValue) * -80 + (centerX - 30);
        let polygon = null;
        if (i > 0) {
          const prevX =
            (data[i - 1].leftCell.count / maxValue) * -80 + +(centerX - 30);
          polygon = (
            <polygon
              pointerEvents="none"
              fillOpacity={opacity[i - 1]}
              fill="#28e89e"
              points={`${prevX} ${yPos[i - 1]} ${centerX - 30} ${
                yPos[i - 1]
              } ${centerX - 30} ${yPos[i]} ${x} ${yPos[i]}`}
            />
          );
        }
        return (
          <Fragment key={`left-${i}`}>
            <rect
              className="clickable"
              fill="#ffffff"
              onClick={() => onClick(row.leftCell)}
              x={x - 30}
              y={yPos[i] - 10}
              width="40"
              height="20"
              rx="7"
            />
            <text
              pointerEvents="none"
              x={x - 10}
              y={yPos[i] + 3}
              textAnchor="end"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="10"
              fontWeight="600"
              fill="#000000"
            >
              <tspan>{leftValue}</tspan>
            </text>
            <circle
              pointerEvents="none"
              fill="#28e89e"
              cx={x}
              cy={yPos[i]}
              r="3"
            />
            {polygon}
          </Fragment>
        );
      })}

      {/* right */}
      {data.map((row, i) => {
        const rightValue = row.rightCell.count;
        const x = (rightValue / maxValue) * 80 + (centerX + 30);
        let polygon = null;
        if (i > 0) {
          const prevX =
            (data[i - 1].rightCell.count / maxValue) * 80 + (centerX + 30);
          polygon = (
            <polygon
              pointerEvents="none"
              fillOpacity={opacity[i - 1]}
              fill="#5650a3"
              points={`${prevX} ${yPos[i - 1]} ${centerX + 30} ${
                yPos[i - 1]
              } ${centerX + 30} ${yPos[i]} ${x} ${yPos[i]}`}
            />
          );
        }
        return (
          <Fragment key={`right-${i}`}>
            <rect
              className="clickable"
              fill="#ffffff"
              onClick={() => onClick(row.rightCell)}
              x={x - 10}
              y={yPos[i] - 10}
              width="40"
              height="20"
              rx="7"
            />
            <text
              pointerEvents="none"
              x={x + 10}
              y={yPos[i] + 3}
              textAnchor="start"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="10"
              fontWeight="600"
              fill="#000000"
            >
              <tspan>{rightValue}</tspan>
            </text>
            <circle
              pointerEvents="none"
              fill="#5650a3"
              cx={x}
              cy={yPos[i]}
              r="3"
            />
            {polygon}
          </Fragment>
        );
      })}
    </g>
  );
};

const Donut = ({ title, leftReport, rightReport, successMetric, onClick }) => {
  const successCountLeft = leftReport.v(successMetric);
  const totalCountLeft = leftReport.count;
  const leftPercentage =
    totalCountLeft > 0 ? successCountLeft / totalCountLeft : 0;

  const successCountRight = rightReport.v(successMetric);
  const totalCountRight = rightReport.count;
  const rightPercentage =
    totalCountRight > 0 ? successCountRight / totalCountRight : 0;

  return (
    <g transform="translate(6, 0)">
      <g>
        <g transform="translate(29.000000, 64.000000)">
          <g>
            <rect
              className="clickable"
              onClick={() => onClick(leftReport, { successMetric })}
              fill="#28e89e"
              x="0"
              y="0"
              width="40"
              height="18"
              rx="7"
            />
            <text
              pointerEvents="none"
              textAnchor="end"
              x="28"
              y="14"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan>{(leftPercentage * 100).toFixed(0)}</tspan>
            </text>
            <text
              pointerEvents="none"
              x="28"
              y="14"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="300"
              fill="#FFFFFF"
            >
              <tspan
                fontFamily="Avenir-Book, Avenir, Lato"
                fontSize="10"
                fontWeight="300"
              >
                %
              </tspan>
            </text>
          </g>
          <g transform="translate(0.000000, 18.000000)">
            <rect
              className="clickable"
              onClick={() => onClick(rightReport, { successMetric })}
              fill="#5650a3"
              x="0"
              y="0"
              width="40"
              height="18"
              rx="7"
            />
            <text
              pointerEvents="none"
              textAnchor="end"
              x="28"
              y="14"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="600"
              fill="#FFFFFF"
            >
              <tspan>{(rightPercentage * 100).toFixed(0)}</tspan>
            </text>
            <text
              pointerEvents="none"
              x="28"
              y="14"
              fontFamily="Avenir-Heavy, Avenir, Lato"
              fontSize="14"
              fontWeight="300"
              fill="#FFFFFF"
            >
              <tspan
                fontFamily="Avenir-Book, Avenir, Lato"
                fontSize="10"
                fontWeight="300"
              >
                %
              </tspan>
            </text>
          </g>
        </g>
        <text
          x="50"
          y="12"
          textAnchor="middle"
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="12"
          fontWeight="600"
          fill="#000000"
        >
          <tspan>{title}</tspan>
        </text>
      </g>
      <path
        d="M51,133 C79.1665222,133 102,110.166522 102,82 C102,53.8334778 79.1665222,31 51,31 C22.8334778,31 0,53.8334778 0,82 C0,110.166522 22.8334778,133 51,133 Z"
        stroke="#F2F2F2"
        strokeWidth="10"
        strokeDasharray="500,100"
        transform="translate(51.000000, 82.000000) scale(-1, 1) translate(-51.000000, -82.000000) "
      />
      <path
        d="M51,133 C79.1665222,133 102,110.166522 102,82 C102,53.8334778 79.1665222,31 51,31 C22.8334778,31 0,53.8334778 0,82 C0,110.166522 22.8334778,133 51,133 Z"
        stroke="#28e89e"
        strokeWidth="10"
        strokeDasharray={`${leftPercentage * 321},500`}
        transform="translate(51.000000, 82.000000) scale(-1, 1) rotate(180.000000) translate(-51.000000, -82.000000) "
      />
      <path
        d="M51,123 C73.6436747,123 92,104.643675 92,82 C92,59.3563253 73.6436747,41 51,41 C28.3563253,41 10,59.3563253 10,82 C10,104.643675 28.3563253,123 51,123 Z"
        stroke="#F2F2F2"
        strokeWidth="10"
        strokeDasharray="500,100"
        transform="translate(51.000000, 82.000000) scale(-1, 1) translate(-51.000000, -82.000000) "
      />
      <path
        d="M51,123 C73.6436747,123 92,104.643675 92,82 C92,59.3563253 73.6436747,41 51,41 C28.3563253,41 10,59.3563253 10,82 C10,104.643675 28.3563253,123 51,123 Z"
        stroke="#5650a3"
        strokeWidth="10"
        strokeDasharray={`${rightPercentage * 258},500`}
        transform="translate(51.000000, 82.000000) scale(-1, 1) rotate(180.000000) translate(-51.000000, -82.000000) "
      />
    </g>
  );
};

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

const LabelledCell = ({ title, cell, onClick, valueWidth }) => {
  const value = cell.count;
  const titleWidth = getTextWidth(title, '300 10 Avenir-Book, Avenir');
  valueWidth = parseInt(
    valueWidth || getTextWidth(value, '600 16 Avenir-Book, Avenir')
  );
  const width = titleWidth + valueWidth + 30;
  return (
    <g className="clickable" onClick={() => onClick(cell)}>
      <rect fill="#F2F2F2" x="0" y="0" width={width} height="32" rx="7" />
      <text
        fontFamily="Avenir-Book, Avenir, Lato"
        fontSize="10"
        fontWeight="300"
        fill="#000000"
      >
        <tspan x="10" y="20">
          {title}
        </tspan>
        <tspan
          x={titleWidth + 20 + valueWidth / 2}
          textAnchor="middle"
          y="20"
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="16"
          fontWeight="600"
        >
          {value}
        </tspan>
      </text>
    </g>
  );
};

const HockeyFunnel = ({ leftReport, rightReport, title, onClick }) => {
  const data = [
    {
      title: title,
      leftCell: leftReport.c('all'),
      rightCell: rightReport.c('all'),
    },
    {
      title: '23 Entry',
      leftCell: leftReport.c('23_entry'),
      rightCell: rightReport.c('23_entry'),
    },
    {
      title: 'C. Entry',
      leftCell: leftReport.c('circle_entry'),
      rightCell: rightReport.c('circle_entry'),
    },
    {
      title: 'S. Oppor.',
      leftCell: leftReport.c('scoring_opportunity'),
      rightCell: rightReport.c('scoring_opportunity'),
    },
    {
      title: 'P. corner',
      leftCell: leftReport.c('penalty_corner'),
      rightCell: rightReport.c('penalty_corner'),
    },
    {
      title: 'P. stroke',
      leftCell: leftReport.c('penalty_stroke'),
      rightCell: rightReport.c('penalty_stroke'),
    },
    {
      title: 'Goal',
      leftCell: leftReport.c('goal'),
      rightCell: rightReport.c('goal'),
    },
  ];
  return <Funnel data={data} title={title} onClick={onClick} />;
};

const Possession = ({ leftReport, rightReport, onClick }) => {
  let totalDuration = leftReport.duration + rightReport.duration;
  if (totalDuration === 0) totalDuration = 1; // make sure division is possible
  const leftDurationPercentage = leftReport.duration / totalDuration;
  const rightDurationPercentage = rightReport.duration / totalDuration;

  const leftWidth = leftDurationPercentage * 300;
  const rightWidth = rightDurationPercentage * 300;

  return (
    <g>
      <g className="clickable" onClick={() => onClick(leftReport)}>
        <rect
          fill="#28e89e"
          x={410 - leftWidth}
          y="0"
          width={leftWidth}
          height="15"
        />
        <text
          textAnchor="end"
          x={410 - leftWidth - 10}
          y="15"
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="14"
          fontWeight="600"
          fill="#000000"
        >
          <tspan>{(leftDurationPercentage * 100).toFixed(0)}</tspan>
        </text>
        <text textAnchor="start" x={410 - leftWidth - 10} y="15">
          <tspan
            fontFamily="Avenir-Book, Avenir, Lato"
            fontSize="10"
            fontWeight="300"
            fill="#000000"
          >
            %
          </tspan>
        </text>
        <text textAnchor="start" x={410 - leftWidth - 80} y="15">
          <tspan
            fontFamily="Avenir-Book, Avenir, Lato"
            fontSize="10"
            fontWeight="300"
            fill="#000000"
          >
            Possession
          </tspan>
        </text>
      </g>
      <g
        transform="translate(530, 0)"
        className="clickable"
        onClick={() => onClick(rightReport)}
      >
        <rect fill="#5650a3" x="0" y="0" width={rightWidth} height="15" />
        <text
          textAnchor="end"
          x={rightWidth + 20}
          y="15"
          fontFamily="Avenir-Heavy, Avenir, Lato"
          fontSize="14"
          fontWeight="600"
          fill="#000000"
        >
          <tspan>{(rightDurationPercentage * 100).toFixed(0)}</tspan>
        </text>
        <text textAnchor="start" x={rightWidth + 20} y="15">
          <tspan
            fontFamily="Avenir-Book, Avenir, Lato"
            fontSize="10"
            fontWeight="300"
            fill="#000000"
          >
            % Possession
          </tspan>
        </text>
      </g>
    </g>
  );
};

const Dashboard = ({ homeTeamReport, awayTeamReport, onClick }) => {
  return (
    <svg viewBox="0 0 940 540" version="1.1">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g class="possession" transform="translate(0, 10)">
          <Possession
            leftReport={homeTeamReport.c('possession')}
            rightReport={awayTeamReport.c('possession')}
            onClick={onClick}
          />
        </g>
        <g transform="translate(0, 35)">
          <g class="goal" transform="translate(75, 0)">
            <LabelledCell
              title="Penalty stroke"
              cell={homeTeamReport.c('overall.goal.type:PENALTY-STROKE')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(195, 0)">
            <LabelledCell
              title="Penalty corner"
              cell={homeTeamReport.c('overall.goal.type:PENALTY-CORNER')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(315, 0)">
            <LabelledCell
              title="Field goal"
              cell={homeTeamReport.c('overall.goal.type:FIELD')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>

          <text
            textAnchor="middle"
            x="470"
            y="20"
            fontFamily="Avenir-Heavy, Avenir, Lato"
            fontSize="12"
            fontWeight="600"
            fill="#000000"
          >
            Goals
          </text>

          <g transform="translate(530, 0)">
            <LabelledCell
              title="Field goal"
              cell={awayTeamReport.c('overall.goal.type:FIELD')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(630, 0)">
            <LabelledCell
              title="Penalty corner"
              cell={awayTeamReport.c('overall.goal.type:PENALTY-CORNER')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(750, 0)">
            <LabelledCell
              title="Penalty stroke"
              cell={awayTeamReport.c('overall.goal.type:PENALTY-STROKE')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
        </g>
        <g transform="translate(0, 80)">
          <g class="action" transform="translate(105, 0)">
            <LabelledCell
              title="Turnover"
              cell={homeTeamReport.c('overall.turnover')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(200, 0)">
            <LabelledCell
              title="23 Entries"
              cell={homeTeamReport.c('overall.23_entry')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(300, 0)">
            <LabelledCell
              title="Circle Entries"
              cell={homeTeamReport.c('overall.circle_entry')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>

          <text
            textAnchor="middle"
            x="470"
            y="20"
            fontFamily="Avenir-Heavy, Avenir, Lato"
            fontSize="12"
            fontWeight="600"
            fill="#000000"
          >
            Actions
          </text>

          <g transform="translate(530, 0)">
            <LabelledCell
              title="Circle Entries"
              cell={awayTeamReport.c('overall.circle_entry')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(645, 0)">
            <LabelledCell
              title="23 Entries"
              cell={awayTeamReport.c('overall.23_entry')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(745, 0)">
            <LabelledCell
              title="Turnover"
              cell={awayTeamReport.c('overall.turnover')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
        </g>
        <g transform="translate(0, 120)">
          <g transform="translate(45, 0)">
            <LabelledCell
              title="Scoring Opp."
              cell={homeTeamReport.c('overall.scoring_opportunity')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(160, 0)">
            <LabelledCell
              title="Penalty Strokes"
              cell={homeTeamReport.c('overall.penalty_stroke')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(287, 0)">
            <LabelledCell
              title="Penalty Corners"
              cell={homeTeamReport.c('overall.penalty_corner')}
              valueWidth="20"
              onClick={onClick.setDuration(7, 10)}
            />
          </g>

          <g transform="translate(530, 0)">
            <LabelledCell
              title="Penalty Corners"
              cell={awayTeamReport.c('overall.penalty_corner')}
              valueWidth="20"
              onClick={onClick.setDuration(7, 10)}
            />
          </g>
          <g transform="translate(657, 0)">
            <LabelledCell
              title="Penalty Strokes"
              cell={awayTeamReport.c('overall.penalty_stroke')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
          <g transform="translate(782, 0)">
            <LabelledCell
              title="Scoring Opp."
              cell={awayTeamReport.c('overall.scoring_opportunity')}
              valueWidth="20"
              onClick={onClick}
            />
          </g>
        </g>
        <g transform="translate(10, 170)">
          <g class="twentythreetocircle" transform="translate(0, 0)">
            <Donut
              title="23 Entry → Circle"
              leftReport={homeTeamReport.c(
                'efficiency.23_entry_to_circle_entry'
              )}
              rightReport={awayTeamReport.c(
                'efficiency.23_entry_to_circle_entry'
              )}
              successMetric="circle_entered"
              onClick={onClick}
            />
          </g>
          <g class="twentythreetoopp" transform="translate(200, 0)">
            <Donut
              title="23 Entry → Sc Opp+PC"
              leftReport={homeTeamReport.c(
                'efficiency.23_entry_to_scoring_opportunity_or_penalty_corner_creation'
              )}
              rightReport={awayTeamReport.c(
                'efficiency.23_entry_to_scoring_opportunity_or_penalty_corner_creation'
              )}
              successMetric="scoring_opportunity_or_penalty_corner_created"
              onClick={onClick}
            />
          </g>
          <g class="circletoopp" transform="translate(400, 0)">
            <Donut
              title="Circle → Sc Opp+PC"
              leftReport={homeTeamReport.c(
                'efficiency.circle_entry_to_scoring_opportunity_or_penalty_corner_creation'
              )}
              rightReport={awayTeamReport.c(
                'efficiency.circle_entry_to_scoring_opportunity_or_penalty_corner_creation'
              )}
              successMetric="scoring_opportunity_or_penalty_corner_created"
              onClick={onClick}
            />
          </g>
          <g class="circletogoal" transform="translate(610, 0)">
            <Donut
              title="Circle → Goal"
              leftReport={homeTeamReport.c('efficiency.circle_entry_to_goal')}
              rightReport={awayTeamReport.c('efficiency.circle_entry_to_goal')}
              successMetric="goal_scored"
              onClick={onClick}
            />
          </g>
          <g class="opptogoal" transform="translate(805, 0)">
            <Donut
              title="Sc Opp+PC → Goal"
              leftReport={homeTeamReport.c(
                'efficiency.scoring_opportunity_or_penalty_corner_to_goal'
              )}
              rightReport={awayTeamReport.c(
                'efficiency.scoring_opportunity_or_penalty_corner_to_goal'
              )}
              successMetric="goal_scored"
              onClick={onClick}
            />
          </g>
        </g>
        <g transform="translate(0, 320)">
          <g class="funnelbuildup" transform="translate(10, 0)">
            <HockeyFunnel
              title="Build-Up"
              leftReport={homeTeamReport.c('possession.build_up')}
              rightReport={awayTeamReport.c('possession.build_up')}
              onClick={onClick}
            />
          </g>
          <g class="turnover" transform="translate(330, 0)">
            <HockeyFunnel
              title="Turnover"
              leftReport={homeTeamReport.c('possession.turnover')}
              rightReport={awayTeamReport.c('possession.turnover')}
              onClick={onClick}
            />
          </g>
          <g class="freehit" transform="translate(650, 0)">
            <HockeyFunnel
              title="Free Hit"
              leftReport={homeTeamReport.c('possession.free_hit')}
              rightReport={awayTeamReport.c('possession.free_hit')}
              onClick={onClick}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Dashboard;
