import React, { useCallback, useEffect, useState } from 'react';
import 'survey-react/modern.min.css';
// import 'survey-react/survey.min.css';

import { Survey, StylesManager, Model } from 'survey-react';

import Lottie from 'react-lottie';
import starLottie from './3152-star-success.json';

import './index.scss';
import { withTranslation } from 'react-i18next';

// StylesManager.applyTheme('default');

const _Survey = withTranslation('module.survey')(
  ({ t, data, options, onSubmit, onCloseCallback }) => {
    const [surveyComponent, setSurveyComponent] = useState(null);
    useEffect(() => {
      const survey = new Model(data);
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: starLottie,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
        eventListeners: [
          {
            eventName: 'loopComplete',
            callback: onCloseCallback,
          },
        ],
      };

      survey.onComplete.add(sender => {
        onSubmit(sender.data);

        setSurveyComponent(
          <div>
            <Lottie options={defaultOptions} />
            <h2
              style={{
                zIndex: 1001,
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
                bottom: '30px',
              }}
            >
              {t('thankYouDescription')}
            </h2>
          </div>
        );
      });
      setSurveyComponent(<Survey model={survey} />);
    }, [data]);

    return surveyComponent;
  }
);

export default _Survey;
