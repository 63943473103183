import { InputCheckboxRadio } from 'lib/InputCheckBoxRadio';
import { useEffect, useState } from 'react';
import { Tooltip } from 'lib/Tooltip';
import { useTranslation } from 'react-i18next';
import { Session } from 'domain/Session';
import { useQueryClient, useQuery } from 'react-query';
import Loading from 'lib/Loading';
import Skeleton from 'react-loading-skeleton';
import { Notification } from 'lib';
import { SubmittingButton } from 'lib/SubmittingButton';

export const SetupNotifications = () => {
  const { t } = useTranslation('module.settings.profile');
  // Channels and categories.
  // todo: deduct them from https://notifications.teamtvsport.com/redoc#operation/get_user_preferences_api_userPreferences_get
  const notificationCategories = ['content', 'product', 'marketing'];
  const notificationChannels = ['mail']; // mail || inapp || push

  const queryClient = useQueryClient();
  const currentSession = Session.current();
  const [preferences, setPreferences] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (channel, category) => {
    let val = !preferences[`${channel}_preferences`][category];
    preferences[`${channel}_preferences`][category] = val;
    setPreferences((currentValue) => {
      return {
        ...preferences,
      };
    });
  };
  const fetchNotificationPreferences = async () => {
    const currentSession = Session.current();
    let notificationSettings =
      await currentSession.getNotificationPreferences();
    return notificationSettings.data;
  };

  const savePreferences = async () => {
    setIsSubmitting(true);
    await currentSession.setNotificationPreferences(preferences);
    queryClient.invalidateQueries(['notificationPreferences']);
    Notification.show(t('notifications.submitted'), 2500);
    setIsSubmitting(false);
  };

  const {
    isLoading,
    isFetched,
    data: _preferences,
  } = useQuery(['notificationPreferences'], fetchNotificationPreferences);

  useEffect(() => {
    setPreferences(_preferences);
  }, [isFetched, _preferences]);

  if (isLoading || !isFetched || !preferences) {
    return <Skeleton style={{ height: '250px' }} />;
  }

  // Layout On MD each channel is 2 columns, on XL it is 1. SM is equally divided per channel on new row.
  const colsMd = 12 - notificationChannels.length * 2; // Divide columns based on amount of settings available.
  const colsXl = 12 - notificationChannels.length; // Divide columns based on amount of settings available.

  return (
    <div id={'notifications'} className={'setup-notifications'}>
      <h3>{t('notifications.title')}</h3>

      <div
        className={`setup-notifications__container ${
          isSubmitting ? 'isLoading' : ''
        }`}
      >
        {notificationCategories.map((category) => {
          return (
            <div key={category} className="setup-notifications__row">
              <div
                className={`setup-notifications__setting col-md-${colsMd} col-xl-${colsXl} col-12`}
              >
                <label className={'setup-notifications__label'}>
                  {t(`notifications.categoryLabel.${category}`)}
                </label>
                <p> {t(`notifications.categoryDescription.${category}`)}</p>
              </div>

              {notificationChannels.map((channel) => {
                let haskey = `${channel}_preferences` in preferences;

                return (
                  <div
                    key={`${category}__${channel}`}
                    className={`setup-notifications__setting d-flex align-items-center align-content-center justify-content-center col-xl-1 col-md-2 col-${Math.floor(
                      12 / notificationChannels.length
                    )}`}
                  >
                    <InputCheckboxRadio
                      type={'checkbox'}
                      label={
                        <Tooltip
                          tooltip={t(`notifications.channels.${channel}`)}
                        >
                          <i className={`i-${channel} i-light i-md`}></i>
                        </Tooltip>
                      }
                      checked={
                        haskey &&
                        preferences[`${channel}_preferences`][category]
                      }
                      disabled={channel === 'inapp'}
                      onChange={() => {
                        handleChange(channel, category);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="form-actions">
        <SubmittingButton
          className={'btn btn-primary'}
          onClick={savePreferences}
        >
          {t('notifications.save')}
        </SubmittingButton>
      </div>
    </div>
  );
};
