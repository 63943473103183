import React, { FC } from 'react';

import {
  closeEditor,
  openEditor,
  updateCellValue,
  updateEditorValue,
  updateFilterRowValue,
} from 'ka-table/actionCreators';
import 'ka-table/style.scss';
import { ICellEditorProps, ICellTextProps } from 'ka-table/props';
import Select from 'react-select';
import { DropdownOption } from '../pdp-table-options';

interface ICellTextWithOptions extends ICellTextProps {
  options: DropdownOption[];
  defaultValue?: any;
}

export const DropdownColumnCell: FC<ICellTextWithOptions> = ({
  column,
  dispatch,
  rowKeyValue,
  defaultValue,
  value,
  options,
}) => {
  const label =
    options.find(o => o.id === value)?.label ||
    options.find(o => o.id === defaultValue)?.label;

  return (
    <div
      onClick={() => {
        dispatch(openEditor(rowKeyValue, column.key));
      }}
    >
      {label || '—'}
    </div>
  );
};

interface ICellEditorPropsWithOptions extends ICellEditorProps {
  options: DropdownOption[];
  defaultValue?: any;
}

export const DropdownColumnEditor: FC<ICellEditorPropsWithOptions> = ({
  column,
  dispatch,
  rowKeyValue,
  defaultValue,
  value,
  options,
}) => {
  return (
    <div>
      <Select
        autoFocus={typeof rowKeyValue === 'string'}
        defaultValue={
          options.find(option => option.value === value) ||
          options.find(option => option.value === defaultValue)
        }
        onBlur={() => {
          if (typeof rowKeyValue === 'string') {
            dispatch(closeEditor(rowKeyValue, column.key));
          }
        }}
        onChange={({ value }) => {
          if (typeof rowKeyValue === 'string') {
            // update existing
            dispatch(updateCellValue(rowKeyValue, column.key, value));
            dispatch(closeEditor(rowKeyValue, column.key));
          } else {
            // update new row
            dispatch(updateEditorValue(rowKeyValue, column.key, value));
          }
        }}
        options={options}
      />
    </div>
  );
};
