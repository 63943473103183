import React from 'react';

import './imagepicker.scss';
import { CachedAsyncImage } from '../../modules/highlights/utils/clipThumbnail';

interface ImagePickerProps {
  images: {
    [key: string]: {
      imageSrc: string;
      selected: boolean;
      label: string;
      cachekey?: string;
    };
  }[];
  callback: (updatedImages: { imageSrc: string; selected: boolean }[]) => void;
  selectMultiple: boolean;
  aspectRatio?: string;
  imageStyle: CSSStyleRule;
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  images,
  callback,
  selectMultiple = false,
  aspectRatio,
  imageStyle,
}) => {
  const onClick = (index: number) => {
    let updatedImages = [...images];
    if (selectMultiple) {
      updatedImages[index].selected = !updatedImages[index].selected;
      callback(updatedImages);
    } else {
      updatedImages = updatedImages.map((x) => {
        x.selected = false;
        return x;
      });
      updatedImages[index].selected = true;
      callback(updatedImages);
    }
  };
  const _aspectRatio = aspectRatio;
  return (
    <div className={'imagepicker'}>
      <div className="imagepicker__container">
        {images.map((image, index) => (
          <div
            className={`imagepicker__image ${
              image.selected ? 'is-selected' : ''
            }`}
            key={`imageselector-${index}`}
            onClick={() => onClick(index)}
            style={_aspectRatio ? { aspectRatio: _aspectRatio } : {}}
          >
            <Image
              imageStyle={imageStyle}
              image={image.imageSrc}
              cacheKey={image?.cacheKey}
            />
            {image?.label && (
              <span className={'imagepicker__caption'}>{image.label}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const Image = ({ imageStyle, image, cacheKey }) => {
  if (typeof image == 'string') return <img style={imageStyle} src={image} />;
  if (typeof image == 'function')
    return <CachedAsyncImage promise={image} cacheKey={cacheKey} />;
};

export default ImagePicker;
