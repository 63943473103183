import { Model } from 'mobx-rest';
import { PersistentCollection } from '../PersistentCollection';

import personPlaceHolder from 'img/icons/profile-picture-placeholder.svg';

class Person extends Model {
  get primaryKey() {
    return 'personId';
  }

  get code() {
    const lastName = this.has('lastName') ? this.get('lastName') : '';
    return (
      this.get('firstName')
        .substring(0, 1)
        .toUpperCase() + lastName.substring(0, 1).toUpperCase()
    );
  }

  get firstName() {
    return this.get('firstName');
  }

  get lastName() {
    return this.has('lastName') ? this.get('lastName') : '';
  }

  get label() {
    return this.firstName;
  }

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  get gender() {
    return this.has('gender') ? this.get('gender') : null;
  }

  get profilePictureUrl() {
    return this.has('profilePictureUrl') ? this.get('profilePictureUrl') : null;
  }
}

class PersonCollection extends PersistentCollection {
  url() {
    return '/persons';
  }

  model() {
    return Person;
  }

  loading() {
    return this.isRequest('fetching');
  }

  addPerson(personId, firstName, lastName, gender) {
    this.add([
      {
        personId,
        firstName,
        lastName,
        gender,
      },
    ]);
  }

  getOrEmpty(personId) {
    let person = this.get(personId);
    if (!person) {
      person = this.build({
        personId,
        firstName: 'Onbekend',
        lastName: 'Onbekend',
      });
    }
    return person;
  }
}

const personCollection = new PersonCollection();
window.personCollection = personCollection;

export default personCollection;
