// import { Session } from 'domain/Session';
// import { IntercomAPI } from 'react-intercom';

class EventPublisher {
  static dispatch(eventName, ...args) {
    // console.warn('TODO: add event tracking'); // TODO: @koenvo
    // const session = Session.current();
    // const currentUser = session.user;
    // if (!currentUser.isReady) {
    //   return null;
    // }
    // const intercomSettings = currentUser.settings.intercom;
    // if (!intercomSettings.enabled) {
    //   return false;
    // }
    // let description = null;
    // switch (eventName) {
    //   case 'LOGGED_IN':
    //     description = 'Logged in';
    //     break;
    //   case 'ADDED_MATCH':
    //     description = 'Added match';
    //     break;
    //   case 'UPLOAD_STARTED':
    //     description = 'Started video upload';
    //     break;
    //   case 'UPLOAD_COMPLETED':
    //     description = 'Completed video upload';
    //     break;
    //   case 'UPLOAD_ABORTED':
    //     description = 'Aborted video upload';
    //     break;
    //   case 'INVITED_MEMBERS':
    //     const [memberCount] = args;
    //     if (memberCount === 1) {
    //       description = 'Invited single team member';
    //     } else {
    //       description = 'Invited multiple team members';
    //     }
    //     break;
    //   case 'LIVE_DATA_SYNCED':
    //     description = 'Live data synced';
    //     break;
    //   case 'UPCOMING_FEATURE_TEST__TRIGGERED':
    //     description = `Upcoming feature test triggered: ${args[0]}`;
    //     break;
    //   case 'UPCOMING_FEATURE_TEST__CTA_CLICKED':
    //     description = `Upcoming feature cta clicked: ${args[0]}`;
    //     break;
    //   case 'UPCOMING_FEATURE_TEST__KEEP_ME_INFORMED':
    //     description = `Upcoming feature keep me informed: ${args[0]}`;
    //     break;
    //   case 'VIDEO_OBSERVATION_STARTED':
    //     const [observationCount] = args;
    //     switch (observationCount) {
    //       case 0:
    //       case 1:
    //         description = 'Tagged video 1';
    //         break;
    //       case 10:
    //         description = 'Tagged video 10';
    //         break;
    //       case 30:
    //         description = 'Tagged video 30';
    //         break;
    //       default:
    //         description = null;
    //         break;
    //     }
    //     break;
    //   case 'VIDEO_OBSERVATION_ATTRIBUTES_CHANGED':
    //     const [attributes] = args;
    //     if (!!attributes.personId || !!attributes.participantsPersonIds) {
    //       description = 'Person attribute set';
    //     }
    //     break;
    //   case 'DASHBOARD_VIEWED':
    //     description = 'Viewed dashboard';
    //     break;
    //   case 'DASHBOARD_VIDEO_VIEWED':
    //     description = 'Viewed video on dashboard';
    //     break;
    //   case 'INVITATION_ACCEPTED':
    //     description = 'Accepted invitation';
    //     break;
    //   case 'INVITATION_DECLINED':
    //     break;
    //   case 'VIDEO_PLAYED':
    //     description = 'Played video';
    //     break;
    //   case 'XML_IMPORTED':
    //     description = 'Imported XML';
    //     break;
    //   case 'APP_LAUNCHED':
    //     description = `Launched app ${args[0]}`;
    //     break;
    // }
    // if (description !== null) {
    //   IntercomAPI('trackEvent', description);
    // }
  }
}

export { EventPublisher };
