import React, { Component } from 'react';

export const withToggle = (
  WrappedComponent,
  initialState = { isToggled: false }
) => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.toggle = this.toggle.bind(this);
      this.state = initialState;
    }

    toggle() {
      this.setState(({ isToggled }) => ({
        isToggled: !isToggled,
      }));
    }

    render() {
      return (
        <WrappedComponent
          isToggled={this.state.isToggled}
          toggle={this.toggle}
          {...this.props}
        />
      );
    }
  };
};
