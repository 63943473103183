import { Aside } from 'lib/Aside';
import { useState } from 'react';
export const AsideTest = () => {
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [isClosedCallback, setIsClosedCallback] = useState(false);
  return (
    <div>
      <button id={'e2e-aside-button'} onClick={() => setOpen(true)}>
        Click
      </button>

      <button id={'e2e-aside-button-2'} onClick={() => setOpen2(true)}>
        Click
      </button>
      {isOpen && (
        <Aside>
          <h2>Aside content</h2>
        </Aside>
      )}
      {isOpen2 && (
        <Aside
          onClose={() => {
            setIsClosedCallback(true);
            setOpen2(false);
          }}
          transitionDuration={450}
        >
          <h2>Aside content</h2>
        </Aside>
      )}
      {isClosedCallback && <div id={'e2e-aside-closed'}>Closed</div>}
    </div>
  );
};
