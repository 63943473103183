import React, { Component, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { useContext } from 'react';

//import PersonCollection from 'domain/Person';

function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

const REAL_FIELD_WIDTH = 20;
const REAL_FIELD_HEIGHT = 20;

const pol2cart = ({
  distance,
  angle,
  centerX,
  centerY,
  imageWidth,
  imageHeight,
}) => {
  if (angle > 90) {
    angle -= 360;
  }
  angle += 90;

  const phi = (angle / 360) * 2 * Math.PI;
  const rho = distance;

  const x = ((rho * Math.cos(phi)) / REAL_FIELD_WIDTH) * imageWidth + centerX;
  const y = ((rho * Math.sin(phi)) / REAL_FIELD_HEIGHT) * imageHeight + centerY;
  return { x, y };
};

const HorizontalStatistic = ({
  metricDescription,
  homeCell,
  awayCell,
  offsetX,
  offsetY,
  onClick,
  fontRemarkable = 'true',
  successMetric = 'won',
}) => {
  const {
    total: homeTotal = 0,
    [successMetric]: homeSuccess = 0,
  } = homeCell.metrics;
  const {
    total: awayTotal = 0,
    [successMetric]: awaySuccess = 0,
  } = awayCell.metrics;

  const barMaxWidth = 100;

  const max = Math.max(homeTotal, awayTotal);
  let unit = 0;
  if (max > 0) {
    unit = barMaxWidth / max;
  }

  let metricHomePercentage = 0;
  let metricAwayPercentage = 0;
  if (homeTotal > 0) {
    metricHomePercentage = Math.round((homeSuccess / homeTotal) * 100);
  }
  if (awayTotal > 0) {
    metricAwayPercentage = Math.round((awaySuccess / awayTotal) * 100);
  }
  const spacing = 5;

  const metricAwayOffset =
    40 - spacing - getTextWidth(metricAwayPercentage, '400 5px Lato');
  const metricHomeOffset =
    -30 - spacing - getTextWidth(metricHomePercentage, '400 5px Lato');

  const barAwayX1 = 55;
  const barHomeX1 = -50;
  const barAwaySuccessX2 = barAwayX1 + awaySuccess * unit;
  const barAwayTotalX2 = barAwayX1 + awayTotal * unit;
  const barHomeSuccessX2 = barHomeX1 - homeSuccess * unit;
  const barHomeTotalX2 = barHomeX1 - homeTotal * unit;

  let fontFill = 'var(--secondary-color)';
  if (fontRemarkable === 'false') {
    fontFill = '#DAE0E3';
  }

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <text>
        <tspan
          x="4.5"
          y="0"
          fill={fontFill}
          textAnchor="middle"
          fontFamily="'Lato', sans-serif"
          fontSize="5"
        >
          {metricDescription}
        </tspan>
        <tspan
          x={metricHomeOffset}
          y="0"
          fill="var(--secondary-color)"
          fontFamily="'Lato', sans-serif"
          fontSize="6.8598"
          onClick={() => onClick(homeCell)}
        >
          {metricHomePercentage}
        </tspan>
        <tspan
          x="-28"
          y="0"
          fill="var(--secondary-color)"
          fontFamily="'Lato', sans-serif"
          fontSize="4.8598"
        >
          %
        </tspan>
        <tspan
          x={metricAwayOffset}
          y="0"
          fill="var(--secondary-color)"
          fontFamily="'Lato', sans-serif"
          fontSize="6.8598"
          onClick={() => onClick(awayCell)}
        >
          {metricAwayPercentage}
        </tspan>
        <tspan
          x="42"
          y="0"
          fill="var(--secondary-color)"
          fontFamily="'Lato', sans-serif"
          fontSize="4.8598"
        >
          %
        </tspan>
      </text>

      <line
        id="barAwaySuccess"
        fill="none"
        stroke="var(--secondary-color)"
        strokeWidth="3"
        strokeMiterlimit="10"
        x1={barAwayX1}
        y1="-4"
        x2={barAwaySuccessX2}
        y2="-4"
      />
      <line
        id="barAwayTotal"
        fill="none"
        stroke="#DAE0E3"
        strokeWidth="3"
        strokeMiterlimit="10"
        x1={barAwayX1}
        y1="0"
        x2={barAwayTotalX2}
        y2="0"
      />
      <text
        transform={`matrix(1 0 0 1 ${barAwaySuccessX2 + 3} -3)`}
        fill="var(--secondary-color)"
        fontFamily="'Lato', sans-serif"
        fontSize="4"
      >
        {awaySuccess}
      </text>
      <text
        transform={`matrix(1 0 0 1 ${barAwayTotalX2 + 3} 1)`}
        fill="#DAE0E3"
        fontFamily="'Lato', sans-serif"
        fontSize="4"
      >
        {awayTotal}
      </text>

      <line
        id="barHomeSuccess"
        fill="none"
        stroke="var(--secondary-color)"
        strokeWidth="3"
        strokeMiterlimit="10"
        x1={barHomeX1}
        y1="-4"
        x2={barHomeSuccessX2}
        y2="-4"
      />
      <line
        id="barHomeTotal"
        fill="none"
        stroke="#DAE0E3"
        strokeWidth="3"
        strokeMiterlimit="10"
        x1={barHomeX1}
        y1="0"
        x2={barHomeTotalX2}
        y2="0"
      />
      <text
        transform={`matrix(1 0 0 1 ${barHomeSuccessX2 - 8} -3)`}
        fill="var(--secondary-color)"
        fontFamily="'Lato', sans-serif"
        fontSize="4"
      >
        {homeSuccess}
      </text>
      <text
        transform={`matrix(1 0 0 1 ${barHomeTotalX2 - 8} 1)`}
        fill="#DAE0E3"
        fontFamily="'Lato', sans-serif"
        fontSize="4"
      >
        {homeTotal}
      </text>
    </g>
  );
};

const CircleGraph = ({ metricDescription, metricTotal, offsetX, offsetY }) => {
  const startX = (-1 * getTextWidth(metricTotal, '400 11.6095px Lato')) / 2;
  const startDescriptionX =
    (-1 * getTextWidth(metricDescription, '400 5px Lato')) / 2;

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <circle
        id={metricDescription}
        fill="var(--secondary-color)"
        cx="0"
        cy="0"
        r="18.731"
      />
      <text
        transform={`matrix(1 0 0 1 ${startDescriptionX} 26)`}
        fill="var(--secondary-color)"
        fontFamily="'Lato', sans-serif"
        fontSize="5"
      >
        {metricDescription}
      </text>
      <text
        transform={`matrix(1 0 0 1 ${startX} 4)`}
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="11.6095"
      >
        {metricTotal}
      </text>
    </g>
  );
};

const CirclePieGraph = ({
  metricDescription,
  metricTotal,
  offsetX,
  offsetY,
}) => {
  const ratio = metricTotal / 100;

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <g className="clickable">
        <g strokeWidth="3">
          <path
            d="M22,44 C34.1502645,44 44,34.1502645 44,22 C44,9.8497355 34.1502645,0 22,0 C9.8497355,0 0,9.8497355 0,22 C0,34.1502645 9.8497355,44 22,44 Z"
            stroke="#DAE0E3"
            strokeDasharray="289,217"
            fill="none"
            transform="translate(22,22) scale(0.8) translate(-22,-22)"
          />
          <path
            d="M22,44 C34.1502645,44 44,34.1502645 44,22 C44,9.8497355 34.1502645,0 22,0 C9.8497355,0 0,9.8497355 0,22 C0,34.1502645 9.8497355,44 22,44 Z"
            stroke="var(--secondary-color)"
            strokeDasharray={(ratio * 138).toFixed(0) + ', 444'}
            transform="translate(22, 22) scale(1,-1) scale(0.8) translate(-22, -22)"
            fill="none"
          />
        </g>
        <text
          fontFamily="'Lato', sans-serif"
          fontSize="5"
          fill="var(--secondary-color)"
          textAnchor="middle"
        >
          <tspan x="23" y="48">
            {metricDescription}
          </tspan>
        </text>
        <text
          fontFamily="'Lato', sans-serif"
          fontSize="11"
          fontWeight="400"
          fill="var(--secondary-color)"
          textAnchor="middle"
        >
          <tspan x="22.5" y="26">
            {(ratio * 100).toFixed(0)}%
          </tspan>
        </text>
      </g>
    </g>
  );
};

const MatchBlock = ({ report, offsetX, offsetY, onClick }) => {
  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <rect
        id="matchHeader"
        x="0"
        y="0"
        opacity="0.5"
        fill="var(--secondary-color)"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        width="352.807"
        height="23.618"
      />
      <text
        transform="matrix(1 0 0 1 160 14.8)"
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="10.8399"
      >
        MATCH
      </text>
      <line
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        x1="0"
        y1="82"
        x2="353"
        y2="82"
      />
      <polygon
        id="matchRect"
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        points="352.807, 160 352.807,0  0,0  0,160 "
      />

      <CircleGraph
        metricDescription="Sets"
        metricTotal={report.getCell('match.HomeParty.sets').metrics.total}
        offsetX="111"
        offsetY="48"
      />
      <CircleGraph
        metricDescription="Games"
        metricTotal={report.getCell('match.HomeParty.games').metrics.total}
        offsetX="155"
        offsetY="48"
      />
      <CircleGraph
        metricDescription="Points"
        metricTotal={report.getCell('match.HomeParty.points').metrics.total}
        offsetX="198"
        offsetY="48"
      />
      <CircleGraph
        metricDescription="Strokes"
        metricTotal={
          report.getCell('match.HomeParty.strokes').metrics.total +
          report.getCell('match.AwayParty.strokes').metrics.total
        }
        offsetX="241"
        offsetY="48"
      />

      <g>
        <HorizontalStatistic
          metricDescription="Sets"
          homeCell={report.getCell('match.HomeParty.sets')}
          awayCell={report.getCell('match.AwayParty.sets')}
          offsetX="168"
          offsetY="98.5"
          onClick={onClick}
        />
      </g>

      <g>
        <HorizontalStatistic
          metricDescription="Games"
          homeCell={report.getCell('match.HomeParty.games')}
          awayCell={report.getCell('match.AwayParty.games')}
          offsetX="168"
          offsetY="111.1"
          onClick={onClick}
        />
      </g>

      <g>
        <HorizontalStatistic
          metricDescription="Points"
          homeCell={report.getCell('match.HomeParty.points')}
          awayCell={report.getCell('match.AwayParty.points')}
          offsetX="168"
          offsetY="123"
          onClick={onClick}
        />
      </g>

      <g>
        <HorizontalStatistic
          metricDescription="Strokes"
          homeCell={report.getCell('match.HomeParty.strokes')}
          awayCell={report.getCell('match.AwayParty.strokes')}
          offsetX="168"
          offsetY="135"
          successMetric="in"
          onClick={onClick}
        />
      </g>
    </g>
  );
};

const CriticalPointsBlock = ({ report, offsetX, offsetY, onClick }) => {
  const {
    won: homeCriticalPointsWon,
    total: homeCriticalPointsTotal,
  } = report.getCell('criticalPoints.HomeParty').metrics;
  const homeCriticalPointsWonPercentage =
    homeCriticalPointsTotal > 0
      ? Math.round((homeCriticalPointsWon * 100) / homeCriticalPointsTotal)
      : 0;

  const {
    won: awayCriticalPointsWon,
    total: awayCriticalPointsTotal,
  } = report.getCell('criticalPoints.AwayParty').metrics;

  const awayCriticalPointsWonPercentage =
    awayCriticalPointsTotal > 0
      ? Math.round((awayCriticalPointsWon * 100) / awayCriticalPointsTotal)
      : 0;
  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <polygon
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        points="353,148  353,0   0,0 0,148 "
      />
      <rect
        id="criticalPointsHeader"
        x="0"
        y="0"
        opacity="0.5"
        fill="var(--secondary-color)"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        width="352.807"
        height="23.618"
      />

      <CircleGraph
        metricDescription="Total"
        metricTotal={report.getCell(`criticalPoints`).metrics.total}
        offsetX="176"
        offsetY="48"
      />

      <CirclePieGraph
        metricDescription="Won"
        metricTotal={homeCriticalPointsWonPercentage}
        offsetX="102"
        offsetY="26"
      />
      <CirclePieGraph
        metricDescription="Won"
        metricTotal={awayCriticalPointsWonPercentage}
        offsetX="207"
        offsetY="26"
      />

      <line
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        x1="0"
        y1="82"
        x2="353"
        y2="82"
      />
      <text
        transform="matrix(1 0 0 1 137 15.6)"
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="10.8399"
      >
        CRITICAL POINTS
      </text>

      <g className={'clickable'}>
        <HorizontalStatistic
          metricDescription="GAME points"
          homeCell={report.getCell('criticalPoints.HomeParty.Game')}
          awayCell={report.getCell('criticalPoints.AwayParty.Game')}
          offsetX="168"
          offsetY="98.5"
          onClick={onClick}
        />
      </g>
      <g className={'clickable'}>
        <HorizontalStatistic
          metricDescription="BREAK points"
          homeCell={report.getCell('criticalPoints.HomeParty.Break')}
          awayCell={report.getCell('criticalPoints.AwayParty.Break')}
          offsetX="168"
          offsetY="111.1"
          onClick={onClick}
        />
      </g>
      <g className={'clickable'}>
        <HorizontalStatistic
          metricDescription="SET points"
          homeCell={report.getCell('criticalPoints.HomeParty.Set')}
          awayCell={report.getCell('criticalPoints.AwayParty.Set')}
          offsetX="168"
          offsetY="123.7"
          onClick={onClick}
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="MATCH points"
          homeCell={report.getCell('criticalPoints.HomeParty.Match')}
          awayCell={report.getCell('criticalPoints.AwayParty.Match')}
          offsetX="168"
          offsetY="136.3"
          onClick={onClick}
        />
      </g>
    </g>
  );
};

const RallyLengthBlock = ({ report, offsetX, offsetY, onClick }) => {
  const totalRallies =
    report.getCell('rallyLength.1-4').metrics.total +
    report.getCell('rallyLength.5-8').metrics.total +
    report.getCell('rallyLength.9+').metrics.total;

  const totalL4Percentage =
    totalRallies > 0
      ? Math.round(
          (report.getCell('rallyLength.1-4').metrics.total * 100) / totalRallies
        )
      : 0;
  const totalL8Percentage =
    totalRallies > 0
      ? Math.round(
          (report.getCell('rallyLength.5-8').metrics.total * 100) / totalRallies
        )
      : 0;
  const totalLG8ercentage =
    totalRallies > 0
      ? Math.round(
          (report.getCell('rallyLength.9+').metrics.total * 100) / totalRallies
        )
      : 0;

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <rect
        x="0"
        y="0"
        opacity="0.5"
        fill="var(--secondary-color)"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        width="352.807"
        height="23.618"
      />
      <polygon
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        points="353,148 353,0 0,0  0,148 "
      />
      <line
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        x1="0"
        y1="82"
        x2="353"
        y2="82"
      />
      <text
        transform="matrix(1 0 0 1 137 15)"
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="10.8399"
      >
        RALLY LENGTH
      </text>

      <CircleGraph
        metricDescription="Total"
        metricTotal={totalRallies}
        offsetX="111"
        offsetY="48"
      />

      <CirclePieGraph
        metricDescription="1-4 strokes"
        metricTotal={totalL4Percentage}
        offsetX="135"
        offsetY="26"
      />
      <CirclePieGraph
        metricDescription="5-8 strokes"
        metricTotal={totalL8Percentage}
        offsetX="178"
        offsetY="26"
      />
      <CirclePieGraph
        metricDescription="9+ strokes"
        metricTotal={totalLG8ercentage}
        offsetX="221"
        offsetY="26"
      />

      <g>
        <HorizontalStatistic
          metricDescription="Total"
          homeCell={report.getCell('rallyLength.HomeParty')}
          awayCell={report.getCell('rallyLength.AwayParty')}
          offsetX="168"
          offsetY="98"
          onClick={onClick}
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="1 - 4 strokes"
          homeCell={report.getCell('rallyLength.HomeParty.1-4')}
          awayCell={report.getCell('rallyLength.AwayParty.1-4')}
          offsetX="168"
          offsetY="111"
          onClick={onClick}
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="5 - 8 strokes"
          homeCell={report.getCell('rallyLength.HomeParty.5-8')}
          awayCell={report.getCell('rallyLength.AwayParty.5-8')}
          offsetX="168"
          offsetY="123"
          onClick={onClick}
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="9+ strokes"
          homeCell={report.getCell('rallyLength.HomeParty.9+')}
          awayCell={report.getCell('rallyLength.AwayParty.9+')}
          offsetX="168"
          offsetY="136"
          onClick={onClick}
        />
      </g>
    </g>
  );
};

const StrokeBlock = ({ report, offsetX, offsetY }) => {
  // alle strokes thuis
  const homeStrokes =
    report.getCell('strokes.HomeParty.SERVICE').metrics.total +
    report.getCell('strokes.HomeParty.RETURN').metrics.total +
    report.getCell('strokes.HomeParty.BASELINE').metrics.total +
    report.getCell('strokes.HomeParty.APPROACH').metrics.total +
    report.getCell('strokes.HomeParty.NETPLAY').metrics.total +
    report.getCell('strokes.HomeParty.AGAINSTNETPLAY').metrics.total;

  const homeStrokesWon =
    report.getCell('strokes.HomeParty.SERVICE').metrics.won +
    report.getCell('strokes.HomeParty.RETURN').metrics.won +
    report.getCell('strokes.HomeParty.BASELINE').metrics.won +
    report.getCell('strokes.HomeParty.APPROACH').metrics.won +
    report.getCell('strokes.HomeParty.NETPLAY').metrics.won +
    report.getCell('strokes.HomeParty.AGAINSTNETPLAY').metrics.won;

  const homeGood =
    homeStrokes > 0 ? Math.round((homeStrokesWon * 100) / homeStrokes) : 0;

  const awayStrokes =
    report.getCell('strokes.AwayParty.SERVICE').metrics.total +
    report.getCell('strokes.AwayParty.RETURN').metrics.total +
    report.getCell('strokes.AwayParty.BASELINE').metrics.total +
    report.getCell('strokes.AwayParty.APPROACH').metrics.total +
    report.getCell('strokes.AwayParty.NETPLAY').metrics.total +
    report.getCell('strokes.AwayParty.AGAINSTNETPLAY').metrics.total;

  const awayStrokesWon =
    report.getCell('strokes.AwayParty.SERVICE').metrics.won +
    report.getCell('strokes.AwayParty.RETURN').metrics.won +
    report.getCell('strokes.AwayParty.BASELINE').metrics.won +
    report.getCell('strokes.AwayParty.APPROACH').metrics.won +
    report.getCell('strokes.AwayParty.NETPLAY').metrics.won +
    report.getCell('strokes.AwayParty.AGAINSTNETPLAY').metrics.won;

  const awayGood =
    homeStrokes > 0 ? Math.round((awayStrokesWon * 100) / awayStrokes) : 0;

  const strokesTotal = homeStrokes + awayStrokes;

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <rect
        x="0"
        y="0"
        opacity="0.5"
        fill="var(--secondary-color)"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        width="352.807"
        height="23.618"
      />
      <text
        transform="matrix(1 0 0 1 160 15.6)"
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="10.8399"
      >
        STROKES
      </text>{' '}
      0
      <polygon
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        points="353,344.5 353,0 0,0 0,344.5 "
      />
      <CircleGraph
        metricDescription="Total"
        metricTotal={strokesTotal}
        offsetX="176"
        offsetY="48"
      />
      <CirclePieGraph
        metricDescription="Good"
        metricTotal={homeGood}
        offsetX="102"
        offsetY="26"
      />
      <CirclePieGraph
        metricDescription="Good"
        metricTotal={awayGood}
        offsetX="207"
        offsetY="26"
      />
      <g>
        <HorizontalStatistic
          metricDescription="Service"
          homeCell={report.getCell('strokes.HomeParty.SERVICE')}
          awayCell={report.getCell('strokes.AwayParty.SERVICE')}
          offsetX="178"
          offsetY="96"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="1st service"
          homeCell={report.getCell('strokes.HomeParty.SERVICE.FIRST')}
          awayCell={report.getCell('strokes.AwayParty.SERVICE.FIRST')}
          offsetX="178"
          offsetY="109"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="2nd service"
          homeCell={report.getCell('strokes.HomeParty.SERVICE.SECOND')}
          awayCell={report.getCell('strokes.AwayParty.SERVICE.SECOND')}
          offsetX="178"
          offsetY="122"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Return"
          homeCell={report.getCell('strokes.HomeParty.RETURN')}
          awayCell={report.getCell('strokes.AwayParty.RETURN')}
          offsetX="178"
          offsetY="135"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Forehand"
          homeCell={report.getCell('strokes.HomeParty.RETURN.FOREHAND')}
          awayCell={report.getCell('strokes.AwayParty.RETURN.FOREHAND')}
          offsetX="178"
          offsetY="148"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Backhand"
          homeCell={report.getCell('strokes.HomeParty.RETURN.BACKHAND')}
          awayCell={report.getCell('strokes.AwayParty.RETURN.BACKHAND')}
          offsetX="178"
          offsetY="161"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Baseline"
          homeCell={report.getCell('strokes.HomeParty.BASELINE')}
          awayCell={report.getCell('strokes.AwayParty.BASELINE')}
          offsetX="178"
          offsetY="174"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Forehand"
          homeCell={report.getCell('strokes.HomeParty.BASELINE.FOREHAND')}
          awayCell={report.getCell('strokes.AwayParty.BASELINE.FOREHAND')}
          offsetX="178"
          offsetY="187"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Backhand"
          homeCell={report.getCell('strokes.HomeParty.BASELINE.BACKHAND')}
          awayCell={report.getCell('strokes.AwayParty.BASELINE.BACKHAND')}
          offsetX="178"
          offsetY="200"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Midcourt"
          homeCell={report.getCell('strokes.HomeParty.APPROACH')}
          awayCell={report.getCell('strokes.AwayParty.APPROACH')}
          offsetX="178"
          offsetY="213"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Forehand"
          homeCell={report.getCell('strokes.HomeParty.APPROACH.FOREHAND')}
          awayCell={report.getCell('strokes.AwayParty.APPROACH.FOREHAND')}
          offsetX="178"
          offsetY="226"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Backhand"
          homeCell={report.getCell('strokes.HomeParty.APPROACH.BACKHAND')}
          awayCell={report.getCell('strokes.AwayParty.APPROACH.BACKHAND')}
          offsetX="178"
          offsetY="239"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Netplay"
          homeCell={report.getCell('strokes.HomeParty.NETPLAY')}
          awayCell={report.getCell('strokes.AwayParty.NETPLAY')}
          offsetX="178"
          offsetY="253"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Forehand"
          homeCell={report.getCell('strokes.HomeParty.NETPLAY.FOREHAND')}
          awayCell={report.getCell('strokes.AwayParty.NETPLAY.FOREHAND')}
          offsetX="178"
          offsetY="266"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Backhand"
          homeCell={report.getCell('strokes.HomeParty.NETPLAY.BACKHAND')}
          awayCell={report.getCell('strokes.AwayParty.NETPLAY.BACKHAND')}
          offsetX="178"
          offsetY="279"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Against netplayer"
          homeCell={report.getCell('strokes.HomeParty.AGAINSTNETPLAY')}
          awayCell={report.getCell('strokes.AwayParty.AGAINSTNETPLAY')}
          offsetX="178"
          offsetY="292"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Forehand"
          homeCell={report.getCell('strokes.HomeParty.AGAINSTNETPLAY.FOREHAND')}
          awayCell={report.getCell('strokes.AwayParty.AGAINSTNETPLAY.FOREHAND')}
          offsetX="178"
          offsetY="305"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Backhand"
          homeCell={report.getCell('strokes.HomeParty.AGAINSTNETPLAY.BACKHAND')}
          awayCell={report.getCell('strokes.AwayParty.AGAINSTNETPLAY.BACKHAND')}
          offsetX="178"
          offsetY="318"
          fontRemarkable="false"
        />
      </g>
      <g>
        <HorizontalStatistic
          metricDescription="Smash"
          homeCell={report.getCell('strokes.HomeParty.AGAINSTNETPLAY.SMASH')}
          awayCell={report.getCell('strokes.AwayParty.AGAINSTNETPLAY.SMASH')}
          offsetX="178"
          offsetY="331"
          fontRemarkable="false"
        />
      </g>
    </g>
  );
};

const ScoreBlock = ({ report, offsetX, offsetY, onClick }) => {
  const [server, setServer] = useState('ALL');

  const scores = [
    '0 - 0',
    '0 - 15',
    '0 - 30',
    '0 - 40',
    '15 - 0',
    '15 - 15',
    '15 - 30',
    '15 - 40',
    '30 - 0',
    '30 - 15',
    '30 - 30',
    '30 - 40',
    '40 - 0',
    '40 - 15',
    '40 - 30',
    '40 - 40',
    '40 - 40A',
    '40A - 40',
  ];

  let bars = scores.map((score, index) => {
    const offsetY = 66 + index * 13;
    return (
      <g key={score}>
        <HorizontalStatistic
          metricDescription={score}
          homeCell={report.getCell(
            `scores.HomeParty.server:${server}.${score}`
          )}
          awayCell={report.getCell(
            `scores.AwayParty.server:${server}.${score}`
          )}
          onClick={onClick}
          offsetX="178"
          offsetY={offsetY}
        />
      </g>
    );
  });

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <rect
        x="0"
        y="0"
        opacity="0.5"
        fill="var(--secondary-color)"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        width="352.807"
        height="23.618"
      />
      <text
        transform="matrix(1 0 0 1 160 15.6)"
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="10.8399"
      >
        SCORES
      </text>
      <polygon
        fill="none"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        points="353,300 353,0  0,0  0,300 "
      />

      <g>
        <text
          transform="matrix(1 0 0 1 133 46)"
          fontFamily="'Lato', sans-serif"
          fontSize="5"
        >
          <tspan
            fill={server === 'HomeParty' ? '' : '#DAE0E3'}
            style={{ cursor: 'pointer' }}
            onClick={() => setServer('HomeParty')}
          >
            {report.getMetaData('HomeParty.player.shortName')}
          </tspan>
        </text>

        <text
          transform="matrix(1 0 0 1 173 46)"
          fontFamily="'Lato', sans-serif"
          fontSize="5"
        >
          <tspan
            fill={server === 'ALL' ? '' : '#DAE0E3'}
            style={{ cursor: 'pointer' }}
            onClick={() => setServer('ALL')}
          >
            ALL
          </tspan>
        </text>

        <text
          transform="matrix(1 0 0 1 213 46)"
          fill="var(--secondary-color)"
          fontFamily="'Lato', sans-serif"
          fontSize="5"
        >
          <tspan
            fill={server === 'AwayParty' ? '' : '#DAE0E3'}
            style={{ cursor: 'pointer' }}
            onClick={() => setServer('AwayParty')}
          >
            {report.getMetaData('AwayParty.player.shortName')}
          </tspan>
        </text>
      </g>
      <g>
        <text
          transform="matrix(1 0 0 1 78 46)"
          fill="#9D9D9C"
          fontFamily="'Lato', sans-serif"
          fontSize="5"
        >
          {report.getMetaData('home.player')}
        </text>
      </g>
      <g>
        <text
          transform="matrix(1 0 0 1 234 46)"
          fill="#9D9D9C"
          fontFamily="'Lato', sans-serif"
          fontSize="5"
        >
          {report.getMetaData('away.player')}
        </text>
      </g>

      {bars}
    </g>
  );
};

const MatchHeader = ({ report, offsetX, offsetY, setActiveBlockId }) => {
  const startX =
    352 -
    getTextWidth(`${report.getMetaData('AwayParty.player')}`, '400 9px Lato') -
    9;
  const setScoresX =
    235 - getTextWidth(`{report.getMetaData('setscores')}`, '400 9px Lato') / 2;

  return (
    <g transform={`matrix(1 0 0 1 ${offsetX} ${offsetY})`}>
      <rect
        x="0"
        y="0"
        fill="var(--secondary-color)"
        stroke="var(--secondary-color)"
        strokeMiterlimit="10"
        width="352.807"
        height="36"
      />
      <text
        transform="matrix(1 0 0 1 9 15)"
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="9"
      >
        {report.getMetaData('HomeParty.player')}
      </text>
      <text
        transform={`matrix(1 0 0 1 ${startX} 15)`}
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="9"
      >
        {report.getMetaData('AwayParty.player')}
      </text>
      <text
        transform={`matrix(1 0 0 1 ${setScoresX} 15)`}
        fill="#FFFFFF"
        fontFamily="'Lato', sans-serif"
        fontSize="7.6986"
      >
        {report.getMetaData('setscores')}
      </text>

      <rect
        x="1"
        y="24"
        fill="#FFFFFF"
        stroke="#FFFFFF"
        width="350.807"
        height="12"
      />
      <text
        transform="matrix(1 0 0 1 80 32)"
        fill="var(--secondary-color)"
        fontFamily="'Lato', sans-serif"
        fontSize="8"
      >
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setActiveBlockId('match');
          }}
        >
          match
        </a>{' '}
        |{' '}
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setActiveBlockId('critical points');
          }}
        >
          critical points
        </a>{' '}
        |{' '}
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setActiveBlockId('rally length');
          }}
        >
          rally length
        </a>{' '}
        |{' '}
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setActiveBlockId('scores');
          }}
        >
          scores
        </a>{' '}
        |{' '}
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            setActiveBlockId('strokes');
          }}
        >
          strokes
        </a>
      </text>
    </g>
  );
};

const Dashboard = ({ report, onClick }) => {
  const [activeBlockId, setActiveBlockId] = useState('match');

  return (
    <svg
      className="light-color"
      viewBox="120 50 360 370"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '80%' }}
    >
      <MatchHeader
        report={report}
        setActiveBlockId={setActiveBlockId}
        offsetX="123.441"
        offsetY="50.009"
        onClick={onClick}
      />

      {activeBlockId === 'match' && (
        <MatchBlock
          report={report}
          offsetX="123.441"
          offsetY="85.626"
          onClick={onClick}
        />
      )}

      {activeBlockId === 'critical points' && (
        <CriticalPointsBlock
          onClick={onClick}
          report={report}
          offsetX="123.441"
          offsetY="85.626"
        />
      )}

      {activeBlockId === 'rally length' && (
        <RallyLengthBlock
          onClick={onClick}
          report={report}
          offsetX="123.441"
          offsetY="85.626"
        />
      )}

      {activeBlockId === 'scores' && (
        <ScoreBlock
          onClick={onClick}
          report={report}
          offsetX="123.441"
          offsetY="85.626"
        />
      )}

      {activeBlockId === 'strokes' && (
        <StrokeBlock
          report={report}
          offsetX="123.441"
          offsetY="85.626"
          onClick={onClick}
        />
      )}
    </svg>
  );
};

export default Dashboard;
