import React, { FC } from 'react';

import { ITableProps } from 'ka-table';
import {
  closeEditor,
  openEditor,
  updateCellValue,
  updateEditorValue,
} from 'ka-table/actionCreators';

import { ICellEditorProps, ICellTextProps } from 'ka-table/props';
import Select from 'react-select';
import { OptionsType } from 'react-select/lib/types';

import { EditableCell } from 'ka-table/models';

type Predicate = <I>(item: I, index: number, collection: I[]) => boolean;

// tree node
interface TNode {
  id: string;
  children: TNode[];
}

interface Marker extends TNode {
  label: string;
  children: Marker[];
}

const traverse = (predicate: Predicate) => (
  node: TNode,
  idx: number,
  collection: TNode[]
): TNode | undefined => {
  const found = predicate(node, idx, collection);

  if (found) {
    return node;
  } else if (typeof node.children !== 'undefined') {
    let found;

    for (let idx = 0; idx < node.children.length; idx++) {
      const child = node.children[idx];
      const result = traverse(predicate)(child, idx, node.children);

      if (result) {
        found = result;
        break;
      }
    }

    return found;
  }
  return;
};

// traverse depth first
const traverseRoot = (predicate: Predicate) => (
  nodes: TNode[]
): TNode | undefined => {
  let found;

  for (let idx = 0; idx < nodes.length; idx++) {
    const child = nodes[idx];
    const result = traverse(predicate)(child, idx, nodes);

    if (result) {
      found = result;
      break;
    }
  }

  return found;
};

export interface MarkerCellProps extends ICellTextProps {
  markerLevel?: number;
  learningLines: Marker[];
  editableCells?: EditableCell[];
}

export const markerFilter = (
  value: string,
  filterRowValue: string,
  markers: Marker[]
) => {
  const marker = traverseRoot(marker => marker.id == value)(markers);
  return marker?.label
    .toLowerCase()
    .includes(filterRowValue.toLocaleLowerCase());
};

export const MarkerCell: FC<MarkerCellProps> = ({
  column,
  dispatch,
  rowKeyValue,
  rowData,
  markerLevel = 0,
  editableCells = [],
  learningLines = [],
  value,
}) => {
  let markersFiltered: Marker[];

  if (markerLevel === 0) {
    markersFiltered = learningLines;
  } else {
    const parentColumnKey = `markerLevel${markerLevel - 1}`;
    if (isNewRow(rowKeyValue)) {
      const parentValue = editableCells.find(
        cell => cell.columnKey == parentColumnKey
      )?.editorValue;
      markersFiltered = parentValue
        ? markersForId(parentValue, learningLines)
        : [];
    } else {
      markersFiltered = markersForId(rowData[parentColumnKey], learningLines);
    }
  }

  // TODO strictly equal
  const label = markersFiltered.find(m => m.id === value)?.label;

  return (
    <div
      onClick={() => {
        dispatch(openEditor(rowKeyValue, column.key));
      }}
    >
      {label || '—'}
    </div>
  );
};

// WARN: this breaks when non-primitive is used as the row key value
const isNewRow = (rowKeyValue: any) => typeof rowKeyValue === 'object';

const markersForId = (id: string, markers: Marker[]): Marker[] => {
  const marker = traverseRoot(marker => marker.id == id)(markers);

  return marker ? marker.children : [];
};

export interface MarkerEditorProps extends ICellEditorProps {
  markerLevel?: number;
  learningLines: Marker[];
  editableCells?: EditableCell[];
}

export const MarkerEditor: FC<MarkerEditorProps> = ({
  column,
  dispatch,
  rowKeyValue,
  rowData,
  value,
  field,
  editableCells = [],
  learningLines = [],
  markerLevel = 0,
  ...props
}) => {
  let markersFiltered: Marker[];

  if (markerLevel === 0) {
    markersFiltered = learningLines;
  } else {
    const parentColumnKey = `markerLevel${markerLevel - 1}`;
    if (isNewRow(rowKeyValue)) {
      const parentValue = editableCells.find(
        cell => cell.columnKey == parentColumnKey
      )?.editorValue;
      markersFiltered = parentValue
        ? markersForId(parentValue, learningLines)
        : [];
    } else {
      markersFiltered = markersForId(rowData[parentColumnKey], learningLines);
    }
  }

  const options = markersFiltered.map(marker => ({
    value: marker.id,
    label: marker.label,
  })) as OptionsType<{ value: string; label: string }>;

  return (
    <div>
      <Select
        maxMenuHeight={200}
        autoFocus={typeof rowKeyValue === 'string'}
        defaultValue={options.find(option => option.value == value)}
        isDisabled={options.length === 0}
        onBlur={() => {
          if (typeof rowKeyValue === 'string') {
            dispatch(closeEditor(rowKeyValue, column.key));
          }
        }}
        onChange={({ value }) => {
          if (typeof rowKeyValue === 'string') {
            // update existing
            dispatch(updateCellValue(rowKeyValue, column.key, value));
            dispatch(closeEditor(rowKeyValue, column.key));
          } else {
            // update new row
            dispatch(updateEditorValue(rowKeyValue, column.key, value));
          }
        }}
        options={options}
      />
    </div>
  );
};

interface Action {
  type: string;
  columnKey: string;
  rowKeyValue: any;
  value: any;
}

const rowDataIndex = (prevState: ITableProps, action: Action) =>
  prevState.data.findIndex(
    row => row[prevState.rowKeyField] == action.rowKeyValue
  );
const rowData = (prevState: ITableProps, action: Action) =>
  prevState.data[rowDataIndex(prevState, action)];

const hasDataValueChanged = (rowData: any, action: Action) => {
  return action.value !== rowData[action.columnKey];
};

const editCellIndex = (prevState: ITableProps, action: Action) =>
  prevState.editableCells.findIndex(
    cell =>
      cell.columnKey == action.columnKey &&
      cell.rowKeyValue === action.rowKeyValue
  );
const cellData = (prevState: ITableProps, action: Action) =>
  prevState.editableCells[editCellIndex(prevState, action)];

const hasCellValueChanged = (cellData: ICellEditorProps, action: Action) => {
  return action.value !== cellData.editorValue;
};

export const markerReducer = (
  defaultReducer: (prevState: ITableProps, action: any) => ITableProps,
  action: Action
) => (prevState: ITableProps) => {
  if (action.type === 'CloseEditor') {
    console.log(action, prevState);
  }

  if (
    action.type === 'UpdateCellValue' &&
    action.columnKey.substr(0, 11) === 'markerLevel' &&
    hasDataValueChanged(rowData(prevState, action), action)
  ) {
    const markerLevel = Number.parseInt(action.columnKey.substr(11));
    const dataRowIdx = rowDataIndex(prevState, action);
    const updatedData = { ...rowData(prevState, action) };

    updatedData[action.columnKey] = action.value;

    // find higher level columns
    const higherLevelColumns = Object.keys(updatedData).filter(
      column =>
        column.substr(0, 11) === 'markerLevel' &&
        Number.parseInt(column.substr(11)) > markerLevel
    );

    // clear those values
    higherLevelColumns.forEach(column => {
      updatedData[column] = undefined;
    });

    return {
      ...prevState,
      editableCells: prevState.editableCells.filter(
        cell =>
          // close currently editing higher level cells
          !(
            cell.rowKeyValue === action.rowKeyValue &&
            higherLevelColumns.includes(cell.columnKey)
          )
      ),
      data: prevState.data.map((row, idx) =>
        idx !== dataRowIdx ? row : updatedData
      ),
    };
  } else if (
    action.type === 'UpdateEditorValue' &&
    action.columnKey.substr(0, 11) === 'markerLevel' &&
    hasCellValueChanged(cellData(prevState, action), action)
  ) {
    // new row marker level
    const markerLevel = Number.parseInt(action.columnKey.substr(11));

    // find higher level columns
    const higherLevelColumns = prevState.columns
      .map(column => column.key)
      .filter(
        column =>
          column.substr(0, 11) === 'markerLevel' &&
          Number.parseInt(column.substr(11)) > markerLevel
      );

    const editableCells = prevState.editableCells.map(prevCell => {
      if (prevCell.rowKeyValue === action.rowKeyValue) {
        if (higherLevelColumns.includes(prevCell.columnKey)) {
          // clear higher level column
          return { ...prevCell };
        } else if (prevCell.columnKey === action.columnKey) {
          // update current column
          return { ...prevCell, editorValue: action.value };
        } else {
          // leave other columns be
          return prevCell;
        }
      } else {
        return prevCell;
      }
    });

    const cells = editableCells.filter(cell => typeof cell !== 'undefined');

    return {
      ...prevState,
      editableCells: cells,
    };
  } else {
    return defaultReducer(prevState, action);
  }
};
