import React, { Component } from 'react';
import { Session } from '../../../domain/Session';
import { Notification } from 'lib/Notification';

import { observable, action, runInAction, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';

import Loading from 'lib/Loading';
import { Page } from 'lib/Page';
import logger from '../../../utils/Logger';
import userPlaceHolder from 'img/icons/profile-picture-placeholder.svg';

import { ChangeLanguage } from 'lib/ChangeLanguage';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ProfileVerifyEmail } from '../components/ProfileVerifyEmail';
import { SetupNotifications } from '../components/SetupNotifications';

async function read(blob) {
  const reader = new FileReader();
  const promise = new Promise((resolve) => {
    reader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        // DONE == 2
        resolve(evt.target.result);
      }
    };
  });
  reader.readAsDataURL(blob);
  return promise;
}

const UserProfile = withTranslation('module.settings.profile')(
  observer(
    class UserProfile extends Component {
      constructor() {
        super();
        this.imageFileDataUrl = observable('');
        this.dataState = observable('loading');
        this.imageRotation = observable(0);
        this.cropperRef = React.createRef(null);
        this.getCropperData = () => {};
      }

      componentDidMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        this.dataState.set('loading');
        try {
          const currentSession = Session.current();
          yield currentSession.isReady();
          this.user = Session.current().user;
          this.dataState.set('ready');
        } catch (e) {
          logger.error(e, {
            transactionName: 'unable to load user profile',
          });
          this.dataState.set('error');
        }
      });

      async onFileSelected(file) {
        let dataURL;
        if (file === undefined) {
          dataURL = '';
        } else {
          dataURL = await read(file);
        }

        runInAction(() => {
          this.imageFileDataUrl.set(dataURL);
        });
      }

      onCancel = action(() => {
        this.imageFileDataUrl.set('');
      });

      handleSubmit = asyncAction(
        function* (values, formikBag) {
          let changed = false;
          if (this.cropperRef.current) {
            const imageData = this.cropperRef.current.cropper
              .getCroppedCanvas()
              .toDataURL('image/jpeg');
            yield this.user.setProfilePicture(imageData, false);
            this.imageFileDataUrl.set('');
            changed = true;
          }

          if (
            values.firstName !== this.user.firstName ||
            values.lastName !== this.user.lastName
          ) {
            yield this.user.setRealName(
              values.firstName,
              values.lastName,
              false
            );
            changed = true;
          }

          if (values.locale !== this.user.locale?.language) {
            yield this.user.setLocale(values.locale.baseName);
            changed = true;
          }

          if (changed) {
            yield this.user.fetch();
          }
          Notification.show(this.props.t('common.form:dataSaved'), 2500);
        }.bind(this)
      );

      rotateLeft = action(() => {
        this.imageRotation.set(this.imageRotation.get() - 90);
        this.cropperRef.current.cropper.rotateTo(this.imageRotation.get());
      });

      rotateRight = action(() => {
        this.imageRotation.set(this.imageRotation.get() + 90);
        this.cropperRef.current.cropper.rotateTo(this.imageRotation.get());
      });

      validate = (values) => {
        const errors = {};

        if (!values.firstName || !values.firstName.length) {
          errors.firstName = true;
        }

        if (!values.lastName || !values.lastName.length) {
          errors.lastName = true;
        }

        if (!values.locale) {
          errors.locale = true;
        }
        return errors;
      };

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return 'error';
        }

        const { t } = this.props;
        const profilePictureUrl = this.user.profilePictureUrl
          ? this.user.profilePictureUrl
          : userPlaceHolder;
        const initialValues = {
          firstName: this.user.firstName || '',
          lastName: this.user.lastName || '',
          locale: this.user.locale || null,
        };

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: this.props.t('module.settings:title') },
            { title: this.props.t('title') },
          ],
        };

        const isFileSelected = this.imageFileDataUrl.get() !== '';
        return (
          <Page {...pageProps}>
            <Formik
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              validate={this.validate}
            >
              {({
                handleSubmit,
                values,
                handleChange,
                errors,
                isSubmitting,
              }) => {
                return (
                  <form
                    className="form"
                    onSubmit={
                      !isSubmitting
                        ? handleSubmit
                        : (evt) => evt.preventDefault()
                    }
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <h3>{t('title')}</h3>
                        {!this.user.emailVerified && (
                          <ProfileVerifyEmail user={this.user} />
                        )}
                        <div className="form-group">
                          <label htmlFor="firstName">{t('firstName')}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={handleChange}
                            value={values.firstName}
                          />
                          {errors.firstName && (
                            <span>{t('common.form:required-field')}</span>
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="lastName">{t('lastName')}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={handleChange}
                            value={values.lastName}
                          />
                          {errors.lastName && (
                            <span>{t('common.form:required-field')}</span>
                          )}
                        </div>
                        <ChangeLanguage
                          onChange={(locale) => {
                            values.locale = locale;
                            handleChange('locale');
                          }}
                          value={values.locale?.region}
                          id="language"
                        />
                        {errors.locale && (
                          <span>{t('common.form:required-field')}</span>
                        )}
                        {/* <div className="form-actions" style={{ marginBottom: '20px' }}>
                  <button type="button" className="btn btn-primary">
                    Gegevens opslaan
                  </button>
                </div> */}
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group">
                          <label>{t('selectPhotoTitle')}</label>
                        </div>

                        {isFileSelected ? (
                          <>
                            <Cropper
                              ref={this.cropperRef}
                              src={this.imageFileDataUrl.get()}
                              style={{
                                height: 287,
                                width: 287,
                                margin: '0 auto 20px',
                              }}
                              // Cropper.js options
                              aspectRatio={1 / 1}
                              guides={false}
                            />
                            <div
                              className="form-group"
                              style={{ textAlign: 'center' }}
                            >
                              <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.rotateLeft}
                              >
                                ↺
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.rotateRight}
                              >
                                ↻
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="form-group">
                            <div
                              style={{
                                width: '150px',
                                height: '150px',
                                // margin: '0 auto 20px',
                                backgroundImage: `url(${profilePictureUrl})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                borderRadius: '400px',
                                border: 'solid 2px #ccc',
                              }}
                            />
                          </div>
                        )}
                        <div className="form-group">
                          <input
                            type="file"
                            id="file-select"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              this.onFileSelected(e.target.files[0]);
                              e.target.value = '';
                            }}
                          />
                          <label
                            htmlFor="file-select"
                            className="btn btn-secondary btn-cta"
                            // style={{ display: 'block' }}
                          >
                            {t('selectPhotoCTA')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <div className="form-actions">
                            <button
                              className="btn btn-primary btn-cta"
                              type="submit"
                            >
                              {t('save')}
                              {isSubmitting && (
                                <Loading
                                  type="spinner"
                                  size="16"
                                  color="#fff"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
            <div className="row">
              <div className="col-lg-8">
                <SetupNotifications />
              </div>
            </div>
          </Page>
        );
      }
    }
  )
);

export { UserProfile };
