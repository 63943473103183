import React from 'react';
import { withRouter } from 'react-router-dom';

import { AnonymousPageWrapper } from './AnonymousPageWrapper';
import { Helmet } from 'react-helmet';

import { PasswordResetForm } from '../lib/UserLogin/PasswordResetForm';
import { useTranslation } from 'react-i18next';
import ttvIcon from 'img/ttv_icon.svg';
import srIcon from '../img/sr_icon.svg';

const PasswordResetPage = () => {
  const { t } = useTranslation('loginPage');
  return (
    <AnonymousPageWrapper className={'page--login'} title={'login'}>
      <Helmet>
        <title>{t('PasswordResetTitle')}</title>
      </Helmet>
      <div className="userlogin-layout">
        <div className="userlogin__formwrapper">
          <img
            className="userlogin__icon"
            src={window.isSkillReflect ? srIcon : ttvIcon}
          />
          <PasswordResetForm />
          <div className={'userlogin__bottom'}>
            {t('backToLogin1')} <a href={'/login'}>{t('backToLogin2')}</a>
            <p className="text-muted">
              {t('noAccount1')} <a href={'/register'}>{t('noAccount2')}</a>
            </p>
          </div>
        </div>
      </div>
    </AnonymousPageWrapper>
  );
};

export default withRouter(PasswordResetPage);
