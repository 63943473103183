/* Define on paths on which users should not be bothered with overlays or changes. (eg. Popups) */
/* Returns TRUE when user should not be disturbed. */
let blacklistedPaths = [
  '/observe/*',
  '/embedding/*',
  '/lineup/*',
  '/reporting/*',
  '/match/livestream/*',
];

const doNotDisturbPaths = (pathname) => {
  function matchRuleShort(subject: string, rule: string) {
    return new RegExp('^' + rule.split('*').join('.*') + '$').test(subject);
  }
  const isBlackListedPath = (path): boolean => {
    return blacklistedPaths.some((blacklistedPath) => {
      return matchRuleShort(path, blacklistedPath);
    });
  };

  return isBlackListedPath(pathname);
};

export default doNotDisturbPaths;
