import React from 'react';
import { LearningIndicator } from './learning-indicator';

const LearningIndicatorsGroup = ({
  phaseId,
  indicators,
  learningLine,
  observations,
  t,
  ...objectiveProps
}) => {
  const unmarkedObservations = observations.filter((obs) => {
    return (
      !obs.attributes.indicatorId &&
      obs.attributes.phaseId === phaseId &&
      obs.attributes.learningLineId === learningLine.learningLineId
    );
  });
  let availableIndicators = indicators.map(
    (indicator) => indicator.indicatorId
  );
  const markedObservations = observations.filter((obs) => {
    return (
      obs.attributes.phaseId == phaseId &&
      availableIndicators.includes(obs.attributes.indicatorId)
    );
  });
  return (
    <div className="learning-indicators-group">
      {indicators.map((indicator) => (
        <LearningIndicator
          key={indicator.indicatorId}
          phaseId={phaseId}
          indicator={indicator}
          examples={learningLine.getVideoFragments(indicator.indicatorId)}
          observations={markedObservations}
          {...objectiveProps}
        />
      ))}
      {unmarkedObservations.length > 0 && (
        <>
          <LearningIndicator
            key={t('unmarkedObs')}
            t={t}
            phaseId={phaseId}
            indicator={{
              name: t('unmarkedObs'),
              indicatorId: `unmarked-${phaseId}-${learningLine.learningLineId}`,
              isUnmarked: true,
            }}
            observations={unmarkedObservations}
            {...objectiveProps}
          />
        </>
      )}
    </div>
  );
};

export { LearningIndicatorsGroup };
