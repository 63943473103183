/* https://dev.to/ycmjason/limit-concurrent-asynchronous-calls-5bae */

const asyncLimit = (fn, n) => {
  let pendingPromises = [];
  return async function(...args) {
    console.log('Called with some stuff', args);
    while (pendingPromises.length >= n) {
      await Promise.race(pendingPromises).catch(() => {});
    }

    const p = fn.apply(this, args);
    pendingPromises.push(p);
    await p.catch(() => {});
    pendingPromises = pendingPromises.filter(pending => pending !== p);
    return p;
  };
};

export default asyncLimit;
