import { VideoFragmentCollection } from './Collection';

class VideoFragmentCollector {
  constructor() {
    this.generators = [];
  }

  addGenerator(generator) {
    this.generators.push(generator);
  }

  collect(video, sportingEvent, observations) {
    const startTime = performance.now();
    const videoFragmentCollection = new VideoFragmentCollection(video);
    for (const generator of this.generators) {
      generator.generate(videoFragmentCollection, {
        video,
        sportingEvent,
        observations,
      });
    }
    const took = performance.now() - startTime;
    console.log('took', took, 'ms');
    return videoFragmentCollection;
  }
}

export { VideoFragmentCollector };
