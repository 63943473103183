import { ChangeEvent } from "react";

const secsPerMin = 60;

export const useMinsWithoutSecs = (
  totalSeconds: number,
  setTotalSeconds: (seconds: number) => void
) => {
  let mins = minutesWithoutSeconds(totalSeconds);

  const setMins = parseNumberValue(
    (value) => {
      mins = value;

      setTotalSeconds(mins * secsPerMin + remainderSeconds(totalSeconds));
    },
    999
  );

  return { mins, setMins };
};

export const useRemainderSecs = (
  totalSeconds: number,
  setTotalSeconds: (seconds: number) => void
) => {
  let secs = remainderSeconds(totalSeconds);

  const setSecs = parseNumberValue((value) => {
    secs = value;

    setTotalSeconds(minutesWithoutSeconds(totalSeconds) * secsPerMin + secs);
  });

  return { secs, setSecs };
};

const parseNumberValue = (onChange: (value: number) => void, maxNumber: number = 60) => (
  evt: ChangeEvent<HTMLInputElement>
) => {
  if (evt.target.value === "") {
    onChange(0);
  } else {
    const parsedNumber = Number.parseInt(evt.target.value);

    if (!isNaN(parsedNumber) && parsedNumber >= 0 && parsedNumber < maxNumber) {
      onChange(parsedNumber);
    }
  }
};

const minutesWithoutSeconds = (seconds: number) => {
  return Math.floor(seconds / secsPerMin);
};

const remainderSeconds = (seconds: number) => {
  return seconds % secsPerMin;
};
