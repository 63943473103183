import React, { useState, useEffect } from 'react';

import Select from 'react-select';

import { Column, Input, ObservationDetailsContainer } from './base';

import sportingEventCollection from 'domain/SportingEvent';

import videoCollection from 'domain/Video';
import { useObservationsEditCollectionContext } from 'modules/observe/edit-collection-context';
import {
  displayTime,
  sportingEvent as observationSportingEvent,
} from 'domain/Observation';
import { ObservationChatBox } from '../../../../lib/Conversation';
import { ObservationDetailsPlayers } from './players';
import { withTranslation } from 'react-i18next';

function buildMap() {
  const map = {};
  // sportingEventCollection.toArray() === []
  for (const sportingEvent of sportingEventCollection.toArray()) {
    for (const [clockSource, clock] of Object.entries(sportingEvent.clocks())) {
      if (clockSource !== 'U1') {
        map[
          `${sportingEvent.toJS().observationLogId}-${clock.clockId}`
        ] = clockSource;
      }
    }
  }
  return map;
}

function videoByObservation(observation) {
  const videoIds = buildMap();
  const videoId =
    videoIds[`${observation.observationLogId}-${observation.clockId}`];

  return videoCollection.get(videoId);
}

const normalizeObservation = objectOrObservable =>
  !!objectOrObservable.toJS ? objectOrObservable.toJS() : objectOrObservable;

const CustomObservationDetails = withTranslation('module.observe.video')(
  ({ t, attributesOptions, ...props }) => {
    const { current, observations } = useObservationsEditCollectionContext();
    const observation = normalizeObservation(current);

    const [rating, setRating] = useState(null);
    const [comment, setComment] = useState(null);
    const [personId, setPersonId] = useState(null);
    const [participantsPersonIds, setParticipantsPersonIds] = useState([]);

    const [sportingEvent, setSportingEvent] = useState();

    useEffect(() => {
      (async () => {
        await sportingEventCollection.fetchIfEmpty();

        setSportingEvent(
          observationSportingEvent(sportingEventCollection, observation)
        );
      })();
    }, [observations]);

    useEffect(() => {
      setRating(observation.attributes.rating);
      setComment(observation.attributes.comment);
      setPersonId(observation.attributes.personId);
      setParticipantsPersonIds(
        observation.attributes.participantsPersonIds || []
      );
    }, [
      observation.attributes.comment,
      observation.attributes.rating,
      observation.observationId,
      observation.attributes.personId,
      // observation.attributes.participantsPersonIds, <- Not sure why this must be commented out. Otherwise this will cause an infinite loop..
    ]);

    const ratingOptions = [
      { value: 'positive', label: 'Positive' },
      { value: 'neutral', label: 'Neutral' },
      { value: 'negative', label: 'Negative' },
      { value: 'unknown', label: 'Unrated' },
    ];

    if (!observation) {
      return null;
    }

    const video = props.video || videoByObservation(observation);

    const { displayStartTime, displayEndTime } = displayTime(current);

    return (
      <ObservationDetailsContainer
        observationId={observation.id}
        startTime={displayStartTime}
        endTime={displayEndTime}
        observation={observation}
        observationProps={{
          attributes: {
            rating,
            comment,
            personId,
            participantsPersonIds,
          },
        }}
        title={sportingEvent?.name}
        {...props}
        video={video}
      >
        <Column>{null}</Column>
        <Column>
          <p>
            {sportingEvent
              ? new Date(sportingEvent.createdAt).toLocaleDateString()
              : ''}{' '}
            - {observation.description}
          </p>
          <p>Created by role: {observation.creatorRoleName}</p>
          <Input label="Rating">
            <Select
              options={ratingOptions}
              value={
                ratingOptions.find(option => option.value === rating) || null
              }
              onChange={option => setRating(option.value)}
            />
          </Input>
          {!window.isSkillReflect && (
            <>
              <Input label={t('whoPerformsAction')}>
                <Select
                  options={attributesOptions.personId}
                  value={
                    attributesOptions.personId.find(
                      option => option.value === personId
                    ) || null
                  }
                  onChange={option => setPersonId(option.value)}
                />
              </Input>
              <Input label={t('playersInvolvedInAction')}>
                <Select
                  options={attributesOptions.personId}
                  isMulti
                  value={attributesOptions.personId.filter(
                    option => participantsPersonIds.indexOf(option.value) !== -1
                  )}
                  onChange={options =>
                    setParticipantsPersonIds(
                      options.map(option => option.value)
                    )
                  }
                />
              </Input>
            </>
          )}
          <Input label="Comment">
            <textarea
              className="form-control"
              rows="5"
              value={comment || ''}
              onChange={e => setComment(e.target.value)}
            />
          </Input>
        </Column>
        <Column>
          <ObservationChatBox observationId={observation.observationId} />
        </Column>
      </ObservationDetailsContainer>
    );
  }
);

export { CustomObservationDetails };
