import React, { Component } from 'react';

import './index.scss';

const CollapseIcon = ({ collapsed = true, dark = false, ...props }) => (
  <div className={`collapse-icon ${collapsed ? 'collapsed' : ''}`} {...props}>
    <i className={`i-chevron-down ${dark ? 'i-dark' : ''}`} />
  </div>
);

export { CollapseIcon };
