import React from 'react';

import { useMutation } from 'react-query';

import { cloneDeep, merge } from 'lodash';

import {
  changeset,
  delete_ as deleteObservation,
  update as updateObservation,
} from 'domain/Observation';

export const GetObservationUpdateMutation = queryClient => {
  return useMutation(updateObservation, {
    onMutate: async ({ observation, params }) => {
      const changes = changeset({ observation, params });
      const observationUpdated = merge(cloneDeep(observation), changes);
      const queryKey = 'observations';

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const observationsCached = queryClient.getQueryData('observations');
      // Optimistically update to the new value
      const idx = observationsCached.findIndex(
        obs => obs.observationId === observation.observationId
      );
      const observationsUpdated = [
        ...observationsCached.slice(0, idx),
        observationUpdated,
        ...observationsCached.slice(idx + 1),
      ];

      observationsUpdated.map((obs, i) => {
        // Fix start/endTimes returning null when not modifying them.
        if (obs.startTime === null && obs.endTime == null) {
          // Restore start/endTimes from cache.
          observationsUpdated[i].startTime = observationsCached[i].startTime;
          observationsUpdated[i].endTime = observationsCached[i].endTime;
        }
      });

      queryClient.setQueryData(queryKey, observationsUpdated);

      return { previous: observationsCached, current: observationsUpdated };
    },
  });
};
