import { ReportDefinition } from '../../ReportDefinition';
import personCollection from '../../../../domain/Person';
import { PlayerReport, Report, ShotMetricType } from './Report';
import { IObservation } from '../../../ObservationTree/IObservation';
import { TypedCell } from '../../Cell';
import { VideoFragment } from '../../VideoFragment';

// TODO: move to utils
function groupBy<T, KT>(list: T[], keyGetter: (item: T) => KT): Map<KT, T[]> {
  const map = new Map<KT, T[]>();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function aggregateToCell(
  list: any[],
  filterFn: (item: any) => boolean,
  successMetric: string = 'scored'
): TypedCell<ShotMetricType> {
  const items = list.filter(filterFn);
  return new TypedCell<ShotMetricType>(
    {
      total: items.length,
      scored: items.filter(item => (item as any).scored).length,
    },
    items.map(
      (item: any): VideoFragment => ({
        startTime: item.startTime || item.time - 5,
        endTime: item.endTime || item.time + 5,
        description: item.description || '',
        label: item[successMetric] ? 'positive' : undefined,
      })
    )
  );
}

function between(n: number, min: number, max: number) {
  return min <= n && n < max;
}

enum Team {
  HOME = 'home',
  AWAY = 'away',
}

enum AttackResult {
  GOAL = 'goal',
  FOUL = 'foul',
  TURNOVER = 'turnover',
}

type ObservationWithTeam = {
  observation: IObservation;
  team: Team;
};

type Attack = {
  team: Team;
  result: AttackResult;
  startTime: number;
  endTime: number;
};

enum ShotType {
  NORMAL = 'normal',
  PENALTY = 'penalty',
}

type Person = {
  firstName: string;
  lastName: string;
  number: string;
};

type Shot = {
  team: Team;
  type: ShotType;
  personId: string;
  person: Person | null;
  scored: boolean;
  position: {
    distance: number;
    x: number;
  };
  goalPosition: {
    x: number;
    y: number;
  };
  polarCoordinates: {
    distance: number;
    angle: number;
  };
  time: number;
};

type Foul = {
  team: Team;
  time: number;
};

const polarCoordinates = (distance: number, x: number) => {
  let angle =
    Math.round((Math.atan2(distance, x) / (2 * Math.PI)) * 360 * 10) / 10;
  angle -= 90;
  angle *= -1;
  return {
    distance: Math.sqrt(Math.pow(distance, 2) + Math.pow(x, 2)),
    angle,
  };
};

const distance_to_label = (distance: number): string => {
  switch (true) {
    case distance < 6:
      return 'lt6m';
    case 6 <= distance && distance <= 7:
      return '6_7m';
    case 7 < distance && distance <= 9:
      return '7_9m';
    case distance > 9:
      return 'gt9m';
    default:
      return 'unknown';
  }
};

export const handballReportDefinition: ReportDefinition<Report> = {
  dataSources: {
    observationLog: async sportingEvent => {
      const observationLog = sportingEvent.getObservationCollection(
        sportingEvent.mainClock(true).clockId()
      );
      await observationLog.fetch();

      const observations = observationLog.toArray();
      observations.sort((a, b) => a.triggerTime - b.triggerTime);
      return observations;
    },
    persons: async () => {
      await personCollection.fetchIfEmpty();
      return personCollection;
    },
    sportingEvent: sportingEvent => sportingEvent,
  },
  dataViews: {
    observationsWithTeam: [
      ['observationLog'],
      (
        resolveContext,
        observationLog: IObservation[]
      ): ObservationWithTeam[] => {
        let currentTeam: Team = Team.HOME;
        const observationsWithTeam: ObservationWithTeam[] = [];
        for (const observation of observationLog) {
          switch (observation.code) {
            case 'POSSESSION':
              currentTeam =
                observation.attributes_.teamId ===
                resolveContext.homeTeam.teamId
                  ? Team.HOME
                  : Team.AWAY;
            // passthrough intented
            case 'SHOT':
            case 'GAME:SPECIAL':
            case 'GAME:FOUL':
              observationsWithTeam.push({
                observation: observation,
                team: currentTeam,
              });

              break;
          }
        }
        return observationsWithTeam;
      },
    ],
    shots: [
      ['observationsWithTeam'],
      (resolveContext, observationsWithTeam: ObservationWithTeam[]): Shot[] => {
        return observationsWithTeam
          .filter(
            (observationWithTeam: ObservationWithTeam) =>
              observationWithTeam.observation.code === 'SHOT' ||
              observationWithTeam.observation.code === 'GAME:SPECIAL'
          )
          .map(
            (observationWithTeam: ObservationWithTeam): Shot => {
              const observation = observationWithTeam.observation;
              let positionAttributes = {
                position: {
                  distance: 0,
                  x: 0,
                },
                goalPosition: { x: 0, y: 0 },
                polarCoordinates: { distance: 0, angle: 0 },
              };
              if (typeof observation.attributes_.distance !== 'undefined') {
                const goalPosition = observation.attributes_.goalPosition as {
                  x: number;
                  y: number;
                };
                const distance = observation.attributes_.distance as number;
                const x = observation.attributes_.x as number;
                positionAttributes = {
                  position: {
                    distance,
                    x,
                  },
                  goalPosition: {
                    x: (goalPosition && goalPosition.x) || 0,
                    y: (goalPosition && goalPosition.y) || 0,
                  },
                  polarCoordinates: polarCoordinates(distance, x),
                };
              }
              return {
                team: observationWithTeam.team,
                personId: (observation.attributes_.personId as string) || '',
                person: observation.attributes_.person
                  ? observation.attributes_.person
                  : null,
                scored: observation.attributes_.result === 'GOAL',
                time: observation.startTime,
                type:
                  observation.code === 'GAME:SPECIAL'
                    ? ShotType.PENALTY
                    : ShotType.NORMAL,
                ...positionAttributes,
              };
            }
          );
      },
    ],
    fouls: [
      ['observationsWithTeam'],
      (resolveContext, observationsWithTeam: ObservationWithTeam[]): Foul[] => {
        return observationsWithTeam
          .filter(
            (observationWithTeam: ObservationWithTeam) =>
              observationWithTeam.observation.code === 'GAME:FOUL'
          )
          .map(
            (observationWithTeam: ObservationWithTeam): Foul => {
              return {
                team: observationWithTeam.team,
                time: observationWithTeam.observation.startTime,
              };
            }
          );
      },
    ],
    attacks: [
      ['shots', 'fouls', 'observationsWithTeam'],
      (
        resolveContext,
        shots: Shot[],
        fouls: Foul[],
        observationsWithTeam: ObservationWithTeam[]
      ): Attack[] => {
        // TODO: introduce state!!
        return observationsWithTeam
          .filter(
            (observationWithTeam: ObservationWithTeam) =>
              observationWithTeam.observation.code === 'POSSESSION'
          )
          .map(
            (observationWithTeam: ObservationWithTeam): Attack => {
              const { startTime, endTime } = observationWithTeam.observation;

              let result: AttackResult = AttackResult.TURNOVER;
              if (
                shots.filter(
                  (shot: Shot) =>
                    startTime <= shot.time &&
                    shot.time <= endTime &&
                    shot.scored
                ).length > 0
              ) {
                result = AttackResult.GOAL;
              } else if (
                fouls.filter(
                  (foul: Foul) => startTime <= foul.time && foul.time <= endTime
                ).length > 0
              ) {
                result = AttackResult.FOUL;
              }

              return {
                team: observationWithTeam.team,
                result,
                startTime,
                endTime,
              };
            }
          );
      },
    ],
  },
  queries: {
    shotsPerPlayer: [
      ['shots', 'persons'],
      (report: Report, shots: Shot[], persons: any) => {
        groupBy<Shot, string>(
          shots,
          (shot: Shot) => `${shot.team}:${shot.personId}`
        ).forEach((personShots: Shot[], teamPerson: string) => {
          const [team, personId] = teamPerson.split(':');
          const person = personShots[0].person || persons.get(personId);
          const name = person ? person.firstName : 'Onbekend';
          const playerReport = new PlayerReport(name);

          playerReport.penalties = aggregateToCell(
            personShots,
            shot => shot.type === ShotType.PENALTY
          );
          playerReport.shots_6_7m = aggregateToCell(
            personShots,
            (shot: Shot) =>
              shot.type === ShotType.NORMAL &&
              distance_to_label(shot.polarCoordinates.distance) === '6_7m'
          );
          playerReport.shots_7_9m = aggregateToCell(
            personShots,
            (shot: Shot) =>
              shot.type === ShotType.NORMAL &&
              distance_to_label(shot.polarCoordinates.distance) === '7_9m'
          );
          playerReport.shots_gt9m = aggregateToCell(
            personShots,
            (shot: Shot) =>
              shot.type === ShotType.NORMAL &&
              distance_to_label(shot.polarCoordinates.distance) === 'gt9m'
          );
          playerReport.shots_all = aggregateToCell(
            personShots,
            shot => shot.type === ShotType.NORMAL
          );

          playerReport.shots = personShots.map((shot: Shot) => {
            return new TypedCell(
              { scored: shot.scored },
              [
                {
                  startTime: shot.time - 5,
                  endTime: shot.time + 5,
                  description: 'Shot',
                  label: shot.scored ? 'positive' : undefined,
                },
              ],
              {
                position: (shot.position && {
                  x: shot.position.x,
                  distance: shot.position.distance,
                }) || { x: 0, distance: 0 },
                goalPosition: (shot.goalPosition && {
                  x: shot.goalPosition.x,
                  y: shot.goalPosition.y,
                }) || { x: 0, y: 0 },
              }
            );
          });
          (report as any)[team].playerReports.push(playerReport);
        });
      },
    ],
    position: [
      ['shots'],
      (report: Report, shots: Shot[]) => {
        groupBy<Shot, Team>(shots, (shot: Shot) => shot.team).forEach(
          (teamShots: Shot[], team: Team) => {
            groupBy<Shot, string>(teamShots, (shot: Shot) => {
              let position: string = '';
              switch (true) {
                case shot.polarCoordinates.angle < -54:
                  position = 'left';
                  break;
                case -54 <= shot.polarCoordinates.angle &&
                  shot.polarCoordinates.angle <= -18:
                  position = `left_center_${distance_to_label(
                    shot.polarCoordinates.distance
                  )}`;
                  break;
                case -18 < shot.polarCoordinates.angle &&
                  shot.polarCoordinates.angle < 18:
                  position = `center_${distance_to_label(
                    shot.polarCoordinates.distance
                  )}`;
                  break;
                case 18 <= shot.polarCoordinates.angle &&
                  shot.polarCoordinates.angle <= 54:
                  position = `right_center_${distance_to_label(
                    shot.polarCoordinates.distance
                  )}`;
                  break;
                case shot.polarCoordinates.angle > 54:
                  position = 'right';
                  break;
              }
              return position;
            }).forEach((teamShotsPerPosition: Shot[], position: string) => {
              (report[team].positionReport as any)[position] = new TypedCell(
                {
                  total: teamShotsPerPosition.length,
                  scored: teamShotsPerPosition.filter(
                    (shot: Shot) => shot.scored
                  ).length,
                },
                teamShotsPerPosition.map(
                  (shot: Shot): VideoFragment => {
                    return {
                      startTime: shot.time - 5,
                      endTime: shot.time + 5,
                      description: 'Shot',
                      label: shot.scored ? 'positive' : undefined,
                    };
                  }
                )
              );
            });
          }
        );
      },
    ],
    summary: [
      ['shots', 'attacks'],
      (report: Report, shots: Shot[], attacks: Attack[]) => {
        groupBy<Shot, Team>(shots, (shot: Shot) => shot.team).forEach(
          (shots: Shot[], team: Team) => {
            report[team].shots = new TypedCell(
              {
                total: shots.length,
                scored: shots.filter((shot: Shot) => shot.scored).length,
              },
              shots.map(
                (shot: Shot): VideoFragment => {
                  return {
                    startTime: shot.time - 5,
                    endTime: shot.time + 5,
                    description: 'Shot',
                    label: shot.scored ? 'positive' : undefined,
                  };
                }
              )
            );
          }
        );

        groupBy<Attack, Team>(attacks, (attack: Attack) => attack.team).forEach(
          (attacks: Attack[], team: Team) => {
            report[team].attackScores = new TypedCell(
              {
                total: attacks.length,
                scored: attacks.filter(
                  (attack: Attack) => attack.result === AttackResult.GOAL
                ).length,
              },
              attacks.map(
                (attack: Attack): VideoFragment => {
                  return {
                    startTime: attack.startTime - 2,
                    endTime: attack.endTime + 2,
                    description: 'Aanval',
                    label:
                      attack.result === AttackResult.GOAL
                        ? 'positive'
                        : undefined,
                  };
                }
              )
            );
            report[team].attackFouls = new TypedCell(
              {
                total: attacks.length,
                fouled: attacks.filter(
                  (attack: Attack) => attack.result === AttackResult.FOUL
                ).length,
              },
              attacks.map(
                (attack: Attack): VideoFragment => {
                  return {
                    startTime: attack.startTime - 2,
                    endTime: attack.endTime + 2,
                    description: 'Aanval',
                    label:
                      attack.result === AttackResult.FOUL
                        ? 'negative'
                        : undefined,
                  };
                }
              )
            );
          }
        );
      },
    ],
    metaData: [
      ['sportingEvent'],
      (report: Report, sportingEvent: any) => {
        report.metadata = {
          home: sportingEvent.homeTeam.label,
          away: sportingEvent.awayTeam.label,
        };
      },
    ],
  },
};
