import { ReportDefinition } from '../../ReportDefinition';
import { Report } from './Report';

import { IObservation } from '../../../ObservationTree/IObservation';
import { VideoFragment } from '../../VideoFragment';

import { TennisReducer } from '@teamtv/teamtv-match-state';

function groupBy<T, KT>(list: T[], keyGetter: (item: T) => KT): Map<KT, T[]> {
  const map = new Map<KT, T[]>();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const tennisReportDefinition: ReportDefinition<Report> = {
  dataSources: {
    observationLog: async sportingEvent => {
      // TODO: R1 should be fixed!!!
      const videoClock = sportingEvent.getVideoClock();
      const observationLog = sportingEvent.getObservationCollection(
        videoClock.clockId
      );
      await observationLog.fetchIfEmpty();

      return observationLog.toArray();
    },
    sportingEvent: sportingEvent => sportingEvent,
  },
  dataViews: {
    observationsWithState: [
      ['observationLog'],
      (
        resolveContext,
        observationLog: IObservation[]
      ): ObservationWithState[] => {
        const reducer = new TennisReducer(resolveContext.matchConfig);
        let matchState = reducer.get_state();

        let isError = false;

        return observationLog
          .map(
            (observation: IObservation): ObservationWithState => {
              try {
                reducer.reduce(observation.toJS());
              } catch (e) {
                isError = true;
              }
              if (isError) {
                return;
              }
              const tiebreakType: TiebreakType = matchState.tiebreakType;
              const { homeScore, awayScore } = matchState.score;

              const currentMatchState = matchState;

              matchState = reducer.get_state();

              const setCounter = homeScore.sets + awayScore.sets;
              const gameCounter = homeScore.games + awayScore.games;
              const pointCounter = homeScore.points + awayScore.points;
              const pointId = `point-${setCounter}-${gameCounter}-${pointCounter}`;

              return {
                observation,
                beforeEventMatchState: currentMatchState,
                afterEventMatchState: matchState,
                currentScore: currentMatchState.gameScore,
                pointId,
              };
            }
          )
          .filter(obj => !!obj);
      },
    ],
    rallies: [
      ['observationsWithState'],
      (
        resolveContext: any,
        observationsWithState: ObservationWithState[]
      ): RallyAggregate[] => {
        return Array.from(
          groupBy<ObservationWithState, string>(
            observationsWithState,
            ({ pointId }) => {
              return pointId;
            }
          ).values()
        ).map((rallyObservationsWithState: ObservationWithState[]) => {
          const lastObservation =
            rallyObservationsWithState[rallyObservationsWithState.length - 1];

          const {
            beforeEventMatchState: { server },
            afterEventMatchState: { rally },
          } = lastObservation;

          const winningParty = rally.isWonByServer
            ? server
            : server === 'HomeParty'
            ? 'AwayParty'
            : 'HomeParty';

          return {
            score: rallyObservationsWithState[0].currentScore,
            winningParty,
            startTime: rallyObservationsWithState[0].observation.startTime,
            endTime: lastObservation.observation.endTime,
            rallyObservationsWithState,
            server,
          };
        });
      },
    ],
    criticalPoints: [
      ['rallies'],
      (resolveContext: any, rallies: RallyAggregate[]): CriticalPoint[] => {
        return rallies.reduce(
          (result: CriticalPoint[], rally: RallyAggregate) => {
            //const lastStrokeInRally = rally.rallyObservationsWithState[rally.rallyObservationsWithState.length - 2];
            const winnerInfo =
              rally.rallyObservationsWithState[
                rally.rallyObservationsWithState.length - 1
              ];

            if (
              !!rally.rallyObservationsWithState[0].beforeEventMatchState
                .criticalPoint
            ) {
              const cp =
                rally.rallyObservationsWithState[0].beforeEventMatchState
                  .criticalPoint;
              const server =
                rally.rallyObservationsWithState[0].beforeEventMatchState
                  .server;
              const winnerParty = winnerInfo.afterEventMatchState.rally
                .isWonByServer
                ? server
                : server === 'HomeParty'
                ? 'AwayParty'
                : 'HomeParty';

              const criticalPoint = {
                type: cp.type_,
                party: cp.party,
                won: winnerParty === cp.party,
                startTime: rally.startTime,
                endTime: rally.endTime,
              };

              result.push(criticalPoint);
            }
            return result;
          },
          []
        );
      },
    ],
    strokes: [
      ['observationsWithState'],
      (
        resolveContext: any,
        observationsWithState: ObservationWithState[]
      ): Stroke[] => {
        return observationsWithState.reduce(
          (result: Stroke[], observationWithState: ObservationWithState) => {
            const {
              code,
              attributes_: attributes,
              startTime,
              endTime,
            } = observationWithState.observation;
            const rally: Rally =
              observationWithState.afterEventMatchState.rally;
            const matchState = observationWithState.beforeEventMatchState;

            let strokeAttribute: string = '';

            switch (code) {
              case 'ENDPOINT':
                result[result.length - 1].result =
                  rally.endpoint === 'Winner' ? 'in' : 'out';
                break;

              case 'SERVICE-ERROR':
                result[result.length - 1].result = 'out';
                break;
              case 'SERVICE':
                if (
                  result.length > 0 &&
                  result[result.length - 1].type == 'SERVICE'
                ) {
                  result[result.length - 1].result = 'out';
                }
                strokeAttribute = attributes.type as string;
              // fallthrough is intentional
              default:
                strokeAttribute =
                  strokeAttribute || (attributes.stroke as string);
                const stroke: Stroke = {
                  party: rally.isLastShotOfServer
                    ? matchState.server
                    : matchState.server === 'HomeParty'
                    ? 'AwayParty'
                    : 'HomeParty',
                  result: 'in',
                  type: code,
                  attribute: strokeAttribute,
                  startTime: startTime,
                  endTime: endTime,
                };
                result.push(stroke);
            }
            return result;
          },
          []
        );
      },
    ],
  },
  queries: {
    ralliesWonOnCriticalPointsPerParty: [
      ['criticalPoints'],
      (report: Report, criticalPoints: CriticalPoint[]) => {
        let totalHomeCriticalPoints = 0;
        let totalHomeCriticalPointsWon = 0;
        let totalAwayCriticalPoints = 0;
        let totalAwayCriticalPointsWon = 0;
        groupBy<CriticalPoint, CriticalPointType>(
          criticalPoints,
          (criticalPoint: CriticalPoint) => {
            return criticalPoint.type;
          }
        ).forEach(
          (criticalPoints: CriticalPoint[], type: CriticalPointType) => {
            const criticalPointsPerParty = groupBy<CriticalPoint, String>(
              criticalPoints,
              (criticalPoint: CriticalPoint) => {
                return criticalPoint.party;
              }
            );

            const homeCriticalPoints =
              criticalPointsPerParty.get('HomeParty') || [];
            const homeCriticalPointsWon = homeCriticalPoints.filter(
              criticalPoint => criticalPoint.won
            );
            totalHomeCriticalPointsWon += homeCriticalPointsWon.length;
            totalHomeCriticalPoints += homeCriticalPoints.length;

            const awayCriticalPoints =
              criticalPointsPerParty.get('AwayParty') || [];
            const awayCriticalPointsWon = awayCriticalPoints.filter(
              criticalPoint => criticalPoint.won
            );
            totalAwayCriticalPointsWon += awayCriticalPointsWon.length;
            totalAwayCriticalPoints += awayCriticalPoints.length;

            report.setCell(
              `criticalPoints.HomeParty.${type}`,
              {
                total: homeCriticalPoints.length,
                won: homeCriticalPointsWon.length,
              },
              homeCriticalPoints.map<VideoFragment>(
                (criticalPoint: CriticalPoint) => {
                  return {
                    startTime: criticalPoint.startTime,
                    endTime: criticalPoint.endTime,
                    description: 'Critical point',
                    label: criticalPoint.won ? 'success' : undefined,
                  };
                }
              )
            );

            report.setCell(
              `criticalPoints.AwayParty.${type}`,
              {
                total: awayCriticalPoints.length,
                won: awayCriticalPointsWon.length,
              },
              awayCriticalPoints.map<VideoFragment>(
                (criticalPoint: CriticalPoint) => {
                  return {
                    startTime: criticalPoint.startTime,
                    endTime: criticalPoint.endTime,
                    description: 'Critical point',
                    label: criticalPoint.won ? 'success' : undefined,
                  };
                }
              )
            );
          }
        );
        report.setCell(
          'criticalPoints.HomeParty',
          {
            won: totalHomeCriticalPointsWon,
            total: totalHomeCriticalPoints,
          },
          []
        );
        report.setCell(
          'criticalPoints.AwayParty',
          {
            won: totalAwayCriticalPointsWon,
            total: totalAwayCriticalPoints,
          },
          []
        );
        report.setCell(
          'criticalPoints',
          {
            won: totalHomeCriticalPoints + totalAwayCriticalPoints,
            total: totalHomeCriticalPoints + totalAwayCriticalPoints,
          },
          []
        );
      },
    ],
    ralliesWonPerPartyPerScore: [
      ['rallies'],
      (report: Report, rallies: RallyAggregate[]) => {
        groupBy<RallyAggregate, string>(rallies, (rally: RallyAggregate) => {
          return rally.score;
        }).forEach((rallies: RallyAggregate[], score: string) => {
          for (const server of ['ALL', 'HomeParty', 'AwayParty']) {
            const ralliesServer = rallies.filter(
              rally => rally.server === server || server === 'ALL'
            );
            // if (server !== 'ALL') {
            //   debugger;
            // }

            const ralliesPerWinningParty = groupBy<RallyAggregate, Party>(
              ralliesServer,
              (rally: RallyAggregate) => {
                return rally.winningParty;
              }
            );
            const homeWinningRallies =
              ralliesPerWinningParty.get('HomeParty') || [];
            const awayWinningRallies =
              ralliesPerWinningParty.get('AwayParty') || [];

            const total = ralliesServer.length;
            report.setCell(
              `scores.HomeParty.server:${server}.${score}`,
              {
                total,
                won: homeWinningRallies.length,
              },
              homeWinningRallies.map<VideoFragment>((rally: RallyAggregate) => {
                return {
                  startTime: rally.startTime,
                  endTime: rally.endTime,
                  description: 'Rally',
                };
              })
            );
            report.setCell(
              `scores.AwayParty.server:${server}.${score}`,
              {
                total,
                won: awayWinningRallies.length,
              },
              awayWinningRallies.map<VideoFragment>((rally: RallyAggregate) => {
                return {
                  startTime: rally.startTime,
                  endTime: rally.endTime,
                  description: 'Rally',
                };
              })
            );
          }
        });
      },
    ],
    rallyLengthPerParty: [
      ['rallies'],
      (report: Report, rallies: RallyAggregate[]) => {
        let homeTotalRalliesWon = 0;
        let awayTotalRalliesWon = 0;
        groupBy<RallyAggregate, string>(
          rallies,
          (rallyAggregate: RallyAggregate) => {
            const {
              afterEventMatchState: { rally },
            } = rallyAggregate.rallyObservationsWithState[
              rallyAggregate.rallyObservationsWithState.length - 1
            ];
            if (rally.length < 5) return '1-4';
            if (rally.length < 9) return '5-8';
            return '9+';
          }
        ).forEach((rallies: RallyAggregate[], lengthClass: string) => {
          const ralliesPerWinningParty = groupBy<RallyAggregate, String>(
            rallies,
            (rallyAggregate: RallyAggregate) => {
              return rallyAggregate.winningParty;
            }
          );

          const homeWinningRallies =
            ralliesPerWinningParty.get('HomeParty') || [];
          const awayWinningRallies =
            ralliesPerWinningParty.get('AwayParty') || [];

          homeTotalRalliesWon += homeWinningRallies.length;
          awayTotalRalliesWon += awayWinningRallies.length;

          const total = rallies.length;
          report.setCell(
            `rallyLength.${lengthClass}`,
            {
              total: total,
            },
            []
          );

          report.setCell(
            `rallyLength.HomeParty.${lengthClass}`,
            {
              total,
              won: homeWinningRallies.length,
            },
            homeWinningRallies.map<VideoFragment>((rally: RallyAggregate) => {
              return {
                startTime: rally.startTime,
                endTime: rally.endTime,
                description: 'Rally',
              };
            })
          );

          report.setCell(
            `rallyLength.AwayParty.${lengthClass}`,
            {
              total,
              won: awayWinningRallies.length,
            },
            awayWinningRallies.map<VideoFragment>((rally: RallyAggregate) => {
              return {
                startTime: rally.startTime,
                endTime: rally.endTime,
                description: 'Rally',
              };
            })
          );
        });

        report.setCell(
          'rallyLength.HomeParty',
          {
            won: homeTotalRalliesWon,
            total: awayTotalRalliesWon + homeTotalRalliesWon,
          },
          []
        );

        report.setCell(
          'rallyLength.AwayParty',
          {
            won: awayTotalRalliesWon,
            total: awayTotalRalliesWon + homeTotalRalliesWon,
          },
          []
        );
      },
    ],
    strokesPerPartyPerStrokeType: [
      ['strokes'],
      (report: Report, strokes: Stroke[]) => {
        groupBy<Stroke, string>(strokes, (stroke: Stroke) => {
          return `${stroke.party}:${stroke.type}`;
        }).forEach((partyTypeStrokes: Stroke[], key: string) => {
          // super ugly fix around JS Map compare (wanted to use Object instead)
          const [party, type] = key.split(':');

          const strokesIn = partyTypeStrokes.filter(
            stroke => stroke.result === 'in'
          );

          // 1. set totals
          report.setCell(
            `strokes.${party}.${type}`,
            {
              total: partyTypeStrokes.length,
              won: strokesIn.length,
            },
            strokesIn.map<VideoFragment>((stroke: Stroke) => {
              return {
                startTime: stroke.startTime - 1,
                endTime: stroke.endTime + 1,
                description: type,
              };
            })
          );

          // 2. breakdown per attribute
          groupBy<Stroke, string>(
            partyTypeStrokes,
            stroke => stroke.attribute
          ).forEach((strokesPerAttribute: Stroke[], attribute: string) => {
            const strokesIn = strokesPerAttribute.filter(
              stroke => stroke.result === 'in'
            );

            report.setCell(
              `strokes.${party}.${type}.${attribute}`,
              {
                total: strokesPerAttribute.length,
                won: strokesIn.length,
              },
              strokesIn.map<VideoFragment>((stroke: Stroke) => {
                return {
                  startTime: stroke.startTime - 1,
                  endTime: stroke.endTime + 1,
                  description: attribute,
                };
              })
            );
          });
        });
      },
    ],
    matchEndingMatchState: [
      ['observationsWithState', 'strokes', 'rallies'],
      (
        report: Report,
        observationsWithState: ObservationWithState[],
        strokes: Stroke[],
        rallies: RallyAggregate[]
      ) => {
        const homeWinningRallies = rallies.filter(rally => {
          return rally.winningParty === 'HomeParty';
        });

        const awayWinningRallies = rallies.filter(rally => {
          return rally.winningParty === 'AwayParty';
        });

        report.setCell(
          `match.HomeParty.points`,
          {
            total: homeWinningRallies.length + awayWinningRallies.length,
            won: homeWinningRallies.length,
          },
          homeWinningRallies.map<VideoFragment>((rally: RallyAggregate) => {
            return {
              startTime: rally.startTime,
              endTime: rally.endTime,
              description: 'Point',
            };
          })
        );
        report.setCell(
          `match.AwayParty.points`,
          {
            total: homeWinningRallies.length + awayWinningRallies.length,
            won: awayWinningRallies.length,
          },
          awayWinningRallies.map<VideoFragment>((rally: RallyAggregate) => {
            return {
              startTime: rally.startTime,
              endTime: rally.endTime,
              description: 'Point',
            };
          })
        );

        groupBy<Stroke, Party>(strokes, (stroke: Stroke) => {
          return stroke.party;
        }).forEach((strokes: Stroke[], party: Party) => {
          const strokeResults = groupBy<Stroke, string>(
            strokes,
            (stroke: Stroke) => {
              return stroke.result;
            }
          );

          const strokesOutResults = strokeResults.get('out') || [];
          const strokesInResults = strokeResults.get('in') || [];

          report.setCell(
            `match.${party}.strokes`,
            {
              total: strokesInResults.length + strokesOutResults.length,
              in: strokesInResults.length,
            },
            strokesInResults.map<VideoFragment>((stroke: Stroke) => {
              return {
                startTime: stroke.startTime - 1,
                endTime: stroke.endTime + 1,
                description: 'in',
              };
            })
          );
        });

        // debugger;

        let setscoreDescription = '';

        let homeSetsWon = 0;
        let awaySetsWon = 0;
        let homeGamesWon = 0;
        let awayGamesWon = 0;

        if (observationsWithState.length > 0) {
          // Nu wil ik de laatste observation hebben
          // Daar ga ik dan de sets, Games, Points ?, Strokes ? uit halen.
          const lastObservationWithState =
            observationsWithState[observationsWithState.length - 1];
          const lastMatchState: MatchState =
            lastObservationWithState.afterEventMatchState;

          const homeSetScore: SetScore[] =
            lastMatchState.score.homeScore.setScores;
          const awaySetScore: SetScore[] =
            lastMatchState.score.awayScore.setScores;

          homeGamesWon = lastMatchState.score.homeScore.games;
          awayGamesWon = lastMatchState.score.awayScore.games;

          for (const setScore in homeSetScore) {
            if (homeSetScore[setScore].games > awaySetScore[setScore].games) {
              homeSetsWon += 1;
            } else {
              awaySetsWon += 1;
            }
            homeGamesWon += homeSetScore[setScore].games;
            awayGamesWon += awaySetScore[setScore].games;
            setscoreDescription +=
              homeSetScore[setScore].games +
              '-' +
              awaySetScore[setScore].games +
              '  ';
          }
        }
        //const totalGames = homeGamesWon + awayGamesWon;

        report.setMetaData('setscores', setscoreDescription);

        report.setCell(
          `match.HomeParty.sets`,
          {
            total: homeSetsWon + awaySetsWon,
            won: homeSetsWon,
          },
          []
        );
        report.setCell(
          `match.AwayParty.sets`,
          {
            total: homeSetsWon + awaySetsWon,
            won: awaySetsWon,
          },
          []
        );

        report.setCell(
          `match.HomeParty.games`,
          {
            total: homeGamesWon + awayGamesWon,
            won: homeGamesWon,
          },
          []
        );
        report.setCell(
          `match.AwayParty.games`,
          {
            total: homeGamesWon + awayGamesWon,
            won: awayGamesWon,
          },
          []
        );
      },
    ],
    metaData: [
      ['sportingEvent'],
      (report: Report, sportingEvent: any) => {
        report.setMetaData('HomeParty.player', sportingEvent.homeTeam.label);
        report.setMetaData('AwayParty.player', sportingEvent.awayTeam.label);

        report.setMetaData(
          'HomeParty.player.shortName',
          sportingEvent.homeTeam.shortLabel
        );
        report.setMetaData(
          'AwayParty.player.shortName',
          sportingEvent.awayTeam.shortLabel
        );
      },
    ],
  },
};
