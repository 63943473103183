import uuidv4 from 'uuid/v4';

import { EventHandler, CommandBus, Command } from '../../infra/Messaging';
import { ObservationContext } from './ObservationContext';

class ObservationLoggerEventHandler extends EventHandler {
  understoodTypes() {
    return [
      'ObservationLogger.RangeObservationEnded',
      'ObservationLogger.ObservationUpdated',
    ];
  }

  doHandle(event) {
    switch (event.eventType) {
      case 'ObservationLogger.RangeObservationEnded': {
        const observationLog = ObservationContext.instance().observationLog;
        const observationId = observationLog.nextIdentity();
        const clockId = ObservationContext.instance().clock.clockId();

        const command = Command.create('ObservationLog.AddObservation', {
          clockId,
          observationId: observationId,
          code: event.code,
          attributes: event.attributes,
          triggerTime: event.triggerTime,
          startTime: event.startTime,
          endTime: event.endTime,
          description: event.description,
        });
        CommandBus.dispatch(command);
        break;
      }
      case 'ObservationLogger.ObservationUpdated': {
        const command = Command.create('ObservationLog.UpdateObservation', {
          observationId: event.observationId,
          attributes: event.attributes,
          description: event.description,
          clockId: event.clockId,
          triggerTime: event.triggerTime,
          startTime: event.startTime,
          endTime: event.endTime,
        });
        CommandBus.dispatch(command);
        break;
      }
      default:
        return null;
    }
    return true;
  }
}

export default () => {
  new ObservationLoggerEventHandler();
};
