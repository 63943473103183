// Step 4
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import _ from 'lodash';
import Loading from 'lib/Loading';
import { Error } from '../index';
import { InputCheckboxRadio } from '../../../InputCheckBoxRadio';

export const UserInquiryForm = ({ stepHandler, formData, setFormData }) => {
  const [value, setValue] = useState({
    role: window.isSkillReflect ? '__DEFAULT__' : formData?.role, // Add default value in case of skillReflect (no roletypes)
  });
  const [isChecking, setIsChecking] = useState(false);
  const { t } = useTranslation('registerPage');
  const [error, setError] = useState();
  const checkValue = async () => {
    setError(false);
    setIsChecking(true);
    if (!!value.role) {
      setFormData((o) => {
        return { ...o, ...{ role: value.role, inquiry: value.inquiry } };
      });
      stepRef.current.classList.add('animateOut');

      setTimeout(stepHandler.next(), 500);
    } else {
      setError({
        title: t('common.form:required-field'),
      });
      setIsChecking(false);
    }
  };

  const roleTypeOptions = [
    { value: 'trainer', label: t('roleType:trainer') },
    { value: 'coach', label: t('roleType:coach') },
    { value: 'player', label: t('roleType:player') },
    { value: 'analyst', label: t('roleType:analyst') },
    { value: 'camera', label: t('roleType:camera') },
    { value: 'treasurer', label: t('roleType:treasurer') },
    { value: 'technicalCommission', label: t('roleType:technicalCommission') },
    { value: 'media', label: t('roleType:media') },
    { value: 'livestreaming', label: t('roleType:livestreaming') },
    { value: 'advisor', label: t('roleType:advisor') },
  ];

  const setInQuiry = (inquiry) => {
    setValue((o) => {
      return { ...o, inquiry: inquiry };
    });
  };

  const stepRef = useRef();

  return (
    <form
      data-test-id={'step-inquiry'}
      ref={stepRef}
      className={'userlogin-form-step'}
      onSubmit={(e) => {
        e.preventDefault();
        if (!isChecking) {
          checkValue();
        }
      }}
    >
      <h1>{t('inquiry.title')}</h1>
      <p>{t('inquiry.subTitle')}</p>

      <div className={'userlogin-form-input'}>
        <h5>{t('form.role', { clubName: formData.tenantName })}</h5>
        <Select
          options={roleTypeOptions}
          isClearable={false}
          required={'required'}
          className="userloginform__select"
          classNamePrefix="userloginform__select"
          placeholder={t('form.role', { clubName: formData.tenantName })}
          value={roleTypeOptions.find((o) => o.value === formData?.role)}
          onChange={(option) =>
            setValue((o) => {
              return { ...o, ...{ role: option.value } };
            })
          }
        />
      </div>
      {error && <Error title={error.title}></Error>}
      <div className="mt-4"></div>
      <div className={'userlogin-form-input'}>
        <h5>{t('inquiry.form')}</h5>
        <CheckBoxes
          onChange={(v) => {
            setInQuiry(v);
          }}
          initialValues={formData?.inquiry}
        />
      </div>

      <div className={'userlogin-form-actions'}>
        <button type={'submit'} className={'btn btn-primary'}>
          {t('form.nextStep')}
          {isChecking && (
            <Loading type={'spinner'} color={'white'} size={16}></Loading>
          )}
        </button>
      </div>
    </form>
  );
};

const CheckBoxes = ({ onChange, initialValues }) => {
  let options = [
    'analysis',
    'tagging',
    'automation',
    'highlights',
    'livestreaming',
    'statistics',
    'play',
    'camera',
    'draw',
    'individualCoaching',
    'other',
  ];
  const { t } = useTranslation('registerPage');

  const setInitialValues = () => {
    let output = {};
    options.forEach((option) => {
      output[option] = false;
    });
    return output;
  };
  const [selectedOptions, setSelectedOptions] = useState(
    initialValues ?? setInitialValues()
  );

  const _onchange = (option, value = null) => {
    setSelectedOptions((orig) => {
      onChange({ ...orig, [option]: value ?? !selectedOptions[option] });
      return { ...orig, [option]: value ?? !selectedOptions[option] };
    });
  };

  return (
    <>
      {options.map((option) => {
        return (
          <InputCheckboxRadio
            key={option}
            type={'checkbox'}
            value={option}
            label={t(`inquiry.${option}`)}
            checked={selectedOptions[option]}
            onChange={() => {
              _onchange(option);
            }}
          />
        );
      })}
      {selectedOptions['other'] && (
        <div className="userlogin-form-input">
          <input
            type={'text'}
            placeholder={'Please tell us why'}
            id={'userlogin-inquiry'}
            value={selectedOptions['otherExplanation']}
            onChange={(e) => _onchange('otherExplanation', e.target.value)}
          />
          <label htmlFor={'userlogin-inquiry'}></label>
        </div>
      )}
    </>
  );
};
