import {
  AbsoluteFill,
  Easing,
  interpolate,
  useCurrentFrame,
  useVideoConfig,
  Video,
} from 'remotion';
import replayOverlay from './Instant Replay Overlay.webm';
import { transitionDurationInSeconds } from '../../helpers/preprocess-content';
import zIndexes from '../../helpers/zIndexes.json';

export const ReplayOverlay = ({ clip, nextClip, durationInFrames }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const startFadeInAt =
    (clip?.transition &&
      (transitionDurationInSeconds(clip.transition) / 2) * fps) ??
    1;
  const startFadeOutAt =
    (nextClip?.transition &&
      (transitionDurationInSeconds(nextClip.transition) / 2) * fps) ??
    1;
  const fadeInOutDuration = 20;
  const nextClipIsReplay = nextClip?.effects?.includes('replay');

  const fadeIn = clip?.transition ? 0 : 1;
  const fadeOut = !nextClipIsReplay || nextClip?.transition ? 0 : 1;

  const opacity = interpolate(
    frame,
    [
      startFadeInAt,
      startFadeInAt + fadeInOutDuration,
      durationInFrames - startFadeOutAt - fadeInOutDuration,
      durationInFrames - startFadeOutAt,
    ],
    [fadeIn, 1, 1, fadeOut],
    {
      easing: Easing.inOut(Easing.ease),
      extrapolateRight: 'clamp',
      extrapolateLeft: 'clamp',
    }
  );
  return (
    <AbsoluteFill style={{ opacity: opacity, zIndex: zIndexes.overlay - 1 }}>
      <Video src={replayOverlay} loop />
    </AbsoluteFill>
  );
};
