function debounce(func, wait, immediate = false) {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

class TouchInputHelper {
  constructor(onTap, onLongTap, onMove, clock) {
    this.startTime = null;
    this.onTap = onTap;
    this.onLongTap = onLongTap;
    this.onMove = onMove;
    this.clock = clock;

    this.events = [];
  }

  timerValue() {
    return this.clock.getTime();
  }

  getTouchStartEnd(timeWindow = 0.3) {
    if (this.events.length < 2) {
      return null;
    }
    const firstEvent = this.events[0];
    const lastEvent = this.events[this.events.length - 1];
    if (firstEvent.name !== 'touchStart') {
      return null;
    }
    if (lastEvent.name !== 'touchEnd' || lastEvent.touchCount !== 0) {
      return null;
    }
    let startTouchCount = null;
    let endTouchCount = null;
    let currentCount = null;

    for (const event of this.events) {
      if (
        event.name === 'touchStart' &&
        event.timestamp < firstEvent.timestamp + timeWindow
      ) {
        startTouchCount = event.touchCount;
      }
      if (
        event.name === 'touchEnd' &&
        event.timestamp > lastEvent.timestamp - timeWindow
      ) {
        if (endTouchCount === null) {
          endTouchCount = currentCount;
        }
      }

      currentCount = event.touchCount;
    }
    const startEvents = this.events.filter(event => {
      return (
        event.name === 'touchStart' &&
        event.timestamp < firstEvent.timestamp + timeWindow
      );
    });
    const endEvents = this.events.filter(event => {
      return (
        event.name === 'touchEnd' &&
        event.timestamp > lastEvent.timestamp - timeWindow
      );
    });

    const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

    return {
      start: {
        touchCount: startTouchCount,
        x: average(startEvents.map(event => event.x)),
        y: average(startEvents.map(event => event.y)),
        timestamp: firstEvent.timestamp,
      },
      end: {
        touchCount: endTouchCount,
        x: average(endEvents.map(event => event.x)),
        y: average(endEvents.map(event => event.y)),
        timestamp: lastEvent.timestamp,
      },
    };
  }

  processEvents = debounce(() => {
    // find first startTouch
    const touches = this.getTouchStartEnd();
    if (touches !== null) {
      this.handleTouches(touches.start, touches.end);
      this.events = [];
    }
  }, 150);

  addEvent(event) {
    this.events.push(event);
    this.processEvents();
  }

  getTouchEvent(name, touchEvent) {
    return {
      name,
      timestamp: this.timerValue(),
      x: touchEvent.x,
      y: touchEvent.y,
      touchCount: touchEvent.touchCount,
    };
  }

  onTouchStart(touchEvent) {
    this.addEvent(this.getTouchEvent('touchStart', touchEvent));
  }

  replacePassStart(p) {
    if (this.passStart !== null) {
      this.passStart = p;
    }
  }

  onTouchEnd(touchEvent) {
    this.addEvent(this.getTouchEvent('touchEnd', touchEvent));
  }

  handleTouches(touchStart, touchEnd) {
    const timeDelta = touchEnd.timestamp - touchStart.timestamp;
    const movement = Math.sqrt(
      (touchEnd.x - touchStart.x) ** 2 + (touchEnd.y - touchStart.y) ** 2
    );

    if (movement < 0.1) {
      if (timeDelta < 0.3) {
        this.onTap(touchStart, touchEnd);
      } else {
        this.onLongTap(touchStart, touchEnd, timeDelta);
      }
    } else {
      this.onMove(touchStart, touchEnd, timeDelta);
    }
  }
}

export { TouchInputHelper };
