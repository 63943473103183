import { useRef, useState } from 'react';
import Loading from '../Loading';
import { MentionsInput, Mention } from 'react-mentions';
import MentionStyle from './mentionStyle';

export const ChatBoxInput = ({ parentRef, onAddMessage, members = [] }) => {
  const [message, setMessage] = useState();
  const [messageHTML, setMessageHTML] = useState();
  const [mentions, setMentions] = useState();
  const [isSending, setIsSending] = useState(false);

  const inputEl = useRef();

  const resizeTextInput = (element) => {
    // Resize textinput element
    let defaultHeight = 36;
    let maxHeight = 150;

    let chatInput =
      inputEl.current || element.parentElement.parentElement.parentElement;
    let chatBox =
      parentRef.current || document.querySelector('.chatbox__wrapper');

    // Resize chat window when textarea grows.
    element.style.height = defaultHeight + 'px';
    if (element.scrollHeight > element.clientHeight) {
      element.style.height = 'auto';
      element.style.height =
        Math.min(element.scrollHeight + 2, maxHeight) + 'px'; // +2px to negate border
      chatBox.style.paddingBottom = chatInput.scrollHeight + 'px';
    }
    chatBox.style.paddingBottom = chatInput.scrollHeight + 'px';
  };

  const handleMentionInput = (event, newValue, newPlainTextValue, mentions) => {
    setMessage(newValue.replace(/^\s+$/g, '').trimStart()); // Replace beginning newlines and empty characters
    setMessageHTML(newValue.replace(/^\s+$/g, '').trimStart()); // Replace beginning newlines and empty characters
    setMentions(mentions.map((m) => m.id));
    // Resize textinput element
    let element = inputEl.current.querySelector('textarea'); // Refs cannot be passed due to bug. https://github.com/signavio/react-mentions/issues/411
    resizeTextInput(element);
  };

  const sendMessage = () => {
    if (!isSending) {
      setIsSending(true);
      if (!messageHTML) {
        setIsSending(false);
        return;
      }
      if (messageHTML) {
        setMessage(messageHTML.replace(/^\s+|\s+$/g, '').trim()); // Message without empty characters before and after
        onAddMessage(
          messageHTML.replace(/^\s+|\s+$/g, '').trim(),
          mentions
        ).then(() => {
          clearState();
        });
      }
    }
  };

  const clearState = () => {
    // Clean state for new message
    setMessage('');
    setMessageHTML('');
    setMentions([]);
    setIsSending(false);
    resizeTextInput(document.querySelector('.chatbox__input textarea'));
  };

  return (
    <div className="chatbox__input" ref={inputEl}>
      <MentionsInput
        style={MentionStyle}
        value={messageHTML}
        placeholder={'Enter message..'}
        allowSuggestionsAboveCursor={true}
        forceSuggestionsAboveCursor={true}
        a11ySuggestionsListLabel={'Suggested mentions'}
        onChange={(event, newValue, newPlainTextValue, mentions) =>
          handleMentionInput(event, newValue, newPlainTextValue, mentions)
        }
      >
        <Mention
          trigger="@"
          data={members}
          markup="{{__id__}}"
          displayTransform={(id) =>
            `@${members?.find((u) => u.id === id).display}`
          }
          appendSpaceOnAdd
          style={{
            backgroundColor: '#e3fff1',
            borderRadius: '2px',
            outline: 'solid 2px #93d3b3',
          }}
        />
      </MentionsInput>
      <button
        className={'button--mention'}
        onClick={() => {
          inputEl.current.querySelector('textarea').focus();
          setMessageHTML((messageHTML ? messageHTML : '') + '@');
        }}
      >
        @
      </button>
      <button className={`${message ? '' : 'disabled'}`} onClick={sendMessage}>
        {isSending && <Loading size={18} type={'spinner'} />}
        {!isSending && <i className="i-send"></i>}
      </button>
    </div>
  );
};
