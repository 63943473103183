import { useMutation } from 'react-query';
import { delete_ as deleteObservation } from '../../../../domain/Observation';

export const GetObservationDeleteMutation = queryClient => {
  return useMutation(deleteObservation, {
    onMutate: async ({ observationId }) => {
      const queryKey = 'observations';

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      // Snapshot the previous value
      const observationsCached = queryClient.getQueryData('observations');
      // Optimistically update to the new value
      const idx = observationsCached.findIndex(
        obs => obs.observationId === observationId
      );
      const observationsUpdated = [
        ...observationsCached.slice(0, idx),
        ...observationsCached.slice(idx + 1),
      ];

      queryClient.setQueryData(queryKey, observationsUpdated);

      return { previous: observationsCached, current: observationsUpdated };
    },
  });
};
