import { observable, action } from 'mobx';
import { now } from 'mobx-utils';

class MatchClockHelper {
  static getClockByClockId(clockOwner, clockId, videoFirst = false) {
    if (clockId instanceof Clock) {
      return clockId;
    }

    if (clockId === 'main') {
      clockId = this._getMainClockId(clockOwner, videoFirst);
    }
    for (const [source, clock] of Object.entries(clockOwner.clocks())) {
      if (clock.clockId === clockId) {
        switch (clockId) {
          case 'U1':
            return new LiveClock(clockId, clock.synchronizationPoints);
          default:
            return new VideoClock(clockId, clock.synchronizationPoints);
        }
      }
    }
    throw 'Clock not found';
  }

  static _getMainClockId(clockOwner, videoFirst = false) {
    // Get clock where synchronization points are added. When
    // there is no clock, return live clock
    const clocks = Object.entries(clockOwner.clocks());
    if (videoFirst) {
      clocks.reverse();
    }
    for (const [source, clock] of clocks) {
      if (
        clock.synchronizationPoints &&
        clock.synchronizationPoints.length > 0
      ) {
        return clock.clockId;
      }
    }
    return 'U1';
  }

  static getCurrentPeriod(clockOwner, clock) {
    const synchronizationPoints = clock
      .synchronizationPoints()
      .filter((synchronizationPoint) => {
        return (
          (synchronizationPoint.type === 'START_PERIOD' ||
            synchronizationPoint.type === 'END_PERIOD') &&
          (clock.isLive() ||
            synchronizationPoint.time <= clock.mapTime(clock.getTime()))
        );
      });
    synchronizationPoints.sort((a, b) => {
      return a.time === b.time ? 0 : a.time < b.time ? -1 : 1;
    });
    const periods = {};
    for (const { type, key: periodNr, time } of synchronizationPoints) {
      if (type === 'START_PERIOD') {
        periods[periodNr] = { state: 'START', startTime: time };
      } else if (type === 'END_PERIOD') {
        periods[periodNr] = {
          ...periods[periodNr],
          state: 'END',
          endTime: time,
        };
      }
    }

    let res;
    for (let periodNr = clockOwner.periodCount; periodNr >= 1; periodNr--) {
      if (typeof periods[periodNr] === 'undefined') {
        res = ['NOT_STARTED', periodNr];
      } else if (periods[periodNr].state === 'START') {
        res = ['STARTED', periodNr, periods[periodNr].startTime];
        break;
      } else if (periods[periodNr].state === 'END') {
        if (periodNr < clockOwner.periodCount) {
          res = ['NOT_STARTED', periodNr + 1];
        } else {
          res = [
            'ENDED',
            periodNr,
            periods[periodNr].startTime,
            periods[periodNr].endTime,
          ];
        }
        break;
      }
    }
    return res;
  }

  static getCurrentState(clockOwner, clockId = 'main') {
    const clock = this.getClockByClockId(clockOwner, clockId);
    const [currentState, periodNr, startTime, endTime] = this.getCurrentPeriod(
      clockOwner,
      clock
    );

    return currentState;
  }

  static getPeriodLength(clockOwner, periodNr, clockId = 'main') {
    const clock = this.getClockByClockId(clockOwner, clockId);
    let startTime, endTime;
    for (const {
      type,
      key: periodNr_,
      time,
    } of clock.synchronizationPoints()) {
      if (parseInt(periodNr_) === periodNr) {
        if (type === 'START_PERIOD') {
          startTime = time;
        } else if (type === 'END_PERIOD') {
          endTime = time;
        }
      }
    }
    return clock.secondsElapsedBetween(startTime, endTime);
  }

  static getCurrentTimeFn(
    clockOwner,
    clockId = 'main',
    timeObservationsFn = null,
    withPeriod = false
  ) {
    const clock = this.getClockByClockId(clockOwner, clockId);

    return () => {
      const [currentState, periodNr, startTime, endTime] =
        this.getCurrentPeriod(clockOwner, clock);
      const makeTime = (time) => {
        if (withPeriod) {
          return [periodNr, time];
        } else {
          return time;
        }
      };
      let timeFn;
      switch (currentState) {
        case 'NOT_STARTED':
          if (periodNr > 1) {
            return makeTime('RUST');
          } else {
            return makeTime('START');
          }
          break;
        case 'STARTED':
          let timeObservations = [];
          if (timeObservationsFn !== null) {
            timeObservations = timeObservationsFn();
          }

          const currentPeriodTimeObservations = timeObservations.filter(
            (observation) => {
              return makeTime(observation.startTime >= startTime);
            }
          );
          let timeCorrection = 0;
          let pauseTime = null;
          for (const timeObservation of currentPeriodTimeObservations) {
            switch (timeObservation.code) {
              case 'TIME:PAUSE':
                if (pauseTime === null) {
                  pauseTime = timeObservation.startTime;
                }
                break;
              case 'TIME:RESUME':
                if (pauseTime !== null) {
                  timeCorrection -= clock.secondsElapsedBetween(
                    pauseTime,
                    timeObservation.startTime
                  );
                  pauseTime = null;
                }
                break;

              case 'TIME:CORRECTION':
                timeCorrection += timeObservation.attributes_.seconds;
                break;
            }
          }

          let baseTime;
          if (pauseTime === null) {
            baseTime = clock.secondsElapsedSince(startTime);
          } else {
            baseTime = clock.secondsElapsedBetween(startTime, pauseTime);
          }
          return makeTime(baseTime + timeCorrection);
          break;
        case 'ENDED':
          return makeTime('EINDE');
          break;
      }
    };
  }

  static isStarted(clockOwner, clockId = 'main') {
    const clock = this.getClockByClockId(clockOwner, clockId);

    const [currentState, periodNr, startTime, endTime] = this.getCurrentPeriod(
      clockOwner,
      clock
    );
    return (
      currentState === 'STARTED' ||
      (currentState === 'NOT_STARTED' && periodNr > 1)
    );
  }

  static isEnded(clockOwner, clockId = 'main') {
    return this.getCurrentState(clockOwner, clockId) === 'ENDED';
  }
}

class TimeMapException {
  constructor(message) {
    this.message = message;
  }
}

class ObservationLogProjection {
  constructor(projectedObservations, mappingStats) {
    this._projectedObservations = projectedObservations;
    this._mappingStats = mappingStats;
  }

  get mappingStats() {
    return this._mappingStats;
  }

  get projectedObservations() {
    return this._projectedObservations;
  }
}

class MappingStats {
  constructor() {
    this.failures = {};
    this.success = {};
    this.noMapping = {};
  }

  addFailure(clockId) {
    if (typeof this.failures[clockId] === 'undefined') {
      this.failures[clockId] = 0;
    }
    this.failures[clockId]++;
  }

  addSuccess(clockId) {
    if (typeof this.success[clockId] === 'undefined') {
      this.success[clockId] = 0;
    }
    this.success[clockId]++;
  }

  addNoMapping(clockId) {
    if (typeof this.noMapping[clockId] === 'undefined') {
      this.noMapping[clockId] = 0;
    }
    this.noMapping[clockId]++;
  }
}

class TimeMapper {
  static mapObservations(observations, destinationClock, clockOwner) {
    const clocks = {};
    const getClock = (clockId) => {
      if (typeof clocks[clockId] === 'undefined') {
        clocks[clockId] = MatchClockHelper.getClockByClockId(
          clockOwner,
          clockId
        );
      }
      return clocks[clockId];
    };

    const mappingStats = new MappingStats();

    const projectedObservations = observations
      .map((observation) => {
        if (observation.clockId !== destinationClock.clockId()) {
          let triggerTime, startTime, endTime;
          try {
            const clock = getClock(observation.clockId);

            triggerTime = this.map(
              clock,
              destinationClock,
              observation.triggerTime
            );
            startTime = this.map(
              clock,
              destinationClock,
              observation.startTime
            );
            endTime = this.map(clock, destinationClock, observation.endTime);
          } catch (e) {
            console.log(e);
            mappingStats.addFailure(observation.clockId);
            return null;
          }

          mappingStats.addSuccess(observation.clockId);

          return observation.setTimes(
            destinationClock.clockId(),
            triggerTime,
            startTime,
            endTime
          );
        } else {
          mappingStats.addNoMapping(observation.clockId);
          return observation;
        }
      })
      .filter((observation) => observation !== null);

    return new ObservationLogProjection(projectedObservations, mappingStats);
  }

  static map(
    sourceClock,
    destinationClock,
    time,
    { unSerializeOutTime = true } = {}
  ) {
    if (sourceClock.clockId() === destinationClock.clockId()) {
      return time;
    } else {
      time = sourceClock.serializeTimeToFloat(time);
      let outTime;
      try {
        outTime = this.doMap(sourceClock, destinationClock, time);
      } catch (e) {
        throw new TimeMapException(e);
      }

      let inTime;
      try {
        inTime = this.doMap(destinationClock, sourceClock, outTime);
      } catch (e) {
        throw new TimeMapException();
      }

      if (inTime !== time) {
        throw new TimeMapException(
          `Non-reversable time: ${inTime} should match ${time}`
        );
      }

      if (unSerializeOutTime) {
        outTime = destinationClock.unSerializeFloatToTime(outTime);
      }
      return outTime;
    }
  }

  static doMap(sourceClock, destinationClock, time) {
    let stop = false;
    const foundSourceSynchronizationPoints = sourceClock
      .synchronizationPoints()
      .filter((synchronizationPoint, i) => {
        if (stop) {
          return false;
        }
        if (
          sourceClock.serializeTimeToFloat(synchronizationPoint.time) > time
        ) {
          stop = true;
          if (i === 0) {
            // 17 sep 2019: change to also allow backward mapping
            //// we only do forward mapping
            //// throw new TimeMappingException("Only forward mapping");
            return true;
          }
        } else {
          return true;
        }
      });

    if (foundSourceSynchronizationPoints.length === 0) {
      throw 'No applicable synchronization points found';
    }

    // Sort descending
    foundSourceSynchronizationPoints.reverse();
    for (const sourceSynchronizationPoint of foundSourceSynchronizationPoints) {
      for (const destinationSynchronizationPoint of destinationClock.synchronizationPoints()) {
        if (
          destinationSynchronizationPoint.type ===
            sourceSynchronizationPoint.type &&
          destinationSynchronizationPoint.key === sourceSynchronizationPoint.key
        ) {
          const delta =
            destinationClock.serializeTimeToFloat(
              destinationSynchronizationPoint.time
            ) -
            sourceClock.serializeTimeToFloat(sourceSynchronizationPoint.time);
          return time + delta;
        }
      }
    }

    throw 'no matching synchronization points found';
  }
}

class Clock {
  constructor(clockId, synchronizationPoints) {
    this._clockId = clockId;
    this._synchronizationPoints = synchronizationPoints;

    this.init();
  }

  init() {}

  isLive() {
    return false;
  }

  synchronizationPoints() {
    return this._synchronizationPoints;
  }

  clockId() {
    return this._clockId;
  }

  findSynchronizationPointTime(type, key) {
    const synchronizationPoint = this.synchronizationPoints().find(
      ({ type: sType, key: sKey }) => sType === type && sKey === key
    );
    return synchronizationPoint ? synchronizationPoint.time : null;
  }

  unSerializeFloatToTime(time) {
    return this.mapTime(time);
  }
}

class LiveClock extends Clock {
  getTime() {
    return now(0.1) / 1000;
  }

  isLive() {
    return true;
  }

  setStartTime(time) {
    return time;
  }

  secondsElapsedSince(time) {
    return this.getTime() - new Date(time) / 1000;
  }

  secondsElapsedBetween(startTime, endTime) {
    return Math.max(0, (new Date(endTime) - new Date(startTime)) / 1000);
  }

  mapTime(time) {
    return new Date(parseFloat(time) * 1000).toISOString();
  }

  serializeTimeToFloat(time) {
    return new Date(time) / 1000;
  }

  setRate(rate) {
    throw 'Not supported';
  }

  play() {
    return false;
  }
  pause() {
    return false;
  }
}

class RelativeClock extends Clock {
  init() {
    this._time = observable(null);
    this._offset = observable(0);
  }

  getTime() {
    return this._time.get() ?? 0;
  }

  secondsElapsedSince(time) {
    return this.getTime() - time;
  }

  secondsElapsedBetween(startTime, endTime) {
    return Math.max(0, endTime - startTime);
  }

  mapTime(time) {
    return time;
  }

  serializeTimeToFloat(time) {
    return time;
  }
}

class VideoClock extends RelativeClock {
  setOffset = action((offset) => {
    this._offset.set(offset);
  });

  setTime(time, pauseAfterSeek = false, playAfterSeek = false) {
    this.stateAfterSeek = pauseAfterSeek
      ? 'paused'
      : playAfterSeek
      ? 'play'
      : this.videoElement.getState();
    this.videoElement.seek(time - this._offset.get());
  }

  pause() {
    if (this.videoElement && this.videoElement.getState() !== 'paused') {
      this.videoElement.pause();
    }
  }

  setRate(rate) {
    this.videoElement.setPlaybackRate(rate);
  }

  stop() {
    // make sure the video won't play. Either pause when
    // playing or make sure it won't autostart
    if (this.videoElement) {
      this.pause();
    } else {
      this.__pauseOnReady = true;
    }
  }

  setStartTime(time) {
    if (this.videoElement) {
      this.setTime(time);
    } else {
      this.__seekOnReady = time;
    }
  }

  play() {
    if (this.videoElement) {
      this.videoElement.play();
    }
  }

  attachToVideo(videoElement) {
    this.videoElement = videoElement;
    videoElement.on(
      'time',
      action((event) => {
        this._time.set(event.position + this._offset.get());
      })
    );
    videoElement.on(
      'seek',
      action((event) => {
        this._time.set(event.offset + this._offset.get());
      })
    );
    videoElement.on('seeked', () => {
      if (this.stateAfterSeek === 'paused') {
        this.pause();
        this.stateAfterSeek = null;
      } else if (this.stateAfterSeek === 'play') {
        this.play();
        this.stateAfterSeek = null;
      }
    });

    if (this.__pauseOnReady) {
      setTimeout(() => this.pause(), 100);

      this.__pauseOnReady = undefined;
    }
    if (this.__seekOnReady) {
      this.setTime(this.__seekOnReady);
      this.__seekOnReady = undefined;
    }
  }

  togglePlayState() {
    if (this.videoElement.getState() === 'paused') {
      this.play();
    } else {
      this.pause();
    }
  }

  relativeSeek(seconds) {
    const currentPosition = this.videoElement.getPosition();
    this.videoElement.seek(Math.max(0, currentPosition + seconds));
  }
}

export {
  MatchClockHelper,
  TimeMapper,
  Clock,
  RelativeClock,
  LiveClock,
  VideoClock,
};
