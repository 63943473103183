import {
  interpolate,
  Sequence,
  spring,
  useCurrentFrame,
  useVideoConfig,
  Easing,
} from 'remotion';

import play from './play.svg';
import teamtv from './teamtv.svg';
import highlights from './highlights.svg';

import zIndexes from '../../helpers/zIndexes.json';
export const TeamtvOutro = ({ durationInFrames, clip }) => {
  const frame = useCurrentFrame();
  const videoConfig = useVideoConfig();
  const circleDuration = 1 * videoConfig.fps;
  const logoDuration = 1 * videoConfig.fps;
  const playStart = circleDuration - 0.5 * videoConfig.fps;

  const seqSize = interpolate(
    frame,
    [0, circleDuration],
    [videoConfig.width * 1.5, 0]
  );
  const borderSize = interpolate(
    frame,
    [0, circleDuration],
    [0, (videoConfig.width + frame * 10) * 0.5],
    { easing: Easing.bezier(0.8, 0.22, 0.96, 0.65) }
  );
  const circleOpacity = interpolate(
    frame,
    [circleDuration, circleDuration * 1.25],
    [1, 0],
    {
      extrapolateRight: 'clamp',
      extrapolateLeft: 'clamp',
      easing: Easing.bezier(0.8, 0.22, 0.96, 0.65),
    }
  );

  const playOpacity = interpolate(
    frame,
    [playStart, circleDuration + logoDuration],
    [0, 1],
    { extrapolateRight: 'clamp', extrapolateLeft: 'clamp' }
  );
  const playScale = spring({
    frame: frame - circleDuration,
    fps: videoConfig.fps,
    durationInFrames: videoConfig.fps,
    from: 0,
    to: 1,
  });

  const playTextWidth = interpolate(
    frame,
    [playStart + 2.5 * videoConfig.fps, playStart + 3 * videoConfig.fps],
    [0, 1],
    {
      extrapolateRight: 'clamp',
      extrapolateLeft: 'clamp',
      easing: Easing.bezier(0.25, 1, 0.5, 1),
    }
  );

  const highlightTextHeight = interpolate(
    frame,
    [playStart + 3 * videoConfig.fps, playStart + 4 * videoConfig.fps],
    [0, 1],
    {
      extrapolateRight: 'clamp',
      extrapolateLeft: 'clamp',
      easing: Easing.bezier(0.25, 1, 0.5, 1),
    }
  );

  return (
    <>
      <Sequence from={0} to={circleDuration * 2}>
        <div
          style={{
            width: '200%',
            height: '200%',
            position: 'absolute',
            left: '-50%',
            top: '-50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            zIndex: zIndexes.outro,
          }}
        >
          <div
            style={{
              width: `${seqSize}px`,
              aspectRatio: '1/1',
              boxSizing: 'content-box',
              borderRadius: '10000px',
              border: `solid ${borderSize / 2}px #5650A3`,
              opacity: circleOpacity,
            }}
          ></div>
        </div>
        <div
          style={{
            width: '200%',
            height: '200%',
            position: 'absolute',
            left: '-50%',
            top: '-50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            zIndex: zIndexes.outro,
          }}
        >
          <div
            style={{
              width: `${seqSize * 0.75}px`,
              aspectRatio: '1/1',
              boxSizing: 'content-box',
              borderRadius: '10000px',
              border: `solid ${borderSize / 3}px #28E89E`,
              opacity: circleOpacity,
            }}
          ></div>
        </div>
        <div
          style={{
            width: '200%',
            height: '200%',
            position: 'absolute',
            left: '-50%',
            top: '-50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              width: `${seqSize * 0.55}px`,
              aspectRatio: '1/1',
              boxSizing: 'content-box',
              borderRadius: '10000px',
              border: `solid ${borderSize / 4}px #5650A3`,
              opacity: circleOpacity,
            }}
          ></div>
        </div>
      </Sequence>
      <Sequence from={circleDuration - 20} to={circleDuration + logoDuration}>
        <div
          style={{
            width: '200%',
            height: '200%',
            position: 'absolute',
            left: '-50%',
            top: '-50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            zIndex: 999,
            backgroundColor: '#5650A3',
            opacity: playOpacity,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                transform: `scale(${playScale})`,
                width: '5em',
              }}
              src={play}
            />
            <div
              style={{
                width: `${playTextWidth * 22}em`,
                overflow: 'hidden',
                position: 'relative',
                left: '1em',
              }}
            >
              <img style={{ width: '22em' }} src={teamtv} />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1em',
              height: `${highlightTextHeight * 3}em`,
              overflow: 'hidden',
              flexGrow: 0,
            }}
          >
            <img style={{ width: '22em' }} src={highlights} alt="" />
          </div>
        </div>
      </Sequence>
    </>
  );
};
