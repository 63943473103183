import React, { Component, Fragment, useEffect, useState } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';
import moment from 'moment';

import { tenantCollection } from 'domain/Tenant';
import Loading from 'lib/Loading';

import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';
import { Error } from 'lib/PlaceHolder';

import { LicenseOverview } from './license/overview';
import { NewLicenseForm } from './license/new-form';
import { EditLicenseForm } from './license/edit-form';

const ViewClubPage = withTranslation('module.reseller')(
  observer(
    class ViewClubPage extends Component {
      constructor(props) {
        super(props);

        this.tenant = null;
        this.formValues = observable(this.newLicenseValues());
        this.dataState = observable('loading');
        this.isNewLicenseFormVisible = observable(false);
        this.isChangeEndDateFormVisible = observable(false);
      }

      componentDidMount() {
        this.loadData();
      }

      loadData = asyncAction(function* loadDataAsync() {
        try {
          this.tenant = yield tenantCollection.getOrFetch(
            this.props.match.params.tenantId
          );

          this.dataState.set('loaded');
        } catch (e) {
          this.dataState.set('error');
          console.error(e);
        }
      });

      toggleIsNewLicenseFormVisible = action(() => {
        this.isNewLicenseFormVisible.set(!this.isNewLicenseFormVisible.get());
      });

      toggleIsChangeEndDateFormVisible = action(() => {
        this.isChangeEndDateFormVisible.set(
          !this.isChangeEndDateFormVisible.get()
        );
      });

      handleNewLicenseCancel = () => {
        this.formValues = this.newLicenseValues();
        this.toggleIsNewLicenseFormVisible();
      };

      handleNewLicenseSubmit = async (evt) => {
        evt.preventDefault();
        const submitValues = {
          licenseType: this.formValues.licenseType,
          productId: this.formValues.productId,
          startDate: this.formValues.licenseStart.toISOString(),
          endDate: this.durationToLicenseEnd(this.formValues).toISOString(),
        };
        try {
          await this.tenant.grantLicense(submitValues);
          this.formValues = this.newLicenseValues();
          this.toggleIsNewLicenseFormVisible();
        } catch (e) {
          console.error(e);
        }
      };

      handleChangeEndDateSubmit = async (evt) => {
        evt.preventDefault();
        const args = {
          licenseId: this.formValues.licenseId,
          endDate: this.formValues.licenseEnd.toISOString(),
        };
        try {
          await this.tenant.changeLicenseEndDate(args);
          this.toggleIsChangeEndDateFormVisible();
        } catch (e) {
          console.error(e);
        }
      };

      newLicenseValues = () => ({
        licenseId: null,
        licenseType: 'paid',
        productId: 'teamtv-video-analyse-default',
        licenseStart: moment(),
        licenseEnd: moment().add(moment.duration(1, 'years')),
        licenseDuration: '1',
      });

      startChangeEndDate = (id) => () => {
        const license = this.tenant.licenses.find(
          (license) => license.licenseId === id
        );
        this.setLicenseValues(license);
        this.toggleIsChangeEndDateFormVisible();
      };

      setLicenseValues = (license) => {
        this.formValues.licenseId = license.licenseId;
        this.formValues.licenseType = license.licenseType;
        this.formValues.licenseStart = moment(license.startDate);
        this.formValues.licenseEnd = moment(license.endDate);
        this.formValues.productId = license.productId;
      };

      durationToLicenseEnd = ({ licenseStart, licenseDuration }) =>
        moment(licenseStart).add(
          moment.duration(Number.parseInt(licenseDuration), 'years')
        );

      render() {
        const { t } = this.props;

        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const tenant = this.tenant;

        const pageProps = {
          title: 'View tenant',
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { path: '/reseller', title: t('module.match:reseller') },
            {
              title: tenant.name,
            },
          ],
        };

        const { status: licenseStatus, ...license } = tenant.license;

        const revoke = (id) => () => tenant.revokeLicense(id);
        const convert =
          ({ id, licenseType, period, conversionDate }) =>
          () =>
            tenant.convertLicense({ id, licenseType, period, conversionDate });
        const grant =
          ({ licenseType, productId, startDate, endDate }) =>
          () =>
            tenant.grantLicense({ licenseType, productId, startDate, endDate });

        return (
          <Page {...pageProps}>
            <dl>
              <dt>{t('name')}</dt>
              <dd key="name">{tenant.name}</dd>
              <dt>{t('lastActiveLicenseType')}</dt>
              <dd key="licenseType">{license.licenseType}</dd>
              <dt>{t('products')}</dt>
              <dd key="productIds">
                {!!license.productIds &&
                  license.productIds.map((productId) => (
                    <>
                      <span key={productId}>{productId}</span>
                      <br />
                    </>
                  ))}
              </dd>
              <dt>{t('licenseEnd')}</dt>
              <dd key="licenseEnd">
                {license.endDate &&
                  new Date(license.endDate).toLocaleDateString(undefined, {
                    timeZone: 'UTC',
                  })}
              </dd>
            </dl>
            {this.isNewLicenseFormVisible.get() ? (
              <NewLicenseForm
                values={this.formValues}
                onCancel={this.handleNewLicenseCancel}
                onSubmit={this.handleNewLicenseSubmit}
              />
            ) : (
              <div>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.toggleIsNewLicenseFormVisible}
                >
                  {t('common:add')}
                </button>
              </div>
            )}
            {this.isChangeEndDateFormVisible.get() && (
              <EditLicenseForm
                values={this.formValues}
                onCancel={this.toggleIsChangeEndDateFormVisible}
                onSubmit={this.handleChangeEndDateSubmit}
              />
            )}
            <LicenseOverview
              licenses={tenant.licenses.slice()}
              grant={grant}
              revoke={revoke}
              startChangeEndDate={this.startChangeEndDate}
              convert={convert}
            />
          </Page>
        );
      }
    }
  )
);

export { ViewClubPage };
