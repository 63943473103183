import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { ModalView } from './modal-view';
import { ObservationLogVideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { VideoClock } from 'domain/ObservationLogger/Clock';
import { observer } from 'mobx-react';
import { observable, action } from 'mobx';
import i18next from 'i18next';
import { formatTime } from 'lib/FormattedTime';
import { getOrdinalPeriod } from './get-ordinal-period';

const SyncTagView = withTranslation('module.dataSync')(
  observer(
    class SyncTagView extends Component {
      constructor(props) {
        super(props);

        this.syncTime = observable(this.props.startTime || 0);

        this.videoClock = new VideoClock();
      }

      setSyncTime = action(() => {
        this.syncTime.set(this.videoClock.getTime());
      });

      componentDidMount() {
        this.videoClock.setStartTime(this.syncTime.get());
      }

      render() {
        const { onSave, onCancel, t, video, period, periodCount } = this.props;
        const { period: _period, periodCount: _periodCount } = getOrdinalPeriod(
          this.props.t,
          period,
          periodCount
        );
        const buttonText = t('syncTagButtonText', {
          period: _period,
          periodCount: _periodCount,
        });
        return (
          <ModalView
            onSave={() => onSave(this.syncTime.get())}
            onSaveText={t('common:continue')}
            onCancel={onCancel}
          >
            <form className="row">
              <div className="col-12">
                <p className="mb-4">
                  {t('syncTagContent', {
                    buttonText: buttonText,
                  })}
                </p>
              </div>
              <div className="col-12 col-lg-7">
                <div
                  style={{
                    display: 'flex',
                    aspectRatio: '16 / 9',
                    paddingBottom: '12px',
                  }}
                >
                  <ObservationLogVideoPlayer
                    clock={this.videoClock}
                    video={video}
                    multiCam={this.props.multiCam}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-5">
                <div className="row">
                  <button
                    className="btn btn-primary col-4 mb-2 ml-3 mr-2"
                    type="button"
                    onClick={() => this.setSyncTime()}
                  >
                    <i className="i-clock i-xs i-light"></i>
                    {buttonText}
                  </button>
                  <input
                    type="text"
                    className="form-control col-4 mb-2"
                    value={formatTime(this.syncTime.get())}
                    name="syncTime"
                    readOnly
                  />
                </div>
                {/*<div className="form-group">
                  <button className="btn btn-link pl-0" type="button">
                    {t('syncTagMissingText', {
                      tagName: 'fluitsignaal TODO',
                    })}
                  </button>
                </div>*/}
              </div>
            </form>
          </ModalView>
        );
      }
    }
  )
);

export { SyncTagView };
