import React from 'react';

import styled from 'styled-components';
import './index.scss';
import { Skeleton } from 'survey-react';
function round(x) {
  return Math.round(x);
}
const LoaderDots = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  vertical-align: middle;
  margin-left: ${(props) => round((props.size / 80) * 20)}px;

  & div {
    position: absolute;
    transform: translateY(-50%);
    top: ${(props) => round((props.size / 80) * 33)}px;
    top: 50%;
    width: ${(props) => round((props.size / 80) * 13)}px;
    height: ${(props) => round((props.size / 80) * 13)}px;
    border-radius: 50%;
    background: ${(props) => props.color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: ${(props) => round((props.size / 80) * 8)}px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  & div:nth-child(2) {
    left: ${(props) => round((props.size / 80) * 8)}px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(3) {
    left: ${(props) => round((props.size / 80) * 33)}px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  & div:nth-child(4) {
    left: ${(props) => round((props.size / 80) * 56)}px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(${(props) => round((props.size / 80) * 24)}px, 0);
    }
  }
`;

const LoaderSpinner = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin-left: ${(props) => round((props.size / 80) * 20)}px;
  & div {
    transform-origin: ${(props) => round((props.size / 80) * 40)}px
      ${(props) => round((props.size / 80) * 40)}px;
    animation: lds-spinner 1.2s linear infinite;
  }
  & div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: ${(props) => round((props.size / 80) * 3)}px;
    left: ${(props) => round((props.size / 80) * 37)}px;
    width: ${(props) => round((props.size / 80) * 6)}px;
    height: ${(props) => round((props.size / 80) * 18)}px;
    border-radius: 20%;
    background: ${(props) => props.color};
  }
  & div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  & div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  & div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  & div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  & div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  & div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  & div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  & div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  & div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  & div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  & div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  & div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const Loading = ({ color = 'primary', size = 80, type = 'dots' }) => {
  if (color === 'primary') {
    color = 'var(--primary-color)';
  }
  switch (type) {
    case 'dots':
      return (
        <LoaderDots size={size} color={color} className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LoaderDots>
      );
      break;
    case 'spinner':
      return (
        <LoaderSpinner size={size} color={color} className="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LoaderSpinner>
      );
      break;
    case 'fullscreen':
      return (
        <div className="loading-fullscreen">
          <div className="app-header app-header--fake">
            <div className="app-header-content">
              <div className="app-header-branding">
                <div className="app-nav"></div>
              </div>
            </div>
          </div>
          <div className="page">
            <header className="page-header">
              <div className="page-header-inset-wrapper">
                <div className="page-header-inset">
                  <div className={'page-title'}>loading..</div>
                </div>
              </div>
            </header>
          </div>

          <div className="loading-fullscreen__inner">
            <LoaderDots size={size} color={color} className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </LoaderDots>
          </div>
        </div>
      );
    default:
      return (
        <LoaderDots size={size} color={color} className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </LoaderDots>
      );
  }
};

export default Loading;
