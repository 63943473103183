import React, { Component, Fragment } from 'react';
import { Session } from 'domain/Session';
import TeamCollection from 'domain/Team';

import { observable, action } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { observer } from 'mobx-react';

import Loading from 'lib/Loading';
import { gotoRoute } from '../../route';
import logger from '../../../utils/Logger';
import { confirmModal } from 'lib/Confirmation';
import { NoResults, Error } from 'lib/PlaceHolder';

import noMembershipsImage from 'img/no_teams.svg';
import noRequestsImage from 'img/no_invites.svg';

import { withTranslation } from 'react-i18next';
import { EventPublisher } from 'utils/EventPublisher';
import { Page } from 'lib/Page';
import { OverviewItem } from 'lib/OverviewItem';

import clubPlaceholderIcon from 'img/icons/club_placeholder.svg';

const ShareOverview = ({ shares, onRemove, t }) => {
  return (
    <div>
      {shares.map((share) => {
        const secondaryActions = [
          // {
          //   label: 'Trainers beheren',
          //   onClick: console.debug,
          // },
          // {
          //   label: 'Team bewerken',
          //   onClick: console.debug,
          // },
          // {
          //   label: t('deleteTeam'),
          //   onClick: () => alert('click'),
          // },
        ];

        const details = 'piet';

        const team = TeamCollection.get(share.shareAttributes.teamId);

        const overviewItemProps = {
          details: () => `Geclaimed door ${share.description}`,
          title: (team || {}).label,
          icon: clubPlaceholderIcon,
          active: true,
          secondaryActions,
        };

        return <OverviewItem key={share.id} {...overviewItemProps} />;
      })}
    </div>
  );
};

const RequestOverview = ({ requests, onAcceptRequest, onRejectRequest, t }) => {
  return (
    <div>
      {requests.map((request) => {
        const secondaryActions = [
          // {
          //   label: 'Trainers beheren',
          //   onClick: console.debug,
          // },
          // {
          //   label: 'Team bewerken',
          //   onClick: console.debug,
          // },
          {
            label: t('accept'),
            onClick: () => onAcceptRequest(request),
          },
          {
            label: t('reject'),
            onClick: () => onRejectRequest(request),
          },
        ];

        let title = <i>{t(`common.role:${request.roleName}`)}</i>;
        if (request.roleName === 'team') {
          const team = TeamCollection.get(request.shareAttributes.teamId);
          title = team?.label || '*INVALID TEAM*';
        }

        const overviewItemProps = {
          details: () => t('requestDetail', { request }),
          title,
          icon: clubPlaceholderIcon,
          secondaryActions,
        };

        return <OverviewItem key={request.id} {...overviewItemProps} />;
      })}
    </div>
  );
};

const UnclaimedOverview = ({ teams }) => {
  return (
    <div>
      {teams.map((team) => {
        const secondaryActions = [
          // {
          //   label: 'Trainers beheren',
          //   onClick: console.debug,
          // },
          // {
          //   label: 'Team bewerken',
          //   onClick: console.debug,
          // },
          // {
          //   label: t('accept'),
          //   onClick: () => onAcceptRequest(request),
          // },
          // {
          //   label: t('reject'),
          //   onClick: () => onRejectRequest(request),
          // },
        ];

        const overviewItemProps = {
          title: team.label,
          icon: clubPlaceholderIcon,
          secondaryActions,
          inactive: true,
        };

        return <OverviewItem key={team.id} {...overviewItemProps} />;
      })}
    </div>
  );
};

const OutgoingShares = withTranslation('module.shares')(
  observer(
    class OutgoingShares extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');
      }

      componentDidMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        this.dataState.set('loading');
        try {
          const currentSession = Session.current();
          yield currentSession.isReady();
          this.currentResourceGroup = currentSession.currentResourceGroup();

          yield Promise.all([
            TeamCollection.fetchIfEmpty(),
            this.currentResourceGroup.outgoingShareRequests.fetchIfEmpty(),
            this.currentResourceGroup.outgoingShares.fetchIfEmpty(),
          ]);
          //        ]);
          this.dataState.set('ready');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load Outgoing shares',
          });
          this.dataState.set('error');
        }
      });

      rejectRequest(request) {
        confirmModal({
          title: this.props.t('rejectRequestConfirmTitle', { request }),
          body: this.props.t('rejectRequestConfirmMessage', {
            request,
          }),
          actionLabel: this.props.t('common:confirm'),
          actionHandler: async () => {
            await request.reject();
            this.currentResourceGroup.outgoingShareRequests.fetch();
          },
        });
      }

      async acceptRequest(request) {
        await request.accept();
        this.currentResourceGroup.outgoingShareRequests.fetch();
        this.currentResourceGroup.outgoingShares.fetch();
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { title: this.props.t('title') },
          ],
        };

        const outgoingShares =
          this.currentResourceGroup.outgoingShares.toArray();
        const outgoingShareRequests =
          this.currentResourceGroup.outgoingShareRequests.toArray();

        const unclaimedTeams = TeamCollection.toArray().filter((team) => {
          if (
            !!outgoingShares.find(
              (share) => share.shareAttributes.teamId === team.id
            )
          ) {
            return false;
          }
          if (
            !!outgoingShareRequests.find(
              (shareRequest) => shareRequest.shareAttributes.teamId === team.id
            )
          ) {
            return false;
          }
          return true;
        });
        unclaimedTeams.sort((a, b) => a.label.localeCompare(b.label));

        return (
          <Page {...pageProps}>
            <ShareOverview
              shares={outgoingShares}
              onRemove={(shareRecipientId) => {
                this.removeShare(shareRecipientId);
              }}
              t={this.props.t}
            />
            <RequestOverview
              requests={outgoingShareRequests}
              t={this.props.t}
              onAcceptRequest={(request) => {
                this.acceptRequest(request);
                EventPublisher.dispatch('REQUEST_ACCEPTED');
              }}
              onRejectRequest={(request) => {
                this.rejectRequest(request);
                EventPublisher.dispatch('REQUEST_REJECTED');
              }}
            />
            <UnclaimedOverview teams={unclaimedTeams} />
          </Page>
        );
      }
    }
  )
);

export { OutgoingShares };
