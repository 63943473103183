import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { RjfTest } from './react-jsonschema-form-test';
import { ComponentPage } from './ComponentPage';

// import './index.scss';

const MODULE_NAME = 'dev';

const devModule = new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('component-page/:testName', ComponentPage, 'test'),
    new RouteRedirect('', 'test/popup'),
  ]
);

export default devModule;
