import React from 'react';
import { hideNewRow, saveNewRow } from 'ka-table/actionCreators';

const SaveButton = data => {
  const saveNewData = () => {
    data.dispatch(
      saveNewRow(
        {},
        {
          validate: false,
        }
      )
    );
  };
  return (
    <div className="buttons">
      <button
        type="button"
        className="btn btn-primary"
        onClick={saveNewData}
        style={{ marginRight: 10 }}
      >
        {data.t('common:save')}
      </button>
      <button
        type="button"
        className="btn btn-secondary"
        onClick={() => data.dispatch(hideNewRow())}
      >
        {data.t('common:cancel')}
      </button>
    </div>
  );
};

export default SaveButton;
