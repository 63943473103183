import developmentPlanCollection from './DevelopmentPlan';

const createApplyBeforeFnWrapper = (fn, applyBefore) => {
  let applying = false;
  return async (...args) => {
    if (applying === false) {
      applying = applyBefore();
    }
    try {
      await applying;
    } catch (e) {
      applying = false;
      throw e;
    }

    return fn(...args);
  };
};

const applyBeforeClassWrapper = (target, methodName, applyBefore) => {
  const proxyFn = createApplyBeforeFnWrapper(
    (...args) => target[methodName](...args),
    applyBefore
  );

  return new Proxy(target, {
    get: function(target, prop, receiver) {
      if (prop === methodName) {
        return proxyFn;
      }
      return target[prop];
    },
  });
};

class DevelopmentPlanQueryService {
  static async developmentPlanByPerson(personId) {
    return this.developmentPlanBySubject({
      type: 'person',
      id: personId,
    });
  }

  static async developmentPlanByTeam(teamId) {
    return this.developmentPlanBySubject({
      type: 'team',
      id: teamId,
    });
  }

  static async developmentPlanByResourceGroup() {
    await developmentPlanCollection.fetchIfEmpty();
    let developmentPlan = developmentPlanCollection
      .toArray()
      .find(
        developmentPlan =>
          developmentPlan.subjectType === 'resource-group' &&
          developmentPlan.subjectId === 'default'
      );

    if (!developmentPlan) {
      developmentPlan = await developmentPlanCollection.create({
        subject: {
          type: 'resource-group',
          id: 'default',
        },
        finalIndicatorEvaluations: [],
        lastIndicatorEvaluations: [],
        perspectives: [],
        name: `resource-group: default`,
      });
    }
    return developmentPlan;
  }

  static async developmentPlanBySubject(subject) {
    // no logic here yet
    await developmentPlanCollection.fetchIfEmpty();

    let developmentPlan = developmentPlanCollection
      .toArray()
      .find(
        developmentPlan =>
          developmentPlan.subjectType === subject.type &&
          developmentPlan.subjectId === subject.id
      );

    if (typeof developmentPlan === 'undefined') {
      developmentPlan = this._developmentPlanProxyBySubject(subject);
    }
    return developmentPlan;
  }

  static _developmentPlanProxyBySubject(subject) {
    // don't use add! This would make developmentPlanByPerson return the unsaved developmentPlan
    // instead of calling this function again
    const developmentPlan = developmentPlanCollection.build({
      subject,
      finalIndicatorEvaluations: [],
      lastIndicatorEvaluations: [],
      perspectives: [],
      name: `${subject.type}: ${subject.id}`,
    });

    return applyBeforeClassWrapper(developmentPlan, 'rpc', async () => {
      await developmentPlan.save();
      developmentPlan.setVideoFragmentCollectionUrl();

      // add it to the collection when saved
      developmentPlanCollection.models.push(developmentPlan);
    });
  }
}

export { DevelopmentPlanQueryService };
