import { Module } from '../base';
import { RouteExact, RouteRedirect } from '../route';
import { TrainingScheduleOverview } from './overview';

const MODULE_NAME = 'training-schedule';
const scheduleTrainings = new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact('overview', TrainingScheduleOverview, 'trainingSchedule'),
    new RouteRedirect('', 'overview'),
  ]
);
export default scheduleTrainings;
