import { Audio, Easing, interpolate, useCurrentFrame } from 'remotion';
import { getBackgroundMusicTrack } from '../audio/backgroundMusicRegistry';

export const BackgroundMusic: React.FC<{
  startAt: number;
  durationInFrames: number;
  fps: number;
  backgroundMusic: string;
  volume: number;
}> = ({ startAt, durationInFrames, fps, backgroundMusic, volume = 0.4 }) => {
  const currentFrame = useCurrentFrame();
  const fadeInDuration = Math.round(1.5 * fps);
  const fadeOutDuration = Math.round(5.0 * fps);
  const crossFadeOutDuration = Math.round(1.0 * fps);
  const endAt = startAt + durationInFrames + crossFadeOutDuration;
  if (!backgroundMusic) {
    return null;
  }
  let backgroundMusicUrl = null;

  backgroundMusicUrl = getBackgroundMusicTrack(backgroundMusic)?.url;

  if (!backgroundMusicUrl) {
    return null;
  }

  if (startAt < currentFrame && currentFrame <= endAt) {
    const _volume = interpolate(
      currentFrame,
      [startAt, startAt + fadeInDuration, endAt - fadeOutDuration, endAt],
      [0, volume, volume, 0],
      {
        easing: Easing.inOut(Easing.ease),
        extrapolateRight: 'clamp',
        extrapolateLeft: 'clamp',
      }
    );
    return <Audio loop src={backgroundMusicUrl} volume={_volume} />;
  }
};
