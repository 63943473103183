import React, { Component, Fragment, useEffect, useState } from 'react';
import { observer, Observer } from 'mobx-react';
import { action } from 'mobx';
import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { PlayerSelect } from '../components/PlayerSelect';

import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';

import {
  CustomTagButton,
  PointTriggerButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';
import { LiveObservationView } from './live-observation';

//const HockeyObservationInput = createComponentFactoryFromTemplate(hockeyObservationInputTemplate);

import './live-input.scss';
import previewImgSrc from './hockey.live.default.png';
import { Modal } from 'lib/Modal';
import sportingEventCollection from '../../../domain/SportingEvent';

const HockeyObservationInput = observer(
  class HockeyObservationInput extends Component {
    componentWillMount() {
      const { observationLogger, homeTeam, awayTeam } =
        this.props.observationContext;
    }

    getPossessionName(teamId) {
      const { homeTeam, awayTeam } = this.props.observationContext;
      const teamName = teamId === homeTeam.id ? homeTeam.label : awayTeam.label;
      return `Possession ${teamName}`;
    }

    endAllObservations() {
      {
        const command = Command.create('ObservationLogger.TurnoffGroup', {
          groupId: 'IN-POSSESSION-OF-BALL',
        });
        CommandBus.dispatch(command);
      }

      {
        const command = Command.create('ObservationLogger.TurnoffGroup', {
          groupId: 'POSSESSION',
        });
        CommandBus.dispatch(command);
      }
    }

    render() {
      const homeTeam = this.props.observationContext.homeTeam;
      const awayTeam = this.props.observationContext.awayTeam;
      const observationLogger = this.props.observationContext.observationLogger;
      return <div>hoi</div>;
    }
  }
);

const penaltyTypes = [
  ['RED-CARD', 'Red card'],
  ['YELLOW-CARD', 'Yellow card'],
  ['GREEN-CARD', 'Green card'],
];

const PenaltyGivenModal = observer(({ team, observationLogger }) => {
  const cancelPenaltyGiven = () => {
    const command = Command.create(
      'ObservationLogger.RemovePartialObservation',
      { groupId: 'PENALTY-GIVEN' }
    );
    CommandBus.dispatch(command);
  };

  return (
    <Modal onCloseClick={cancelPenaltyGiven} size="lg">
      <div className="row">
        <PlayerSelect
          observationLogger={observationLogger}
          players={team.players.toArray()}
          observationCode="PENALTY-GIVEN"
          triggerType="set-attributes"
        />
        <div className="col-4">
          <b>Type</b>
          <ul className="invoer-select" id="chance-select">
            {penaltyTypes.map(([type, label]) => {
              return (
                <InputTrigger
                  key={type}
                  type="end-point"
                  observationCode="PENALTY-GIVEN"
                  groupId="PENALTY-GIVEN"
                  attributes={{ type }}
                  observationLogger={observationLogger}
                  render={({ active, trigger }) => (
                    <li className={active ? 'selected' : ''}>
                      <div className="select-thumb" />
                      <div className="select-name clickable" onClick={trigger}>
                        {label}
                      </div>
                    </li>
                  )}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </Modal>
  );
});

const GoalScoredModal = observer(({ team, observationLogger }) => {
  const cancelGoalScored = () => {
    const command = Command.create(
      'ObservationLogger.RemovePartialObservation',
      { groupId: 'GOAL' }
    );
    CommandBus.dispatch(command);
  };

  return (
    <Modal onCloseClick={cancelGoalScored} size="lg">
      <div className="row">
        <PlayerSelect
          observationLogger={observationLogger}
          players={team.players.toArray()}
          observationCode="GOAL"
          triggerType="end-point"
        />
      </div>
    </Modal>
  );
});

const CameraSideModal = observer(
  ({ description, observationCode, observationLogger }) => {
    const cancelCameraSide = () => {
      const command = Command.create('ObservationLogger.EndRangeObservation', {
        groupId: observationCode,
        attributes: {},
        code: observationCode,
        description,
        time: 'start',
      });
      CommandBus.dispatch(command);
    };

    return (
      <Modal onCloseClick={cancelCameraSide} size="sm">
        <div className="row">
          <div className="col-12 mb-5">
            <h5 className="text-center">Choose side</h5>
          </div>
          <div className="col-6">
            <div className={'d-flex'}>
              <InputTrigger
                type="end-point"
                observationCode={observationCode}
                groupId={observationCode}
                attributes={{ side: 'LEFT' }}
                observationLogger={observationLogger}
                render={(props) => (
                  <PointTriggerButton
                    {...props}
                    classNames={'btn btn-primary'}
                    description="Left"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-6">
            <div className={'d-flex'}>
              <InputTrigger
                type="end-point"
                observationCode={observationCode}
                groupId={observationCode}
                attributes={{ side: 'RIGHT' }}
                observationLogger={observationLogger}
                render={(props) => (
                  <PointTriggerButton
                    {...props}
                    classNames={'btn btn-primary'}
                    description="Right"
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

const LiveObservationInputHockeyInnerView = ({
  observationLogger,
  homeTeam,
  awayTeam,
  useTaggingData, // Use tagging data to control the camera
}) => {
  return (
    <div className="observation-input-live">
      <div className="labels-connected">
        <div className="label-connection" />
        <InputTrigger
          type="toggle-range"
          observationCode="POSSESSION"
          groupId="POSSESSION"
          attributes={{ teamId: homeTeam.id }}
          observationLogger={observationLogger}
          render={({ active, trigger }) => (
            <div
              onClick={trigger}
              className={`clickable label ${active ? 'active' : ''} `}
            >
              <HotKey keys={['1']} onKeysPressed={trigger} />
              Possession {homeTeam.shortLabel}
              <span className="button-hotkey">1</span>
              <img className="i" src={homeTeam.logoUrl} />
            </div>
          )}
        />

        <InputTrigger
          type="toggle-range"
          observationCode="POSSESSION"
          groupId="POSSESSION"
          attributes={{ teamId: awayTeam.id }}
          observationLogger={observationLogger}
          render={({ active, trigger }) => (
            <div
              onClick={trigger}
              className={`clickable label label-i-lft ${
                active ? 'active' : ''
              } `}
            >
              <HotKey keys={['2']} onKeysPressed={trigger} />
              <img className="i" src={awayTeam.logoUrl} />
              Possession {awayTeam.shortLabel}
              <span className="button-hotkey">2</span>
            </div>
          )}
        />
      </div>
      <div className="labels">
        <InputTrigger
          type="toggle-range"
          observationCode="POSSESSION-BUILD-UP"
          groupId="IN-POSSESSION-OF-BALL"
          observationLogger={observationLogger}
          render={(props) => (
            <RangeTriggerButton {...props} keys="w" description="Build-up" />
          )}
        />

        <InputTrigger
          type="toggle-range"
          observationCode="POSSESSION-TURNOVER"
          groupId="IN-POSSESSION-OF-BALL"
          observationLogger={observationLogger}
          render={(props) => (
            <RangeTriggerButton {...props} keys="q" description="Turnover" />
          )}
        />

        <InputTrigger
          type="toggle-range"
          observationCode="POSSESSION-FREE-HIT"
          groupId="IN-POSSESSION-OF-BALL"
          observationLogger={observationLogger}
          render={(props) => (
            <RangeTriggerButton {...props} keys="e" description="Free-hit" />
          )}
        />
      </div>
      <div className="labels">
        <InputTrigger
          type="point"
          observationCode="23-ENTRY"
          observationLogger={observationLogger}
          description="23 Entry"
          render={(props) => <PointTriggerButton {...props} keys="a" />}
        />

        <InputTrigger
          type="point"
          observationCode="CIRCLE-ENTRY"
          observationLogger={observationLogger}
          description="Circle Entry"
          render={(props) => <PointTriggerButton {...props} keys="s" />}
        />

        <InputTrigger
          type="point"
          observationCode="SCORING-OPPORTUNITY"
          observationLogger={observationLogger}
          description="Scorings Opportunity"
          render={(props) => <PointTriggerButton {...props} keys="d" />}
        />

        <Observer>
          {() => {
            const penaltyCornerGivenAttributes =
              observationLogger.getCurrentPartialObservationAttributes(
                'REASON-PENALTY-CORNER'
              );
            let modal = null;
            if (!!penaltyCornerGivenAttributes) {
              modal = (
                <CameraSideModal
                  observationCode="REASON-PENALTY-CORNER"
                  observationLogger={observationLogger}
                  description="Penalty Corner given"
                />
              );
            }
            return (
              <>
                {modal}
                <InputTrigger
                  type={useTaggingData ? 'start-point' : 'point'}
                  observationCode="REASON-PENALTY-CORNER"
                  groupId="REASON-PENALTY-CORNER"
                  observationLogger={observationLogger}
                  description="Penalty Corner given"
                  render={(props) => <PointTriggerButton {...props} keys="f" />}
                />
              </>
            );
          }}
        </Observer>

        <InputTrigger
          type="point"
          observationCode="PENALTY-CORNER"
          observationLogger={observationLogger}
          description="Penalty Corner"
          render={(props) => <PointTriggerButton {...props} keys="z" />}
        />

        <InputTrigger
          type="point"
          observationCode="PENALTY-STROKE"
          observationLogger={observationLogger}
          description="Penalty Stroke"
          render={(props) => <PointTriggerButton {...props} keys="x" />}
        />

        <Observer>
          {() => {
            let team,
              hasPlayers = false;
            const possessionAttributes =
              observationLogger.getCurrentPartialObservationAttributes(
                'POSSESSION'
              );
            if (!!possessionAttributes) {
              if (possessionAttributes.get('teamId') === homeTeam.teamId) {
                team = homeTeam;
              } else {
                team = awayTeam;
              }
              hasPlayers = team.players.toArray().length > 0;
            }

            const goalAttributes =
              observationLogger.getCurrentPartialObservationAttributes('GOAL');
            let modal = null;
            if (!!goalAttributes && hasPlayers) {
              modal = (
                <GoalScoredModal
                  observationLogger={observationLogger}
                  team={team}
                />
              );
            }
            return (
              <>
                {modal}
                <InputTrigger
                  type={hasPlayers ? 'start-point' : 'point'}
                  observationCode="SHOT"
                  attributes={{ type: 'FIELD', result: 'GOAL' }}
                  observationLogger={observationLogger}
                  description="Goal - FG"
                  groupId="GOAL"
                  render={(props) => (
                    <PointTriggerButton {...props} keys="c+v" />
                  )}
                />

                <InputTrigger
                  type={hasPlayers ? 'start-point' : 'point'}
                  observationCode="SHOT"
                  attributes={{ type: 'PENALTY-CORNER', result: 'GOAL' }}
                  observationLogger={observationLogger}
                  description="Goal - PC"
                  groupId="GOAL"
                  render={(props) => (
                    <PointTriggerButton {...props} keys="c+b" />
                  )}
                />

                <InputTrigger
                  type={hasPlayers ? 'start-point' : 'point'}
                  observationCode="SHOT"
                  attributes={{ type: 'PENALTY-STROKE', result: 'GOAL' }}
                  observationLogger={observationLogger}
                  groupId="GOAL"
                  description="Goal - PS"
                  render={(props) => (
                    <PointTriggerButton {...props} keys="c+n" />
                  )}
                />
              </>
            );
          }}
        </Observer>

        <InputTrigger
          type="start-point"
          observationCode="PENALTY-GIVEN"
          description={`Card given ${homeTeam.label}`}
          observationLogger={observationLogger}
          attributes={{ teamId: homeTeam.teamId }}
          groupId="PENALTY-GIVEN"
          render={(props) => <PointTriggerButton {...props} />}
        />
        <InputTrigger
          type="start-point"
          observationCode="PENALTY-GIVEN"
          description={`Card given ${awayTeam.label}`}
          observationLogger={observationLogger}
          attributes={{ teamId: awayTeam.teamId }}
          groupId="PENALTY-GIVEN"
          render={(props) => <PointTriggerButton {...props} />}
        />
        <Observer>
          {() => {
            const penaltyGivenAttributes =
              observationLogger.getCurrentPartialObservationAttributes(
                'PENALTY-GIVEN'
              );
            if (!!penaltyGivenAttributes) {
              let team;
              if (penaltyGivenAttributes.get('teamId') === homeTeam.teamId) {
                team = homeTeam;
              } else {
                team = awayTeam;
              }
              return (
                <PenaltyGivenModal
                  observationLogger={observationLogger}
                  team={team}
                />
              );
            }
            return null;
          }}
        </Observer>
        <InputTrigger
          type="point"
          observationCode="HIGHLIGHT"
          observationLogger={observationLogger}
          description="Highlight"
          render={(props) => <PointTriggerButton {...props} keys="" />}
        />
      </div>
    </div>
  );
};

const LiveObservationInputHockeyView = () => {
  const [playDirection, setPlayDirection] = useState('home-away');

  const observationContext = ObservationContext.instance();

  const switchPossession = (teamId, turnover = false) => {
    CommandBus.dispatch(
      Command.create('ObservationLogger.TurnoffGroup', {
        groupId: 'POSSESSION',
      })
    );

    CommandBus.dispatch(
      Command.create('ObservationLogger.SetPointObservation', {
        code: 'GAME:TURNOVER',
        attributes: {},
        description: 'Turnover',
      })
    );

    CommandBus.dispatch(
      Command.create('ObservationLogger.StartRangeObservation', {
        groupId: 'POSSESSION',
        code: 'POSSESSION',
        attributes: { teamId },
      })
    );

    CommandBus.dispatch(
      Command.create('ObservationLogger.SetPointObservation', {
        code: 'START-POSSESSION',
        attributes: { teamId },
        description: 'Start balbezit',
      })
    );
  };

  const swapPossession = () => {
    const possessionAttributes =
      observationContext.observationLogger.getCurrentPartialObservationAttributes(
        'POSSESSION'
      );
    let possessionTeamId;
    if (possessionAttributes) {
      possessionTeamId =
        possessionAttributes.get('teamId') === observationContext.homeTeam.id
          ? observationContext.awayTeam.id
          : observationContext.homeTeam.id;
    } else {
      possessionTeamId = observationContext.homeTeam.id;
    }
    switchPossession(possessionTeamId);
  };

  const turnover = () => {
    swapPossession();
    CommandHistory.instance().tagHistoryItem('Turnover');
  };

  useEffect(() => {
    const observationContext = ObservationContext.instance();
    const { homeTeam, awayTeam } = observationContext;

    const commandHistory = CommandHistory.instance();

    const observationLogger = observationContext.observationLogger;
    observationLogger.on(
      'beforeObservationAdded',
      (observationCode, attributes) => {
        if (observationCode === 'POSSESSION') {
          const command = Command.create('ObservationLogger.TurnoffGroup', {
            groupId: 'IN-POSSESSION-OF-BALL',
          });
          CommandBus.dispatch(command);
        }
      }
    );
    observationLogger.on(
      'beforeObservationStarted',
      (observationCode, attributes) => {
        const teamSwitchMap = {
          [homeTeam.id]: awayTeam.id,
          [awayTeam.id]: homeTeam.id,
        };
        switch (observationCode) {
          case 'POSSESSION-TURNOVER':
            {
              const partialObservationAttributeBag =
                observationLogger.getCurrentPartialObservationAttributes(
                  'POSSESSION'
                );
              if (!partialObservationAttributeBag) {
                return;
              }

              const newTeamId = {
                teamId:
                  teamSwitchMap[partialObservationAttributeBag.get('teamId')],
              };

              const command = Command.create(
                'ObservationLogger.ToggleRangeObservationExclusive',
                {
                  groupId: 'POSSESSION',
                  code: 'POSSESSION',
                  attributes: newTeamId,
                }
              );
              CommandBus.dispatch(command);
            }
            break;
          case 'POSSESSION':
            CommandBus.dispatch(
              Command.create('ObservationLogger.SetPointObservation', {
                code: 'START-POSSESSION',
                attributes: { teamId: attributes.teamId },
                description: 'Start balbezit',
              })
            );
            break;
        }
      }
    );

    observationLogger.on('descriptionRequested', (code, attributes) => {
      let team, player;
      if (attributes.has('teamId')) {
        if (attributes.get('teamId') === homeTeam.teamId) {
          team = homeTeam;
        } else {
          team = awayTeam;
        }
      } else {
        const partialObservationAttributeBag =
          observationLogger.getCurrentPartialObservationAttributes(
            'POSSESSION'
          );
        if (!!partialObservationAttributeBag) {
          if (
            partialObservationAttributeBag.get('teamId') === homeTeam.teamId
          ) {
            team = homeTeam;
          } else {
            team = awayTeam;
          }
        }
      }

      if (attributes.has('personId')) {
        player = team.players.get(attributes.get('personId'));
      }

      switch (code) {
        case 'POSSESSION':
          return `Possession ${team.label}`;
          break;
        case 'POSSESSION-BUILD-UP':
          return 'Build-Up';
        case 'POSSESSION-TURNOVER':
          return 'Turnover';
        case 'POSSESSION-FREE-HIT':
          return 'Free-hit';
        case 'REASON-PENALTY-CORNER':
          return 'Penalty corner given';
        case 'PENALTY-GIVEN': {
          let description = {
            'GREEN-CARD': 'Green card',
            'YELLOW-CARD': 'Yellow card',
            'RED-CARD': 'Red card',
          }[attributes.get('type')];

          if (!!player) {
            description += ` - ${player.fullName}`;
          } else {
            description += ` - ${team.label}`;
          }
          return description;
        }
        case 'SHOT': {
          let description = {
            FIELD: 'Goal - FG',
            'PENALTY-CORNER': 'Goal - PC',
            'PENALTY-STROKE': 'Goal - PS',
          }[attributes.get('type')];
          if (!!player) {
            description += ` - ${player.fullName}`;
          }
          return description;
        }
        default:
          throw "Don't know how to describe " + code;
      }
    });

    observationLogger.on(
      'afterObservationAdded',
      (code, attributes, triggerTime, startTime, endTime, description) => {
        switch (code) {
          case 'TIME:START-PERIOD':
          case 'TIME:END-PERIOD':
            break;

          case 'TIME:CORRECTION':
          case 'TIME:RESUME':
          case 'TIME:PAUSE':
            commandHistory.tagHistoryItem(description, {
              showNotification: false,
            });
            break;
          default:
            commandHistory.tagHistoryItem(description);
        }
      }
    );

    observationContext.on('periodStarted', (periodNr) => {
      CommandBus.dispatch(
        Command.create('ObservationLogger.SetPointObservation', {
          code: 'TIME:START-PERIOD',
          attributes: { period: periodNr },
          description: `Start period ${periodNr}`,
        })
      );
    });

    observationContext.on('periodEnded', (periodNr) => {
      CommandBus.dispatch(
        Command.create('ObservationLogger.SetPointObservation', {
          code: 'TIME:END-PERIOD',
          attributes: { period: periodNr },
          description: `End period ${periodNr}`,
        })
      );
    });
  }, []);

  if (observationContext === null) {
    return null;
  }

  const actionsPrimary = [
    {
      onClick: () => observationContext.togglePeriod(),
      children: [<Observer>{() => observationContext.periodLabel}</Observer>],
    },
  ];

  const swapSportingEventDirection = {
    onClick: () => {
      swapSportingEventStartingPosition(observationContext.sportingEvent.id);
    },
    children: [
      <Observer>
        {() => (
          // todo: Translate
          <>
            <i className="i-flip i-light" />
            {`Playing direction first period: ${
              observationContext.sportingEvent.matchConfig
                ?.firstPeriodPlayingDirection === 'HOME_AWAY'
                ? 'Left to right'
                : 'Right to left'
            }
            `}
          </>
        )}
      </Observer>,
    ],
  };

  const useTaggingData =
    observationContext.sportingEvent.get('tags')?.automaticCameraConfig
      ?.useTaggingData;

  // Show only when using automatic camera with useTaggingData, do not change when clock has started.
  if (
    observationContext.sportingEvent.get('tags')?.automaticCameraConfig
      ?.record &&
    observationContext.sportingEvent.get('tags')?.automaticCameraConfig
      ?.useTaggingData &&
    observationContext.currentState === 'NOT_STARTED'
  ) {
    actionsPrimary.push(swapSportingEventDirection);
  }

  const actionsSecondary = [
    // {
    //   onClick: () => observationContext.togglePeriod(),
    //   children: <i className="i-time-out" />,
    // },
    {
      onClick: () =>
        CommandHistory.instance().undoToPreviousTaggedHistoryItem(),
      children: <i className="i-undo2" />,
    },

    // {
    //   onClick: console.debug,
    //   children: <i className="i-export" />,
    // },
  ];

  return (
    <>
      <LiveObservationView
        actionsPrimary={actionsPrimary}
        actionsSecondary={actionsSecondary}
      >
        <LiveObservationInputHockeyInnerView
          observationLogger={observationContext.observationLogger}
          homeTeam={observationContext.homeTeam}
          awayTeam={observationContext.awayTeam}
          useTaggingData={useTaggingData}
        />
      </LiveObservationView>
    </>
  );
};

const swapSportingEventStartingPosition = async (sportingEventId) => {
  const sportingEvent = await sportingEventCollection.getOrFetch(
    sportingEventId
  );
  await sportingEvent.setMatchConfig({
    ...sportingEvent.matchConfig,
    firstPeriodPlayingDirection:
      sportingEvent.matchConfig.firstPeriodPlayingDirection === 'HOME_AWAY'
        ? 'AWAY_HOME'
        : 'HOME_AWAY',
  });
};

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'hockey.live.default',
  version: 2,
  component: LiveObservationInputHockeyView,
  title: 'Hockey Live',
  options,
  usedKeys: [
    '1',
    '2',
    'q',
    'w',
    'e',
    'a',
    's',
    'd',
    'f',
    'z',
    'x',
    'c',
    'v',
    'b',
    'n',
  ],
  previewImgSrc,
});
