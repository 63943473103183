import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { observer, Observer } from 'mobx-react';

import { withTranslation, Trans } from 'react-i18next';

import { Session } from '../../../domain/Session';
import logger from '../../../utils/Logger';
import Loading from 'lib/Loading';
import { Modal } from 'lib/Modal';

import SportingEventCollection from '../../../domain/SportingEvent';
import VideoCollection from '../../../domain/Video';
import { DataSyncView } from '../../data-sync';

import { EventPublisher } from 'utils/EventPublisher';
import { Page } from 'lib/Page';
import { SportingEventVideoPlayerHOC } from 'modules/play/components/SportingEventVideoPlayerHOC';
import { Error } from 'lib/PlaceHolder';
import _ from 'lodash';
import { getRoutePath } from '../../route';
import VideoAngleContextProvider from '../../../lib/VideoAngleContextProvider';

const OTHER_OBSERVATIONS_KEY = 'unknown';

const Match = withTranslation('module.play')(
  observer(
    class Match extends Component {
      constructor() {
        super();

        this.sportingEvent = null;
        this.report = null;

        this.dataState = observable('loading');
      }

      componentWillMount() {
        this.loadData();
      }

      get sportingEventId() {
        return this.props.match.params.sportingEventId;
      }

      getOverviewUrl(type) {
        switch (type) {
          case 'training':
            return getRoutePath('trainings');
          case 'recording':
            return getRoutePath('recordings');
          case 'match':
          default:
            return getRoutePath('match');
        }
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          this.sportingEvent = yield SportingEventCollection.getOrFetch(
            this.sportingEventId
          );
          yield this.sportingEvent.syncVideos();

          yield this.sportingEvent.loadTeams();

          const videoId = this.sportingEvent.mainVideoId();

          yield VideoCollection.fetchIfEmpty();
          this.video = VideoCollection.get(videoId);
          this.multiCam = this.sportingEvent.multiVideo();

          this.dataState.set('loaded');

          EventPublisher.dispatch('VIDEO_PLAYED');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to play Match',
          });
          this.dataState.set('error');
        }
      });

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const actions = [];

        const pageProps = {
          noTopPadding: false,
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            {
              path: this.getOverviewUrl(this.sportingEvent.type),
              title: _.startCase(
                this.props.t(
                  `module.match:typePlural.${this.sportingEvent.type}`
                )
              ),
            },
            {
              title: this.sportingEvent.label,
            },
          ],
        };

        return (
          <Page className={'page--play-match'} {...pageProps}>
            <VideoAngleContextProvider>
              <SportingEventVideoPlayerHOC
                sportingEvent={this.sportingEvent}
                video={this.video}
                multiCam={this.multiCam}
              >
                {({
                  videoPlayer,
                  reload,
                  isLoaded,
                  categorySelector,
                  labelSelector,
                  observationContext,
                  filter,
                }) => {
                  if (!isLoaded) {
                    return (
                      <Loading
                        type={'fullscreen'}
                        color={'var(--primary-color)'}
                      />
                    );
                  }
                  return (
                    <Fragment>
                      <div className="video-player-container play-match-main">
                        {videoPlayer}

                        <div className="row match-video-actions">
                          {categorySelector}
                        </div>
                        <div className="">{filter}</div>

                        {labelSelector}
                      </div>
                      {Session.current().isFeatureAvailable('liveObserve') && (
                        <DataSyncView
                          onSynced={() => {
                            reload();
                            observationContext.clock.play();
                            EventPublisher.dispatch('LIVE_DATA_SYNCED');
                          }}
                          video={this.video}
                          observationContext={observationContext}
                        />
                      )}
                    </Fragment>
                  );
                }}
              </SportingEventVideoPlayerHOC>
            </VideoAngleContextProvider>
          </Page>
        );
      }
    }
  )
);

export { Match };
