import { Model } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';

class TrainingTemplate extends Model {
  get primaryKey() {
    return 'trainingTemplateId';
  }

  get trainingTemplateId() {
    return this.get('trainingTemplateId');
  }

  get title() {
    return this.get('title');
  }

  get themeId() {
    return this.get('themeId');
  }

  get categoryId() {
    return this.get('categoryId');
  }

  get exercises() {
    return this.get('exercises');
  }
}

class TrainingTemplateCollection extends PersistentCollection {
  url() {
    return '/trainingTemplates';
  }

  model() {
    return TrainingTemplate;
  }

  addTrainingTemplate(title) {
    return this.create(
      {
        title,
      },
      { optimistic: false }
    );
  }
}

console.log('init trainingTemplate collection');
const trainingTemplateCollection = new TrainingTemplateCollection();
window.trainingTemplateCollection = trainingTemplateCollection;

export { trainingTemplateCollection };
