import sportingEventCollection from '../../../domain/SportingEvent';
import { useEffect, useState } from 'react';
import Loading from '../../../lib/Loading';
import { gotoRoute } from '../../route';

export const DemoRedirect = () => {
  const [demoMatchId, setDemoMatchId] = useState(null);
  useEffect(() => {
    (async () => {
      await sportingEventCollection.fetchIfEmpty();
      const demoMatch = sportingEventCollection.find((sportingEvent) =>
        sportingEvent.isDemo()
      );
      if (demoMatch) {
        setDemoMatchId(demoMatch.id);
      } else {
        setDemoMatchId('empty');
      }
    })();
  });

  if (!demoMatchId) {
    return <Loading type={'fullscreen'} />;
  } else {
    if (demoMatchId !== 'empty') {
      return gotoRoute('play.match', { sportingEventId: demoMatchId });
    } else {
      gotoRoute('home');
    }
  }
};
