import adapter from 'mobx-rest-fetch-adapter';
import { apiClient } from 'mobx-rest';
import axios, { AxiosRequestConfig } from 'axios';

import { AuthAwareAdapterProxy } from './AuthAwareAdapterProxy';
import apiPath from './ApiEndpoint';
import auth from './Authentication';
import { Session } from 'domain/Session';

const adapterProxy = new AuthAwareAdapterProxy({ ...adapter, apiPath });

// build mobx-rest api client
apiClient({
  get: adapterProxy.get.bind(adapterProxy),
  post: adapterProxy.post.bind(adapterProxy),
  del: adapterProxy.del.bind(adapterProxy),
  put: adapterProxy.put.bind(adapterProxy),
  patch: adapterProxy.patch.bind(adapterProxy),
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const currentSession = Session.current();

  config.headers.Authorization = `Bearer ${auth.getAccessToken()}`;

  if (AuthAwareAdapterProxy.forceResourceGroupId) {
    config.headers['X-Resource-Group-Id'] =
      AuthAwareAdapterProxy.forceResourceGroupId;
  } else if (currentSession.resourceGroupId()) {
    config.headers['X-Resource-Group-Id'] = currentSession.resourceGroupId();
  } else {
    if (currentSession.possibleResourceGroupId()) {
      config.headers[
        'X-Try-Resource-Group-Id'
      ] = currentSession.possibleResourceGroupId();
    }
  }
  return config;
};

export const api = axios.create({
  baseURL: `${apiPath}/`,
});

// @ts-ignore NOTE: type definition is not up-to-date
api.interceptors.request.use(authRequestInterceptor, null, {
  synchronous: true,
});

export default api;
