import { Model } from 'mobx-rest';

import { PersistentCollection } from '../PersistentCollection';

class CustomTag extends Model {
  get primaryKey() {
    return 'customTagId';
  }

  get key() {
    return this.get('key');
  }

  get description() {
    return this.get('description');
  }

  get durationBefore() {
    return this.get('durationBefore');
  }

  get durationAfter() {
    return this.get('durationAfter');
  }
}

class CustomTagCollection extends PersistentCollection {
  url() {
    return '/customTags';
  }

  model() {
    return CustomTag;
  }

  sorted() {
    const customTags = this.toArray();
    customTags.sort((a, b) => a.description.localeCompare(b.description));
    return customTags;
  }
}

const customTagCollection = new CustomTagCollection();
window.customTagCollection = customTagCollection;

export default customTagCollection;
