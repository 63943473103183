import React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { LicenseTypeSelect } from './type-select';
import { ProductSelect } from './product-select';

const EditLicenseForm = withTranslation('module.reseller')(
  observer(({ t, values, onSubmit, onCancel }) => (
    <form className="card" onSubmit={onSubmit}>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="licenseType">{t('licenseType')}</label>
              <LicenseTypeSelect
                t={t}
                className="form-control"
                name="licenseType"
                value={values.licenseType}
                disabled
              />
            </div>
          </div>
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="productId">{t('products')}</label>
              <ProductSelect
                t={t}
                className="form-control"
                name="productId"
                value={values.productId}
                disabled
              />
            </div>
          </div>
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="licenseStart">{t('licenseStart')}</label>
              <DatePicker
                id="new-clubs-license-start"
                name="licenseStart"
                className="form-control-datepicker"
                selected={values.licenseStart}
                onChange={action(value => {
                  values.licenseStart = value;
                })}
                autoComplete="off"
                isClearable={false}
                placeholderText={t('licenseStart')}
                popperPlacement="top"
                disabled
              />
            </div>
          </div>
          <div className="col-12">
            <div className={`form-group`}>
              <label htmlFor="licenseEnd">{t('licenseEnd')}</label>
              <DatePicker
                id="edit-tenant-license-end"
                name="licenseEnd"
                className="form-control-datepicker"
                selected={values.licenseEnd}
                onChange={action(value => {
                  values.licenseEnd = value;
                })}
                autoComplete="off"
                isClearable={false}
                placeholderText={t('licenseEnd')}
                popperPlacement="top"
              />
            </div>
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            {t('common:save')}
          </button>
          <button type="button" className="btn btn-link" onClick={onCancel}>
            {t('common:cancel')}
          </button>
        </div>
      </div>
    </form>
  ))
);

export { EditLicenseForm };
