import { Modal } from '../../../lib';
import { useRef, useState } from 'react';
import { Tooltip } from '../../../lib/Tooltip';
import { useTranslation } from 'react-i18next';
import { writeToClipboard } from '../../../lib/writeToClipboard';

export const ReportHTML = ({ content, onClose }) => {
  const [copyState, setCopyState] = useState(null);
  const textToCopy = useRef();

  const { t } = useTranslation('common');
  return (
    <Modal size={'lg'} onCloseClick={onClose}>
      <div className="modal-header">
        <Tooltip
          tooltip={
            !copyState
              ? t('copy')
              : copyState === 'success'
              ? t('copySuccess')
              : t('copyFailed')
          }
        >
          <button
            className={'btn btn-primary'}
            onClick={() =>
              setCopyState(writeToClipboard(content) ? 'success' : 'failed')
            }
          >
            {t('copy')}
          </button>
        </Tooltip>
      </div>
      <div ref={textToCopy} dangerouslySetInnerHTML={{ __html: content }} />
    </Modal>
  );
};
