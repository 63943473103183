import { Model } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';

import SHIELD_IMAGE from '../img/icons/club_placeholder.svg';

// const SHIELD_IMAGE = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJtNTAgOS0zMS4yNSAxMWMtNy45MTggMjUuOTM0IDAuOTAyMzQgNTkuNzg1IDMxLjI1IDcxIDMwLjM1Mi0xMS4yMTUgMzkuMTY4LTQ1LjA2NiAzMS4yNS03MXoiLz4KPC9zdmc+";

class Tenant extends Model {
  get primaryKey() {
    return 'tenantId';
  }

  get tenantId() {
    return this.get('tenantId');
  }

  get name() {
    return this.has('name') ? this.get('name') : this.tenantId;
  }

  get licenses() {
    return this.get('licenses');
  }

  activeLicense() {
    const now = new Date();
    return this.get('licenses').filter(license => {
      return (
        new Date(license.startDate) <= now && now <= new Date(license.endDate)
      );
    });
  }

  lastLicense() {
    const licenses = this.get('licenses');
    licenses.sort((a, b) => {
      return a < b ? -1 : b < a ? 1 : 0;
    });
    return licenses[0];
  }

  // get the last active license
  get license() {
    const activeLicenses = this.activeLicense();
    if (activeLicenses.length > 0) {
      return {
        status:
          new Date(activeLicenses[0].endDate) / 1000 - new Date() / 1000 <
          7 * 24 * 3600
            ? 'almost-inactive'
            : 'active',
        ...activeLicenses[0],
        productIds: activeLicenses.map(license => license.productId),
      };
    }

    const lastLicense = this.lastLicense();
    if (lastLicense) {
      return {
        status: 'inactive',
        ...lastLicense,
        productIds: [lastLicense.productId],
      };
    }

    return {
      status: 'none',
    };
  }
  // grantLicense(licenseType, productId, startDate, endDate) {
  grantLicense({ licenseType, productId, startDate, endDate }) {
    return this.rpc('grantLicense', {
      licenseType,
      productId,
      startDate,
      endDate,
    }).then(() => this.fetch());
  }

  revokeLicense(licenseId) {
    return this.rpc('revokeLicense', {
      licenseId,
    }).then(() => this.fetch());
  }

  changeLicenseEndDate({ licenseId, endDate }) {
    return this.rpc('changeLicenseEndDate', {
      licenseId,
      endDate,
    }).then(() => {
      return this.fetch();
    });
  }

  extendLicense(licenseId, period) {
    return this.rpc('extendLicense', {
      licenseId,
      period,
    }).then(() => {
      return this.fetch();
    });
  }

  convertLicense(
    licenseId,
    newLicenseType,
    period = '1 year',
    conversionDate = null
  ) {
    return this.rpc('convertLicense', {
      licenseId,
      newLicenseType,
      period,
      conversionDate,
    }).then(() => {
      return this.fetch();
    });
  }
}

class TenantCollection extends PersistentCollection {
  url() {
    return '/tenants';
  }

  model() {
    return Tenant;
  }
}

const tenantCollection = new TenantCollection();
window.tenantCollection = tenantCollection;

export { tenantCollection };
