import React from 'react';
import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import './styles/index.scss';
import {
  TeamManagement,
  UserSettings,
  UserProfile,
  Invitation,
  AcceptInvitation,
  TeamTagManagement,
  IncomingShares,
} from './containers';
import { TeamManagementOverview } from './team-management';
const MODULE_NAME = 'user-management';

export default new Module(
  MODULE_NAME,
  [],
  [
    new RouteRedirect('team', 'team/all'),
    new RouteExact('team/:roleFilter', TeamManagement, 'team'),
    new RouteExact(
      'admins',
      ({ ...props }) => <TeamManagement manageAdmins {...props} />,
      'admins'
    ),
    new RouteRedirect('user-settings', 'user-settings/memberships'),
    new RouteExact('user-settings/:page', UserSettings, 'user-settings'),
    new RouteExact(
      'invitation/accept/:token',
      AcceptInvitation,
      'accept-invitation'
    ),

    new RouteExact('user-profile', UserProfile, 'user-profile'),
    new RouteExact('invitation/:token', Invitation, 'invitation', {
      protectedRoute: false,
    }),
    new RouteExact('team-tags', TeamTagManagement, 'team-tag-management'),
    new RouteExact('teams', TeamManagementOverview, 'teams-management'),
    new RouteExact('shares/incoming', IncomingShares, 'incoming-shares'),
    new RouteExact(
      'shares/incoming/request',
      IncomingShares,
      'incoming-shares',
      {
        defaultProps: {
          requestModalOpen: true,
        },
      }
    ),
  ]
);
