import React from 'react';

import { Session } from 'domain/Session';
import clubPlaceholderIcon from '../../../img/icons/club_placeholder.svg';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
const ClubInfo = ({ t }) => {
  const session = Session.current();
  const membership = session.currentMembership();
  if (!membership) {
    return null;
  }
  return (
    <div className={'user-club'}>
      <div className="picture-team">
        <img src={session.currentMembershipLogo() || clubPlaceholderIcon} />
      </div>
      <div>
        <h3>{membership.targetResourceName}</h3>
        <h4>
          <Trans
            i18nKey="menu:activeRole"
            values={{ roleName: membership.roleNames[0].toLowerCase() }}
          >
            Your role: <em>Trainer</em>
          </Trans>
        </h4>
      </div>
    </div>
  );
};

export default ClubInfo;
