import React, { Component, Fragment } from 'react';

import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { asyncAction } from 'mobx-utils';

import { Session } from 'domain/Session';
import logger from 'utils/Logger';
import Loading from 'lib/Loading';

import { OverviewItem } from 'lib/OverviewItem';
import userPlaceHolder from 'img/icons/profile-picture-placeholder.svg';

import PersonCollection from 'domain/Person';
import TeamCollection from 'domain/Team';
import { getRoutePath, gotoRoute } from 'modules/route';
import { Page } from 'lib/Page';
import { withTranslation } from 'react-i18next';

import noPayersImg from 'img/no_teams.svg';
import { Error, NoResults } from 'lib/PlaceHolder';

const PDPOverviewPage = withTranslation('module.development')(
  observer(
    class PDPOverviewPage extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');
          const currentSession = Session.current();

          yield currentSession.isReady();

          const teamId = currentSession.currentObjectId();

          this.team = yield TeamCollection.getOrFetch(teamId);

          yield Promise.all([
            this.team.players.fetchIfEmpty(),
            PersonCollection.fetchIfEmpty(),
          ]);

          this.players = this.team.players.toArray();

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'unable to load personal overview',
          });
          this.dataState.set('error');
        }
      });

      componentDidMount() {
        this.loadData();
      }

      gotoDetailPage(personId) {
        gotoRoute('development.pdp-start', { personId });
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { title: this.props.t('playerDevelopment') },
          ],
        };

        return (
          <Page {...pageProps}>
            {this.players.length === 0 && (
              <NoResults
                imgSrc={noPayersImg}
                description={this.props.t('noResults')}
              />
            )}
            {this.players.map((player) => (
              <PlayerRow
                onClick={() => this.gotoDetailPage(player.personId)}
                player={player}
                t={this.props.t}
                key={player.id}
              />
            ))}
          </Page>
        );
      }
    }
  )
);

const PlayerRow = ({ t, player, onClick }) => {
  const itemProps = {
    title: player.fullName,
    icon: player.profilePictureUrl || userPlaceHolder,

    features: t('common.role:player'),
    onClick,
  };

  return <OverviewItem {...itemProps} />;
};

export { PDPOverviewPage };
