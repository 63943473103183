import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { PersonalPage } from './containers';
import './styles/index.scss';

const MODULE_NAME = 'home';

export default new Module(
  MODULE_NAME,
  [
    {
      title: 'Home',
    },
  ],
  [new RouteRedirect('', 'me'), new RouteExact('me', PersonalPage, 'me')]
);
