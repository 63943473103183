import React, { useState, useCallback } from 'react';

import { Notification } from 'lib/Notification';
import { Modal } from 'lib/Modal';
import { EventPublisher } from 'utils/EventPublisher';

import messageRegistry from './MessageRegistry';
import './messages';
import { withTranslation } from 'react-i18next';

const MessageModal = withTranslation()(({ t, message, onCloseClick }) => {
  const [step, setStep] = useState(0);

  const ctaClicked = () => {
    EventPublisher.dispatch('UPCOMING_FEATURE_TEST__CTA_CLICKED', message.key);
    setStep(1);
  };

  const keepMeInformed = () => {
    EventPublisher.dispatch(
      'UPCOMING_FEATURE_TEST__KEEP_ME_INFORMED',
      message.key
    );

    Notification.show(
      'Bedankt voor je feedback! We houden je op de hoogte over deze functie.',
      4000
    );
    onCloseClick();
  };

  const title = t(`${message.i18nNamespace}:title`);
  const description = t(`${message.i18nNamespace}:description`);
  const ctaLabel = t(`${message.i18nNamespace}:ctaLabel`);

  return (
    <Modal size="lg" onCloseClick={onCloseClick} contrastingCloseBtn>
      <div>
        <div className="modal-intro">
          <img src={message.image} alt="header img" />
          {/* <iframe height="420" src="https://www.youtube.com/embed/c1cMgNm9I8A" name="everybody-knew" /> */}
        </div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className="row modal-footer">
        <div className="col-12 form-actions">
          <button className="btn btn-primary" onClick={ctaClicked}>
            {ctaLabel}
          </button>
          {step > 0 && (
            <>
              <span className="mx-3">
                Deze functie is momenteel nog niet beschikbaar.
              </span>
              <button
                className="btn btn-link"
                style={{ textDecoration: 'underline' }}
                onClick={keepMeInformed}
              >
                Hou me op de hoogte.
              </button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
});

const MarketingToggle = withTranslation()(({ t, messageKey, onClick }) => {
  const [isOpen, setOpen] = useState(false);

  const message = messageRegistry.getMessage(messageKey);

  const handleClick = useCallback(() => {
    EventPublisher.dispatch('UPCOMING_FEATURE_TEST__TRIGGERED', message.key);

    setOpen(true);
    onClick();
  }, [onClick]);

  const triggerLabel = t(`${message.i18nNamespace}:triggerLabel`);

  return (
    <>
      <span
        className="nav-link clickable marketing-wiggle"
        onClick={handleClick}
      >
        {triggerLabel}
      </span>
      {isOpen && (
        <MessageModal message={message} onCloseClick={() => setOpen(false)} />
      )}
    </>
  );
});

export { MarketingToggle };
