import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import HotKey from 'react-shortcut';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';
import { InputTrigger } from '../components/InputTrigger';
import { CommandBus, Command, CommandHistory } from '../../../infra/Messaging';
import {
  CustomTagButton,
  PointTriggerButton,
  RangeTriggerButton,
} from 'utils/ObservationInput/components/TriggerButton';

import {
  VideoObservationPanel,
  PossessionButtonGroup,
  EventButtonGroup,
} from 'lib/ObservationPanel/Video';

const HandballObservationInput = observer(
  class HandballObservationInput extends Component {
    componentWillMount() {
      const {
        observationLogger,
        homeTeam,
        awayTeam,
      } = this.props.observationContext;

      observationLogger.on(
        'afterObservationAdded',
        (observationCode, attributes) => {
          switch (observationCode) {
            case 'SHOT':
              if (attributes.get('result') === 'GOAL') {
                this.switchPossession();
              }
              break;

            case 'BALL-LOSS':
              this.switchPossession();

              break;
          }
        }
      );

      observationLogger.on('descriptionRequested', (code, attributes) => {
        switch (code) {
          case 'POSSESSION':
            if (attributes.has('outNumbered') && attributes.has('goalKeeper')) {
              if (attributes.get('outNumbered') === 'OVER') {
                if (attributes.get('goalKeeper') === 'OUT') {
                  return 'Overtal en Empty goal';
                }
              }
              if (attributes.get('outNumbered') === 'UNDER') {
                if (attributes.get('goalKeeper') === 'OUT') {
                  return 'Ondertal en Empty goal';
                }
              }
            }

            if (
              attributes.has('outNumbered') &&
              !attributes.has('goalKeeper')
            ) {
              if (attributes.get('outNumbered') === 'OVER') {
                return 'Aanvallen Overtal';
              }
              if (attributes.get('outNumbered') === 'UNDER') {
                return 'Aanvallen Ondertal';
              }
            }

            if (
              !attributes.has('outNumbered') &&
              attributes.has('goalKeeper')
            ) {
              if (attributes.get('goalKeeper') === 'OUT') {
                return 'Aanvallen Empty goal';
              }
            }

            return 'Aanvallen';
            break;

          default:
            throw "Don't know how to describe " + code;
        }
      });

      observationLogger.on(
        'afterObservationAdded',
        (code, attributes, triggerTime, startTime, endTime, description) => {
          CommandHistory.instance().tagHistoryItem(description);
        }
      );
    }

    switchPossession() {
      const {
        observationLogger,
        homeTeam,
        awayTeam,
      } = this.props.observationContext;

      const teamSwitchMap = {
        [homeTeam.id]: awayTeam.id,
        [awayTeam.id]: homeTeam.id,
      };
      const partialObservationAttributeBag = observationLogger.getCurrentPartialObservationAttributes(
        'POSSESSION'
      );
      if (!partialObservationAttributeBag) {
        return;
      }

      const newTeamId = {
        teamId: teamSwitchMap[partialObservationAttributeBag.get('teamId')],
      };

      const command = Command.create(
        'ObservationLogger.ToggleRangeObservationExclusive',
        {
          groupId: 'POSSESSION',
          code: 'POSSESSION',
          attributes: newTeamId,
        }
      );
      CommandBus.dispatch(command);
    }

    render() {
      const homeTeam = this.props.observationContext.homeTeam;
      const awayTeam = this.props.observationContext.awayTeam;
      const observationLogger = this.props.observationContext.observationLogger;

      return (
        <VideoObservationPanel
          observationContext={this.props.observationContext}
        >
          <PossessionButtonGroup>
            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: homeTeam.id }}
              observationLogger={observationLogger}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable set-homepossession-button label ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['1']} onKeysPressed={trigger} />
                  Balbezit {homeTeam.shortLabel}
                  <span className="button-hotkey">1</span>
                  <img className="i" src={homeTeam.logoUrl} />
                </div>
              )}
            />

            <InputTrigger
              type="toggle-range"
              observationCode="POSSESSION"
              groupId="POSSESSION"
              attributes={{ teamId: awayTeam.id }}
              observationLogger={observationLogger}
              render={({ active, trigger }) => (
                <div
                  onClick={trigger}
                  className={`clickable label label-i-lft ${
                    active ? 'active' : ''
                  } `}
                >
                  <HotKey keys={['2']} onKeysPressed={trigger} />
                  <img className="i" src={awayTeam.logoUrl} />
                  Balbezit {awayTeam.shortLabel}
                  <span className="button-hotkey">2</span>
                </div>
              )}
            />
          </PossessionButtonGroup>
          <EventButtonGroup>
            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ result: 'MISS' }}
              observationLogger={observationLogger}
              description="Schot mis"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="c"
                />
              )}
            />
            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ result: 'GOAL' }}
              observationLogger={observationLogger}
              description="Goal"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  description="Schot goal"
                  keys="v"
                />
              )}
            />

            <PointTriggerButton
              classNames="tag-standardtag-button"
              trigger={() => this.switchPossession()}
              description="Balveries na schot"
              keys="n"
            />

            <InputTrigger
              type="point"
              observationCode="BALL-LOSS"
              attributes={{ type: 'TECHNICAL-FAULT' }}
              observationLogger={observationLogger}
              description="Technische fout"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="t"
                />
              )}
            />

            <InputTrigger
              type="point"
              observationCode="FOUL"
              observationLogger={observationLogger}
              description="Overtreding"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="o"
                />
              )}
            />
            <InputTrigger
              type="point"
              observationCode="PENALTY-GIVEN"
              observationLogger={observationLogger}
              description="7-meter gegeven"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="d"
                />
              )}
            />
            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ type: 'PENALTY', result: 'MISS' }}
              observationLogger={observationLogger}
              description="7-meter mis"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="f"
                />
              )}
            />
            <InputTrigger
              type="point"
              observationCode="SHOT"
              attributes={{ type: 'PENALTY', result: 'GOAL' }}
              observationLogger={observationLogger}
              description="Goal"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  description="7-meter goal"
                  keys="g"
                />
              )}
            />
            <InputTrigger
              type="set-attributes"
              groupId="POSSESSION"
              observationCode="POSSESSION"
              attributes={{ goalKeeper: 'OUT' }}
              observationLogger={observationLogger}
              description="Empty goal"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="7"
                />
              )}
            />
            <InputTrigger
              type="set-attributes"
              groupId="POSSESSION"
              observationCode="POSSESSION"
              attributes={{ outNumbered: 'OVER' }}
              observationLogger={observationLogger}
              description="Overtal"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="8"
                />
              )}
            />
            <InputTrigger
              type="set-attributes"
              groupId="POSSESSION"
              observationCode="POSSESSION"
              attributes={{ outNumbered: 'UNDER' }}
              observationLogger={observationLogger}
              description="Ondertal"
              render={props => (
                <PointTriggerButton
                  classNames="tag-standardtag-button"
                  {...props}
                  keys="9"
                />
              )}
            />
          </EventButtonGroup>
        </VideoObservationPanel>
      );
    }
  }
);

const options = new ObservationInputOptions(false, false, true, false, true);

export default new ObservationInput({
  name: 'handball.video.default',
  component: HandballObservationInput,
  options,
  title: 'Handball Video',
  usedKeys: ['1', '2', '7', '8', '9', 'c', 'd', 'f', 'g', 'n', 'o', 't', 'v'],
});
