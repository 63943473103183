import React, { useEffect, useState } from 'react';
import { Modal } from '../../../lib';
import moment from 'moment';
import { Tooltip } from '../../../lib/Tooltip';
import { useQuery, useQueryClient } from 'react-query';
import api from '../../../utils/ApiClient';
import highlights from '../index';
import Loading from '../../../lib/Loading';
import { getAspectRationOptions } from '../highlightsDetail';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import { forEach } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { SubmittingButton } from 'lib/SubmittingButton';
import { confirmModal } from '../../../lib/Confirmation';
import { Session } from '../../../domain/Session';

export const Renders = ({ highlightVideo, aspectRatio }) => {
  const renders = highlightVideo.renders;

  const [modalContent, setModalContent] = useState(null);
  const { t } = useTranslation('module.highlights');

  return (
    <>
      {modalContent && (
        <Modal onCloseClick={() => setModalContent()}>{modalContent}</Modal>
      )}
      <button
        onClick={() => {
          setModalContent(
            <ScheduleRender
              aspectRatio={aspectRatio}
              highlightVideoId={highlightVideo.highlightVideoId}
              openRenders={() => {
                setModalContent(
                  <RenderList
                    highlightVideoId={highlightVideo.highlightVideoId}
                    renders={renders}
                    t={t}
                  />
                );
              }}
            />
          );
        }}
        className={'btn btn-primary'}
      >
        <i className={'i-add i-sm i-light'}></i> {t('render.new')}
      </button>
      <button
        onClick={() => {
          setModalContent(
            <RenderList
              highlightVideoId={highlightVideo.highlightVideoId}
              renders={renders}
              t={t}
            />
          );
        }}
        className={'btn btn-primary'}
        disabled={renders?.length < 1}
      >
        <i className={'i-folder i-sm'}></i>
        {t('render.title')} ({renders.length})
      </button>
    </>
  );
};

const RenderList = ({ renders, highlightVideoId, t }) => {
  const queryClient = useQueryClient();

  const [limit, setLimit] = useState(10);

  const fetchRenders = async () => {
    const { data } = await api.get(
      `/highlights/v2/highlightVideos/${highlightVideoId}`
    );
    return data.renders;
  };
  const fetchPublications = async () => {
    const { data } = await api.get(
      `/highlights/v2/highlightVideos/${highlightVideoId}/publications`
    );
    return data;
  };
  const { data: _renders, isFetching } = useQuery(
    ['highlightVideo', highlightVideoId, 'renders'],
    fetchRenders,
    {
      placeholderData: renders,
    }
  );
  const { data: publications, isFetching: isPublicationsFetching } = useQuery(
    ['highlightVideo', highlightVideoId, 'renders', 'publications'],
    fetchPublications,
    {
      placeholderData: [],
    }
  );

  const onRenderChanged = () => {
    queryClient.invalidateQueries([
      'highlightVideo',
      highlightVideoId,
      'renders',
    ]);
  };
  if (isFetching || isPublicationsFetching) {
    return <Loading />;
  }
  return (
    <div>
      <div className="modal-header">
        <h3>{t('render.title')}</h3>
      </div>
      <table className={'render__table'}>
        <thead>
          <tr>
            <th>{t('render.version')}</th>
            <th>
              <i className={'i-aspectratio'} />{' '}
            </th>
            <th>{t('render.updated')}</th>
            <th>{t('render.status')}</th>
          </tr>
        </thead>
        <tbody>
          {_renders
            ?.sort((a, b) => {
              return b.createdAt.localeCompare(a.createdAt);
            })
            .slice(0, limit)
            .map((render) => {
              return (
                <RenderJobState
                  key={render.renderId}
                  render={render}
                  highlightVideoId={highlightVideoId}
                  onRenderChanged={onRenderChanged}
                  canPublish={publications.length === 0}
                  publication={publications.find((publication) => {
                    return publication.sourceTrn.endsWith(
                      `/renders/${render.renderId}`
                    );
                  })}
                />
              );
            })}
        </tbody>
      </table>
      {_renders.length > limit && (
        <button
          className={'btn btn-primary'}
          onClick={() => setLimit(renders.length)}
        >
          {t('render.showOlder')}
        </button>
      )}
    </div>
  );
};

const RenderJobState = ({
  render,
  highlightVideoId,
  publication,
  canPublish,
  onRenderChanged,
}) => {
  const { t } = useTranslation('module.highlights');
  const fetchRender = async () => {
    const { data } = await api.get(
      `/highlights/v2/highlightVideos/${highlightVideoId}/renders/${render.renderId}`
    );
    return data;
  };

  const getDownloadUrl = async () => {
    const { data } = await api.get(
      `/highlights/v2/highlightVideos/${highlightVideoId}/renders/${render.renderId}/downloadUrl`
    );
    return data;
  };

  const { data: _render, isFetching } = useQuery(
    ['highlightVideo.render', render.renderId],
    fetchRender,
    {
      placeholderData: render,
      refetchInterval: (data) =>
        data.job?.stateChanges[data.job?.stateChanges.length - 1]?.state ===
        'scheduled'
          ? 10000
          : false,
      refetchOnMount:
        render.job?.stateChanges[render.job?.stateChanges.length - 1]?.state !==
        'success',
    }
  );

  const renderJobState =
    _render.job?.stateChanges[_render.job?.stateChanges.length - 1]?.state ??
    'failed';

  const download = async (e) => {
    e.target.disabled = true;
    const downloadUrl = await getDownloadUrl();
    console.log(downloadUrl);
    window.location.href = downloadUrl;
    e.target.disabled = false;
  };

  const publish = async () => {
    const { data } = await api.post(
      `/highlights/v2/highlightVideos/${highlightVideoId}/publications`,
      {
        renderId: render.renderId,
      }
    );
    onRenderChanged();
    return data;
  };

  const unpublish = async () => {
    const { data } = await api.delete(
      `/highlights/v2/highlightVideos/${highlightVideoId}/publications/${publication.publicationId}`
    );
    onRenderChanged();
  };

  const publishAvailable = Session.current().isFeatureAvailable(
    'publishHighlightRender'
  );

  let value;
  switch (renderJobState) {
    case 'new':
      value = t('render.new');
      break;
    case 'success':
      value = (
        <>
          <button onClick={download} className={'btn btn-primary'}>
            {t('render.download')}
          </button>
          &nbsp;
          {publication && (
            <SubmittingButton
              type="button"
              className="btn btn-warning"
              onClick={unpublish}
            >
              Unpublish
            </SubmittingButton>
          )}
          {publishAvailable && canPublish && !publication && (
            <SubmittingButton onClick={publish} className="btn btn-primary">
              {t('render.publish')}
            </SubmittingButton>
          )}
        </>
      );
      break;
    case 'scheduled':
      value = t('render.processing');
      break;
    default:
      value = t('render.failed');
  }
  return (
    <tr className={'render__item'}>
      <td>{_render.versionId || '-'}</td>
      <td>{_render.renderConfig?.aspectRatio}</td>

      <td>
        <Tooltip tooltip={moment(render.updatedAt).format('DD-MM-YYYY HH:mm')}>
          {moment(render.updatedAt).from(moment())}
        </Tooltip>
      </td>
      <td>{value}</td>
    </tr>
  );
};

const ScheduleRender = ({ highlightVideoId, openRenders, aspectRatio }) => {
  const aspectRatioOptions = getAspectRationOptions();
  const [renderState, setRenderState] = useState('initial');
  const [selectedAspectRatios, setSelectedAspectRatios] = useState([
    aspectRatioOptions.find(
      (ratio) =>
        ratio.value.x === aspectRatio.x && ratio.value.y === aspectRatio.y
    ).id,
  ]);
  const queryClient = useQueryClient();

  const { t } = useTranslation('module.highlights');

  useEffect(() => {
    if (renderState === 'starting') {
      (async () => {
        for (let i = 0; i < selectedAspectRatios.length; i++) {
          await startRender(selectedAspectRatios[i]);
        }
        queryClient.invalidateQueries([
          'highlightVideos',
          highlightVideoId,
          true,
        ]);
        openRenders();
      })();
    }
  }, [renderState]);

  const startRender = async (aspectRatio) => {
    try {
      return await api.post(
        `highlights/v2/highlightVideos/${highlightVideoId}/renders`,
        { aspectRatio: aspectRatio }
      );
    } catch (e) {
      if (e.response.status !== 409) {
        throw e;
      }
    }
  };

  const modifyValues = (id) => {
    setSelectedAspectRatios((old) => {
      const index = old.indexOf(id);
      if (old.indexOf(id) !== -1) {
        return [...old.filter((item) => item !== id)];
      } else {
        return [...old, id];
      }
    });
  };

  const moveRenderState = () => {
    if (renderState === 'initial') {
      setRenderState('starting');
    }
    if (renderState === 'starting') {
      setRenderState('starting');
    }
  };

  return (
    <div>
      <div className="modal-header">
        <h3>{t('render.selectRenderOptions')}</h3>
      </div>
      {aspectRatioOptions.map((aspectRatio, index) => {
        return (
          <RenderAspectRatio
            renderState={renderState}
            key={index}
            aspectRatio={aspectRatio}
            highlightVideoId={highlightVideoId}
            onChange={modifyValues}
            selected={selectedAspectRatios.includes(aspectRatio.id)}
          />
        );
      })}
      <div className="form-actions">
        <button
          disabled={renderState != 'initial' || selectedAspectRatios.length < 1}
          onClick={moveRenderState}
          className="btn btn-primary"
        >
          {t('render.startRender')}
        </button>
      </div>
    </div>
  );
};

const RenderAspectRatio = ({
  renderState,
  aspectRatio,
  onChange,
  selected,
}) => {
  return (
    <>
      <InputCheckboxRadio
        key={aspectRatio.label}
        label={
          <>
            {renderState === 'starting' && selected && (
              <Loading
                type={'spinner'}
                color={'var(--primary-color)'}
                size={20}
              />
            )}
            {aspectRatio.label}
          </>
        }
        disabled={renderState !== 'initial'}
        onChange={(v) => {
          onChange(aspectRatio.id);
        }}
        checked={selected}
      />
    </>
  );
};
