import React, { useState } from 'react';
import { asyncAction } from 'mobx-utils';
import { Session } from '../../../domain/Session';
import Loading from '../../../lib/Loading';
import Lottie from 'react-lottie';

import emailSentLottie from 'lib/animations/lottie/emailsent.json';
import emailSentLottieSR from 'lib/animations/lottie/emailsent_sr.json';
import { useTranslation } from 'react-i18next';

export const ProfileVerifyEmail = ({ user }) => {
  const [requestState, setRequestState] = useState('initial');
  const { t } = useTranslation('module.settings.profile');

  const sendEmailverification = async () => {
    if (await user.requestEmailVerification()) {
      setRequestState('finished');
    } else {
      setRequestState('error');
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: window.isSkillReflect ? emailSentLottieSR : emailSentLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (requestState === 'initial') {
    return (
      <div className="userlogin__warning">
        <label>{t('verify.label')}</label>
        <p>{t('verify.body')}</p>
        <a
          className={'btn secondary btn-cta'}
          href={'#'}
          onClick={() => {
            setRequestState('loading');
            sendEmailverification();
          }}
        >
          {t('verify.cta')}
        </a>
      </div>
    );
  }
  if (requestState === 'loading') {
    return <Loading type={'spinner'} size={40} />;
  }
  if (requestState === 'finished') {
    return (
      <div className="userlogin__warning userlogin__warning--success">
        <div className="d-flex justify-content-start">
          <Lottie
            style={{
              height: 50,
              width: 50,
              flexGrow: 0,
              flexShrink: 0,
              marginRight: '1em',
              marginLeft: '0px',
            }}
            options={defaultOptions}
          />
          <div>
            <label>
              {t('verify.successLabel')}
              <i className={'primary'}>{user.email}</i>.
            </label>
            <p> {t('verify.successBody')}</p>
          </div>
        </div>
      </div>
    );
  }
  if (requestState === 'error') {
    return (
      <div className="userlogin__warning">
        <div>
          <label> {t('verify.errorLabel')}</label>
          <p> {t('verify.errorLabel')}</p>
        </div>
      </div>
    );
  }
};
