import React from 'react';

import crossIcon from '../../../img/icons/cross.svg';
import substitionIcon from '../../../img/icons/substitution.svg';
import timeoutIcon from '../../../img/icons/time_out.svg';
import eventIcon from '../../../img/icons/event.svg';
import undoIcon from '../../../img/icons/undo.svg';
import { withTranslation } from 'react-i18next';

const ButtonBar = withTranslation('module.observe.live')(
  ({ actionHandler, periodLabel, observationInputOptions, t }) => {
    return (
      <div className="invoer-buttons relative break-after text-center">
        {observationInputOptions.canEnterBallLoss && (
          <div className="invoer-button">
            <div className="flex-content">
              <div
                className="text-center relative invoer-button-inner ballloss"
                onClick={() => actionHandler('ball-loss')}
              >
                <div className="invoer-button-icon">
                  <img src={crossIcon} />
                </div>
                <div className="invoer-button-text">{t('ball-loss')}</div>
              </div>
            </div>
          </div>
        )}
        {observationInputOptions.canSubstitePlayer && (
          <div className="invoer-button">
            <div className="flex-content">
              <div
                className="text-center relative invoer-button-inner substitute"
                onClick={() => actionHandler('substitutePlayer')}
              >
                <div className="invoer-button-icon">
                  <img src={substitionIcon} />
                </div>
                <div className="invoer-button-text">
                  {t('substitutePlayer')}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`invoer-button height-100 ${!observationInputOptions.canManageTime &&
            'disabled'}`}
        >
          <div className="flex-content">
            <div
              className="text-center relative invoer-button-inner period"
              onClick={() =>
                observationInputOptions.canManageTime &&
                actionHandler('togglePeriod')
              }
            >
              <div className="invoer-button-icon">
                <img src={timeoutIcon} />
              </div>
              <div className="invoer-button-text">{periodLabel}</div>
            </div>
          </div>
        </div>
        {observationInputOptions.canEnterEvent && (
          <div className="invoer-button">
            <div className="flex-content">
              <div
                className="text-center relative invoer-button-inner events"
                onClick={() => actionHandler('enterEvent')}
              >
                <div className="invoer-button-icon">
                  <img src={eventIcon} />
                </div>
                <div className="invoer-button-text">{t('enterEvent')}</div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`invoer-button ${!observationInputOptions.canUndo &&
            'disabled'}`}
        >
          <div className="flex-content">
            <div
              className="text-center relative invoer-button-inner undo"
              onClick={() =>
                observationInputOptions.canUndo && actionHandler('undo')
              }
            >
              <div className="invoer-button-icon">
                <img src={undoIcon} />
              </div>
              <div className="invoer-button-text">{t('common:undo')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export { ButtonBar };
