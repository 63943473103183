import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Session } from 'domain/Session';
import Loading from 'lib/Loading';
import { landingsPageForType } from './resource-group-types';

const NavigateToResourceGroupLanding = () => {
  const [landingsPage, setLandingsPage] = useState(null);

  useEffect(() => {
    const determineLandingsPage = async () => {
      const currentSession = Session.current();
      await currentSession.isReady();
      const activeGroupType = currentSession.currentResourceGroupType();
      switch (activeGroupType) {
        case null:
          setLandingsPage('/user-management/user-settings/memberships');
          break;
        default:
          const appManager = currentSession.getAppManager();
          const activeApp = appManager.getLastActiveApp();

          //if (appManager.getAppCount() > 1) {
          //  setLandingsPage('/apps/choose-app');
          //} else {
          if (activeApp.appId === 'teamtv') {
            setLandingsPage(
              landingsPageForType(activeGroupType, currentSession)
            );
          } else {
            setLandingsPage(appManager.getAppPath(activeApp));
          }
          //}
          break;
      }
    };
    determineLandingsPage();
  }, []);

  if (landingsPage === null) {
    return <Loading type={'fullscreen'} />;
  }
  return <Redirect to={landingsPage} />;
};
export { NavigateToResourceGroupLanding };
