import { Model } from 'mobx-rest';
import { PersistentCollection } from './PersistentCollection';

import SHIELD_IMAGE from '../img/icons/club_placeholder.svg';

import { apiClient } from 'mobx-rest';

// const SHIELD_IMAGE = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJtNTAgOS0zMS4yNSAxMWMtNy45MTggMjUuOTM0IDAuOTAyMzQgNTkuNzg1IDMxLjI1IDcxIDMwLjM1Mi0xMS4yMTUgMzkuMTY4LTQ1LjA2NiAzMS4yNS03MXoiLz4KPC9zdmc+";

class Member extends Model {
  get primaryKey() {
    return 'email';
  }

  isInvitationPending() {
    return this.has('invitationId');
  }

  get firstName() {
    return this.get('firstName');
  }

  get lastName() {
    return this.has('lastName') ? this.get('lastName') : '';
  }

  get email() {
    return this.get('email');
  }

  get fullName() {
    if (this.firstName !== '' || this.lastName !== '') {
      return this.firstName + ' ' + this.lastName;
    } else {
      return this.email;
    }
  }

  get userId() {
    return this.get('userId');
  }

  get invitationId() {
    return this.has('invitationId') && this.get('invitationId');
  }

  get profilePictureUrl() {
    return this.has('profilePictureUrl') ? this.get('profilePictureUrl') : '';
  }

  get roleNames() {
    return this.get('roleNames');
  }

  get locale() {
    return this.get('locale');
  }

  get invitationCreated() {
    return this.get('created');
  }

  get memberAttributes() {
    return this.has('memberAttributes') ? this.get('memberAttributes') : {};
  }
}

class MemberCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Member;
  }
}

class Role extends Model {
  get primaryKey() {
    return 'roleName';
  }

  get roleName() {
    return this.get('roleName');
  }
}

class RoleCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Role;
  }
}

class ShareRequest extends Model {
  get primaryKey() {
    return 'shareRequestId';
  }

  get targetResourceId() {
    return this.get('targetResourceId');
  }

  get isRequest() {
    return this.has('shareRequestId');
  }

  get sharingGroupId() {
    return this.targetResourceId.split(':')[1];
  }

  get roleName() {
    return this.get('roleName');
  }

  get shareAttributes() {
    return this.get('shareAttributes');
  }

  get requester() {
    return this.get('requester');
  }

  cancel() {
    return this.rpc('cancel');
  }

  accept() {
    return this.rpc('accept');
  }

  reject() {
    return this.rpc('reject');
  }
}

class Share extends Model {
  get primaryKey() {
    return 'shareRecipientId';
  }

  get targetResourceId() {
    return this.get('targetResourceId');
  }

  get isRequest() {
    return this.has('shareRequestId');
  }

  get sharingGroupId() {
    return this.targetResourceId.split(':')[1];
  }

  get roleName() {
    return this.get('roleName');
  }

  get shareAttributes() {
    return this.get('shareAttributes');
  }

  get description() {
    return this.get('description');
  }
}

class IncomingShareCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    // TODO: can be share request or share
    return ShareRequest;
  }
}

class OutgoingShareRequestCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return ShareRequest;
  }
}

class OutgoingShareCollection extends PersistentCollection {
  url() {
    return this.url_;
  }

  model() {
    return Share;
  }
}

class ResourceGroup extends Model {
  constructor(attributes) {
    super(attributes);

    this.memberCollection = null;
    this.roleCollection = null;
    this.incomingShareCollection = null;

    this.outgoingShareRequestCollection = null;
    this.outgoingShareCollection = null;
  }

  get primaryKey() {
    return 'resourceGroupId';
  }

  get logoUrl() {
    let logoUrl = this.get('appearance').logoUrl;
    if (!logoUrl) {
      logoUrl = SHIELD_IMAGE;
    }
    return logoUrl;
  }

  get label() {
    return this.get('targetResourceName');
  }

  get memberCount() {
    return this.get('memberCount');
  }

  get created() {
    return this.get('created');
  }

  get updated() {
    return this.get('updated');
  }

  get shortLabel() {
    if (this.label.length < 5) {
      return this.label;
    }

    let _shortLabel = this.label.substring(0, 3).toUpperCase();
    // if (this.label.indexOf(' ') !== -1) {
    //   const ageIdentifier = this.label.split(/[, ]+/).pop();
    //   _shortLabel += ' ' + ageIdentifier;
    // }
    return _shortLabel;
  }

  get members() {
    if (this.memberCollection === null) {
      this.memberCollection = new MemberCollection(`${this.url()}/members`);
    }
    return this.memberCollection;
  }

  get roles() {
    if (this.roleCollection === null) {
      this.roleCollection = new RoleCollection(`${this.url()}/roles`);
    }
    return this.roleCollection;
  }

  join() {
    return this.rpc('join');
  }

  get incomingShares() {
    if (this.incomingShareCollection === null) {
      this.incomingShareCollection = new IncomingShareCollection(
        `${this.url()}/shares/incoming`
      );
    }
    return this.incomingShareCollection;
  }

  get outgoingShareRequests() {
    if (this.outgoingShareRequestCollection === null) {
      this.outgoingShareRequestCollection = new OutgoingShareRequestCollection(
        `${this.url()}/shares/outgoing/requests`
      );
    }
    return this.outgoingShareRequestCollection;
  }

  get outgoingShares() {
    if (this.outgoingShareCollection === null) {
      this.outgoingShareCollection = new OutgoingShareCollection(
        `${this.url()}/shares/outgoing/active`
      );
    }
    return this.outgoingShareCollection;
  }

  setResourceACL(resourceId, acl) {
    return this.rpc('setResourceACL', { resourceId, acl });
  }
}

class ResourceGroupCollection extends PersistentCollection {
  url() {
    return '/resourceGroups';
  }

  model() {
    return ResourceGroup;
  }

  sorted() {
    const resourceGroups = this.toArray();
    resourceGroups.sort((a, b) => a.label.localeCompare(b.label));
    return resourceGroups;
  }
}

export const getMembers = async () => {
  const res = apiClient().get('/resourceGroups/current/members');
  return await res.promise;
};

const resourceGroupCollection = new ResourceGroupCollection();
window.resourceGroupCollection = resourceGroupCollection;

export default resourceGroupCollection;
