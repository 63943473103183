import React, { Component, Fragment } from 'react';
import { Session } from '../../../domain/Session';

import { observable, action } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { observer } from 'mobx-react';

import Loading from 'lib/Loading';
import { gotoRoute } from '../../route';
import logger from '../../../utils/Logger';
import { confirmModal } from 'lib/Confirmation';
import { NoResults } from 'lib/PlaceHolder';

import noMembershipsImage from 'img/no_teams.svg';
import noInvitationsImage from 'img/no_invites.svg';

import { withTranslation } from 'react-i18next';
import { DaysAgo } from 'lib/DaysAgo';
import { EventPublisher } from 'utils/EventPublisher';
import { Page } from 'lib/Page';
import sweetAlert from 'lib/sweetAlert';

const MembershipOverview = ({ memberships, onLeaveResourceGroup }) => {
  return memberships.map((membership) => {
    return (
      <div key={membership.resourceGroupId} className="user-setting-item">
        <div className="user-setting-thumb">
          <div className="user-setting-thumb-inner" />
        </div>
        <div className="flex-content">
          <div className="user-setting-user-name float-left">
            <b>{membership.targetResourceName}</b>
          </div>
          <div className="user-setting-actions float-right break-after">
            {membership.roleNames.map((roleName) => {
              return (
                <div key={roleName} className="user-setting-action float-left">
                  {roleName}
                </div>
              );
            })}
            {/*<div className="user-setting-action float-left user-setting-icon"></div>*/}
          </div>
          <div className="user-setting-icons">
            <div
              className="user-setting-icon leave clickable"
              onClick={() => onLeaveResourceGroup(membership)}
            />
          </div>
        </div>
      </div>
    );
  });
};

const InvitationOverview = withTranslation('module.settings.account')(
  ({ invitations, onAcceptInvitation, onDeclineInvitation, t }) => {
    return invitations.map((invitation) => {
      return (
        <div
          key={invitation.invitationId}
          className="user-setting-item invited"
        >
          <div className="user-setting-thumb">
            <div className="user-setting-thumb-inner" />
          </div>
          <div className="flex-content">
            <div className="user-setting-user-name float-left">
              <b>{invitation.targetResourceName}</b>
              <span className="light-span">
                <DaysAgo date={invitation.created} />
              </span>
            </div>
            <div className="user-setting-actions float-right break-after">
              {invitation.roleNames.map((roleName) => {
                return (
                  <div
                    key={roleName}
                    className="user-setting-action float-left"
                  >
                    {roleName}
                  </div>
                );
              })}
              {/*<div className="user-setting-action float-left underlined">Meerdere rollen</div>*/}
            </div>
            <div className="user-setting-icons">
              <div
                className="user-setting-icon check clickable"
                onClick={() => onAcceptInvitation(invitation.invitationId)}
              />
              <div
                className="user-setting-icon cross clickable"
                onClick={() => onDeclineInvitation(invitation.invitationId)}
              />
            </div>
          </div>
        </div>
      );
    });
  }
);

const UserSettings = withTranslation('module.settings.account')(
  observer(
    class UserSettings extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');
      }

      componentDidMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        this.dataState.set('loading');
        try {
          const currentSession = Session.current();
          yield currentSession.isReady();
          this.user = Session.current().user;
          this.dataState.set('ready');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load user settings',
          });
          this.dataState.set('error');
        }
      });

      setCurrentPage(page) {
        gotoRoute('user-management.user-settings', { page });
      }

      get currentPage() {
        return this.props.match.params.page;
      }

      async acceptInvitation(invitationId) {
        await this.user.acceptInvitation(invitationId);

        Session.current().reloadSettings(true);
      }

      onLeaveResourceGroup(membership) {
        sweetAlert({
          title: this.props.t('leaveResourceGroupConfirmTitle', { membership }),
          text: this.props.t('leaveResourceGroupConfirmMessage', {
            membership,
          }),
          dangerMode: true,
          buttons: [
            this.props.t('common:cancel'),
            this.props.t('common:confirm'),
          ],
        }).then((willDelete) =>
          willDelete
            ? this.user.leaveResourceGroup(membership.resourceGroupId)
            : null
        );
      }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return 'error';
        }

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { title: this.props.t('title') },
          ],
          filters: [
            {
              path: 'memberships',
              title: this.props.t('myTeams'),
              isActive: this.currentPage === 'memberships',
            },
            {
              path: 'invitations',
              title: this.props.t('invitations'),
              isActive: this.currentPage === 'invitations',
            },
          ],
        };

        return (
          <Page {...pageProps}>
            {this.currentPage === 'memberships' && (
              <Fragment>
                <MembershipOverview
                  memberships={this.user.memberships}
                  onLeaveResourceGroup={(membership) =>
                    this.onLeaveResourceGroup(membership)
                  }
                />
                {this.user.memberships.length === 0 && (
                  <NoResults
                    imgSrc={noMembershipsImage}
                    description={this.props.t('noMemberships')}
                  />
                )}
              </Fragment>
            )}
            {this.currentPage === 'invitations' && (
              <Fragment>
                <InvitationOverview
                  invitations={this.user.invitations}
                  onAcceptInvitation={(invitationId) => {
                    this.acceptInvitation(invitationId);
                    EventPublisher.dispatch('INVITATION_ACCEPTED');
                  }}
                  onDeclineInvitation={(invitationId) => {
                    this.user.declineInvitation(invitationId);
                    EventPublisher.dispatch('INVITATION_DECLINED');
                  }}
                />
                {this.user.invitations.length === 0 && (
                  <NoResults
                    imgSrc={noInvitationsImage}
                    description={this.props.t('noOpenInvitations')}
                  />
                )}
              </Fragment>
            )}
          </Page>
        );
      }
    }
  )
);

export { UserSettings };
