import videoCollection from 'domain/Video';
import { useQuery } from 'react-query';
import Loading from '../../../lib/Loading';

export const generateClipThumbnail = async (
  videoId: string,
  time: number,
  size: number = 120
) => {
  const video = await videoCollection.getOrFetch(videoId);
  const thumbnailUrl = await video.clipThumbnailUrl(time, size);
  return thumbnailUrl;
};

export const CachedAsyncImage = ({ promise, cacheKey, style }) => {
  const { data: image, isFetching } = useQuery([cacheKey], () => promise(), {
    refetchOnMount: false,
  });
  if (isFetching) {
    return (
      <div
        className={'aync-image__loading'}
        style={{
          ...style,
        }}
      >
        <Loading type={'spinner'} size={20} color={'white'}></Loading>
      </div>
    );
  } else {
    return (
      <div
        style={{
          ...style,
        }}
      >
        <img src={image} style={{ maxWidth: '100%', width: '100%' }} />
      </div>
    );
  }
};
