import { getDependencies, filteredObject } from './util';
import { DataResolver } from './DataResolver';

type DataViewList = { [key: string]: DataResolver };

class DataViewDefinition {
  constructor(
    readonly name: string,
    readonly dependencies: string[],
    readonly resolver: DataResolver
  ) {}
}

class BuildStep {
  constructor(private dataViewDefinitions: DataViewDefinition[] = []) {}

  addDataViewDefinition(dataViewDefinition: DataViewDefinition) {
    this.dataViewDefinitions.push(dataViewDefinition);
  }

  getDataViewDefinitions() {
    return this.dataViewDefinitions;
  }
}

export class DataBuilder {
  private buildSteps: BuildStep[];

  constructor() {
    this.buildSteps = [];
  }

  getCurrentBuildStep(): BuildStep {
    return this.buildSteps[this.buildSteps.length - 1];
  }

  addDataViewDefinitionToCurrentBuildStep(
    dataViewDefinition: DataViewDefinition
  ) {
    this.getCurrentBuildStep().addDataViewDefinition(dataViewDefinition);
  }

  startBuildStep() {
    this.buildSteps.push(new BuildStep());
  }

  static fromDataViews(
    dataSources: string[],
    dataViewList: DataViewList
  ): DataBuilder {
    const dataViewsToProcess = Object.entries(dataViewList).map(
      ([name, resolver]) => {
        const dependencies = getDependencies(resolver);
        return new DataViewDefinition(name, dependencies, resolver);
      }
    );

    const builder = new DataBuilder();

    const metDependencies = new Set(dataSources);
    const steps = [];
    while (dataViewsToProcess.length > 0) {
      builder.startBuildStep();

      const newDeps = new Set();
      for (const dataView of dataViewsToProcess) {
        const missingDeps = dataView.dependencies.filter(
          dep => !metDependencies.has(dep)
        );
        if (missingDeps.length === 0) {
          builder.addDataViewDefinitionToCurrentBuildStep(dataView);
        }
      }

      const currentDataViewDefinitions = builder
        .getCurrentBuildStep()
        .getDataViewDefinitions();
      for (const metDataViewDefinition of currentDataViewDefinitions) {
        metDependencies.add(metDataViewDefinition.name);

        dataViewsToProcess.splice(
          dataViewsToProcess.findIndex(a => a === metDataViewDefinition),
          1
        );
      }

      if (currentDataViewDefinitions.length === 0) {
        console.log(dataViewsToProcess);
        throw 'Error: missing deps';
      }
    }
    return builder;
  }

  resolve(
    data_: { [key: string]: any },
    resolveContext?: any
  ): { [key: string]: any } {
    const data = Object.assign({}, data_);

    for (const step of this.buildSteps) {
      for (const dataViewDefinition of step.getDataViewDefinitions()) {
        const [dependencies, fn] = dataViewDefinition.resolver;

        data[dataViewDefinition.name] = fn(
          resolveContext,
          ...dependencies.map(dep => data[dep])
        );
      }
    }
    return data;
  }
}
