import { RouteExact, RouteRedirect } from '../route';
import { Module } from '../base';

import { ObserveLive, ObserveVideo } from './containers';
import { getRoutePath } from 'modules/route';

import { registry } from 'utils/ObservationInput';
import { Session } from 'domain/Session';

import sportingEventCollection from '../../domain/SportingEvent';

const MODULE_NAME = 'observe';

export default new Module(
  MODULE_NAME,
  [],
  [
    new RouteExact(
      ':sportingEventId/live/:observationInputKey',
      ObserveLive,
      'live'
    ),
    new RouteRedirect(
      ':sportingEventId/live-start/:observationInputKey',
      async ({ sportingEventId, observationInputKey }) => {
        const sportingEvent = await sportingEventCollection.getOrFetch(
          sportingEventId
        );

        sportingEventId = await sportingEvent.getPrivilegedToSportingEventId(
          'observe'
        );

        return getRoutePath(`${MODULE_NAME}.live`, {
          sportingEventId,
          observationInputKey,
        });
      },
      'live-start'
    ),

    new RouteExact(
      ':sportingEventId/video/:observationInputKey',
      ObserveVideo,
      'video'
    ),
    new RouteRedirect(
      ':sportingEventId/video-start/:observationInputKey',
      async ({ sportingEventId, observationInputKey }) => {
        const sportingEvent = await sportingEventCollection.getOrFetch(
          sportingEventId
        );

        sportingEventId = await sportingEvent.getPrivilegedToSportingEventId(
          'observe',
          true
        );

        if (observationInputKey === 'default') {
          const observationInputs = await registry.forSession({
            session: Session.current(),
            live: false,
          });
          observationInputKey = observationInputs[0].key();
        }

        return getRoutePath(`${MODULE_NAME}.video`, {
          sportingEventId,
          observationInputKey,
        });
      },
      'video-start'
    ),
  ]
);
