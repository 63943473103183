import React from 'react';

import { MatchDashboard } from './handball/match-dashboard';
import { TeamDashboard } from './handball/team-dashboard';
import { Report } from '../../../lib/Reporting/Sports/Handball/Report';

// TODO enforce type
export type HandballReportState =
  | 'default'
  | 'match'
  | 'team-home'
  | 'team-away';

const Dashboard: React.FC<{
  reportState: HandballReportState;
  report: Report;
  onClick: () => void;
}> = ({ reportState, report, onClick, ...props }) => {
  switch (reportState) {
    case 'team-home':
      // @ts-ignore
      return (
        <TeamDashboard
          teamReport={report.home}
          reportState={reportState}
          onClick={onClick}
          {...props}
        />
      );
    case 'team-away':
      // @ts-ignore
      return (
        <TeamDashboard
          teamReport={report.away}
          reportState={reportState}
          onClick={onClick}
          {...props}
        />
      );
    case 'match':
    default:
      // @ts-ignore
      return (
        <MatchDashboard
          onClick={onClick}
          report={report}
          reportState={reportState}
          {...props}
        />
      );
  }
};

export default Dashboard;
