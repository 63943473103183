import { isDev } from './dev';
import logger from '../utils/Logger';

/**
 * Writes content to the clipboard.
 * @param content The content to be copied to the clipboard.
 * @returns A promise that resolves to true if the operation is successful, false otherwise.
 */
export const writeToClipboard = async (
  content: string | HTMLElement
): Promise<boolean> => {
  const { ClipboardItem } = window;
  let res = false;
  // Check if the connection is via HTTPS
  if (window.location.protocol !== 'https:') {
    isDev() && window.alert('Cannot copy when connection is not via https://');
    logger.error('Cannot copy when connection is not via https://');
  }
  if (typeof ClipboardItem !== 'undefined' && navigator.clipboard) {
    // For modern browsers supporting ClipboardItem

    const htmlContent = [
      new ClipboardItem(
        !isHTML(content)
          ? {
              'text/plain': new Blob([content], { type: 'text/plain' }),
            }
          : {
              'text/html': new Blob([content], { type: 'text/html' }),
            }
      ),
    ];

    // Attempt to write to clipboard
    res = await navigator.clipboard.write(htmlContent).then(
      function () {
        isDev() &&
          console.log(
            'Async: Copying to clipboard was successful!',
            htmlContent
          );
        return true;
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
        logger.error('Async: Could not copy text: ', err);
        return false;
      }
    );
    return res;
  } else {
    // Fallback for browsers not supporting ClipboardItem or navigator.clipboard
    const selector = document.createElement('div');
    if (!isHTML(content)) {
      selector.textContent = content;
    } else {
      selector.appendChild(content.cloneNode(true));
    }
    selector.style.position = 'fixed';
    selector.style.pointerEvents = 'none';
    selector.style.opacity = '0';

    document.body.appendChild(selector);

    const range = document.createRange();
    range.selectNodeContents(selector);
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    // Attempt to copy to clipboard using document.execCommand
    try {
      document.execCommand('copy');
      res = true;
    } catch (err) {
      console.error('Unable to copy: ', err);
      logger.error('Unable to copy: ', err);
      res = false;
    } finally {
      // Clean up
      if (selection) {
        selection.removeAllRanges();
      }
      document.body.removeChild(selector);
    }
    return res;
  }

  function isHTML(str) {
    // Create a new DOMParser
    const parser = new DOMParser();

    try {
      // Attempt to parse the string as HTML
      const doc = parser.parseFromString(str, 'text/html');

      // Check if the parser didn't throw an error and the document has elements

      return doc.body.childNodes.length > 1;
    } catch (e) {
      // If parsing throws an error, return false
      return false;
    }
  }
};
